const listingSteps = [
  {
    number: 1,
    id: 'property-info',
    title: 'Property Info',
    formSteps: [
      {
        number: 1,
        id: 'type-and-mls',
        title: 'Listing Type and MLS',
      },
      {
        number: 2,
        id: 'owners',
        title: 'Owners',
      },
      {
        number: 3,
        id: 'required-info',
        title: 'Required Info',
      },
      {
        number: 4,
        id: 'optional-info',
        title: 'Optional Info',
      },
    ]
  },
  {
    number: 2,
    id: 'marketing-options',
    title: 'Marketing Options',
  },
  {
    number: 3,
    id: 'upload-photos',
    title: 'Submit Photos',
  },
  {
    number: 4,
    id: 'review',
    title: 'Review Info',
  },
  {
    number: 5,
    id: 'sign',
    title: 'Sign Forms',
  },
  {
    number: 6,
    id: 'payment',
    title: 'Make Payment',
  },
  {
    number: 7,
    id: 'waiting-for-listing',
    title: 'Processing',
  },
  {
    number: 8,
    id: 'active-listing',
    additionalInfo: 'When your "Sales Contract" will be ready, don\'t forget to submit it (or send via email) and sign "Under Contract" form. You can do it on the listing page.',
    title: 'Active Listing',
  },
  {
    number: 9,
    id: 'under-contract',
    additionalInfo: 'Please, don\'t forget to submit (or send via email) "Closing Disclosure Statement" when your home goes to settlement. You can do it on the listing page.',
    title: 'Under Contract',
  },
  {
    number: 10,
    id: 'completed',
    title: 'Completed',
  },
]


export {
  listingSteps,
}
