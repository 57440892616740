import { PropertyTypes, propertyTypes } from './property-types'
import { Areas, sourcesStatesAreas, areas, areasCoverage } from './states-areas'
import { listingSteps } from './listing-steps'


const domainName = process.env.VUE_APP_DOMAIN_NAME
const domainAddress = process.env.VUE_APP_DOMAIN_ADDRESS

const getDomainBySource = (source) => {
  const addresses = {
    lexp: {
      address: 'https://listingexpress.com',
      name: 'ListingExpress.com',
    },
    clxp: {
      address: 'https://carolinalistingexpress.com',
      name: 'CarolinaListingExpress.com',
    },
    glxp: {
      address: 'https://georgialistingexpress.com',
      name: 'GeorgiaListingExpress.com',
    },
    emls: {
      address: 'https://expressmlslistings.com',
      name: 'ExpressMLSListings.com',
    }
  }

  return addresses[source]
}


const localhostSendEmails = false
const localhostDevEmail = process.env.VUE_APP_DEV_EMAIL
const localhostDevIP = process.env.VUE_APP_DEV_IP

const adminEmails = localhostDevEmail ? [localhostDevEmail] : ['service@selectpremiumprop.com', 'selectpremiumprop@gmail.com']


export {
  getDomainBySource,
  domainName,
  domainAddress,

  localhostSendEmails,
  localhostDevEmail,
  adminEmails,
  localhostDevIP,
  
  PropertyTypes,
  propertyTypes,
  sourcesStatesAreas,
  Areas,
  areas,
  areasCoverage,
  listingSteps,
}