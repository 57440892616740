import { PropertyTypes } from '@/config/property-types'
import { Areas, States } from '@/config/states-areas'
import { InputTypes, Scopes } from './types'
import { prices } from '@/config/prices'


const form = [
  {
    sectionTitle: 'Address',
    fieldsets: [
      {
        fieldsetTitle: '',
        fieldsetDescription: '',
        fields: [
          {
            name: 'streetAddress',
            inputType: InputTypes.Text,
            label: 'Street Address',
            required: true,
          },
          {
            name: 'unitNumber',
            inputType: InputTypes.Text,
            label: 'Unit Number',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          },
          {
            name: 'city',
            inputType: InputTypes.Text,
            label: 'City',
            required: true,
          },
          {
            name: 'zipCode',
            inputType: InputTypes.Text,
            label: 'Zip Code',
            required: true,
          },
          {
            name: 'county',
            inputType: InputTypes.Text,
            label: 'County',
            required: true,
          },
          {
            name: 'subdivision',
            inputType: InputTypes.Text,
            label: 'Subdivision',
          },
          {
            name: 'isWithinCityLimits',
            inputType: InputTypes.YesNo,
            label: 'Is Property Within City Limits?',
            required: true,
          },
          {
            name: 'shippingOrMailingAddress',
            inputType: InputTypes.Text,
            label: 'Shipping or Mailing Address (If Different)',
            help: 'Where Materials Should Be Sent'
          },
        ],
      },
    ],
  },
  {
    sectionTitle: 'General',
    fieldsets: [
      {
        fieldsetTitle: '',
        fieldsetDescription: '',
        fields: [
          {
            name: 'listingPrice',
            inputType: InputTypes.Price,
            label: {
              default: 'Property Listing Price',
              scope: Scopes.ByPropertyType,
              types: { [PropertyTypes.HomeRent]: 'Monthly Rental Price' },
            },
            required: true,
          },
          {
            name: 'listingExpirationDate',
            inputType: InputTypes.Date,
            label: 'Listing Expiration Date',
            required: true,
            help: {
              default: 'Within 6 Months of Today',
              scope: Scopes.ByPropertyType,
              types: { [PropertyTypes.HomeRent]: 'Within 3 Months of Today' },
            },
            additionalInputs: [
              {
                name: 'wantOneYearListing',
                inputType: InputTypes.Checkbox,
                label: `Check this box if you would like the 1 year option <b>for only $${prices.oneYearListing}</b>.`,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.LandSale]
              }
            ]
          },
          {
            name: 'ownershipType',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Georgia]: [PropertyTypes.HomeSale],
            },
            inputType: InputTypes.YesNo,
            label: 'Leased Land',
            required: true,
          },
          {
            name: 'ownershipType',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale],
            },
            inputType: InputTypes.Select,
            options: ['Normal Ownership / Fee Simple', 'Condominium', 'Cooperative', 'Ground Rent', 'Land Lease'],
            label: 'Ownership Type',
            required: true,
          },
          {
            name: 'additionalParcels',
            inputType: InputTypes.YesNo,
            label: 'Additional Parcels',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Bright]: [PropertyTypes.LandSale] }
          },
          {
            name: 'typeOfProperty',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
            },
            inputType: InputTypes.Select,
            options: {
              default: ['Single Family Detached', 'Single Family Attached', 'Single Family Attached — Condo'],
              scope: Scopes.IndividualMapping,
              mapping: {
                [Areas.Georgia]: {
                  [PropertyTypes.HomeSale]: ['Single Family Residence', 'Condominium', 'Townhouse', 'Conversion', 'Farm', 'Mobile Home', 'Mixed Use', 'Other'],
                  [PropertyTypes.HomeRent]: ['Single Family Residence', 'Condominium', 'Townhouse', 'Conversion', 'Farm', 'Mobile Home', 'Mixed Use', 'Other'],
                },
                [Areas.Bright]: {
                  [PropertyTypes.HomeSale]: ['Detached', 'Twin / Semi-Detached', 'Interior Row / Townhouse', 'End of Row Townhouse', 'Unit / Flat', 'Penthouse Unit / Flat', 'Manufactured', 'Mobile Home'],
                  [PropertyTypes.HomeRent]: ['Detached', 'Twin / Semi-Detached', 'Row / Townhouse', 'End of Row Townhouse', 'Unit / Flat', 'Penthouse Unit / Flat', 'Manufactured', 'Mobile Home'],
                },
                [Areas.CentralVA]: {
                  [PropertyTypes.HomeSale]: ['Single Family Detached', 'Single Family Attached', 'Condo', 'Cooperative', 'Townhouse'],
                  [PropertyTypes.HomeRent]: ['House - DETACHED', 'House - ATTACHED', 'Condo/Townhome', 'Apartment', 'Duplex', 'Triplex', 'Quad'],
                  [PropertyTypes.LandSale]: ['Building Lot', 'Residential Land'],
                },
                [Areas.Charlotte]: {
                  [PropertyTypes.HomeSale]: ['Single Family', 'Condo', 'Townhouse', 'Manufactured', 'Modular', 'Co-op'],
                  [PropertyTypes.HomeRent]: ['Single Family', 'Apartment', 'Condo', 'Townhome', 'Studio', 'Manufactured - Single Wide', 'Manufactured - Double Wide', 'Manufactured - Triple Wide', 'Modular'],
                  [PropertyTypes.LandSale]: ['Lot', 'Acreage', 'Farm', 'Unimproved Commercial'],
                },
                [Areas.NCRegion]: {
                  [PropertyTypes.HomeSale]: ['Single Family Residence', 'Condo', 'Townhouse', 'Manufactured'],
                  [PropertyTypes.HomeRent]: ['Single Family Residence', 'Apartment', 'Condo', 'Duplex', 'Efficiency', 'Manufactured', 'Quadplex', 'Studio', 'Townhouse', 'Triplex'],
                  [PropertyTypes.LandSale]: ['Residential', 'Commercial', 'Farm', 'Mobile Home Lot', 'Recreation', 'Timber', 'Undeveloped', 'Wooded'],
                },
                [Areas.Triangle]: {
                  [PropertyTypes.HomeSale]: ['Single Family Residence', 'Apartment', 'Condo', 'Duplex', 'Farm', 'Manufactured on Land', 'Quadruplex', 'Ranch', 'Townhouse', 'Triplex'],
                  [PropertyTypes.HomeRent]: ['Single Family Residence', 'Apartment', 'Condo', 'Duplex', 'Farm', 'Manufactured on Land', 'Quadruplex', 'Ranch', 'Townhouse', 'Triplex'],
                  [PropertyTypes.LandSale]: ['Vacant Lot / Land', 'Farm', 'Mobile Home Lot'],
                },
                [Areas.Triad]: {
                  [PropertyTypes.HomeSale]: ['Site / Stick Built', 'Cluster', 'Condo', 'Manufactured', 'Modular', 'Townhouse', 'Duplex'],
                  [PropertyTypes.HomeRent]: ['Site / Stick Built', 'Condo / Townhouse', 'Manufactured', 'Modular', 'Duplex', 'Triplex'],
                  [PropertyTypes.LandSale]: ['Single', 'Agricultural', 'Commercial', 'Industrial', 'Resort', 'Undeveloped'],
                },
                [Areas.Charleston]: {
                  [PropertyTypes.HomeSale]: ['Single Family Detached', 'Single Family Attached', 'Manufactured / Mobile Home', 'Condo'],
                  [PropertyTypes.HomeRent]: ['Single Family Detached', 'Apartment', 'Condo', 'Mobile Home', 'Patio Home', 'Townhouse', 'Villa'],
                },
                [Areas.Coastal]: {
                  [PropertyTypes.HomeSale]: ['Single Family', 'Condo / Townhome'],
                  [PropertyTypes.LandSale]: ['Residential Lot', 'Acreage', 'Commercial Lot', 'Farm', 'Multi-Family Lot', 'Manufactured Lot'],
                },
              }
            },
            label: 'Type of Property',
            required: true,
          },
          {
            name: 'yearBuilt',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            inputType: InputTypes.Text,
            mask: '####',
            label: 'Year Built',
            placeholder: '####',
            required: true,
            help: {
              default: '',
              scope: Scopes.IndividualMapping,
              mapping: {
                [Areas.Bright]: {
                  [PropertyTypes.HomeRent]: `
                    Please <a href="#" data-detailed-help="leadBasedPaint" class="link" target="_blank">click here</a> for lead based paint regulations if your property was built before 1978.
                  `,
                }
              },
            },
            detailedHelp: {
              leadBasedPaint: `
                <p class="mb-2 font-bold">Effective January 1, 2015, the following Act will apply to all residential 
                rental dwelling units built prior to 1978.</p>
                <p class="mb-2 font-bold">MARYLAND’S “LEAD LAW”</p>
                <p class="mb-2">All Residential rental properties built before 1978 are required to be registered and 
                then renewed <b>annually</b> with Maryland Department of the Environment.</p>
                <p class="mb-2">Under this law, landlords are required to (1) address all potential lead-based paint 
                hazards in rental properties constructed prior to 1978, (2) register and annually renew registration of 
                their properties with the Maryland Department of the Environment’s (MDE) Lead Poisoning Prevention 
                Program, and (3) provide tenants with lead educational materials: A Notice of Tenant’s Rights must be 
                provided to all tenants providing a detailed explanation of what property owners are required to do to 
                comply with the law, how to inform the landlord that repairs need to be performed in the home, and steps 
                one can take to enforce tenant legal rights if the landlord refuses to respond to a request.</p>
                <p class="mb-2 font-bold">In order to be fully compliant with the law, a landlord is required to:</p>
                <ul class="list-decimal list-inside mb-2">
                  <li class="mb-1">Give every tenant three items: &nbsp;the Notice of Tenant Rights, the EPA brochure, 
                  “Protect Your Family from Lead in Your Home”, and a copy of the lead inspection certificate for the 
                  unit on or before the day the tenant moves in. Every landlord and property manager should keep on file 
                  a signed statement from the tenant acknowledging receiving these items.</li>
                  <li class="mb-1">Ensure that the property is currently registered with the Maryland Department of the 
                  Environment (MDE) and to pay a $30.00 per property/unit registration fee.</li>
                  <li class="mb-1">Obtain a passing Full Risk Reduction Certificate prior to a tenant moving into the 
                  property.&nbsp; This certificate can only be issued by a select number of inspectors, licensed for 
                  this type of inspection.&nbsp; The cost for this service can range from $200-$500 depending on the 
                  property and inspector chosen for the work.</li>
                </ul>
                More information can be obtained from the Maryland Department of Environment by visiting their website 
                at <a href="http://www.mde.state.md.us/lead" class="link" 
                target="_blank">http://www.mde.state.md.us/lead</a> or by contacting them directly at 410 537-4199. A 
                summary of the law can also be found by <a href="/pdfs/mdleadlaw.pdf" class="link" target="_blank">clicking here</a> and a FAQ page <a href="/pdfs/LeadLawQuestions.pdf" class="link" target="_blank">here</a>.
              `
            }
          },
          {
            name: 'approxAcreage',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.LandSale],
            inputType: InputTypes.Text,
            label: 'Approx Acreage',
            required: true,
          },
          {
            name: 'classType',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Georgia]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Select,
            options: ['Agriculture', 'Estate Lot', 'Industrial', 'Residential Lot', 'Unimproved Land'],
            label: 'Class Type',
            required: true,
          },
          {
            name: 'newConstruction',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.CentralVA]: [PropertyTypes.HomeSale],
              [Areas.NCRegion]: [PropertyTypes.HomeSale],
              [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triad]: [PropertyTypes.HomeSale],
            },
            required: true,
            inputType: InputTypes.YesNo,
            label: 'New Construction',
          },
          {
            name: 'storiesLevels',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            inputType: InputTypes.Select,
            options: {
              default: ['1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5'],
              scope: Scopes.ByMLSArea,
              areas: {
                [Areas.Georgia]: ['One', 'One and One Half', 'Two', 'Three Or More', 'Multi/Split'],
                [Areas.Triad]: ['1 Story', '1 Story w/Upper Bonus', '1.5 Story', '2 Story', '2.5 Story', '3 Story', 'Split Foyer', 'Split Level'],
              }
            },
            label: 'Stories / Levels',
            required: true,
          },
          {
            name: 'numberOfFireplaces',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triad]: [PropertyTypes.HomeSale],
            },
            inputType: InputTypes.Number,
            label: 'Number of Fireplaces',
            required: true,
          },
          {
            name: 'styleOfHome',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Select,
            options: ['A-Frame', 'Bungalow', 'Cape Cod', 'Chalet', 'Colonial', 'Condo Lower Level', 'Condo Main Level', 'Condo Upper Level', 'Contemporary', 'Cottage', 'Double-Wide', 'Dutch Colonial', 'Farm House', 'French Provincial', 'Georgian', 'Log', 'Ranch', 'Salt Box', 'Single Wide', 'Traditional', 'Transitional', 'Tudor', 'Victorian', 'Williamsburg'],
            label: 'Style of Home',
            required: true,
          },
          {
            name: 'styleOfHome',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Coastal]: [PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: ['Ranch', 'Raised Beach', 'Split Level', 'Contemporary', 'Colonial', 'Low Country', 'Traditional', 'Patio Home', 'Other', 'Single Wide', 'Double Wide', 'Mediterranean', 'Condo', 'Townhome'],
            label: 'Style of Home',
            required: true,
          },
          {
            name: 'isThereBasement',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triad]: [PropertyTypes.HomeSale],
            },
            inputType: InputTypes.YesNo,
            label: 'Is there a Basement',
            required: true,
          },
          {
            name: 'homeOccupiedBy',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Select,
            options: ['Owner', 'Tenant', 'New Construction', 'Vacant', 'Other'],
            label: 'Home is Occupied By',
            required: true,
          },
          {
            name: 'homeOccupiedBy',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: ['Tenant', 'Vacant', 'Owner'],
            label: 'Occupant Type Now',
            required: true,
          },
          {
            name: 'approxAcreage',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Text,
            label: 'Acreage',
            required: true
          },
          {
            name: 'swimmingPool',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.YesNo,
            label: 'Swimming Pool',
            required: true
          },
          {
            name: 'swimmingPool',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: ['Yes - Community', 'Yes - Personal', 'No'],
            label: 'Swimming Pool',
            required: true,
          },
          {
            name: 'golfFrontage',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Golf Frontage',
            required: true
          },
          {
            name: 'numberOfAttachedGarageSpaces',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            inputType: InputTypes.Number,
            label: 'Number of Attached Garage Spaces',
            required: 'Required if Attached Garage',
          },
          {
            name: 'numberOfDetachedGarageSpaces',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.Number,
            label: 'Number of Detached Garage Spaces',
            required: 'Required if Attached Garage',
          },
          {
            name: 'garageSpaces',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.Text,
            label: 'Garage Spaces',
            required: 'Required if Garage',
          },
          {
            name: 'openSpaces',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.Text,
            label: 'Number of Open Parking Spaces',
            required: true
          },
          {
            name: 'garageOrCarportSpaces',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Text,
            label: 'Garage / Carport Spaces',
            required: 'Required if Garage/Carport',
          },
          {
            name: 'zoningCode',
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.CentralVA]: [PropertyTypes.LandSale],
              [Areas.Bright]: [PropertyTypes.LandSale],
              [Areas.Charlotte]: [PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.LandSale],
              [Areas.Coastal]: [PropertyTypes.LandSale],
            },
            inputType: InputTypes.Text,
            label: 'Zoning Code',
            required: true
          },
          {
            name: 'environmentalFacotrs',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Select,
            options: ['Yes', 'No', 'Unknown'],
            label: 'Environmental Factors?',
            required: true,
          },
          {
            name: 'federalFloodZone',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Federal Flood Zone?',
            help: `
              <a href="https://msc.fema.gov/portal/search" class="link" 
              target="blank">Click Here</a> to Check
            `,
            required: true,
          },
          {
            name: 'propertyInFloodZone',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Select,
            options: ['Not in a Flood Zone', 'Flood Fringe', 'Flood Plain', 'Flood Way', 'Wetlands', 'Partial'],
            label: 'Property in a Flood Zone',
            required: true,
          },
          {
            name: 'waterOriented',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Is the Property Located Near Water?',
            required: true
          },
          {
            name: 'farmLandPreservation',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Farm Land Preservation?',
            required: true
          },
          {
            name: 'seniorCommunity',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.YesNo,
            label: 'Senior Community?',
            required: true
          },
          {
            name: 'minSqFtToBuild',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Text,
            label: 'Minimum SqFt To Build',
            required: true
          },
          {
            name: 'dwellingOnProperty',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Dwelling On Property',
            required: true
          },          
          {
            name: 'constructionType',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Select,
            options: ['Site Built', 'Off Frame Modular', 'On Frame Modular', 'Manufactured'],
            label: 'Construction Type',
            required: true
          },
          {
            name: 'subType',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Select,
            options: ['1 Story', '1 Story / F.R.O.G.', '1.5 Story', '2 Story', '2.5 Story', '3 Story', '4 Story', '5 Story or More', 'Split Level', 'Split Foyer', 'Tri Level'],
            label: 'Sub Type',
            required: true
          },
          {
            name: 'subType',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Select,
            options: ['Stick Built', 'Modular', 'Both Stick and Modular', 'Single Wide', 'Double Wide', 'Triple Wide'],
            label: 'Property Sub Type',
            required: true
          },
          {
            name: 'entryLevel',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: ['Main', 'Upper', 'Lower', 'Basement', 'Third'],
            label: 'Entry Level',
            required: true
          },
          {
            name: 'propertySubjectToHOA',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: ['None', 'Optional', 'Required'],
            label: 'Property subject to HOA',
            required: true
          },
          {
            name: 'unitLevelInBuilding',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
            inputType: InputTypes.Text,
            label: 'Unit Level in Building',
            required: 'Required if Condo'
          },
          {
            name: 'totalRoadFrontage',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Text,
            label: 'Total Road Frontage',
            required: true
          },
          {
            name: 'deedRestrictions',
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.LandSale],
              [Areas.Triangle]: [PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
            },
            inputType: InputTypes.YesNo,
            label: 'Deed Restrictions',
            required: true
          },
          {
            name: 'easements',
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.LandSale],
            },
            inputType: InputTypes.YesNo,
            label: 'Easements',
            required: true
          },
          {
            name: 'soilEvaluation',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Soil Evaluation?',
            required: true
          },
          {
            name: 'environmentalHazards',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Select,
            options: ['Yes', 'No', 'Possible', 'Unknown'],
            label: 'Environmental Hazards?',
            required: true
          },
          {
            name: 'typeOfHome',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: ['Site Built', 'Manufactured', 'Modular'],
            label: 'Structure Type',
            required: true
          },
          {
            name: 'primaryBedroomFirstFloor',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.YesNo,
            label: 'Primary BR 1st Floor',
            required: true
          },
          {
            name: 'anyBedroomsFirstFloor',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Number,
            label: 'Number of Bedrooms on 1st Floor',
            required: true
          },
          {
            name: 'canBeDivided',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Can Be Divided',
            required: true
          },
          {
            name: 'percTest',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
            inputType: InputTypes.YesNo,
            label: 'Perc Test',
            required: true
          },
          {
            name: 'percTestDate',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Date,
            label: 'Perc Test Date',
            required: 'Required if Yes to Perc Test'
          },
          {
            name: 'numberOfBedsYielded',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
            inputType: InputTypes.Text,
            label: 'Number of Beds Yielded',
            required: 'Required if Yes to Perc Test'
          },
          {
            name: 'isPool',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.YesNo,
            label: 'Is There a Pool',
            required: true
          },
          {
            name: 'builtInformation',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Select,
            options: ['Existing', 'Estimated', 'Historic', 'New'],
            label: 'Built Information',
            required: true
          },
          {
            name: 'handicapAccessible',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.YesNo,
            label: 'Handicap Accessible?',
            required: true
          },
          {
            name: 'isFencing',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.YesNo,
            label: 'Is There Fencing?',
            required: true
          },
          {
            name: 'singleFamilyDetachedType',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Select,
            options: ['Ranch', 'Raised Beach', 'Split Level', 'Contemporary', 'Colonial', 'Low Country', 'Traditional', 'Patio Home', 'Other', 'Single Wide', 'Double Wide', 'Mediterranean'],
            label: 'Single Family Detached Type',
            required: true
          },
          {
            name: 'isDiningRoom',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.YesNo,
            label: 'Is There a Formal Dining Room',
            required: true
          },
          {
            name: 'anyBedroomsNonConforming',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.YesNo,
            label: 'Are Any Bedrooms Non Conforming',
            required: true
          },
          {
            name: 'singleFamilyHomeGarage',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: ['Attached 1 Car', 'Attached 2 Car', 'Attached 3 Car ', 'Attached 4 Car ', 'Detached 1 Car ', 'Detached 2 Car', 'Detached 3 Car ', 'Detached 4 Car ', 'Attached / Built-In', 'Detached ', 'Under House - Raised Beach ', 'Carport ', 'Converted ', 'Tandem ', 'Driveway'],
            label: 'Garage / Parking: Single Family Home',
            required: true
          },
          {
            name: 'vehicleParkingCapacity',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale] },
            inputType: InputTypes.Text,
            label: 'Vehicle Parking Capacity',
            required: true
          },
        ]
      },
      {
        fieldsetTitle: 'Available Documents',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.LandSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'availableDocuments-AerialSurvey',
                inputType: InputTypes.Checkbox,
                label: 'Aerial Survey'
              },
              {
                name: 'availableDocuments-Appraisal',
                inputType: InputTypes.Checkbox,
                label: 'Appraisal'
              },
              {
                name: 'availableDocuments-BoundarySurvey',
                inputType: InputTypes.Checkbox,
                label: 'Boundary Survey'
              },
              {
                name: 'availableDocuments-CashFlowAnalysis',
                inputType: InputTypes.Checkbox,
                label: 'Cash Flow Analysis'
              },
              {
                name: 'availableDocuments-DeedRestrictionsConvts',
                inputType: InputTypes.Checkbox,
                label: 'Deed Restrictions/Convts'
              },
              {
                name: 'availableDocuments-EnvironmentalStudy',
                inputType: InputTypes.Checkbox,
                label: 'Environmental Study'
              },
              {
                name: 'availableDocuments-FeasibilityStudy',
                inputType: InputTypes.Checkbox,
                label: 'Feasibility Study'
              },
              {
                name: 'availableDocuments-Lease',
                inputType: InputTypes.Checkbox,
                label: 'Lease'
              },
              {
                name: 'availableDocuments-LegalDescription',
                inputType: InputTypes.Checkbox,
                label: 'Legal Description'
              },
              {
                name: 'availableDocuments-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'availableDocuments-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'availableDocuments-PlatMap',
                inputType: InputTypes.Checkbox,
                label: 'Plat Map'
              },
              {
                name: 'availableDocuments-ProfLoss',
                inputType: InputTypes.Checkbox,
                label: 'Prof/Loss'
              },
              {
                name: 'availableDocuments-SepticPermit',
                inputType: InputTypes.Checkbox,
                label: 'Septic Permit'
              },
              {
                name: 'availableDocuments-SitePlan',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan'
              },
              {
                name: 'availableDocuments-SoilAnalysis',
                inputType: InputTypes.Checkbox,
                label: 'Soil Analysis'
              },
              {
                name: 'availableDocuments-TopographicalMap',
                inputType: InputTypes.Checkbox,
                label: 'Topographical Map'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Utilities Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.LandSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'utilitiesAvailable-CableAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Cable Available'
              },
              {
                name: 'utilitiesAvailable-ElectricityAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Electricity Available'
              },
              {
                name: 'utilitiesAvailable-HighSpeedInternet',
                inputType: InputTypes.Checkbox,
                label: 'High Speed Internet'
              },
              {
                name: 'utilitiesAvailable-NaturalGasAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas Available'
              },
              {
                name: 'utilitiesAvailable-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'utilitiesAvailable-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'utilitiesAvailable-PhoneAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Phone Available'
              },
              {
                name: 'utilitiesAvailable-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'utilitiesAvailable-SewerAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Sewer Available'
              },
              {
                name: 'utilitiesAvailable-UndergroundUtilities',
                inputType: InputTypes.Checkbox,
                label: 'Underground Utilities'
              },
              {
                name: 'utilitiesAvailable-WaterAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Water Available'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Structure of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'StructureOfHome-1',
                inputType: InputTypes.Checkbox,
                label: 'Block',
              },
              {
                name: 'StructureOfHome-2',
                inputType: InputTypes.Checkbox,
                label: 'Brick',
              },
              {
                name: 'StructureOfHome-3',
                inputType: InputTypes.Checkbox,
                label: 'Concrete',
              },
              {
                name: 'StructureOfHome-4',
                inputType: InputTypes.Checkbox,
                label: 'Frame',
              },
              {
                name: 'StructureOfHome-5',
                inputType: InputTypes.Checkbox,
                label: 'Log',
              },
              {
                name: 'StructureOfHome-6',
                inputType: InputTypes.Checkbox,
                label: 'Metal',
              },
              {
                name: 'StructureOfHome-7',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
              {
                name: 'StructureOfHome-8',
                inputType: InputTypes.Checkbox,
                label: 'Stone',
              },
              {
                name: 'StructureOfHome-9',
                inputType: InputTypes.Checkbox,
                label: 'Wood',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Type of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'typeOfHome-Detached',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                inputType: InputTypes.Checkbox,
                label: 'Detached',
              },
              {
                name: 'typeOfHome-Manufactured',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                inputType: InputTypes.Checkbox,
                label: {
                  default: 'Manufactured',
                  scope: Scopes.ByPropertyType,
                  types: { [PropertyTypes.HomeSale]: 'Manufactured with Land' },
                },
              },
              {
                name: 'typeOfHome-DetachedWithHPR',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                inputType: InputTypes.Checkbox,
                label: 'Detached with HPR',
              },
              {
                name: 'typeOfHome-SemiDetached',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                inputType: InputTypes.Checkbox,
                label: 'Semi-Detached',
              },
              {
                name: 'typeOfHome-DetachedLeasedLand',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                inputType: InputTypes.Checkbox,
                label: 'Detached Leased Land',
              },
              {
                name: 'typeOfHome-ManufacturedLeasedLand',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                inputType: InputTypes.Checkbox,
                label: 'Manufactured Leased Land',
              },
              {
                name: 'typeOfHome-Condo',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent],
                inputType: InputTypes.Checkbox,
                label: 'Condo',
              },
              {
                name: 'typeOfHome-Townhouse',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent],
                inputType: InputTypes.Checkbox,
                label: 'Townhouse',
              },
            ]
          },
          {
            groupTitle: 'Condos and Townhomes',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale],
            fields: [
              {
                name: 'typeOfHome-condo-Condo',
                inputType: InputTypes.Checkbox,
                label: 'Condo',
              },
              {
                name: 'typeOfHome-condo-Townhouse',
                inputType: InputTypes.Checkbox,
                label: 'Townhouse',
              },
            ]
          },
        ]
      },
      {
        fieldsetTitle: 'Stories / Levels',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'storiesLevels-1Story',
                inputType: InputTypes.Checkbox,
                label: '1 Story',
              },
              {
                name: 'storiesLevels-15Stories',
                inputType: InputTypes.Checkbox,
                label: '1.5 Stories',
              },
              {
                name: 'storiesLevels-2Stories',
                inputType: InputTypes.Checkbox,
                label: '2 Stories',
              },
              {
                name: 'storiesLevels-3StoriesOrMore',
                inputType: InputTypes.Checkbox,
                label: '3 Stories or More',
              },
              {
                name: 'storiesLevels-1FloorUnit',
                inputType: InputTypes.Checkbox,
                label: '1st Floor Unit',
              },
              {
                name: 'storiesLevels-2FloorUnit',
                inputType: InputTypes.Checkbox,
                label: '2nd Floor Unit',
              },
              {
                name: 'storiesLevels-3FloorUnit',
                inputType: InputTypes.Checkbox,
                label: '3rd Floor Unit',
              },
              {
                name: 'storiesLevels-4FloorOrHigherUnit',
                inputType: InputTypes.Checkbox,
                label: '4th Floor or Higher Unit',
              },
              {
                name: 'storiesLevels-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement',
              },
              {
                name: 'storiesLevels-EndUnit',
                inputType: InputTypes.Checkbox,
                label: 'End Unit',
              },
              {
                name: 'storiesLevels-Ground',
                inputType: InputTypes.Checkbox,
                label: 'Ground',
              },
              {
                name: 'storiesLevels-Interior',
                inputType: InputTypes.Checkbox,
                label: 'Interior',
              },
              {
                name: 'storiesLevels-HalfBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Half Building',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Design of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'storiesLevels-BiLevel',
                inputType: InputTypes.Checkbox,
                label: 'Bi-Level',
              },
              {
                name: 'storiesLevels-MultiSplit',
                inputType: InputTypes.Checkbox,
                label: 'Multi/Split',
              },
              {
                name: 'storiesLevels-1Level',
                inputType: InputTypes.Checkbox,
                label: '1 Level',
              },
              {
                name: 'storiesLevels-15Level',
                inputType: InputTypes.Checkbox,
                label: '1.5 Levels',
              },
              {
                name: 'storiesLevels-2Levels',
                inputType: InputTypes.Checkbox,
                label: '2 Levels',
              },
              {
                name: 'storiesLevels-25Levels',
                inputType: InputTypes.Checkbox,
                label: '2.5 Levels',
              },
              {
                name: 'storiesLevels-3Levels',
                inputType: InputTypes.Checkbox,
                label: '3 or More Levels',
              },

              {
                name: 'storiesLevels-TriLevel',
                inputType: InputTypes.Checkbox,
                label: 'Tri-Level',
              },
              {
                name: 'storiesLevels-QuadLevel',
                inputType: InputTypes.Checkbox,
                label: 'Quad Level',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Stories / Levels',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'storiesLevels-1Story',
                inputType: InputTypes.Checkbox,
                label: '1 Story',
              },
              {
                name: 'storiesLevels-15Story',
                inputType: InputTypes.Checkbox,
                label: '1.5 Story',
              },
              {
                name: 'storiesLevels-2Story',
                inputType: InputTypes.Checkbox,
                label: '2 Story',
              },
              {
                name: 'storiesLevels-3Story',
                inputType: InputTypes.Checkbox,
                label: '3 Story',
              },
              {
                name: 'storiesLevels-HighRise',
                inputType: InputTypes.Checkbox,
                label: 'High Rise',
              },
              {
                name: 'storiesLevels-MultiStory',
                inputType: InputTypes.Checkbox,
                label: 'Multi-Story',
              },
              {
                name: 'storiesLevels-SplitLevel',
                inputType: InputTypes.Checkbox,
                label: 'Split-Level',
              },
              {
                name: 'storiesLevels-TriLevel',
                inputType: InputTypes.Checkbox,
                label: 'Tri-Level',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Stories / Levels',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale] },
        required: true,
        inputGroups: [
          {
            groupTitle: 'Detached Homes',
            fields: [
              {
                name: 'storiesLevels-DetachedHomes-1',
                inputType: InputTypes.Checkbox,
                label: 'Single Level',
              },
              {
                name: 'storiesLevels-DetachedHomes-2',
                inputType: InputTypes.Checkbox,
                label: 'Split Level',
              },
              {
                name: 'storiesLevels-DetachedHomes-3',
                inputType: InputTypes.Checkbox,
                label: 'One and One Half',
              },
              {
                name: 'storiesLevels-DetachedHomes-4',
                inputType: InputTypes.Checkbox,
                label: 'Two-Story',
              },
            ]
          },
          {
            groupTitle: 'Condos and Townhomes',
            fields: [
              {
                name: 'storiesLevels-CondosAndTownhomes-1',
                inputType: InputTypes.Checkbox,
                label: 'One Story',
              },
              {
                name: 'storiesLevels-CondosAndTownhomes-2',
                inputType: InputTypes.Checkbox,
                label: 'Low Rise 2-3 Stories',
              },
              {
                name: 'storiesLevels-CondosAndTownhomes-3',
                inputType: InputTypes.Checkbox,
                label: 'Mid Rise 4-6 Stories',
              },
              {
                name: 'storiesLevels-CondosAndTownhomes-4',
                inputType: InputTypes.Checkbox,
                label: 'High Rise 7+ Stories',
              },
              {
                name: 'storiesLevels-CondosAndTownhomes-5',
                inputType: InputTypes.Checkbox,
                label: 'Townhouse',
              },
            ]
          },
        ]
      },
      {
        fieldsetTitle: 'Style of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'styleOfHome-Aframe',
                inputType: InputTypes.Checkbox,
                label: 'A-frame'
              },
              {
                name: 'styleOfHome-Adirondack',
                inputType: InputTypes.Checkbox,
                label: 'Adirondack'
              },
              {
                name: 'styleOfHome-Brick3Side',
                inputType: InputTypes.Checkbox,
                label: 'Brick 3 Side'
              },
              {
                name: 'styleOfHome-Brick4Side',
                inputType: InputTypes.Checkbox,
                label: 'Brick 4 Side'
              },
              {
                name: 'styleOfHome-BrickFront',
                inputType: InputTypes.Checkbox,
                label: 'Brick Front'
              },
              {
                name: 'styleOfHome-BrickFrame',
                inputType: InputTypes.Checkbox,
                label: 'Brick/Frame'
              },
              {
                name: 'styleOfHome-BungalowCottage',
                inputType: InputTypes.Checkbox,
                label: 'Bungalow/Cottage'
              },
              {
                name: 'styleOfHome-CapeCod',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod'
              },
              {
                name: 'styleOfHome-Cluster',
                inputType: InputTypes.Checkbox,
                label: 'Cluster'
              },
              {
                name: 'styleOfHome-Colonial',
                inputType: InputTypes.Checkbox,
                label: 'Colonial'
              },
              {
                name: 'styleOfHome-Contemporary',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary'
              },
              {
                name: 'styleOfHome-CountryRustic',
                inputType: InputTypes.Checkbox,
                label: 'Country/Rustic'
              },
              {
                name: 'styleOfHome-Craftsman',
                inputType: InputTypes.Checkbox,
                label: 'Craftsman'
              },
              {
                name: 'styleOfHome-European',
                inputType: InputTypes.Checkbox,
                label: 'European'
              },
              {
                name: 'styleOfHome-FrenchProvincial',
                inputType: InputTypes.Checkbox,
                label: 'French Provincial'
              },
              {
                name: 'styleOfHome-Mediterranean',
                inputType: InputTypes.Checkbox,
                label: 'Mediterranean'
              },
              {
                name: 'styleOfHome-ModularHome',
                inputType: InputTypes.Checkbox,
                label: 'Modular Home'
              },
              {
                name: 'styleOfHome-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'styleOfHome-Ranch',
                inputType: InputTypes.Checkbox,
                label: 'Ranch'
              },
              {
                name: 'styleOfHome-StoneFrame',
                inputType: InputTypes.Checkbox,
                label: 'Stone Frame'
              },
              {
                name: 'styleOfHome-Traditional',
                inputType: InputTypes.Checkbox,
                label: 'Traditional'
              },
              {
                name: 'styleOfHome-Tudor',
                inputType: InputTypes.Checkbox,
                label: 'Tudor'
              },
              {
                name: 'styleOfHome-Victorian',
                inputType: InputTypes.Checkbox,
                label: 'Victorian'
              },
            ]
          },
        ]
      },
      {
        fieldsetTitle: 'Style of Home',
        fieldsetDescription: `
          <a href="/pdfs/BrightMLSStyle.pdf" class="link" target="_blank">
          Click Here</a> for Bright MLS Style Definitions.
        `,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            // groupTitle: '',
            // groupDescription: '',
            fields: [
              {
                name: 'styleOfHome-1',
                inputType: InputTypes.Checkbox,
                label: 'A-Frame',
              },
              {
                name: 'styleOfHome-2',
                inputType: InputTypes.Checkbox,
                label: 'AirLite',
              },
              {
                name: 'styleOfHome-3',
                inputType: InputTypes.Checkbox,
                label: 'Art Deco',
              },
              {
                name: 'styleOfHome-4',
                inputType: InputTypes.Checkbox,
                label: 'Back to Back',
              },
              {
                name: 'styleOfHome-5',
                inputType: InputTypes.Checkbox,
                label: 'Beaux Arts',
              },
              {
                name: 'styleOfHome-6',
                inputType: InputTypes.Checkbox,
                label: 'Bi-Level',
              },
              {
                name: 'styleOfHome-7',
                inputType: InputTypes.Checkbox,
                label: 'Bungalow',
              },
              {
                name: 'styleOfHome-8',
                inputType: InputTypes.Checkbox,
                label: 'Cabin / Lodge',
              },
              {
                name: 'styleOfHome-9',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod',
              },
              {
                name: 'styleOfHome-10',
                inputType: InputTypes.Checkbox,
                label: 'Carriage House',
              },
              {
                name: 'styleOfHome-11',
                inputType: InputTypes.Checkbox,
                label: 'Chalet',
              },
              {
                name: 'styleOfHome-12',
                inputType: InputTypes.Checkbox,
                label: 'Coastal',
              },
              {
                name: 'styleOfHome-13',
                inputType: InputTypes.Checkbox,
                label: 'Colonial',
              },
              {
                name: 'styleOfHome-14',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary',
              },
              {
                name: 'styleOfHome-15',
                inputType: InputTypes.Checkbox,
                label: 'Converted Barn',
              },
              {
                name: 'styleOfHome-16',
                inputType: InputTypes.Checkbox,
                label: 'Converted Dwelling',
              },
              {
                name: 'styleOfHome-17',
                inputType: InputTypes.Checkbox,
                label: 'Cottage',
              },
              {
                name: 'styleOfHome-18',
                inputType: InputTypes.Checkbox,
                label: 'Craftsman',
              },
              {
                name: 'styleOfHome-19',
                inputType: InputTypes.Checkbox,
                label: 'Dome',
              },
              {
                name: 'styleOfHome-20',
                inputType: InputTypes.Checkbox,
                label: 'Dutch',
              },
              {
                name: 'styleOfHome-21',
                inputType: InputTypes.Checkbox,
                label: 'Dwelling with Sep Living',
              },
              {
                name: 'styleOfHome-22',
                inputType: InputTypes.Checkbox,
                label: 'Farm House / National Fork',
              },
              {
                name: 'styleOfHome-23',
                inputType: InputTypes.Checkbox,
                label: 'Federal',
              },
              {
                name: 'styleOfHome-24',
                inputType: InputTypes.Checkbox,
                label: 'French',
              },
              {
                name: 'styleOfHome-25',
                inputType: InputTypes.Checkbox,
                label: 'Georgian',
              },
              {
                name: 'styleOfHome-26',
                inputType: InputTypes.Checkbox,
                label: 'Loft',
              },
              {
                name: 'styleOfHome-27',
                inputType: InputTypes.Checkbox,
                label: 'Loft with Bedrooms',
              },
              {
                name: 'styleOfHome-28',
                inputType: InputTypes.Checkbox,
                label: 'Log Home',
              },
              {
                name: 'styleOfHome-29',
                inputType: InputTypes.Checkbox,
                label: 'Manor',
              },
              {
                name: 'styleOfHome-30',
                inputType: InputTypes.Checkbox,
                label: 'Mediterranean',
              },
              {
                name: 'styleOfHome-31',
                inputType: InputTypes.Checkbox,
                label: 'Modular / Pre-Fabricated',
              },
              {
                name: 'styleOfHome-32',
                inputType: InputTypes.Checkbox,
                label: 'Normandy',
              },
              {
                name: 'styleOfHome-33',
                inputType: InputTypes.Checkbox,
                label: 'Post and Beam',
              },
              {
                name: 'styleOfHome-34',
                inputType: InputTypes.Checkbox,
                label: 'Prairie',
              },
              {
                name: 'styleOfHome-35',
                inputType: InputTypes.Checkbox,
                label: 'Raised Ranch / Rambler',
              },
              {
                name: 'styleOfHome-36',
                inputType: InputTypes.Checkbox,
                label: 'Ranch / Rambler',
              },
              {
                name: 'styleOfHome-37',
                inputType: InputTypes.Checkbox,
                label: 'Reverse',
              },
              {
                name: 'styleOfHome-38',
                inputType: InputTypes.Checkbox,
                label: 'Salt Box',
              },
              {
                name: 'styleOfHome-39',
                inputType: InputTypes.Checkbox,
                label: 'Side By Side',
              },
              {
                name: 'styleOfHome-40',
                inputType: InputTypes.Checkbox,
                label: 'Spanish',
              },
              {
                name: 'styleOfHome-41',
                inputType: InputTypes.Checkbox,
                label: 'Split Foyer',
              },
              {
                name: 'styleOfHome-42',
                inputType: InputTypes.Checkbox,
                label: 'Split Level',
              },
              {
                name: 'styleOfHome-43',
                inputType: InputTypes.Checkbox,
                label: 'Traditional',
              },
              {
                name: 'styleOfHome-44',
                inputType: InputTypes.Checkbox,
                label: 'Transitional',
              },
              {
                name: 'styleOfHome-45',
                inputType: InputTypes.Checkbox,
                label: 'Tudor',
              },
              {
                name: 'styleOfHome-46',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                inputType: InputTypes.Checkbox,
                label: 'Unit / Flat',
              },
              {
                name: 'styleOfHome-47',
                inputType: InputTypes.Checkbox,
                label: 'Victorian',
              },
              {
                name: 'styleOfHome-48',
                inputType: InputTypes.Checkbox,
                label: 'Villa',
              },
              {
                name: 'styleOfHome-49',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
            ]
          },
          {
            groupTitle: 'Condos Only',
            fields: [
              {
                name: 'styleOfHome-CondosOnly-1',
                inputType: InputTypes.Checkbox,
                label: 'Garden 1-4 Floors',
              },
              {
                name: 'styleOfHome-CondosOnly-2',
                inputType: InputTypes.Checkbox,
                label: 'Mid Rise 5-8 Floors',
              },
              {
                name: 'styleOfHome-CondosOnly-3',
                inputType: InputTypes.Checkbox,
                label: 'High Rise 9+ Floors',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Style of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'styleOfHome-1',
                inputType: InputTypes.Checkbox,
                label: '2-Story',
              },
              {
                name: 'styleOfHome-2',
                inputType: InputTypes.Checkbox,
                label: 'A-Frame',
              },
              {
                name: 'styleOfHome-3',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod',
              },
              {
                name: 'styleOfHome-4',
                inputType: InputTypes.Checkbox,
                label: 'Colonial',
              },
              {
                name: 'styleOfHome-5',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary',
              },
              {
                name: 'styleOfHome-6',
                inputType: InputTypes.Checkbox,
                label: 'Cottage / Bungalow',
              },
              {
                name: 'styleOfHome-7',
                inputType: InputTypes.Checkbox,
                label: 'Craftsman',
              },
              {
                name: 'styleOfHome-8',
                inputType: InputTypes.Checkbox,
                label: 'Custom',
              },
              {
                name: 'styleOfHome-9',
                inputType: InputTypes.Checkbox,
                label: 'Dutch Colonial',
              },
              {
                name: 'styleOfHome-10',
                inputType: InputTypes.Checkbox,
                label: 'Farm House',
              },
              {
                name: 'styleOfHome-11',
                inputType: InputTypes.Checkbox,
                label: 'Green Certified Home',
              },
              {
                name: 'styleOfHome-12',
                inputType: InputTypes.Checkbox,
                label: 'Log',
              },
              {
                name: 'styleOfHome-13',
                inputType: InputTypes.Checkbox,
                label: 'Manufactured Home',
              },
              {
                name: 'styleOfHome-14',
                inputType: InputTypes.Checkbox,
                label: 'Mediterranean/Spanish',
              },
              {
                name: 'styleOfHome-15',
                inputType: InputTypes.Checkbox,
                label: 'Mid-Century Modern',
              },
              {
                name: 'styleOfHome-16',
                inputType: InputTypes.Checkbox,
                label: 'Modern',
              },
              {
                name: 'styleOfHome-17',
                inputType: InputTypes.Checkbox,
                label: 'Modular',
              },
              {
                name: 'styleOfHome-18',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
              {
                name: 'styleOfHome-19',
                inputType: InputTypes.Checkbox,
                label: 'Patio Home',
              },
              {
                name: 'styleOfHome-20',
                inputType: InputTypes.Checkbox,
                label: 'Ranch',
              },
              {
                name: 'styleOfHome-21',
                inputType: InputTypes.Checkbox,
                label: 'Rowhouse/Townhouse',
              },
              {
                name: 'styleOfHome-22',
                inputType: InputTypes.Checkbox,
                label: 'Saltbox',
              },
              {
                name: 'styleOfHome-23',
                inputType: InputTypes.Checkbox,
                label: 'Split Foyer',
              },
              {
                name: 'styleOfHome-24',
                inputType: InputTypes.Checkbox,
                label: 'Transitional',
              },
              {
                name: 'styleOfHome-25',
                inputType: InputTypes.Checkbox,
                label: 'Tri-Level/Quad Level',
              },
              {
                name: 'styleOfHome-26',
                inputType: InputTypes.Checkbox,
                label: 'Tudor',
              },
              {
                name: 'styleOfHome-27',
                inputType: InputTypes.Checkbox,
                label: 'Victorian',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Style of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'styleOfHome-1',
                inputType: InputTypes.Checkbox,
                label: 'A-Frame',
              },
              {
                name: 'styleOfHome-2',
                inputType: InputTypes.Checkbox,
                label: 'Arts and Crafts',
              },
              {
                name: 'styleOfHome-3',
                inputType: InputTypes.Checkbox,
                label: 'Cabin',
              },
              {
                name: 'styleOfHome-4',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod',
              },
              {
                name: 'styleOfHome-5',
                inputType: InputTypes.Checkbox,
                label: 'Charleston',
              },
              {
                name: 'styleOfHome-6',
                inputType: InputTypes.Checkbox,
                label: 'Colonial',
              },
              {
                name: 'styleOfHome-7',
                inputType: InputTypes.Checkbox,
                label: 'Cottage',
              },
              {
                name: 'styleOfHome-8',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary',
              },
              {
                name: 'styleOfHome-9',
                inputType: InputTypes.Checkbox,
                label: 'French Provincial',
              },
              {
                name: 'styleOfHome-10',
                inputType: InputTypes.Checkbox,
                label: 'Garden',
              },
              {
                name: 'styleOfHome-11',
                inputType: InputTypes.Checkbox,
                label: 'Georgian',
              },
              {
                name: 'styleOfHome-12',
                inputType: InputTypes.Checkbox,
                label: 'Post and Beam',
              },
              {
                name: 'styleOfHome-13',
                inputType: InputTypes.Checkbox,
                label: 'Ranch',
              },
              {
                name: 'styleOfHome-14',
                inputType: InputTypes.Checkbox,
                label: 'Rustic',
              },
              {
                name: 'styleOfHome-15',
                inputType: InputTypes.Checkbox,
                label: 'Spanish',
              },
              {
                name: 'styleOfHome-16',
                inputType: InputTypes.Checkbox,
                label: 'Traditional',
              },
              {
                name: 'styleOfHome-17',
                inputType: InputTypes.Checkbox,
                label: 'Transitional',
              },
              {
                name: 'styleOfHome-18',
                inputType: InputTypes.Checkbox,
                label: 'Tudor',
              },
              {
                name: 'styleOfHome-19',
                inputType: InputTypes.Checkbox,
                label: 'Victorian',
              },
              {
                name: 'styleOfHome-20',
                inputType: InputTypes.Checkbox,
                label: 'Williamsburg',
              },
              {
                name: 'styleOfHome-21',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Style of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'styleOfHome-1',
                inputType: InputTypes.Checkbox,
                label: 'A-Frame',
              },
              {
                name: 'styleOfHome-2',
                inputType: InputTypes.Checkbox,
                label: 'Arts and Crafts',
              },
              {
                name: 'styleOfHome-3',
                inputType: InputTypes.Checkbox,
                label: 'Bungalow',
              },
              {
                name: 'styleOfHome-4',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod',
              },
              {
                name: 'styleOfHome-5',
                inputType: InputTypes.Checkbox,
                label: 'Charleston',
              },
              {
                name: 'styleOfHome-6',
                inputType: InputTypes.Checkbox,
                label: 'Colonial',
              },
              {
                name: 'styleOfHome-7',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary',
              },
              {
                name: 'styleOfHome-8',
                inputType: InputTypes.Checkbox,
                label: 'Craftsman',
              },
              {
                name: 'styleOfHome-9',
                inputType: InputTypes.Checkbox,
                label: 'Dutch Colonial',
              },
              {
                name: 'styleOfHome-10',
                inputType: InputTypes.Checkbox,
                label: 'Farm House',
              },
              {
                name: 'styleOfHome-12',
                inputType: InputTypes.Checkbox,
                label: 'French Province',
              },
              {
                name: 'styleOfHome-13',
                inputType: InputTypes.Checkbox,
                label: 'Geodesic',
              },
              {
                name: 'styleOfHome-14',
                inputType: InputTypes.Checkbox,
                label: 'Georgian',
              },
              {
                name: 'styleOfHome-15',
                inputType: InputTypes.Checkbox,
                label: 'Local Historic Designation',
              },
              {
                name: 'styleOfHome-16',
                inputType: InputTypes.Checkbox,
                label: 'Log Home',
              },
              {
                name: 'styleOfHome-17',
                inputType: InputTypes.Checkbox,
                label: 'National Historic Designation',
              },
              {
                name: 'styleOfHome-19',
                inputType: InputTypes.Checkbox,
                label: 'Ranch',
              },
              {
                name: 'styleOfHome-20',
                inputType: InputTypes.Checkbox,
                label: 'Rustic',
              },
              {
                name: 'styleOfHome-21',
                inputType: InputTypes.Checkbox,
                label: 'Spanish',
              },
              {
                name: 'styleOfHome-17',
                inputType: InputTypes.Checkbox,
                label: 'State Historic Designation',
              },
              {
                name: 'styleOfHome-22',
                inputType: InputTypes.Checkbox,
                label: 'Traditional',
              },
              {
                name: 'styleOfHome-23',
                inputType: InputTypes.Checkbox,
                label: 'Transitional',
              },
              {
                name: 'styleOfHome-24',
                inputType: InputTypes.Checkbox,
                label: 'Tudor',
              },
              {
                name: 'styleOfHome-25',
                inputType: InputTypes.Checkbox,
                label: 'Victorian',
              },
              {
                name: 'styleOfHome-26',
                inputType: InputTypes.Checkbox,
                label: 'Williamsburg',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Style of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'styleOfHome-1',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod',
              },
              {
                name: 'styleOfHome-2',
                inputType: InputTypes.Checkbox,
                label: 'Carriage/Kitchen House',
              },
              {
                name: 'styleOfHome-3',
                inputType: InputTypes.Checkbox,
                label: 'Charleston Single',
              },
              {
                name: 'styleOfHome-4',
                inputType: InputTypes.Checkbox,
                label: 'Colonial',
              },
              {
                name: 'styleOfHome-5',
                inputType: InputTypes.Checkbox,
                label: 'Condo Regime',
              },
              {
                name: 'styleOfHome-6',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary',
              },
              {
                name: 'styleOfHome-7',
                inputType: InputTypes.Checkbox,
                label: 'Cottage',
              },
              {
                name: 'styleOfHome-8',
                inputType: InputTypes.Checkbox,
                label: 'Craftsman',
              },
              {
                name: 'styleOfHome-9',
                inputType: InputTypes.Checkbox,
                label: 'Horse Farm',
              },
              {
                name: 'styleOfHome-10',
                inputType: InputTypes.Checkbox,
                label: 'Live/Work',
              },
              {
                name: 'styleOfHome-11',
                inputType: InputTypes.Checkbox,
                label: 'Loft',
              },
              {
                name: 'styleOfHome-12',
                inputType: InputTypes.Checkbox,
                label: 'Log Home',
              },
              {
                name: 'styleOfHome-13',
                inputType: InputTypes.Checkbox,
                label: 'Patio',
              },
              {
                name: 'styleOfHome-14',
                inputType: InputTypes.Checkbox,
                label: 'Ranch',
              },
              {
                name: 'styleOfHome-15',
                inputType: InputTypes.Checkbox,
                label: 'Spanish',
              },
              {
                name: 'styleOfHome-16',
                inputType: InputTypes.Checkbox,
                label: 'Traditional',
              },
              {
                name: 'styleOfHome-17',
                inputType: InputTypes.Checkbox,
                label: 'Tudor',
              },
              {
                name: 'styleOfHome-18',
                inputType: InputTypes.Checkbox,
                label: 'Victorian',
              },
              {
                name: 'styleOfHome-19',
                inputType: InputTypes.Checkbox,
                label: 'Villa',
              },
              {
                name: 'styleOfHome-20',
                inputType: InputTypes.Checkbox,
                label: 'None',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Structure Type',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'structureType-Cabin',
                inputType: InputTypes.Checkbox,
                label: 'Cabin'
              },
              {
                name: 'structureType-GardenPatioHome',
                inputType: InputTypes.Checkbox,
                label: 'Garden/Patio Home'
              },
              {
                name: 'structureType-HighRise',
                inputType: InputTypes.Checkbox,
                label: 'High Rise'
              },
              {
                name: 'structureType-House',
                inputType: InputTypes.Checkbox,
                label: 'House'
              },
              {
                name: 'structureType-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'structureType-ManufacturedHouse',
                inputType: InputTypes.Checkbox,
                label: 'Manufactured House'
              },
              {
                name: 'structureType-MidRise',
                inputType: InputTypes.Checkbox,
                label: 'Mid Rise'
              },
              {
                name: 'structureType-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Condo Location',
        required: 'Required for Condos',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'condoLocation-1',
                inputType: InputTypes.Checkbox,
                label: 'Corner Unit',
              },
              {
                name: 'condoLocation-2',
                inputType: InputTypes.Checkbox,
                label: 'Detached',
              },
              {
                name: 'condoLocation-3',
                inputType: InputTypes.Checkbox,
                label: 'End Unit',
              },
              {
                name: 'condoLocation-4',
                inputType: InputTypes.Checkbox,
                label: 'Interior Unit',
              },
              {
                name: 'condoLocation-5',
                inputType: InputTypes.Checkbox,
                label: 'Lower Level',
              },
              {
                name: 'condoLocation-6',
                inputType: InputTypes.Checkbox,
                label: 'Middle Level',
              },
              {
                name: 'condoLocation-7',
                inputType: InputTypes.Checkbox,
                label: 'Street Level',
              },
              {
                name: 'condoLocation-8',
                inputType: InputTypes.Checkbox,
                label: 'Top Level',
              },
              {
                name: 'condoLocation-9',
                inputType: InputTypes.Checkbox,
                label: 'Walkout',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Square Footage',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'finishedSquareFootageAboveGroundHeated',
            inputType: InputTypes.Number,
            required: true,
            label: 'Above Ground Heated / Finished Square Footage',
            placeholder: 'Finished SqFt Above'
          },
          {
            name: 'finishedSquareFootageBelowGroundHeated',
            inputType: InputTypes.Number,
            required: false,
            label: 'Below Ground Heated / Finished Square Footage',
            placeholder: 'Example: Basement'
          },
          {
            name: 'unfinishedSquareFootageAboveGroundUnheated',
            inputType: InputTypes.Number,
            required: false,
            label: 'Above Ground UNHEATED / UNFINISHED Square Footage',
            placeholder: 'Example: Garage'
          },
          {
            name: 'unfinishedSquareFootageBelowGroundUnheated',
            inputType: InputTypes.Number,
            required: false,
            label: 'Below Ground UNHEATED / UNFINISHED Square Footage',
            placeholder: 'Example: Unfinished Basement'
          },
        ]
      },
      {
        fieldsetTitle: '',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'finishedSquareFootage',
            inputType: InputTypes.Text,
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            label: 'Finished Square Footage'
          },
          {
            name: 'numberOfRooms',
            inputType: InputTypes.Number,
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            label: 'Number of Rooms'
          },
          {
            name: 'numbeOfBedrooms',
            inputType: InputTypes.Number,
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
            label: 'Number of Bedrooms'
          },
          {
            name: 'numbeOfBaths',
            inputType: InputTypes.Number,
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeRent] },
            label: 'Number of Baths'
          },
        ]

      },
      {
        fieldsetTitle: 'Number of Bedrooms',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        fields: [
          {
            name: 'lowerLevelBedrooms',
            inputType: InputTypes.Number,
            label: 'Lower Level Bedrooms'
          },
          {
            name: 'mainLevelBedrooms',
            inputType: InputTypes.Number,
            label: 'Main Level Bedrooms'
          },
          {
            name: 'upperLevelBedrooms',
            inputType: InputTypes.Number,
            label: 'Upper Level Bedrooms'
          },
          {
            name: 'upperLevelTwoBedrooms',
            inputType: InputTypes.Number,
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent]
            },
            label: 'Upper Level 2 Bedrooms'
          },
        ]
      },
      {
        fieldsetTitle: {
          default: 'Number of Full Bathrooms',
          scope: Scopes.ByMLSArea,
          areas: { [Areas.CentralVA]: 'Levels and Types of Full Bathrooms' }
        },
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.CentralVA]: [PropertyTypes.HomeSale],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        fields: [
          {
            name: 'lowerLevelFullBaths',
            inputType: InputTypes.Number,
            label: 'Lower Level Full Baths'
          },
          {
            name: 'lowerLevelFullBathsDescription',
            inputType: InputTypes.Select,
            options: ['Shower', 'Tub', 'Tub and Shower'],
            required: 'Required if Basement Baths',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
            label: 'Description'
          },
          {
            name: 'mainLevelFullBaths',
            inputType: InputTypes.Number,
            label: 'Main Level Full Baths'
          },
          {
            name: 'mainLevelFullBathsDescription',
            inputType: InputTypes.Select,
            options: ['Shower', 'Tub', 'Tub and Shower'],
            required: 'Required if Main Level Baths',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
            label: 'Desrciption'
          },
          {
            name: 'upperLevelFullBaths',
            inputType: InputTypes.Number,
            label: 'Upper Level Full Baths'
          },
          {
            name: 'upperLevelFullBathsDescription',
            inputType: InputTypes.Select,
            options: ['Shower', 'Tub', 'Tub and Shower'],
            required: 'Required if Upper Level Baths',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
            label: 'Description'
          },
          {
            name: 'upperLevelTwoFullBaths',
            inputType: InputTypes.Number,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.CentralVA]: [PropertyTypes.HomeSale],
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent]
            },
            label: 'Upper Level 2 Full Baths'
          },
          {
            name: 'upperLevelTwoFullBathsDescription',
            inputType: InputTypes.Select,
            options: ['Shower', 'Tub', 'Tub and Shower'],
            required: 'Required if Upper Level 2 Baths',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
            label: 'Desrciption'
          },
        ]
      },
      {
        fieldsetTitle: 'Number of Half Bathrooms',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.CentralVA]: [PropertyTypes.HomeSale],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        fields: [
          {
            name: 'lowerLevelHalfBaths',
            inputType: InputTypes.Number,
            label: 'Lower Level Half Baths'
          },
          {
            name: 'mainLevelHalfBaths',
            inputType: InputTypes.Number,
            label: 'Main Level Half Baths'
          },
          {
            name: 'upperLevelHalfBaths',
            inputType: InputTypes.Number,
            label: 'Upper Level Half Baths'
          },
          {
            name: 'upperLevelTwoHalfBaths',
            inputType: InputTypes.Number,
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.CentralVA]: [PropertyTypes.HomeSale],
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            label: 'Upper Level 2 Half Baths'
          },
        ]
      },


      {
        fieldsetTitle: '',
        fieldsetDescription: {
          default: '',
          scope: Scopes.ByState,
          states: {
            [States.NorthCarolina]: `
              <a href="/info/residential-square-footage" class="link" 
              target="_blank">Click Here</a> for Square Footage Guidelines
            `
          }
        },
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.Charlotte]: [PropertyTypes.HomeSale],
          [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Triangle]: [PropertyTypes.HomeSale],
          [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        required: {
          default: '',
          scope: Scopes.IndividualMapping,
          mapping: {
            [Areas.Charlotte]: {
              [PropertyTypes.HomeSale]: 'Square Footage Amount is Required for Each Level of the Home - Both Heated and Unheated',
            },
          },
        },
        fields: [
          {
            name: 'livingAreaAboveGround',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
            label: 'Above Ground Finished Area',
            required: true,
          },
          {
            name: 'livingAreaBelowGround',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
            label: 'Below Ground Finished Area',
            required: true,
          },
          {
            name: 'otherAreaAboveGround',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
            label: 'Above Ground Unfinished Area',
            required: true,
          },
          {
            name: 'otherAreaBelowGround',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
            label: 'Below Ground Unfinished Area',
            required: true,
          },
          {
            name: 'totalHeatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale],
              [Areas.NCRegion]: [PropertyTypes.HomeSale],
              [Areas.Charleston]: [PropertyTypes.HomeSale],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            label: 'Total Heated Square Footage',
            required: true,
          },
          {
            name: 'totalHeatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.HomeRent],
            },
            label: 'Heated Square Footage',
          },
          {
            name: 'totalSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charleston]: [PropertyTypes.HomeRent],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            label: 'Total Square Footage',
            required: true,
          },
          {
            name: 'totalNumberBedrooms',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale],
              [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            label: 'Total Number of Bedrooms',
            required: true,
          },
          {
            name: 'totalNumberFullBaths',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale],
              [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            label: 'Total Number of Full Baths',
            required: true,
          },
          {
            name: 'totalNumberHalfBaths',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale],
              [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            label: 'Total Number of Half Baths',
            required: true,
          },
          {
            name: 'totalNumberRooms',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale],
            },
            label: 'Total Number of Rooms',
            required: true,
          },
          {
            name: 'totalNumberStoriesInBuilding',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            },
            label: 'Total Number of Stories in Building',
            required: true,
          },
          {
            name: 'mainLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            label: 'Main Level Heated Square Footage',
            required: true,
          },
          {
            name: 'upperLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            label: 'Upper Level Heated Square Footage',
            required: true,
          },
          {
            name: 'thirdLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale],
            },
            label: 'Third Level Heated Square Footage',
            required: {
              default: false,
              scope: Scopes.IndividualMapping,
              mapping: {
                [Areas.Charlotte]: {[PropertyTypes.HomeSale]: true}
              }
            },
          },
          {
            name: 'lowerLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale],
            },
            label: 'Lower Level Heated Square Footage',
            required: {
              default: false,
              scope: Scopes.IndividualMapping,
              mapping: {
                [Areas.Charlotte]: {[PropertyTypes.HomeSale]: true}
              }
            },
          },
          {
            name: 'basementLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale],
            },
            label: 'Basement Level Heated Square Footage',
            required: {
              default: false,
              scope: Scopes.IndividualMapping,
              mapping: {
                [Areas.Charlotte]: {[PropertyTypes.HomeSale]: true}
              }
            },
          },
          {
            name: 'totalUnheatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            label: 'Total UNHeated Square Footage',
            required: true,
          },
          {
            name: 'mainLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            placeholder: 'Example: Garage SqFt',
            label: 'Main Level UNHeated Square Footage',
            required: true,
          },
          {
            name: 'upperLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            label: 'Upper Level UNHeated Square Footage',
            required: true,
          },
          {
            name: 'thirdLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
            label: 'Third Level UNHeated Square Footage',
            required: true,
          },
          {
            name: 'lowerLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale],
            },
            label: 'Lower Level UNHeated Square Footage',
            required: {
              default: false,
              scope: Scopes.IndividualMapping,
              mapping: {
                [Areas.Charlotte]: {[PropertyTypes.HomeSale]: true}
              }
            },
          },
          {
            name: 'basementLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale],
            },
            label: 'Basement Level UNHeated Square Footage',
            required: {
              default: false,
              scope: Scopes.IndividualMapping,
              mapping: {
                [Areas.Charlotte]: {[PropertyTypes.HomeSale]: true}
              }
            },
          },
        ]
      },

      // Triad Sqft
      {
        fieldsetDescription: {
          default: '',
          scope: Scopes.ByState,
          states: {
            [States.NorthCarolina]: `
              <a href="/info/residential-square-footage" class="link" 
              target="_blank">Click Here</a> for Square Footage Guidelines
            `
          }
        },
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Triad]: [PropertyTypes.HomeSale],
        },
        required: 'Required for Each Level in the Home',
        fields: [
          {
            name: 'mainLevelFinishedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Main Level Finished Sqft',
            required: true,
          },
          {
            name: 'secondUpperLevelFinishedSquareFootage',
            inputType: InputTypes.Text,
            label: '2nd Upper Level Finished Sqft',
          },
          {
            name: 'thirdLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Third Level Heated Square Footage',
          },
          {
            name: 'lowerLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Lower Level Heated Square Footage',
          },
          {
            name: 'basementLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Basement Level Heated Square Footage',
          },
          {
            name: 'lowerLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Lower Level UNHeated Square Footage',
          },
          {
            name: 'basementLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Basement Level UNHeated Square Footage',
          },
        ]
      },
      // Triad number of rooms
      {
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        fields: [
          {
            name: 'totalNumberRooms',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeSale],
            },
            label: 'Total Number of Rooms',
          },
          {
            name: 'totalNumberBedrooms',
            inputType: InputTypes.Text,
            label: 'Number of Bedrooms',
            required: true,
          },
          {
            name: 'totalNumberFullBaths',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Triad]: [PropertyTypes.HomeRent],
            },
            label: 'Total Number of Full Baths',
            required: true,
          },
          {
            name: 'totalNumberHalfBaths',
            inputType: InputTypes.Text,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Triad]: [PropertyTypes.HomeRent],
            },
            label: 'Total Number of Half Baths',
            required: true,
          },
        ]
      },
      // Triad number of rooms 2
      {
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.Triad]: [PropertyTypes.HomeSale],
        },
        fields: [
          {
            name: 'totalNumberFullBaths',
            inputType: InputTypes.Text,
            label: 'Total Number of Full Baths',
            required: true,
          },
          {
            name: 'mainLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Main Level',
          },
          {
            name: 'upperLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Upper Level',
          },
          {
            name: 'lowerLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Lower Level',
          },
          {
            name: 'totalNumberHalfBaths',
            inputType: InputTypes.Text,
            label: 'Total Number of Half Baths',
            required: true,
          },
          {
            name: 'mainLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Main Level',
          },
          {
            name: 'upperLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Upper Level',
          },
          {
            name: 'lowerLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Lower Level',
          },
        ]
      },

      // Triangle number of rooms
      {
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.Triangle]: [PropertyTypes.HomeRent],
        },
        fields: [
          {
            name: 'totalNumberBedrooms',
            inputType: InputTypes.Text,
            label: 'Total Number of Bedrooms',
            required: true,
          },
          {
            name: 'totalNumberFullBaths',
            inputType: InputTypes.Text,
            label: 'Total Number of Full Baths',
            required: true,
          },
          {
            name: 'totalNumberHalfBaths',
            inputType: InputTypes.Text,
            label: 'Total Number of Half Baths',
            required: true,
          },
          {
            name: 'totalNumberRooms',
            inputType: InputTypes.Text,
            label: 'Total Number of Rooms',
            required: true,
          },
        ]
      },
      {
        fieldsetTitle: 'Number of Bedrooms',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'totalNumberBedrooms',
            inputType: InputTypes.Text,
            label: 'Total Number of Bedrooms',
          },
          {
            name: 'mainLevelBedrooms',
            inputType: InputTypes.Text,
            label: 'Main Level Bedrooms',
          },
          {
            name: 'upperLevelBedrooms',
            inputType: InputTypes.Text,
            label: 'Upper Level Bedrooms',
          },
          {
            name: 'thirdLevelBedrooms',
            inputType: InputTypes.Text,
            label: 'Third Level Bedrooms',
          },
          {
            name: 'lowerLevelBedrooms',
            inputType: InputTypes.Text,
            label: 'Lower Level Bedrooms',
          },
          {
            name: 'basementLevelBedrooms',
            inputType: InputTypes.Text,
            label: 'Basement Level Bedrooms',
          },
        ]
      },
      {
        fieldsetTitle: 'Number of Bathrooms',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        fields: [
          {
            name: 'totalNumberFullBaths',
            inputType: InputTypes.Text,
            label: 'Total Number of Full Baths',
          },
          {
            name: 'mainLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Main Level',
          },
          {
            name: 'upperLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Upper Level',
          },
          {
            name: 'thirdLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Third Level',
          },
          {
            name: 'lowerLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Lower Level',
          },
          {
            name: 'basementLevelFullBaths',
            inputType: InputTypes.Text,
            label: 'Full Baths Basement Level',
          },
          {
            name: 'totalNumberHalfBaths',
            inputType: InputTypes.Text,
            label: 'Total Number of Half Baths',
          },
          {
            name: 'mainLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Main Level',
          },
          {
            name: 'upperLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Upper Level',
          },
          {
            name: 'thirdLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Third Level',
          },
          {
            name: 'lowerLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Lower Level',
          },
          {
            name: 'basementLevelHalfBaths',
            inputType: InputTypes.Text,
            label: 'Half Baths Basement Level',
          },
        ]
      },
      
      {
        fieldsetTitle: 'Single Family Detached Type',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'singleFamilyDetachedType-CapeCod',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod'
              },
              {
                name: 'singleFamilyDetachedType-CarriageKitchenHouse',
                inputType: InputTypes.Checkbox,
                label: 'Carriage/Kitchen House'
              },
              {
                name: 'singleFamilyDetachedType-CharlestonSingle',
                inputType: InputTypes.Checkbox,
                label: 'Charleston Single'
              },
              {
                name: 'singleFamilyDetachedType-Colonial',
                inputType: InputTypes.Checkbox,
                label: 'Colonial'
              },
              {
                name: 'singleFamilyDetachedType-CondoRegime',
                inputType: InputTypes.Checkbox,
                label: 'Condo Regime'
              },
              {
                name: 'singleFamilyDetachedType-Contemporary',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary'
              },
              {
                name: 'singleFamilyDetachedType-Cottage',
                inputType: InputTypes.Checkbox,
                label: 'Cottage'
              },
              {
                name: 'singleFamilyDetachedType-Craftsman',
                inputType: InputTypes.Checkbox,
                label: 'Craftsman'
              },
              {
                name: 'singleFamilyDetachedType-HorseFarm',
                inputType: InputTypes.Checkbox,
                label: 'Horse Farm'
              },
              {
                name: 'singleFamilyDetachedType-LiveWork',
                inputType: InputTypes.Checkbox,
                label: 'Live/Work'
              },
              {
                name: 'singleFamilyDetachedType-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'singleFamilyDetachedType-LogHome',
                inputType: InputTypes.Checkbox,
                label: 'Log Home'
              },
              {
                name: 'singleFamilyDetachedType-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'singleFamilyDetachedType-Ranch',
                inputType: InputTypes.Checkbox,
                label: 'Ranch'
              },
              {
                name: 'singleFamilyDetachedType-Spanish',
                inputType: InputTypes.Checkbox,
                label: 'Spanish'
              },
              {
                name: 'singleFamilyDetachedType-Traditional',
                inputType: InputTypes.Checkbox,
                label: 'Traditional'
              },
              {
                name: 'singleFamilyDetachedType-Tudor',
                inputType: InputTypes.Checkbox,
                label: 'Tudor'
              },
              {
                name: 'singleFamilyDetachedType-Victorian',
                inputType: InputTypes.Checkbox,
                label: 'Victorian'
              },
              {
                name: 'singleFamilyDetachedType-Villa',
                inputType: InputTypes.Checkbox,
                label: 'Villa'
              },
              {
                name: 'singleFamilyDetachedType-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Single Family Attached Type',
        required: 'Required if Single Family Attached',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'singleFamilyAttachedType-CondoRegime',
                inputType: InputTypes.Checkbox,
                label: 'Condo Regime'
              },
              {
                name: 'singleFamilyAttachedType-Condominium',
                inputType: InputTypes.Checkbox,
                label: 'Condominium'
              },
              {
                name: 'singleFamilyAttachedType-Duplex',
                inputType: InputTypes.Checkbox,
                label: 'Duplex (1 Unit)'
              },
              {
                name: 'singleFamilyAttachedType-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },
              {
                name: 'singleFamilyAttachedType-LiveWork',
                inputType: InputTypes.Checkbox,
                label: 'Live/Work'
              },
              {
                name: 'singleFamilyAttachedType-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'singleFamilyAttachedType-Quadplex',
                inputType: InputTypes.Checkbox,
                label: 'Quadplex (1 Unit)'
              },
              {
                name: 'singleFamilyAttachedType-Townhouse',
                inputType: InputTypes.Checkbox,
                label: 'Townhouse'
              },
              {
                name: 'singleFamilyAttachedType-Triplex',
                inputType: InputTypes.Checkbox,
                label: 'Triplex (1 Unit)'
              },
              {
                name: 'singleFamilyAttachedType-Villa',
                inputType: InputTypes.Checkbox,
                label: 'Villa'
              },
            ]
          },
          {
            groupTitle: 'Manufactured Home Type',
            required: 'Required if Manufactured Home',
            fields: [
              {
                name: 'manufacturedHomeType-SingleWide',
                inputType: InputTypes.Checkbox,
                label: 'Single Wide'
              },
              {
                name: 'manufacturedHomeType-DoubleWide',
                inputType: InputTypes.Checkbox,
                label: 'Double Wide'
              },
              {
                name: 'manufacturedHomeType-TripleWide',
                inputType: InputTypes.Checkbox,
                label: 'Triple Wide'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Rooms',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'rooms-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'rooms-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'rooms-ExerciseRoom',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Room'
              },
              {
                name: 'rooms-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'rooms-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'rooms-GameRoom',
                inputType: InputTypes.Checkbox,
                label: 'Game Room'
              },
              {
                name: 'rooms-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'rooms-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'rooms-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'rooms-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'rooms-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'rooms-MediaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Media Room'
              },
              {
                name: 'rooms-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'rooms-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'rooms-SunRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sun Room'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Equipment and Appliances',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'appliances-BevRef',
                inputType: InputTypes.Checkbox,
                label: 'Beverage Refrigerator'
              },
              {
                name: 'appliances-ConvectionOven',
                inputType: InputTypes.Checkbox,
                label: 'Convection Oven'
              },
              {
                name: 'appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'appliances-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'appliances-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'appliances-DowndraftCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Downdraft Cooktop'
              },
              {
                name: 'appliances-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'appliances-DualFlush',
                inputType: InputTypes.Checkbox,
                label: 'Dual Flush Toilets'
              },
              {
                name: 'appliances-ElecCookTop',
                inputType: InputTypes.Checkbox,
                label: 'Electric Cooktop'
              },
              {
                name: 'appliances-ElecOven',
                inputType: InputTypes.Checkbox,
                label: 'Electric Oven'
              },
              {
                name: 'appliances-ElecRange',
                inputType: InputTypes.Checkbox,
                label: 'Electric Range'
              },
              {
                name: 'appliances-ElecWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Electric Water Heater'
              },
              {
                name: 'appliances-ClothesWasherEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Clothes Washer'
              },
              {
                name: 'appliances-DishwasherEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Dishwasher'
              },
              {
                name: 'appliances-DryerEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Dryer'
              },
              {
                name: 'appliances-FreezerEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Freezer'
              },
              {
                name: 'appliances-LightFixturesEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Light Fixtures'
              },
              {
                name: 'appliances-RefrigeratorEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Refrigerator'
              },
              {
                name: 'appliances-ExhaustFan',
                inputType: InputTypes.Checkbox,
                label: 'Exhaust Fan'
              },
              {
                name: 'appliances-FilterSyst',
                inputType: InputTypes.Checkbox,
                label: 'Filtration System'
              },
              {
                name: 'appliances-Freezer',
                inputType: InputTypes.Checkbox,
                label: 'Freezer'
              },          
              {
                name: 'appliances-GasCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Gas Cooktop'
              },
              {
                name: 'appliances-GasOven',
                inputType: InputTypes.Checkbox,
                label: 'Gas Oven'
              },
              {
                name: 'appliances-GasRange',
                inputType: InputTypes.Checkbox,
                label: 'Gas Range'
              },
              {
                name: 'appliances-GasWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Gas Water Heater'
              },
              {
                name: 'appliances-Grilltop',
                inputType: InputTypes.Checkbox,
                label: 'Grilltop'
              },
              {
                name: 'appliances-HybridHeatWater',
                inputType: InputTypes.Checkbox,
                label: 'Hybrid Heat Pump Water Htr'
              },
              {
                name: 'appliances-InductionCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Induction Cooktop'
              },
              {
                name: 'appliances-LowFlowFixtures',
                inputType: InputTypes.Checkbox,
                label: 'Low Flow Fixtures'
              },
              {
                name: 'appliances-Microwave',
                inputType: InputTypes.Checkbox,
                label: 'Microwave'
              },
              {
                name: 'appliances-OilWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Oil Water Heater'
              },
              {
                name: 'appliances-Oven',
                inputType: InputTypes.Checkbox,
                label: 'Oven'
              },
              {
                name: 'appliances-PropaneWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Propane Water Heater'
              }, 
              {
                name: 'appliances-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'appliances-SelfCleaningOven',
                inputType: InputTypes.Checkbox,
                label: 'Self Cleaning Oven'
              },
              {
                name: 'appliances-SolarHotWater',
                inputType: InputTypes.Checkbox,
                label: 'Solar Hot Water'
              },
              {
                name: 'appliances-TanklessWater',
                inputType: InputTypes.Checkbox,
                label: 'Tankless Water Heater'
              },
              {
                name: 'appliances-TrashCompactor',
                inputType: InputTypes.Checkbox,
                label: 'Trash Compactor'
              },
              {
                name: 'appliances-WallOven',
                inputType: InputTypes.Checkbox,
                label: 'Wall Oven'
              },
              {
                name: 'appliances-WarmingDrawer',
                inputType: InputTypes.Checkbox,
                label: 'Warming Drawer'
              },
              {
                name: 'appliances-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'appliances-WasherDryerIncl',
                inputType: InputTypes.Checkbox,
                label: 'Washer and Dryer Included'
              },
              {
                name: 'appliances-WaterSoftener',
                inputType: InputTypes.Checkbox,
                label: 'Water Softener'
              },
              {
                name: 'appliances-WineRefrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Wine Refrigerator'
              },
              {
                name: 'appliances-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'appliances-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Laundry Features',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'laundryLocation-CommonArea',
                inputType: InputTypes.Checkbox,
                label: 'Common Area'
              },
              {
                name: 'laundryLocation-ElectricDryerHookUp',
                inputType: InputTypes.Checkbox,
                label: 'Electric Dryer Hookup'
              },
              {
                name: 'laundryLocation-GasDryerHookUp',
                inputType: InputTypes.Checkbox,
                label: 'Gas Dryer Hookup'
              },
              {
                name: 'laundryLocation-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'laundryLocation-Carport',
                inputType: InputTypes.Checkbox,
                label: 'Carport'
              },
              {
                name: 'laundryLocation-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'laundryLocation-Hall',
                inputType: InputTypes.Checkbox,
                label: 'Hall'
              },
              {
                name: 'laundryLocation-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'laundryLocation-MudRoom',
                inputType: InputTypes.Checkbox,
                label: 'Mud Room'
              },
              {
                name: 'laundryLocation-InUnit',
                inputType: InputTypes.Checkbox,
                label: 'In Unit'
              },
              {
                name: 'laundryLocation-UtilityRoom',
                inputType: InputTypes.Checkbox,
                label: 'Utility Room'
              },
              {
                name: 'laundryLocation-Inside',
                inputType: InputTypes.Checkbox,
                label: 'Inside'
              },
              {
                name: 'laundryLocation-LaundryChute',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Chute'
              },
              {
                name: 'laundryLocation-LaundryCloset',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Closet'
              },
              {
                name: 'laundryLocation-LaundryRoom',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Room'
              },
              {
                name: 'laundryLocation-Lower',
                inputType: InputTypes.Checkbox,
                label: 'Lower Level'
              },
              {
                name: 'laundryLocation-Main',
                inputType: InputTypes.Checkbox,
                label: 'Main Level'
              },
              {
                name: 'laundryLocation-MultipleLoc',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Locations'
              },
              {
                name: 'laundryLocation-Outside',
                inputType: InputTypes.Checkbox,
                label: 'Outside'
              },
              {
                name: 'laundryLocation-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'laundryLocation-Sink',
                inputType: InputTypes.Checkbox,
                label: 'Sink'
              },
              {
                name: 'laundryLocation-Third',
                inputType: InputTypes.Checkbox,
                label: 'Third'
              },
              {
                name: 'laundryLocation-Upper',
                inputType: InputTypes.Checkbox,
                label: 'Upper'
              },       
               {
                name: 'laundryLocation-WasherHookup',
                inputType: InputTypes.Checkbox,
                label: 'Washer Hookup'
              },
              {
                name: 'laundryLocation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'laundryLocation-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'flooring-Bamboo',
                inputType: InputTypes.Checkbox,
                label: 'Bamboo'
              },
              {
                name: 'flooring-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'flooring-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },
              {
                name: 'flooring-CeramicTile',
                inputType: InputTypes.Checkbox,
                label: 'Ceramic Tile'
              },
              {
                name: 'flooring-Cork',
                inputType: InputTypes.Checkbox,
                label: 'Cork'
              },
              {
                name: 'flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
              {
                name: 'flooring-Linoleum',
                inputType: InputTypes.Checkbox,
                label: 'Linoleum'
              },
              {
                name: 'flooring-LuxuryVinyl',
                inputType: InputTypes.Checkbox,
                label: 'Luxury Vinyl'
              },
              {
                name: 'flooring-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },
              {
                name: 'flooring-Parquet',
                inputType: InputTypes.Checkbox,
                label: 'Parquet'
              },
              {
                name: 'flooring-ReclaimedWood',
                inputType: InputTypes.Checkbox,
                label: 'Reclaimed Wood'
              },
              {
                name: 'flooring-SimulatedWood',
                inputType: InputTypes.Checkbox,
                label: 'Simulated Wood'
              },
              {
                name: 'flooring-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'flooring-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'flooring-SustainableFlooring',
                inputType: InputTypes.Checkbox,
                label: 'Sustainable Flooring'
              },
              {
                name: 'flooring-Terrazzo',
                inputType: InputTypes.Checkbox,
                label: 'Terrazzo'
              },
              {
                name: 'flooring-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'flooring-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Construction Materials',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'cunstructionMaterials-AluminumSiding',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum Siding'
              },
              {
                name: 'cunstructionMaterials-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'cunstructionMaterials-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'cunstructionMaterials-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'cunstructionMaterials-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'cunstructionMaterials-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'cunstructionMaterials-PressBoard',
                inputType: InputTypes.Checkbox,
                label: 'Press Board'
              },
              {
                name: 'cunstructionMaterials-RoughSawnLumber',
                inputType: InputTypes.Checkbox,
                label: 'Rough-Sawn Lumber'
              },
              {
                name: 'cunstructionMaterials-SteelSiding',
                inputType: InputTypes.Checkbox,
                label: 'Steel Siding'
              },
              {
                name: 'cunstructionMaterials-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'cunstructionMaterials-Stucco',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'cunstructionMaterials-SyntheticStucco',
                inputType: InputTypes.Checkbox,
                label: 'Synthetic Stucco'
              },
              {
                name: 'cunstructionMaterials-Tabby',
                inputType: InputTypes.Checkbox,
                label: 'Tabby'
              },
              {
                name: 'cunstructionMaterials-WoodSiding',
                inputType: InputTypes.Checkbox,
                label: 'Wood Siding'
              },
              {
                name: 'cunstructionMaterials-VinylSiding',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Siding'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Construction Materials',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'exteriorFinishing-AluminumSiding',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum Siding'
              },
              {
                name: 'exteriorFinishing-Asbestos',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos'
              },
              {
                name: 'exteriorFinishing-Asphalt',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt'
              },
              {
                name: 'exteriorFinishing-AtticCrawlInsulated',
                inputType: InputTypes.Checkbox,
                label: 'Attic/Crawl Hatchway(s) Insulated'
              },
              {
                name: 'exteriorFinishing-BattsInsulation',
                inputType: InputTypes.Checkbox,
                label: 'Batts Insulation'
              },
              {
                name: 'exteriorFinishing-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'exteriorFinishing-BlownInsulation',
                inputType: InputTypes.Checkbox,
                label: 'Blown-In Insulation'
              },
              {
                name: 'exteriorFinishing-BoardBatten',
                inputType: InputTypes.Checkbox,
                label: 'Board / Batten Siding'
              },
              {
                name: 'exteriorFinishing-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'exteriorFinishing-BrickVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Brick Veneer'
              },
              {
                name: 'exteriorFinishing-Cedar',
                inputType: InputTypes.Checkbox,
                label: 'Cedar'
              },
              {
                name: 'exteriorFinishing-CementSiding',
                inputType: InputTypes.Checkbox,
                label: 'Cement Siding'
              },
              {
                name: 'exteriorFinishing-Clapboard',
                inputType: InputTypes.Checkbox,
                label: 'Clapboard'
              },
              {
                name: 'exteriorFinishing-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'exteriorFinishing-EngineeredWood',
                inputType: InputTypes.Checkbox,
                label: 'Engineered Wood'
              },
              {
                name: 'exteriorFinishing-FiberCement',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Cement'
              },
              {
                name: 'exteriorFinishing-FiberglassSiding',
                inputType: InputTypes.Checkbox,
                label: 'Fiberglass Siding'
              },
              {
                name: 'exteriorFinishing-Foam',
                inputType: InputTypes.Checkbox,
                label: 'Foam'
              },
              {
                name: 'exteriorFinishing-Frame',
                inputType: InputTypes.Checkbox,
                label: 'Frame'
              },
              {
                name: 'exteriorFinishing-HardiplankType',
                inputType: InputTypes.Checkbox,
                label: 'HardiPlank Type'
              },
              {
                name: 'exteriorFinishing-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'exteriorFinishing-LowVOCInsulation',
                inputType: InputTypes.Checkbox,
                label: 'Low VOC Insulation'
              },
              {
                name: 'exteriorFinishing-LowVOCPaint',
                inputType: InputTypes.Checkbox,
                label: 'Low VOC Paint'
              },
              {
                name: 'exteriorFinishing-Masonite',
                inputType: InputTypes.Checkbox,
                label: 'Masonite'
              },
              {
                name: 'exteriorFinishing-MetalSiding',
                inputType: InputTypes.Checkbox,
                label: 'Metal Siding'
              },
              {
                name: 'exteriorFinishing-Plaster',
                inputType: InputTypes.Checkbox,
                label: 'Plaster'
              },
              {
                name: 'exteriorFinishing-RadiantBarrier',
                inputType: InputTypes.Checkbox,
                label: 'Radiant Barrier'
              },
              {
                name: 'exteriorFinishing-Shake',
                inputType: InputTypes.Checkbox,
                label: 'Shake Siding'
              },
              {
                name: 'exteriorFinishing-ShingleSiding',
                inputType: InputTypes.Checkbox,
                label: 'Shingle Siding'
              },
              {
                name: 'exteriorFinishing-SprayFoam',
                inputType: InputTypes.Checkbox,
                label: 'Spray Foam Insulation'
              },
              {
                name: 'exteriorFinishing-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'exteriorFinishing-StoneVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Stone Veneer'
              },
              {
                name: 'exteriorFinishing-Stucco',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'exteriorFinishing-SyntheticStucco',
                inputType: InputTypes.Checkbox,
                label: 'Synthetic Stucco'
              },
              {
                name: 'exteriorFinishing-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Siding'
              },
              {
                name: 'exteriorFinishing-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood Siding'
              },
              {
                name: 'exteriorFinishing-Unknown',
                inputType: InputTypes.Checkbox,
                label: 'Unknown'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Finishing',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'exteriorFinishing-Aluminum',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum'
              },  
              {
                name: 'exteriorFinishing-Asbestos',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos'
              },  
              {
                name: 'exteriorFinishing-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },  
              {
                name: 'exteriorFinishing-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },  
              {
                name: 'exteriorFinishing-CementeousSiding',
                inputType: InputTypes.Checkbox,
                label: 'Cementeous Siding'
              },  
              {
                name: 'exteriorFinishing-FormedStone',
                inputType: InputTypes.Checkbox,
                label: 'Formed Stone'
              },  
              {
                name: 'exteriorFinishing-Frame',
                inputType: InputTypes.Checkbox,
                label: 'Frame'
              },  
              {
                name: 'exteriorFinishing-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },  
              {
                name: 'exteriorFinishing-MasoniteTypeSiding',
                inputType: InputTypes.Checkbox,
                label: 'Masonite-Type Siding'
              },  
              {
                name: 'exteriorFinishing-PreCastStone',
                inputType: InputTypes.Checkbox,
                label: 'Pre-Cast Stone'
              },  
              {
                name: 'exteriorFinishing-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },  
              {
                name: 'exteriorFinishing-Stucco',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },  
              {
                name: 'exteriorFinishing-StuccoHardCoat',
                inputType: InputTypes.Checkbox,
                label: 'Stucco Hard Coat'
              },  
              {
                name: 'exteriorFinishing-StuccoEIFS',
                inputType: InputTypes.Checkbox,
                label: 'Stucco EIFS'
              },  
              {
                name: 'exteriorFinishing-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },  
              {
                name: 'exteriorFinishing-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },  
              {
                name: 'exteriorFinishing-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },  
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Finishing',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'exteriorFinishing-Aluminum',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum'
              },
              {
                name: 'exteriorFinishing-Asbestos',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos'
              },
              {
                name: 'exteriorFinishing-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'exteriorFinishing-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'exteriorFinishing-BrickVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Brick Veneer'
              },
              {
                name: 'exteriorFinishing-Composition',
                inputType: InputTypes.Checkbox,
                label: 'Composition'
              },
              {
                name: 'exteriorFinishing-FiberCement',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Cement'
              },
              {
                name: 'exteriorFinishing-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'exteriorFinishing-SteelSiding',
                inputType: InputTypes.Checkbox,
                label: 'Steel Siding'
              },
              {
                name: 'exteriorFinishing-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'exteriorFinishing-Stucco',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'exteriorFinishing-StuccoRemoved',
                inputType: InputTypes.Checkbox,
                label: 'Stucco Removed'
              },
              {
                name: 'exteriorFinishing-StuccoSynthetic',
                inputType: InputTypes.Checkbox,
                label: 'Stucco Synthetic'
              },
              {
                name: 'exteriorFinishing-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'exteriorFinishing-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'exteriorFinishing-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Covering',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'exteriorCovering-AluminumSiding',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum Siding'
              },
              {
                name: 'exteriorCovering-Asbestos',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos'
              },
              {
                name: 'exteriorCovering-BrickFull',
                inputType: InputTypes.Checkbox,
                label: 'Brick Full'
              },
              {
                name: 'exteriorCovering-BrickPartial',
                inputType: InputTypes.Checkbox,
                label: 'Brick Partial'
              },
              {
                name: 'exteriorCovering-CedarShake',
                inputType: InputTypes.Checkbox,
                label: 'Cedar Shake'
              },
              {
                name: 'exteriorCovering-ConcreteBlock',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Block'
              },
              {
                name: 'exteriorCovering-FiberCement',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Cement'
              },
              {
                name: 'exteriorCovering-Glass',
                inputType: InputTypes.Checkbox,
                label: 'Glass'
              },
              {
                name: 'exteriorCovering-HardStucco',
                inputType: InputTypes.Checkbox,
                label: 'Hard Stucco'
              },
              {
                name: 'exteriorCovering-HardboardSiding',
                inputType: InputTypes.Checkbox,
                label: 'Hardboard Siding'
              },
              {
                name: 'exteriorCovering-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'exteriorCovering-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'exteriorCovering-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'exteriorCovering-RoughSawn',
                inputType: InputTypes.Checkbox,
                label: 'Rough/Sawn'
              },
              {
                name: 'exteriorCovering-Shingle',
                inputType: InputTypes.Checkbox,
                label: 'Shingle'
              },
              {
                name: 'exteriorCovering-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'exteriorCovering-StoneVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Stone Veneer'
              },
              {
                name: 'exteriorCovering-SyntheticStucco',
                inputType: InputTypes.Checkbox,
                label: 'Synthetic Stucco'
              },
              {
                name: 'exteriorCovering-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'exteriorCovering-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Basement and Foundation',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'foundation-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'foundation-PillarPostPier',
                inputType: InputTypes.Checkbox,
                label: 'Pillar/Post/Pier'
              },
              {
                name: 'foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
              {
                name: 'foundation-BathFinished',
                inputType: InputTypes.Checkbox,
                label: 'Bath Finished'
              },
              {
                name: 'foundation-BathStubbed',
                inputType: InputTypes.Checkbox,
                label: 'Bath/Stubbed'
              },
              {
                name: 'foundation-BoatDoor',
                inputType: InputTypes.Checkbox,
                label: 'Boat Door'
              },
              {
                name: 'foundation-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'foundation-Daylight',
                inputType: InputTypes.Checkbox,
                label: 'Daylight'
              },
              {
                name: 'foundation-DirtFloor',
                inputType: InputTypes.Checkbox,
                label: 'Dirt Floor'
              },
              {
                name: 'foundation-ExteriorEntry',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Entry'
              },
              {
                name: 'foundation-Finished',
                inputType: InputTypes.Checkbox,
                label: 'Finished'
              },
              {
                name: 'foundation-Full',
                inputType: InputTypes.Checkbox,
                label: 'Full'
              },
              {
                name: 'foundation-InteriorEntry',
                inputType: InputTypes.Checkbox,
                label: 'Interior Entry'
              },
              {
                name: 'foundation-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'foundation-Partial',
                inputType: InputTypes.Checkbox,
                label: 'Partial'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation and Basement',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'foundation-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'foundation-BasementFinished',
                inputType: InputTypes.Checkbox,
                label: 'Basement - Finished'
              },
              {
                name: 'foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'foundation-Full',
                inputType: InputTypes.Checkbox,
                label: 'Full'
              },
              {
                name: 'foundation-Partial',
                inputType: InputTypes.Checkbox,
                label: 'Partial'
              },
              {
                name: 'foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation and Basement',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'foundation-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'foundation-BrickMortar',
                inputType: InputTypes.Checkbox,
                label: 'Brick/Mortar'
              },
              {
                name: 'foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'foundation-Permanent',
                inputType: InputTypes.Checkbox,
                label: 'Permanent'
              },
              {
                name: 'foundation-Pilings',
                inputType: InputTypes.Checkbox,
                label: 'Pilings'
              },
              {
                name: 'foundation-Raised',
                inputType: InputTypes.Checkbox,
                label: 'Raised'
              },
              {
                name: 'foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
              {
                name: 'foundation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'foundation-FinishedFullBasement',
                inputType: InputTypes.Checkbox,
                label: 'Finished - Full Basement'
              },
              {
                name: 'foundation-FinishedNo',
                inputType: InputTypes.Checkbox,
                label: 'Finished - No'
              },
              {
                name: 'foundation-FinishedPartialBasement',
                inputType: InputTypes.Checkbox,
                label: 'Finished - Partial Basement'
              },
              {
                name: 'foundation-OutsideEntranceBasement',
                inputType: InputTypes.Checkbox,
                label: 'Outside Entrance Basement'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'foundation-Permanent',
                inputType: InputTypes.Checkbox,
                label: 'Permanent'
              },
              {
                name: 'foundation-PillarPostPier',
                inputType: InputTypes.Checkbox,
                label: 'Pillar / Post / Pier'
              },
              {
                name: 'foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
              {
                name: 'foundation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          },
              {
                groupTitle: 'Basement Features',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                fields: [
               {
                name: 'foundation-basement-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'foundation-basement-BasementApartment',
                inputType: InputTypes.Checkbox,
                label: 'Basement Apartment'
              },
              {
                name: 'foundation-basement-BasementGarageDoor',
                inputType: InputTypes.Checkbox,
                label: 'Basement Garage Door'
              },            {
                name: 'foundation-basement-BasementShop',
                inputType: InputTypes.Checkbox,
                label: 'Basement Shop'
              },
              {
                name: 'foundation-basement-BathStub',
                inputType: InputTypes.Checkbox,
                label: 'Bath Stubbed'
              },
              {
                name: 'foundation-basement-BasementDaylight',
                inputType: InputTypes.Checkbox,
                label: 'Daylight'
              },
              {
                name: 'foundation-basement-BasementExterior',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Entry'
              },
              {
                name: 'foundation-basement-BasementFullyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Fully Finished'
              },
              {
                name: 'foundation-basement-BasementFull',
                inputType: InputTypes.Checkbox,
                label: 'Full Basement'
              },    
              {
                name: 'foundation-basement-BasementInteriorEnt',
                inputType: InputTypes.Checkbox,
                label: 'Interior Entrance'
              },
              {
                name: 'foundation-basement-BasementPartial',
                inputType: InputTypes.Checkbox,
                label: 'Partial Basement'
              },
              {
                name: 'foundation-basement-BasementPartiallyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Partially Finished'
              },
              {
                name: 'foundation-basement-BasementStorage',
                inputType: InputTypes.Checkbox,
                label: 'Storage Space'
              },
              {
                name: 'foundation-basement-SumpPump',
                inputType: InputTypes.Checkbox,
                label: 'Sump Pump'
              },
              {
                name: 'foundation-basement-Unfinisehd',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished Basement '
              },
              {
                name: 'foundation-basement-WalkOut',
                inputType: InputTypes.Checkbox,
                label: 'Walk Out'
              },       
              {
                name: 'foundation-basement-WalkUp',
                inputType: InputTypes.Checkbox,
                label: 'Walk Up'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Construction',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'construction-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'construction-BrickStone',
                inputType: InputTypes.Checkbox,
                label: 'Brick/Stone'
              },
              {
                name: 'construction-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'construction-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'construction-SteelFrame',
                inputType: InputTypes.Checkbox,
                label: 'Steel Frame'
              },
              {
                name: 'construction-WoodFrame',
                inputType: InputTypes.Checkbox,
                label: 'Wood Frame'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Construction Materials',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'constructionMaterials-1',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum Siding'
              },
              {
                name: 'constructionMaterials-2',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos'
              },
              {
                name: 'constructionMaterials-3',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt'
              },
              {
                name: 'constructionMaterials-4',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'constructionMaterials-5',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'constructionMaterials-6',
                inputType: InputTypes.Checkbox,
                label: 'Brick Veneer'
              },
              {
                name: 'constructionMaterials-7',
                inputType: InputTypes.Checkbox,
                label: 'Cedar'
              },
              {
                name: 'constructionMaterials-8',
                inputType: InputTypes.Checkbox,
                label: 'Cedar Shake'
              },
              {
                name: 'constructionMaterials-9',
                inputType: InputTypes.Checkbox,
                label: 'Cement Lapbox'
              },
              {
                name: 'constructionMaterials-10',
                inputType: InputTypes.Checkbox,
                label: 'Clapboard'
              },
              {
                name: 'constructionMaterials-11',
                inputType: InputTypes.Checkbox,
                label: 'Glass'
              },
              {
                name: 'constructionMaterials-12',
                inputType: InputTypes.Checkbox,
                label: 'Hardboard'
              },
              {
                name: 'constructionMaterials-13',
                inputType: InputTypes.Checkbox,
                label: 'Hardiplank'
              },
              {
                name: 'constructionMaterials-14',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'constructionMaterials-15',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'constructionMaterials-16',
                inputType: InputTypes.Checkbox,
                label: 'Redwood'
              },
              {
                name: 'constructionMaterials-17',
                inputType: InputTypes.Checkbox,
                label: 'Shingle Siding'
              },
              {
                name: 'constructionMaterials-18',
                inputType: InputTypes.Checkbox,
                label: 'Steel Siding'
              },
              {
                name: 'constructionMaterials-19',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'constructionMaterials-20',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'constructionMaterials-21',
                inputType: InputTypes.Checkbox,
                label: 'Synthetic Stucco'
              },
              {
                name: 'constructionMaterials-22',
                inputType: InputTypes.Checkbox,
                label: 'T-1-11'
              },
              {
                name: 'constructionMaterials-23',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Siding'
              },
              {
                name: 'constructionMaterials-24',
                inputType: InputTypes.Checkbox,
                label: 'Wood Siding'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Construction Materials',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'constructionMaterials-1',
                inputType: InputTypes.Checkbox,
                label: 'Advanced Framing'
              },
              {
                name: 'constructionMaterials-2',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum Siding'
              },
              {
                name: 'constructionMaterials-3',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos'
              },
              {
                name: 'constructionMaterials-4',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt'
              },
              {
                name: 'constructionMaterials-5',
                inputType: InputTypes.Checkbox,
                label: 'Batts Insulation'
              },
              {
                name: 'constructionMaterials-6',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'constructionMaterials-7',
                inputType: InputTypes.Checkbox,
                label: 'Blown-In Insulation'
              },
              {
                name: 'constructionMaterials-8',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'constructionMaterials-9',
                inputType: InputTypes.Checkbox,
                label: 'Brick Front'
              },
              {
                name: 'constructionMaterials-10',
                inputType: InputTypes.Checkbox,
                label: 'Brick Veneer'
              },
              {
                name: 'constructionMaterials-11',
                inputType: InputTypes.Checkbox,
                label: 'Cedar'
              },
              {
                name: 'constructionMaterials-12',
                inputType: InputTypes.Checkbox,
                label: 'Cement Siding'
              },
              {
                name: 'constructionMaterials-13',
                inputType: InputTypes.Checkbox,
                label: 'Combination'
              },
              {
                name: 'constructionMaterials-14',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'constructionMaterials-15',
                inputType: InputTypes.Checkbox,
                label: 'Copper Plumbing'
              },
              {
                name: 'constructionMaterials-16',
                inputType: InputTypes.Checkbox,
                label: 'Corrugated Siding'
              },
              {
                name: 'constructionMaterials-17',
                inputType: InputTypes.Checkbox,
                label: 'CPVC/PVC'
              },
              {
                name: 'constructionMaterials-18',
                inputType: InputTypes.Checkbox,
                label: 'Dryvit'
              },
              {
                name: 'constructionMaterials-19',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Cement'
              },
              {
                name: 'constructionMaterials-20',
                inputType: InputTypes.Checkbox,
                label: 'Frame'
              },
              {
                name: 'constructionMaterials-21',
                inputType: InputTypes.Checkbox,
                label: 'Galvanized Plumbing'
              },
              {
                name: 'constructionMaterials-22',
                inputType: InputTypes.Checkbox,
                label: 'HardiPlank Type'
              },
              {
                name: 'constructionMaterials-23',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'constructionMaterials-24',
                inputType: InputTypes.Checkbox,
                label: 'Low VOC Insulation'
              },
              {
                name: 'constructionMaterials-25',
                inputType: InputTypes.Checkbox,
                label: 'Low VOC Products/Finishes'
              },
              {
                name: 'constructionMaterials-26',
                inputType: InputTypes.Checkbox,
                label: 'Masonite'
              },
              {
                name: 'constructionMaterials-27',
                inputType: InputTypes.Checkbox,
                label: 'Masonry'
              },
              {
                name: 'constructionMaterials-28',
                inputType: InputTypes.Checkbox,
                label: 'Metal Siding'
              },
              {
                name: 'constructionMaterials-29',
                inputType: InputTypes.Checkbox,
                label: 'Mixed'
              },
              {
                name: 'constructionMaterials-30',
                inputType: InputTypes.Checkbox,
                label: 'Mixed Plumbing'
              },
              {
                name: 'constructionMaterials-31',
                inputType: InputTypes.Checkbox,
                label: 'Modular/Manufactured'
              },
              {
                name: 'constructionMaterials-32',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'constructionMaterials-33',
                inputType: InputTypes.Checkbox,
                label: 'Rigid Insulation'
              },
              {
                name: 'constructionMaterials-34',
                inputType: InputTypes.Checkbox,
                label: 'Rock'
              },
              {
                name: 'constructionMaterials-35',
                inputType: InputTypes.Checkbox,
                label: 'Rough-In Plumbing'
              },
              {
                name: 'constructionMaterials-36',
                inputType: InputTypes.Checkbox,
                label: 'Shake Siding'
              },
              {
                name: 'constructionMaterials-37',
                inputType: InputTypes.Checkbox,
                label: 'Shingle Siding'
              },
              {
                name: 'constructionMaterials-38',
                inputType: InputTypes.Checkbox,
                label: 'Spray Foam Insulation'
              },
              {
                name: 'constructionMaterials-39',
                inputType: InputTypes.Checkbox,
                label: 'Steel Siding'
              },
              {
                name: 'constructionMaterials-40',
                inputType: InputTypes.Checkbox,
                label: 'Stick Built'
              },
              {
                name: 'constructionMaterials-41',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'constructionMaterials-42',
                inputType: InputTypes.Checkbox,
                label: 'SIPS Panel System'
              },
              {
                name: 'constructionMaterials-43',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'constructionMaterials-44',
                inputType: InputTypes.Checkbox,
                label: 'Stucco - Synthetic'
              },
              {
                name: 'constructionMaterials-45',
                inputType: InputTypes.Checkbox,
                label: 'T-1-11'
              },
              {
                name: 'constructionMaterials-46',
                inputType: InputTypes.Checkbox,
                label: 'Trombe Wall'
              },
              {
                name: 'constructionMaterials-47',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Siding'
              },
              {
                name: 'constructionMaterials-48',
                inputType: InputTypes.Checkbox,
                label: 'Wood Siding'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Basement',
        fieldsetDescription: '',
        required: 'Required if Basement',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'basement-Apartment',
                inputType: InputTypes.Checkbox,
                label: 'Apartment'
              },
              {
                name: 'basement-BathStubbed',
                inputType: InputTypes.Checkbox,
                label: 'Bath/Stubbed'
              },
              {
                name: 'basement-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'basement-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'basement-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'basement-Daylight',
                inputType: InputTypes.Checkbox,
                label: 'Daylight'
              },
              {
                name: 'basement-ExteriorEntry',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Entry'
              },
              {
                name: 'basement-Finished',
                inputType: InputTypes.Checkbox,
                label: 'Finished'
              },
              {
                name: 'basement-Full',
                inputType: InputTypes.Checkbox,
                label: 'Full'
              },
              {
                name: 'basement-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'basement-InteriorEntry',
                inputType: InputTypes.Checkbox,
                label: 'Interior Entry'
              },
              {
                name: 'basement-Partial',
                inputType: InputTypes.Checkbox,
                label: 'Partial'
              },
              {
                name: 'basement-PartiallyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Partially Finished'
              },
              {
                name: 'basement-StorageSpace',
                inputType: InputTypes.Checkbox,
                label: 'Storage Space'
              },
              {
                name: 'basement-SumpPump',
                inputType: InputTypes.Checkbox,
                label: 'Sump Pump'
              },
              {
                name: 'basement-Unfinished',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished'
              },
              {
                name: 'basement-Unheated',
                inputType: InputTypes.Checkbox,
                label: 'Unheated'
              },
              {
                name: 'basement-WalkInCrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Crawl Space'
              },
              {
                name: 'basement-WalkOutCrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Walk-Out Crawl Space'
              },
              {
                name: 'basement-WalkUpAccess',
                inputType: InputTypes.Checkbox,
                label: 'Walk-Up Access'
              },
              {
                name: 'basement-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Basement / Foundation',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'basement-BasementFull',
                inputType: InputTypes.Checkbox,
                label: 'Basement-Full'
              },
              {
                name: 'basement-BasementPartial',
                inputType: InputTypes.Checkbox,
                label: 'Basement-Partial'
              },
              {
                name: 'basement-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'basement-Dirt',
                inputType: InputTypes.Checkbox,
                label: 'Dirt'
              },
              {
                name: 'basement-FinishedCom',
                inputType: InputTypes.Checkbox,
                label: 'Finished-Com'
              },
              {
                name: 'basement-FinishedPart',
                inputType: InputTypes.Checkbox,
                label: 'Finished-Part'
              },
              {
                name: 'basement-Floored',
                inputType: InputTypes.Checkbox,
                label: 'Floored'
              },
              {
                name: 'basement-GarageAccess',
                inputType: InputTypes.Checkbox,
                label: 'Garage Access'
              },
              {
                name: 'basement-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'basement-InteriorAccess',
                inputType: InputTypes.Checkbox,
                label: 'Interior Access'
              },
              {
                name: 'basement-LockedStorage',
                inputType: InputTypes.Checkbox,
                label: 'Locked Storage'
              },
              {
                name: 'basement-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'basement-RoughedIn',
                inputType: InputTypes.Checkbox,
                label: 'Roughed In'
              },
              {
                name: 'basement-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
              {
                name: 'basement-Unfinished',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished'
              },
              {
                name: 'basement-Walk-Out',
                inputType: InputTypes.Checkbox,
                label: 'Walk-Out'
              },
              {
                name: 'basement-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Attic',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'attic-AccessPanel',
                inputType: InputTypes.Checkbox,
                label: 'Access Panel'
              },
              {
                name: 'attic-Expandable',
                inputType: InputTypes.Checkbox,
                label: 'Expandable'
              },
              {
                name: 'attic-Finished',
                inputType: InputTypes.Checkbox,
                label: 'Finished'
              },
              {
                name: 'attic-Floored',
                inputType: InputTypes.Checkbox,
                label: 'Floored'
              },
              {
                name: 'attic-NoAttic',
                inputType: InputTypes.Checkbox,
                label: 'No Attic'
              },
              {
                name: 'attic-PartFinished',
                inputType: InputTypes.Checkbox,
                label: 'Part Finished'
              },
              {
                name: 'attic-PullDown',
                inputType: InputTypes.Checkbox,
                label: 'Pull Down'
              },
              {
                name: 'attic-Walk-In',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In'
              },
              {
                name: 'attic-Walk-Up',
                inputType: InputTypes.Checkbox,
                label: 'Walk-Up'
              },              
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Roof',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'roof-Aluminum',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum'
              },
              {
                name: 'roof-ArchitecturalShingle',
                inputType: InputTypes.Checkbox,
                label: 'Architectural Shingle'
              },
              {
                name: 'roof-AsbestosShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos Shingle'
              },
              {
                name: 'roof-Asphalt',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt'
              },
              {
                name: 'roof-BuiltUp',
                inputType: InputTypes.Checkbox,
                label: 'Built-Up'
              },
              {
                name: 'roof-Composition',
                inputType: InputTypes.Checkbox,
                label: 'Composition'
              },
              {
                name: 'roof-Copper',
                inputType: InputTypes.Checkbox,
                label: 'Copper'
              },
              {
                name: 'roof-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },
              {
                name: 'roof-GreenRoof',
                inputType: InputTypes.Checkbox,
                label: 'Green Roof'
              },
              {
                name: 'roof-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'roof-Mixed',
                inputType: InputTypes.Checkbox,
                label: 'Mixed'
              },
              {
                name: 'roof-Rubber',
                inputType: InputTypes.Checkbox,
                label: 'Rubber'
              },
              {
                name: 'roof-Shake',
                inputType: InputTypes.Checkbox,
                label: 'Shake'
              },
              {
                name: 'roof-Shingle',
                inputType: InputTypes.Checkbox,
                label: 'Shingle'
              },
              {
                name: 'roof-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'roof-SpanishTile',
                inputType: InputTypes.Checkbox,
                label: 'Spanish Tile'
              },
              {
                name: 'roof-TarGravel',
                inputType: InputTypes.Checkbox,
                label: 'Tar/Gravel'
              },
              {
                name: 'roof-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },              
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Roof',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'roof-ArchitecturalShingle',
                inputType: InputTypes.Checkbox,
                label: 'Architectural Shingle'
              },
              {
                name: 'roof-Composition',
                inputType: InputTypes.Checkbox,
                label: 'Composition'
              },
              {
                name: 'roof-Membrane',
                inputType: InputTypes.Checkbox,
                label: 'Membrane'
              },
              {
                name: 'roof-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'roof-Shake',
                inputType: InputTypes.Checkbox,
                label: 'Shake'
              },
              {
                name: 'roof-Shingle',
                inputType: InputTypes.Checkbox,
                label: 'Shingle'
              },
              {
                name: 'roof-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'roof-TarGravel',
                inputType: InputTypes.Checkbox,
                label: 'Tar/Gravel'
              },
              {
                name: 'roof-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'roof-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Roof',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'roof-AsbestosShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos Shingle'
              },
              {
                name: 'roof-AsphaltShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt Shingle'
              },
              {
                name: 'roof-BuiltUp',
                inputType: InputTypes.Checkbox,
                label: 'Built Up'
              },
              {
                name: 'roof-Composition',
                inputType: InputTypes.Checkbox,
                label: 'Composition'
              },
              {
                name: 'roof-Dimensional',
                inputType: InputTypes.Checkbox,
                label: 'Dimensional'
              },
              {
                name: 'roof-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },
              {
                name: 'roof-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'roof-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'roof-Rubber',
                inputType: InputTypes.Checkbox,
                label: 'Rubber'
              },
              {
                name: 'roof-Shingled',
                inputType: InputTypes.Checkbox,
                label: 'Shingled'
              },
              {
                name: 'roof-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'roof-Sloped',
                inputType: InputTypes.Checkbox,
                label: 'Sloped'
              },
              {
                name: 'roof-TarAndGravel',
                inputType: InputTypes.Checkbox,
                label: 'Tar and Gravel'
              },
              {
                name: 'roof-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'roof-WoodShingle',
                inputType: InputTypes.Checkbox,
                label: 'Wood Shingle'
              },              
            ]
          }
        ]
      }
    ]
  },
  {
    sectionTitle: 'Building Interior',
    scope: Scopes.IndividualMapping,
    mapping: { 
      [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
    },
    fieldsets: [
      {
        fieldsetTitle: 'Appliances',
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'appliences-ConvectionOven',
                inputType: InputTypes.Checkbox,
                label: 'Convection Oven'
              },
              {
                name: 'appliences-Cooktop',
                inputType: InputTypes.Checkbox,
                label: 'Cooktop'
              },
              {
                name: 'appliences-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'appliences-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'appliences-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'appliences-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'appliences-ElectricWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Electric Water Heater'
              },
              {
                name: 'appliences-GasWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Gas Water Heater'
              },
              {
                name: 'appliences-IceMaker',
                inputType: InputTypes.Checkbox,
                label: 'Ice Maker'
              },
              {
                name: 'appliences-IndoorGrill',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Grill'
              },
              {
                name: 'appliences-Microwave',
                inputType: InputTypes.Checkbox,
                label: 'Microwave'
              },
              {
                name: 'appliences-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'appliences-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'appliences-OvenWall',
                inputType: InputTypes.Checkbox,
                label: 'Oven (Wall)'
              },
              {
                name: 'appliences-OvenRangeCombo',
                inputType: InputTypes.Checkbox,
                label: 'Oven/Range (Combo)'
              },
              {
                name: 'appliences-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'appliences-StainlessSteelAppliance',
                inputType: InputTypes.Checkbox,
                label: 'Stainless Steel Appliance'
              },
              {
                name: 'appliences-TanklessWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Tankless Water Heater'
              },
              {
                name: 'appliences-TrashCompactor',
                inputType: InputTypes.Checkbox,
                label: 'Trash Compactor'
              },
              {
                name: 'appliences-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'appliences-WaterSoftener',
                inputType: InputTypes.Checkbox,
                label: 'Water Softener'
              },
              {
                name: 'appliences-ElectricAirFilter',
                inputType: InputTypes.Checkbox,
                label: 'Electric Air Filter'
              },
              {
                name: 'appliences-HomeTheater',
                inputType: InputTypes.Checkbox,
                label: 'Home Theater'
              },
              {
                name: 'appliences-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },
              {
                name: 'appliences-SatelliteDish',
                inputType: InputTypes.Checkbox,
                label: 'Satellite Dish'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'interiorFeatures-AtticExpandable',
                inputType: InputTypes.Checkbox,
                label: 'Attic Expandable'
              },
              {
                name: 'interiorFeatures-BeamedCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Beamed Ceilings'
              },
              {
                name: 'interiorFeatures-Bookcases',
                inputType: InputTypes.Checkbox,
                label: 'Bookcases'
              },
              {
                name: 'interiorFeatures-CentralVacuum',
                inputType: InputTypes.Checkbox,
                label: 'Central Vacuum'
              },
              {
                name: 'interiorFeatures-DoubleVanity',
                inputType: InputTypes.Checkbox,
                label: 'Double Vanity'
              },
              {
                name: 'interiorFeatures-HighCeilings',
                inputType: InputTypes.Checkbox,
                label: 'High Ceilings'
              },
              {
                name: 'interiorFeatures-InLawFloorplan',
                inputType: InputTypes.Checkbox,
                label: 'In-Law Floorplan'
              },
              {
                name: 'interiorFeatures-MasterOnMainLevel',
                inputType: InputTypes.Checkbox,
                label: 'Master On Main Level'
              },
              {
                name: 'interiorFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'interiorFeatures-PulldownAtticStairs',
                inputType: InputTypes.Checkbox,
                label: 'Pulldown Attic Stairs'
              },
              {
                name: 'interiorFeatures-RearStairs',
                inputType: InputTypes.Checkbox,
                label: 'Rear Stairs'
              },
              {
                name: 'interiorFeatures-RoommatePlan',
                inputType: InputTypes.Checkbox,
                label: 'Roommate Plan'
              },
              {
                name: 'interiorFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'interiorFeatures-SeparateShower',
                inputType: InputTypes.Checkbox,
                label: 'Separate Shower'
              },
              {
                name: 'interiorFeatures-SoakingTub',
                inputType: InputTypes.Checkbox,
                label: 'Soaking Tub'
              },
              {
                name: 'interiorFeatures-SplitBedroomPlan',
                inputType: InputTypes.Checkbox,
                label: 'Split Bedroom Plan'
              },
              {
                name: 'interiorFeatures-SplitFoyer',
                inputType: InputTypes.Checkbox,
                label: 'Split Foyer'
              },
              {
                name: 'interiorFeatures-TileBath',
                inputType: InputTypes.Checkbox,
                label: 'Tile Bath'
              },
              {
                name: 'interiorFeatures-TrayCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling(s)'
              },
              {
                name: 'interiorFeatures-TwoStoryFoyer',
                inputType: InputTypes.Checkbox,
                label: 'Two Story Foyer'
              },
              {
                name: 'interiorFeatures-VaultedCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted Ceiling(s)'
              },
              {
                name: 'interiorFeatures-WalkInClosets',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Closet(s)'
              },
              {
                name: 'interiorFeatures-WetBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet Bar'
              },
              {
                name: 'interiorFeatures-WhirlpoolBath',
                inputType: InputTypes.Checkbox,
                label: 'Whirlpool Bath'
              },
              {
                name: 'interiorFeatures-WineCellar',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cellar'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'flooring-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },
              {
                name: 'flooring-Hardwood',
                inputType: InputTypes.Checkbox,
                label: 'Hardwood'
              },
              {
                name: 'flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
              {
                name: 'flooring-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'flooring-Pine',
                inputType: InputTypes.Checkbox,
                label: 'Pine'
              },
              {
                name: 'flooring-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'flooring-Sustainable',
                inputType: InputTypes.Checkbox,
                label: 'Sustainable'
              },
              {
                name: 'flooring-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
            ]
          }
        ]
      },
    ]
  },
  {
    sectionTitle: 'Parking',
    scope: Scopes.IndividualMapping,
    mapping: { 
      [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Charlotte]: [PropertyTypes.HomeSale],
      [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
    },
    fieldsets: [
      {
        fieldsetTitle: 'Parking',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'parking-alley',
                inputType: InputTypes.Checkbox,
                label: 'Alley'
              },
              {
                name: 'parking-attachedCarport',
                inputType: InputTypes.Checkbox,
                label: 'Attached Carport'
              },
              {
                name: 'parking-detachedCarport',
                inputType: InputTypes.Checkbox,
                label: 'Detached Carport'
              },
              {
                name: 'parking-attachedGarage',
                inputType: InputTypes.Checkbox,
                label: 'Attached Garage'
              },
              {
                name: 'parking-detachedGarage',
                inputType: InputTypes.Checkbox,
                label: 'Detached Garage'
              },
              {
                name: 'parking-driveway',
                inputType: InputTypes.Checkbox,
                label: 'Driveway'
              },
              {
                name: 'parking-offSiteParking',
                inputType: InputTypes.Checkbox,
                label: 'Off Site Parking'
              },
              {
                name: 'parking-offStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'Off Street Parking'
              },
              {
                name: 'parking-onStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'On Street Parking'
              },
              {
                name: 'parking-parkingLot',
                inputType: InputTypes.Checkbox,
                label: 'Parking Lot'
              },
              {
                name: 'parking-none',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'parking-other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Garage Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'garageFeatures-additionalStorageArea',
                inputType: InputTypes.Checkbox,
                label: 'Additional Storage Area'
              },
              {
                name: 'garageFeatures-basementGarage',
                inputType: InputTypes.Checkbox,
                label: 'Basement Garage'
              },
              {
                name: 'garageFeatures-builtIn',
                inputType: InputTypes.Checkbox,
                label: 'Built In'
              },
              {
                name: 'garageFeatures-coveredParking',
                inputType: InputTypes.Checkbox,
                label: 'Covered Parking'
              },
              {
                name: 'garageFeatures-garageFrontEntry',
                inputType: InputTypes.Checkbox,
                label: 'Garage - Front Entry'
              },
              {
                name: 'garageFeatures-garageRearEntry',
                inputType: InputTypes.Checkbox,
                label: 'Garage - Rear Entry'
              },
              {
                name: 'garageFeatures-garageSideEntry',
                inputType: InputTypes.Checkbox,
                label: 'Garage - Side Entry'
              },
              {
                name: 'garageFeatures-garageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener'
              },
              {
                name: 'garageFeatures-insideAccess',
                inputType: InputTypes.Checkbox,
                label: 'Inside Access'
              },
              {
                name: 'garageFeatures-oversized',
                inputType: InputTypes.Checkbox,
                label: 'Oversized'
              },
              {
                name: 'garageFeatures-underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              },
              {
                name: 'garageFeatures-other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'attachedGarageSpaces',
            inputType: InputTypes.Number,
            label: 'Number of Attached Garage Spaces',
            required: 'Required if Attached Garage'
          },
          {
            name: 'detachedGarageSpaces',
            inputType: InputTypes.Number,
            label: 'Number of Detached Garage Spaces',
            required: 'Required if Detached Garage'
          }
        ]
      },
      {
        fieldsetTitle: 'Parking Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'parkingFeatures-asphaltDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt Driveway'
              },
              {
                name: 'parkingFeatures-assignedParking',
                inputType: InputTypes.Checkbox,
                label: 'Assigned Parking'
              },
              {
                name: 'parkingFeatures-brickDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Brick Driveway'
              },
              {
                name: 'parkingFeatures-circularDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Circular Driveway'
              },
              {
                name: 'parkingFeatures-concreteDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Driveway'
              },
              {
                name: 'parkingFeatures-dirtDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Dirt Driveway'
              },
              {
                name: 'parkingFeatures-electVehicleCharging',
                inputType: InputTypes.Checkbox,
                label: 'Elect Vehicle Charging'
              },
              {
                name: 'parkingFeatures-fencedParking',
                inputType: InputTypes.Checkbox,
                label: 'Fenced Parking'
              },
              {
                name: 'parkingFeatures-freeParking',
                inputType: InputTypes.Checkbox,
                label: 'Free Parking'
              },
              {
                name: 'parkingFeatures-gravelDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Gravel Driveway'
              },
              {
                name: 'parkingFeatures-handicapParking',
                inputType: InputTypes.Checkbox,
                label: 'Handicap Parking'
              },
              {
                name: 'parkingFeatures-heatedParking',
                inputType: InputTypes.Checkbox,
                label: 'Heated Parking'
              },
              {
                name: 'parkingFeatures-lightedParking',
                inputType: InputTypes.Checkbox,
                label: 'Lighted Parking'
              },
              {
                name: 'parkingFeatures-onSiteParkingForRent',
                inputType: InputTypes.Checkbox,
                label: 'On-site Parking for Rent'
              },
              {
                name: 'parkingFeatures-onSiteParkingForSale',
                inputType: InputTypes.Checkbox,
                label: 'On-site Parking for Sale'
              },
              {
                name: 'parkingFeatures-parkingAttendant',
                inputType: InputTypes.Checkbox,
                label: 'Parking Attendant'
              },
              {
                name: 'parkingFeatures-parkingFee',
                inputType: InputTypes.Checkbox,
                label: 'Parking Fee'
              },
              {
                name: 'parkingFeatures-parkingSpaceConveys',
                inputType: InputTypes.Checkbox,
                label: 'Parking Space Conveys'
              },
              {
                name: 'parkingFeatures-pavedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Paved Driveway'
              },
              {
                name: 'parkingFeatures-pavedParking',
                inputType: InputTypes.Checkbox,
                label: 'Paved Parking'
              },
              {
                name: 'parkingFeatures-prepaidParking',
                inputType: InputTypes.Checkbox,
                label: 'Prepaid Parking'
              },
              {
                name: 'parkingFeatures-privateParking',
                inputType: InputTypes.Checkbox,
                label: 'Private Parking'
              },
              {
                name: 'parkingFeatures-publicParking',
                inputType: InputTypes.Checkbox,
                label: 'Public Parking'
              },
              {
                name: 'parkingFeatures-rentedOrPermitRequired',
                inputType: InputTypes.Checkbox,
                label: 'Rented/Permit Required'
              },
              {
                name: 'parkingFeatures-RVParking',
                inputType: InputTypes.Checkbox,
                label: 'RV Parking'
              },
              {
                name: 'parkingFeatures-secureParking',
                inputType: InputTypes.Checkbox,
                label: 'Secure Parking'
              },
              {
                name: 'parkingFeatures-sharedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Shared Driveway'
              },
              {
                name: 'parkingFeatures-stoneDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Stone Driveway'
              },
              {
                name: 'parkingFeatures-surfaceParking',
                inputType: InputTypes.Checkbox,
                label: 'Surface Parking'
              },
              {
                name: 'parkingFeatures-uassignedParking',
                inputType: InputTypes.Checkbox,
                label: 'Unassigned Parking'
              },
              {
                name: 'parkingFeatures-underHomeParking',
                inputType: InputTypes.Checkbox,
                label: 'Under Home Parking'
              },
              {
                name: 'parkingFeatures-unpavedParking',
                inputType: InputTypes.Checkbox,
                label: 'Unpaved Parking'
              },
              {
                name: 'parkingFeatures-none',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'attachedCarportSpaces',
            inputType: InputTypes.Number,
            label: 'Number of Attached Carport Spaces',
            required: 'Required if Attached Carport'
          },
          {
            name: 'detachedCarportSpaces',
            inputType: InputTypes.Number,
            label: 'Number of Detached Carport Spaces',
            required: 'Required if Detached Carport'
          },
          {
            name: 'assignedSpaces',
            inputType: InputTypes.Number,
            label: 'Number of Assigned Spaces',
            required: 'Required if Assigned Spaces'
          }
        ]
      },
      {
        fieldsetTitle: 'Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'parkingFeatures-Assigned',
                inputType: InputTypes.Checkbox,
                label: 'Assigned'
              },   
              {
                name: 'parkingFeatures-BasementGarage',
                inputType: InputTypes.Checkbox,
                label: 'Basement Garage'
              },  
              {
                name: 'parkingFeatures-CarportAttached',
                inputType: InputTypes.Checkbox,
                label: 'Carport Attached'
              }, 
              {
                name: 'parkingFeatures-CarportDetached',
                inputType: InputTypes.Checkbox,
                label: 'Carport Detached'
              },   
              {
                name: 'parkingFeatures-1CarCarport',
                inputType: InputTypes.Checkbox,
                label: '1 Car Carport'
              },  
              {
                name: 'parkingFeatures-2CarCarport',
                inputType: InputTypes.Checkbox,
                label: '2 Car Carport'
              },  
              {
                name: 'parkingFeatures-3CarCarport',
                inputType: InputTypes.Checkbox,
                label: '3 Car Carport'
              },  
              {
                name: 'parkingFeatures-4PlusCarCarport',
                inputType: InputTypes.Checkbox,
                label: '4+ Car Carport'
              },  
              {
                name: 'parkingFeatures-Driveway',
                inputType: InputTypes.Checkbox,
                label: 'Driveway'
              },  
              {
                name: 'parkingFeatures-ElectricCharge',
                inputType: InputTypes.Checkbox,
                label: 'Electric Veh. Charging Station'
              },  
              {
                name: 'parkingFeatures-GarageAttached',
                inputType: InputTypes.Checkbox,
                label: 'Garage Attached'
              },  
              {
                name: 'parkingFeatures-GarageDetached',
                inputType: InputTypes.Checkbox,
                label: 'Garage Detached'
              },  
              {
                name: 'parkingFeatures-1CarGarage',
                inputType: InputTypes.Checkbox,
                label: '1 Car Garage'
              },  
              {
                name: 'parkingFeatures-2CarGarage',
                inputType: InputTypes.Checkbox,
                label: '2 Car Garage'
              },  
              {
                name: 'parkingFeatures-3CarGarage',
                inputType: InputTypes.Checkbox,
                label: '3 Car Garage'
              },  
              {
                name: 'parkingFeatures-4PlusCarGarage',
                inputType: InputTypes.Checkbox,
                label: '4+ Car Garage'
              },  
              {
                name: 'parkingFeatures-GarageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener'
              },  
              {
                name: 'parkingFeatures-GarageFacesFront',
                inputType: InputTypes.Checkbox,
                label: 'Garage Faces Front'
              },  
              {
                name: 'parkingFeatures-GarageFacesRear',
                inputType: InputTypes.Checkbox,
                label: 'Garage Faces Rear'
              },  
              {
                name: 'parkingFeatures-GarageFacesSide',
                inputType: InputTypes.Checkbox,
                label: 'Garage Faces Side'
              },  
              {
                name: 'parkingFeatures-GarageShop',
                inputType: InputTypes.Checkbox,
                label: 'Garage Shop'
              },  
              {
                name: 'parkingFeatures-GolfCartGarage',
                inputType: InputTypes.Checkbox,
                label: 'Golf Cart Garage'
              },  
              {
                name: 'parkingFeatures-KeypadEntry',
                inputType: InputTypes.Checkbox,
                label: 'Keypad Entry'
              },  
              {
                name: 'parkingFeatures-OnStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'On Street Parking'
              },  
              {
                name: 'parkingFeatures-ParkingDeck',
                inputType: InputTypes.Checkbox,
                label: 'Parking Deck'
              },  
              {
                name: 'parkingFeatures-ParkingGarage',
                inputType: InputTypes.Checkbox,
                label: 'Parking Garage'
              },  
              {
                name: 'parkingFeatures-ParkingLot',
                inputType: InputTypes.Checkbox,
                label: 'Parking Lot'
              },  
              {
                name: 'parkingFeatures-ParkingSpaces',
                inputType: InputTypes.Checkbox,
                label: 'Parking Spaces'
              },  
              {
                name: 'parkingFeatures-RVParking',
                inputType: InputTypes.Checkbox,
                label: 'RV Access / Parking'
              },  
              {
                name: 'parkingFeatures-SharedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Shared Driveway'
              },  
              {
                name: 'parkingFeatures-TandemGarage',
                inputType: InputTypes.Checkbox,
                label: 'Tandem Garage'
              },  
              {
                name: 'parkingFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },  
              {
                name: 'parkingFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Driveway Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'drivewayFeatures-AsphaltDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt Driveway'
              },
              {
                name: 'drivewayFeatures-BrickDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Brick Driveway'
              },
              {
                name: 'drivewayFeatures-CobblestoneDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Cobblestone Driveway'
              },
              {
                name: 'drivewayFeatures-ConcreteDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Driveway'
              },
              {
                name: 'drivewayFeatures-EarthDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Earth Driveway'
              },
              {
                name: 'drivewayFeatures-GatedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Gated Driveway'
              },
              {
                name: 'drivewayFeatures-GravelDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Gravel Driveway'
              },
              {
                name: 'drivewayFeatures-StoneDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Stone Driveway'
              },
              {
                name: 'drivewayFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'drivewayFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Parking and Driveway',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'parkingDriveway-AssignedSpaces',
                inputType: InputTypes.Checkbox,
                label: 'Assigned Spaces'
              },
              {
                name: 'parkingDriveway-Carport',
                inputType: InputTypes.Checkbox,
                label: 'Carport'
              },
              {
                name: 'parkingDriveway-CircularDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Circular Driveway'
              },
              {
                name: 'parkingDriveway-DirtDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Dirt Driveway'
              },
              {
                name: 'parkingDriveway-Lighted',
                inputType: InputTypes.Checkbox,
                label: 'Lighted'
              },
              {
                name: 'parkingDriveway-OffStreet',
                inputType: InputTypes.Checkbox,
                label: 'Off Street'
              },
              {
                name: 'parkingDriveway-OnSite',
                inputType: InputTypes.Checkbox,
                label: 'On Site'
              },
              {
                name: 'parkingDriveway-PavedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Paved Driveway'
              },
              {
                name: 'parkingDriveway-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'parkingDriveway-SharedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Shared Driveway'
              },
              {
                name: 'parkingDriveway-StreetOnlyParking',
                inputType: InputTypes.Checkbox,
                label: 'Street Only Parking'
              },
              {
                name: 'parkingDriveway-Underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              },
              {
                name: 'parkingDriveway-UnpavedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Unpaved Driveway'
              },
              {
                name: 'parkingDriveway-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Road Type / Frontage',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'roadType-Alley',
                inputType: InputTypes.Checkbox,
                label: 'Alley'
              },
              {
                name: 'roadType-Maintained',
                inputType: InputTypes.Checkbox,
                label: 'Maintained'
              },
              {
                name: 'roadType-NoFrontage',
                inputType: InputTypes.Checkbox,
                label: 'No Frontage'
              },
              {
                name: 'roadType-NotMaintained',
                inputType: InputTypes.Checkbox,
                label: 'Not Maintained'
              },
              {
                name: 'roadType-Paved',
                inputType: InputTypes.Checkbox,
                label: 'Paved'
              },
              {
                name: 'roadType-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'roadType-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public (City/Cty/St)'
              },
              {
                name: 'roadType-Unpaved',
                inputType: InputTypes.Checkbox,
                label: 'Unpaved'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'parking-1CarCarport',
                inputType: InputTypes.Checkbox,
                label: '1 Car Carport'
              },  
              {
                name: 'parking-2CarCarport',
                inputType: InputTypes.Checkbox,
                label: '2 Car Carport'
              },  
              {
                name: 'parking-3CarCarport',
                inputType: InputTypes.Checkbox,
                label: '3 Car Carport'
              },  
              {
                name: 'parking-1CarGarage',
                inputType: InputTypes.Checkbox,
                label: '1 Car Garage'
              },  
              {
                name: 'parking-2CarGarage',
                inputType: InputTypes.Checkbox,
                label: '2 Car Garage'
              },  
              {
                name: 'parking-3CarGarage',
                inputType: InputTypes.Checkbox,
                label: '3 Car Garage'
              },  
              {
                name: 'parking-AdditionalParking',
                inputType: InputTypes.Checkbox,
                label: 'Additional Parking'
              },  
              {
                name: 'parking-AssignedSpaces',
                inputType: InputTypes.Checkbox,
                label: 'Assigned Spaces'
              },  
              {
                name: 'parking-Attached',
                inputType: InputTypes.Checkbox,
                label: 'Attached'
              },  
              {
                name: 'parking-CircularDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Circular Driveway'
              },  
              {
                name: 'parking-CoveredSpaces',
                inputType: InputTypes.Checkbox,
                label: 'Covered Spaces'
              },  
              {
                name: 'parking-Detached',
                inputType: InputTypes.Checkbox,
                label: 'Detached'
              },  
              {
                name: 'parking-Driveway',
                inputType: InputTypes.Checkbox,
                label: 'Driveway'
              },  
              {
                name: 'parking-ElectricVehicleCharging',
                inputType: InputTypes.Checkbox,
                label: 'Electric Vehicle Charging Station(s)'
              },  
              {
                name: 'parking-GarageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener'
              },  
              {
                name: 'parking-Gated',
                inputType: InputTypes.Checkbox,
                label: 'Gated'
              },  
              {
                name: 'parking-GolfCartGarage',
                inputType: InputTypes.Checkbox,
                label: 'Golf Cart Garage'
              },  
              {
                name: 'parking-Gravel',
                inputType: InputTypes.Checkbox,
                label: 'Gravel'
              },  
              {
                name: 'parking-HeatedGarage',
                inputType: InputTypes.Checkbox,
                label: 'Heated Garage'
              },  
              {
                name: 'parking-Lighted',
                inputType: InputTypes.Checkbox,
                label: 'Lighted'
              },  
              {
                name: 'parking-OffSite',
                inputType: InputTypes.Checkbox,
                label: 'Off Site'
              },  
              {
                name: 'parking-OffStreet',
                inputType: InputTypes.Checkbox,
                label: 'Off Street'
              },  
              {
                name: 'parking-OnSite',
                inputType: InputTypes.Checkbox,
                label: 'On Site'
              },  
              {
                name: 'parking-OnStreet',
                inputType: InputTypes.Checkbox,
                label: 'On Street'
              }, 
              {
                name: 'parking-Oversized',
                inputType: InputTypes.Checkbox,
                label: 'Oversized'
              },  
              {
                name: 'parking-ParkingLot',
                inputType: InputTypes.Checkbox,
                label: 'Parking Lot'
              },   
              {
                name: 'parking-ParkingPad',
                inputType: InputTypes.Checkbox,
                label: 'Parking Pad'
              },  
              {
                name: 'parking-Paved',
                inputType: InputTypes.Checkbox,
                label: 'Paved'
              },  
              {
                name: 'parking-PermitReq',
                inputType: InputTypes.Checkbox,
                label: 'Permit Required'
              }, 
              {
                name: 'parking-RVAccess',
                inputType: InputTypes.Checkbox,
                label: 'RV Access/Parking'
              }, 
              {
                name: 'parking-Secured',
                inputType: InputTypes.Checkbox,
                label: 'Secured'
              }, 
              {
                name: 'parking-SharedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Shared Driveway'
              }, 
              {
                name: 'parking-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              }, 
              {
                name: 'parking-Unassigned',
                inputType: InputTypes.Checkbox,
                label: 'Unassigned'
              }, 
              {
                name: 'parking-Underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              }, 
              {
                name: 'parking-Unpaved',
                inputType: InputTypes.Checkbox,
                label: 'Unpaved'
              }, 
              {
                name: 'parking-WorkshopGarage',
                inputType: InputTypes.Checkbox,
                label: 'Workshop in Garage'
              }, 
              {
                name: 'parking-NoParking',
                inputType: InputTypes.Checkbox,
                label: 'No Parking'
              },  
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'parking-AlleyParking',
                inputType: InputTypes.Checkbox,
                label: 'Alley Parking'
              },  
              {
                name: 'parking-AssignedSpaces',
                inputType: InputTypes.Checkbox,
                label: 'Assigned Spaces'
              },  
              {
                name: 'parking-Carport',
                inputType: InputTypes.Checkbox,
                label: 'Carport'
              },  
              {
                name: 'parking-Driveway',
                inputType: InputTypes.Checkbox,
                label: 'Driveway'
              },  
              {
                name: 'parking-Field',
                inputType: InputTypes.Checkbox,
                label: 'Field'
              },  
              {
                name: 'parking-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },  
              {
                name: 'parking-Gravel',
                inputType: InputTypes.Checkbox,
                label: 'Gravel'
              },  
              {
                name: 'parking-LightedParking',
                inputType: InputTypes.Checkbox,
                label: 'Lighted Parking'
              },  
              {
                name: 'parking-NoParking',
                inputType: InputTypes.Checkbox,
                label: 'No Parking'
              },  
              {
                name: 'parking-ParkingDeckLotAvail',
                inputType: InputTypes.Checkbox,
                label: 'Parking Deck / Lot Avail'
              },  
              {
                name: 'parking-ParkingDeckLotIncluded',
                inputType: InputTypes.Checkbox,
                label: 'Parking Deck / Lot Included'
              },  
              {
                name: 'parking-Paved',
                inputType: InputTypes.Checkbox,
                label: 'Paved'
              },  
              {
                name: 'parking-PublicOnStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'Public On-Street Parking'
              },  
              {
                name: 'parking-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },  
              {
                name: 'parking-Underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Garage / Carpot Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: 'Required if Garage or Carport',
        inputGroups: [
          {
            fields: [
              {
                name: 'garageParking-AttachedCarport',
                inputType: InputTypes.Checkbox,
                label: 'Attached Carport'
              },
              {
                name: 'garageParking-AttachedGarage',
                inputType: InputTypes.Checkbox,
                label: 'Attached Garage'
              },
              {
                name: 'garageParking-BackLoadGarage',
                inputType: InputTypes.Checkbox,
                label: 'Back Load Garage'
              },
              {
                name: 'garageParking-BasementGarage',
                inputType: InputTypes.Checkbox,
                label: 'Basement Garage'
              },
              {
                name: 'garageParking-DetachedCarport',
                inputType: InputTypes.Checkbox,
                label: 'Detached Carport'
              },
              {
                name: 'garageParking-DetachedGarage',
                inputType: InputTypes.Checkbox,
                label: 'Detached Garage'
              },
              {
                name: 'garageParking-FrontLoadGarage',
                inputType: InputTypes.Checkbox,
                label: 'Front Load Garage'
              },
              {
                name: 'garageParking-LowerLevelGarage',
                inputType: InputTypes.Checkbox,
                label: 'Lower Level Garage'
              },
              {
                name: 'garageParking-SideLoadGarage',
                inputType: InputTypes.Checkbox,
                label: 'Side Load Garage'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'parking-Assigned',
                inputType: InputTypes.Checkbox,
                label: 'Assigned'
              },
              {
                name: 'parking-Attached',
                inputType: InputTypes.Checkbox,
                label: 'Attached'
              },
              {
                name: 'parking-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'parking-Carport',
                inputType: InputTypes.Checkbox,
                label: 'Carport'
              },
              {
                name: 'parking-Detached',
                inputType: InputTypes.Checkbox,
                label: 'Detached'
              },
              {
                name: 'parking-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'parking-GarageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener'
              },
              {
                name: 'parking-Guest',
                inputType: InputTypes.Checkbox,
                label: 'Guest'
              },
              {
                name: 'parking-KitchenLevel',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Level'
              },
              {
                name: 'parking-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'parking-OffStreet',
                inputType: InputTypes.Checkbox,
                label: 'Off Street'
              },
              {
                name: 'parking-Over1SpaceperUnit',
                inputType: InputTypes.Checkbox,
                label: 'Over 1 Space per Unit'
              },
              {
                name: 'parking-ParkingPad',
                inputType: InputTypes.Checkbox,
                label: 'Parking Pad'
              },
              {
                name: 'parking-ParkingShed',
                inputType: InputTypes.Checkbox,
                label: 'Parking Shed'
              },
              {
                name: 'parking-RVBoatParking',
                inputType: InputTypes.Checkbox,
                label: 'RV/Boat Parking'
              },
              {
                name: 'parking-SideRearEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Side/Rear Entrance'
              },
              {
                name: 'parking-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
            ]
          }
        ]
      },
      {
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'parkingTotal',
            inputType: InputTypes.Number,
            label: 'Parking Total'
          },
        ]
      }
    ]
  },
  {
    sectionTitle: '',
    fieldsets: [
      {
        fieldsetTitle: 'Utilities Features',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'utilitiesFeatures-CableAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Cable Available'
              },
              {
                name: 'utilitiesFeatures-ElectricityAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Electricity Available'
              },
              {
                name: 'utilitiesFeatures-HighSpeedInternet',
                inputType: InputTypes.Checkbox,
                label: 'High Speed Internet'
              },
              {
                name: 'utilitiesFeatures-NaturalGasAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas Available'
              },
              {
                name: 'utilitiesFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'utilitiesFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'utilitiesFeatures-PhoneAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Phone Available'
              },
              {
                name: 'utilitiesFeatures-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'utilitiesFeatures-SewerAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Sewer Available'
              },
              {
                name: 'utilitiesFeatures-SewerConnected',
                inputType: InputTypes.Checkbox,
                label: 'Sewer Connected'
              },
              {
                name: 'utilitiesFeatures-UndergroundUtilities',
                inputType: InputTypes.Checkbox,
                label: 'Underground Utilities'
              },
              {
                name: 'utilitiesFeatures-WaterAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Water Available'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Telecom',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'telecom-cable',
                inputType: InputTypes.Checkbox,
                label: 'Cable'
              },
              {
                name: 'telecom-DSL',
                inputType: InputTypes.Checkbox,
                label: 'DSL'
              },
              {
                name: 'telecom-easement',
                inputType: InputTypes.Checkbox,
                label: 'Easement'
              },
              {
                name: 'telecom-fiberoptic',
                inputType: InputTypes.Checkbox,
                label: 'Fiberoptic'
              },
              {
                name: 'telecom-noService',
                inputType: InputTypes.Checkbox,
                label: 'No Service'
              },
              {
                name: 'telecom-satellite',
                inputType: InputTypes.Checkbox,
                label: 'Satellite'
              },
              {
                name: 'telecom-telephone',
                inputType: InputTypes.Checkbox,
                label: 'Telephone'
              },
              {
                name: 'telecom-underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              },
              {
                name: 'telecom-unknown',
                inputType: InputTypes.Checkbox,
                label: 'Unknown'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Electric',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'electric-atStreet',
                inputType: InputTypes.Checkbox,
                label: 'At Street'
              },
              {
                name: 'electric-easement',
                inputType: InputTypes.Checkbox,
                label: 'Easement'
              },
              {
                name: 'electric-noElectricity',
                inputType: InputTypes.Checkbox,
                label: 'No Electricity'
              },
              {
                name: 'electric-offSite',
                inputType: InputTypes.Checkbox,
                label: 'Off Site'
              },
              {
                name: 'electric-onSite',
                inputType: InputTypes.Checkbox,
                label: 'On Site'
              },
              {
                name: 'electric-underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              },
              {
                name: 'electric-unknown',
                inputType: InputTypes.Checkbox,
                label: 'Unknown'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Natural Gas',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        required: true,
        inputGroups: [
          {
            fields: [
              {
                name: 'naturalGas-atStreet',
                inputType: InputTypes.Checkbox,
                label: 'At Street'
              },
              {
                name: 'naturalGas-easement',
                inputType: InputTypes.Checkbox,
                label: 'Easement'
              },
              {
                name: 'naturalGas-noGas',
                inputType: InputTypes.Checkbox,
                label: 'No Gas'
              },
              {
                name: 'naturalGas-offSite',
                inputType: InputTypes.Checkbox,
                label: 'Off Site'
              },
              {
                name: 'naturalGas-onSite',
                inputType: InputTypes.Checkbox,
                label: 'On Site'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Current Use',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'currentUse-Residential',
                inputType: InputTypes.Checkbox,
                label: 'Residential'
              },
              {
                name: 'currentUse-Agriculture',
                inputType: InputTypes.Checkbox,
                label: 'Agriculture'
              },
              {
                name: 'currentUse-Commercial',
                inputType: InputTypes.Checkbox,
                label: 'Commercial'
              },
              {
                name: 'currentUse-Hunting',
                inputType: InputTypes.Checkbox,
                label: 'Hunting'
              },
              {
                name: 'currentUse-Industrial',
                inputType: InputTypes.Checkbox,
                label: 'Industrial'
              },
              {
                name: 'currentUse-LandLotOnly',
                inputType: InputTypes.Checkbox,
                label: 'Land/Lot Only'
              },
              {
                name: 'currentUse-Livestock',
                inputType: InputTypes.Checkbox,
                label: 'Livestock'
              },
              {
                name: 'currentUse-Mixed',
                inputType: InputTypes.Checkbox,
                label: 'Mixed'
              },
              {
                name: 'currentUse-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'currentUse-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'currentUse-Parking',
                inputType: InputTypes.Checkbox,
                label: 'Parking'
              },
              {
                name: 'currentUse-Recreation',
                inputType: InputTypes.Checkbox,
                label: 'Recreation'
              },
              {
                name: 'currentUse-Retail',
                inputType: InputTypes.Checkbox,
                label: 'Retail'
              },
              {
                name: 'currentUse-SandGravelDirt',
                inputType: InputTypes.Checkbox,
                label: 'Sand/Gravel/Dirt'
              },
              {
                name: 'currentUse-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'currentUse-Warehouse',
                inputType: InputTypes.Checkbox,
                label: 'Warehouse'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heating and Cooling',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {            
              groupTitle: 'Heating',
              scope: Scopes.ByPropertyType,
              types: [PropertyTypes.HomeSale],
              fields: [
              {
                name: 'HVAC-ActiveSolarHeat',
                inputType: InputTypes.Checkbox,
                label: 'Active Solar Heat'
              },
              {
                name: 'HVAC-Baseboard',
                inputType: InputTypes.Checkbox,
                label: 'Baseboard'
              },
              {
                name: 'HVAC-CentralAirHeat',
                inputType: InputTypes.Checkbox,
                label: 'Central Air Heat'
              },
              {
                name: 'HVAC-DuctlessMiniSplitSystem',
                inputType: InputTypes.Checkbox,
                label: 'Ductless/Mini-Split System'
              },
              {
                name: 'HVAC-ElectricHeat',
                inputType: InputTypes.Checkbox,
                label: 'Electric Heat'
              },
              {
                name: 'HVAC-EnergyStarQualHeat',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Qual - Heat'
              },
               {
                name: 'HVAC-FloorFurnace',
                inputType: InputTypes.Checkbox,
                label: 'Floor Furnace'
              },
              {
                name: 'HVAC-ForcedAir',
                inputType: InputTypes.Checkbox,
                label: 'Forced Air'
              },
              {
                name: 'HVAC-FreshAirVent',
                inputType: InputTypes.Checkbox,
                label: 'Fresh Air Ventilation'
              },
              {
                name: 'HVAC-GeothermalHeat',
                inputType: InputTypes.Checkbox,
                label: 'Geothermal Heat'
              },
              {
                name: 'HVAC-HeatPumpHeat',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump - Heat'
              },
              {
                name: 'HVAC-HotWaterHeat',
                inputType: InputTypes.Checkbox,
                label: 'Hot Water - Heat'
              },
              {
                name: 'HVAC-HumidityControlHeat',
                inputType: InputTypes.Checkbox,
                label: 'Humidity Control - Heat'
              },
              {
                name: 'HVAC-Kerosene',
                inputType: InputTypes.Checkbox,
                label: 'Kerosene'
              },
              {
                name: 'HVAC-NaturalGas',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas'
              },
              {
                name: 'HVAC-NoHeat',
                inputType: InputTypes.Checkbox,
                label: 'No Heat'
              },
              {
                name: 'HVAC-Oil',
                inputType: InputTypes.Checkbox,
                label: 'Oil'
              },       
              {
                name: 'HVAC-PassiveSolar',
                inputType: InputTypes.Checkbox,
                label: 'Passive Solar'
              },
              {
                name: 'HVAC-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'HVAC-RadiantHeat',
                inputType: InputTypes.Checkbox,
                label: 'Radiant Heat'
              },
              {
                name: 'HVAC-RadiantFloor',
                inputType: InputTypes.Checkbox,
                label: 'Radiant Floor'
              },
              {
                name: 'HVAC-SealedCombustionFireplace',
                inputType: InputTypes.Checkbox,
                label: 'Sealed Combustion Fireplce'
              },
              {
                name: 'HVAC-SealedCombustionWoodstove',
                inputType: InputTypes.Checkbox,
                label: 'Sealed Combustion Woodstv'
              },
              {
                name: 'HVAC-SteamHeat',
                inputType: InputTypes.Checkbox,
                label: 'Steam Heat'
              },
              {
                name: 'HVAC-WallFurnace',
                inputType: InputTypes.Checkbox,
                label: 'Wall Furnace'
              },
              {
                name: 'HVAC-Woodstove',
                inputType: InputTypes.Checkbox,
                label: 'Woodstove'
              },
              {
                name: 'HVAC-ZonedHeat',
                inputType: InputTypes.Checkbox,
                label: 'Zoned Heat'
              },
              {
                name: 'HVAC-OtherHeat',
                inputType: InputTypes.Checkbox,
                label: 'Other - Heat'
              },
          ]
        },
        {
          groupTitle: 'Cooling',
          scope: Scopes.ByPropertyType,
          types: [PropertyTypes.HomeSale],
          fields: [
              {
                name: 'cooling-AtticFan',
                inputType: InputTypes.Checkbox,
                label: 'Attic Fan'
              },
              {
                name: 'cooling-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'cooling-CentralAirCool',
                inputType: InputTypes.Checkbox,
                label: 'Central Air'
              },
              {
                name: 'cooling-Dual',
                inputType: InputTypes.Checkbox,
                label: 'Dual'
              },
              {
                name: 'cooling-Ductless',
                inputType: InputTypes.Checkbox,
                label: 'Ductless / Mini Split'
              },
              {
                name: 'cooling-ElectricCool',
                inputType: InputTypes.Checkbox,
                label: 'Electric - Cool'
              },
              {
                name: 'cooling-EnergyStarQualCool',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Qual Syst'
              },
              {
                name: 'cooling-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'cooling-GeothermalCool',
                inputType: InputTypes.Checkbox,
                label: 'Geothermal - Cool'
              },
              {
                name: 'cooling-HeatPumpCool',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump - Cool'
              },
              {
                name: 'cooling-HumidityControlCool',
                inputType: InputTypes.Checkbox,
                label: 'Humidity Control'
              },
              {
                name: 'cooling-MultiUnit',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Units'
              },
              {
                name: 'cooling-WallUnit',
                inputType: InputTypes.Checkbox,
                label: 'Wall Unit(s)'
              },
              {
                name: 'cooling-WindowUnit',
                inputType: InputTypes.Checkbox,
                label: 'Window Unit(s)'
              },
              {
                name: 'cooling-ZonedCool',
                inputType: InputTypes.Checkbox,
                label: 'Zoned'
              },
              {
                name: 'cooling-OtherCool',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'cooling-NoAC',
                inputType: InputTypes.Checkbox,
                label: 'No AC'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Restrictions',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'restrictions-ArchitecturalReview',
                inputType: InputTypes.Checkbox,
                label: 'Architectural Review'
              },
              {
                name: 'restrictions-BuildingTimeLimit',
                inputType: InputTypes.Checkbox,
                label: 'Building Time Limit'
              },
              {
                name: 'restrictions-CanDivide',
                inputType: InputTypes.Checkbox,
                label: 'Can Divide'
              },
              {
                name: 'restrictions-CanUseOwnBuilder',
                inputType: InputTypes.Checkbox,
                label: 'Can Use Own Builder'
              },
              {
                name: 'restrictions-DeedRestriction',
                inputType: InputTypes.Checkbox,
                label: 'Deed Restriction(s)'
              },
              {
                name: 'restrictions-DoubleMobileAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Double Mobile Allowed'
              },
              {
                name: 'restrictions-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'restrictions-Leased',
                inputType: InputTypes.Checkbox,
                label: 'Leased'
              },
              {
                name: 'restrictions-MineralRights',
                inputType: InputTypes.Checkbox,
                label: 'Mineral Rights'
              },
              {
                name: 'restrictions-ModularAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Modular Allowed'
              },
              {
                name: 'restrictions-NoMobileHomes',
                inputType: InputTypes.Checkbox,
                label: 'No Mobile Homes'
              },
              {
                name: 'restrictions-NoModularHomes',
                inputType: InputTypes.Checkbox,
                label: 'No Modular Homes'
              },
              {
                name: 'restrictions-NoRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'No Restrictions'
              },
              {
                name: 'restrictions-OwnerBuilder',
                inputType: InputTypes.Checkbox,
                label: 'Owner/Builder'
              },
              {
                name: 'restrictions-RecordSubdivision',
                inputType: InputTypes.Checkbox,
                label: 'Record Subdivision'
              },
              {
                name: 'restrictions-ResidentialCovenants',
                inputType: InputTypes.Checkbox,
                label: 'Residential Covenants'
              },
              {
                name: 'restrictions-SingleMobileAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Single Mobile Allowed'
              },
              {
                name: 'restrictions-SubjectToZoning',
                inputType: InputTypes.Checkbox,
                label: 'Subject to Zoning'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Restrictions',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'restrictions-ArchitecturalReview',
                inputType: InputTypes.Checkbox,
                label: 'Architectural Review'
              },
              {
                name: 'restrictions-Building',
                inputType: InputTypes.Checkbox,
                label: 'Building'
              },
              {
                name: 'restrictions-Deed',
                inputType: InputTypes.Checkbox,
                label: 'Deed'
              },
              {
                name: 'restrictions-Height',
                inputType: InputTypes.Checkbox,
                label: 'Height'
              },
              {
                name: 'restrictions-Historical',
                inputType: InputTypes.Checkbox,
                label: 'Historical'
              },
              {
                name: 'restrictions-LivestockRestriction',
                inputType: InputTypes.Checkbox,
                label: 'Livestock Restriction'
              },
              {
                name: 'restrictions-ManufacturedHomeAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Manufactured Home Allowed'
              },
              {
                name: 'restrictions-ManufacturedNotAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Manufactured NOT Allowed'
              },
              {
                name: 'restrictions-ModularAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Modular Allowed'
              },
              {
                name: 'restrictions-ModularNotAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Modular NOT Allowed'
              },
              {
                name: 'restrictions-NoRepresentation',
                inputType: InputTypes.Checkbox,
                label: 'No Representation'
              },
              {
                name: 'restrictions-NoRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'No Restrictions'
              },
              {
                name: 'restrictions-SquareFeet',
                inputType: InputTypes.Checkbox,
                label: 'Square Feet'
              },
              {
                name: 'restrictions-Subdivision',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision'
              },
              {
                name: 'restrictions-Use',
                inputType: InputTypes.Checkbox,
                label: 'Use'
              },
              {
                name: 'restrictions-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Topology',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.NCRegion]: [PropertyTypes.LandSale],
          [Areas.Triangle]: [PropertyTypes.LandSale],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'topology-Cleared',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
                label: 'Cleared'
              },
              {
                name: 'topology-ClearedPartial',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
                label: 'Cleared / Partial'
              },
              {
                name: 'topology-FloodPlain',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Flood Plain'
              },
              {
                name: 'topology-Level',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Level'
              },
              {
                name: 'topology-MarshWetland',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
                label: 'Marsh / Wetland'
              },
              {
                name: 'topology-Rolling',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Rolling'
              },
              {
                name: 'topology-Sloped',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Sloped'
              },
              {
                name: 'topology-Steep',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Steep'
              },
              {
                name: 'topology-Undeveloped',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Undeveloped'
              },
              {
                name: 'topology-Watershed',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
                label: 'Watershed'
              },
              {
                name: 'topology-Wooded',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
                label: 'Wooded'
              },
              {
                name: 'topology-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Vegetation',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'vegetation-Brush',
                inputType: InputTypes.Checkbox,
                label: 'Brush'
              },
              {
                name: 'vegetation-Cleared',
                inputType: InputTypes.Checkbox,
                label: 'Cleared'
              },
              {
                name: 'vegetation-Croplands',
                inputType: InputTypes.Checkbox,
                label: 'Croplands'
              },
              {
                name: 'vegetation-Hardwoods',
                inputType: InputTypes.Checkbox,
                label: 'Hardwoods'
              },
              {
                name: 'vegetation-HeavyWood',
                inputType: InputTypes.Checkbox,
                label: 'Heavy Wood'
              },
              {
                name: 'vegetation-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'vegetation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Road Description',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'roadDescription-Alley',
                inputType: InputTypes.Checkbox,
                label: 'Alley'
              },
              {
                name: 'roadDescription-DeededRightOfWay',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Right of Way'
              },
              {
                name: 'roadDescription-Maintained',
                inputType: InputTypes.Checkbox,
                label: 'Maintained'
              },
              {
                name: 'roadDescription-NoFrontage',
                inputType: InputTypes.Checkbox,
                label: 'No Frontage'
              },
              {
                name: 'roadDescription-NotMaintained',
                inputType: InputTypes.Checkbox,
                label: 'Not Maintained'
              },
              {
                name: 'roadDescription-Paved',
                inputType: InputTypes.Checkbox,
                label: 'Paved'
              },
              {
                name: 'roadDescription-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'roadDescription-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public (City/Cty/St)'
              },
              {
                name: 'roadDescription-ServiceRoad',
                inputType: InputTypes.Checkbox,
                label: 'Service Road'
              },
              {
                name: 'roadDescription-Unpaved',
                inputType: InputTypes.Checkbox,
                label: 'Unpaved'
              },
              {
                name: 'roadDescription-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Road Description',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'roadDescription-Alley',
                inputType: InputTypes.Checkbox,
                label: 'Alley'
              },
              {
                name: 'roadDescription-Easement',
                inputType: InputTypes.Checkbox,
                label: 'Easement'
              },
              {
                name: 'roadDescription-Unimproved',
                inputType: InputTypes.Checkbox,
                label: 'Unimproved'
              },
              {
                name: 'roadDescription-PrivateMaint',
                inputType: InputTypes.Checkbox,
                label: 'Privately Maintained Road'
              },
              {
                name: 'roadDescription-PublicMaint',
                inputType: InputTypes.Checkbox,
                label: 'Public Maintained Road'
              },
              {
                name: 'roadDescription-RoadMaintAgree',
                inputType: InputTypes.Checkbox,
                label: 'Road Maintenance Agreement'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Road Frontage',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'roadFrontage-EasementPaved',
                inputType: InputTypes.Checkbox,
                label: 'Easement Paved'
              },
              {
                name: 'roadFrontage-EasementUnpaved',
                inputType: InputTypes.Checkbox,
                label: 'Easement Unpaved'
              },
              {
                name: 'roadFrontage-HasFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Has Frontage'
              },
              {
                name: 'roadFrontage-NoEasement',
                inputType: InputTypes.Checkbox,
                label: 'No Easement'
              },
              {
                name: 'roadFrontage-NoFrontage',
                inputType: InputTypes.Checkbox,
                label: 'No Frontage'
              },
              {
                name: 'roadFrontage-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Suitable Us',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'suitableUs-Residential',
                inputType: InputTypes.Checkbox,
                label: 'Residential'
              },
              {
                name: 'suitableUs-Agriculture',
                inputType: InputTypes.Checkbox,
                label: 'Agriculture'
              },
              {
                name: 'suitableUs-Cattle',
                inputType: InputTypes.Checkbox,
                label: 'Cattle'
              },
              {
                name: 'suitableUs-Commercial',
                inputType: InputTypes.Checkbox,
                label: 'Commercial'
              },
              {
                name: 'suitableUs-Dairy',
                inputType: InputTypes.Checkbox,
                label: 'Dairy'
              },
              {
                name: 'suitableUs-Development',
                inputType: InputTypes.Checkbox,
                label: 'Development'
              },
              {
                name: 'suitableUs-Farm',
                inputType: InputTypes.Checkbox,
                label: 'Farm'
              },
              {
                name: 'suitableUs-Fishery',
                inputType: InputTypes.Checkbox,
                label: 'Fishery'
              },
              {
                name: 'suitableUs-Gardening',
                inputType: InputTypes.Checkbox,
                label: 'Gardening'
              },
              {
                name: 'suitableUs-Horses',
                inputType: InputTypes.Checkbox,
                label: 'Horses'
              },
              {
                name: 'suitableUs-Industrial',
                inputType: InputTypes.Checkbox,
                label: 'Industrial'
              },
              {
                name: 'suitableUs-MultiFamily',
                inputType: InputTypes.Checkbox,
                label: 'Multi-Family'
              },
              {
                name: 'suitableUs-Orchard',
                inputType: InputTypes.Checkbox,
                label: 'Orchard'
              },
              {
                name: 'suitableUs-PastureGrazing',
                inputType: InputTypes.Checkbox,
                label: 'Pasture/Grazing'
              },
              {
                name: 'suitableUs-Poultry',
                inputType: InputTypes.Checkbox,
                label: 'Poultry'
              },
              {
                name: 'suitableUs-PrivateEstate',
                inputType: InputTypes.Checkbox,
                label: 'Private Estate'
              },
              {
                name: 'suitableUs-Recreational2ndHouse',
                inputType: InputTypes.Checkbox,
                label: 'Recreational/2nd House'
              },
              {
                name: 'suitableUs-Subdevelopment',
                inputType: InputTypes.Checkbox,
                label: 'Subdevelopment'
              },
              {
                name: 'suitableUs-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'suitableUs-TreeFarm',
                inputType: InputTypes.Checkbox,
                label: 'Tree Farm'
              },
              {
                name: 'suitableUs-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fuel Tank',
        fieldsetDescription: '',
        required: 'Required if Fuel Tank',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'fuelTank-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'fuelTank-OilAboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Oil Above Ground'
              },
              {
                name: 'fuelTank-OilBuried',
                inputType: InputTypes.Checkbox,
                label: 'Oil Buried'
              },
              {
                name: 'fuelTank-PropaneAboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Propane Above Ground'
              },
              {
                name: 'fuelTank-PropaneBuried',
                inputType: InputTypes.Checkbox,
                label: 'Propane Buried'
              },
              {
                name: 'fuelTank-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'fuelTank-FuelTankLeased',
                inputType: InputTypes.Checkbox,
                label: 'Fuel Tank Leased'
              },
              {
                name: 'fuelTank-FuelTankOwned',
                inputType: InputTypes.Checkbox,
                label: 'Fuel Tank Owned'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heat Type',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Triangle]: [PropertyTypes.HomeSale],
          [Areas.Triad]: [PropertyTypes.HomeSale],
          [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'heatType-2ZonedHeat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: '2 Zoned Heat'
              },
              {
                name: 'heatType-3OrMoreZones',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: '3 Or More Zones'
              },
              {
                name: 'heatType-Zoned',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Zoned'
              },
              {
                name: 'heatType-Baseboard',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: 'Baseboard'
              },
              {
                name: 'heatType-BaseboardElectric',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Baseboard - Electric'
              },
              {
                name: 'heatType-BaseboardHotWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Baseboard - Hot Water'
              },
              {
                name: 'heatType-Ceiling',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Ceiling'
              },
              {
                name: 'heatType-Central',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Central'
              },
              {
                name: 'heatType-EnergyStarHeatingSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Energy Star Heating System'
              },
              {
                name: 'heatType-ForcedAir',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Forced Air'
              },
              {
                name: 'heatType-HotWaterBaseboardElectric',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Hot Water Baseboard Electric'
              },
              {
                name: 'heatType-Humidifier',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Humidifier'
              },
              {
                name: 'heatType-ProgrammableThermostat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Programmable Thermostat'
              },
              {
                name: 'heatType-Solar',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Solar'
              },
              {
                name: 'heatType-SolarActiveOrPassive',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar Active / Passive'
              },
              {
                name: 'heatType-SolarPassive',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Solar - Passive'
              },
              {
                name: 'heatType-SolarActive',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Solar - Active'
              },
              {
                name: 'heatType-SolarOffGrid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar - Off Grid'
              },
              {
                name: 'heatType-SolarOnGrid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar - On Grid'
              },
              {
                name: 'heatType-WallUnit',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Wall Unit'
              },
              {
                name: 'heatType-WoodBurningStove',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Wood Burning Stove'
              },
              {
                name: 'heatType-Zoned',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Zoned'
              },
              {
                name: 'heatType-NaturalGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Natural Gas'
              },
              {
                name: 'heatType-Oil',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Oil'
              },
              {
                name: 'heatType-PropaneGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Propane Gas'
              },
              {
                name: 'heatType-GasPack',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Gas Pack'
              },
              {
                name: 'heatType-Gas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Gas'
              },
              {
                name: 'heatType-WallOrFloorFurnace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Wall/Floor Furnace'
              },
              {
                name: 'heatType-Circulator',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: {                  
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Circulator'
              },
              {
                name: 'heatType-CirculatorHotWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Circulator Hot Water'
              },
              {
                name: 'heatType-Ductless',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: 'Ductless'
              },
              {
                name: 'heatType-HumidityControl',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Humidity Control'
              },
              {
                name: 'heatType-DualFuelSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Dual Fuel System'
              },
              {
                name: 'heatType-ElectricCeiling',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Electric Ceiling'
              },
              {
                name: 'heatType-Fireplace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Fireplace'
              },
              {
                name: 'heatType-MultiZone',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Multi-zone'
              },
              {
                name: 'heatType-MultipleSystems',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Multiple Systems'
              },
              {
                name: 'heatType-RadiantFloor',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Radiant Floor'
              },
              {
                name: 'heatType-SuspendedCeilingHeat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Suspended Ceiling Heat'
              },
              {
                name: 'heatType-WaterToAir',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Water-To-Air'
              },
              {
                name: 'heatType-CentralBoiler',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Central Boiler'
              },
              {
                name: 'heatType-Boiler',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Boiler'
              },
              {
                name: 'heatType-CoalStove',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Coal Stove'
              },
              {
                name: 'heatType-Electric',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Electric'
              },
              {
                name: 'heatType-FloorFurnace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Floor Furnace'
              },
              {
                name: 'heatType-ForcedHotAir',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Forced Hot Air'
              },
              {
                name: 'heatType-Geothermal',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Geothermal'
              },
              {
                name: 'heatType-HeatPump',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                 },
                label: {
                  default: 'Heat Pump',
                  scope: Scopes.ByMLSArea,
                  areas: {
                    [Areas.Bright]: 'Heat Pump (s)'
                  }
                }
              },
              {
                name: 'heatType-GroundWaterHeatPump',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Ground-Water Heat Pump'
              },
              {
                name: 'heatType-HotWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: 'Hot Water'
              },
              {
                name: 'heatType-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Other'
              },
              {
                name: 'heatType-Radiant',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: 'Radiant'
              },
              {
                name: 'heatType-Radiator',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Radiator'
              },
              {
                name: 'heatType-SpaceHeater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: 'Space Heater'
              },
              {
                name: 'heatType-WallFurnace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Wall Furnace'
              },
              {
                name: 'heatType-Steam',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: 'Steam',
              },
              {
                name: 'heatType-TimerThermostat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Timer Thermostat'
              },
              {
                name: 'heatType-WallFurnace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Wall Furnace'
              },
              {
                name: 'heatType-WoodFurnace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Wood Furnace'
              },
              {
                name: 'heatType-ActiveSolar',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Active Solar'
              },
              {
                name: 'heatType-PassiveSolar',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Passive Solar'
              },  
              {
                name: 'heatType-NaturalGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Natural Gas'
              },    
              {
                name: 'heatType-Oil',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Oil'
              },               
              {
                name: 'heatType-WoodStove',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: {
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  
                },
                label: 'Wood Stove'
              },
              {
                name: 'heatType-None',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'None'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heating Fuel',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Triad]: [PropertyTypes.HomeSale],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'heatingFuel-BioFuel',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Bio Fuel'
              },
              {
                name: 'heatingFuel-Central',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Central'
              },
              {
                name: 'heatingFuel-Coal',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Coal'
              },
              {
                name: 'heatingFuel-Electric',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],            
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Electric'
              },
              {
                name: 'heatingFuel-Geo-thermal',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent]
                },
                label: 'Geo-thermal'
              },
              {
                name: 'heatingFuel-MultiFuelSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Multi-Fuel System'
              },
              {
                name: 'heatingFuel-NaturalGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Natural Gas'
              },
              {
                name: 'heatingFuel-NaturalGasAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Natural Gas Available'
              },
              {
                name: 'heatingFuel-Renewable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Renewable'
              },
              {
                name: 'heatingFuel-Oil',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Oil'
              },
              {
                name: 'heatingFuel-PropaneGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Propane Gas'
              },
              {
                name: 'heatingFuel-PropaneLeased',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Propane - Leased'
              },
              {
                name: 'heatingFuel-PropaneOwned',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Propane - Owned'
              },
              {
                name: 'heatingFuel-Solar',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Solar'
              },
              {
                name: 'heatingFuel-WindTurbinePower',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Wind Turbine Power'
              },
              {
                name: 'heatingFuel-Wood',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Wood'
              },
              {
                name: 'heatingFuel-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Other'
              },
              {
                name: 'heatingFuel-None',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heating',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'heating-Baseboard',
                inputType: InputTypes.Checkbox,
                label: 'Baseboard'
              },
              {
                name: 'heating-Central',
                inputType: InputTypes.Checkbox,
                label: 'Central'
              },
              {
                name: 'heating-Common',
                inputType: InputTypes.Checkbox,
                label: 'Common'
              },
              {
                name: 'heating-Dual',
                inputType: InputTypes.Checkbox,
                label: 'Dual'
              },
              {
                name: 'heating-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'heating-FloorFurnace',
                inputType: InputTypes.Checkbox,
                label: 'Floor Furnace'
              },
              {
                name: 'heating-ForcedAir',
                inputType: InputTypes.Checkbox,
                label: 'Forced Air'
              },
              {
                name: 'heating-HeatPump',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump'
              },
              {
                name: 'heating-HotWater',
                inputType: InputTypes.Checkbox,
                label: 'Hot Water'
              },
              {
                name: 'heating-NaturalGas',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas'
              },
              {
                name: 'heating-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'heating-Oil',
                inputType: InputTypes.Checkbox,
                label: 'Oil'
              },
              {
                name: 'heating-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'heating-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'heating-Radiant',
                inputType: InputTypes.Checkbox,
                label: 'Radiant'
              },
              {
                name: 'heating-Steam',
                inputType: InputTypes.Checkbox,
                label: 'Steam'
              },
              {
                name: 'heating-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'heating-Zoned',
                inputType: InputTypes.Checkbox,
                label: 'Zoned'
              },
            ]
          }
        ]
      },
            
      {
        fieldsetTitle: 'Cooling Fuel',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'coolingFuel-BottledGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Bottled Gas'
              },
              {
                name: 'coolingFuel-Electric',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Electric'
              },
              {
                name: 'coolingFuel-Geothermal',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Geothermal'
              },
              {
                name: 'coolingFuel-NaturalGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Natural Gas'
              },
              {
                name: 'coolingFuel-Renewable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Renewable'
              },
              {
                name: 'coolingFuel-SolarPhotovoltaic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar Photovoltaic'
              },
              {
                name: 'coolingFuel-None',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'None'
              },
              {
                name: 'coolingFuel-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Cooling Type',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Triangle]: [PropertyTypes.HomeSale],
          [Areas.Triad]: [PropertyTypes.HomeSale],
          [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'coolingType-2ZonedAC',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: '2 Zoned AC'
              },
              {
                name: 'coolingType-3OrMoreZoned',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: '3 or More Zoned'
              },
              {
                name: 'coolingType-Zoned',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Zoned'
              },
              {
                name: 'coolingType-DualZoneAC',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Dual Zone A/C'
              },
              {
                name: 'coolingType-AirPurnificationSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Air Purnification System'
              },
              {
                name: 'coolingType-AtticFan',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Attic Fan(s)'
              },
              {
                name: 'coolingType-CeilingFan',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Ceiling Fan(s)'
              },
              {
                name: 'coolingType-Central',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Central'
              },
              {
                name: 'coolingType-WaterToAir',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Water-To-Air'
              },
              {
                name: 'coolingType-Chiller',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Chiller'
              },
              {
                name: 'coolingType-GroundWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Ground Water'
              },
              {
                name: 'coolingType-Dehumidifier',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Dehumidifier'
              },
              {
                name: 'coolingType-EnergyStarCoolingSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Energy Star Cooling System'
              },
              {
                name: 'coolingType-FreshAirRecoverySystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Fresh Air Recovery System'
              },
              {
                name: 'coolingType-ProgrammableThermostat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Programmable Thermostat'
              },
              {
                name: 'coolingType-RoofMounted',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Roof Mounted'
              },
              {
                name: 'coolingType-SolarAtticFan',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar - Attic Fan'
              },
              {
                name: 'coolingType-SolarOffGrid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar - Off Grid'
              },
              {
                name: 'coolingType-SolarOnGrid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar - On Grid'
              },
              {
                name: 'coolingType-SolarRoughIn',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Solar - Rough In'
              },
              {
                name: 'coolingType-WallUnit',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale]
                },
                label: 'Wall Unit(s)'
              },
              {
                name: 'coolingType-WindowUnit',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Window Unit(s)'
              },
              {
                name: 'coolingType-WindowWallUnit',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Wall / Window Unit(s)'
              },
              {
                name: 'coolingType-WholeHouseExhaustVentilation',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Whole House Exhaust Ventilation'
              },
              {
                name: 'coolingType-WholeHouseSupplyVentilation',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Whole House Supply Ventilation'
              },
              {
                name: 'coolingType-Zoned',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Zoned'
              },
              {
                name: 'coolingType-MultiZoned',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Multi-Zoned'
              },
              {
                name: 'coolingType-MultipleSystems',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Multiple Systems'
              },
              {
                name: 'coolingType-SingleLevelOnly',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Single Level Only'
              },
              {
                name: 'coolingType-Electric',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Electric'
              },
              {
                name: 'coolingType-GasAC',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Gas A/C'
              },
              {
                name: 'coolingType-GasPack',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Gas Pack'
              },
              {
                name: 'coolingType-Geothermal',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Geothermal'
              },
              {
                name: 'coolingType-HeatPump',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Heat Pump(s)'
              },
              {
                name: 'coolingType-IndividualWallUnits',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Individual Wall Units'
              },
              {
                name: 'coolingType-IndividualWindowUnits',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Individual Window Units'
              },
              {
                name: 'coolingType-None',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'None'
              },
              {
                name: 'coolingType-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Other'
              },
              {
                name: 'coolingType-TimerThermostat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Timer Thermostat'
              },
              {
                name: 'coolingType-WholeHouseFan',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: 'Whole House Fan'
              },
              {
                name: 'coolingType-CeilingFans',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Ceiling Fans'
              },
              {
                name: 'coolingType-Ductless',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Ductless'
              },
              {
                name: 'coolingType-HumidityControl',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
                label: 'Humidity Control'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Cooling',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'cooling-AtticFan',
                inputType: InputTypes.Checkbox,
                label: 'Attic Fan'
              },
              {
                name: 'cooling-CeilingFans',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan(s)'
              },
              {
                name: 'cooling-CentralAir',
                inputType: InputTypes.Checkbox,
                label: 'Central Air'
              },
              {
                name: 'cooling-Common',
                inputType: InputTypes.Checkbox,
                label: 'Common'
              },
              {
                name: 'cooling-Dual',
                inputType: InputTypes.Checkbox,
                label: 'Dual'
              },
              {
                name: 'cooling-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'cooling-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'cooling-HeatPump',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump'
              },
              {
                name: 'cooling-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'cooling-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'cooling-WholeHouseFan',
                inputType: InputTypes.Checkbox,
                label: 'Whole House Fan'
              },
              {
                name: 'cooling-WindowUnits',
                inputType: InputTypes.Checkbox,
                label: 'Window Units'
              },
              {
                name: 'cooling-Zoned',
                inputType: InputTypes.Checkbox,
                label: 'Zoned'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Heater',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.CentralVA]: [PropertyTypes.HomeSale],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Triad]: [PropertyTypes.HomeSale],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'waterHeater-BotteledGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Bottled Gas'
              },
              {
                name: 'waterHeater-Electric',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Electric'
              },
              {
                name: 'waterHeater-Gas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Gas'
              },
              {
                name: 'waterHeater-InstantHotWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Instant Hot Water'
              },
              {
                name: 'waterHeater-MultiTank',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Multi - Tank'
              },
              {
                name: 'waterHeater-Insulated',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Insulated'
              },
              {
                name: 'waterHeater-NaturalGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Natural Gas'
              },
              {
                name: 'waterHeater-OffFurnace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
                label: 'Off Furnace'
              },
              {
                name: 'waterHeater-Oil',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Oil'
              },
              {
                name: 'waterHeater-PropaneGas',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Propane Gas'
              },
              {
                name: 'waterHeater-Recirculating',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
                label: 'Recirculating'
              },
              {
                name: 'waterHeater-Solar',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Solar'
              },
              {
                name: 'waterHeater-Tank',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
                label: 'Tank'
              },
              {
                name: 'waterHeater-Tankless',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Tankless'
              },
              {
                name: 'waterHeater-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Other'
              },
              {
                name: 'waterHeater-None',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'None'
              },
              {
                name: 'waterHeater-Unknown',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Unknown'
              },
              {
                name: 'waterHeater-Wood',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Wood'
              },             
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Source',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
          [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
          [Areas.Triangle]: [PropertyTypes.LandSale],
          [Areas.Triad]: [PropertyTypes.HomeSale],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'waterSource-CityWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'City Water'
              },
              {
                name: 'waterSource-Community',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                  [Areas.Triangle]: [PropertyTypes.HomeSale],
                },
                label: {
                  default: 'Community',
                  scope: Scopes.ByMLSArea,
                  areas: { [Areas.Triad]: 'Community / Subdivision', },
                },
              },  
              {
                name: 'waterSource-CityOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: 'City on Site'
              },
              {
                name: 'waterSource-CommunityWaterOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: 'Community Water on Site'
              },            
              {
                name: 'waterSource-Conditioner',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Conditioner'
              },
              {
                name: 'waterSource-CommunityWell',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                },
                label: 'Community Well'
              },
              {
                name: 'waterSource-CountyWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'County Water'
              },
              {
                name: 'waterSource-CountyWaterOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: 'County on Site'
              },
              {
                name: 'waterSource-Easement',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Easement'
              },
              {
                name: 'waterSource-Filter',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Filter'
              }, 
              {
                name: 'waterSource-GreyWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Grey Water'
              },
              {
                name: 'waterSource-HoldingTank',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Holding Tank'
              },
              {
                name: 'waterSource-LateralNotInstalled',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Lateral Not Installed'
              },
              {
                name: 'waterSource-OnsiteWell',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Onsite Well'
              },
              {
                name: 'waterSource-PrivateWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Private Water'
              },
              {
                name: 'waterSource-PrivateCommunityWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Private Community Water'
              },
              {
                name: 'waterSource-PublicWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Public Water'
              },
              {
                name: 'waterSource-PublicWaterAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Public Water Available'
              },
              {
                name: 'waterSource-PublicWaterAtStreet',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Public Water At Street'
              },
              {
                name: 'waterSource-PublicWaterOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Public Water On Site'
              },
              {
                name: 'waterSource-PublicHookupAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Public Hookup Available'
              },
              {
                name: 'waterSource-PublicWaterNearby',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: 'Public Water Nearby'
              },
              {
                name: 'waterSource-RainwaterHarvesting',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Rainwater Harvesting'
              },  
              {
                name: 'waterSource-SharedSpring',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Shared Spring'
              },
              {
                name: 'waterSource-WellShared',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.LandSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                 },
                label: 'Shared Well'
              },      
              {
                name: 'waterSource-Spring',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Spring'
              },      
              {
                name: 'waterSource-TapFee',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Tap Fee'
              },
              {
                name: 'waterSource-TapFeeRequired',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'Tap Fee Required'
              },
              {
                name: 'waterSource-TapFeesPaid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Tap Fees Paid'
              },
              {
                name: 'waterSource-WellWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Well Water'
              },
              {
                name: 'waterSource-WellInstalled',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'Well Installed'
              },
              {
                name: 'waterSource-WellNeeded',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Well Needed'
              },

              {
                name: 'waterSource-WellOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: 'Well on Site'
              },
              {
                name: 'waterSource-WellPermitAppliedFor',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Well Permit Applied For'
              },
              {
                name: 'waterSource-WellPermitNotAppliedFor',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Well Permit NOT Applied For'
              },
              {
                name: 'waterSource-WellPermitOnFile',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Well Permit On File'
              },
              {
                name: 'waterSource-WellRequired',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Well Required'
              },
              {
                name: 'waterSource-Within50Feet',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Within 50 Feet'
              },
              {
                name: 'waterSource-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Other'
              },
              {
                name: 'waterSource-None',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Sewer Type',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
          [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
          [Areas.Triangle]: [PropertyTypes.LandSale],
          [Areas.Triad]: [PropertyTypes.HomeSale],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'sewerType-AlternativeSeptic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Alternative Septic'
              },  
              {
                name: 'sewerType-AppliedForPermit',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Applied for Permit'
              },
              {
                name: 'sewerType-ApprovedSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Approved System'
              },
              {
                name: 'sewerType-CappingFill',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Capping Fill'
              },
              {
                name: 'sewerType-CessPool',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Cess Pool'
              },
              {
                name: 'sewerType-CitySewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'City Sewer'
              },
              {
                name: 'sewerType-CountySewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'County Sewer'
              },
              {
                name: 'sewerType-CommunitySewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Community Sewer'
              },
              {
                name: 'sewerType-ConventionalSeptic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                },
                label: 'Conventional Septic'
              },              
              {
                name: 'sewerType-Easement',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Easement'
              },
              {
                name: 'sewerType-GravitySepticField',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Gravity Septic Field'
              },
              {
                name: 'sewerType-GrinderOrLiftPump',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Grinder or Lift Pump'
              },
                            {
                name: 'sewerType-GrinderPump',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Grinder Pump'
              },
              {
                name: 'sewerType-HoldingTank',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Holding Tank'
              },
              {
                name: 'sewerType-LateralTapOffMain',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Lateral Tap Off Main'
              },
              {
                name: 'sewerType-LateralTapOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Lateral Tap on Site'
              },
              {
                name: 'sewerType-LowPressurePipe',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Low Pressure Pipe'
              },
              {
                name: 'sewerType-NoPerk',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: 'No Perk'
              },
              {
                name: 'sewerType-NoSepticApproved',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'No Septic Approved'
              },
              {
                name: 'sewerType-NoSepticSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'No Septic System'
              },
              {
                name: 'sewerType-NoSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                },
                label: 'No Sewer'
              },
              {
                name: 'sewerType-NoSewerSystem',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'No Sewer System'
              },
              {
                name: 'sewerType-NotAppliedForPermit',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Not Applied For Permit'
              },
              {
                name: 'sewerType-OnSiteSeptic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.LandSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale]
                },
                label: 'On Site Septic'
              },
              {
                name: 'sewerType-PercApprovedSeptic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Perc Approved Septic'
              },              
              {
                name: 'sewerType-PrivateSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Bright]: [PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Private Sewer'
              },
              {
                name: 'sewerType-PrivateCommunitySeptic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Private Community Septic'
              },              
              {
                name: 'sewerType-PublicSewerAtStreet',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Public Sewer At Street'
              },
              {
                name: 'sewerType-PublicSewerOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Public Sewer On Site'
              },
              {
                name: 'sewerType-PublicHookupAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Public Hookup Available'
              },              
              {
                name: 'sewerType-PublicSeptic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Public Septic'
              },
              {
                name: 'sewerType-PublicSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Public Sewer'
              },
              {
                name: 'sewerType-PublicSewerAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Public Sewer Available'
              },
              {
                name: 'sewerType-PublicSewerNearby',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: 'Public Sewer Nearby'
              },
              {
                name: 'sewerType-Septic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
                label: 'Septic'
              },
              {
                name: 'sewerType-SepticInstalled',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'Septic Installed'
              },
              {
                name: 'sewerType-SepticNeeded',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale]
                },
                label: 'Septic Needed'
              },
              {
                name: 'sewerType-SepticUnderWaiver',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Septic Under Waiver'
              },              
              {
                name: 'sewerType-SepticLessThanAllowed',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Septic is Less Than Allowed'
              },
              {
                name: 'sewerType-SepticMoreThanAllowed',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Septic is More Than Allowed'
              },
              {
                name: 'sewerType-SepticEqualToAllowed',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
                label: 'Septic is Equal To Allowed'
              },       
              {
                name: 'sewerType-SepticPermitIssued',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Septic Permit Issued'
              },
              {
                name: 'sewerType-SewerTapFee',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Sewer Tap Fee'
              },
              {
                name: 'sewerType-SharedSeptic',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.LandSale],
                  [Areas.Bright]: [PropertyTypes.LandSale],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                },
                label: 'Shared Septic'
              },     
              {
                name: 'sewerType-SharedSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Shared Sewer'
              },
              {
                name: 'sewerType-SiteEvaluationOnFile',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Site Evaluation on File'
              },
              {
                name: 'sewerType-StandardTrenchApproved',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Standard Trench Approved'
              },
              {
                name: 'sewerType-TapFeesPaid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: 'Tap Fees Paid'
              },
              {
                name: 'sewerType-TapFeesRequired',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
                label: 'Tap Fees Required'
              },
              {
                name: 'sewerType-Within50Feet',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
                label: 'Within 50 Feet'
              },  
              {
                name: 'sewerType-2BarMax',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: '2 Br Max'
              },
              {
                name: 'sewerType-3BarMax',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: '3 Br Max'
              },
              {
                name: 'sewerType-4BarMax',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: '4 Br Max'
              },
              {
                name: 'sewerType-5PlusBarMax',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
                label: '5+ Br Max'
              },
              {
                name: 'sewerType-1SepticSitePossible',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
                label: '1+ Septic Site Possible'
              },     
              {
                name: 'sewerType-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.LandSale],
                  [Areas.Triad]: [PropertyTypes.HomeSale],
                },
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water, Sewer, Other Utilities',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { 
          [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
          [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
          [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'waterSewerType-CommunityWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                },
                label: 'Community Water'
              },
              {
                name: 'waterSewerType-CommunitySewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                },
                label: 'Community Sewer'
              },
              {
                name: 'waterSewerType-CommunitySewerAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Community Sewer Available'
              },

              {
                name: 'waterSewerType-CommunityWaterAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Community Water Available'
              },
              {
                name: 'waterSewerType-MunicipalSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Municipal Sewer'
              },
              {
                name: 'waterSewerType-MunicipalSewerAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Municipal Sewer Available'
              },
              {
                name: 'waterSewerType-MunicipalWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Municipal Water'
              },
              {
                name: 'waterSewerType-MunicipalWaterAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Municipal Water Available'
              },
              {
                name: 'waterSewerType-NaturalGasAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Natural Gas Available'
              },
              {
                name: 'waterSewerType-NaturalGasConnected',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Natural Gas Connected'
              },
              {
                name: 'waterSewerType-PrivateSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                },
                label: 'Private Sewer'
              },
              {
                name: 'waterSewerType-PrivateWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                },
                label: 'Private Water'
              },
              {
                name: 'waterSewerType-PublicSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent], 
                           [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                          },                
                label: 'Public Sewer'
              },
              {
                name: 'waterSewerType-PublicWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                },
                label: 'Public Water'
              },
              {
                name: 'waterSewerType-SepticOffSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Septic Off Site'
              },
              {
                name: 'waterSewerType-SepticOnSite',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Septic On Site'
              },
              {
                name: 'waterSewerType-SepticTank',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Septic Tank'
              },
              {
                name: 'waterSewerType-SepticNeeded',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Septic Needed'
              },
              {
                name: 'waterSewerType-SepticShared',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Septic Shared'
              },
              {
                name: 'waterSewerType-SewerTapAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Sewer Tap Available'
              },
              {
                name: 'waterSewerType-SewerTapPaid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale], },
                label: 'Sewer Tap Paid'
              },
              {
                name: 'waterSewerType-WaterTapAvailable',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Water Tap Available'
              },
              {
                name: 'waterSewerType-WaterTapPaid',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale], },
                label: 'Water Tap Paid'
              },
              {
                name: 'waterSewerType-WellWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                },
                label: 'Well Water'
              },
              {
                name: 'waterSewerType-SewerConnected',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Sewer Connected'
              },
              {
                name: 'waterSewerType-WaterConnected',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], },
                label: 'Water Connected'
              },
              {
                name: 'waterSewerType-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent], },
                label: 'Other'
              },
              {
                name: 'waterSewerType-NoSewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                },
                label: 'No Sewer'
              },
              {
                name: 'waterSewerType-NoWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { 
                  [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                  [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
                },
                label: 'No Water'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Source',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Georgia],
        inputGroups: [
          {
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            fields: [
              {
                name: 'waterSource-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'waterSource-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'waterSource-SharedWell',
                inputType: InputTypes.Checkbox,
                label: 'Shared Well'
              },
              {
                name: 'waterSource-Well',
                inputType: InputTypes.Checkbox,
                label: 'Well'
              },
            ]
          },
          {
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.LandSale],
            fields: [
              {
                name: 'waterSource-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'waterSource-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'waterSource-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'waterSource-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'waterSource-SharedWell',
                inputType: InputTypes.Checkbox,
                label: 'Shared Well'
              },
              {
                name: 'waterSource-Well',
                inputType: InputTypes.Checkbox,
                label: 'Well'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Sewer Source',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Georgia],
        inputGroups: [
          {
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            fields: [
              {
                name: 'sewerSource-PrivateSewer',
                inputType: InputTypes.Checkbox,
                label: 'Private Sewer'
              },
              {
                name: 'sewerSource-PublicSewer',
                inputType: InputTypes.Checkbox,
                label: 'Public Sewer'
              },
              {
                name: 'sewerSource-SepticTank',
                inputType: InputTypes.Checkbox,
                label: 'Septic Tank'
              },
            ]
          },
          {
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.LandSale],
            fields: [
              {
                name: 'sewerSource-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'sewerSource-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'sewerSource-PercTestOnFile',
                inputType: InputTypes.Checkbox,
                label: 'Perc Test On File'
              },
              {
                name: 'sewerSource-PrivateSewer',
                inputType: InputTypes.Checkbox,
                label: 'Private Sewer'
              },
              {
                name: 'sewerSource-PublicSewer',
                inputType: InputTypes.Checkbox,
                label: 'Public Sewer'
              },
              {
                name: 'sewerSource-SepticTank',
                inputType: InputTypes.Checkbox,
                label: 'Septic Tank'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Utilities Available',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'utilities-CableAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Cable Available'
              },
              {
                name: 'utilities-CableOnSite',
                inputType: InputTypes.Checkbox,
                label: 'Cable on Site'
              },
              {
                name: 'utilities-ElectricityAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Electricity Available'
              },
              {
                name: 'utilities-ElectricityOnSite',
                inputType: InputTypes.Checkbox,
                label: 'Electricity on Site'
              },
              {
                name: 'utilities-GasAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Gas Available'
              },
              {
                name: 'utilities-GasOnSite',
                inputType: InputTypes.Checkbox,
                label: 'Gas on Site'
              },
              {
                name: 'utilities-NoUtilities',
                inputType: InputTypes.Checkbox,
                label: 'No Utilities'
              },
              {
                name: 'utilities-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'utilities-TelephoneAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Telephone Available'
              },
              {
                name: 'utilities-TelephoneOnSite',
                inputType: InputTypes.Checkbox,
                label: 'Telephone on Site'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: '',
        fieldsetDescription: '',
        fields: [
          {
            name: 'isToShortSale',
            inputType: InputTypes.YesNo,
            label: 'Is your property subject to a Short Sale?',
            required: true,
            help: `
              A Short Sale means that negotiations may be necessary with your bank or investor in order to sell/close. 
              <a href="#" data-detailed-help="shortSale" class="link" 
              target="_blank">Click Here for More Information</a>
            `,
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
            detailedHelp: {
              shortSale: `
                A general description of a short sale means that upon the transfer of title, the sale price will be 
                insufficient to pay the total of all liens and costs of the sale and the seller will not bring 
                sufficient liquid assets to the closing to cure all deficiencies. The National Association of Realtors 
                (NAR) and the MLS's require the seller to agree to this disclosure within the MLS system and agents will 
                be able to search for short sales and provide the results to their clients/customers upon request.
              `
            }
          },
          {
            name: 'isInForeclosureProceedings',
            inputType: InputTypes.YesNo,
            label: 'Is your property in Foreclosure Proceedings?',
            required: true,
            help: '<b>Please note: you must notify us immediately if the property goes into Foreclosure proceedings.</b>',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.LandSale]
          },
          {
            name: 'isREOOwned',
            inputType: InputTypes.YesNo,
            label: 'Is your property REO owned? (Real Estate Owned)',
            required: true,
            help: 'A property owned by the lender or a government agency.',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] }
          },          
          {
            name: 'rentalRestrictions',
            inputType: InputTypes.Text,
            label: 'Are There Rental Restrictions',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeRent] }
          },
        ]
      },
      {
        fieldsetTitle: 'Accessibility Features',
        fieldsetDescription: '',
        required: true,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'accessibilityFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'accessibilityFeatures-2PlusAccessExits',
                inputType: InputTypes.Checkbox,
                label: '2+ Access Exits'
              },
              {
                name: 'accessibilityFeatures-32PlusWideDoors',
                inputType: InputTypes.Checkbox,
                label: '32+ Wide Doors'
              },
              {
                name: 'accessibilityFeatures-36PlusWideHalls',
                inputType: InputTypes.Checkbox,
                label: '36+ Wide Halls'
              },
              {
                name: 'accessibilityFeatures-48PlusHalls',
                inputType: InputTypes.Checkbox,
                label: '48+ Halls'
              },
              {
                name: 'accessibilityFeatures-greaterThan84Garage',
                inputType: InputTypes.Checkbox,
                label: 'Greater Than 84" Garage'
              },
              {
                name: 'accessibilityFeatures-accessibleSwitchesOrOutlets',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Switches / Outlets'
              },
              {
                name: 'accessibilityFeatures-chairlift',
                inputType: InputTypes.Checkbox,
                label: 'Chairlift'
              },
              {
                name: 'accessibilityFeatures-doorsLeverHandle',
                inputType: InputTypes.Checkbox,
                label: 'Doors - Lever Handle(s)'
              },
              {
                name: 'accessibilityFeatures-doorsRecede',
                inputType: InputTypes.Checkbox,
                label: 'Doors - Recede'
              },
              {
                name: 'accessibilityFeatures-doorsSwingIn',
                inputType: InputTypes.Checkbox,
                label: 'Doors - Swing-in'
              },
              {
                name: 'accessibilityFeatures-elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'accessibilityFeatures-entrySlopeless1',
                inputType: InputTypes.Checkbox,
                label: 'Entry Slope < 1"'
              },
              {
                name: 'accessibilityFeatures-flooringModifications',
                inputType: InputTypes.Checkbox,
                label: 'Flooring Modifications'
              },
              {
                name: 'accessibilityFeatures-grabBarsModifications',
                inputType: InputTypes.Checkbox,
                label: 'Grab Bars Modifications'
              },
              {
                name: 'accessibilityFeatures-hearingModifications',
                inputType: InputTypes.Checkbox,
                label: 'Hearing Modifications'
              },
              {
                name: 'accessibilityFeatures-kitchenModifications',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Modifications'
              },
              {
                name: 'accessibilityFeatures-levelEntryMain',
                inputType: InputTypes.Checkbox,
                label: 'Level Entry - Main'
              },
              {
                name: 'accessibilityFeatures-lowBathroomMirrors',
                inputType: InputTypes.Checkbox,
                label: 'Low Bathroom Mirrors'
              },
              {
                name: 'accessibilityFeatures-lowClosetRods',
                inputType: InputTypes.Checkbox,
                label: 'Low Closet Rods'
              },
              {
                name: 'accessibilityFeatures-lowPileCarpeting',
                inputType: InputTypes.Checkbox,
                label: 'Low Pile Carpeting'
              },
              {
                name: 'accessibilityFeatures-mobilityImprovements',
                inputType: InputTypes.Checkbox,
                label: 'Mobility Improvements'
              },
              {
                name: 'accessibilityFeatures-other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'accessibilityFeatures-otherBathModifications',
                inputType: InputTypes.Checkbox,
                label: 'Other Bath Modifications'
              },
              {
                name: 'accessibilityFeatures-rampMainLevel',
                inputType: InputTypes.Checkbox,
                label: 'Ramp - Main Level'
              },
              {
                name: 'accessibilityFeatures-rollInShower',
                inputType: InputTypes.Checkbox,
                label: 'Roll-in Shower'
              },
              {
                name: 'accessibilityFeatures-rollUnderVanity',
                inputType: InputTypes.Checkbox,
                label: 'Roll Under Vanity'
              },
              {
                name: 'accessibilityFeatures-thresholdsLessThan5Div8',
                inputType: InputTypes.Checkbox,
                label: 'Thresholds Less Than 5/8'
              },
              {
                name: 'accessibilityFeatures-vehicleTransferArea',
                inputType: InputTypes.Checkbox,
                label: 'Vehicle Transfer Area'
              },
              {
                name: 'accessibilityFeatures-visualModifications',
                inputType: InputTypes.Checkbox,
                label: 'Visual Modifications'
              },
              {
                name: 'accessibilityFeatures-wheelchairHeightMailbox',
                inputType: InputTypes.Checkbox,
                label: 'Wheelchair Height Mailbox'
              },
              {
                name: 'accessibilityFeatures-wheelchairHeightShelves',
                inputType: InputTypes.Checkbox,
                label: 'Wheelchair Height Shelves'
              },
              {
                name: 'accessibilityFeatures-wheelchairModifications',
                inputType: InputTypes.Checkbox,
                label: 'Wheelchair Modifications'
              },
            ]
          }
        ]
      },

      {
        fieldsetTitle: '',
        fieldsetDescription: '',
        fields: [
          {
            name: 'isLicensedRealEstateAgent',
            inputType: InputTypes.YesNo,
            label: 'Are you a licensed Real Estate agent?',
            required: true,
          },
          {
            name: 'ownedBy',
            inputType: InputTypes.Select,
            options: ['Corporate', 'Estate', 'Individuals', 'Other', 'Partnership', 'Relocation', 'REO'],
            label: 'Owned By',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
          },
          {
            name: 'isThereAnHoa',
            inputType: InputTypes.YesNo,
            label: 'Is there an HOA?',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
            }
          },
          {
            name: 'HOAOrCondoFee',
            inputType: InputTypes.Price,
            label: 'HOA and/or Condo Fee Amount',
            help: 'Please state if paid: monthly, semi-annually, annually',
            required: {
              default: 'Required if Applicable',
              scope: Scopes.ByPropertyType,
              types: {
                [PropertyTypes.HomeRent]: 'Needed Only if Tenant Pays'
              }
            },
          },
          {
            name: 'landUseAssessment',
            inputType: InputTypes.Select,
            options: ['Yes', 'No', 'Unknown'],
            label: 'Is there a Land Use Assessment?',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] }
          },
          {
            name: 'HOASpecialAssessment',
            inputType: InputTypes.YesNo,
            label: 'Is There a Confirmed HOA Special Assessment?',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
              [Areas.NCRegion]: [PropertyTypes.HomeSale],
            }
          },
          {
            name: 'restrectiveCovenants',
            inputType: InputTypes.Select,
            options: ['Yes', 'No'],
            label: "Are There Restrictive Covenants (CCR's)?",
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
              [Areas.NCRegion]: [PropertyTypes.HomeSale],
            }
          },
          {
            name: 'roadResponsibility',
            inputType: InputTypes.Select,
            options: ['Concrete', 'Dirt', 'Gravel', 'Paved', 'Privately Maintained Road', 'Dedicated to Public Use Pending Acceptance', 'No Road', 'Other'],
            label: 'Road Responsibility',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] }
          },
          {
            name: 'newConstruction',
            inputType: InputTypes.YesNo,
            label: 'Is this New Construction? ',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] }
          },
          {
            name: 'isHOA',
            inputType: InputTypes.YesNo,
            label: 'Is There an HOA',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.HomeRent] }
          },
          {
            name: 'tenantOccupied',
            inputType: InputTypes.YesNo,
            label: 'Is the Home Currently Leased?',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] }
          },
          {
            name: 'specialAssessments',
            inputType: InputTypes.YesNo,
            label: 'Confirmed Special Assessments',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] }
          },
          {
            name: 'lengthOfTimeOwningHome',
            inputType: InputTypes.Select,
            options: ['One Year or More', 'Less Than One Year', 'Does Not Own Yet'],
            label: 'Length of Time Owning the Home',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Triangle]: [PropertyTypes.HomeSale],
              [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
              
            }
          },
          {
            name: 'locationType',
            inputType: InputTypes.Select,
            options: ['Mainland', 'Island'],
            label: 'Location Type',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] }
          },
          {
            name: 'cobraZone',
            inputType: InputTypes.YesNo,
            label: 'Is the Property in a Cobra Zone?',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
            help: `
              <a href="#" data-detailed-help="cobra" class="link">What is COBRA</a>
            `,
            detailedHelp: {
              cobra: `
                <p>Coastal Barrier Resources Act, or COBRA</p>
                <p class="mt-2">
                  COBRA Act banned the sale of NFIP flood insurance for structures built or substantially improved on or after a specified date.  For more information please go to: 
                  <a href="https://www.fws.gov/cbra/Flood-Insurance.html" class="link" target="_blank">www.fws.gov/cbra/Flood-Insurance.html</a>
                </p>
              `
            }
          },
          {
            name: 'primaryResidence',
            inputType: InputTypes.YesNo,
            label: 'Is this your Primary Residence?',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.NCRegion]: [PropertyTypes.HomeSale],
              [Areas.Triangle]: [PropertyTypes.HomeSale],
              [Areas.Triad]: [PropertyTypes.HomeSale],
            }
          },
          {
            name: 'directions',
            inputType: InputTypes.Textarea,
            label: 'Driving Directions',
            required: true,
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.CentralVA]: [PropertyTypes.LandSale], 
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale], 
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
            },
            charsLimit: {
              default: 4000,
              scope: Scopes.ByMLSArea,
              areas: {
                [Areas.Charleston]: 10000,
                [Areas.Charlotte]: 500,
                [Areas.NCRegion]: 400,
                [Areas.Triangle]: 1024,
                [Areas.Triad]: 255,
                [Areas.Georgia]: 256,
                [Areas.Bright]: 1024,
                [Areas.CentralVA]: 215,
              }
            },
          },
          {
            name: 'hearAboutUs',
            inputType: InputTypes.Select,
            options: ['Google', 'Other Search Engine', 'Direct Mailing - Postcard', 'Referred by Friend', 'Repeat Customer'],
            label: 'How did you hear about us?',
            required: true,
          }
        ]
      },
    ]
  },
  {
    sectionTitle: 'Rental Information',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeRent],
    fieldsets: [
      {
        fieldsetTitle: '',
        fields: [
          {
            name: 'tenantMoveInDate',
            inputType: InputTypes.Date,
            label: 'Tenant Move In Date',
            required: true
          },
          {
            name: 'securityDeposit',
            inputType: InputTypes.Price,
            label: 'Security Deposit',
            help: {
              default: `<a href="#" data-detailed-help="depositCLXP" class="link">Click Here</a> For Deposit Information`,
              scope: Scopes.ByMLSArea,
              areas: {
                [Areas.Bright]: `<a href="#" data-detailed-help="depositLEXP" class="link">Click Here</a> For Deposit Information`,
                [Areas.CentralVA]: `<a href="#" data-detailed-help="depositLEXP" class="link">Click Here</a> For Deposit Information`,
                [Areas.Georgia]: '',
              }
            },
            required: true,
            detailedHelp: {
              depositLEXP: `
                Security deposit amounts are limited by state laws. Please check with your local county for further 
                rules regarding deposits and interest rate requirements. 
                <ul class="list-disc list-inside mt-2">
                  <li>Maryland - no more then 2 month's rent</li>
                  <li>Virginia - no more then 2 month's rent</li>
                  <li>Pennsylvania - no more then 2 month's rent</li>
                </ul>
              `,
              depositCLXP: `
              Security deposit amounts are limited by state laws.
                <ul class="list-disc list-inside mt-2">
                  <li>North Carolina - no more then 2 month's rent.</li>
                  <li>South Carolina - no limit at this time.</li>
                </ul>
              `,
            }
          },
          {
            name: 'paymentPeriod',
            inputType: InputTypes.Text,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Triangle],
            label: 'Payment Period',
            required: true
          },
          {
            name: 'moveInFee',
            inputType: InputTypes.Price,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Bright],
            label: 'Move In Fee',
            help: 'In Addition to Security Deposit',
            required: true
          },
          {
            name: 'leaseTerm',
            inputType: InputTypes.Select,
            options: {
              default: ['Month to Month', '6 Month', '1 Year', 'Long Term', 'Seasonal',  'Other'],
              scope: Scopes.ByMLSArea,
              areas: {
                [Areas.CentralVA]: ['Month to Month', '3 Month', 'Quarterly', 'Year', 'Other'],
                [Areas.Charlotte]: ['Month to Month', '1 Year', '2 Year', '3 Year', 'Other']
              }
              
            },
            scope: Scopes.ByMLSArea,
            areas: [Areas.CentralVA, Areas.Charlotte, Areas.Charleston, Areas.Coastal],
            label: 'Lease Term',
            required: true
          },
          {
            name: 'minLeaseTerm',
            inputType: InputTypes.Text,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Bright, Areas.Georgia],
            label: 'Minimum Lease Term',
            placeholder: 'Example: 6 Months',
            required: true
          },
          {
            name: 'maxLeaseTerm',
            inputType: InputTypes.Text,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Bright, Areas.Georgia],
            label: 'Maximum Lease Term',
            placeholder: 'Example: 60 Months',
            required: true
          },
          {
            name: 'applicationFee',
            inputType: InputTypes.Price,
            scope: Scopes.ByMLSArea,
            areas: [Areas.CentralVA, Areas.Bright, Areas.Triangle, Areas.Coastal, Areas.Georgia],
            label: 'Application Fee',
            required: true
          },
          {
            name: 'isApplicationFee',
            inputType: InputTypes.YesNo,
            scope: Scopes.ByMLSArea,
            areas: [Areas.NCRegion],
            label: 'Is There an Application Fee?',
            required: true
          },
          {
            name: 'arePetsAllowed',
            inputType: InputTypes.YesNo,
            scope: Scopes.ByMLSArea,
            areas: [Areas.CentralVA, Areas.Bright, Areas.Charlotte, Areas.NCRegion, Areas.Triad, Areas.Charleston, Areas.Coastal, Areas.Georgia],
            label: 'Are Pets Allowed?',
            required: true
          },
          {
            name: 'amountOfPetDeposit',
            inputType: InputTypes.Price,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Bright, Areas.Charlotte, Areas.Charleston, Areas.Coastal, Areas.Georgia],
            label: 'Amount of Pet Deposit',
            required: false
          },
          {
            name: 'amountOfMonthlyPetRent',
            inputType: InputTypes.Price,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Bright],
            label: 'Amount of Monthly Pet Rent',
            required: false
          },
          {
            name: 'optionToPurachase',
            inputType: InputTypes.YesNo,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Bright],
            label: 'Tenant Option to Purchase?',
            required: false
          },
          {
            name: 'isSmokingAllowed',
            inputType: InputTypes.YesNo,
            scope: Scopes.ByMLSArea,
            areas: [Areas.CentralVA, Areas.Charlotte, Areas.NCRegion],
            label: 'Smoking Allowed?',
            required: true
          },
          {
            name: 'isPetFee',
            inputType: InputTypes.YesNo,
            scope: Scopes.ByMLSArea,
            areas: [Areas.NCRegion],
            label: 'Is There a Pet Fee',
            required: true
          },
          {
            name: 'governmentSubsidy',
            inputType: InputTypes.YesNo,
            scope: Scopes.ByMLSArea,
            areas: [Areas.CentralVA],
            label: 'Government Subsidy Accepted?',
            required: true
          },
          {
            name: 'acceptSection8Voucher',
            inputType: InputTypes.YesNo,
            scope: Scopes.ByMLSArea,
            areas: [Areas.Triangle],
            label: 'Accept Section 8 Voucher?',
            required: true
          },
          {
            name: 'rentingPropertyFurnished',
            inputType: InputTypes.Select,
            options: ['Yes', 'No', 'Partial'],
            scope: Scopes.ByMLSArea,
            areas: [Areas.CentralVA, Areas.Bright, Areas.Charlotte, Areas.Triad, Areas.Charleston, Areas.Coastal, Areas.Georgia],
            label: 'Renting the Property Furnished?',
            required: true
          },
        ]
      },
      {
        fieldsetTitle: 'Included in Rent',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.CentralVA],
        inputGroups: [
          {
            fields: [
              {
                name: 'includedInRent-AirConditioning',
                inputType: InputTypes.Checkbox,
                label: 'Air Conditioning',
              },
              {
                name: 'includedInRent-CableTV',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV',
              },
              {
                name: 'includedInRent-CommunityAmenities',
                inputType: InputTypes.Checkbox,
                label: 'Community Amenities',
              },
              {
                name: 'includedInRent-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity',
              },
              {
                name: 'includedInRent-Heat',
                inputType: InputTypes.Checkbox,
                label: 'Heat',
              },
              {
                name: 'includedInRent-HomeHeatingOil',
                inputType: InputTypes.Checkbox,
                label: 'Home Heating Oil',
              },
              {
                name: 'includedInRent-HotWater',
                inputType: InputTypes.Checkbox,
                label: 'Hot Water',
              },
              {
                name: 'includedInRent-Internet',
                inputType: InputTypes.Checkbox,
                label: 'Internet',
              },
              {
                name: 'includedInRent-MunicipalSewerCharges',
                inputType: InputTypes.Checkbox,
                label: 'Municipal Sewer Charges',
              },
              {
                name: 'includedInRent-MunicipalWaterCharges',
                inputType: InputTypes.Checkbox,
                label: 'Municipal Water Charges',
              },
              {
                name: 'includedInRent-NaturalGasPropane',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas / Propane',
              },
              {
                name: 'includedInRent-None',
                inputType: InputTypes.Checkbox,
                label: 'None',
              },
              {
                name: 'includedInRent-Parking',
                inputType: InputTypes.Checkbox,
                label: 'Parking',
              },
              {
                name: 'includedInRent-Phone',
                inputType: InputTypes.Checkbox,
                label: 'Phone',
              },
              {
                name: 'includedInRent-Satellite',
                inputType: InputTypes.Checkbox,
                label: 'Satellite',
              },
              {
                name: 'includedInRent-TrashPickup',
                inputType: InputTypes.Checkbox,
                label: 'Trash Pickup',
              },
              {
                name: 'includedInRent-YardMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Yard Maintenance',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Included in Rent',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Bright],
        inputGroups: [
          {
            fields: [
              {
                name: 'includedInRent-AdditionalStorageSpace',
                inputType: InputTypes.Checkbox,
                label: 'Additional Storage Space',
              },
              {
                name: 'includedInRent-CableTV',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV',
              },
              {
                name: 'includedInRent-CommonAreaMaintenence',
                inputType: InputTypes.Checkbox,
                label: 'Common Area Maintenence',
              },
              {
                name: 'includedInRent-CommunityCenter',
                inputType: InputTypes.Checkbox,
                label: 'Community Center',
              },
              {
                name: 'includedInRent-Cooking',
                inputType: InputTypes.Checkbox,
                label: 'Cooking',
              },
              {
                name: 'includedInRent-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity',
              },
              {
                name: 'includedInRent-FiberOpticsDwelling',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Optics at Dwelling',
              },
              {
                name: 'includedInRent-FiberOpticsAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Optics Available',
              },
              {
                name: 'includedInRent-FullMaintenence',
                inputType: InputTypes.Checkbox,
                label: 'Full Maintenence',
              },
              {
                name: 'includedInRent-Furnished',
                inputType: InputTypes.Checkbox,
                label: 'Furnished',
              },
              {
                name: 'includedInRent-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas',
              },
              {
                name: 'includedInRent-GroundsMaint',
                inputType: InputTypes.Checkbox,
                label: 'Grounds Maint',
              },
              {
                name: 'includedInRent-Heat',
                inputType: InputTypes.Checkbox,
                label: 'Heat',
              },
              {
                name: 'includedInRent-HOACondoFee',
                inputType: InputTypes.Checkbox,
                label: 'HOA/Condo Fee',
              },
              {
                name: 'includedInRent-HVACMaintenence',
                inputType: InputTypes.Checkbox,
                label: 'HVAC Maintenence',
              },
              {
                name: 'includedInRent-Insurance',
                inputType: InputTypes.Checkbox,
                label: 'Insurance',
              },
              {
                name: 'includedInRent-Janitorial',
                inputType: InputTypes.Checkbox,
                label: 'Janitorial',
              },
              {
                name: 'includedInRent-LawnService',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Service',
              },
              {
                name: 'includedInRent-LinenUtensils',
                inputType: InputTypes.Checkbox,
                label: 'Linen / Utensils',
              },
              {
                name: 'includedInRent-MaidService',
                inputType: InputTypes.Checkbox,
                label: 'Maid Service',
              },
              {
                name: 'includedInRent-Oil',
                inputType: InputTypes.Checkbox,
                label: 'Oil',
              },
              {
                name: 'includedInRent-Parking',
                inputType: InputTypes.Checkbox,
                label: 'Parking',
              },
              {
                name: 'includedInRent-PartyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Party Room',
              },
              {
                name: 'includedInRent-PestControl',
                inputType: InputTypes.Checkbox,
                label: 'Pest Control',
              },
              {
                name: 'includedInRent-RecFacility',
                inputType: InputTypes.Checkbox,
                label: 'Rec Facility',
              },
              {
                name: 'includedInRent-SecurityMonitoring',
                inputType: InputTypes.Checkbox,
                label: 'Security / Monitoring',
              },
              {
                name: 'includedInRent-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer',
              },
              {
                name: 'includedInRent-SnowRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Snow Removal',
              },
              {
                name: 'includedInRent-TrashRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Trash Removal',
              },
              {
                name: 'includedInRent-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water',
              },
              {
                name: 'includedInRent-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Tenant Pays',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Bright],
        inputGroups: [
          {
            fields: [
              {
                name: 'tenantPays-AppliancesEquipmentSome',
                inputType: InputTypes.Checkbox,
                label: 'Appliances / Equipment Some',
              },
              {
                name: 'tenantPays-CableTV',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV',
              },
              {
                name: 'tenantPays-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity',
              },
              {
                name: 'tenantPays-ExteriorMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Maintenance',
              },
              {
                name: 'tenantPays-FireplaceFlueCleaning',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace / Flue Cleaning',
              },
              {
                name: 'tenantPays-FrozenPipeDamage',
                inputType: InputTypes.Checkbox,
                label: 'Frozen Pipe Damage',
              },
              {
                name: 'tenantPays-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas',
              },
              {
                name: 'tenantPays-GutterCleaning',
                inputType: InputTypes.Checkbox,
                label: 'Gutter Cleaning',
              },
              {
                name: 'tenantPays-Heat',
                inputType: InputTypes.Checkbox,
                label: 'Heat',
              },
              {
                name: 'tenantPays-HOACondoFee',
                inputType: InputTypes.Checkbox,
                label: 'HOA/Condo Fee',
              },
              {
                name: 'tenantPays-HOACondoFeeIncrease',
                inputType: InputTypes.Checkbox,
                label: 'HOA/Condo Fee Increase',
              },
              {
                name: 'tenantPays-HotWater',
                inputType: InputTypes.Checkbox,
                label: 'Hot Water',
              },
              {
                name: 'tenantPays-HVACMaintenence',
                inputType: InputTypes.Checkbox,
                label: 'HVAC Maintenence',
              },
              {
                name: 'tenantPays-Insurance',
                inputType: InputTypes.Checkbox,
                label: 'Insurance',
              },
              {
                name: 'tenantPays-JanitorialService',
                inputType: InputTypes.Checkbox,
                label: 'Janitorial Service',
              },
              {
                name: 'tenantPays-LawnTreeShrubCare',
                inputType: InputTypes.Checkbox,
                label: 'Lawn / Tree / Shrub Care',
              },
              {
                name: 'tenantPays-LightBulbsFiltersAlarm',
                inputType: InputTypes.Checkbox,
                label: 'Light Bulbs, Filters, Alarm',
              },
              {
                name: 'tenantPays-MinorInteriorMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Minor Interior Maintenance',
              },
              {
                name: 'tenantPays-ParkingFee',
                inputType: InputTypes.Checkbox,
                label: 'Parking Fee',
              },
              {
                name: 'tenantPays-PestControl',
                inputType: InputTypes.Checkbox,
                label: 'Pest Control',
              },
              {
                name: 'tenantPays-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer',
              },
              {
                name: 'tenantPays-SnowRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Snow Removal',
              },
              {
                name: 'tenantPays-Taxes',
                inputType: InputTypes.Checkbox,
                label: 'Taxes',
              },
              {
                name: 'tenantPays-TennisPoolDues',
                inputType: InputTypes.Checkbox,
                label: 'Tennis / Pool Dues',
              },
              {
                name: 'tenantPays-TrashRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Trash Removal',
              },
              {
                name: 'tenantPays-UtilitiesAll',
                inputType: InputTypes.Checkbox,
                label: 'Utilities - All',
              },
              {
                name: 'tenantPays-UtilitiesSome',
                inputType: InputTypes.Checkbox,
                label: 'Utilities - Some',
              },
              {
                name: 'tenantPays-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water',
              },
              {
                name: 'tenantPays-WindowsScreens',
                inputType: InputTypes.Checkbox,
                label: 'Windows / Screens',
              },
              {
                name: 'tenantPays-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Tenant Responsible For',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Charlotte],
        inputGroups: [
          {
            fields: [
              {
                name: 'tenantResponsibleFor-AllExceptWater',
                inputType: InputTypes.Checkbox,
                label: 'All except water',
              },    
              {
                name: 'tenantResponsibleFor-AllUtilities',
                inputType: InputTypes.Checkbox,
                label: 'All Utilities',
              },    
              {
                name: 'tenantResponsibleFor-Cable',
                inputType: InputTypes.Checkbox,
                label: 'Cable',
              },      
              {
                name: 'tenantResponsibleFor-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity',
              },    
              {
                name: 'tenantResponsibleFor-ExteriorMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Maintenance',
              },    
              {
                name: 'tenantResponsibleFor-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas',
              },    
              {
                name: 'tenantResponsibleFor-InteriorCommonLights',
                inputType: InputTypes.Checkbox,
                label: 'Interior Common Lights',
              },    
              {
                name: 'tenantResponsibleFor-OutdoorLighting',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Lighting',
              },    
              {
                name: 'tenantResponsibleFor-TrashCollection',
                inputType: InputTypes.Checkbox,
                label: 'Trash Collection',
              },     
              {
                name: 'tenantResponsibleFor-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water',
              },    
              {
                name: 'tenantResponsibleFor-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },    
              {
                name: 'tenantResponsibleFor-None',
                inputType: InputTypes.Checkbox,
                label: 'None',
              },  
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lease Terms',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.NCRegion],
        inputGroups: [
          {
            fields: [
              {
                name: 'leaseTerm-MonthToMonth',
                inputType: InputTypes.Checkbox,
                label: 'Month-to-Month',
              },
              {
                name: 'leaseTerm-3MonthLease',
                inputType: InputTypes.Checkbox,
                label: '3 Month Lease',
              },
              {
                name: 'leaseTerm-6MonthLease',
                inputType: InputTypes.Checkbox,
                label: '6 Month Lease',
              },
              {
                name: 'leaseTerm-1YearLease',
                inputType: InputTypes.Checkbox,
                label: '1 Year Lease',
              },
              {
                name: 'leaseTerm-2YearsLease',
                inputType: InputTypes.Checkbox,
                label: '2 Years + Lease',
              },
              {
                name: 'leaseTerm-CheckCredit',
                inputType: InputTypes.Checkbox,
                label: 'Check Credit',
              },
              {
                name: 'leaseTerm-References',
                inputType: InputTypes.Checkbox,
                label: 'References',
              },
              {
                name: 'leaseTerm-Section8',
                inputType: InputTypes.Checkbox,
                label: 'Section 8',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Owner Will Pay',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.NCRegion],
        inputGroups: [
          {
            fields: [
              {
                name: 'ownerWillPay-Cable',
                inputType: InputTypes.Checkbox,
                label: 'Cable',
              },
              {
                name: 'ownerWillPay-Cooling',
                inputType: InputTypes.Checkbox,
                label: 'Cooling',
              },
              {
                name: 'ownerWillPay-Deposit',
                inputType: InputTypes.Checkbox,
                label: 'Deposit',
              },
              {
                name: 'ownerWillPay-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity',
              },
              {
                name: 'ownerWillPay-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas',
              },
              {
                name: 'ownerWillPay-Heating',
                inputType: InputTypes.Checkbox,
                label: 'Heating',
              },
              {
                name: 'ownerWillPay-HOA',
                inputType: InputTypes.Checkbox,
                label: 'HOA',
              },
              {
                name: 'ownerWillPay-LawnMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Maintenance',
              },
              {
                name: 'ownerWillPay-PestControl',
                inputType: InputTypes.Checkbox,
                label: 'Pest Control',
              },
              {
                name: 'ownerWillPay-PoolMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Pool Maintenance',
              },
              {
                name: 'ownerWillPay-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer',
              },
              {
                name: 'ownerWillPay-SnowRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Snow Removal',
              },
              {
                name: 'ownerWillPay-Supplies',
                inputType: InputTypes.Checkbox,
                label: 'Supplies',
              },
              {
                name: 'ownerWillPay-TrashRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Trash Removal',
              },
              {
                name: 'ownerWillPay-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water',
              },
              {
                name: 'ownerWillPay-None',
                inputType: InputTypes.Checkbox,
                label: 'None',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Tenant Responsible For',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.NCRegion],
        inputGroups: [
          {
            fields: [
              {
                name: 'tenantResponsibleFor-Cable',
                inputType: InputTypes.Checkbox,
                label: 'Cable',
              },
              {
                name: 'tenantResponsibleFor-Cooling',
                inputType: InputTypes.Checkbox,
                label: 'Cooling',
              },
              {
                name: 'tenantResponsibleFor-Deposit',
                inputType: InputTypes.Checkbox,
                label: 'Deposit',
              },
              {
                name: 'tenantResponsibleFor-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity',
              },
              {
                name: 'tenantResponsibleFor-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas',
              },
              {
                name: 'tenantResponsibleFor-Heating',
                inputType: InputTypes.Checkbox,
                label: 'Heating',
              },
              {
                name: 'tenantResponsibleFor-LawnMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Maintenance',
              },
              {
                name: 'tenantResponsibleFor-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer',
              },
              {
                name: 'tenantResponsibleFor-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water',
              },
              {
                name: 'tenantResponsibleFor-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
              {
                name: 'tenantResponsibleFor-None',
                inputType: InputTypes.Checkbox,
                label: 'None',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Rental Term',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Triangle],
        inputGroups: [
          {
            fields: [
              {
                name: 'rentalTerm-6Months',
                inputType: InputTypes.Checkbox,
                label: '6 Months',
              },
              {
                name: 'rentalTerm-12MonthsPlus',
                inputType: InputTypes.Checkbox,
                label: '12 Months +',
              },
              {
                name: 'rentalTerm-RentToOwn',
                inputType: InputTypes.Checkbox,
                label: 'Rent To Own',
              },
              {
                name: 'rentalTerm-ShortTerm',
                inputType: InputTypes.Checkbox,
                label: 'Short Term',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Pet Information',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Triangle],
        inputGroups: [
          {
            fields: [
              {
                name: 'petInformation-CatsAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Cats Allowed',
              },  
              {
                name: 'petInformation-DogsAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Dogs Allowed',
              },  
              {
                name: 'petInformation-DogsAllowedSizeLimit',
                inputType: InputTypes.Checkbox,
                label: 'Dogs Allowed/Size Limit',
              },  
              {
                name: 'petInformation-NoCats',
                inputType: InputTypes.Checkbox,
                label: 'No Cats',
              },  
              {
                name: 'petInformation-NoDogs',
                inputType: InputTypes.Checkbox,
                label: 'No Dogs',
              },  
              {
                name: 'petInformation-NoPets',
                inputType: InputTypes.Checkbox,
                label: 'No Pets',
              },  
              {
                name: 'petInformation-PetDeposit',
                inputType: InputTypes.Checkbox,
                label: 'Pet Deposit',
              },  
              {
                name: 'petInformation-PetFee',
                inputType: InputTypes.Checkbox,
                label: 'Pet Fee',
              },  
              {
                name: 'petInformation-PetsNegotiable',
                inputType: InputTypes.Checkbox,
                label: 'Pets Negotiable',
              },  
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Restrictions',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Triangle],
        inputGroups: [
          {
            fields: [
              {
                name: 'restrictions-NumberFamilies',
                inputType: InputTypes.Checkbox,
                label: 'Number of Families',
              },  
              {
                name: 'restrictions-NumberOccupants',
                inputType: InputTypes.Checkbox,
                label: 'Number of Occupants',
              },  
              {
                name: 'restrictions-ApprovalRequire',
                inputType: InputTypes.Checkbox,
                label: 'Approval Require',
              },  
              {
                name: 'restrictions-Limited',
                inputType: InputTypes.Checkbox,
                label: 'Limited',
              },  
              {
                name: 'restrictions-NoSmoking',
                inputType: InputTypes.Checkbox,
                label: 'No Smoking',
              },  
              {
                name: 'restrictions-NoRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'No Restrictions',
              },  
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Tenant Responsible For',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Triangle],
        inputGroups: [
          {
            fields: [
              {
                name: 'tenantResponsibleFor-Cable',
                inputType: InputTypes.Checkbox,
                label: 'Cable',
              },
              {
                name: 'tenantResponsibleFor-CommunityRecPool',
                inputType: InputTypes.Checkbox,
                label: 'Community Rec/Pool',
              },
              {
                name: 'tenantResponsibleFor-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric',
              },
              {
                name: 'tenantResponsibleFor-AirWaterFilters',
                inputType: InputTypes.Checkbox,
                label: 'Air and Water Filters',
              },
              {
                name: 'tenantResponsibleFor-GasOil',
                inputType: InputTypes.Checkbox,
                label: 'Gas/Oil',
              },
              {
                name: 'tenantResponsibleFor-LawnMaint',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Maint',
              },
              {
                name: 'tenantResponsibleFor-NoUtilities',
                inputType: InputTypes.Checkbox,
                label: 'No Utilities',
              },
              {
                name: 'tenantResponsibleFor-PartialUtilities',
                inputType: InputTypes.Checkbox,
                label: 'Partial Utilities',
              },
              {
                name: 'tenantResponsibleFor-Phone',
                inputType: InputTypes.Checkbox,
                label: 'Phone',
              },
              {
                name: 'tenantResponsibleFor-PoolMaint',
                inputType: InputTypes.Checkbox,
                label: 'Pool Maint',
              },
              {
                name: 'tenantResponsibleFor-SepticSystemMaint',
                inputType: InputTypes.Checkbox,
                label: 'Septic System Maint',
              },
              {
                name: 'tenantResponsibleFor-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer',
              },
              {
                name: 'tenantResponsibleFor-Trash',
                inputType: InputTypes.Checkbox,
                label: 'Trash',
              },
              {
                name: 'tenantResponsibleFor-Utilities',
                inputType: InputTypes.Checkbox,
                label: 'Utilities',
              },
              {
                name: 'tenantResponsibleFor-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Rental Term',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Triad],
        inputGroups: [
          {
            fields: [
              {
                name: 'rentalTerm-Year',
                inputType: InputTypes.Checkbox,
                label: 'Year',
              },
              {
                name: 'rentalTerm-6Months',
                inputType: InputTypes.Checkbox,
                label: '6 Months',
              },
              {
                name: 'rentalTerm-MonthToMonth',
                inputType: InputTypes.Checkbox,
                label: 'Month To Month',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Utilities INCLUDED in Rent',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Charleston],
        inputGroups: [
          {
            fields: [
              {
                name: 'utilities-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric',
              },
              {
                name: 'utilities-Garbage',
                inputType: InputTypes.Checkbox,
                label: 'Garbage',
              },
              {
                name: 'utilities-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas',
              },
              {
                name: 'utilities-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer',
              },
              {
                name: 'utilities-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water',
              },
              {
                name: 'utilities-None',
                inputType: InputTypes.Checkbox,
                label: 'None',
              },
              {
                name: 'utilities-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Included in Rent',
        required: true,
        scope: Scopes.ByMLSArea,
        areas: [Areas.Coastal],
        inputGroups: [
          {
            fields: [
              {
                name: 'includedInRent-ElectricCommon',
                inputType: InputTypes.Checkbox,
                label: 'Electric Common',
              },
              {
                name: 'includedInRent-WaterSewer',
                inputType: InputTypes.Checkbox,
                label: 'Water and Sewer',
              },
              {
                name: 'includedInRent-TrashPickup',
                inputType: InputTypes.Checkbox,
                label: 'Trash Pickup',
              },
              {
                name: 'includedInRent-PoolService',
                inputType: InputTypes.Checkbox,
                label: 'Pool Service',
              },
              {
                name: 'includedInRent-LandscapeLawn',
                inputType: InputTypes.Checkbox,
                label: 'Landscape/Lawn',
              },
              {
                name: 'includedInRent-Insurance',
                inputType: InputTypes.Checkbox,
                label: 'Insurance',
              },
              {
                name: 'includedInRent-Manager',
                inputType: InputTypes.Checkbox,
                label: 'Manager',
              },
              {
                name: 'includedInRent-CommonMaintRepair',
                inputType: InputTypes.Checkbox,
                label: 'Common Maint/Repair',
              },
              {
                name: 'includedInRent-Golf',
                inputType: InputTypes.Checkbox,
                label: 'Golf',
              },
              {
                name: 'includedInRent-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security',
              },
              {
                name: 'includedInRent-RecreationFacilities',
                inputType: InputTypes.Checkbox,
                label: 'Recreation Facilities',
              },
              {
                name: 'includedInRent-PrimaryAntennaCableTV',
                inputType: InputTypes.Checkbox,
                label: 'Primary Antenna/Cable TV',
              },
              {
                name: 'includedInRent-Recycling',
                inputType: InputTypes.Checkbox,
                label: 'Recycling',
              },
              {
                name: 'includedInRent-InternetAccess',
                inputType: InputTypes.Checkbox,
                label: 'Internet Access',
              },
              {
                name: 'includedInRent-PestControl',
                inputType: InputTypes.Checkbox,
                label: 'Pest Control',
              },
            ]
          }
        ]
      },
    ]
  },
  {
    sectionTitle: 'For Condo Properties Only',
    scope: Scopes.IndividualMapping,
    mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
    fieldsets: [
      {
        fields: [
          {
            name: 'washerDryerHookUpinUnit',
            inputType: InputTypes.YesNo,
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale],
            },
            label: 'Washer / Dryer Hook Up in Unit',
            required: 'Required if Condo',
          },
          {
            name: 'arePetsAllowed',
            inputType: InputTypes.YesNo,
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale],
            },
            label: 'Are Pets Allowed?',
            required: 'Required if Condo',
          },
        ]
      },
      {
        fieldsetTitle: 'Pet Restrictions Type',
        required: 'If Pets Are Allowed',
        scope: Scopes.ByMLSArea,
        areas: [Areas.Bright],
        inputGroups: [
          {
            fields: [
              {
                name: 'petRestrictionType-BreedRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Breed Restrictions',
              },
              {
                name: 'petRestrictionType-CaseByCaseBasis',
                inputType: InputTypes.Checkbox,
                label: 'Case By Case Basis',
              },
              {
                name: 'petRestrictionType-CatsOK',
                inputType: InputTypes.Checkbox,
                label: 'Cats OK',
              },
              {
                name: 'petRestrictionType-DogsOK',
                inputType: InputTypes.Checkbox,
                label: 'Dogs OK',
              },
              {
                name: 'petRestrictionType-NoPetRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'No Pet Restrictions',
              },
              {
                name: 'petRestrictionType-NumberLimit',
                inputType: InputTypes.Checkbox,
                label: 'Number Limit',
              },
              {
                name: 'petRestrictionType-SizeWeightRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Size / Weight Restrictions',
              },
              
            ]
          }
        ]
      },
    ],
  },
  {
    sectionTitle: 'Required For Condos Only',
    scope: Scopes.IndividualMapping,
    mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale] },
    fieldsets: [
      {
        fieldsetTitle: '',
        fields: [
          {
            name: 'floorUnitLocated',
            inputType: InputTypes.Text,
            required: 'Required for Condo',
            label: 'Which Floor # is the Unit Located On'
          },
          {
            name: 'sellingPropertyFurnished',
            inputType: InputTypes.YesNo,
            required: 'Required for Condo',
            label: 'Selling the Property Furnished'
          },
          {
            name: 'rentalManagementContract',
            inputType: InputTypes.YesNo,
            required: 'Required for Condo',
            label: 'Is There a Rental Management Contract'
          },
          {
            name: 'HOAIncludeInsurance',
            inputType: InputTypes.YesNo,
            required: 'Required for Condo',
            label: 'Does HOA Include Insurance'
          },
          {
            name: 'HOAIncludeHO6Insurance',
            inputType: InputTypes.YesNo,
            required: 'Required for Condo',
            label: 'Does HOA Include HO6 Insurance'
          },
          {
            name: 'garageParkingCondosAndTownHomes',
            inputType: InputTypes.Select,
            options: ['Carport', 'Private Garage', 'Parking Deck', 'Assigned', '2 Car Unit', '1.5 Car Unit', 'Under Building', 'Private 1 Car Garage', 'Private 2 Car Garage', 'Other'],
            required: 'Required for Condo',
            label: 'Garage / Parking: Condos and Townhomes'
          },
        ]
      }
    ]
  }
]


export {
  form,
}
