import { PropertyTypes } from './property-types'
import { Areas, StateNames } from './states-areas'
import { InputTypes, Scopes } from './forms/types'
import { get, capitalize } from 'lodash'
import { Localizer } from '@/utils/Localizer'
import { form as requiredForm } from '@/config/forms/required-info'
import { form as optionalForm } from '@/config/forms/optional-info'
import { formatDate, formatAdditionalOwner } from '@/utils/main'
import { options } from '@/config/marketing-options'


// Sort config

// Special names:
// 'OWNER',
// 'ADDITIONAL_OWNER',
// 'OWNER_PHONE',
// 'OWNER_EMAIL',
// 'TODAY_DATE',
// 'MLS_AREA',
// 'STATE',
// 'LISTING_TYPE',

const sorting = {
  [Areas.Bright]: {
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',      
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.county',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.petRestrictionType-',
      'propertyInfo.amountOfPetDeposit',
      'propertyInfo.amountOfMonthlyPetRent',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.directions',
      'propertyInfo.subdivision',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-associationFeeIncludes-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.seniorCommunity',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.newConstruction',
      'propertyInfo.yearBuilt',
      'propertyInfo.typeOfProperty',
      'propertyInfo.styleOfHome-',
      'propertyInfo.styleOfHome-CondosOnly-',
      'propertyInfo.constructionMaterials-',
      'propertyInfo.optional-roof-',
      'propertyInfo.optional-wallsAndCeilings-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.storiesLevels',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerType-',
      'propertyInfo.waterHeater-',
      'propertyInfo.coolingType-',
      'propertyInfo.coolingFuel-',
      'propertyInfo.heatType-',
      'propertyInfo.heatingFuel-',
      'propertyInfo.isThereBasement',
      'propertyInfo.optional-basementType-',
      'propertyInfo.optional-foundation-',
      'propertyInfo.finishedSquareFootageAboveGroundHeated',
      'propertyInfo.finishedSquareFootageBelowGroundHeated',
      'propertyInfo.unfinishedSquareFootageAboveGroundUnheated',
      'propertyInfo.unfinishedSquareFootageBelowGroundUnheated',
      'propertyInfo.mainLevelBedrooms',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.optional-mainLevelRooms-',
      'propertyInfo.upperLevelBedrooms',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.optional-upperLevelRooms-',
      'propertyInfo.upperLevelTwoBedrooms',
      'propertyInfo.upperLevelTwoFullBaths',
      'propertyInfo.upperLevelTwoHalfBaths',
      'propertyInfo.optional-secondUpperLevelRooms-',
      'propertyInfo.lowerLevelBedrooms',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.optional-lowerLevelRooms-',
      'propertyInfo.optional-extraFeatures-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.optional-windows-',
      'propertyInfo.optional-doors-',
      'propertyInfo.accessibilityFeatures-',
      'propertyInfo.rentingPropertyFurnished',
      'propertyInfo.optional-numberOfFireplaces',
      'propertyInfo.optional-fireplacesFeatures-',
      'propertyInfo.optional-laundry-',
      'propertyInfo.optional-security-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.swimmingPool',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.optional-otherStructures-',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-locationType-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.parking-',
      'propertyInfo.garageFeatures-',
      'propertyInfo.parkingFeatures-',
      'propertyInfo.attachedGarageSpaces',
      'propertyInfo.detachedGarageSpaces',
      'propertyInfo.attachedCarportSpaces',
      'propertyInfo.detachedCarportSpaces',
      'propertyInfo.assignedSpaces',
      'propertyInfo.waterOriented',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.optional-waterFrontage',
      'propertyInfo.optional-nameBodyWater',
      'propertyInfo.optional-typeOfWater-',
      'propertyInfo.optional-distanceToBodyOfWater',
      'propertyInfo.optional-waterAccessFeatures-',
      'propertyInfo.optional-waterfrontFeatures-',
      'propertyInfo.optional-numberPrivateDocks',
      'propertyInfo.optional-dockTypeFeatures-',
      'propertyInfo.optional-numberCommunityDocks',
      'propertyInfo.optional-numberSlips',
      'propertyInfo.optional-meanLowWater',
      'propertyInfo.optional-navigableWater',
      'propertyInfo.optional-riparianRightsConvey',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'MLS_AREA',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.minLeaseTerm',
      'propertyInfo.maxLeaseTerm',
      'propertyInfo.securityDeposit',
      'propertyInfo.moveInFee',
      'propertyInfo.applicationFee',
      'propertyInfo.optionToPurachase',
      'propertyInfo.includedInRent-',
      'propertyInfo.tenantPays-',
      'propertyInfo.optional-allowSmoking',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ],
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',      
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.ownershipType',
      'propertyInfo.isToShortSale',
      'propertyInfo.isREOOwned',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.isLicensedRealEstateAgent',
      'marketingOptions.homeWarrantyCoverage',
      'propertyInfo.federalFloodZone',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.petRestrictionType-',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.directions',
      'propertyInfo.subdivision',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-associationFeeIncludes-', 
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.seniorCommunity',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.newConstruction',
      'propertyInfo.yearBuilt',
      'propertyInfo.typeOfProperty',
      'propertyInfo.styleOfHome-',
      'propertyInfo.styleOfHome-CondosOnly-',
      'propertyInfo.constructionMaterials-',
      'propertyInfo.optional-roof-',
      'propertyInfo.optional-wallsAndCeilings-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.storiesLevels',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerType-',
      'propertyInfo.waterHeater-',
      'propertyInfo.coolingType-',
      'propertyInfo.coolingFuel-',
      'propertyInfo.heatType-',
      'propertyInfo.heatingFuel-',
      'propertyInfo.isThereBasement',
      'propertyInfo.optional-basementType-',
      'propertyInfo.optional-foundation-',
      'propertyInfo.finishedSquareFootageAboveGroundHeated',
      'propertyInfo.finishedSquareFootageBelowGroundHeated',
      'propertyInfo.unfinishedSquareFootageAboveGroundUnheated',
      'propertyInfo.unfinishedSquareFootageBelowGroundUnheated',
      'propertyInfo.mainLevelBedrooms',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.optional-mainLevelRooms-',
      'propertyInfo.upperLevelBedrooms',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.optional-upperLevelRooms-',
      'propertyInfo.upperLevelTwoBedrooms',
      'propertyInfo.upperLevelTwoFullBaths',
      'propertyInfo.upperLevelTwoHalfBaths',
      'propertyInfo.optional-secondUpperLevelRooms-',
      'propertyInfo.lowerLevelBedrooms',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.optional-lowerLevelRooms-',
      'propertyInfo.optional-extraFeatures-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.optional-windows-',
      'propertyInfo.optional-doors-',
      'propertyInfo.accessibilityFeatures-',
      'propertyInfo.optional-isPropertyFurnished',
      'propertyInfo.optional-numberOfFireplaces',
      'propertyInfo.optional-fireplacesFeatures-',
      'propertyInfo.optional-laundry-',
      'propertyInfo.washerDryerHookUpinUnit',
      'propertyInfo.optional-security-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-outdoorLivingStructures-',
      'propertyInfo.swimmingPool',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.optional-otherStructures-',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-locationType-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.parking-',
      'propertyInfo.garageFeatures-',
      'propertyInfo.parkingFeatures-',
      'propertyInfo.attachedGarageSpaces',
      'propertyInfo.detachedGarageSpaces',
      'propertyInfo.attachedCarportSpaces',
      'propertyInfo.detachedCarportSpaces',
      'propertyInfo.assignedSpaces',
      'propertyInfo.waterOriented',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.optional-waterFrontage',
      'propertyInfo.optional-nameBodyWater',
      'propertyInfo.optional-typeOfWater-',
      'propertyInfo.optional-distanceToBodyOfWater',
      'propertyInfo.optional-waterAccessFeatures-',
      'propertyInfo.optional-waterfrontFeatures-',
      'propertyInfo.optional-numberPrivateDocks',
      'propertyInfo.optional-dockTypeFeatures-',
      'propertyInfo.optional-numberCommunityDocks',
      'propertyInfo.optional-numberSlips',
      'propertyInfo.optional-meanLowWater',
      'propertyInfo.optional-navigableWater',
      'propertyInfo.optional-riparianRightsConvey',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'MLS_AREA',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.woodenSignPost',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview',
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.streetAddress',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.county',
      'propertyInfo.subdivision',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.isToShortSale',
      'propertyInfo.isREOOwned',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.federalFloodZone',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.directions',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-associationFeeIncludes-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.zoningCode',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.optional-utilities-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerType-',
      'propertyInfo.optional-otherStructures-',
      'propertyInfo.approxAcreage',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-locationType-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.additionalParcels',
      'propertyInfo.waterOriented',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.optional-waterFrontage',
      'propertyInfo.optional-nameBodyWater',
      'propertyInfo.optional-typeOfWater-',
      'propertyInfo.optional-distanceToBodyOfWater',
      'propertyInfo.optional-waterAccessFeatures-',
      'propertyInfo.optional-waterfrontFeatures-',
      'propertyInfo.optional-numberPrivateDocks',
      'propertyInfo.optional-dockTypeFeatures-',
      'propertyInfo.optional-numberSlips',
      'propertyInfo.optional-numberCommunityDocks',
      'propertyInfo.optional-meanLowWater',
      'propertyInfo.optional-navigableWater',
      'propertyInfo.optional-riparianRightsConvey',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.currentUse-',
      'propertyInfo.optional-possibleUse-',
      'propertyInfo.farmLandPreservation',
      'propertyInfo.optional-developmentStatus-',
      'propertyInfo.optional-buildingPermit-',
      'propertyInfo.optional-numberPercSites',
      'propertyInfo.optional-numberBedroomsPerc',
      'propertyInfo.optional-percType',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE', 
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.woodenSignPost',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview'
    ]
  },
  [Areas.CentralVA]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.typeOfProperty', 
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.streetAddress',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.newConstruction',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.yearBuilt',
      'propertyInfo.finishedSquareFootage',
      'propertyInfo.numberOfRooms',
      'propertyInfo.storiesLevels',
      'propertyInfo.numbeOfBedrooms',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-directions',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.lowerLevelFullBathsDescription',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.mainLevelFullBathsDescription',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.upperLevelFullBathsDescription',
      'propertyInfo.upperLevelTwoFullBaths',
      'propertyInfo.upperLevelTwoFullBathsDescription',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.upperLevelTwoHalfBaths',
      'propertyInfo.styleOfHome-',
      'propertyInfo.StructureOfHome-',      
      'propertyInfo.condoLocation-', 
      'propertyInfo.constructionMaterials-', 
      'propertyInfo.roof-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.golfFrontage',
      'propertyInfo.attic-',
      'propertyInfo.optional-parking-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.numberOfAttachedGarageSpaces',
      'propertyInfo.isThereBasement',
      'propertyInfo.optional-garageFeatures-',
      'propertyInfo.basement-',      
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerType-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.optional-greenCertification-',
      'propertyInfo.heatType-',
      'propertyInfo.heatingFuel-',
      'propertyInfo.coolingType-',
      'propertyInfo.optional-numberOfFireplaces',
      'propertyInfo.swimmingPool',
      'propertyInfo.optional-fireplacesFeatures-',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.waterHeater-',
      'propertyInfo.optional-porchAndDeck-',
      'propertyInfo.optional-associationFeeType-', 
      'propertyInfo.optional-otherStructures-',
      'propertyInfo.optional-waterPropertyType-',
      'propertyInfo.approxAcreage',
      'propertyInfo.optional-legalDescription',
      'marketingOptions.homeWarrantyCoverage',
      'propertyInfo.optional-inclusions', 
      'propertyInfo.optional-exclusions', 
      'propertyInfo.optional-typeDescription-',      
      'propertyInfo.yearBuilt',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-inclusions-',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.homeOccupiedBy',
      'propertyInfo.ownedBy',      
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'OWNER',
      'OWNER_PHONE',
      'ADDITIONAL_OWNER',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress', 
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.streetAddress',
      'propertyInfo.typeOfProperty',
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.directions',
      'propertyInfo.approxAcreage',
      'propertyInfo.environmentalFacotrs',
      'propertyInfo.optional-waterPropertyType-',
      'propertyInfo.landUseAssessment',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.zoningCode',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.naturalGas-',
      'propertyInfo.telecom-',
      'propertyInfo.optional-possibleUse-',
      'propertyInfo.optional-waterPropertyType-',
      'propertyInfo.optional-buildingStructures-',
      'propertyInfo.optional-restrictions-',
      'propertyInfo.optional-documentoptional-',
      'propertyInfo.optional-documentFilled-',
      'propertyInfo.optional-documentApproved-',
      'propertyInfo.sewerType-',
      'propertyInfo.optional-availableInformation-',
      'propertyInfo.waterSource-',
      'propertyInfo.electric-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.golfFrontage',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.isToShortSale',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-associationFeeType-',
      'propertyInfo.optional-inclusions-',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.ownedBy',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview',
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.typeOfProperty', 
      'propertyInfo.optional-taxOrParcelIdNumber', 
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.city',
      'propertyInfo.applicationFee',
      'propertyInfo.securityDeposit',
      'propertyInfo.governmentSubsidy',
      'propertyInfo.yearBuilt',
      'propertyInfo.finishedSquareFootage',
      'propertyInfo.numberOfRooms',
      'propertyInfo.storiesLevels',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.numbeOfBedrooms',
      'propertyInfo.numbeOfBaths',
      'propertyInfo.leaseTerm',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-directions',
      'propertyInfo.styleOfHome-',
      'propertyInfo.constructionMaterials-', 
      'propertyInfo.optional-flooring-',
      'propertyInfo.waterSource-',
      'propertyInfo.isThereBasement',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.sewerType-',
      'propertyInfo.optional-parking-',
      'propertyInfo.numberOfAttachedGarageSpaces',
      'propertyInfo.optional-garageFeatures-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.rentalRestrictions',
      'propertyInfo.includedInRent-',
      'propertyInfo.heatType-',
      'propertyInfo.heatingFuel-',
      'propertyInfo.coolingType-',
      'propertyInfo.optional-numberOfFireplaces',
      'propertyInfo.swimmingPool',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-acreage',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.rentingPropertyFurnished',
      'propertyInfo.isSmokingAllowed',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',      
      '',
      'OWNER',
      'OWNER_PHONE',
      'ADDITIONAL_OWNER',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ]
  },
  [Areas.Triad]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.typeOfProperty',
      'propertyInfo.yearBuilt',
      'propertyInfo.builtInformation',
      'propertyInfo.county',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.deedRestrictions',
      'propertyInfo.newConstruction',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'OWNER_PHONE',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.storiesLevels',
      'propertyInfo.totalNumberRooms',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.styleOfHome',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.isThereBasement',
      'propertyInfo.foundation-',
      'propertyInfo.parking-',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-lotDimensions',
      'propertyInfo.numberOfFireplaces',
      'propertyInfo.garageOrCarportSpaces',  
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-attic-',
      'propertyInfo.optional-fireplaces-',
      'propertyInfo.garageParking-',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-levelLivingRoom',
      'propertyInfo.optional-levelDiningRoom',
      'propertyInfo.optional-levelDen',
      'propertyInfo.optional-levelKitchen',
      'propertyInfo.optional-levelPrimaryBedroom',
      'propertyInfo.optional-levelBedroom2',
      'propertyInfo.optional-levelBedroom3',
      'propertyInfo.optional-levelBedroom4',
      'propertyInfo.optional-levelBedroom5',
      'propertyInfo.optional-levelBedroom6',
      'propertyInfo.optional-levelBonusRoom',
      'propertyInfo.optional-levelBreakfastRoom',
      'propertyInfo.optional-levelGreatRoom',
      'propertyInfo.optional-levelOfficeRoom',
      'propertyInfo.optional-levelHomeTheaterRoom',
      'propertyInfo.optional-levelLaundryRoom',
      'propertyInfo.optional-levelMudroom',
      'propertyInfo.optional-levelRecRoom',
      'propertyInfo.optional-levelSittingRoom',
      'propertyInfo.optional-levelWorkshop',
      'propertyInfo.optional-levelOtherRoom1',
      'propertyInfo.optional-levelOtherRoom2',
      'propertyInfo.mainLevelFinishedSquareFootage',
      'propertyInfo.secondUpperLevelFinishedSquareFootage',
      'propertyInfo.thirdLevelHeatedSquareFootage',
      'propertyInfo.basementLevelHeatedSquareFootage',
      'propertyInfo.basementLevelUnheatedSquareFootage',
      'propertyInfo.lowerLevelHeatedSquareFootage',
      'propertyInfo.lowerLevelUnheatedSquareFootage',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.isFencing',
      'propertyInfo.isPool',
      'propertyInfo.handicapAccessible',
      'propertyInfo.exteriorFinishing-',
      'propertyInfo.optional-miscellaneous-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-interiorFeatures-',      
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-appliances-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.heatType-',
      'propertyInfo.heatingFuel-',
      'propertyInfo.coolingType-',
      'propertyInfo.waterHeater-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerType-',
      'propertyInfo.optional-energyFeatures-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.directions',
      'propertyInfo.optional-inclusions', 
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.lengthOfTimeOwningHome',
      
      'propertyInfo.primaryResidence',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.zipCode',
      'propertyInfo.county',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.typeOfProperty',
      'propertyInfo.county',
      'propertyInfo.streetAddress',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'STATE',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.zoningCode',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.deedRestrictions',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',  
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.optional-documentAvailable-',
      'propertyInfo.easements',
      'propertyInfo.approxAcreage',
      'propertyInfo.optional-landDimensions',
      'propertyInfo.propertyInFloodZone',
      'propertyInfo.optional-woodedAcres',
      'propertyInfo.optional-clearedAcres',
      'propertyInfo.optional-roadFrontage',
      'propertyInfo.optional-mainRoadType-',
      'propertyInfo.optional-bestUse-',
      'propertyInfo.optional-typeDescription-',      
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-building-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-miscellaneous-',
      'propertyInfo.optional-water-',
      'propertyInfo.optional-sewerSeptic-',
      'propertyInfo.optional-gas-',
      'propertyInfo.directions',
      'propertyInfo.optional-propertyDescription', 
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.lengthOfTimeOwningHome',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.typeOfProperty',
      'propertyInfo.county',
      'propertyInfo.streetAddress',
      'propertyInfo.city',
      'propertyInfo.unitNumber',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.storiesLevels',
      'propertyInfo.yearBuilt',
      'propertyInfo.optional-totalNumberOfRooms',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.optional-styleOfHome',
      'propertyInfo.optional-isThereBasement',
      'propertyInfo.optional-numberOfFireplaces',
      'propertyInfo.parking-',
      'propertyInfo.optional-garageOrCarportSpaces',
      'propertyInfo.garageParking-',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-mainLevelFinishedSqFt',
      'propertyInfo.optional-secondUpperLevelFinishedSqFt',
      'propertyInfo.optional-thirdLevelHeatedSqFt',
      'propertyInfo.optional-basementLevelHeatedSqFt',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.securityDeposit',
      'propertyInfo.rentalTerm-',
      'propertyInfo.rentingPropertyFurnished',
      'propertyInfo.optional-isThereFencing',
      'propertyInfo.optional-isTherePool',
      'propertyInfo.optional-handicapAccessible',
      'propertyInfo.exteriorFinishing-',
      'propertyInfo.optional-miscellaneous-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.optional-heatSource-',
      'propertyInfo.optional-heatingFuel-',
      'propertyInfo.optional-coolingType-',
      'propertyInfo.optional-waterHeater-',
      'propertyInfo.optional-waterType-',
      'propertyInfo.optional-sewerType-',
      'propertyInfo.directions',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-listingAndShowingInstructions',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.zipCode',
      'propertyInfo.county',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ]
  },
  [Areas.NCRegion]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.typeOfProperty',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.zipCode',      
      'propertyInfo.subType',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',    
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.subdivision',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.directions',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.newConstruction',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-lotDimensions',
      'propertyInfo.optional-waterOptions-WaterFront',
      'propertyInfo.optional-waterOptions-WaterView',
      'propertyInfo.locationType',
      'propertyInfo.totalHeatedSquareFootage',
      'propertyInfo.totalNumberRooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',      
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.storiesLevels-',
      'propertyInfo.optional-legalDescription',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.HOASpecialAssessment',
      'propertyInfo.yearBuilt',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.restrectiveCovenants',     
      'propertyInfo.cobraZone',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'marketingOptions.lockbox',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.primaryResidence',
      'propertyInfo.numberOfAttachedGarageSpaces',
      'propertyInfo.numberOfDetachedGarageSpaces',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.optional-attic-',
      'propertyInfo.foundation-',
      'propertyInfo.construction-',
      'propertyInfo.optional-exteriorFeatures-',      
      'propertyInfo.exteriorFinishing-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.foundation-',
      'propertyInfo.fuelTank-',
      'propertyInfo.optional-greenBuildingCertificates-',
      'propertyInfo.optional-greenFeatures-',      
      'propertyInfo.parkingDriveway-',    
      'propertyInfo.optional-porchBalconyDeckPool-',
      'propertyInfo.roof-',
      'propertyInfo.totalNumberStoriesInBuilding',
      'propertyInfo.optional-typeDescription-', 
      'propertyInfo.roadType-',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.coolingType-',
      'propertyInfo.heatType-',
      'propertyInfo.waterHeater-',
      'propertyInfo.waterSewerType-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.numberOfFireplaces',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-laundry-',
      'propertyInfo.optional-typeDiningRoom',
      'propertyInfo.optional-separateLivingSpace-',
      'propertyInfo.optional-exteriorStructure-',
      'propertyInfo.optional-HOANeighbordhoodAmenites-',
      'marketingOptions.homeWarrantyCoverage',
      'propertyInfo.lengthOfTimeOwningHome',
      'propertyInfo.optional-levelPrimaryBedroom',
      'propertyInfo.optional-level2Bedroom',
      'propertyInfo.optional-level3Bedroom',
      'propertyInfo.optional-level4Bedroom',
      'propertyInfo.optional-level5Bedroom',
      'propertyInfo.optional-level6Bedroom',
      'propertyInfo.optional-levelLivingRoom',
      'propertyInfo.optional-levelDiningRoom',
      'propertyInfo.optional-levelKitchen',
      'propertyInfo.optional-levelLaundryRoom',
      'propertyInfo.optional-levelMediaRoom',
      'propertyInfo.optional-levelBonusRoom',
      'propertyInfo.optional-levelFamilyRoom',
      'propertyInfo.optional-levelOfficeStudy',
      'propertyInfo.optional-levelGreatRoom',
      'propertyInfo.optional-levelOffice',
      'propertyInfo.optional-levelBreakfastNook',
      'propertyInfo.optional-levelOtherRoom1',
      'propertyInfo.optional-levelOtherRoom2',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',     
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.typeOfProperty',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.listingPrice',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.subdivision',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.directions',
      'marketingOptions.lockbox',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-waterOptions-WaterFront',
      'propertyInfo.optional-waterOptions-WaterView',
      'propertyInfo.locationType',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.totalHeatedSquareFootage',
      'propertyInfo.totalNumberRooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.totalNumberStoriesInBuilding',
      'propertyInfo.yearBuilt',
      'propertyInfo.isHOA',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-HOANeighbordhoodAmenites-',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.isApplicationFee',
      'propertyInfo.leaseTerm-',
      'propertyInfo.isPetFee',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.securityDeposit',
      'propertyInfo.isSmokingAllowed',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.numberOfAttachedGarageSpaces',
      'propertyInfo.numberOfDetachedGarageSpaces',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.optional-construction-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-exteriorStructure-',
      'propertyInfo.optional-exteriorFinish-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.fuelTank-',
      'propertyInfo.parkingDriveway-',
      'propertyInfo.optional-porchBalconyDeckPool-',
      'propertyInfo.storiesLevels-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.roadType-',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.coolingType-',
      'propertyInfo.heatType-',
      'propertyInfo.waterHeater-',
      'propertyInfo.waterSewerType-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.numberOfFireplaces',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-attic-',
      'propertyInfo.optional-greenFeatures-',
      'propertyInfo.optional-greenBuildingCertificates-',
      'propertyInfo.optional-separateLivingSpace-',
      'propertyInfo.tenantResponsibleFor-',
      'propertyInfo.ownerWillPay-',
      'propertyInfo.lengthOfTimeOwningHome',
      'propertyInfo.optional-levelPrimaryBedroom',
      'propertyInfo.optional-level2Bedroom',
      'propertyInfo.optional-level3Bedroom',
      'propertyInfo.optional-level4Bedroom',
      'propertyInfo.optional-level5Bedroom',
      'propertyInfo.optional-level6Bedroom',
      'propertyInfo.optional-levelLivingRoom',
      'propertyInfo.optional-levelDiningRoom',
      'propertyInfo.optional-levelKitchen',
      'propertyInfo.optional-levelLaundryRoom',
      'propertyInfo.optional-levelOffice',
      'propertyInfo.optional-levelMediaRoom',
      'propertyInfo.optional-levelBonusRoom',
      'propertyInfo.optional-levelFamilyRoom',
      'propertyInfo.optional-levelGreatRoom',
      'propertyInfo.optional-levelOfficeStudy',
      'propertyInfo.optional-levelBreakfastNook',
      'propertyInfo.optional-levelOtherRoom1',
      'propertyInfo.optional-levelOtherRoom2',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.county',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.typeOfProperty',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.listingPrice',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.subdivision',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.directions',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.approxAcreage',
      'propertyInfo.deedRestrictions',
      'propertyInfo.totalRoadFrontage',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.optional-waterOptions-WaterFront',
      'propertyInfo.optional-waterOptions-WaterFrontage',
      'propertyInfo.optional-waterOptions-WaterView',
      'propertyInfo.locationType',
      'propertyInfo.optional-legalDescription',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.easements',
      'propertyInfo.specialAssessments',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-HOANeighbordhoodAmenites-',
      'propertyInfo.cobraZone',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-buildings-',
      'propertyInfo.soilEvaluation',
      'propertyInfo.topology-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-fuelTank-',
      'propertyInfo.environmentalHazards',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.roadDescription-',
      'propertyInfo.waterSewerType-',
      'propertyInfo.lengthOfTimeOwningHome',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview',
    ]
  },
  [Areas.Charlotte]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',   
      'propertyInfo.optional-taxOrParcelIdNumber',      
      'propertyInfo.optional-legalDescription',
      'propertyInfo.county',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'STATE',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.optional-isPropertyVacant',
      'OWNER_PHONE',
      'propertyInfo.listingPrice',
      'propertyInfo.typeOfProperty',    
      'propertyInfo.yearBuilt',
      'propertyInfo.subType',
      'propertyInfo.styleOfHome-',
      'propertyInfo.lengthOfTimeOwningHome',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.optional-acreage',
      'propertyInfo.mainLevelBedrooms',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.mainLevelHeatedSquareFootage',
      'propertyInfo.mainLevelUnheatedSquareFootage',
      'propertyInfo.upperLevelBedrooms',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.upperLevelHeatedSquareFootage',
      'propertyInfo.upperLevelUnheatedSquareFootage',
      'propertyInfo.thirdLevelBedrooms',
      'propertyInfo.thirdLevelFullBaths',
      'propertyInfo.thirdLevelHalfBaths',
      'propertyInfo.thirdLevelHeatedSquareFootage',
      'propertyInfo.thirdLevelUnheatedSquareFootage',
      'propertyInfo.lowerLevelBedrooms',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.lowerLevelHeatedSquareFootage',
      'propertyInfo.lowerLevelUnheatedSquareFootage',
      'propertyInfo.basementLevelBedrooms',
      'propertyInfo.basementLevelFullBaths',
      'propertyInfo.basementLevelHalfBaths',
      'propertyInfo.basementLevelHeatedSquareFootage',
      'propertyInfo.basementLevelUnheatedSquareFootage',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.totalHeatedSquareFootage',
      'propertyInfo.totalUnheatedSquareFootage', 
      'propertyInfo.constructionType',
      'propertyInfo.newConstruction',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.restrectiveCovenants',
      'propertyInfo.HOASpecialAssessment',
      'propertyInfo.optional-mainLevelRooms-',     
      'propertyInfo.optional-upperLevelRoom-',     
      'propertyInfo.optional-thirdLevelRoom-',    
      'propertyInfo.optional-lowerLevelRoom-',
      'propertyInfo.optional-basementLevelRoom-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-fencing-',
       'propertyInfo.optional-improvements-',
      'propertyInfo.exteriorCovering-',
      'propertyInfo.foundation-',
      'propertyInfo.foundation-basement-',
      'propertyInfo.optional-roof-',
      'propertyInfo.roadResponsibility',
      'propertyInfo.parkingFeatures-',
      'propertyInfo.drivewayFeatures-',
      'propertyInfo.HVAC-',
      'propertyInfo.cooling-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerType-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.appliances-',
      'propertyInfo.laundryLocation-',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.optional-fireplaces-',
      'propertyInfo.optional-doorsWindows-',     
      'propertyInfo.optional-porchAndPatio-',
      'propertyInfo.optional-security-',      
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-lakeAmenities-',
      'propertyInfo.optional-greenFeatures-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-inclusions', 
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.optional-directions',      
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'STATE',
      'propertyInfo.county',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.streetAddress',
      'propertyInfo.city',     
      'propertyInfo.zipCode',
      'propertyInfo.zoningCode',
      'propertyInfo.subdivision',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings', 
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.listingPrice',
      'propertyInfo.typeOfProperty',
      'propertyInfo.lengthOfTimeOwningHome',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.dwellingOnProperty',
      'propertyInfo.approxAcreage',
      'propertyInfo.optional-landDimensions',
      'propertyInfo.minSqFtToBuild',
      'propertyInfo.optional-canBeDivided',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.restrectiveCovenants',
      'propertyInfo.HOASpecialAssessment',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.roadResponsibility',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.suitableUs-',
      'propertyInfo.optional-improvements-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerType-',
      'propertyInfo.optional-exclusions',
      'propertyInfo.restrictions-',      
      'propertyInfo.optional-lakeAmenities-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-directions',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview',
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.county',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.isLicensedRealEstateAgent',
      'OWNER_PHONE', 
      'propertyInfo.listingPrice',
      'propertyInfo.typeOfProperty',
      'propertyInfo.rentingPropertyFurnished',
      'propertyInfo.yearBuilt',
      'propertyInfo.securityDeposit',
      'propertyInfo.leaseTerm',
      'propertyInfo.typeOfProperty',
      'propertyInfo.optional-subType',
      'propertyInfo.optional-styleOfHome-',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.amountOfPetDeposit',
      'propertyInfo.tenantResponsibleFor-',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.homeOccupiedBy',
      'propertyInfo.isSmokingAllowed',     
      'propertyInfo.optional-acreage',
      'propertyInfo.unitLevelInBuilding',
      'propertyInfo.entryLevel', 
      'propertyInfo.mainLevelBedrooms',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.optional-mainLevelHeatedSquareFootage',
      'propertyInfo.optional-mainLevelUnheatedSquareFootage',
      'propertyInfo.upperLevelBedrooms',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.optional-upperLevelHeatedSquareFootage',
      'propertyInfo.optional-upperLevelUnheatedSquareFootage',
      'propertyInfo.thirdLevelBedrooms',
      'propertyInfo.thirdLevelFullBaths',
      'propertyInfo.thirdLevelHalfBaths',
      'propertyInfo.optional-thirdLevelHeatedSquareFootage',
      'propertyInfo.optional-thirdLevelUnheatedSquareFootage',
      'propertyInfo.lowerLevelBedrooms',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.optional-lowerLevelHeatedSquareFootage',
      'propertyInfo.optional-lowerLevelUnheatedSquareFootage',
      'propertyInfo.basementLevelBedrooms',
      'propertyInfo.basementLevelFullBaths',
      'propertyInfo.basementLevelHalfBaths',
      'propertyInfo.optional-basementLevelHeatedSquareFootage',
      'propertyInfo.optional-basementLevelUnheatedSquareFootage',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.optional-totalHeatedSquareFootage',
      'propertyInfo.optional-totalUnheatedSquareFootage',
      'propertyInfo.propertySubjectToHOA',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-mainLevelRooms-',
      'propertyInfo.optional-upperLevelRoom-',
      'propertyInfo.optional-thirdLevelRoom-',
      'propertyInfo.optional-basementLevelRoom-',
      'propertyInfo.optional-lowerLevelRoom-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-foundation-',
      'propertyInfo.optional-roof-',      
      'propertyInfo.optional-parkingFeatures-', 
      'propertyInfo.optional-drivewayFeatures-',
      'propertyInfo.optional-HVAC-',
      'propertyInfo.optional-cooling-',
      'propertyInfo.optional-waterType-',
      'propertyInfo.optional-sewerType-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.optional-laundry-',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.optional-fireplaces-',
      'propertyInfo.optional-porchAndPatio-',
      'propertyInfo.optional-security-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-lakeAmenities-',
      'propertyInfo.optional-greenFeatures-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',   
      'propertyInfo.optional-directions',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ]
  },
  [Areas.Triangle]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',     
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.listingPrice',
      'propertyInfo.newConstruction',
      'propertyInfo.optional-listingStartDate',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.typeOfProperty',    
      'propertyInfo.lengthOfTimeOwningHome',      
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.tenantOccupied',
      'propertyInfo.subdivision',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.directions',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.restrectiveCovenants',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.primaryBedroomFirstFloor',
      'propertyInfo.anyBedroomsFirstFloor',
      'propertyInfo.totalNumberRooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.livingAreaAboveGround',
      'propertyInfo.livingAreaBelowGround',
      'propertyInfo.otherAreaAboveGround',
      'propertyInfo.otherAreaBelowGround',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.yearBuilt',
      'propertyInfo.garageSpaces',
      'propertyInfo.openSpaces',
      'propertyInfo.parking-',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-lotDimensions',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.primaryResidence',
      'propertyInfo.isThereBasement',
      'propertyInfo.storiesLevels-',
      'propertyInfo.numberOfFireplaces',
      'marketingOptions.homeWarrantyCoverage',
      'propertyInfo.optional-propertyDescription', 
      'propertyInfo.optional-listingAndShowingInstructions',   
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.optional-inclusions', 
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.styleOfHome-', 
      'propertyInfo.basement-',
      'propertyInfo.optional-associationFeeIncludes-',  
      'propertyInfo.optional-communityAmenities-', 
      'propertyInfo.exteriorFinishing-',
      'propertyInfo.coolingType-',
      'propertyInfo.optional-optional-documentAvailable-',  
      'propertyInfo.optional-doorsWindows-',  
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-fireplaces-',
      'propertyInfo.flooring-',
      'propertyInfo.optional-foundation-',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.optional-greenBuildingCertificates-',   
      'propertyInfo.optional-greenFeatures-',
      'propertyInfo.heatType-',
      'propertyInfo.heatingFuel-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-laundry-',
      'propertyInfo.storiesLevels-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-building-',
      'propertyInfo.parking-',
      'propertyInfo.optional-poolFeatures-',      
      'propertyInfo.roadDescription-',
      'propertyInfo.roof-',
      'propertyInfo.optional-securityFeatures-',
      'propertyInfo.waterSewerType-',
      'propertyInfo.optional-doorsWindows-',  
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.isToShortSale',
      'propertyInfo.typeOfHome', 
      'propertyInfo.optional-levelLivingRoom',
      'propertyInfo.optional-levelDiningRoom',
      'propertyInfo.optional-levelFamilyRoom',
      'propertyInfo.optional-levelOfficeStudy',
      'propertyInfo.optional-levelKitchen',
      'propertyInfo.optional-levelBreakfastRoom',
      'propertyInfo.optional-levelPrimaryBedroom',
      'propertyInfo.optional-level2Bedroom',
      'propertyInfo.optional-level3Bedroom',
      'propertyInfo.optional-level4Bedroom',
      'propertyInfo.optional-level5Bedroom',
      'propertyInfo.optional-levelUtilityRoom',
      'propertyInfo.optional-levelBonusRoom',
      'propertyInfo.optional-levelScreenedPorch',
      'propertyInfo.optional-levelSunroom',
      'propertyInfo.optional-levelStorageRoom',  
      'propertyInfo.optional-levelWorkshop', 
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.typeOfProperty',
      'propertyInfo.canBeDivided',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.streetAddress',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.directions',
      'propertyInfo.optional-roadFrontage',
      'propertyInfo.approxAcreage',
      'propertyInfo.optional-clearedAcres',
      'propertyInfo.optional-woodedAcres',
      'propertyInfo.optional-landDimensions',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.percTest',
      'propertyInfo.percTestDate',
      'propertyInfo.numberOfBedsYielded',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.deedRestrictions',
      'propertyInfo.restrectiveCovenants',
      'OWNER_PHONE',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.topology-',
      'propertyInfo.utilities-',
      'propertyInfo.optional-building-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-documentAvailable-',
      'propertyInfo.roadDescription-',
      'propertyInfo.roadFrontage-',
      'propertyInfo.optional-easement-',
      'propertyInfo.restrictions-',
      'propertyInfo.vegetation-',
      'propertyInfo.sewerType-',
      'propertyInfo.waterSource-',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-improvements-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.isLicensedRealEstateAgent', 
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview',
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.typeOfProperty',
      'propertyInfo.typeOfHome',
      'propertyInfo.propertyDescription',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.newConstruction',
      'propertyInfo.directions',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.totalNumberRooms',
      'propertyInfo.isThereBasement',
      'propertyInfo.optional-livingAreaAboveGround',
      'propertyInfo.optional-livingAreaBelowGround',
      'propertyInfo.optional-otherAreaAboveGround',
      'propertyInfo.optional-otherAreaBelowGround',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-acreage',
      'propertyInfo.garageSpaces',
      'propertyInfo.numberOfFireplaces',
      'propertyInfo.yearBuilt',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.paymentPeriod',
      'propertyInfo.applicationFee',
      'propertyInfo.securityDeposit',
      'propertyInfo.acceptSection8Voucher',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.storiesLevels-',
      'propertyInfo.rentalTerm-',
      'propertyInfo.tenantResponsibleFor-',
      'propertyInfo.restrictions-',
      'propertyInfo.petInformation-',
      'propertyInfo.optional-exteriorFinish-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-waterOriented-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.optional-coolingType-',
      'propertyInfo.optional-heatingFuel-',
      'propertyInfo.optional-heatSource-',
      'propertyInfo.optional-waterHeater-',
      'propertyInfo.optional-waterSewer-',
      'propertyInfo.parking-',
      'propertyInfo.optional-additionalRooms-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-diningRoom-',
      'propertyInfo.optional-laundry-',
      'propertyInfo.optional-basement-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-fireplaces-',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.optional-greenFeatures-',
      'propertyInfo.optional-greenBuildingCertificates-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-isPropertyVacant',
      'marketingOptions.lockbox',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.city',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ]
  },
  [Areas.Charleston]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.county',
      'propertyInfo.city',
      'propertyInfo.isWithinCityLimits',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.typeOfProperty',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.listingPrice',
      'OWNER',
      'OWNER_PHONE',
      'propertyInfo.subdivision',      
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.directions',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.storiesLevels-',
      'propertyInfo.totalHeatedSquareFootage',
      'propertyInfo.yearBuilt',
      'propertyInfo.optional-acreage',
      'propertyInfo.isToShortSale',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.manufacturedHomeType-',
      'propertyInfo.singleFamilyDetachedType-',
      'propertyInfo.singleFamilyAttachedType-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-roof-',
      'propertyInfo.optional-exteriorFinish-',
      'propertyInfo.optional-foundation-',
      'propertyInfo.optional-garage-',
      'propertyInfo.totalNumberStoriesInBuilding',      
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-rooms-',
      'propertyInfo.optional-primaryBedroom-',
      'propertyInfo.coolingType-',      
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-fireplaces-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.waterSewerType-',
      'propertyInfo.heatType-',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.optional-utilitiesSuppliers-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-greenFeatures-',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.county',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.county',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.listingPrice',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.directions',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.optional-landDimensions',
      'propertyInfo.approxAcreage',
      'propertyInfo.optional-waterFrontage',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.isToShortSale',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'OWNER_PHONE',
      'propertyInfo.optional-typeDescription-',
      'LISTING_TYPE',
      'propertyInfo.optional-zoning',
      'propertyInfo.optional-uses-',
      'propertyInfo.optional-roadFronatge-',
      'propertyInfo.optional-developmentStatus-',
      'propertyInfo.optional-documentAvailable-',
      'propertyInfo.optional-structures-',
      'propertyInfo.optional-utilities-',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.optional-miscellaneous-',
      'propertyInfo.optional-communityAmenities-',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview',
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.county',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.listingPrice',
      'propertyInfo.leaseTerm',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.optional-directions',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.securityDeposit',
      'propertyInfo.yearBuilt',
      'propertyInfo.storiesLevels-',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.totalSquareFootage',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.amountOfPetDeposit',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.rentingPropertyFurnished',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.utilities-',
      'propertyInfo.leaseTerm',
      'propertyInfo.optional-garage-',
      'propertyInfo.totalNumberStoriesInBuilding',
      'propertyInfo.optional-rooms-',
      'propertyInfo.styleOfHome-',
      'propertyInfo.typeOfProperty',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-primaryBedroom-',
      'propertyInfo.optional-roof-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-fireplaces-',
      'propertyInfo.optional-appliances-',
      'propertyInfo.coolingType-',
      'propertyInfo.heatType-',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.waterSewerType-',
      'propertyInfo.optional-communityAmenities-',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ]
  },
  [Areas.Coastal]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.county',
      'propertyInfo.typeOfProperty',
      'propertyInfo.listingPrice',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.floorUnitLocated',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.typeOfProperty',
      'propertyInfo.typeOfHome-',
      'propertyInfo.typeOfHome-condo-',
      'propertyInfo.singleFamilyHomeGarage',
      'propertyInfo.singleFamilyDetachedType',
      'propertyInfo.vehicleParkingCapacity',
      'propertyInfo.garageParkingCondosAndTownHomes',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.anyBedroomsNonConforming',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.isDiningRoom',
      'propertyInfo.sellingPropertyFurnished',
      'propertyInfo.rentalManagementContract',
      'propertyInfo.HOAIncludeInsurance',
      'propertyInfo.HOAIncludeHO6Insurance',
      'marketingOptions.homeWarrantyCoverage',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.totalHeatedSquareFootage',
      'propertyInfo.totalSquareFootage',
      'propertyInfo.yearBuilt',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.listingExpirationDate',
      'marketingOptions.lockbox',
      'propertyInfo.directions',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-livingRoom-',
      'propertyInfo.optional-diningRoom-',
      'propertyInfo.optional-greatRoom-',
      'propertyInfo.optional-primaryBedroom-',
      'propertyInfo.optional-primaryBath-',
      'propertyInfo.optional-kitchen-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-exteriorFinish-',
      'propertyInfo.storiesLevels-DetachedHomes-',
      'propertyInfo.storiesLevels-CondosAndTownhomes-',
      'propertyInfo.optional-heatSource-',
      'propertyInfo.optional-waterHeater-',
      'propertyInfo.optional-foundation-',
      'propertyInfo.optional-otherRoom-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-amenitiesAvailable-',
      'propertyInfo.optional-waterSewer-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-locationSingleFamily-',
      'propertyInfo.optional-locationCondoTownHouse-',
      'propertyInfo.optional-HOAFeeIncludes-',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.streetAddress',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.typeOfProperty',
      'propertyInfo.approxAcreage',
      'propertyInfo.optional-landDimensions',
      'propertyInfo.optional-roadFrontage',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.zoningCode',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-HOAFeeIncludes-',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.optional-directions',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-saleOption-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-roadAccess-',
      'propertyInfo.optional-roadSurface-',
      'propertyInfo.optional-utilities-',
      'propertyInfo.optional-amenitiesAvailable-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-waterType-',
      'propertyInfo.optional-sewerType-',
      'propertyInfo.optional-improvements-',
      'propertyInfo.optional-documentAvailable-',
      'propertyInfo.optional-additionalInfo-',
      'propertyInfo.optional-location-',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview',
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'propertyInfo.county',
      'propertyInfo.listingPrice',
      'propertyInfo.applicationFee',
      'propertyInfo.securityDeposit',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'STATE',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.styleOfHome',
      'propertyInfo.typeOfHome-',
      'propertyInfo.singleFamilyHomeGarage',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.totalNumberBedrooms',
      'propertyInfo.anyBedroomsNonConforming',
      'propertyInfo.totalNumberFullBaths',
      'propertyInfo.totalNumberHalfBaths',
      'propertyInfo.isDiningRoom',
      'propertyInfo.rentingPropertyFurnished',
      'propertyInfo.totalHeatedSquareFootage',
      'propertyInfo.totalSquareFootage',
      'propertyInfo.yearBuilt',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-HOAFeeIncludes-',
      'OWNER',
      'ADDITIONAL_OWNER',
      'propertyInfo.listingExpirationDate',
      'marketingOptions.lockbox',
      'propertyInfo.directions',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.amountOfPetDeposit',
      'propertyInfo.leaseTerm',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-livingRoom-',
      'propertyInfo.optional-diningRoom-',
      'propertyInfo.optional-greatRoom-',
      'propertyInfo.optional-primaryBedroom-',
      'propertyInfo.optional-primaryBath-',
      'propertyInfo.optional-kitchen-',
      'propertyInfo.optional-interiorFeatures-',
      'propertyInfo.optional-flooring-',
      'propertyInfo.optional-exteriorFinish-',
      'propertyInfo.optional-levels-',
      'propertyInfo.optional-heatSource-',
      'propertyInfo.optional-waterHeater-',
      'propertyInfo.optional-foundation-',
      'propertyInfo.optional-otherRoom-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-amenitiesAvailable-',
      'propertyInfo.optional-waterSewer-',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.optional-locationSingleFamily-',
      'propertyInfo.optional-locationCondoTownHouse-',
      'propertyInfo.includedInRent-',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ]
  },
  [Areas.Georgia]: {
    [PropertyTypes.HomeSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'STATE',
      'propertyInfo.county',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'marketingOptions.homeWarrantyCoverage',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.ownershipType',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.isThereAnHoa',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-HOAFeeIncludes-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-propertySettings-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-otherStructures-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.parkingTotal',
      'propertyInfo.parking-',
      'propertyInfo.optional-waterfrontFeatures-',
      'propertyInfo.totalDockSlips',
      'propertyInfo.cooling-',
      'propertyInfo.heating-',
      'propertyInfo.utilitiesFeatures-',
      'propertyInfo.optional-securityFeatures-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerSource-',
      'propertyInfo.optional-energyFeatures-',
      'propertyInfo.upperLevelBedrooms',
      'propertyInfo.mainLevelBedrooms',
      'propertyInfo.lowerLevelBedrooms',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.optional-AboveGroundFinishedSqFt',
      'propertyInfo.optional-BelowGroundFinishedSqFt',
      'propertyInfo.optional-BelowGroundUnFinishedSqFt',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.appliences-',
      'propertyInfo.windowFeatures-',
      'propertyInfo.interiorFeatures-',
      'propertyInfo.optional-kitchen-',
      'propertyInfo.flooring-',
      'propertyInfo.optional-laundryFeatures-',
      'propertyInfo.rooms-',    
      'propertyInfo.optional-diningRoomFeatures-',
      'propertyInfo.typeOfProperty',
      'propertyInfo.structureType-',
      'propertyInfo.newConstruction',
      'propertyInfo.yearBuilt',
      'propertyInfo.styleOfHome-',
      'propertyInfo.cunstructionMaterials-',
      'propertyInfo.optional-roof-',
      'propertyInfo.foundation-',
      'propertyInfo.storiesLevels',      
      'propertyInfo.optional-patioAndPorch-',
      'propertyInfo.numberOfFireplaces',
      'propertyInfo.optional-fireplaceFeatures-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.directions',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'propertyInfo.hearAboutUs',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.homeWarrantyCoverage',
      'marketingOptions.contractReview'
    ],
    [PropertyTypes.HomeRent]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'STATE',
      'propertyInfo.county',
      'propertyInfo.streetAddress',
      'propertyInfo.unitNumber',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.optional-isPropertyVacant',
      'propertyInfo.isLicensedRealEstateAgent',
      'marketingOptions.lockbox',
      'propertyInfo.tenantMoveInDate',
      'propertyInfo.minLeaseTerm',
      'propertyInfo.maxLeaseTerm',
      'propertyInfo.securityDeposit',
      'propertyInfo.applicationFee',
      'propertyInfo.arePetsAllowed',
      'propertyInfo.amountOfPetDeposit',
      'propertyInfo.rentingPropertyFurnished',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.isThereAnHoa',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-HOAFeeIncludes-',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.optional-acreage',
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-views-',
      'propertyInfo.optional-propertySettings-',
      'propertyInfo.optional-fencing-',
      'propertyInfo.optional-otherStructures-',
      'propertyInfo.optional-exteriorFeatures-',
      'propertyInfo.optional-poolFeatures-',
      'propertyInfo.parkingTotal',
      'propertyInfo.parking-',
      'propertyInfo.optional-waterfrontFeatures-',
      'propertyInfo.totalDockSlips',
      'propertyInfo.cooling-',
      'propertyInfo.heating-',
      'propertyInfo.utilitiesFeatures-',
      'propertyInfo.optional-securityFeatures-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerSource-',
      'propertyInfo.optional-energyFeatures-',
      'propertyInfo.upperLevelBedrooms',
      'propertyInfo.mainLevelBedrooms',
      'propertyInfo.lowerLevelBedrooms',
      'propertyInfo.upperLevelFullBaths',
      'propertyInfo.mainLevelFullBaths',
      'propertyInfo.lowerLevelFullBaths',
      'propertyInfo.upperLevelHalfBaths',
      'propertyInfo.mainLevelHalfBaths',
      'propertyInfo.lowerLevelHalfBaths',
      'propertyInfo.optional-AboveGroundFinishedSqFt',
      'propertyInfo.optional-BelowGroundFinishedSqFt',
      'propertyInfo.optional-BelowGroundUnFinishedSqFt',
      'propertyInfo.optional-accessibilityFeatures-',
      'propertyInfo.appliences-',
      'propertyInfo.windowFeatures-',
      'propertyInfo.interiorFeatures-',
      'propertyInfo.flooring-',
      'propertyInfo.optional-laundryFeatures-',
      'propertyInfo.rooms-',
      'propertyInfo.optional-kitchen-',
      'propertyInfo.optional-diningRoomFeatures-',
      'propertyInfo.typeOfProperty',
      'propertyInfo.structureType-',
      'propertyInfo.newConstruction',
      'propertyInfo.yearBuilt',
      'propertyInfo.styleOfHome-',
      'propertyInfo.cunstructionMaterials-',
      'propertyInfo.optional-roof-',
      'propertyInfo.storiesLevels',
      'propertyInfo.foundation-',
      'propertyInfo.optional-patioAndPorch-',
      'propertyInfo.numberOfFireplaces',
      'propertyInfo.optional-fireplaceFeatures-',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.directions',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'propertyInfo.hearAboutUs',
      '',
      'marketingOptions.lockbox',
      'marketingOptions.forRentSign',
      'marketingOptions.additionalPhotos',
      'marketingOptions.openHouseAnnouncement',
    ],
    [PropertyTypes.LandSale]: [
      'propertyInfo.optional-listingStartDate',
      '',
      'STATE',
      'propertyInfo.county',
      'propertyInfo.streetAddress',
      'propertyInfo.city',
      'propertyInfo.zipCode',
      'propertyInfo.subdivision',
      'propertyInfo.isWithinCityLimits',
      'propertyInfo.listingPrice',
      'propertyInfo.listingExpirationDate',      
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      'propertyInfo.isLicensedRealEstateAgent',
      'propertyInfo.isToShortSale',
      'propertyInfo.isInForeclosureProceedings',
      'propertyInfo.optional-taxOrParcelIdNumber',
      'propertyInfo.optional-legalDescription',
      'propertyInfo.optional-elementarySchool',
      'propertyInfo.optional-middleSchool',
      'propertyInfo.optional-highSchool',
      'propertyInfo.isThereAnHoa',
      'propertyInfo.HOAOrCondoFee',
      'propertyInfo.optional-annualHOACondoFee',
      'propertyInfo.optional-communityAmenities-',
      'propertyInfo.approxAcreage',
      'propertyInfo.classType',
      'propertyInfo.optional-presentUse-',
      'propertyInfo.optional-potentialUse-',
      'propertyInfo.optional-structuresOnProperty-',   
      'propertyInfo.optional-typeDescription-',
      'propertyInfo.optional-propertySettings-',
      'propertyInfo.optional-vegetation-',
      'propertyInfo.utilitiesAvailable-',
      'propertyInfo.waterSource-',
      'propertyInfo.sewerSource-',
      'propertyInfo.availableDocuments-',
      'propertyInfo.optional-waterfrontFeatures-',
      'propertyInfo.totalDockSlips',
      'propertyInfo.optional-propertyDescription',
      'propertyInfo.optional-listingAndShowingInstructions',
      'propertyInfo.optional-inclusions',
      'propertyInfo.optional-exclusions',      
      'propertyInfo.directions',
      '',
      'OWNER',
      'ADDITIONAL_OWNER',
      'OWNER_PHONE',
      'OWNER_EMAIL',
      'propertyInfo.shippingOrMailingAddress',
      'TODAY_DATE',
      'propertyInfo.hearAboutUs',
      'propertyInfo.optional-showingContactName',
      'propertyInfo.optional-showingContactPhoneNumber',
      '',
      'marketingOptions.fullColorForSaleSign',
      'marketingOptions.blueAndWhiteForSaleSign',
      'marketingOptions.fullColorSignAndRider',
      'marketingOptions.additionalPhotos',
      'propertyInfo.wantOneYearListing',
      'marketingOptions.contractReview',
    ]
  }
}


// Utils

// return: [{name: '', label: '', value: ''}, '', ...]
const getSortedFields = (listing) => {
  const mlsArea = get(listing, 'propertyInfo.area', null)
  const propertyType = get(listing, 'propertyInfo.propertyType', null)

  if (!mlsArea || !propertyType) {
    // console.error('No MLS area or Property type')
    return
  }

  if (!(mlsArea in sorting) || !(propertyType in sorting[mlsArea])) {
    // console.error('Sorting not found')
    return
  }

  const localSorting = sorting[mlsArea][propertyType]
  let collectedFields = collectAllFields(mlsArea, propertyType, listing)
  let collectedOptions = collectMarketingOptions(mlsArea, propertyType, listing)

  let sortedFields = []
  let notFoundFields = []
  for (const [index, id] of localSorting.entries()) {
    if (id === '') {
      sortedFields.push('')
    } else if (id === 'TODAY_DATE') {
      sortedFields.push({
        name: id,
        label: 'Today\'s Date',
        value: formatDate(new Date()),
      })
    } else if (id === 'OWNER') {
      sortedFields.push({
        name: id,
        label: 'Owner',
        value: `${listing.owner.firstName} ${listing.owner.lastName}`.toUpperCase(),
      })
    } else if (id === 'ADDITIONAL_OWNER') {
      const additionalOwner = formatAdditionalOwner(get(listing, 'additionalOwner.firstName', ''), get(listing, 'additionalOwner.lastName', ''))

      if (additionalOwner) {
        sortedFields.push({
          name: id,
          label: 'Additional Owner',
          value: additionalOwner.toUpperCase(),
        })
      }
    } else if (id === 'OWNER_PHONE') {
      sortedFields.push({
        name: id,
        label: 'Owner Phone',
        value: listing.owner.phone,
      })
    } else if (id === 'OWNER_EMAIL') {
      sortedFields.push({
        name: id,
        label: 'Owner Email',
        value: listing.owner.email.toUpperCase(),
      })
    } else if (id === 'MLS_AREA') {
      sortedFields.push({
        name: id,
        label: 'MLS Area',
        value: listing.propertyInfo.area.toUpperCase(),
      })
    } else if (id === 'STATE') {
      const state = listing.propertyInfo.state
      const stateName = StateNames[state]

      sortedFields.push({
        name: id,
        label: 'State',
        value: stateName.toUpperCase(),
      })
    } else if (id === 'LISTING_TYPE') {
      sortedFields.push({
        name: id,
        label: 'Listing Type',
        value: listing.propertyInfo.propertyType.toUpperCase(),
      })
    } else {
      const fieldRoot = id.split('.')[0]
      const fieldName = id.substring(fieldRoot.length + 1)

      if (fieldRoot === 'propertyInfo') {
        const fields = collectedFields.filter(field => field.name === fieldName)
        if (fields.length) {
          if (fields.length === 1) {
            sortedFields.push(fields[0])
            const duplications = localSorting.slice(index + 1).filter(fieldId => fieldId === id)
            if (!duplications.length) {
              collectedFields = collectedFields.filter(field => field.name !== fieldName)
            }
          } else {
            console.error('Duplicated IDs!', fields)
          }
        } else {
          notFoundFields.push(id)
        }
      } else if (fieldRoot === 'marketingOptions') {
        const options = collectedOptions.filter(field => field.name === fieldName)
        if (options.length) {
          if (options.length === 1) {
            sortedFields.push(options[0])
            const duplications = localSorting.slice(index + 1).filter(fieldId => fieldId === id)
            if (!duplications.length) {
              collectedOptions = collectedOptions.filter(field => field.name !== fieldName)
            }
          } else {
            console.error('Duplicated IDs!', fields)
          }
        } else {
          notFoundFields.push(id)
        }
      } else {
        notFoundFields.push(id)
      }
    }
  }

  // console.log('\nSorted result: '.toUpperCase())
  // console.log('------------------------')
  // sortedFields.forEach(field => {
  //   if (field) {
  //     console.log(`[${field.name}] ${field.label}: ${field.value}`);
  //   } else {
  //     console.log('');
  //   }
  // })

  // console.log('\nFilled by user, but not sorted (form):'.toUpperCase())
  // console.log('------------------------')
  // collectedFields.forEach(field => {
  //   console.log(`[${field.name}] ${field.label}: ${field.value}`);
  // })

  // console.log('\nFilled by user, but not sorted (marketing):'.toUpperCase())
  // console.log('------------------------')
  // collectedOptions.forEach(field => {
  //   console.log(`[${field.name}] ${field.label}: ${field.value}`);
  // })

  // console.log('\nPresented in sorting, but not found:'.toUpperCase())
  // console.log('------------------------')
  // notFoundFields.forEach(field => {
  //   console.log(field);
  // })

  return [...sortedFields, ...collectedFields, ...collectedOptions]
}

const collectMarketingOptions = (mlsArea, propertyType, listing) => {
  let marketingOptions = []

  const localizer = new Localizer(propertyType, mlsArea)
  for (const option of localizer.localizeArray(options)) {
    if (get(listing, `marketingOptions.${option.name}`)) {
      let label = option.title
      if (option.price) {
        label += ` ($${option.price}${option.delivery ? ' plus s/h' : ''})`
      } else if (option.name === 'homeWarrantyCoverage') {
        label += ' (paid at closing)'
      }
      marketingOptions.push({
        label,
        name: option.name,
        value: 'YES',
      })
    }
  }
  return marketingOptions
}

const collectAllFields = (mlsArea, propertyType, listing) => {
  
  const collectFormFields = (form) => {
    const scopedForm = localizer.localizeArray(form)
    for (const section of scopedForm) {
      section.fieldsets = localizer.localizeArray(section.fieldsets)
      for (const fieldset of section.fieldsets) {
        if ('inputGroups' in fieldset) {
          fieldset.inputGroups = localizer.localizeArray(fieldset.inputGroups)
          for (const group of fieldset.inputGroups) {
            group.fields = localizer.localizeArray(group.fields)
  
            if (group.fields[0].inputType === InputTypes.Checkbox) {
              let checkedValues = []
              for (const field of group.fields) {
                if (getValue('propertyInfo', field, listing)) {
                  checkedValues.push(field.label)
                }
              }
              if (checkedValues.length) {
                const checkboxName = group.fields[0].name
                const chunks = checkboxName.split('-')
                collectedFields.push({
                  name:  checkboxName.slice(0, -chunks[chunks.length - 1].length),
                  label: group.groupTitle || fieldset.fieldsetTitle,
                  value: checkedValues.join(' ----- ').toUpperCase(),
                })
              }
            } else {
              for (const field of group.fields) {
                addFieldInfo(field)
              }
            }
          }
        } else {
          fieldset.fields = localizer.localizeArray(fieldset.fields)
          for (const field of fieldset.fields) {
            addFieldInfo(field)
          }
        }
      }
    }
  }
  
  const addFieldInfo = (field) => {
    let value = getValue('propertyInfo', field, listing)
  
    if ('additionalInputs' in field) {
      field.additionalInputs = localizer.localizeArray(field.additionalInputs)
      for (const input of field.additionalInputs) {
        addFieldInfo(input)
      }
    }
  
    if (value) {
      if ([InputTypes.YesNo, InputTypes.Text, InputTypes.Select, InputTypes.Number].includes(field.inputType)) {
        value = value.toString().toUpperCase()
      } else if (field.inputType === InputTypes.Checkbox) {
        value = 'Checked'
      } else if (field.inputType === InputTypes.Date && value) {
        value = formatDate(value.toDate())
      }
  
      collectedFields.push({
        name: field.name,
        label: field.label,
        value: value
      })
    }
  }
  
  const getValue = (root, field) => {
    return get(listing, `${root}.${field.name}`, '')
  }

  let collectedFields = []
  const localizer = new Localizer(propertyType, mlsArea)
  collectFormFields(requiredForm)
  collectFormFields(optionalForm)
  return collectedFields
}


export {
  sorting,
  getSortedFields,
}