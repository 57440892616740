<template>
  <div
    class="min-h-screen bg-gray-100 flex flex-col justify-between"
    id="app-container"
    :class="{'pt-16 print:pt-0': innerAppUI}"
  >
    <!-- Navbar -->
    <nav v-if="innerAppUI && navigation.length" class="bg-white shadow z-30 fixed w-full top-0">
      <div class="px-4 sm:px-6">
        <div class="flex justify-between h-16">
          <div class="flex-shrink-0 flex items-center">
            <router-link :to="navigation[0].path">
              <img class="block h-8 w-auto" src="./assets/img/logo.svg" alt="Select Premium Properties">
            </router-link>
          </div>
          <div class="hidden sm:ml-6 sm:mr-4 sm:flex sm:space-x-4">
            <router-link
              v-for="link in navigation"
              :key="link.path"
              :to="link.path"
              class="nav-link capitalize"
              :class="{'active': route.name === link.name}"
            >
              {{ link.name }}
            </router-link>
            <a href="#" @click.prevent="logout" class="nav-link danger">Logout</a>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <button type="button"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              aria-controls="mobile-menu" @click="open = !open" aria-expanded="false"
              x-bind:aria-expanded="open.toString()"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                x-description="Icon when menu is closed. Heroicon name: outline/menu"
                x-state:on="Menu open"
                x-state:off="Menu closed"
                class="h-6 w-6"
                :class="{ 'hidden': open, 'block': !(open) }"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
              <svg
                x-description="Icon when menu is open. Heroicon name: outline/x"
                x-state:on="Menu open"
                x-state:off="Menu closed"
                class="h-6 w-6"
                :class="{ 'block': open, 'hidden': !(open) }"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-if="open" x-description="Mobile menu, show/hide based on menu state." class="sm:hidden" id="mobile-menu">
        <div class="pt-2 pb-3 space-y-1">
          <router-link
            v-for="link in navigation"
            :key="link.path"
            :to="link.path"
            class="mobile-nav-link capitalize"
            :class="{'active': route.name === link.name}"
            @click="open = false"
          >
            {{ link.name }}
          </router-link>
          <a href="#" @click.prevent="logout" class="mobile-nav-link danger">Logout</a>
        </div>
      </div>
    </nav>

    <!-- Main content -->
    <router-view />

    <!-- Footer -->
    <footer class="mt-auto" v-if="innerAppUI">
      <div class="px-4 sm:px-6 py-5 flex items-center justify-between">
        <div class="">
          <p class="text-sm text-gray-500">
            © {{ (new Date()).getFullYear() }} Select Premium Properties, Inc
          </p>
        </div>
        <div class="flex justify-center space-x-4">
          <a href="mailto:service@selectpremiumprop.com" class="text-gray-400 hover:text-gray-600">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="m2.5 6.667 6.575 4.383c.56.373 1.29.373 1.85 0L17.5 6.666M4.167 15.834h11.666c.92 0 1.667-.746 1.667-1.667V5.833c0-.92-.746-1.667-1.667-1.667H4.167c-.92 0-1.667.747-1.667 1.667v8.334c0 .92.746 1.666 1.667 1.666Z" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
          <!-- <a href="tel:703-831-6806" class="text-gray-400 hover:text-gray-600">
            <svg class="h-5 w-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="m7.657 2.97.652-.217-.652.217Zm1.321 3.964.652-.217-.652.217Zm-.345.833.308.615-.308-.615Zm-1.994.997-.307-.615a.687.687 0 0 0-.32.897l.627-.282Zm4.597 4.597-.282.627c.336.151.732.01.897-.32l-.615-.307Zm.997-1.994.615.307-.615-.307Zm.833-.345.217-.652-.217.652Zm3.964 1.321-.218.652.218-.652ZM15 17.5l-.429.538c.122.097.273.15.429.15V17.5Zm0 0 .428-.538a.687.687 0 0 0-.428-.15v.688ZM2.5 5v-.687A.688.688 0 0 0 1.812 5H2.5Zm0 0v.688a.688.688 0 0 0 .537-1.117L2.5 5Zm0 0h-.688c0 .156.054.308.151.43L2.5 5Zm1.375-3.188c-1.14 0-2.063.924-2.063 2.063h1.375c0-.38.308-.688.688-.688V1.813Zm3.13 0h-3.13v1.375h3.13V1.813Zm1.304.94a1.375 1.375 0 0 0-1.305-.94v1.375l1.305-.434ZM9.63 6.718 8.31 2.753l-1.305.434 1.322 3.965 1.304-.435Zm-.69 1.665a1.375 1.375 0 0 0 .69-1.665l-1.304.435.615 1.23Zm-1.993.997 1.994-.997-.615-1.23-1.994.997.615 1.23Zm4.572 3.355a8.514 8.514 0 0 1-4.253-4.253l-1.254.565a9.889 9.889 0 0 0 4.942 4.942l.565-1.254Zm.1-1.675-.998 1.994 1.23.615.997-1.994-1.23-.615Zm1.664-.69a1.375 1.375 0 0 0-1.665.69l1.23.615.435-1.304Zm3.964 1.322-3.964-1.321-.435 1.304 3.964 1.321.435-1.304Zm.94 1.304c0-.591-.378-1.117-.94-1.304l-.435 1.304h1.375Zm0 3.13v-3.13h-1.375v3.13h1.375Zm-2.062 2.062c1.139 0 2.062-.923 2.062-2.062h-1.375c0 .38-.307.687-.687.687v1.375Zm-1.125 0h1.125v-1.375H15v1.375Zm-.429-.15.857-1.075-.857 1.075ZM1.813 5c0 7.283 5.904 13.187 13.187 13.187v-1.375C8.476 16.812 3.188 11.524 3.187 5H1.813Zm.687-.687v1.375-1.375Zm-.537 1.116 1.074-.858-1.074.858Zm-.15-1.554V5h1.375V3.875H1.812Z" />
            </svg>
          </a> -->
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { watchEffect, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'App',

  components: {},

  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const open = ref(false)

    const innerAppUI = computed(() => {
      return ![
        'landing',
        'resetPassword',
        'login',
        'signup',
        'signupInfo',
        'adminPhotos'
      ].includes(route.name)
    })

    const logout = () => {
      let msg = 'Are you sure?'
      if (route.name === 'listing') {
        msg = 'Your listing has been saved.  Please log in again to complete your listing.'
      }
      const confirm = window.confirm(msg)
      if (confirm) {
        store.dispatch('user/logout')
      }
    }


    // Nav

    const navigation = ref([])


    watchEffect(() => {
      // Empty profile check
      if (store.getters['user/profileFetchedAndEmpty']) {
        router.push('/signup-info')
      }

      if (store.state.user.profileFetched) {
        if (store.state.user.profile.isAdmin) {
          navigation.value = [
            {
              path: '/admin/listings',
              name: 'listings',
            },
            {
              path: '/admin/users',
              name: 'users',
            },
          ]
        } else {
          navigation.value = [
            {
              path: '/home',
              name: 'home',
            },
            {
              path: '/settings',
              name: 'settings',
            },
          ]
        }
      }
    })


    return {
      route,
      logout,
      open,
      innerAppUI,
      navigation,
    }
  },
}
</script>


<style lang="scss">
@import url('https://rsms.me/inter/inter.css');

:root {
  font-family: 'Inter', system-ui, sans-serif;
  font-size: 112.5%;
  @apply sm:bg-gray-100 text-gray-800;
}

a.link {
  @apply font-medium text-blue-600 hover:text-blue-500;
}

.header-1 {
  @apply text-2xl font-semibold text-gray-900;
}

.header-2 {
  @apply text-lg font-semibold text-gray-900;
}

.header-3 {
  @apply text-base font-semibold text-gray-900;
}

.text-primary {
  @apply text-gray-800;
}

.text-secondary {
  @apply text-gray-500;
}

.subtitle {
  @apply block text-gray-500 text-sm font-medium;
}

input[readonly], textarea[readonly], select[readonly] {
  background: #eee;
}

@media print {
  nav, footer {
    display: none;
  }

  #app-container {
    background-color: #fff;
  }

  #printable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
}


// Nav

.nav-link {
  @apply inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium border-transparent text-gray-900 hover:text-gray-700;

  &.danger {
    @apply text-red-600 hover:text-red-500;
  }

  &.active {
    @apply text-blue-600 border-blue-500;
  }
}

.mobile-nav-link {
  @apply block p-2 pl-4 border-l-4 text-sm font-medium border-transparent text-gray-900 hover:text-gray-700;

  &.danger {
    @apply text-red-600 hover:text-red-500;
  }

  &.active {
    @apply bg-blue-50 border-blue-500 text-blue-600;
  }
}


// Listing pages

.listing-step-badge {
  @apply text-sm uppercase font-medium text-blue-600 mb-6 block;
}


// Property info form

.form-section {
  @apply mt-6 sm:rounded-xl sm:border sm:p-6;

  &:first-of-type {
    @apply mt-0;
  }

  .section-title {
    @apply text-xl text-gray-900 font-semibold mb-6;
  }
}

.form-fieldset {
  $mb: 4;
  @apply mt-8;

  .fieldset-title {
    @apply text-lg text-gray-900 font-semibold mb-#{$mb};
  }

  .fieldset-description {
    @apply text-gray-500 text-sm -mt-#{$mb} mb-#{$mb};
  }
}

.form-input-group {
  $mb: 2;
  @apply my-2;

  .group-title {
    @apply text-gray-900 text-base font-semibold mt-4 mb-#{$mb};
  }

  .group-description {
    @apply text-gray-500 text-sm -mt-#{$mb} mb-#{$mb};
  }
}


// Signable forms and info-pages

.form-wrapper {
  @apply text-sm;

  mark {
    @apply bg-yellow-200;
  }

  .header-1 {
    @apply text-center mb-8;
  }

  .subtitle {
    @apply -mt-7 mb-8 text-center;
  }

  .header-2 {
    @apply mt-6;
  }

  .header-3 {
    @apply mt-3;
  }

  p {
    @apply mt-2;
  }

  ul {
    @apply list-outside pl-8 mt-2;

    & > li {
      @apply mt-2;
    }

    &.small {
      @apply mt-1;

      & > li {
        @apply mt-1;
      }
    }
  }

  ol.main {
    @apply list-decimal list-inside;

    & > li {
      @apply mt-4;
    }
  }
}
</style>

<style lang="scss">
// datepicker conflicts with our yes/no radio buttons (both have z-index=10)
.v3dp__popout {
  z-index: 50 !important;
}

.v3dp__datepicker {
  input {
    background-color: white !important;
  }
}
</style>