const PropertyTypes = {
  HomeSale: 'HomeSale',
  HomeRent: 'HomeRent',
  LandSale: 'LandSale',
}

const propertyTypes = {
  HomeSale: {
    title: 'Home for Sale',
    number: 1,
  },
  HomeRent: {
    title: 'Home for Rent',
    number: 2,
  },
  LandSale: {
    title: 'Land for Sale',
    number: 3,
  }
}


export {
  PropertyTypes,
  propertyTypes,
}