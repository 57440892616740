import router from '@/router'
import { auth, userRef } from '@/firebase'
import { merge } from 'lodash'
import { getDomainBySource } from '@/config/main'


export default {
  namespaced: true,

  state: {
    profile: {},
    profileFetched: false,
  },

  getters: {
    profileFetchedAndEmpty: (state) => {
      return state.profileFetched && Object.keys(state.profile).length === 0
    },
    domain: (state) => {
      return getDomainBySource(state.profile.source)
    },
  },
  
  actions: {
    async fetchProfile(ctx) {
      if (!auth.currentUser) {
        throw new Error('No current user')
      }

      const profile = await userRef().get()
      if (profile.exists) {
        ctx.commit('setProfile', profile.data())
      }

      ctx.commit('setFetchedFlag', true)
    },
  
    async update(ctx) {
      const localProfile = ctx.state.profile
      if (!localProfile.firstName || !localProfile.lastName || !localProfile.phone || !localProfile.source) {
        throw new Error('Some of required fields are empty')
      }

      const profile = await userRef().get()
      profile.exists ? userRef().update(localProfile) : userRef().set(localProfile)
    },
  
    async logout(ctx) {
      await auth.signOut()
      ctx.commit('setProfile', {})
      ctx.commit('setFetchedFlag', false)
      router.push('/login')
    },
  },

  mutations: {
    setProfile(state, profile) {
      state.profile = profile
    },
    updateProfile(state, newData) {
      merge(state.profile, newData)
    },
    setFetchedFlag(state, fetched) {
      state.profileFetched = fetched
    },
  },
}