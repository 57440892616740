import { PropertyTypes } from '../property-types'
import { Areas } from '../states-areas'
import { InputTypes, Scopes } from './types'

const form = [
  {
    sectionTitle: 'Optional Information',
    fieldsets: [
      {
        fieldsetTitle: '',
        fields: [
          {
            name: 'optional-listingStartDate',
            inputType: InputTypes.Date,
            label: 'Coming Soon Listing Option:',
            help: `
            Leave this Field Blank if you are Ready to Start Showings Now. <a href="#" data-detailed-help="delayedMarketing" 
              class="link" target="_blank">Click Here</a> For Important "Coming Soon" Listing Information.
            `,
            detailedHelp: {
              default: {
                delayedMarketing: `If you choose a future 'start showing' date the listing will be placed into the "Coming Soon" category in the MLS. Your listing may remain in this category for up to 21 days before it is changed to Active in the MLS. The "Coming Soon" category lets agents know that your property is coming on the market so they can put it on their "must see" list. It also allows time for you to receive any ordered materials before your first showing. While in the "Coming Soon" category the listing is <b>strictly prohibited from being shown or conducting open houses</b>. A violation of this rule may result in a fine from the MLS. You may change the 'start showing' Active date to an earlier time by emailing us this information. Please note: while in the "Coming Soon" category the listing may only be viewed by agents belonging to the MLS. It may not appear on any public real estate website until it is in the "Active" category.`,
              },
              scope: Scopes.ByMLSArea,
              areas: {
                [Areas.Bright]: {
                  delayedMarketing: `If you choose a future 'start showing' date the listing will be placed into the "Coming Soon" category in the MLS. Your listing may remain in this category for up to 21 days before it is changed to Active in the MLS. The "Coming Soon" category lets agents know that your property is coming on the market so they can put it on their "must see" list. It also allows time for you to receive any ordered materials before your first showing. While in the "Coming Soon" category the listing is <b>strictly prohibited from being shown or conducting open houses</b>. A violation of this rule may result in a fine from the MLS. You may change the 'start showing' Active date to an earlier time by emailing us this information. Please note: while in the "Coming Soon" category the listing may only be viewed by agents belonging to the MLS. It may not appear on any public real estate website until it is in the "Active" category.`,
                },
                [Areas.CentralVA]: {
                  delayedMarketing: `If you choose a future 'start showing' date the listing will be placed into the "Coming Soon" category in the MLS. Your listing may remain in this category for up to 21 days before it is changed to Active in the MLS. The "Coming Soon" category lets agents know that your property is coming on the market so they can put it on their "must see" list. It also allows time for you to receive any ordered materials before your first showing. While in the "Coming Soon" category the listing is <b>strictly prohibited from being shown or conducting open houses</b>. A violation of this rule may result in a fine from the MLS. You may change the 'start showing' Active date to an earlier time by emailing us this information. Please note: while in the "Coming Soon" category the listing may only be viewed by agents belonging to the MLS. It may not appear on any public real estate website until it is in the "Active" category.`,
                },
              }
            }
          },
          {
            name: 'optional-zoning',
            inputType: InputTypes.Select,
            options: ['Residential', 'Agricultural', 'Commercial', 'Condominium', 'Mobile Home', 'PUD'],
            label: 'Zoning',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] }
          },
          {
            name: 'optional-isPropertyVacant',
            inputType: InputTypes.YesNo,
            label: 'Is the property vacant?',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-isPropertyVacant',
            inputType: InputTypes.Select,
            options: ['Yes', 'No - Tenant Ocuppied', 'No - Owner Occupied'],
            label: 'Is the property vacant?',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale] }
          },
          {
            name: 'optional-allowSmoking',
            inputType: InputTypes.YesNo,
            label: 'Do you allow smoking in the home?',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-landDimensions',
            inputType: InputTypes.Text,
            label: 'Land Dimensions',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Charlotte]: [PropertyTypes.LandSale],
              [Areas.Triangle]: [PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.LandSale],
              [Areas.Charleston]: [PropertyTypes.LandSale],
              [Areas.Coastal]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-annualHOACondoFee',
            inputType: InputTypes.Price,
            label: 'Annual HOA or Condo Fee',
            required: 'Required if Applicable',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Georgia]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-lotDimensions',
            inputType: InputTypes.Text,
            label: 'Lot Dimensions',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.NCRegion]: [PropertyTypes.HomeSale],
              [Areas.Triangle]: [PropertyTypes.HomeSale],
              [Areas.Triad]: [PropertyTypes.HomeSale],
            }
          },
          {
            name: 'optional-canBeDivided',
            inputType: InputTypes.YesNo,
            label: 'Can Be Divided',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Charlotte]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-roadFrontage',
            inputType: InputTypes.Text,
            label: 'Road Frontage',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triangle]: [PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.LandSale],
              [Areas.Coastal]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-waterFrontage',
            inputType: InputTypes.Text,
            label: 'Water Frontage (in ft)',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Charleston]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-clearedAcres',
            inputType: InputTypes.Text,
            label: 'Cleared Acres',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triangle]: [PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-woodedAcres',
            inputType: InputTypes.Text,
            label: 'Wooded Acres',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triangle]: [PropertyTypes.LandSale],
              [Areas.Triad]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-subType',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
            inputType: InputTypes.Select,
            options: [
              '1 Story',
              '1 Story / F.R.O.G.',
              '1.5 Story',
              '2 Story',
              '2.5 Story',
              '3 Story',
              '4 Story',
              '5 Story or More',
              'Manufactured Double Wide',
              'Manufactured Single Wide',
              'Modular Home',
              'Split Foyer',
              'Split Level',
              'Tri Level',
            ],
            label: 'Sub Type',
          },
          {
            name: 'optional-isPropertyFurnished',
            inputType: InputTypes.Select,
            options: ['Yes', 'No', 'Partially'],
            label: 'Are You Selling the Property Furnished?',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale],
            }
          },
          {
            name: 'optional-acreage',
            inputType: InputTypes.Text,
            label: 'Acreage',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.CentralVA]: [PropertyTypes.HomeRent],
              [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Charleston]: [PropertyTypes.HomeSale],
              [Areas.Coastal]: [PropertyTypes.HomeSale],
              [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-numberOfFireplaces',
            inputType: InputTypes.Number,
            label: 'Number of Fireplaces',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-taxOrParcelIdNumber',
            inputType: InputTypes.Text,
            label: 'Tax or Parcel Id Number'
          },
          {
            name: 'optional-legalDescription',
            inputType: InputTypes.Text,
            label: 'Legal Description',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
          },
          {
            name: 'optional-elementarySchool',
            inputType: InputTypes.Text,
            label: 'Elementary School'
          },
          {
            name: 'optional-middleSchool',
            inputType: InputTypes.Text,
            label: 'Middle School'
          },
          {
            name: 'optional-highSchool',
            inputType: InputTypes.Text,
            label: 'High School'
          },
          {
            name: 'optional-showingContactName',
            inputType: InputTypes.Text,
            label: 'Showing Contact Name'
          },
          {
            name: 'optional-showingContactPhoneNumber',
            inputType: InputTypes.Text,
            label: 'Showing Contact Phone Number'
          },
          {
            name: 'optional-listingAndShowingInstructions',
            inputType: InputTypes.Textarea,
            label: 'Listing and Showing Instructions',
            help: 'Portable Elements of the Property that WILL be included in the sale. Examples: Hot Tub, Play Equipment, Basketball Goal.',
            help: `This information will not appear on&nbsp;any public site and is&nbsp;only for agents to&nbsp;view. Examples are: Lockbox code&nbsp;/ location, Gate Code, Take Shoes Off in&nbsp;Home, Vacant&nbsp;&mdash; Show Anytime, etc. If you do not want the servcie activated for your listing, please state that here. <a href="#" data-detailed-help="showingTime" class="link" target="_blank">Click Here for more showing service information</a>`,
            detailedHelp: {
              showingTime: `The MLS employs a showing service to facilitate appointments between agents and owners. It verifies each agent and allows them to&nbsp;schedule appointments online. In&nbsp;return you are provided a&nbsp;feedback report after the showing. Only agents who belong to&nbsp;the MLS are able to&nbsp;use this service. Therefore, any calls you receive from the showing service will be from a verified agent. You should also be&nbsp;able to&nbsp;change and update your own showing instructions via the showing service app or Call Center. Your listing is&nbsp;automatically signed up&nbsp;for the showing service, however, you may opt out of&nbsp;the service at&nbsp;any time by&nbsp;simply emailing&nbsp;us the request.`
            }
          },          
          {
            name: 'optional-propertyDescription',
            inputType: InputTypes.Textarea,
            label: 'Property Description / Remarks',
            charsLimit: {
              default: 4000,
              scope: Scopes.ByMLSArea,
              areas: {
                [Areas.Charleston]: 10000,
                [Areas.Charlotte]: 1000,
                [Areas.NCRegion]: 2000,
                [Areas.Triangle]: 4000,
                [Areas.Triad]: 1000,
                [Areas.CentralVA]: 1200,
              }
            }
          },
          {
            name: 'optional-inclusions',
            inputType: InputTypes.Textarea,
            label: 'Inclusions',
            help: 'Portable Elements of the Property that WILL be included in the sale. Examples: Hot Tub, Play Equipment, Basketball Goal.',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
          },
          {
            name: 'optional-exclusions',
            inputType: InputTypes.Textarea,
            label: 'Exclusions',
            help: 'Elements of the Property that WILL NOT be included in the sale. Example: A light fixture that will be removed prior to closing.',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
          },
          {
            name: 'optional-directions',
            inputType: InputTypes.Textarea,
            label: 'Driving Directions',
            scope: Scopes.IndividualMapping,
            mapping: { 
              [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
              [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
              [Areas.Charleston]: [PropertyTypes.HomeRent],
              [Areas.Coastal]: [PropertyTypes.LandSale],
            },
            charsLimit: {
              default: 4000,
              scope: Scopes.ByMLSArea,
              areas: {
                [Areas.Charleston]: 10000,
                [Areas.Charlotte]: 500,
                [Areas.NCRegion]: 400,
                [Areas.Triangle]: 1024,
                [Areas.Triad]: 255,
                [Areas.Georgia]: 256,
                [Areas.Bright]: 1024,
                [Areas.CentralVA]: 215,
              }
            },
          },
          {
            name: 'optional-percType',
            inputType: InputTypes.Select,
            options: ['Unknown', 'Approved Perc', 'Failed Perc', 'Not Applicable', 'Verify Perc', 'Other'],
            label: 'Perc Type',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-numberBedroomsPerc',
            inputType: InputTypes.Number,
            label: 'Number of Bedrooms Perc',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-numberPercSites',
            inputType: InputTypes.Number,
            label: 'Number of Perc Sites',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Bright]: [PropertyTypes.LandSale],
            }
          },
          {
            name: 'optional-styleOfHome',
            inputType: InputTypes.Select,
            options: ['A-Frame', 'Bungalow', 'Cape Cod', 'Chalet', 'Colonial', 'Condo Lower Level', 'Condo Main Level', 'Condo Upper Level', 'Contemporary', 'Cottage', 'Double-Wide', 'Dutch Colonial', 'Farm House', 'French Provincial', 'Georgian', 'Log', 'Ranch', 'Salt Box', 'Single Wide', 'Traditional', 'Transitional', 'Tudor', 'Victorian', 'Williamsburg'],
            label: 'Style of Home',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-isThereBasement',
            inputType: InputTypes.Select,
            options: ['No', 'Yes - Finished', 'Yes - Unfinished'],
            label: 'Is There a Basement',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-isTherePool',
            inputType: InputTypes.YesNo,
            label: 'Is There a Pool',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-mainLevelFinishedSqFt',
            inputType: InputTypes.Text,
            label: 'Main Level Finished Sqft',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-secondUpperLevelFinishedSqFt',
            inputType: InputTypes.Text,
            label: '2nd Upper Level Finished Sqft',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-thirdLevelHeatedSqFt',
            inputType: InputTypes.Text,
            label: 'Third Level Heated Sqft',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-basementLevelHeatedSqFt',
            inputType: InputTypes.Text,
            label: 'Basement Level Heated Sqft',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-handicapAccessible',
            inputType: InputTypes.YesNo,
            label: 'Handicap Accessible?',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-isThereFencing',
            inputType: InputTypes.YesNo,
            label: 'Is There Fencing?',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-garageOrCarportSpaces',
            inputType: InputTypes.Text,
            label: 'Garage / Carport Spaces',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-totalNumberOfRooms',
            inputType: InputTypes.Text,
            label: 'Total Number of Rooms',
            scope: Scopes.IndividualMapping,
            mapping: {
              [Areas.Triad]: [PropertyTypes.HomeRent],
            }
          },
          {
            name: 'optional-AboveGroundFinishedSqFt',
            inputType: InputTypes.Text,
            label: 'Above Ground Finished Square Footage',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] }
          },
          {
            name: 'optional-BelowGroundFinishedSqFt',
            inputType: InputTypes.Text,
            label: 'Below Ground Finished Square Footage',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] }
          },
          {
            name: 'optional-BelowGroundUnFinishedSqFt',
            inputType: InputTypes.Text,
            label: 'Below Ground UNFinished Square Footage',
            scope: Scopes.IndividualMapping,
            mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] }
          },
        ]
      },
      {
        fieldsetTitle: 'Rooms in the Home',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-rooms-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-rooms-EatInKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Eat-In-Kitchen'
              },
              {
                name: 'optional-rooms-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-rooms-FormalLivingRm',
                inputType: InputTypes.Checkbox,
                label: 'Formal Living Rm'
              },
              {
                name: 'optional-rooms-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-rooms-FrogAttached',
                inputType: InputTypes.Checkbox,
                label: 'Frog Attached'
              },
              {
                name: 'optional-rooms-FrogDetached',
                inputType: InputTypes.Checkbox,
                label: 'Frog Detached'
              },
              {
                name: 'optional-rooms-GameRoom',
                inputType: InputTypes.Checkbox,
                label: 'Game Room'
              },
              {
                name: 'optional-rooms-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-rooms-LaundryRoom',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Room'
              },
              {
                name: 'optional-rooms-LivingDiningCombo',
                inputType: InputTypes.Checkbox,
                label: 'Living/Dining Combo'
              },
              {
                name: 'optional-rooms-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-rooms-MediaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Media Room'
              },
              {
                name: 'optional-rooms-InLawSuite',
                inputType: InputTypes.Checkbox,
                label: 'In-law Suite'
              },
              {
                name: 'optional-rooms-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-rooms-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-rooms-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-rooms-SeparateDiningRm',
                inputType: InputTypes.Checkbox,
                label: 'Separate Dining Rm'
              },
              {
                name: 'optional-rooms-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-rooms-Sunroom',
                inputType: InputTypes.Checkbox,
                label: 'Sunroom'
              },
              {
                name: 'optional-rooms-UtilityRoom',
                inputType: InputTypes.Checkbox,
                label: 'Utility Room'
              },
              {
                name: 'optional-rooms-OtherRoom',
                inputType: InputTypes.Checkbox,
                label: 'Other Room'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Rooms in the Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
        fields: [
          {
            name: 'optional-levelLivingRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Living Room'
          },
          {
            name: 'optional-levelDiningRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Dining Room'
          },
          {
            name: 'optional-levelDen',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Den'
          },
          {
            name: 'optional-levelKitchen',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Kitchen'
          },
          {
            name: 'optional-levelPrimaryBedroom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Primary Bedroom'
          },
          {
            name: 'optional-levelBedroom2',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Bedroom 2'
          },
          {
            name: 'optional-levelBedroom3',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Bedroom 3'
          },
          {
            name: 'optional-levelBedroom4',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Bedroom 4'
          },
          {
            name: 'optional-levelBedroom5',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Bedroom 5'
          },
          {
            name: 'optional-levelBedroom6',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Bedroom 6'
          },
          {
            name: 'optional-levelBonusRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Bonus Room'
          },
          {
            name: 'optional-levelBreakfastRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Breakfast Room'
          },
          {
            name: 'optional-levelGreatRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Great Room'
          },
          {
            name: 'optional-levelOfficeRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Office Room'
          },
          {
            name: 'optional-levelHomeTheaterRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Home Theater Room'
          },
          {
            name: 'optional-levelLaundryRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Laundry Room'
          },
          {
            name: 'optional-levelMudroom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Mudroom'
          },
          {
            name: 'optional-levelRecRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Rec Room'
          },
          {
            name: 'optional-levelSittingRoom',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Sitting Room'
          },
          {
            name: 'optional-levelWorkshop',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Workshop'
          },
          {
            name: 'optional-levelOtherRoom1',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Other Room 1'
          },
          {
            name: 'optional-levelOtherRoom2',
            inputType: InputTypes.Select,
            options: ['Main', 'Second', 'Third', 'Basement', 'Lower', 'Other'],
            label: 'Level of Other Room 2'
          },
        ]
      },
      {
        fieldsetTitle: 'Rooms in the Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
        fields: [
          {
            name: 'optional-levelLivingRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Living Room'
          },
          {
            name: 'optional-levelDiningRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Dining Room'
          },
          {
            name: 'optional-levelFamilyRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Family Room'
          },
          {
            name: 'optional-levelOfficeStudy',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Office'
          },
          {
            name: 'optional-levelKitchen',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Kitchen'
          },
          {
            name: 'optional-levelBreakfastRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Breakfast Room'
          },
          {
            name: 'optional-levelPrimaryBedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Primary Bedroom'
          },
          {
            name: 'optional-level2Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of 2 Bedroom'
          },
          {
            name: 'optional-level3Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of 3 Bedroom'
          },
          {
            name: 'optional-level4Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of 4 Bedroom'
          },
          {
            name: 'optional-level5Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of 5 Bedroom'
          },
          {
            name: 'optional-levelBonusRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Bonus Room'
          },
          {
            name: 'optional-levelRecRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Rec Room'
          },
          {
            name: 'optional-levelSunRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Sunroom'
          },
          {
            name: 'optional-levelUtilityRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Utility Room'
          },
          {
            name: 'optional-levelWorkshop',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Basement', 'Lower', 'Additional'],
            label: 'Level of Workshop'
          },
        ]
      },
      {
        fieldsetTitle: 'Levels',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-levels-single',
                inputType: InputTypes.Checkbox,
                label: 'Single Level'
              },
              {
                name: 'optional-levels-split',
                inputType: InputTypes.Checkbox,
                label: 'Split Level'
              },
              {
                name: 'optional-levels-OneAndOneHalf',
                inputType: InputTypes.Checkbox,
                label: 'One and One Half'
              },
              {
                name: 'optional-levels-TwoStory',
                inputType: InputTypes.Checkbox,
                label: 'Two-Story'
              },
              {
                name: 'optional-levels-3OrMore',
                inputType: InputTypes.Checkbox,
                label: '3 Or More'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Rooms in the Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'optional-levelLivingRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Living Room'
          },
          {
            name: 'optional-levelDiningRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Dining Room'
          },
          {
            name: 'optional-typeDiningRoom',
            inputType: InputTypes.Select,
            scope: Scopes.ByPropertyType,
            types: [ PropertyTypes.HomeSale ],
            options: ['Formal', 'Combination', 'Kitchen'],
            label: 'Type of Dining Room'
          },
          {
            name: 'optional-levelFamilyRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Family Room'
          },
          {
            name: 'optional-levelOfficeStudy',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Office/Study'
          },
          {
            name: 'optional-levelKitchen',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Kitchen'
          },
          {
            name: 'optional-levelBreakfastNook',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Breakfast Nook'
          },
          {
            name: 'optional-levelGreatRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Great Room'
          },
          {
            name: 'optional-levelBonusRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Bonus Room'
          },
          {
            name: 'optional-levelMediaRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Media Room'
          },
          {
            name: 'optional-levelLaundryRoom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Laundry Room'
          },
          {
            name: 'optional-levelOffice',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Office'
          },
          {
            name: 'optional-levelPrimaryBedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Primary Bedroom'
          },
          {
            name: 'optional-level2Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of 2 Bedroom'
          },
          {
            name: 'optional-level3Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of 3 Bedroom'
          },
          {
            name: 'optional-level4Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of 4 Bedroom'
          },
          {
            name: 'optional-level5Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of 5 Bedroom'
          },
          {
            name: 'optional-level6Bedroom',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of 6 Bedroom'
          },
          {
            name: 'optional-levelOtherRoom1',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Other Room 1'
          },
          {
            name: 'optional-levelOtherRoom2',
            inputType: InputTypes.Select,
            options: ['First', 'Second', 'Third', 'Fourth', 'Basement'],
            label: 'Level of Other Room 2'
          },
        ]
      },      
      {
        fieldsetTitle: 'Main Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-mainLevelRooms-2ndLivingQrtrs',
                inputType: InputTypes.Checkbox,
                label: '2nd Living Qrtrs'
              },
              {
                name: 'optional-mainLevelRooms-BarEntertainment',
                inputType: InputTypes.Checkbox,
                label: 'Bar/Entertainment'
              },
              {
                name: 'optional-mainLevelRooms-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-mainLevelRooms-Bathroom',
                inputType: InputTypes.Checkbox,
                label: 'Bathroom(s)'
              },
              {
                name: 'optional-mainLevelRooms-Bedroom',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom(s)'
              },
              {
                name: 'optional-mainLevelRooms-BedBonus',
                inputType: InputTypes.Checkbox,
                label: 'Bed/Bonus'
              },
              {
                name: 'optional-mainLevelRooms-Billiard',
                inputType: InputTypes.Checkbox,
                label: 'Billiard'
              },
              {
                name: 'optional-mainLevelRooms-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-mainLevelRooms-Breakfast',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast'
              },
              {
                name: 'optional-mainLevelRooms-ComputerNiche',
                inputType: InputTypes.Checkbox,
                label: 'Computer Niche'
              },
              {
                name: 'optional-mainLevelRooms-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-mainLevelRooms-DiningArea',
                inputType: InputTypes.Checkbox,
                label: 'Dining Area'
              },
              {
                name: 'optional-mainLevelRooms-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-mainLevelRooms-EntryHall',
                inputType: InputTypes.Checkbox,
                label: 'Entry Hall'
              },
              {
                name: 'optional-mainLevelRooms-Exercise',
                inputType: InputTypes.Checkbox,
                label: 'Exercise'
              },
              {
                name: 'optional-mainLevelRooms-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-mainLevelRooms-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-mainLevelRooms-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-mainLevelRooms-GreatRoom2Story',
                inputType: InputTypes.Checkbox,
                label: 'Great Room - 2 Story'
              },
              {
                name: 'optional-mainLevelRooms-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'optional-mainLevelRooms-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-mainLevelRooms-2ndKitchen',
                inputType: InputTypes.Checkbox,
                label: '2nd Kitchen'
              },
              {
                name: 'optional-mainLevelRooms-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-mainLevelRooms-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-mainLevelRooms-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-mainLevelRooms-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-mainLevelRooms-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-mainLevelRooms-2ndPrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: '2nd Primary Bedroom'
              },
              {
                name: 'optional-mainLevelRooms-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-mainLevelRooms-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-mainLevelRooms-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-mainLevelRooms-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-mainLevelRooms-Parlor',
                inputType: InputTypes.Checkbox,
                label: 'Parlor'
              },
              {
                name: 'optional-mainLevelRooms-PlayRoom',
                inputType: InputTypes.Checkbox,
                label: 'Play Room'
              },
              {
                name: 'optional-mainLevelRooms-RecRoom',
                inputType: InputTypes.Checkbox,
                label: 'Rec Room'
              },
              {
                name: 'optional-mainLevelRooms-Sewing',
                inputType: InputTypes.Checkbox,
                label: 'Sewing'
              },
              {
                name: 'optional-mainLevelRooms-Sitting',
                inputType: InputTypes.Checkbox,
                label: 'Sitting'
              },
              {
                name: 'optional-mainLevelRooms-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-mainLevelRooms-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-mainLevelRooms-Sunroom',
                inputType: InputTypes.Checkbox,
                label: 'Sunroom'
              },
              {
                name: 'optional-mainLevelRooms-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-mainLevelRooms-WineCellar',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cellar'
              },
              {
                name: 'optional-mainLevelRooms-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Main Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-mainLevelRooms-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-mainLevelRooms-Bedroom2',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 2'
              },
              {
                name: 'optional-mainLevelRooms-Bedroom3',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 3'
              },
              {
                name: 'optional-mainLevelRooms-Bedroom4',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 4'
              },
              {
                name: 'optional-mainLevelRooms-Bedroom5',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 5'
              },
              {
                name: 'optional-mainLevelRooms-Bedroom6',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 6'
              },
              {
                name: 'optional-mainLevelRooms-AdditionalBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Additional Bedroom'
              },
              {
                name: 'optional-mainLevelRooms-Attic',
                inputType: InputTypes.Checkbox,
                label: 'Attic'
              },
              {
                name: 'optional-mainLevelRooms-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-mainLevelRooms-BreakfastRoom',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Room'
              },
              {
                name: 'optional-mainLevelRooms-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-mainLevelRooms-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-mainLevelRooms-ExerciseRoom',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Room'
              },
              {
                name: 'optional-mainLevelRooms-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-mainLevelRooms-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-mainLevelRooms-GameRoom',
                inputType: InputTypes.Checkbox,
                label: 'Game Room'
              },
              {
                name: 'optional-mainLevelRooms-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-mainLevelRooms-HobbyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Hobby Room'
              },
              {
                name: 'optional-mainLevelRooms-InLawAuPairSuite',
                inputType: InputTypes.Checkbox,
                label: 'In-Law / Au Pair Suite'
              },
              {
                name: 'optional-mainLevelRooms-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-mainLevelRooms-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-mainLevelRooms-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-mainLevelRooms-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-mainLevelRooms-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-mainLevelRooms-MaidGuestQuarters',
                inputType: InputTypes.Checkbox,
                label: 'Maid / Guest Quarters'
              },
              {
                name: 'optional-mainLevelRooms-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-mainLevelRooms-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-mainLevelRooms-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-mainLevelRooms-PhotoLabDarkroom',
                inputType: InputTypes.Checkbox,
                label: 'Photo Lab / Darkroom'
              },
              {
                name: 'optional-mainLevelRooms-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },
              {
                name: 'optional-mainLevelRooms-SittingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sitting Room'
              },
              {
                name: 'optional-mainLevelRooms-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-mainLevelRooms-StorageRoom',
                inputType: InputTypes.Checkbox,
                label: 'Storage Room'
              },
              {
                name: 'optional-mainLevelRooms-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-mainLevelRooms-SunFloridaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sun / Florida Room'
              },
              {
                name: 'optional-mainLevelRooms-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-mainLevelRooms-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
              {
                name: 'optional-mainLevelRooms-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Upper Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-upperLevelRoom-2ndLivingQrtrs',
                inputType: InputTypes.Checkbox,
                label: '2nd Living Qrtrs'
              },
              {
                name: 'optional-upperLevelRoom-BarEntertainment',
                inputType: InputTypes.Checkbox,
                label: 'Bar/Entertainment'
              },
              {
                name: 'optional-upperLevelRoom-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-upperLevelRoom-Bathroom',
                inputType: InputTypes.Checkbox,
                label: 'Bathroom(s)'
              },
              {
                name: 'optional-upperLevelRoom-Bedroom',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom(s)'
              },
              {
                name: 'optional-upperLevelRoom-BedBonus',
                inputType: InputTypes.Checkbox,
                label: 'Bed/Bonus'
              },
              {
                name: 'optional-upperLevelRoom-Billiard',
                inputType: InputTypes.Checkbox,
                label: 'Billiard'
              },
              {
                name: 'optional-upperLevelRoom-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-upperLevelRoom-Breakfast',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast'
              },
              {
                name: 'optional-upperLevelRoom-ComputerNiche',
                inputType: InputTypes.Checkbox,
                label: 'Computer Niche'
              },
              {
                name: 'optional-upperLevelRoom-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-upperLevelRoom-DiningArea',
                inputType: InputTypes.Checkbox,
                label: 'Dining Area'
              },
              {
                name: 'optional-upperLevelRoom-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-upperLevelRoom-EntryHall',
                inputType: InputTypes.Checkbox,
                label: 'Entry Hall'
              },
              {
                name: 'optional-upperLevelRoom-Exercise',
                inputType: InputTypes.Checkbox,
                label: 'Exercise'
              },
              {
                name: 'optional-upperLevelRoom-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-upperLevelRoom-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-upperLevelRoom-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-upperLevelRoom-GreatRoom2Story',
                inputType: InputTypes.Checkbox,
                label: 'Great Room - 2 Story'
              },
              {
                name: 'optional-upperLevelRoom-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'optional-upperLevelRoom-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-upperLevelRoom-2ndKitchen',
                inputType: InputTypes.Checkbox,
                label: '2nd Kitchen'
              },
              {
                name: 'optional-upperLevelRoom-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-upperLevelRoom-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-upperLevelRoom-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-upperLevelRoom-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-upperLevelRoom-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-upperLevelRoom-2ndPrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: '2nd Primary Bedroom'
              },
              {
                name: 'optional-upperLevelRoom-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-upperLevelRoom-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-upperLevelRoom-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-upperLevelRoom-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-upperLevelRoom-Parlor',
                inputType: InputTypes.Checkbox,
                label: 'Parlor'
              },
              {
                name: 'optional-upperLevelRoom-PlayRoom',
                inputType: InputTypes.Checkbox,
                label: 'Play Room'
              },
              {
                name: 'optional-upperLevelRoom-RecRoom',
                inputType: InputTypes.Checkbox,
                label: 'Rec Room'
              },
              {
                name: 'optional-upperLevelRoom-Sewing',
                inputType: InputTypes.Checkbox,
                label: 'Sewing'
              },
              {
                name: 'optional-upperLevelRoom-Sitting',
                inputType: InputTypes.Checkbox,
                label: 'Sitting'
              },
              {
                name: 'optional-upperLevelRoom-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-upperLevelRoom-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-upperLevelRoom-Sunroom',
                inputType: InputTypes.Checkbox,
                label: 'Sunroom'
              },
              {
                name: 'optional-upperLevelRoom-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-upperLevelRoom-WineCellar',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cellar'
              },
              {
                name: 'optional-upperLevelRoom-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Upper Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-upperLevelRooms-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-upperLevelRooms-2ndStoryFamilyRmOverlook',
                inputType: InputTypes.Checkbox,
                label: '2nd Story Family Rm Overlook'
              },
              {
                name: 'optional-upperLevelRooms-2ndStoryFamilyRm',
                inputType: InputTypes.Checkbox,
                label: '2nd Story Family Rm'
              },
              {
                name: 'optional-upperLevelRooms-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-upperLevelRooms-Bedroom2',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 2'
              },
              {
                name: 'optional-upperLevelRooms-Bedroom3',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 3'
              },
              {
                name: 'optional-upperLevelRooms-Bedroom4',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 4'
              },
              {
                name: 'optional-upperLevelRooms-Bedroom5',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 5'
              },
              {
                name: 'optional-upperLevelRooms-Bedroom6',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 6'
              },
              {
                name: 'optional-upperLevelRooms-AdditionalBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Additional Bedroom'
              },
              {
                name: 'optional-upperLevelRooms-Attic',
                inputType: InputTypes.Checkbox,
                label: 'Attic'
              },
              {
                name: 'optional-upperLevelRooms-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-upperLevelRooms-BreakfastRoom',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Room'
              },
              {
                name: 'optional-upperLevelRooms-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-upperLevelRooms-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-upperLevelRooms-ExerciseRoom',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Room'
              },
              {
                name: 'optional-upperLevelRooms-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-upperLevelRooms-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-upperLevelRooms-GameRoom',
                inputType: InputTypes.Checkbox,
                label: 'Game Room'
              },
              {
                name: 'optional-upperLevelRooms-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-upperLevelRooms-HobbyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Hobby Room'
              },
              {
                name: 'optional-upperLevelRooms-InLawAuPairSuite',
                inputType: InputTypes.Checkbox,
                label: 'In-Law / Au Pair Suite'
              },
              {
                name: 'optional-upperLevelRooms-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-upperLevelRooms-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-upperLevelRooms-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-upperLevelRooms-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-upperLevelRooms-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-upperLevelRooms-MaidGuestQuarters',
                inputType: InputTypes.Checkbox,
                label: 'Maid / Guest Quarters'
              },
              {
                name: 'optional-upperLevelRooms-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-upperLevelRooms-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-upperLevelRooms-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-upperLevelRooms-PhotoLabDarkroom',
                inputType: InputTypes.Checkbox,
                label: 'Photo Lab / Darkroom'
              },
              {
                name: 'optional-upperLevelRooms-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },
              {
                name: 'optional-upperLevelRooms-SittingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sitting Room'
              },
              {
                name: 'optional-upperLevelRooms-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-upperLevelRooms-StorageRoom',
                inputType: InputTypes.Checkbox,
                label: 'Storage Room'
              },
              {
                name: 'optional-upperLevelRooms-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-upperLevelRooms-SunFloridaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sun / Florida Room'
              },
              {
                name: 'optional-upperLevelRooms-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-upperLevelRooms-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
              {
                name: 'optional-upperLevelRooms-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Third Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-thirdLevelRoom-2ndLivingQrtrs',
                inputType: InputTypes.Checkbox,
                label: '2nd Living Qrtrs'
              },
              {
                name: 'optional-thirdLevelRoom-BarEntertainment',
                inputType: InputTypes.Checkbox,
                label: 'Bar/Entertainment'
              },
              {
                name: 'optional-thirdLevelRoom-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-thirdLevelRoom-Bathroom',
                inputType: InputTypes.Checkbox,
                label: 'Bathroom(s)'
              },
              {
                name: 'optional-thirdLevelRoom-Bedroom',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom(s)'
              },
              {
                name: 'optional-thirdLevelRoom-BedBonus',
                inputType: InputTypes.Checkbox,
                label: 'Bed/Bonus'
              },
              {
                name: 'optional-thirdLevelRoom-Billiard',
                inputType: InputTypes.Checkbox,
                label: 'Billiard'
              },
              {
                name: 'optional-thirdLevelRoom-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-thirdLevelRoom-Breakfast',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast'
              },
              {
                name: 'optional-thirdLevelRoom-ComputerNiche',
                inputType: InputTypes.Checkbox,
                label: 'Computer Niche'
              },
              {
                name: 'optional-thirdLevelRoom-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-thirdLevelRoom-DiningArea',
                inputType: InputTypes.Checkbox,
                label: 'Dining Area'
              },
              {
                name: 'optional-thirdLevelRoom-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-thirdLevelRoom-EntryHall',
                inputType: InputTypes.Checkbox,
                label: 'Entry Hall'
              },
              {
                name: 'optional-thirdLevelRoom-Exercise',
                inputType: InputTypes.Checkbox,
                label: 'Exercise'
              },
              {
                name: 'optional-thirdLevelRoom-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-thirdLevelRoom-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-thirdLevelRoom-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-thirdLevelRoom-GreatRoom2Story',
                inputType: InputTypes.Checkbox,
                label: 'Great Room - 2 Story'
              },
              {
                name: 'optional-thirdLevelRoom-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'optional-thirdLevelRoom-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-thirdLevelRoom-2ndKitchen',
                inputType: InputTypes.Checkbox,
                label: '2nd Kitchen'
              },
              {
                name: 'optional-thirdLevelRoom-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-thirdLevelRoom-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-thirdLevelRoom-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-thirdLevelRoom-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-thirdLevelRoom-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-thirdLevelRoom-2ndPrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: '2nd Primary Bedroom'
              },
              {
                name: 'optional-thirdLevelRoom-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-thirdLevelRoom-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-thirdLevelRoom-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-thirdLevelRoom-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-thirdLevelRoom-Parlor',
                inputType: InputTypes.Checkbox,
                label: 'Parlor'
              },
              {
                name: 'optional-thirdLevelRoom-PlayRoom',
                inputType: InputTypes.Checkbox,
                label: 'Play Room'
              },
              {
                name: 'optional-thirdLevelRoom-RecRoom',
                inputType: InputTypes.Checkbox,
                label: 'Rec Room'
              },
              {
                name: 'optional-thirdLevelRoom-Sewing',
                inputType: InputTypes.Checkbox,
                label: 'Sewing'
              },
              {
                name: 'optional-thirdLevelRoom-Sitting',
                inputType: InputTypes.Checkbox,
                label: 'Sitting'
              },
              {
                name: 'optional-thirdLevelRoom-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-thirdLevelRoom-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-thirdLevelRoom-Sunroom',
                inputType: InputTypes.Checkbox,
                label: 'Sunroom'
              },
              {
                name: 'optional-thirdLevelRoom-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-thirdLevelRoom-WineCellar',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cellar'
              },
              {
                name: 'optional-thirdLevelRoom-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: '2nd Upper Level Rooms (3rd Floor)',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-secondUpperLevelRooms-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-secondUpperLevelRooms-2ndStoryFamilyRmOverlook',
                inputType: InputTypes.Checkbox,
                label: '2nd Story Family Rm Overlook'
              },
              {
                name: 'optional-secondUpperLevelRooms-2ndStoryFamilyRm',
                inputType: InputTypes.Checkbox,
                label: '2nd Story Family Rm'
              },
              {
                name: 'optional-secondUpperLevelRooms-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-secondUpperLevelRooms-Bedroom2',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 2'
              },
              {
                name: 'optional-secondUpperLevelRooms-Bedroom3',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 3'
              },
              {
                name: 'optional-secondUpperLevelRooms-Bedroom4',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 4'
              },
              {
                name: 'optional-secondUpperLevelRooms-Bedroom5',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 5'
              },
              {
                name: 'optional-secondUpperLevelRooms-Bedroom6',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 6'
              },
              {
                name: 'optional-secondUpperLevelRooms-AdditionalBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Additional Bedroom'
              },
              {
                name: 'optional-secondUpperLevelRooms-Attic',
                inputType: InputTypes.Checkbox,
                label: 'Attic'
              },
              {
                name: 'optional-secondUpperLevelRooms-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-BreakfastRoom',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-secondUpperLevelRooms-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-ExerciseRoom',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-secondUpperLevelRooms-GameRoom',
                inputType: InputTypes.Checkbox,
                label: 'Game Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-Hobby',
                inputType: InputTypes.Checkbox,
                label: 'Hobby'
              },
              {
                name: 'optional-secondUpperLevelRooms-InLawAuPairSuite',
                inputType: InputTypes.Checkbox,
                label: 'In-Law / Au Pair Suite'
              },
              {
                name: 'optional-secondUpperLevelRooms-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-secondUpperLevelRooms-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-secondUpperLevelRooms-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-secondUpperLevelRooms-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-secondUpperLevelRooms-MaidGuestQuarters',
                inputType: InputTypes.Checkbox,
                label: 'Maid / Guest Quarters'
              },
              {
                name: 'optional-secondUpperLevelRooms-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-secondUpperLevelRooms-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-secondUpperLevelRooms-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-secondUpperLevelRooms-PhotoLabDarkroom',
                inputType: InputTypes.Checkbox,
                label: 'Photo Lab / Darkroom'
              },
              {
                name: 'optional-secondUpperLevelRooms-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },
              {
                name: 'optional-secondUpperLevelRooms-SittingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sitting Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-secondUpperLevelRooms-StorageRoom',
                inputType: InputTypes.Checkbox,
                label: 'Storage Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-secondUpperLevelRooms-SunFloridaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sun / Florida Room'
              },
              {
                name: 'optional-secondUpperLevelRooms-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-secondUpperLevelRooms-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
              {
                name: 'optional-secondUpperLevelRooms-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lower Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-lowerLevelRoom-2ndLivingQrtrs',
                inputType: InputTypes.Checkbox,
                label: '2nd Living Qrtrs'
              },
              {
                name: 'optional-lowerLevelRoom-BarEntertainment',
                inputType: InputTypes.Checkbox,
                label: 'Bar/Entertainment'
              },
              {
                name: 'optional-lowerLevelRoom-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-lowerLevelRoom-Bathroom',
                inputType: InputTypes.Checkbox,
                label: 'Bathroom(s)'
              },
              {
                name: 'optional-lowerLevelRoom-Bedroom',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom(s)'
              },
              {
                name: 'optional-lowerLevelRoom-BedBonus',
                inputType: InputTypes.Checkbox,
                label: 'Bed/Bonus'
              },
              {
                name: 'optional-lowerLevelRoom-Billiard',
                inputType: InputTypes.Checkbox,
                label: 'Billiard'
              },
              {
                name: 'optional-lowerLevelRoom-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-lowerLevelRoom-Breakfast',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast'
              },
              {
                name: 'optional-lowerLevelRoom-ComputerNiche',
                inputType: InputTypes.Checkbox,
                label: 'Computer Niche'
              },
              {
                name: 'optional-lowerLevelRoom-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-lowerLevelRoom-DiningArea',
                inputType: InputTypes.Checkbox,
                label: 'Dining Area'
              },
              {
                name: 'optional-lowerLevelRoom-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-lowerLevelRoom-EntryHall',
                inputType: InputTypes.Checkbox,
                label: 'Entry Hall'
              },
              {
                name: 'optional-lowerLevelRoom-Exercise',
                inputType: InputTypes.Checkbox,
                label: 'Exercise'
              },
              {
                name: 'optional-lowerLevelRoom-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-lowerLevelRoom-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-lowerLevelRoom-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-lowerLevelRoom-GreatRoom2Story',
                inputType: InputTypes.Checkbox,
                label: 'Great Room - 2 Story'
              },
              {
                name: 'optional-lowerLevelRoom-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'optional-lowerLevelRoom-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-lowerLevelRoom-2ndKitchen',
                inputType: InputTypes.Checkbox,
                label: '2nd Kitchen'
              },
              {
                name: 'optional-lowerLevelRoom-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-lowerLevelRoom-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-lowerLevelRoom-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-lowerLevelRoom-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-lowerLevelRoom-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-lowerLevelRoom-2ndPrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: '2nd Primary Bedroom'
              },
              {
                name: 'optional-lowerLevelRoom-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-lowerLevelRoom-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-lowerLevelRoom-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-lowerLevelRoom-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-lowerLevelRoom-Parlor',
                inputType: InputTypes.Checkbox,
                label: 'Parlor'
              },
              {
                name: 'optional-lowerLevelRoom-PlayRoom',
                inputType: InputTypes.Checkbox,
                label: 'Play Room'
              },
              {
                name: 'optional-lowerLevelRoom-RecRoom',
                inputType: InputTypes.Checkbox,
                label: 'Rec Room'
              },
              {
                name: 'optional-lowerLevelRoom-Sewing',
                inputType: InputTypes.Checkbox,
                label: 'Sewing'
              },
              {
                name: 'optional-lowerLevelRoom-Sitting',
                inputType: InputTypes.Checkbox,
                label: 'Sitting'
              },
              {
                name: 'optional-lowerLevelRoom-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-lowerLevelRoom-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-lowerLevelRoom-Sunroom',
                inputType: InputTypes.Checkbox,
                label: 'Sunroom'
              },
              {
                name: 'optional-lowerLevelRoom-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-lowerLevelRoom-WineCellar',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cellar'
              },
              {
                name: 'optional-lowerLevelRoom-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lower Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-lowerLevelRooms-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-lowerLevelRooms-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-lowerLevelRooms-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-lowerLevelRooms-Bedroom2',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 2'
              },
              {
                name: 'optional-lowerLevelRooms-Bedroom3',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 3'
              },
              {
                name: 'optional-lowerLevelRooms-Bedroom4',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 4'
              },
              {
                name: 'optional-lowerLevelRooms-Bedroom5',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 5'
              },
              {
                name: 'optional-lowerLevelRooms-Bedroom6',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom 6'
              },
              {
                name: 'optional-lowerLevelRooms-AdditionalBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Additional Bedroom'
              },
              {
                name: 'optional-lowerLevelRooms-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-lowerLevelRooms-BreakfastRoom',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Room'
              },
              {
                name: 'optional-lowerLevelRooms-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-lowerLevelRooms-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-lowerLevelRooms-ExerciseRoom',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Room'
              },
              {
                name: 'optional-lowerLevelRooms-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-lowerLevelRooms-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-lowerLevelRooms-GameRoom',
                inputType: InputTypes.Checkbox,
                label: 'Game Room'
              },
              {
                name: 'optional-lowerLevelRooms-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-lowerLevelRooms-Hobby',
                inputType: InputTypes.Checkbox,
                label: 'Hobby'
              },
              {
                name: 'optional-lowerLevelRooms-InLawAuPairSuite',
                inputType: InputTypes.Checkbox,
                label: 'In-Law / Au Pair Suite'
              },
              {
                name: 'optional-lowerLevelRooms-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-lowerLevelRooms-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-lowerLevelRooms-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-lowerLevelRooms-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-lowerLevelRooms-MaidGuestQuarters',
                inputType: InputTypes.Checkbox,
                label: 'Maid / Guest Quarters'
              },
              {
                name: 'optional-lowerLevelRooms-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-lowerLevelRooms-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-lowerLevelRooms-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-lowerLevelRooms-PhotoLabDarkroom',
                inputType: InputTypes.Checkbox,
                label: 'Photo Lab / Darkroom'
              },
              {
                name: 'optional-lowerLevelRooms-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },
              {
                name: 'optional-lowerLevelRooms-SittingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sitting Room'
              },
              {
                name: 'optional-lowerLevelRooms-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-lowerLevelRooms-StorageRoom',
                inputType: InputTypes.Checkbox,
                label: 'Storage Room'
              },
              {
                name: 'optional-lowerLevelRooms-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-lowerLevelRooms-SunFloridaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sun / Florida Room'
              },
              {
                name: 'optional-lowerLevelRooms-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-lowerLevelRooms-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
              {
                name: 'optional-lowerLevelRooms-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Basement Level Rooms',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-basementLevelRoom-2ndLivingQrtrs',
                inputType: InputTypes.Checkbox,
                label: '2nd Living Qrtrs'
              },
              {
                name: 'optional-basementLevelRoom-BarEntertainment',
                inputType: InputTypes.Checkbox,
                label: 'Bar/Entertainment'
              },
              {
                name: 'optional-basementLevelRoom-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-basementLevelRoom-Bathroom',
                inputType: InputTypes.Checkbox,
                label: 'Bathroom(s)'
              },
              {
                name: 'optional-basementLevelRoom-Bedroom',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom(s)'
              },
              {
                name: 'optional-basementLevelRoom-BedBonus',
                inputType: InputTypes.Checkbox,
                label: 'Bed/Bonus'
              },
              {
                name: 'optional-basementLevelRoom-Billiard',
                inputType: InputTypes.Checkbox,
                label: 'Billiard'
              },
              {
                name: 'optional-basementLevelRoom-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-basementLevelRoom-Breakfast',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast'
              },
              {
                name: 'optional-basementLevelRoom-ComputerNiche',
                inputType: InputTypes.Checkbox,
                label: 'Computer Niche'
              },
              {
                name: 'optional-basementLevelRoom-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-basementLevelRoom-DiningArea',
                inputType: InputTypes.Checkbox,
                label: 'Dining Area'
              },
              {
                name: 'optional-basementLevelRoom-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-basementLevelRoom-EntryHall',
                inputType: InputTypes.Checkbox,
                label: 'Entry Hall'
              },
              {
                name: 'optional-basementLevelRoom-Exercise',
                inputType: InputTypes.Checkbox,
                label: 'Exercise'
              },
              {
                name: 'optional-basementLevelRoom-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-basementLevelRoom-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-basementLevelRoom-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-basementLevelRoom-GreatRoom2Story',
                inputType: InputTypes.Checkbox,
                label: 'Great Room - 2 Story'
              },
              {
                name: 'optional-basementLevelRoom-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'optional-basementLevelRoom-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-basementLevelRoom-2ndKitchen',
                inputType: InputTypes.Checkbox,
                label: '2nd Kitchen'
              },
              {
                name: 'optional-basementLevelRoom-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-basementLevelRoom-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-basementLevelRoom-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-basementLevelRoom-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-basementLevelRoom-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-basementLevelRoom-2ndPrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: '2nd Primary Bedroom'
              },
              {
                name: 'optional-basementLevelRoom-Media',
                inputType: InputTypes.Checkbox,
                label: 'Media'
              },
              {
                name: 'optional-basementLevelRoom-Mud',
                inputType: InputTypes.Checkbox,
                label: 'Mud'
              },
              {
                name: 'optional-basementLevelRoom-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-basementLevelRoom-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-basementLevelRoom-Parlor',
                inputType: InputTypes.Checkbox,
                label: 'Parlor'
              },
              {
                name: 'optional-basementLevelRoom-PlayRoom',
                inputType: InputTypes.Checkbox,
                label: 'Play Room'
              },
              {
                name: 'optional-basementLevelRoom-RecRoom',
                inputType: InputTypes.Checkbox,
                label: 'Rec Room'
              },
              {
                name: 'optional-basementLevelRoom-Sewing',
                inputType: InputTypes.Checkbox,
                label: 'Sewing'
              },
              {
                name: 'optional-basementLevelRoom-Sitting',
                inputType: InputTypes.Checkbox,
                label: 'Sitting'
              },
              {
                name: 'optional-basementLevelRoom-Solarium',
                inputType: InputTypes.Checkbox,
                label: 'Solarium'
              },
              {
                name: 'optional-basementLevelRoom-Study',
                inputType: InputTypes.Checkbox,
                label: 'Study'
              },
              {
                name: 'optional-basementLevelRoom-Sunroom',
                inputType: InputTypes.Checkbox,
                label: 'Sunroom'
              },
              {
                name: 'optional-basementLevelRoom-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
              {
                name: 'optional-basementLevelRoom-WineCellar',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cellar'
              },
              {
                name: 'optional-basementLevelRoom-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups : [
          {
            fields: [
              {
                name: 'optional-interiorFeatures-SpaHotTub',
                inputType: InputTypes.Checkbox,
                label: 'Spa/Hot Tub'
              },
              {
                name: 'optional-interiorFeatures-WindowTreatmentsConvey',
                inputType: InputTypes.Checkbox,
                label: 'Window Treatments Convey'
              },
              {
                name: 'optional-interiorFeatures-GarageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener'
              },
              {
                name: 'optional-interiorFeatures-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
              {
                name: 'optional-interiorFeatures-CentralVacuum',
                inputType: InputTypes.Checkbox,
                label: 'Central Vacuum'
              },
              {
                name: 'optional-interiorFeatures-WaterPurifier',
                inputType: InputTypes.Checkbox,
                label: 'Water Purifier'
              },
              {
                name: 'optional-interiorFeatures-SecuritySystem',
                inputType: InputTypes.Checkbox,
                label: 'Security System'
              },
              {
                name: 'optional-interiorFeatures-StairsAttic',
                inputType: InputTypes.Checkbox,
                label: 'Stairs to Attic'
              },
              {
                name: 'optional-interiorFeatures-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },
              {
                name: 'optional-interiorFeatures-SmokeDetector',
                inputType: InputTypes.Checkbox,
                label: 'Smoke Detector'
              },
              {
                name: 'optional-interiorFeatures-SaunaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sauna Room'
              },
              {
                name: 'optional-interiorFeatures-WasherDryerConnection',
                inputType: InputTypes.Checkbox,
                label: 'Washer / Dryer Connection'
              },
              {
                name: 'optional-interiorFeatures-WasherDryer',
                inputType: InputTypes.Checkbox,
                label: 'Washer / Dryer'
              },
              {
                name: 'optional-interiorFeatures-Skylights',
                inputType: InputTypes.Checkbox,
                label: 'Skylights'
              },
              {
                name: 'optional-interiorFeatures-AtticFan',
                inputType: InputTypes.Checkbox,
                label: 'Attic Fan'
              },
              {
                name: 'optional-interiorFeatures-ElectricAirFilter',
                inputType: InputTypes.Checkbox,
                label: 'Electric Air Filter'
              },
              {
                name: 'optional-interiorFeatures-SplitBedroomPlan',
                inputType: InputTypes.Checkbox,
                label: 'Split Bedroom Plan'
              },
              {
                name: 'optional-interiorFeatures-Furnished',
                inputType: InputTypes.Checkbox,
                label: 'Furnished'
              },
              {
                name: 'optional-interiorFeatures-HandicappedAccess',
                inputType: InputTypes.Checkbox,
                label: 'Handicapped Access'
              },
              {
                name: 'optional-interiorFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-interiorFeatures-Fireplace',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace'
              },
              {
                name: 'optional-interiorFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-interiorFeatures-FireSprinkler',
                inputType: InputTypes.Checkbox,
                label: 'Fire Sprinkler'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-interiorFeatures-CeilingBeamed',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling - Beamed'
              },
              {
                name: 'optional-interiorFeatures-CeilingBlown',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling - Blown'
              },
              {
                name: 'optional-interiorFeatures-CeilingCathedralVaulted',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling - Cathedral/Vaulted'
              },
              {
                name: 'optional-interiorFeatures-CeilingSmooth',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling - Smooth'
              },
              {
                name: 'optional-interiorFeatures-CeilingTray',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling - Tray'
              },
              {
                name: "optional-interiorFeatures-Ceilings9",
                inputType: InputTypes.Checkbox,
                label: "Ceilings - 9'+"
              },
              {
                name: 'optional-interiorFeatures-ConvertedGarage',
                inputType: InputTypes.Checkbox,
                label: 'Converted Garage'
              },
              {
                name: 'optional-interiorFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-interiorFeatures-GardenTubShower',
                inputType: InputTypes.Checkbox,
                label: 'Garden Tub/Shower'
              },
              {
                name: 'optional-interiorFeatures-HandicappedEquipped',
                inputType: InputTypes.Checkbox,
                label: 'Handicapped Equipped'
              },
              {
                name: 'optional-interiorFeatures-KitchenIsland',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Island'
              },
              {
                name: 'optional-interiorFeatures-PoolIndoor',
                inputType: InputTypes.Checkbox,
                label: 'Pool - Indoor'
              },
              {
                name: 'optional-interiorFeatures-Skylight',
                inputType: InputTypes.Checkbox,
                label: 'Skylight'
              },
              {
                name: 'optional-interiorFeatures-UnfinishedFrog',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished Frog'
              },
              {
                name: 'optional-interiorFeatures-WalkInClosets',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Closets'
              },
              {
                name: 'optional-interiorFeatures-WindowTreatmentsSome',
                inputType: InputTypes.Checkbox,
                label: 'Window Treatments - Some'
              },
              {
                name: 'optional-interiorFeatures-WindowTreatmentsAll',
                inputType: InputTypes.Checkbox,
                label: 'Window Treatments - All'
              },
              {
                name: 'optional-interiorFeatures-WineCellar',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cellar'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-interiorFeatures-2ndDryerConnection',
                inputType: InputTypes.Checkbox,
                label: '2nd Dryer Connection'
              },
              {
                name: 'optional-interiorFeatures-2ndWasherConnection',
                inputType: InputTypes.Checkbox,
                label: '2nd Washer Connection'
              },
              {
                name: 'optional-interiorFeatures-AlarmSecuritySystem',
                inputType: InputTypes.Checkbox,
                label: 'Alarm/Security System'
              },
              {
                name: 'optional-interiorFeatures-ArchedDoorways',
                inputType: InputTypes.Checkbox,
                label: 'Arched Doorways'
              },
              {
                name: 'optional-interiorFeatures-BuiltInFurniture',
                inputType: InputTypes.Checkbox,
                label: 'Built-in Furniture'
              },
              {
                name: 'optional-interiorFeatures-BuiltInShelves',
                inputType: InputTypes.Checkbox,
                label: 'Built-in Shelves'
              },
              {
                name: 'optional-interiorFeatures-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan(s)'
              },
              {
                name: 'optional-interiorFeatures-CODetector',
                inputType: InputTypes.Checkbox,
                label: 'CO Detector'
              },
              {
                name: 'optional-interiorFeatures-DeadBolt',
                inputType: InputTypes.Checkbox,
                label: 'Dead Bolt(s)'
              },
              {
                name: 'optional-interiorFeatures-DoubleSidedFireplace',
                inputType: InputTypes.Checkbox,
                label: 'Double Sided Fireplace'
              },
              {
                name: 'optional-interiorFeatures-DryerConnection',
                inputType: InputTypes.Checkbox,
                label: 'Dryer Connection'
              },
              {
                name: 'optional-interiorFeatures-ElectronicAirFilter',
                inputType: InputTypes.Checkbox,
                label: 'Electronic Air Filter'
              },
              {
                name: 'optional-interiorFeatures-FireplaceBlower',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace Blower'
              },
              {
                name: 'optional-interiorFeatures-GarageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener(s)'
              },
              {
                name: 'optional-interiorFeatures-GardenTub',
                inputType: InputTypes.Checkbox,
                label: 'Garden Tub'
              },
              {
                name: 'optional-interiorFeatures-GasLogs',
                inputType: InputTypes.Checkbox,
                label: 'Gas Logs'
              },
              {
                name: 'optional-interiorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-interiorFeatures-Humidifer',
                inputType: InputTypes.Checkbox,
                label: 'Humidifer'
              },
              {
                name: 'optional-interiorFeatures-InlawSuite',
                inputType: InputTypes.Checkbox,
                label: 'Inlaw Suite'
              },
              {
                name: 'optional-interiorFeatures-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },
              {
                name: 'optional-interiorFeatures-InteriorAtticFan',
                inputType: InputTypes.Checkbox,
                label: 'Interior Attic Fan'
              },
              {
                name: 'optional-interiorFeatures-JettedTub',
                inputType: InputTypes.Checkbox,
                label: 'Jetted Tub'
              },
              {
                name: 'optional-interiorFeatures-KitchenIsland',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Island'
              },
              {
                name: 'optional-interiorFeatures-LaundryChute',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Chute'
              },
              {
                name: 'optional-interiorFeatures-LaundryRm2ndLevel',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Rm - 2nd Level'
              },
              {
                name: 'optional-interiorFeatures-LaundryRmBasement',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Rm - Basement'
              },
              {
                name: 'optional-interiorFeatures-LaundryRmMainLevel',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Rm - Main Level'
              },
              {
                name: 'optional-interiorFeatures-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-interiorFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-interiorFeatures-SeparateShower',
                inputType: InputTypes.Checkbox,
                label: 'Separate Shower'
              },
              {
                name: 'optional-interiorFeatures-Skylight',
                inputType: InputTypes.Checkbox,
                label: 'Skylight'
              },
              {
                name: 'optional-interiorFeatures-SmokeAlarm',
                inputType: InputTypes.Checkbox,
                label: 'Smoke Alarm'
              },
              {
                name: 'optional-interiorFeatures-SolidSurfaceCounter',
                inputType: InputTypes.Checkbox,
                label: 'Solid Surface Counter'
              },
              {
                name: 'optional-interiorFeatures-SumpPump',
                inputType: InputTypes.Checkbox,
                label: 'Sump Pump'
              },
              {
                name: 'optional-interiorFeatures-SurroundSound',
                inputType: InputTypes.Checkbox,
                label: 'Surround Sound'
              },
              {
                name: 'optional-interiorFeatures-TileCounter',
                inputType: InputTypes.Checkbox,
                label: 'Tile Counter'
              },
              {
                name: 'optional-interiorFeatures-VacuumSystem',
                inputType: InputTypes.Checkbox,
                label: 'Vacuum System'
              },
              {
                name: 'optional-interiorFeatures-VaultedCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted Ceilings'
              },
              {
                name: 'optional-interiorFeatures-WasherConnection',
                inputType: InputTypes.Checkbox,
                label: 'Washer Connection'
              },
              {
                name: 'optional-interiorFeatures-WetBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet Bar'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-interiorFeatures-AptSuite',
                inputType: InputTypes.Checkbox,
                label: 'Apt/Suite'
              },
              {
                name: 'optional-interiorFeatures-BathtubShower',
                inputType: InputTypes.Checkbox,
                label: 'Bathtub/Shower Combination'
              },
              {
                name: 'optional-interiorFeatures-Beamed',
                inputType: InputTypes.Checkbox,
                label: 'Beamed Ceilings'
              },
              {
                name: 'optional-interiorFeatures-Bidet',
                inputType: InputTypes.Checkbox,
                label: 'Bidet'
              },
              {
                name: 'optional-interiorFeatures-Bookcases',
                inputType: InputTypes.Checkbox,
                label: 'Bookcases'
              },
              {
                name: 'optional-interiorFeatures-BreakfastBar',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Bar'
              },
              {
                name: "optional-interiorFeatures-ButlersPantry",
                inputType: InputTypes.Checkbox,
                label: "Butler's Pantry"
              },
              {
                name: 'optional-interiorFeatures-CathedralCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Cathedral Ceiling(s)'
              },
              {
                name: 'optional-interiorFeatures-CedarClosets',
                inputType: InputTypes.Checkbox,
                label: 'Cedar Closets'
              },
              {
                name: 'optional-interiorFeatures-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-interiorFeatures-CentralVac',
                inputType: InputTypes.Checkbox,
                label: 'Central Vac'
              },
              {
                name: 'optional-interiorFeatures-CentralVacPrewired',
                inputType: InputTypes.Checkbox,
                label: 'Central Vac Prewired'
              },
              {
                name: 'optional-interiorFeatures-Chandelier',
                inputType: InputTypes.Checkbox,
                label: 'Chandelier'
              },
              {
                name: 'optional-interiorFeatures-CofferedCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Coffered Ceiling'
              },
              {
                name: 'optional-interiorFeatures-CrownMolding',
                inputType: InputTypes.Checkbox,
                label: 'Crown Molding'
              },
              {
                name: 'optional-interiorFeatures-DiningL',
                inputType: InputTypes.Checkbox,
                label: 'Dining L'
              },
              {
                name: 'optional-interiorFeatures-DoubleVanity',
                inputType: InputTypes.Checkbox,
                label: 'Double Vanity'
              },
              {
                name: 'optional-interiorFeatures-DressingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dressing Room'
              },
              {
                name: 'optional-interiorFeatures-DualClosets',
                inputType: InputTypes.Checkbox,
                label: 'Dual Closets'
              },
              {
                name: 'optional-interiorFeatures-EatinKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Eat-in Kitchen'
              },
              {
                name: 'optional-interiorFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-interiorFeatures-EntranceFoyer',
                inputType: InputTypes.Checkbox,
                label: 'Entrance Foyer'
              },
              {
                name: 'optional-interiorFeatures-GraniteCounter',
                inputType: InputTypes.Checkbox,
                label: 'Granite Counters'
              },
              {
                name: 'optional-interiorFeatures-HighCeilings',
                inputType: InputTypes.Checkbox,
                label: 'High Ceilings'
              },
              {
                name: 'optional-interiorFeatures-HighSpeedInternet',
                inputType: InputTypes.Checkbox,
                label: 'High Speed Internet'
              },
              {
                name: 'optional-interiorFeatures-InLawFloorplan',
                inputType: InputTypes.Checkbox,
                label: 'In-Law Floorplan'
              },
              {
                name: 'optional-interiorFeatures-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'optional-interiorFeatures-KitchenIsland',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Island'
              },
              {
                name: 'optional-interiorFeatures-KitchenDiningCombo',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen/Dining Room Combination'
              },
              {
                name: 'optional-interiorFeatures-LivingDiningCombo',
                inputType: InputTypes.Checkbox,
                label: 'Living/Dining Room Combination'
              },
              {
                name: 'optional-interiorFeatures-LaminateCounters',
                inputType: InputTypes.Checkbox,
                label: 'Laminate Counters'
              },
              {
                name: 'optional-interiorFeatures-LowFlowPlumbing',
                inputType: InputTypes.Checkbox,
                label: 'Low Flow Plumbing Fixtures'
              },
              {
                name: 'optional-interiorFeatures-OpenFloorplan',
                inputType: InputTypes.Checkbox,
                label: 'Open Floorplan'
              },
              {
                name: 'optional-interiorFeatures-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-interiorFeatures-PrimaryDownstairs',
                inputType: InputTypes.Checkbox,
                label: 'Primary Downstairs'
              },
              {
                name: 'optional-interiorFeatures-QuartzCounters',
                inputType: InputTypes.Checkbox,
                label: 'Quartz Counters'
              },
              {
                name: 'optional-interiorFeatures-RadonMitigation',
                inputType: InputTypes.Checkbox,
                label: 'Radon Mitigation'
              },
              {
                name: 'optional-interiorFeatures-RecessedLighting',
                inputType: InputTypes.Checkbox,
                label: 'Recessed Lighting'
              },
              {
                name: 'optional-interiorFeatures-RoomOverGarage',
                inputType: InputTypes.Checkbox,
                label: 'Room Over Garage'
              },
              {
                name: 'optional-interiorFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-interiorFeatures-SecondPrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Second Primary Bedroom'
              },
              {
                name: 'optional-interiorFeatures-SeparateShower',
                inputType: InputTypes.Checkbox,
                label: 'Separate Shower'
              },
              {
                name: 'optional-interiorFeatures-SmartCameras',
                inputType: InputTypes.Checkbox,
                label: 'Smart Camera(s)/Recording'
              },
              {
                name: 'optional-interiorFeatures-SmartHome',
                inputType: InputTypes.Checkbox,
                label: 'Smart Home'
              },
              {
                name: 'optional-interiorFeatures-SmartLights',
                inputType: InputTypes.Checkbox,
                label: 'Smart Light(s)'
              },
              {
                name: 'optional-interiorFeatures-SmartThermostat',
                inputType: InputTypes.Checkbox,
                label: 'Smart Thermostat'
              },
              {
                name: 'optional-interiorFeatures-SmoothCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Smooth Ceilings'
              },
              {
                name: 'optional-interiorFeatures-SoakingTub',
                inputType: InputTypes.Checkbox,
                label: 'Soaking Tub'
              },
              {
                name: 'optional-interiorFeatures-SolarTubes',
                inputType: InputTypes.Checkbox,
                label: 'Solar Tube(s)'
              },
              {
                name: 'optional-interiorFeatures-SoundSystem',
                inputType: InputTypes.Checkbox,
                label: 'Sound System'
              },
              {
                name: 'optional-interiorFeatures-StoneCounters',
                inputType: InputTypes.Checkbox,
                label: 'Stone Counters'
              },
              {
                name: 'optional-interiorFeatures-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
              {
                name: 'optional-interiorFeatures-TileCounters',
                inputType: InputTypes.Checkbox,
                label: 'Tile Counters'
              },
              {
                name: 'optional-interiorFeatures-TrackLighting',
                inputType: InputTypes.Checkbox,
                label: 'Track Lighting'
              },
              {
                name: 'optional-interiorFeatures-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
              {
                name: 'optional-interiorFeatures-VaultedCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted Ceiling(s)'
              },
              {
                name: 'optional-interiorFeatures-WalkInCloset',
                inputType: InputTypes.Checkbox,
                label: 'Walk in Closet'
              },
              {
                name: 'optional-interiorFeatures-WalkInShower',
                inputType: InputTypes.Checkbox,
                label: 'Walk in Shower'
              },
              {
                name: 'optional-interiorFeatures-WetBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet Bar'
              },
              {
                name: 'optional-interiorFeatures-WhirlpoolTub',
                inputType: InputTypes.Checkbox,
                label: 'Whirlpool Tub'
              },
              {
                name: 'optional-interiorFeatures-WiredforData',
                inputType: InputTypes.Checkbox,
                label: 'Wired for Data'
              },
              {
                name: 'optional-interiorFeatures-WiredforSound',
                inputType: InputTypes.Checkbox,
                label: 'Wired for Sound'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-interiorFeatures-1stFloorPrimary',
                inputType: InputTypes.Checkbox,
                label: '1st Floor Primary'
              },
              {
                name: 'optional-interiorFeatures-2ndKitchen',
                inputType: InputTypes.Checkbox,
                label: '2nd Kitchen'
              },
              {
                name: 'optional-interiorFeatures-9FtCeiling',
                inputType: InputTypes.Checkbox,
                label: '9 Ft Ceiling'
              },
              {
                name: 'optional-interiorFeatures-AptSuite',
                inputType: InputTypes.Checkbox,
                label: 'Apt/Suite'
              },
              {
                name: 'optional-interiorFeatures-BlindsShades',
                inputType: InputTypes.Checkbox,
                label: 'Blinds/Shades'
              },
              {
                name: 'optional-interiorFeatures-CeilingTrey',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling - Trey'
              },
              {
                name: 'optional-interiorFeatures-CeilingVaulted',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling - Vaulted'
              },
              {
                name: 'optional-interiorFeatures-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan(s)'
              },
              {
                name: 'optional-interiorFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-interiorFeatures-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-interiorFeatures-Furnished',
                inputType: InputTypes.Checkbox,
                label: 'Furnished'
              },
              {
                name: 'optional-interiorFeatures-GasLogs',
                inputType: InputTypes.Checkbox,
                label: 'Gas Logs'
              },
              {
                name: 'optional-interiorFeatures-HomeTheater',
                inputType: InputTypes.Checkbox,
                label: 'Home Theater'
              },
              {
                name: 'optional-interiorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-interiorFeatures-IntercomMusic',
                inputType: InputTypes.Checkbox,
                label: 'Intercom/Music'
              },
              {
                name: 'optional-interiorFeatures-MudRoom',
                inputType: InputTypes.Checkbox,
                label: 'Mud Room'
              },
              {
                name: 'optional-interiorFeatures-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-interiorFeatures-RadonMitInstalled',
                inputType: InputTypes.Checkbox,
                label: 'Radon Mit Installed'
              },
              {
                name: 'optional-interiorFeatures-ReverseFloorPlan',
                inputType: InputTypes.Checkbox,
                label: 'Reverse Floor Plan'
              },
              {
                name: 'optional-interiorFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-interiorFeatures-SecuritySystem',
                inputType: InputTypes.Checkbox,
                label: 'Security System'
              },
              {
                name: 'optional-interiorFeatures-Skylights',
                inputType: InputTypes.Checkbox,
                label: 'Skylights'
              },
              {
                name: 'optional-interiorFeatures-SmokeDetectors',
                inputType: InputTypes.Checkbox,
                label: 'Smoke Detectors'
              },
              {
                name: 'optional-interiorFeatures-SolidSurfaceCounters',
                inputType: InputTypes.Checkbox,
                label: 'Solid Surface Counters'
              },
              {
                name: 'optional-interiorFeatures-SprinklerSystem',
                inputType: InputTypes.Checkbox,
                label: 'Sprinkler System'
              },
              {
                name: 'optional-interiorFeatures-WalkInShower',
                inputType: InputTypes.Checkbox,
                label: 'Walk-in Shower'
              },
              {
                name: 'optional-interiorFeatures-WalkInCloset',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Closet'
              },
              {
                name: 'optional-interiorFeatures-WetBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet Bar'
              },
              {
                name: 'optional-interiorFeatures-Whirlpool',
                inputType: InputTypes.Checkbox,
                label: 'Whirlpool'
              },
              {
                name: 'optional-interiorFeatures-WholeHouseFan',
                inputType: InputTypes.Checkbox,
                label: 'Whole House Fan'
              },
              {
                name: 'optional-interiorFeatures-WoodStove',
                inputType: InputTypes.Checkbox,
                label: 'Wood Stove'
              },
              {
                name: 'optional-interiorFeatures-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-interiorFeatures-AtticFinished',
                inputType: InputTypes.Checkbox,
                label: 'Attic Finished'
              },
              {
                name: 'optional-interiorFeatures-AtticOther',
                inputType: InputTypes.Checkbox,
                label: 'Attic Other'
              },
              {
                name: 'optional-interiorFeatures-AtticStairsFixed',
                inputType: InputTypes.Checkbox,
                label: 'Attic Stairs Fixed'
              },
              {
                name: 'optional-interiorFeatures-AtticStairsPulldown',
                inputType: InputTypes.Checkbox,
                label: 'Attic Stairs Pulldown'
              },
              {
                name: 'optional-interiorFeatures-AtticWalkIn',
                inputType: InputTypes.Checkbox,
                label: 'Attic Walk-in'
              },
              {
                name: 'optional-interiorFeatures-BreakfastBar',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Bar'
              },
              {
                name: 'optional-interiorFeatures-BuiltIns',
                inputType: InputTypes.Checkbox,
                label: 'Built-Ins'
              },
              {
                name: 'optional-interiorFeatures-CableAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Cable Available'
              },
              {
                name: 'optional-interiorFeatures-CathedralCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Cathedral Ceiling(s)'
              },
              {
                name: 'optional-interiorFeatures-CentralVac',
                inputType: InputTypes.Checkbox,
                label: 'Central Vacuum'
              },
              {
                name: 'optional-interiorFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-interiorFeatures-EntranceFoyer',
                inputType: InputTypes.Checkbox,
                label: 'Entrance Foyer'
              },
              {
                name: 'optional-interiorFeatures-GardenTub',
                inputType: InputTypes.Checkbox,
                label: 'Garden Tub'
              },
              {
                name: 'optional-interiorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-interiorFeatures-KitchenIsland',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Island'
              },
              {
                name: 'optional-interiorFeatures-OpenFloorplan',
                inputType: InputTypes.Checkbox,
                label: 'Open Floorplan'
              },
              {
                name: 'optional-interiorFeatures-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-interiorFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-interiorFeatures-SplitBRPlan',
                inputType: InputTypes.Checkbox,
                label: 'Split BR Plan'
              },
              {
                name: 'optional-interiorFeatures-StorageUnit',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
              {
                name: 'optional-interiorFeatures-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
              {
                name: 'optional-interiorFeatures-VaultedCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted Ceiling'
              },
              {
                name: 'optional-interiorFeatures-WalkInCloset',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Closet(s)'
              },
              {
                name: 'optional-interiorFeatures-WalkInPantry',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Pantry'
              },
              {
                name: 'optional-interiorFeatures-WetBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet Bar'
              },
              {
                name: 'optional-interiorFeatures-Whirlpool',
                inputType: InputTypes.Checkbox,
                label: 'Whirlpool'
              },
              {
                name: 'optional-interiorFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Interior Features',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-interiorFeatures-1stFloorBedrmFullBath',
                inputType: InputTypes.Checkbox,
                label: '1st Floor Bedrm W/ Full Bath'
              },
              {
                name: 'optional-interiorFeatures-1stFloorBedroom',
                inputType: InputTypes.Checkbox,
                label: '1st Floor Bedroom'
              },
              {
                name: 'optional-interiorFeatures-1stFloorPrimarBr',
                inputType: InputTypes.Checkbox,
                label: '1st Floor Primary Br'
              },
              {
                name: 'optional-interiorFeatures-9FtCeilings',
                inputType: InputTypes.Checkbox,
                label: '9 Ft + Ceilings'
              },
              {
                name: 'optional-interiorFeatures-Addl1stFloorPrimary',
                inputType: InputTypes.Checkbox,
                label: 'Addl 1st Floor Primary'
              },
              {
                name: 'optional-interiorFeatures-Atrium',
                inputType: InputTypes.Checkbox,
                label: 'Atrium'
              },
              {
                name: 'optional-interiorFeatures-BayBowWindow',
                inputType: InputTypes.Checkbox,
                label: 'Bay/Bow Window'
              },
              {
                name: 'optional-interiorFeatures-BeamedCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Beamed Ceiling'
              },
              {
                name: 'optional-interiorFeatures-BreakfastNook',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Nook'
              },
              {
                name: 'optional-interiorFeatures-Breezeway',
                inputType: InputTypes.Checkbox,
                label: 'Breezeway'
              },
              {
                name: 'optional-interiorFeatures-BuiltInCabinetBookcases',
                inputType: InputTypes.Checkbox,
                label: 'Built In Cabinet/Bookcases'
              },
              {
                name: 'optional-interiorFeatures-ButlersPantry',
                inputType: InputTypes.Checkbox,
                label: 'Butlers Pantry'
              },
              {
                name: 'optional-interiorFeatures-CableTV',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV'
              },
              {
                name: 'optional-interiorFeatures-CathedralCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Cathedral Ceiling'
              },
              {
                name: 'optional-interiorFeatures-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-interiorFeatures-CenterHall',
                inputType: InputTypes.Checkbox,
                label: 'Center Hall'
              },
              {
                name: 'optional-interiorFeatures-CountertopsGraniteStone',
                inputType: InputTypes.Checkbox,
                label: 'Countertops - Granite/Stone'
              },
              {
                name: 'optional-interiorFeatures-CountertopsLaminate',
                inputType: InputTypes.Checkbox,
                label: 'Countertops - Laminate'
              },
              {
                name: 'optional-interiorFeatures-CountertopsSolidSurface',
                inputType: InputTypes.Checkbox,
                label: 'Countertops - Solid Surface'
              },
              {
                name: 'optional-interiorFeatures-CountertopsTile',
                inputType: InputTypes.Checkbox,
                label: 'Countertops - Tile'
              },
              {
                name: 'optional-interiorFeatures-DiningArea',
                inputType: InputTypes.Checkbox,
                label: 'Dining Area'
              },
              {
                name: 'optional-interiorFeatures-DoubleVanity',
                inputType: InputTypes.Checkbox,
                label: 'Double Vanity'
              },
              {
                name: 'optional-interiorFeatures-DryerHookup',
                inputType: InputTypes.Checkbox,
                label: 'Dryer Hookup'
              },
              {
                name: 'optional-interiorFeatures-DSL',
                inputType: InputTypes.Checkbox,
                label: 'DSL'
              },
              {
                name: 'optional-interiorFeatures-EatInKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Eat-In-Kitchen'
              },
              {
                name: 'optional-interiorFeatures-FireSprinkler',
                inputType: InputTypes.Checkbox,
                label: 'Fire Sprinkler'
              },
              {
                name: 'optional-interiorFeatures-Fireplace',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace'
              },
              {
                name: 'optional-interiorFeatures-FormalDiningRm',
                inputType: InputTypes.Checkbox,
                label: 'Formal Dining Rm'
              },
              {
                name: 'optional-interiorFeatures-FrenchDoors',
                inputType: InputTypes.Checkbox,
                label: 'French Doors'
              },
              {
                name: 'optional-interiorFeatures-GardenTub',
                inputType: InputTypes.Checkbox,
                label: 'Garden Tub'
              },
              {
                name: 'optional-interiorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-interiorFeatures-HousekeepersQuarters',
                inputType: InputTypes.Checkbox,
                label: 'Housekeepers Quarters'
              },
              {
                name: 'optional-interiorFeatures-InternalBalcony',
                inputType: InputTypes.Checkbox,
                label: 'Internal Balcony'
              },
              {
                name: 'optional-interiorFeatures-InternetReady',
                inputType: InputTypes.Checkbox,
                label: 'Internet Ready'
              },
              {
                name: 'optional-interiorFeatures-Island',
                inputType: InputTypes.Checkbox,
                label: 'Island'
              },
              {
                name: 'optional-interiorFeatures-JettedTub',
                inputType: InputTypes.Checkbox,
                label: 'Jetted Tub'
              },
              {
                name: 'optional-interiorFeatures-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
              {
                name: 'optional-interiorFeatures-MBRBath',
                inputType: InputTypes.Checkbox,
                label: 'MBR Bath'
              },
              {
                name: 'optional-interiorFeatures-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-interiorFeatures-RecessedLighting',
                inputType: InputTypes.Checkbox,
                label: 'Recessed Lighting'
              },
              {
                name: 'optional-interiorFeatures-RoughInBath',
                inputType: InputTypes.Checkbox,
                label: 'Rough-In Bath'
              },
              {
                name: 'optional-interiorFeatures-SatelliteDish',
                inputType: InputTypes.Checkbox,
                label: 'Satellite Dish'
              },
              {
                name: 'optional-interiorFeatures-SecuritySystem',
                inputType: InputTypes.Checkbox,
                label: 'Security System'
              },
              {
                name: 'optional-interiorFeatures-SeparateSuite',
                inputType: InputTypes.Checkbox,
                label: 'Separate Suite'
              },
              {
                name: 'optional-interiorFeatures-Skylight',
                inputType: InputTypes.Checkbox,
                label: 'Skylight'
              },
              {
                name: 'optional-interiorFeatures-SolarTubes',
                inputType: InputTypes.Checkbox,
                label: 'Solar Tubes'
              },
              {
                name: 'optional-interiorFeatures-StackWshrDryerHookup',
                inputType: InputTypes.Checkbox,
                label: 'Stack Wshr/Dryer Hookup'
              },
              {
                name: 'optional-interiorFeatures-SteamShower',
                inputType: InputTypes.Checkbox,
                label: 'Steam Shower'
              },
              {
                name: 'optional-interiorFeatures-SunkenTub',
                inputType: InputTypes.Checkbox,
                label: 'Sunken Tub'
              },
              {
                name: 'optional-interiorFeatures-TrackLighting',
                inputType: InputTypes.Checkbox,
                label: 'Track Lighting'
              },
              {
                name: 'optional-interiorFeatures-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
              {
                name: 'optional-interiorFeatures-WalkInCloset',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Closet'
              },
              {
                name: 'optional-interiorFeatures-WasherHookup',
                inputType: InputTypes.Checkbox,
                label: 'Washer Hookup'
              },
              {
                name: 'optional-interiorFeatures-WetBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet Bar'
              },
              {
                name: 'optional-interiorFeatures-WindowTreatment',
                inputType: InputTypes.Checkbox,
                label: 'Window Treatment'
              },
              {
                name: 'optional-interiorFeatures-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Primary Bedroom',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-primaryBedroom-VaultedCathCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted/Cath. Ceiling'
              },
              {
                name: 'optional-primaryBedroom-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-primaryBedroom-1stFlrLevel',
                inputType: InputTypes.Checkbox,
                label: '1st Flr Level'
              },
              {
                name: 'optional-primaryBedroom-WallpaperCovering',
                inputType: InputTypes.Checkbox,
                label: 'Wallpaper/Covering'
              },
              {
                name: 'optional-primaryBedroom-WalkInCloset',
                inputType: InputTypes.Checkbox,
                label: 'Walk-in Closet'
              },
              {
                name: 'optional-primaryBedroom-SittingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sitting Room'
              },
              {
                name: 'optional-primaryBedroom-LinenCloset',
                inputType: InputTypes.Checkbox,
                label: 'Linen Closet'
              },
              {
                name: 'optional-primaryBedroom-BeamCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Beam Ceiling'
              },
              {
                name: 'optional-primaryBedroom-Fireplace',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace'
              },
              {
                name: 'optional-primaryBedroom-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Primary Bedroom',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-primaryBedroom-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-primaryBedroom-Downstairs',
                inputType: InputTypes.Checkbox,
                label: 'Downstairs'
              },
              {
                name: 'optional-primaryBedroom-DualMasters',
                inputType: InputTypes.Checkbox,
                label: 'Dual Masters'
              },
              {
                name: 'optional-primaryBedroom-GardenTubShower',
                inputType: InputTypes.Checkbox,
                label: 'Garden Tub/Shower'
              },
              {
                name: 'optional-primaryBedroom-MultipleClosets',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Closets'
              },
              {
                name: 'optional-primaryBedroom-OutsideAccess',
                inputType: InputTypes.Checkbox,
                label: 'Outside Access'
              },
              {
                name: 'optional-primaryBedroom-SittingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Sitting Room'
              },
              {
                name: 'optional-primaryBedroom-Split',
                inputType: InputTypes.Checkbox,
                label: 'Split'
              },
              {
                name: 'optional-primaryBedroom-Upstairs',
                inputType: InputTypes.Checkbox,
                label: 'Upstairs'
              },
              {
                name: 'optional-primaryBedroom-WalkInClosets',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Closets'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Primary Bath',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-primaryBath-BeamCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Beam Ceiling'
              },
              {
                name: 'optional-primaryBath-DoubleSink',
                inputType: InputTypes.Checkbox,
                label: 'Double Sink'
              },
              {
                name: 'optional-primaryBath-GardenTub',
                inputType: InputTypes.Checkbox,
                label: 'Garden Tub'
              },
              {
                name: 'optional-primaryBath-HalfBath',
                inputType: InputTypes.Checkbox,
                label: 'Half Bath'
              },
              {
                name: 'optional-primaryBath-Shower',
                inputType: InputTypes.Checkbox,
                label: 'Shower'
              },
              {
                name: 'optional-primaryBath-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
              {
                name: 'optional-primaryBath-Tub',
                inputType: InputTypes.Checkbox,
                label: 'Tub'
              },
              {
                name: 'optional-primaryBath-Vanity',
                inputType: InputTypes.Checkbox,
                label: 'Vanity'
              },
              {
                name: 'optional-primaryBath-VaultedCathedralCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted/Cathedral Ceiling'
              },
              {
                name: 'optional-primaryBath-WhirlpoolTub',
                inputType: InputTypes.Checkbox,
                label: 'Whirlpool Tub'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Kitchen',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-kitchen-Range',
                inputType: InputTypes.Checkbox,
                label: 'Range'
              },
              {
                name: 'optional-kitchen-WorkIsland',
                inputType: InputTypes.Checkbox,
                label: 'Work Island'
              },
              {
                name: 'optional-kitchen-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-kitchen-GarbageDisposal',
                inputType: InputTypes.Checkbox,
                label: 'Garbage Disposal'
              },
              {
                name: 'optional-kitchen-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'optional-kitchen-Microwave',
                inputType: InputTypes.Checkbox,
                label: 'Microwave'
              },
              {
                name: 'optional-kitchen-BreakfastBar',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Bar'
              },
              {
                name: 'optional-kitchen-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-kitchen-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'optional-kitchen-TrashCompactor',
                inputType: InputTypes.Checkbox,
                label: 'Trash Compactor'
              },
              {
                name: 'optional-kitchen-BreakfastNook',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Nook'
              },
              {
                name: 'optional-kitchen-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-kitchen-Freezer',
                inputType: InputTypes.Checkbox,
                label: 'Freezer'
              },
              {
                name: 'optional-kitchen-RangeHood',
                inputType: InputTypes.Checkbox,
                label: 'Range Hood'
              },
              {
                name: 'optional-kitchen-StainlessSteelAppliances',
                inputType: InputTypes.Checkbox,
                label: 'Stainless Steel Appliances'
              },
              {
                name: 'optional-kitchen-SolidSurfaceCountertops',
                inputType: InputTypes.Checkbox,
                label: 'Solid Surface Countertops'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Security Features',
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-securityFeatures-24HourSecurity',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: '24 Hour Security'
              },
              {
                name: 'optional-securityFeatures-BuildingSecurity',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Building Security'
              },
              {
                name: 'optional-securityFeatures-CarbonMonoxideDetector',
                inputType: InputTypes.Checkbox,
                label: 'Carbon Monoxide Detector'
              },
              {
                name: 'optional-securityFeatures-FireSprinklerSystem',
                inputType: InputTypes.Checkbox,
                label: 'Fire Sprinkler System'
              },
              {
                name: 'optional-securityFeatures-Gated',
                inputType: InputTypes.Checkbox,
                label: 'Gated'
              },              
              {
                name: 'optional-securityFeatures-GatedGuard',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Gated w/Guard'
              },
              {
                name: 'optional-securityFeatures-KeyCardEntry',
                inputType: InputTypes.Checkbox,
                label: 'Key Card Entry'
              },
              {
                name: 'optional-securityFeatures-OpenAccess',
                inputType: InputTypes.Checkbox,
                label: 'Open Access'
              },
              {
                name: 'optional-securityFeatures-SecuritySystem',
                inputType: InputTypes.Checkbox,
                label: 'Security System'
              },
              {
                name: 'optional-securityFeatures-SmokeDetectors',
                inputType: InputTypes.Checkbox,
                label: 'Smoke Detector(s)'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Equipment and Appliances',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-appliances-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan(s)'
              },
              {
                name: 'optional-appliances-CentralVacuum',
                inputType: InputTypes.Checkbox,
                label: 'Central Vacuum'
              },
              {
                name: 'optional-appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-appliances-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'optional-appliances-DryerConnection',
                inputType: InputTypes.Checkbox,
                label: 'Dryer Connection'
              },
              {
                name: 'optional-appliances-ElectricCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Electric Cooktop'
              },
              {
                name: 'optional-appliances-ElectricRange',
                inputType: InputTypes.Checkbox,
                label: 'Electric Range'
              },
              {
                name: 'optional-appliances-ElectricWallOven',
                inputType: InputTypes.Checkbox,
                label: 'Electric Wall Oven'
              },
              {
                name: 'optional-appliances-FireSprinkle',
                inputType: InputTypes.Checkbox,
                label: 'Fire Sprinkle'
              },
              {
                name: 'optional-appliances-GarageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener'
              },
              {
                name: 'optional-appliances-GarbageDisposal',
                inputType: InputTypes.Checkbox,
                label: 'Garbage Disposal'
              },
              {
                name: 'optional-appliances-GasCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Gas - Cooktop'
              },
              {
                name: 'optional-appliances-GasRange',
                inputType: InputTypes.Checkbox,
                label: 'Gas - Range'
              },
              {
                name: 'optional-appliances-GasWallOven',
                inputType: InputTypes.Checkbox,
                label: 'Gas - Wall Oven'
              },
              {
                name: 'optional-appliances-HotTubSpa',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub/Spa'
              },
              {
                name: 'optional-appliances-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },
              {
                name: 'optional-appliances-MicrowaveBuiltIn',
                inputType: InputTypes.Checkbox,
                label: 'Microwave Built-In'
              },
              {
                name: 'optional-appliances-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'optional-appliances-SatelliteDish',
                inputType: InputTypes.Checkbox,
                label: 'Satellite Dish'
              },
              {
                name: 'optional-appliances-SecuritySystem',
                inputType: InputTypes.Checkbox,
                label: 'Security System'
              },
              {
                name: 'optional-appliances-StackedWasherDryer',
                inputType: InputTypes.Checkbox,
                label: 'Stacked Washer/Dryer'
              },
              {
                name: 'optional-appliances-TrashCompactor',
                inputType: InputTypes.Checkbox,
                label: 'Trash Compactor'
              },
              {
                name: 'optional-appliances-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'optional-appliances-WasherConnection',
                inputType: InputTypes.Checkbox,
                label: 'Washer Connection'
              },
              {
                name: 'optional-appliances-Whirlpool',
                inputType: InputTypes.Checkbox,
                label: 'Whirlpool'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Equipment and Appliances',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-appliances-ApplianceCenter',
                inputType: InputTypes.Checkbox,
                label: 'Appliance Center'
              },
              {
                name: 'optional-appliances-AttachedMicrowaveOven',
                inputType: InputTypes.Checkbox,
                label: 'Attached Microwave Oven'
              },
              {
                name: 'optional-appliances-BuiltInGrill',
                inputType: InputTypes.Checkbox,
                label: 'Built-in Grill'
              },
              {
                name: 'optional-appliances-BuiltInOven',
                inputType: InputTypes.Checkbox,
                label: 'Built-In Oven'
              },
              {
                name: 'optional-appliances-BuiltInRange',
                inputType: InputTypes.Checkbox,
                label: 'Built-in Range'
              },
              {
                name: 'optional-appliances-BuiltInRefrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Built-in Refrigerator'
              },
              {
                name: 'optional-appliances-Compactor',
                inputType: InputTypes.Checkbox,
                label: 'Compactor'
              },
              {
                name: 'optional-appliances-ConvectionMicrowave',
                inputType: InputTypes.Checkbox,
                label: 'Convection Microwave'
              },
              {
                name: 'optional-appliances-ConvectionOven',
                inputType: InputTypes.Checkbox,
                label: 'Convection Oven'
              },
              {
                name: 'optional-appliances-CookTop',
                inputType: InputTypes.Checkbox,
                label: 'Cook Top'
              },
              {
                name: 'optional-appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-appliances-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'optional-appliances-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'optional-appliances-DraftRange',
                inputType: InputTypes.Checkbox,
                label: 'Draft Range'
              },
              {
                name: 'optional-appliances-DualFuelRange',
                inputType: InputTypes.Checkbox,
                label: 'Dual-Fuel Range'
              },
              {
                name: 'optional-appliances-ExhaustFan',
                inputType: InputTypes.Checkbox,
                label: 'Exhaust Fan'
              },
              {
                name: 'optional-appliances-GasCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Gas Cooktop'
              },
              {
                name: 'optional-appliances-HotWaterDispenser',
                inputType: InputTypes.Checkbox,
                label: 'Hot Water Dispenser'
              },
              {
                name: 'optional-appliances-SlideInOvenRange',
                inputType: InputTypes.Checkbox,
                label: 'Slide-In Oven/Range'
              },
              {
                name: 'optional-appliances-SmoothCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Smooth Cooktop'
              },
              {
                name: 'optional-appliances-VentHood',
                inputType: InputTypes.Checkbox,
                label: 'Vent Hood'
              },
              {
                name: 'optional-appliances-WarmingDrawer',
                inputType: InputTypes.Checkbox,
                label: 'Warming Drawer'
              },
              {
                name: 'optional-appliances-WaterPurifier',
                inputType: InputTypes.Checkbox,
                label: 'Water Purifier'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Equipment and Appliances',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-appliances-Chlorinator',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Chlorinator'
              },
              {
                name: 'optional-appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-appliances-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'optional-appliances-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'optional-appliances-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'optional-appliances-DowndraftCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Downdraft Cooktop'
              },
              {
                name: 'optional-appliances-ElectricCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Electric Cooktop'
              },
              {
                name: 'optional-appliances-ElectricRange',
                inputType: InputTypes.Checkbox,
                label: 'Electric Range'
              },
              {
                name: 'optional-appliances-EnergyStarAppliances',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Appliances'
              },
              {
                name: 'optional-appliances-Freezer',
                inputType: InputTypes.Checkbox,
                label: 'Freezer'
              },
              {
                name: 'optional-appliances-GasCooktop',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Gas Cooktop'
              },
              {
                name: 'optional-appliances-GasRange',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Gas Range'
              },
              {
                name: 'optional-appliances-GasWaterHeater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Gas Water Heater'
              },
              {
                name: 'optional-appliances-Humidifier',
                inputType: InputTypes.Checkbox,
                label: 'Humidifier'
              },
              {
                name: 'optional-appliances-IndoorGrill',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Grill'
              },
              {
                name: 'optional-appliances-InductionCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Induction Cooktop'
              },
              {
                name: 'optional-appliances-InstantHotWater',
                inputType: InputTypes.Checkbox,
                label: 'Instant Hot Water'
              },
              {
                name: 'optional-appliances-Microwave',
                inputType: InputTypes.Checkbox,
                label: 'Microwave'
              },
              {
                name: 'optional-appliances-Oven',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent],
                label: 'Oven'
              },
              {
                name: 'optional-appliances-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'optional-appliances-SelfCleaningOven',
                inputType: InputTypes.Checkbox,
                label: 'Self Cleaning Oven'
              },
              {
                name: 'optional-appliances-SmartAppliances',
                inputType: InputTypes.Checkbox,
                label: 'Smart Appliances'
              },
              {
                name: 'optional-appliances-SolarHotWater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent],
                label: 'Solar Hot Water'
              },
              {
                name: 'optional-appliances-StainlessSteelAppli',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent],
                label: 'Stainless Steel Appliances'
              },
              {
                name: 'optional-appliances-TanklessWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Tankless Water Heater'
              },
              {
                name: 'optional-appliances-TrashCompactor',
                inputType: InputTypes.Checkbox,
                label: 'Trash Compactor'
              },
              {
                name: 'optional-appliances-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'optional-appliances-WDStackUnit',
                inputType: InputTypes.Checkbox,
                label: 'W/D Stacked Unit'
              },
              {
                name: 'optional-appliances-WaterHeater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent],
                label: 'Water Heater'
              },
              {
                name: 'optional-appliances-WaterPurifierOwned',
                inputType: InputTypes.Checkbox,
                label: 'Water Purifier Owned'
              },
              {
                name: 'optional-appliances-WaterPurifierRented',
                inputType: InputTypes.Checkbox,
                label: 'Water Purifier Rented'
              },
              {
                name: 'optional-appliances-WaterSoftener',
                inputType: InputTypes.Checkbox,
                label: 'Water Softener'
              },
              {
                name: 'optional-appliances-WineFridge',
                inputType: InputTypes.Checkbox,
                label: 'Wine Fridge'
              },
              {
                name: 'optional-appliances-None',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Equipment and Appliances',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {

            fields: [
              {
                name: 'optional-appliances-CentralVac',
                inputType: InputTypes.Checkbox,
                label: 'Central Vac'
              },
              {
                name: 'optional-appliances-Compactor',
                inputType: InputTypes.Checkbox,
                label: 'Compactor'
              },
              {
                name: 'optional-appliances-ConvectionOven',
                inputType: InputTypes.Checkbox,
                label: 'Convection Oven'
              },
              {
                name: 'optional-appliances-CooktopElectric',
                inputType: InputTypes.Checkbox,
                label: 'Cooktop - Electric'
              },
              {
                name: 'optional-appliances-CooktopGas',
                inputType: InputTypes.Checkbox,
                label: 'Cooktop - Gas'
              },
              {
                name: 'optional-appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-appliances-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'optional-appliances-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'optional-appliances-Downdraft',
                inputType: InputTypes.Checkbox,
                label: 'Downdraft'
              },
              {
                name: 'optional-appliances-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'optional-appliances-Generator',
                inputType: InputTypes.Checkbox,
                label: 'Generator'
              },
              {
                name: 'optional-appliances-HumidifierDehumidifier',
                inputType: InputTypes.Checkbox,
                label: 'Humidifier / Dehumidifier'
              },
              {
                name: 'optional-appliances-IceMaker',
                inputType: InputTypes.Checkbox,
                label: 'Ice Maker'
              },
              {
                name: 'optional-appliances-MicrowaveBuiltIn',
                inputType: InputTypes.Checkbox,
                label: 'Microwave - Built In'
              },
              {
                name: 'optional-appliances-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'optional-appliances-StoveOvenElectric',
                inputType: InputTypes.Checkbox,
                label: 'Stove/Oven - Electric'
              },
              {
                name: 'optional-appliances-StoveOvenGas',
                inputType: InputTypes.Checkbox,
                label: 'Stove/Oven - Gas'
              },
              {
                name: 'optional-appliances-VentHood',
                inputType: InputTypes.Checkbox,
                label: 'Vent Hood'
              },
              {
                name: 'optional-appliances-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'optional-appliances-WaterSoftener',
                inputType: InputTypes.Checkbox,
                label: 'Water Softener'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Equipment and Appliances',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-appliances-BevRef',
                inputType: InputTypes.Checkbox,
                label: 'Beverage Refrigerator'
              },
              {
                name: 'optional-appliances-ConvectionOven',
                inputType: InputTypes.Checkbox,
                label: 'Convection Oven'
              },
              {
                name: 'optional-appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-appliances-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'optional-appliances-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'optional-appliances-DowndraftCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Downdraft Cooktop'
              },
              {
                name: 'optional-appliances-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'optional-appliances-DualFlush',
                inputType: InputTypes.Checkbox,
                label: 'Dual Flush Toilets'
              },
              {
                name: 'optional-appliances-ElecCookTop',
                inputType: InputTypes.Checkbox,
                label: 'Electric Cooktop'
              },
              {
                name: 'optional-appliances-ElecOven',
                inputType: InputTypes.Checkbox,
                label: 'Electric Oven'
              },
              {
                name: 'optional-appliances-ElecRange',
                inputType: InputTypes.Checkbox,
                label: 'Electric Range'
              },
              {
                name: 'optional-appliances-ElecWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Electric Water Heater'
              },
              {
                name: 'optional-appliances-ClothesWasherEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Clothes Washer'
              },
              {
                name: 'optional-appliances-DishwasherEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Dishwasher'
              },
              {
                name: 'optional-appliances-DryerEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Dryer'
              },
              {
                name: 'optional-appliances-FreezerEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Freezer'
              },
              {
                name: 'optional-appliances-LightFixturesEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Light Fixtures'
              },
              {
                name: 'optional-appliances-RefrigeratorEnergyStar',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Refrigerator'
              },
              {
                name: 'optional-appliances-ExhaustFan',
                inputType: InputTypes.Checkbox,
                label: 'Exhaust Fan'
              },
              {
                name: 'optional-appliances-FilterSyst',
                inputType: InputTypes.Checkbox,
                label: 'Filtration System'
              },
              {
                name: 'optional-appliances-Freezer',
                inputType: InputTypes.Checkbox,
                label: 'Freezer'
              },          
              {
                name: 'optional-appliances-GasCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Gas Cooktop'
              },
              {
                name: 'optional-appliances-GasOven',
                inputType: InputTypes.Checkbox,
                label: 'Gas Oven'
              },
              {
                name: 'optional-appliances-GasRange',
                inputType: InputTypes.Checkbox,
                label: 'Gas Range'
              },
              {
                name: 'optional-appliances-GasWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Gas Water Heater'
              },
              {
                name: 'optional-appliances-Grilltop',
                inputType: InputTypes.Checkbox,
                label: 'Grilltop'
              },
              {
                name: 'optional-appliances-HybridHeatWater',
                inputType: InputTypes.Checkbox,
                label: 'Hybrid Heat Pump Water Htr'
              },
              {
                name: 'optional-appliances-InductionCooktop',
                inputType: InputTypes.Checkbox,
                label: 'Induction Cooktop'
              },
              {
                name: 'optional-appliances-LowFlowFixtures',
                inputType: InputTypes.Checkbox,
                label: 'Low Flow Fixtures'
              },
              {
                name: 'optional-appliances-Microwave',
                inputType: InputTypes.Checkbox,
                label: 'Microwave'
              },
              {
                name: 'optional-appliances-OilWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Oil Water Heater'
              },
              {
                name: 'optional-appliances-Oven',
                inputType: InputTypes.Checkbox,
                label: 'Oven'
              },
              {
                name: 'optional-appliances-PropaneWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Propane Water Heater'
              }, 
              {
                name: 'optional-appliances-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'optional-appliances-SelfCleaningOven',
                inputType: InputTypes.Checkbox,
                label: 'Self Cleaning Oven'
              },
              {
                name: 'optional-appliances-SolarHotWater',
                inputType: InputTypes.Checkbox,
                label: 'Solar Hot Water'
              },
              {
                name: 'optional-appliances-TanklessWater',
                inputType: InputTypes.Checkbox,
                label: 'Tankless Water Heater'
              },
              {
                name: 'optional-appliances-TrashCompactor',
                inputType: InputTypes.Checkbox,
                label: 'Trash Compactor'
              },
              {
                name: 'optional-appliances-WallOven',
                inputType: InputTypes.Checkbox,
                label: 'Wall Oven'
              },
              {
                name: 'optional-appliances-WarmingDrawer',
                inputType: InputTypes.Checkbox,
                label: 'Warming Drawer'
              },
              {
                name: 'optional-appliances-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'optional-appliances-WasherDryerIncl',
                inputType: InputTypes.Checkbox,
                label: 'Washer and Dryer Included'
              },
              {
                name: 'optional-appliances-WaterSoftener',
                inputType: InputTypes.Checkbox,
                label: 'Water Softener'
              },
              {
                name: 'optional-appliances-WineRefrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Wine Refrigerator'
              },
              {
                name: 'optional-appliances-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-appliances-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Appliances',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-appliances-AtticFan',
                inputType: InputTypes.Checkbox,
                label: 'Attic Fan'
              },
              {
                name: 'optional-appliances-CentralVacuum',
                inputType: InputTypes.Checkbox,
                label: 'Central Vacuum'
              },
              {
                name: 'optional-appliances-Compactor',
                inputType: InputTypes.Checkbox,
                label: 'Compactor'
              },
              {
                name: 'optional-appliances-CountertopRange',
                inputType: InputTypes.Checkbox,
                label: 'Countertop Range'
              },
              {
                name: 'optional-appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-appliances-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'optional-appliances-DoubleOven',
                inputType: InputTypes.Checkbox,
                label: 'Double Oven'
              },
              {
                name: 'optional-appliances-DowndraftRange',
                inputType: InputTypes.Checkbox,
                label: 'Downdraft Range'
              },
              {
                name: 'optional-appliances-DropInRange',
                inputType: InputTypes.Checkbox,
                label: 'Drop-In Range'
              },
              {
                name: 'optional-appliances-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'optional-appliances-ElectricAirCln',
                inputType: InputTypes.Checkbox,
                label: 'Electric Air Cln'
              },
              {
                name: 'optional-appliances-ElectricCooking',
                inputType: InputTypes.Checkbox,
                label: 'Electric Cooking'
              },
              {
                name: 'optional-appliances-ExhaustFan',
                inputType: InputTypes.Checkbox,
                label: 'Exhaust Fan'
              },
              {
                name: 'optional-appliances-FireSprinklerSystem',
                inputType: InputTypes.Checkbox,
                label: 'Fire Sprinkler System'
              },
              {
                name: 'optional-appliances-Freezer',
                inputType: InputTypes.Checkbox,
                label: 'Freezer'
              },
              {
                name: 'optional-appliances-GasCooking',
                inputType: InputTypes.Checkbox,
                label: 'Gas Cooking'
              },
              {
                name: 'optional-appliances-GasGrill',
                inputType: InputTypes.Checkbox,
                label: 'Gas Grill'
              },
              {
                name: 'optional-appliances-Generator',
                inputType: InputTypes.Checkbox,
                label: 'Generator'
              },
              {
                name: 'optional-appliances-GeneratorWired',
                inputType: InputTypes.Checkbox,
                label: 'Generator Wired'
              },
              {
                name: 'optional-appliances-Humidifier',
                inputType: InputTypes.Checkbox,
                label: 'Humidifier'
              },
              {
                name: 'optional-appliances-IceMaker',
                inputType: InputTypes.Checkbox,
                label: 'Ice maker'
              },
              {
                name: 'optional-appliances-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },
              {
                name: 'optional-appliances-Microwave',
                inputType: InputTypes.Checkbox,
                label: 'Microwave'
              },
              {
                name: 'optional-appliances-Oven',
                inputType: InputTypes.Checkbox,
                label: 'Oven'
              },
              {
                name: 'optional-appliances-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'optional-appliances-SatelliteDish',
                inputType: InputTypes.Checkbox,
                label: 'Satellite Dish'
              },
              {
                name: 'optional-appliances-SelfConCleaning',
                inputType: InputTypes.Checkbox,
                label: 'Self/Con Cleaning'
              },
              {
                name: 'optional-appliances-SmokeAlarm',
                inputType: InputTypes.Checkbox,
                label: 'Smoke Alarm'
              },
              {
                name: 'optional-appliances-SmoothTopCooking',
                inputType: InputTypes.Checkbox,
                label: 'Smooth Top Cooking'
              },
              {
                name: 'optional-appliances-StackWasherDryer',
                inputType: InputTypes.Checkbox,
                label: 'Stack Washer/Dryer'
              },
              {
                name: 'optional-appliances-Stove',
                inputType: InputTypes.Checkbox,
                label: 'Stove'
              },
              {
                name: 'optional-appliances-SumpPump',
                inputType: InputTypes.Checkbox,
                label: 'Sump Pump'
              },
              {
                name: 'optional-appliances-WallOven',
                inputType: InputTypes.Checkbox,
                label: 'Wall Oven'
              },
              {
                name: 'optional-appliances-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'optional-appliances-WaterPurifier',
                inputType: InputTypes.Checkbox,
                label: 'Water Purifier'
              },
              {
                name: 'optional-appliances-WaterSoftener',
                inputType: InputTypes.Checkbox,
                label: 'Water Softener'
              },
              {
                name: 'optional-appliances-WineCooler',
                inputType: InputTypes.Checkbox,
                label: 'Wine Cooler'
              }
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Appliances',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-appliances-AirCleaner',
                inputType: InputTypes.Checkbox,
                label: 'Air Cleaner'
              },
              {
                name: 'optional-appliances-BuiltInMicrowave',
                inputType: InputTypes.Checkbox,
                label: 'Built-In Microwave'
              },
              {
                name: 'optional-appliances-BuiltInRange',
                inputType: InputTypes.Checkbox,
                label: 'Built-In Range'
              },
              {
                name: 'optional-appliances-CentralVacuum',
                inputType: InputTypes.Checkbox,
                label: 'Central Vacuum'
              },
              {
                name: 'optional-appliances-CommercialRange',
                inputType: InputTypes.Checkbox,
                label: 'Commercial Range'
              },
              {
                name: 'optional-appliances-Compactor',
                inputType: InputTypes.Checkbox,
                label: 'Compactor'
              },
              {
                name: 'optional-appliances-Cooktop',
                inputType: InputTypes.Checkbox,
                label: 'Cooktop'
              },
              {
                name: 'optional-appliances-CooktopDowndraft',
                inputType: InputTypes.Checkbox,
                label: 'Cooktop Downdraft'
              },
              {
                name: 'optional-appliances-Dishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Dishwasher'
              },
              {
                name: 'optional-appliances-Disposal',
                inputType: InputTypes.Checkbox,
                label: 'Disposal'
              },
              {
                name: 'optional-appliances-Dryer',
                inputType: InputTypes.Checkbox,
                label: 'Dryer'
              },
              {
                name: 'optional-appliances-DryerElectric',
                inputType: InputTypes.Checkbox,
                label: 'Dryer - Electric'
              },
              {
                name: 'optional-appliances-DryerFrontLoading',
                inputType: InputTypes.Checkbox,
                label: 'Dryer - Front Loading'
              },
              {
                name: 'optional-appliances-DryerGas',
                inputType: InputTypes.Checkbox,
                label: 'Dryer - Gas'
              },
              {
                name: 'optional-appliances-DualFlushToilet',
                inputType: InputTypes.Checkbox,
                label: 'Dual Flush Toilet(s)'
              },
              {
                name: 'optional-appliances-EnergyEfficientAppliances',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Appliances'
              },
              {
                name: 'optional-appliances-EnergyStarClothesWasher',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star - Clothes Washer'
              },
              {
                name: 'optional-appliances-EnergyStarDishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star - Dishwasher'
              },
              {
                name: 'optional-appliances-EnergyStarFreezer',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star - Freezer'
              },
              {
                name: 'optional-appliances-EnergyStarRefrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star - Refrigerator'
              },
              {
                name: 'optional-appliances-ExhaustFan',
                inputType: InputTypes.Checkbox,
                label: 'Exhaust Fan'
              },
              {
                name: 'optional-appliances-ExtraRefrigeratorFreezer',
                inputType: InputTypes.Checkbox,
                label: 'Extra Refrigerator / Freezer'
              },
              {
                name: 'optional-appliances-Freezer',
                inputType: InputTypes.Checkbox,
                label: 'Freezer'
              },
              {
                name: 'optional-appliances-Humidifier',
                inputType: InputTypes.Checkbox,
                label: 'Humidifier'
              },
              {
                name: 'optional-appliances-Icemaker',
                inputType: InputTypes.Checkbox,
                label: 'Ice maker'
              },
              {
                name: 'optional-appliances-IndoorGrill',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Grill'
              },
              {
                name: 'optional-appliances-InstantHotWater',
                inputType: InputTypes.Checkbox,
                label: 'Instant Hot Water'
              },
              {
                name: 'optional-appliances-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },
              {
                name: 'optional-appliances-Microwave',
                inputType: InputTypes.Checkbox,
                label: 'Microwave'
              },
              {
                name: 'optional-appliances-OvenDouble',
                inputType: InputTypes.Checkbox,
                label: 'Oven - Double'
              },
              {
                name: 'optional-appliances-OvenSelfCleaning',
                inputType: InputTypes.Checkbox,
                label: 'Oven - Self Cleaning'
              },
              {
                name: 'optional-appliances-OvenSingle',
                inputType: InputTypes.Checkbox,
                label: 'Oven - Single'
              },
              {
                name: 'optional-appliances-OvenWall',
                inputType: InputTypes.Checkbox,
                label: 'Oven - Wall'
              },
              {
                name: 'optional-appliances-OvenRangeElectric',
                inputType: InputTypes.Checkbox,
                label: 'Oven / Range - Electric'
              },
              {
                name: 'optional-appliances-OvenRangeGas',
                inputType: InputTypes.Checkbox,
                label: 'Oven / Range - Gas'
              },
              {
                name: 'optional-appliances-RangeHood',
                inputType: InputTypes.Checkbox,
                label: 'Range Hood'
              },
              {
                name: 'optional-appliances-Refrigerator',
                inputType: InputTypes.Checkbox,
                label: 'Refrigerator'
              },
              {
                name: 'optional-appliances-SixBurnerStove',
                inputType: InputTypes.Checkbox,
                label: 'Six Burner Stove'
              },
              {
                name: 'optional-appliances-StainlessSteelAppliances',
                inputType: InputTypes.Checkbox,
                label: 'Stainless Steel Appliances'
              },
              {
                name: 'optional-appliances-Stove',
                inputType: InputTypes.Checkbox,
                label: 'Stove'
              },
              {
                name: 'optional-appliances-SurfaceUnit',
                inputType: InputTypes.Checkbox,
                label: 'Surface Unit'
              },
              {
                name: 'optional-appliances-TrashCompactor',
                inputType: InputTypes.Checkbox,
                label: 'Trash Compactor'
              },
              {
                name: 'optional-appliances-Washer',
                inputType: InputTypes.Checkbox,
                label: 'Washer'
              },
              {
                name: 'optional-appliances-WasherFrontLoading',
                inputType: InputTypes.Checkbox,
                label: 'Washer - Front Loading'
              },
              {
                name: 'optional-appliances-WasherDryerHookupsOnly',
                inputType: InputTypes.Checkbox,
                label: 'Washer / Dryer - Hookups Only'
              },
              {
                name: 'optional-appliances-WasherDryerStacked',
                inputType: InputTypes.Checkbox,
                label: 'Washer / Dryer - Stacked'
              },
              {
                name: 'optional-appliances-WaterConditionerOwned',
                inputType: InputTypes.Checkbox,
                label: 'Water Conditioner - Owned'
              },
              {
                name: 'optional-appliances-WaterConditionerRented',
                inputType: InputTypes.Checkbox,
                label: 'Water Conditioner - Rented'
              },
              {
                name: 'optional-appliances-WaterDispenser',
                inputType: InputTypes.Checkbox,
                label: 'Water Dispenser'
              },
              {
                name: 'optional-appliances-WaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Water Heater'
              },
              {
                name: 'optional-appliances-WaterHeaterHighEfficiency',
                inputType: InputTypes.Checkbox,
                label: 'Water Heater - High Efficiency'
              },
              {
                name: 'optional-appliances-WaterHeaterSolar',
                inputType: InputTypes.Checkbox,
                label: 'Water Heater - Solar'
              },
              {
                name: 'optional-appliances-WaterHeaterTankless',
                inputType: InputTypes.Checkbox,
                label: 'Water Heater - Tankless'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Walls and Ceilings',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-wallsAndCeilings-2StoryCeilings',
                inputType: InputTypes.Checkbox,
                label: '2 Story Ceilings'
              },
              {
                name: 'optional-wallsAndCeilings-9FootCeilings',
                inputType: InputTypes.Checkbox,
                label: '9 Foot + Ceilings'
              },
              {
                name: 'optional-wallsAndCeilings-BeamedCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Beamed Ceilings'
              },
              {
                name: 'optional-wallsAndCeilings-BrickWalls',
                inputType: InputTypes.Checkbox,
                label: 'Brick Walls'
              },
              {
                name: 'optional-wallsAndCeilings-CathedralCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Cathedral Ceilings'
              },
              {
                name: 'optional-wallsAndCeilings-Drywall',
                inputType: InputTypes.Checkbox,
                label: 'Drywall'
              },
              {
                name: 'optional-wallsAndCeilings-BlockWalls',
                inputType: InputTypes.Checkbox,
                label: 'Block Walls'
              },
              {
                name: 'optional-wallsAndCeilings-LogWalls',
                inputType: InputTypes.Checkbox,
                label: 'Log Walls'
              },
              {
                name: 'optional-wallsAndCeilings-MetalWalls',
                inputType: InputTypes.Checkbox,
                label: 'Metal Walls'
              },
              {
                name: 'optional-wallsAndCeilings-PaneledWalls',
                inputType: InputTypes.Checkbox,
                label: 'Paneled Walls'
              },
              {
                name: 'optional-wallsAndCeilings-PlasterWalls',
                inputType: InputTypes.Checkbox,
                label: 'Plaster Walls'
              },
              {
                name: 'optional-wallsAndCeilings-TrayCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceilings'
              },
              {
                name: 'optional-wallsAndCeilings-VaultedCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted Ceilings'
              },
              {
                name: 'optional-wallsAndCeilings-WoodCeilings',
                inputType: InputTypes.Checkbox,
                label: 'Wood Ceilings'
              },
              {
                name: 'optional-wallsAndCeilings-WoodWalls',
                inputType: InputTypes.Checkbox,
                label: 'Wood Walls'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-flooring-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-flooring-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'optional-flooring-Carpeting',
                inputType: InputTypes.Checkbox,
                label: 'Carpeting'
              },
              {
                name: 'optional-flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-CeramicTile',
                inputType: InputTypes.Checkbox,
                label: 'Ceramic Tile'
              },
              {
                name: 'optional-flooring-Flagstone',
                inputType: InputTypes.Checkbox,
                label: 'Flagstone'
              },
              {
                name: 'optional-flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
              {
                name: 'optional-flooring-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },
              {
                name: 'optional-flooring-Parquet',
                inputType: InputTypes.Checkbox,
                label: 'Parquet'
              },
              {
                name: 'optional-flooring-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-flooring-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-flooring-Terrazzo',
                inputType: InputTypes.Checkbox,
                label: 'Terrazzo'
              },
              {
                name: 'optional-flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-flooring-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'optional-flooring-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'optional-flooring-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },
              {
                name: 'optional-flooring-CarpetOverWood',
                inputType: InputTypes.Checkbox,
                label: 'Carpet Over Wood'
              },
              {
                name: 'optional-flooring-ConcreteSlabGrade',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Slab/Grade'
              },
              {
                name: 'optional-flooring-Engineered',
                inputType: InputTypes.Checkbox,
                label: 'Engineered'
              },
              {
                name: 'optional-flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
              {
                name: 'optional-flooring-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-flooring-Terrazzo',
                inputType: InputTypes.Checkbox,
                label: 'Terrazzo'
              },
              {
                name: 'optional-flooring-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-flooring-VinylFloor',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Floor'
              },
              {
                name: 'optional-flooring-VinylPlank',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Plank'
              },
              {
                name: 'optional-flooring-WoodFloor',
                inputType: InputTypes.Checkbox,
                label: 'Wood Floor'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-Bamboo',
                inputType: InputTypes.Checkbox,
                label: 'Bamboo'
              },
              {
                name: 'optional-flooring-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'optional-flooring-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },
              {
                name: 'optional-flooring-CeramicTile',
                inputType: InputTypes.Checkbox,
                label: 'Ceramic Tile'
              },
              {
                name: 'optional-flooring-Combination',
                inputType: InputTypes.Checkbox,
                label: 'Combination'
              },
              {
                name: 'optional-flooring-Hardwood',
                inputType: InputTypes.Checkbox,
                label: 'Hardwood'
              },
              {
                name: 'optional-flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
              {
                name: 'optional-flooring-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },
              {
                name: 'optional-flooring-Parquet',
                inputType: InputTypes.Checkbox,
                label: 'Parquet'
              },
              {
                name: 'optional-flooring-Plank',
                inputType: InputTypes.Checkbox,
                label: 'Plank'
              },
              {
                name: 'optional-flooring-SimulatedWood',
                inputType: InputTypes.Checkbox,
                label: 'Simulated Wood'
              },
              {
                name: 'optional-flooring-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-flooring-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-flooring-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-Bamboo',
                inputType: InputTypes.Checkbox,
                label: 'Bamboo'
              },
              {
                name: 'optional-flooring-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'optional-flooring-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },
              {
                name: 'optional-flooring-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-flooring-Cork',
                inputType: InputTypes.Checkbox,
                label: 'Cork'
              },
              {
                name: 'optional-flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
              {
                name: 'optional-flooring-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },
              {
                name: 'optional-flooring-Parquet',
                inputType: InputTypes.Checkbox,
                label: 'Parquet'
              },
              {
                name: 'optional-flooring-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-flooring-Terrazzo',
                inputType: InputTypes.Checkbox,
                label: 'Terrazzo'
              },
              {
                name: 'optional-flooring-TileFloor',
                inputType: InputTypes.Checkbox,
                label: 'Tile Floor'
              },
              {
                name: 'optional-flooring-VinylFloor',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Floor'
              },
              {
                name: 'optional-flooring-WoodFloor',
                inputType: InputTypes.Checkbox,
                label: 'Wood Floor'
              },
              {
                name: 'optional-flooring-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-Bamboo',
                inputType: InputTypes.Checkbox,
                label: 'Bamboo'
              },
              {
                name: 'optional-flooring-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'optional-flooring-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },
              {
                name: 'optional-flooring-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-flooring-Cork',
                inputType: InputTypes.Checkbox,
                label: 'Cork'
              },
              {
                name: 'optional-flooring-LaminateStone',
                inputType: InputTypes.Checkbox,
                label: 'Laminate Stone'
              },
              {
                name: 'optional-flooring-LaminateTile',
                inputType: InputTypes.Checkbox,
                label: 'Laminate Tile'
              },
              {
                name: 'optional-flooring-LaminateWood',
                inputType: InputTypes.Checkbox,
                label: 'Laminate Wood'
              },
              {
                name: 'optional-flooring-Linoleum',
                inputType: InputTypes.Checkbox,
                label: 'Linoleum'
              },
              {
                name: 'optional-flooring-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },
              {
                name: 'optional-flooring-Parquet',
                inputType: InputTypes.Checkbox,
                label: 'Parquet'
              },
              {
                name: 'optional-flooring-PrefinishedWood',
                inputType: InputTypes.Checkbox,
                label: 'Prefinished Wood'
              },
              {
                name: 'optional-flooring-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-flooring-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-flooring-SustainableFlooring',
                inputType: InputTypes.Checkbox,
                label: 'Sustainable Flooring'
              },
              {
                name: 'optional-flooring-Terrazzo',
                inputType: InputTypes.Checkbox,
                label: 'Terrazzo'
              },
              {
                name: 'optional-flooring-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-flooring-VinylPlank',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Plank'
              },
              {
                name: 'optional-flooring-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-Bamboo',
                inputType: InputTypes.Checkbox,
                label: 'Bamboo'
              },
              {
                name: 'optional-flooring-CarpetPart',
                inputType: InputTypes.Checkbox,
                label: 'Carpet - Part'
              },
              {
                name: 'optional-flooring-CarpetWallWall',
                inputType: InputTypes.Checkbox,
                label: 'Carpet - Wall to Wall'
              },
              {
                name: 'optional-flooring-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-flooring-Cork',
                inputType: InputTypes.Checkbox,
                label: 'Cork'
              },
              {
                name: 'optional-flooring-Granite',
                inputType: InputTypes.Checkbox,
                label: 'Granite'
              },
              {
                name: 'optional-flooring-Laminate',
                inputType: InputTypes.Checkbox,
                label: 'Laminate'
              },
              {
                name: 'optional-flooring-Linoleum',
                inputType: InputTypes.Checkbox,
                label: 'Linoleum'
              },
              {
                name: 'optional-flooring-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },
              {
                name: 'optional-flooring-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-flooring-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-flooring-TileCeramic',
                inputType: InputTypes.Checkbox,
                label: 'Tile - Ceramic'
              },
              {
                name: 'optional-flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-flooring-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'optional-flooring-WoodParquet',
                inputType: InputTypes.Checkbox,
                label: 'Wood-Parquet'
              },
              {
                name: 'optional-flooring-WoodPart',
                inputType: InputTypes.Checkbox,
                label: 'Wood-Part'
              }
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Flooring',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-flooring-Bamboo',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Bamboo'
              },
              {
                name: 'optional-flooring-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },
              {
                name: 'optional-flooring-CeramicTile',
                inputType: InputTypes.Checkbox,
                label: 'Ceramic Tile'
              },
              {
                name: 'optional-flooring-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-flooring-Dirt',
                inputType: InputTypes.Checkbox,
                label: 'Dirt'
              },
              {
                name: 'optional-flooring-FullyCarpeted',
                inputType: InputTypes.Checkbox,
                label: 'Fully Carpeted'
              },
              {
                name: 'optional-flooring-Hardwood',
                inputType: InputTypes.Checkbox,
                label: 'Hardwood'
              },
              {
                name: 'optional-flooring-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'optional-flooring-HeavyDuty',
                inputType: InputTypes.Checkbox,
                label: 'Heavy Duty'
              },
              {
                name: 'optional-flooring-Laminated',
                inputType: InputTypes.Checkbox,
                label: 'Laminated'
              },
              {
                name: 'optional-flooring-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },
              {
                name: 'optional-flooring-PartiallyCarpeted',
                inputType: InputTypes.Checkbox,
                label: 'Partially Carpeted'
              },
              {
                name: 'optional-flooring-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-flooring-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-flooring-Terrazzo',
                inputType: InputTypes.Checkbox,
                label: 'Terrazzo'
              },
              {
                name: 'optional-flooring-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-flooring-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'optional-flooring-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Doors and Windows',
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
          [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-doorsWindows-FrenchDoor',
                inputType: InputTypes.Checkbox,
                label: 'French Door(s)'
              },
              {
                name: 'optional-doorsWindows-InsulatedDoor',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Door(s)'
              },              {
                name: 'optional-doorsWindows-MirrorClosetDoors',
                inputType: InputTypes.Checkbox,
                label: 'Mirrored Closet Door(s)'
              },
              {
                name: 'optional-doorsWindows-ScreenDoor',
                inputType: InputTypes.Checkbox,
                label: 'Screen Door(s)'
              },
              {
                name: 'optional-doorsWindows-SlidingDoors',
                inputType: InputTypes.Checkbox,
                label: 'Sliding Doors'
              },
              {
                name: 'optional-doorsWindows-StormDoor',
                inputType: InputTypes.Checkbox,
                label: 'Storm Door(s)'
              },
              {
                name: 'optional-doorsWindows-InsulatedWindows',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Windows'
              },
              {
                name: 'optional-doorsWindows-Skylights',
                inputType: InputTypes.Checkbox,
                label: 'Skylights'
              },
              {
                name: 'optional-doorsWindows-StormWindow',
                inputType: InputTypes.Checkbox,
                label: 'Storm Window(s)'
              },
              {
                name: 'optional-doorsWindows-WindowTreatments',
                inputType: InputTypes.Checkbox,
                label: 'Window Treatments'
              },
            ]
          }
        ]
      },      
      {
        fieldsetTitle: 'Windows',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-windows-AtriumWindows',
                inputType: InputTypes.Checkbox,
                label: 'Atrium Windows'
              },
              {
                name: 'optional-windows-BayBowWindow',
                inputType: InputTypes.Checkbox,
                label: 'Bay/Bow Window'
              },
              {
                name: 'optional-windows-Casement',
                inputType: InputTypes.Checkbox,
                label: 'Casement'
              },
              {
                name: 'optional-windows-DoublePaned',
                inputType: InputTypes.Checkbox,
                label: 'Double Paned'
              },
              {
                name: 'optional-windows-EnergyEfficient',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient'
              },
              {
                name: 'optional-windows-EnergyStarQualifiedWindows',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Qualified Windows'
              },
              {
                name: 'optional-windows-InsulatedWindows',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Windows'
              },
              {
                name: 'optional-windows-LowEWindows',
                inputType: InputTypes.Checkbox,
                label: 'Low-E Windows'
              },
              {
                name: 'optional-windows-PalladianWindows',
                inputType: InputTypes.Checkbox,
                label: 'Palladian Windows'
              },
              {
                name: 'optional-windows-Screens',
                inputType: InputTypes.Checkbox,
                label: 'Screens'
              },
              {
                name: 'optional-windows-Skylights',
                inputType: InputTypes.Checkbox,
                label: 'Skylights'
              },
              {
                name: 'optional-windows-StormWindows',
                inputType: InputTypes.Checkbox,
                label: 'Storm Windows'
              },
              {
                name: 'optional-windows-TriplePaneWindows',
                inputType: InputTypes.Checkbox,
                label: 'Triple Pane Windows'
              },
              {
                name: 'optional-windows-VinylClad',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Clad'
              },
              {
                name: 'optional-windows-WoodFrame',
                inputType: InputTypes.Checkbox,
                label: 'Wood Frame'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Doors',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-doors-AtriumDoors',
                inputType: InputTypes.Checkbox,
                label: 'Atrium Doors'
              },
              {
                name: 'optional-doors-EnergyStarQualifiedDoors',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Qualified Doors'
              },
              {
                name: 'optional-doors-FrenchDoors',
                inputType: InputTypes.Checkbox,
                label: 'French Doors'
              },
              {
                name: 'optional-doors-InsulatedDoors',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Door(s)'
              },
              {
                name: 'optional-doors-SixPanelDoors',
                inputType: InputTypes.Checkbox,
                label: 'Six Panel Door(s)'
              },
              {
                name: 'optional-doors-SlidingGlassDoor',
                inputType: InputTypes.Checkbox,
                label: 'Sliding Glass Door'
              },
              {
                name: 'optional-doors-StormDoors',
                inputType: InputTypes.Checkbox,
                label: 'Storm Doors'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fireplace Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fireplaceFeatures-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-fireplaceFeatures-FactoryBuilt',
                inputType: InputTypes.Checkbox,
                label: 'Factory Built'
              },
              {
                name: 'optional-fireplaceFeatures-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-fireplaceFeatures-GasLog',
                inputType: InputTypes.Checkbox,
                label: 'Gas Log'
              },
              {
                name: 'optional-fireplaceFeatures-GasStarter',
                inputType: InputTypes.Checkbox,
                label: 'Gas Starter'
              },
              {
                name: 'optional-fireplaceFeatures-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-fireplaceFeatures-Masonry',
                inputType: InputTypes.Checkbox,
                label: 'Masonry'
              },
              {
                name: 'optional-fireplaceFeatures-MasterBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Master Bedroom'
              },
              {
                name: 'optional-fireplaceFeatures-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'optional-fireplaceFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-fireplaceFeatures-Outside',
                inputType: InputTypes.Checkbox,
                label: 'Outside'
              },
              {
                name: 'optional-fireplaceFeatures-WoodBurningStove',
                inputType: InputTypes.Checkbox,
                label: 'Wood Burning Stove'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fireplace Description / Location',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fireplaces-One',
                inputType: InputTypes.Checkbox,
                label: 'One'
              },
              {
                name: 'optional-fireplaces-Two',
                inputType: InputTypes.Checkbox,
                label: 'Two'
              },
              {
                name: 'optional-fireplaces-Three',
                inputType: InputTypes.Checkbox,
                label: 'Three'
              },
              {
                name: 'optional-fireplaces-ThreePlus',
                inputType: InputTypes.Checkbox,
                label: 'Three +'
              },
              {
                name: 'optional-fireplaces-Bedroom',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom'
              },
              {
                name: 'optional-fireplaces-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-fireplaces-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-fireplaces-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-fireplaces-FreeStanding',
                inputType: InputTypes.Checkbox,
                label: 'Free Standing'
              },
              {
                name: 'optional-fireplaces-GasConnection',
                inputType: InputTypes.Checkbox,
                label: 'Gas Connection'
              },
              {
                name: 'optional-fireplaces-GasLogs',
                inputType: InputTypes.Checkbox,
                label: 'Gas Logs'
              },
              {
                name: 'optional-fireplaces-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-fireplaces-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-fireplaces-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-fireplaces-WoodBurning',
                inputType: InputTypes.Checkbox,
                label: 'Wood Burning'
              },
              {
                name: 'optional-fireplaces-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fireplace Location',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fireplaces-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-fireplaces-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-fireplaces-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room'
              },
              {
                name: 'optional-fireplaces-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-fireplaces-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-fireplaces-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-fireplaces-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-fireplaces-Outdoor',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor'
              },
              {
                name: 'optional-fireplaces-Playroom',
                inputType: InputTypes.Checkbox,
                label: 'Playroom'
              },
              {
                name: 'optional-fireplaces-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },   
      {
        fieldsetTitle: 'Fireplace',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },      
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fireplaces-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-fireplaces-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-fireplaces-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-fireplaces-FirePit',
                inputType: InputTypes.Checkbox,
                label: 'Fire Pit'
              },
              {
                name: 'optional-fireplaces-GasLogs',
                inputType: InputTypes.Checkbox,
                label: 'Gas Logs'
              },
              {
                name: 'optional-fireplaces-GasStarter',
                inputType: InputTypes.Checkbox,
                label: 'Gas Starter'
              },
              {
                name: 'optional-fireplaces-GasUnvented',
                inputType: InputTypes.Checkbox,
                label: 'Gas Unvented'
              },
              {
                name: 'optional-fireplaces-GasVented',
                inputType: InputTypes.Checkbox,
                label: 'Gas Vented'
              },
              {
                name: 'optional-fireplaces-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-fireplaces-Insert',
                inputType: InputTypes.Checkbox,
                label: 'Insert'
              },
              {
                name: 'optional-fireplaces-KeepingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Keeping Room'
              },
              {
                name: 'optional-fireplaces-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-fireplaces-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-fireplaces-Outside',
                inputType: InputTypes.Checkbox,
                label: 'Outside'
              },
              {
                name: 'optional-fireplaces-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-fireplaces-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-fireplaces-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'optional-fireplaces-RecRoom',
                inputType: InputTypes.Checkbox,
                label: 'Rec Room'
              },
              {
                name: 'optional-fireplaces-SeeThru',
                inputType: InputTypes.Checkbox,
                label: 'See Thru'
              },
              {
                name: 'optional-fireplaces-WoodBurning',
                inputType: InputTypes.Checkbox,
                label: 'Wood Burning'
              },
              {
                name: 'optional-fireplaces-WoodStove',
                inputType: InputTypes.Checkbox,
                label: 'Wood Burning Stove'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fireplace Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fireplaces-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-fireplaces-Bedroom',
                inputType: InputTypes.Checkbox,
                label: 'Bedroom'
              },
              {
                name: 'optional-fireplaces-Decorative',
                inputType: InputTypes.Checkbox,
                label: 'Decorative'
              },
              {
                name: 'optional-fireplaces-Den',
                inputType: InputTypes.Checkbox,
                label: 'Den'
              },
              {
                name: 'optional-fireplaces-DoubleSided',
                inputType: InputTypes.Checkbox,
                label: 'Double Sided'
              },
              {
                name: 'optional-fireplaces-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-fireplaces-FamilyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Family Room'
              },
              {
                name: 'optional-fireplaces-FirePit',
                inputType: InputTypes.Checkbox,
                label: 'Fire Pit'
              },
              {
                name: 'optional-fireplaces-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-fireplaces-GasLogs',
                inputType: InputTypes.Checkbox,
                label: 'Gas Logs'
              },
              {
                name: 'optional-fireplaces-GasStarter',
                inputType: InputTypes.Checkbox,
                label: 'Gas Starter'
              },
              {
                name: 'optional-fireplaces-GlassDoors',
                inputType: InputTypes.Checkbox,
                label: 'Glass Doors'
              },
              {
                name: 'optional-fireplaces-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-fireplaces-Insert',
                inputType: InputTypes.Checkbox,
                label: 'Insert'
              },
              {
                name: 'optional-fireplaces-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-fireplaces-LivingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Living Room'
              },
              {
                name: 'optional-fireplaces-Masonry',
                inputType: InputTypes.Checkbox,
                label: 'Masonry'
              },
              {
                name: 'optional-fireplaces-Outside',
                inputType: InputTypes.Checkbox,
                label: 'Outside'
              },
              {
                name: 'optional-fireplaces-PrimaryBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom'
              },
              {
                name: 'optional-fireplaces-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'optional-fireplaces-RecRoom',
                inputType: InputTypes.Checkbox,
                label: 'Rec Room'
              },
              {
                name: 'optional-fireplaces-SeeThrough',
                inputType: InputTypes.Checkbox,
                label: 'See Through'
              },
              {
                name: 'optional-fireplaces-WoodBurning',
                inputType: InputTypes.Checkbox,
                label: 'Wood Burning'
              },
              {
                name: 'optional-fireplaces-WoodStove',
                inputType: InputTypes.Checkbox,
                label: 'Wood Burning Stove'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fireplaces Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fireplacesFeatures-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'optional-fireplacesFeatures-DirectVent',
                inputType: InputTypes.Checkbox,
                label: 'Direct Vent'
              },
              {
                name: 'optional-fireplacesFeatures-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-fireplacesFeatures-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-fireplacesFeatures-NonVented',
                inputType: InputTypes.Checkbox,
                label: 'Non-Vented'
              },
              {
                name: 'optional-fireplacesFeatures-NonWorking',
                inputType: InputTypes.Checkbox,
                label: 'Non-Working'
              },
              {
                name: 'optional-fireplacesFeatures-Prefabricate',
                inputType: InputTypes.Checkbox,
                label: 'Prefabricate'
              },
              {
                name: 'optional-fireplacesFeatures-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-fireplacesFeatures-WoodBurning',
                inputType: InputTypes.Checkbox,
                label: 'Wood Burning'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fireplaces Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fireplacesFeatures-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },  
              {
                name: 'optional-fireplacesFeatures-Equipment',
                inputType: InputTypes.Checkbox,
                label: 'Equipment'
              },  
              {
                name: 'optional-fireplacesFeatures-FireGlassDoors',
                inputType: InputTypes.Checkbox,
                label: 'Fire - Glass Doors'
              },  
              {
                name: 'optional-fireplacesFeatures-FlueForStove',
                inputType: InputTypes.Checkbox,
                label: 'Flue for Stove'
              },  
              {
                name: 'optional-fireplacesFeatures-GasPropane',
                inputType: InputTypes.Checkbox,
                label: 'Gas / Propane'
              },  
              {
                name: 'optional-fireplacesFeatures-Heatilator',
                inputType: InputTypes.Checkbox,
                label: 'Heatilator'
              },  
              {
                name: 'optional-fireplacesFeatures-Insert',
                inputType: InputTypes.Checkbox,
                label: 'Insert'
              },  
              {
                name: 'optional-fireplacesFeatures-Mantel',
                inputType: InputTypes.Checkbox,
                label: 'Mantel(s)'
              },  
              {
                name: 'optional-fireplacesFeatures-Marble',
                inputType: InputTypes.Checkbox,
                label: 'Marble'
              },  
              {
                name: 'optional-fireplacesFeatures-NonFunctioning',
                inputType: InputTypes.Checkbox,
                label: 'Non-Functioning'
              },  
              {
                name: 'optional-fireplacesFeatures-Screen',
                inputType: InputTypes.Checkbox,
                label: 'Screen'
              },  
              {
                name: 'optional-fireplacesFeatures-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },  
              {
                name: 'optional-fireplacesFeatures-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },  
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Accessibility Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-accessibilityFeatures-AccessibleDoors',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Doors'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Entrance'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleFullBath',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Full Bath'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleHallways',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Hallway(s)'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleHomeControls',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Home Controls'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Kitchen'
              },
              {
                name: 'optional-accessibilityFeatures-ApproachwithRamp',
                inputType: InputTypes.Checkbox,
                label: 'Approach with Ramp'
              },
              {
                name: 'optional-accessibilityFeatures-ElevatorInstalled',
                inputType: InputTypes.Checkbox,
                label: 'Elevator Installed'
              },
              {
                name: 'optional-accessibilityFeatures-GarageVanAccess',
                inputType: InputTypes.Checkbox,
                label: 'Garage Van Access'
              },
              {
                name: 'optional-accessibilityFeatures-LowDoorHandles',
                inputType: InputTypes.Checkbox,
                label: 'Low Door Handles'
              },
              {
                name: 'optional-accessibilityFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-accessibilityFeatures-ShowerAccessWheelchair',
                inputType: InputTypes.Checkbox,
                label: 'Shower Access Wheelchair'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Accessibility Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-accessibilityFeatures-AccessibleApproachRamp',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Approach with Ramp'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Bedroom'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleCentralLivingArea',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Central Living Area'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleClosets',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Closets'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleDoors',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Doors'
              },
              {
                name: 'optional-accessibilityFeatures-ElectricalControls',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Electrical and Environmental Controls'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleElevator',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Elevator Installed'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Entrance'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleforHearing',
                inputType: InputTypes.Checkbox,
                label: 'Accessible for Hearing-Impairment'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleFullBath',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Full Bath'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleHallways',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Hallway(s)'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Kitchen'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleKitchenAppli',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Kitchen Appliances'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleStairway',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Stairway'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleWasherDryer',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Washer/Dryer'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleWindows',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Windows'
              },
              {
                name: 'optional-accessibilityFeatures-AgingPlace',
                inputType: InputTypes.Checkbox,
                label: 'Aging in Place'
              },
              {
                name: 'optional-accessibilityFeatures-CustomizedWheelchair',
                inputType: InputTypes.Checkbox,
                label: 'Customized Wheelchair Accessible'
              },
              {
                name: 'optional-accessibilityFeatures-ElectronicControls',
                inputType: InputTypes.Checkbox,
                label: 'Electronic Environmental Controls'
              },
              {
                name: 'optional-accessibilityFeatures-ExteriorWheelchairLift',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Wheelchair Lift'
              },
              {
                name: 'optional-accessibilityFeatures-LevelFlooring',
                inputType: InputTypes.Checkbox,
                label: 'Level Flooring'
              },
              {
                name: 'optional-accessibilityFeatures-SafeEgress',
                inputType: InputTypes.Checkbox,
                label: 'Safe Emergency Egress from Home'
              },
              {
                name: 'optional-accessibilityFeatures-SmartTechnology',
                inputType: InputTypes.Checkbox,
                label: 'Smart Technology'
              },
              {
                name: 'optional-accessibilityFeatures-StairLift',
                inputType: InputTypes.Checkbox,
                label: 'Stair Lift'
              },
              {
                name: 'optional-accessibilityFeatures-StandbyGenerator',
                inputType: InputTypes.Checkbox,
                label: 'Standby Generator'
              },
              {
                name: 'optional-accessibilityFeatures-TherapeuticWhirlpool',
                inputType: InputTypes.Checkbox,
                label: 'Therapeutic Whirlpool'
              },
              {
                name: 'optional-accessibilityFeatures-WalkerAccessibleStairs',
                inputType: InputTypes.Checkbox,
                label: 'Walker-Accessible Stairs'
              },              
            ]
          }
        ]
      },  
      {
        fieldsetTitle: 'Accessibility Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-accessibilityFeatures-AccessibleApproachRamp',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Approach Ramp'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleDoors',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Doors'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Entrance'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleBathroom',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Bathroom'
              },
              {
                name: 'optional-accessibilityFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-accessibilityFeatures-ExteriorWheelchairLift',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Wheelchair Lift'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleHallways',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Hallways'
              },
              {
                name: 'optional-accessibilityFeatures-AccessibleKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Accessible Kitchen'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Accessibility Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-accessibilityFeatures-2MoreAccessExits',
                inputType: InputTypes.Checkbox,
                label: '2 or More Access Exits'
              },
              {
                name: 'optional-accessibilityFeatures-Bath60InchTurning',
                inputType: InputTypes.Checkbox,
                label: 'Bath 60 Inch Turning'
              },
              {
                name: 'optional-accessibilityFeatures-BathGrabBars',
                inputType: InputTypes.Checkbox,
                label: 'Bath Grab Bars'
              },
              {
                name: 'optional-accessibilityFeatures-BathLeverFaucets',
                inputType: InputTypes.Checkbox,
                label: 'Bath Lever Faucets'
              },
              {
                name: 'optional-accessibilityFeatures-BathLowMirrors',
                inputType: InputTypes.Checkbox,
                label: 'Bath Low Mirrors'
              },
              {
                name: 'optional-accessibilityFeatures-BathRaisedToilet',
                inputType: InputTypes.Checkbox,
                label: 'Bath Raised Toilet'
              },
              {
                name: 'optional-accessibilityFeatures-BathRollInShower',
                inputType: InputTypes.Checkbox,
                label: 'Bath Roll-In Shower'
              },
              {
                name: 'optional-accessibilityFeatures-BathRollUnderSink',
                inputType: InputTypes.Checkbox,
                label: 'Bath Roll-Under Sink'
              },
              {
                name: 'optional-accessibilityFeatures-BathScaldControlFaucet',
                inputType: InputTypes.Checkbox,
                label: 'Bath Scald Control Faucet'
              },
              {
                name: 'optional-accessibilityFeatures-ClosetBars1548Inches',
                inputType: InputTypes.Checkbox,
                label: 'Closet Bars 15-48 Inches'
              },
              {
                name: 'optional-accessibilityFeatures-DoorWidth32Inches',
                inputType: InputTypes.Checkbox,
                label: 'Door Width 32 Inches'
              },
              {
                name: 'optional-accessibilityFeatures-DoorsLeverHandle',
                inputType: InputTypes.Checkbox,
                label: 'Doors-Lever Handle'
              },
              {
                name: 'optional-accessibilityFeatures-DoorsRecede',
                inputType: InputTypes.Checkbox,
                label: 'Doors-Recede'
              },
              {
                name: 'optional-accessibilityFeatures-DoorsSwingIn',
                inputType: InputTypes.Checkbox,
                label: 'Doors-Swing In'
              },
              {
                name: 'optional-accessibilityFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-accessibilityFeatures-EntrySlopeLessThan1Ft',
                inputType: InputTypes.Checkbox,
                label: 'Entry Slope Less Than 1 Ft'
              },
              {
                name: 'optional-accessibilityFeatures-ExteriorCurbCuts',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Curb Cuts'
              },
              {
                name: 'optional-accessibilityFeatures-HallWidth36Inches',
                inputType: InputTypes.Checkbox,
                label: 'Hall Width 36 Inches'
              },
              {
                name: 'optional-accessibilityFeatures-HandicapParking',
                inputType: InputTypes.Checkbox,
                label: 'Handicap Parking'
              },
              {
                name: 'optional-accessibilityFeatures-Kitchen60InchTurning',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen 60 Inch Turning'
              },
              {
                name: 'optional-accessibilityFeatures-KitchenLowCabinetry',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Low Cabinetry'
              },
              {
                name: 'optional-accessibilityFeatures-KitchenModifiedRange',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Modified Range'
              },
              {
                name: 'optional-accessibilityFeatures-KitchenRaisedDishwasher',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Raised Dishwasher'
              },
              {
                name: 'optional-accessibilityFeatures-KitchenRollUnderSink',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Roll-Under Sink'
              },
              {
                name: 'optional-accessibilityFeatures-KitchenSideOpenOven',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Side Open Oven'
              },
              {
                name: 'optional-accessibilityFeatures-LoweredLightSwitches',
                inputType: InputTypes.Checkbox,
                label: 'Lowered Light Switches'
              },
              {
                name: 'optional-accessibilityFeatures-NoInteriorSteps',
                inputType: InputTypes.Checkbox,
                label: 'No Interior Steps'
              },
              {
                name: 'optional-accessibilityFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-accessibilityFeatures-PoolPowerLift',
                inputType: InputTypes.Checkbox,
                label: 'Pool Power Lift'
              },
              {
                name: 'optional-accessibilityFeatures-PoolRampEntry',
                inputType: InputTypes.Checkbox,
                label: 'Pool Ramp Entry'
              },
              {
                name: 'optional-accessibilityFeatures-RampMainLevel',
                inputType: InputTypes.Checkbox,
                label: 'Ramp(s) Main Level'
              },
              {
                name: 'optional-accessibilityFeatures-RemoteDevices',
                inputType: InputTypes.Checkbox,
                label: 'Remote Devices'
              },
              {
                name: 'optional-accessibilityFeatures-StairLift',
                inputType: InputTypes.Checkbox,
                label: 'Stair Lift'
              },
              {
                name: 'optional-accessibilityFeatures-Thresholds58Inches',
                inputType: InputTypes.Checkbox,
                label: 'Thresholds 5/8 Inches'
              },
              {
                name: 'optional-accessibilityFeatures-VehicleTransferArea',
                inputType: InputTypes.Checkbox,
                label: 'Vehicle Transfer Area'
              },
              {
                name: 'optional-accessibilityFeatures-WheelchairHeightMailbox',
                inputType: InputTypes.Checkbox,
                label: 'Wheelchair Height Mailbox'
              },
              {
                name: 'optional-accessibilityFeatures-WheelchairHeightShelves',
                inputType: InputTypes.Checkbox,
                label: 'Wheelchair Height Shelves'
              },
              {
                name: 'optional-accessibilityFeatures-ZeroGradeEntry',
                inputType: InputTypes.Checkbox,
                label: 'Zero-Grade Entry'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Accessibility Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-accessibilityFeatures-AdditionalFeatures',
                inputType: InputTypes.Checkbox,
                label: 'Additional Features'
              },
              {
                name: 'optional-accessibilityFeatures-AuditoryAlarms',
                inputType: InputTypes.Checkbox,
                label: 'Auditory Alarms'
              },
              {
                name: 'optional-accessibilityFeatures-ChairLift',
                inputType: InputTypes.Checkbox,
                label: 'Chair Lift'
              },
              {
                name: 'optional-accessibilityFeatures-ComfortHeightSwitches',
                inputType: InputTypes.Checkbox,
                label: 'Comfort Height Switches'
              },
              {
                name: 'optional-accessibilityFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-accessibilityFeatures-EntryLevelAccessFullBa',
                inputType: InputTypes.Checkbox,
                label: 'Entry Level Access Full Ba'
              },
              {
                name: 'optional-accessibilityFeatures-EntryLevelAccessKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Entry Level Access Kitchen'
              },
              {
                name: 'optional-accessibilityFeatures-EntryLevelBedrooms',
                inputType: InputTypes.Checkbox,
                label: 'Entry Level Bedrooms'
              },
              {
                name: 'optional-accessibilityFeatures-EntryRamp',
                inputType: InputTypes.Checkbox,
                label: 'Entry Ramp'
              },
              {
                name: 'optional-accessibilityFeatures-GrabBars',
                inputType: InputTypes.Checkbox,
                label: 'Grab Bars'
              },
              {
                name: 'optional-accessibilityFeatures-RollInShower',
                inputType: InputTypes.Checkbox,
                label: 'Roll In Shower'
              },
              {
                name: 'optional-accessibilityFeatures-RollUnderSink',
                inputType: InputTypes.Checkbox,
                label: 'Roll Under Sink'
              },
              {
                name: 'optional-accessibilityFeatures-ShowerSeat',
                inputType: InputTypes.Checkbox,
                label: 'Shower Seat'
              },
              {
                name: 'optional-accessibilityFeatures-StairLift',
                inputType: InputTypes.Checkbox,
                label: 'Stair Lift'
              },
              {
                name: 'optional-accessibilityFeatures-SteplessEntry',
                inputType: InputTypes.Checkbox,
                label: 'Stepless Entry'
              },
              {
                name: 'optional-accessibilityFeatures-VariableHeightCabinets',
                inputType: InputTypes.Checkbox,
                label: 'Variable Height Cabinets'
              },
              {
                name: 'optional-accessibilityFeatures-VariableHeightCounters',
                inputType: InputTypes.Checkbox,
                label: 'Variable Height Counters'
              },
              {
                name: 'optional-accessibilityFeatures-WheelchairAdapted',
                inputType: InputTypes.Checkbox,
                label: 'Wheelchair Adapted'
              },
              {
                name: 'optional-accessibilityFeatures-WideDoorways',
                inputType: InputTypes.Checkbox,
                label: 'Wide Doorways'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-parking-Assigned',
                inputType: InputTypes.Checkbox,
                label: 'Assigned'
              },
              {
                name: 'optional-parking-Carport',
                inputType: InputTypes.Checkbox,
                label: 'Carport'
              },
              {
                name: 'optional-parking-CircularDrive',
                inputType: InputTypes.Checkbox,
                label: 'Circular Drive'
              },
              {
                name: 'optional-parking-CommonDrive',
                inputType: InputTypes.Checkbox,
                label: 'Common Drive'
              },
              {
                name: 'optional-parking-Covered',
                inputType: InputTypes.Checkbox,
                label: 'Covered'
              },
              {
                name: 'optional-parking-DoubleWidth',
                inputType: InputTypes.Checkbox,
                label: 'Double Width'
              },
              {
                name: 'optional-parking-InAlley',
                inputType: InputTypes.Checkbox,
                label: 'In Alley'
              },
              {
                name: 'optional-parking-NoParking',
                inputType: InputTypes.Checkbox,
                label: 'No Parking'
              },
              {
                name: 'optional-parking-OffStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'Off Street Parking'
              },
              {
                name: 'optional-parking-OnStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'On Street Parking'
              },
              {
                name: 'optional-parking-OpenParking',
                inputType: InputTypes.Checkbox,
                label: 'Open Parking'
              },
              {
                name: 'optional-parking-PavedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Paved Driveway'
              },
              {
                name: 'optional-parking-Underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              },
              {
                name: 'optional-parking-UnpavedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Unpaved Driveway'
              },
              {
                name: 'optional-parking-Valet',
                inputType: InputTypes.Checkbox,
                label: 'Valet'
              },
              {
                name: 'optional-parking-Visitor',
                inputType: InputTypes.Checkbox,
                label: 'Visitor'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Garage Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-garageFeatures-Apartment',
                inputType: InputTypes.Checkbox,
                label: 'Apartment'
              },
              {
                name: 'optional-garageFeatures-Attached',
                inputType: InputTypes.Checkbox,
                label: 'Attached'
              },
              {
                name: 'optional-garageFeatures-AutoDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Auto Door Opener'
              },
              {
                name: 'optional-garageFeatures-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-garageFeatures-Detached',
                inputType: InputTypes.Checkbox,
                label: 'Detached'
              },
              {
                name: 'optional-garageFeatures-DirectEntry',
                inputType: InputTypes.Checkbox,
                label: 'Direct Entry'
              },
              {
                name: 'optional-garageFeatures-Finished',
                inputType: InputTypes.Checkbox,
                label: 'Finished'
              },
              {
                name: 'optional-garageFeatures-GolfCart',
                inputType: InputTypes.Checkbox,
                label: 'Golf Cart'
              },
              {
                name: 'optional-garageFeatures-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'optional-garageFeatures-Oversized',
                inputType: InputTypes.Checkbox,
                label: 'Oversized'
              },
              {
                name: 'optional-garageFeatures-PedestrianDoor',
                inputType: InputTypes.Checkbox,
                label: 'Pedestrian Door'
              },
              {
                name: 'optional-garageFeatures-SideRearLoad',
                inputType: InputTypes.Checkbox,
                label: 'Side/Rear Load'
              },
              {
                name: 'optional-garageFeatures-StorageAbove',
                inputType: InputTypes.Checkbox,
                label: 'Storage Above'
              },
              {
                name: 'optional-garageFeatures-Unfinished',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished'
              },
              {
                name: 'optional-garageFeatures-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Dining Room Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-diningRoomFeatures-DiningRmLivingRmCombo',
                inputType: InputTypes.Checkbox,
                label: 'Dining Rm/Living Rm Combo'
              },
              {
                name: 'optional-diningRoomFeatures-LShaped',
                inputType: InputTypes.Checkbox,
                label: 'L Shaped'
              },
              {
                name: 'optional-diningRoomFeatures-Seats12',
                inputType: InputTypes.Checkbox,
                label: 'Seats 12+'
              },
              {
                name: 'optional-diningRoomFeatures-SeparateRoom',
                inputType: InputTypes.Checkbox,
                label: 'Separate Room'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Kitchen',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-kitchen-BreakfastArea',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Area'
              },
              {
                name: 'optional-kitchen-BreakfastBar',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Bar'
              },
              {
                name: 'optional-kitchen-BreakfastRoom',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Room'
              },
              {
                name: 'optional-kitchen-CountryKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Country Kitchen'
              },
              {
                name: 'optional-kitchen-KitchenIsland',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen Island'
              },
              {
                name: 'optional-kitchen-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },
              {
                name: 'optional-kitchen-SecondKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Second Kitchen'
              },
              {
                name: 'optional-kitchen-SolidSurfaceCounters',
                inputType: InputTypes.Checkbox,
                label: 'Solid Surface Counters'
              },
              {
                name: 'optional-kitchen-WalkinPantry',
                inputType: InputTypes.Checkbox,
                label: 'Walk-in Pantry'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Laundry Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-laundryFeatures-CommonArea',
                inputType: InputTypes.Checkbox,
                label: 'Common Area'
              },
              {
                name: 'optional-laundryFeatures-InBasement',
                inputType: InputTypes.Checkbox,
                label: 'In Basement'
              },
              {
                name: 'optional-laundryFeatures-InGarage',
                inputType: InputTypes.Checkbox,
                label: 'In Garage'
              },
              {
                name: 'optional-laundryFeatures-InHall',
                inputType: InputTypes.Checkbox,
                label: 'In Hall'
              },
              {
                name: 'optional-laundryFeatures-InKitchen',
                inputType: InputTypes.Checkbox,
                label: 'In Kitchen'
              },
              {
                name: 'optional-laundryFeatures-InMudRoom',
                inputType: InputTypes.Checkbox,
                label: 'In Mud Room'
              },
              {
                name: 'optional-laundryFeatures-LaundryCloset',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Closet'
              },
              {
                name: 'optional-laundryFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-laundryFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-laundryFeatures-UpperLevel',
                inputType: InputTypes.Checkbox,
                label: 'Upper Level'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Window Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'windowFeatures-BayWindows',
                inputType: InputTypes.Checkbox,
                label: 'Bay Window(s)'
              },
              {
                name: 'windowFeatures-DoublePaneWindows',
                inputType: InputTypes.Checkbox,
                label: 'Double Pane Windows'
              },
              {
                name: 'windowFeatures-Skylights',
                inputType: InputTypes.Checkbox,
                label: 'Skylight(s)'
              },
              {
                name: 'windowFeatures-StormWindows',
                inputType: InputTypes.Checkbox,
                label: 'Storm Window(s)'
              },
              {
                name: 'windowFeatures-WindowTreatments',
                inputType: InputTypes.Checkbox,
                label: 'Window Treatments'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Laundry',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-laundry-CommonArea',
                inputType: InputTypes.Checkbox,
                label: 'Common Area'
              },
              {
                name: 'optional-laundry-ElectricDryerHookup',
                inputType: InputTypes.Checkbox,
                label: 'Electric Dryer Hookup'
              },
              {
                name: 'optional-laundry-GasDryerHookup',
                inputType: InputTypes.Checkbox,
                label: 'Gas Dryer Hookup'
              },            
              {
                name: 'optional-laundry-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-laundry-Carport',
                inputType: InputTypes.Checkbox,
                label: 'Carport'
              },
              {
                name: 'optional-laundry-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-laundry-Hall',
                inputType: InputTypes.Checkbox,
                label: 'Hall'
              },
              {
                name: 'optional-laundry-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-laundry-LaundryCloset',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Closet'
              },
              {
                name: 'optional-laundry-LaundryRoom',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Room'
              },             
              {
                name: 'optional-laundry-LowerLevel',
                inputType: InputTypes.Checkbox,
                label: 'Lower Level'
              },
              {
                name: 'optional-laundry-MainLevel',
                inputType: InputTypes.Checkbox,
                label: 'MainLevel'
              },
              {
                name: 'optional-laundry-UpperLevel',
                inputType: InputTypes.Checkbox,
                label: 'UpperLevel'
              },
              {
                name: 'optional-laundry-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-laundry-MultipleLocations',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Locations'
              },
              {
                name: 'optional-laundry-Outside',
                inputType: InputTypes.Checkbox,
                label: 'Outside'
              },
              {
                name: 'optional-laundry-WasherHookup',
                inputType: InputTypes.Checkbox,
                label: 'Washer Hookup'
              },
              {
                name: 'optional-laundry-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Laundry',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-laundry-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-laundry-Closet',
                inputType: InputTypes.Checkbox,
                label: 'Closet'
              },
              {
                name: 'optional-laundry-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-laundry-Hall',
                inputType: InputTypes.Checkbox,
                label: 'Hall'
              },
              {
                name: 'optional-laundry-HookupDryer',
                inputType: InputTypes.Checkbox,
                label: 'Hookup - Dryer'
              },
              {
                name: 'optional-laundry-HookupWasher',
                inputType: InputTypes.Checkbox,
                label: 'Hookup - Washer'
              },
              {
                name: 'optional-laundry-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-laundry-LaundryRoom',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Room'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Laundry',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-laundry-CommonArea',
                inputType: InputTypes.Checkbox,
                label: 'Common Area'
              },
              {
                name: 'optional-laundry-ElectricDryerHookUp',
                inputType: InputTypes.Checkbox,
                label: 'Electric Dryer Hookup'
              },
              {
                name: 'optional-laundry-GasDryerHookUp',
                inputType: InputTypes.Checkbox,
                label: 'Gas Dryer Hookup'
              },
              {
                name: 'optional-laundry-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-laundry-Carport',
                inputType: InputTypes.Checkbox,
                label: 'Carport'
              },
              {
                name: 'optional-laundry-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-laundry-Hall',
                inputType: InputTypes.Checkbox,
                label: 'Hall'
              },
              {
                name: 'optional-laundry-Kitchen',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen'
              },
              {
                name: 'optional-laundry-MudRoom',
                inputType: InputTypes.Checkbox,
                label: 'Mud Room'
              },
              {
                name: 'optional-laundry-InUnit',
                inputType: InputTypes.Checkbox,
                label: 'In Unit'
              },
              {
                name: 'optional-laundry-UtilityRoom',
                inputType: InputTypes.Checkbox,
                label: 'Utility Room'
              },
              {
                name: 'optional-laundry-Inside',
                inputType: InputTypes.Checkbox,
                label: 'Inside'
              },
              {
                name: 'optional-laundry-LaundryChute',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Chute'
              },
              {
                name: 'optional-laundry-Closet',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Closet'
              },
              {
                name: 'optional-laundry-LaundryRoom',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Room'
              },
              {
                name: 'optional-laundry-Lower',
                inputType: InputTypes.Checkbox,
                label: 'Lower Level'
              },
              {
                name: 'optional-laundry-Main',
                inputType: InputTypes.Checkbox,
                label: 'Main Level'
              },
              {
                name: 'optional-laundry-MultipleLoc',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Locations'
              },
              {
                name: 'optional-laundry-Outside',
                inputType: InputTypes.Checkbox,
                label: 'Outside'
              },
              {
                name: 'optional-laundry-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-laundry-Sink',
                inputType: InputTypes.Checkbox,
                label: 'Sink'
              },
              {
                name: 'optional-laundry-Third',
                inputType: InputTypes.Checkbox,
                label: 'Third'
              },
              {
                name: 'optional-laundry-Upper',
                inputType: InputTypes.Checkbox,
                label: 'Upper'
              },       
               {
                name: 'optional-laundry-WasherHookup',
                inputType: InputTypes.Checkbox,
                label: 'Washer Hookup'
              },
              {
                name: 'optional-laundry-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-laundry-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Laundry',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-laundry-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-laundry-BasementLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Basement Laundry'
              },
              {
                name: 'optional-laundry-CommonLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Common Laundry'
              },
              {
                name: 'optional-laundry-DryerInUnit',
                inputType: InputTypes.Checkbox,
                label: 'Dryer In Unit'
              },
              {
                name: 'optional-laundry-HasLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Has Laundry'
              },
              {
                name: 'optional-laundry-LaundryHookup',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Hookup'
              },
              {
                name: 'optional-laundry-LowerFloorLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Lower Floor Laundry'
              },
              {
                name: 'optional-laundry-MainFloorLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Main Floor Laundry'
              },
              {
                name: 'optional-laundry-SharedLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Shared Laundry'
              },
              {
                name: 'optional-laundry-UpperFloorLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Upper Floor Laundry'
              },
              {
                name: 'optional-laundry-WasherInUnit',
                inputType: InputTypes.Checkbox,
                label: 'Washer In Unit'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Living Room',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-livingRoom-Bar',
                inputType: InputTypes.Checkbox,
                label: 'Bar'
              },
              {
                name: 'optional-livingRoom-Sunken',
                inputType: InputTypes.Checkbox,
                label: 'Sunken'
              },
              {
                name: 'optional-livingRoom-Fireplace',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace'
              },
              {
                name: 'optional-livingRoom-VaultedCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted Ceiling'
              },
              {
                name: 'optional-livingRoom-BeamCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Beam Ceiling'
              },
              {
                name: 'optional-livingRoom-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-livingRoom-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Dining Room',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-diningRoom-Formal',
                inputType: InputTypes.Checkbox,
                label: 'Formal'
              },
              {
                name: 'optional-diningRoom-Area',
                inputType: InputTypes.Checkbox,
                label: 'Area'
              },
              {
                name: 'optional-diningRoom-LivingDiningCombo',
                inputType: InputTypes.Checkbox,
                label: 'Living / Dining Combo'
              },
              {
                name: 'optional-diningRoom-KitchenDiningCombo',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen / Dining Combo'
              },
              {
                name: 'optional-diningRoom-FamilyRoomDiningCombo',
                inputType: InputTypes.Checkbox,
                label: 'Family Room / Dining Combo'
              },
              {
                name: 'optional-diningRoom-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-diningRoom-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
              {
                name: 'optional-diningRoom-VaultedCathedralCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted / Cathedral Ceiling'
              },
            ]
          }
        ]
      },    
      {
        fieldsetTitle: 'Family / Great Room',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-greatRoom-Bar',
                inputType: InputTypes.Checkbox,
                label: 'Bar'
              },
              {
                name: 'optional-greatRoom-WetBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet Bar'
              },
              {
                name: 'optional-greatRoom-VaultedCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Vaulted Ceiling'
              },
              {
                name: 'optional-greatRoom-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan'
              },
              {
                name: 'optional-greatRoom-BeamCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Beam Ceiling'
              },
              {
                name: 'optional-greatRoom-Sunken',
                inputType: InputTypes.Checkbox,
                label: 'Sunken'
              },
              {
                name: 'optional-greatRoom-Paneling',
                inputType: InputTypes.Checkbox,
                label: 'Paneling'
              },
              {
                name: 'optional-greatRoom-WallPaperCovering',
                inputType: InputTypes.Checkbox,
                label: 'Wall Paper / Covering'
              },
              {
                name: 'optional-greatRoom-Fireplace',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace'
              },
              {
                name: 'optional-greatRoom-TrayCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Tray Ceiling'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Other Rooms',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-otherRoom-CarolinaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Carolina Room'
              },
              {
                name: 'optional-otherRoom-DenStudyLibrary',
                inputType: InputTypes.Checkbox,
                label: 'Den/Study/Library'
              },
              {
                name: 'optional-otherRoom-RecreationRoom',
                inputType: InputTypes.Checkbox,
                label: 'Recreation Room'
              },
              {
                name: 'optional-otherRoom-1stFloorBedrooms',
                inputType: InputTypes.Checkbox,
                label: '1st Floor Bedrooms'
              },
              {
                name: 'optional-otherRoom-1stFloorBaths',
                inputType: InputTypes.Checkbox,
                label: '1st Floor Baths'
              },
              {
                name: 'optional-otherRoom-Foyer',
                inputType: InputTypes.Checkbox,
                label: 'Foyer'
              },
              {
                name: 'optional-otherRoom-LaundryUtility',
                inputType: InputTypes.Checkbox,
                label: 'Laundry/Utility'
              },
              {
                name: 'optional-otherRoom-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
              {
                name: 'optional-otherRoom-ConvertedGarage',
                inputType: InputTypes.Checkbox,
                label: 'Converted Garage'
              },
              {
                name: 'optional-otherRoom-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },
              {
                name: 'optional-otherRoom-BonusRoom',
                inputType: InputTypes.Checkbox,
                label: 'Bonus Room'
              },
              {
                name: 'optional-otherRoom-InLawSuite',
                inputType: InputTypes.Checkbox,
                label: 'In Law Suite'
              },
              {
                name: 'optional-otherRoom-MediaRoom',
                inputType: InputTypes.Checkbox,
                label: 'Media Room'
              },
              {
                name: 'optional-otherRoom-Loft',
                inputType: InputTypes.Checkbox,
                label: 'Loft'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Attic',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-attic-AccessOnly',
                inputType: InputTypes.Checkbox,
                label: 'Access Only'
              }, 
              {
                name: 'optional-attic-EaveStorage',
                inputType: InputTypes.Checkbox,
                label: 'Eave Storage'
              }, 
              {
                name: 'optional-attic-Floored',
                inputType: InputTypes.Checkbox,
                label: 'Floored'
              }, 
              {
                name: 'optional-attic-FlooredPartial',
                inputType: InputTypes.Checkbox,
                label: 'Floored - Partial'
              }, 
              {
                name: 'optional-attic-NoAccess',
                inputType: InputTypes.Checkbox,
                label: 'No Access'
              }, 
              {
                name: 'optional-attic-StairsPermanent',
                inputType: InputTypes.Checkbox,
                label: 'Stairs - Permanent'
              }, 
              {
                name: 'optional-attic-StairsPullDown',
                inputType: InputTypes.Checkbox,
                label: 'Stairs - Pull Down'
              }, 
              {
                name: 'optional-attic-WalkIn',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Attic',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-attic-AccessOnly',
                inputType: InputTypes.Checkbox,
                label: 'Access Only'
              }, 
              {
                name: 'optional-attic-AtticFan',
                inputType: InputTypes.Checkbox,
                label: 'Attic Fan'
              }, 
              {
                name: 'optional-attic-EaveStorage',
                inputType: InputTypes.Checkbox,
                label: 'Eave Storage'
              }, 
              {
                name: 'optional-attic-Floored',
                inputType: InputTypes.Checkbox,
                label: 'Floored'
              }, 
              {
                name: 'optional-attic-FlooredPartial',
                inputType: InputTypes.Checkbox,
                label: 'Floored - Partial'
              }, 
              {
                name: 'optional-attic-NoAccess',
                inputType: InputTypes.Checkbox,
                label: 'No Access'
              }, 
              {
                name: 'optional-attic-StairsPermanent',
                inputType: InputTypes.Checkbox,
                label: 'Stairs - Permanent'
              }, 
              {
                name: 'optional-attic-StairsPullDown',
                inputType: InputTypes.Checkbox,
                label: 'Stairs - Pull Down'
              }, 
              {
                name: 'optional-attic-WalkIn',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Separate Living Spaces',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-separateLivingSpace-DetGarageApt',
                inputType: InputTypes.Checkbox,
                label: 'Det Garage Apt'
              },
              {
                name: 'optional-separateLivingSpace-GuestHouse',
                inputType: InputTypes.Checkbox,
                label: 'Guest House'
              },
              {
                name: 'optional-separateLivingSpace-AdditionalSuite',
                inputType: InputTypes.Checkbox,
                label: 'Additional Suite'
              },
              {
                name: 'optional-separateLivingSpace-AdditionalMainBldg',
                inputType: InputTypes.Checkbox,
                label: 'Additional Main Bldg'
              },
              {
                name: 'optional-separateLivingSpace-AdditionalSepBldg',
                inputType: InputTypes.Checkbox,
                label: 'Additional Sep Bldg'
              },
              {
                name: 'optional-separateLivingSpace-AdditionalKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Additional Kitchen'
              },
              {
                name: 'optional-separateLivingSpace-AdditionalSepEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Additional Sep Entrance'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Extra Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-extraFeatures-2ndKitchen',
                inputType: InputTypes.Checkbox,
                label: '2nd Kitchen'
              },  
              {
                name: 'optional-extraFeatures-AirFilterSystem',
                inputType: InputTypes.Checkbox,
                label: 'Air Filter System'
              },  
              {
                name: 'optional-extraFeatures-Attic',
                inputType: InputTypes.Checkbox,
                label: 'Attic'
              },  
              {
                name: 'optional-extraFeatures-AtticHouseFan',
                inputType: InputTypes.Checkbox,
                label: 'Attic / House Fan'
              },  
              {
                name: 'optional-extraFeatures-Bar',
                inputType: InputTypes.Checkbox,
                label: 'Bar'
              },  
              {
                name: 'optional-extraFeatures-BreakfastArea',
                inputType: InputTypes.Checkbox,
                label: 'Breakfast Area'
              },  
              {
                name: 'optional-extraFeatures-BuiltIn',
                inputType: InputTypes.Checkbox,
                label: 'Built-Ins'
              },  
              {
                name: 'optional-extraFeatures-ButlersPantry',
                inputType: InputTypes.Checkbox,
                label: 'Butlers Pantry'
              },  
              {
                name: 'optional-extraFeatures-Carpet',
                inputType: InputTypes.Checkbox,
                label: 'Carpet'
              },  
              {
                name: 'optional-extraFeatures-CedarCloset',
                inputType: InputTypes.Checkbox,
                label: 'Cedar Closet'
              },  
              {
                name: 'optional-extraFeatures-CeilingFan',
                inputType: InputTypes.Checkbox,
                label: 'Ceiling Fan(s)'
              },  
              {
                name: 'optional-extraFeatures-CentralVacuum',
                inputType: InputTypes.Checkbox,
                label: 'Central Vacuum'
              },  
              {
                name: 'optional-extraFeatures-ChairRailing',
                inputType: InputTypes.Checkbox,
                label: 'Chair Railing'
              },  
              {
                name: 'optional-extraFeatures-CombinationDiningLiving',
                inputType: InputTypes.Checkbox,
                label: 'Combination Dining / Living'
              },  
              {
                name: 'optional-extraFeatures-CombinationKitchenDining',
                inputType: InputTypes.Checkbox,
                label: 'Combination Kitchen / Dining'
              },  
              {
                name: 'optional-extraFeatures-CombinationKitchenLiving',
                inputType: InputTypes.Checkbox,
                label: 'Combination Kitchen / Living'
              },  
              {
                name: 'optional-extraFeatures-CrownMolding',
                inputType: InputTypes.Checkbox,
                label: 'Crown Molding'
              },  
              {
                name: 'optional-extraFeatures-CurvedStaircase',
                inputType: InputTypes.Checkbox,
                label: 'Curved Staircase'
              },  
              {
                name: 'optional-extraFeatures-DiningArea',
                inputType: InputTypes.Checkbox,
                label: 'Dining Area'
              },  
              {
                name: 'optional-extraFeatures-DoubleDualStaircase',
                inputType: InputTypes.Checkbox,
                label: 'Double / Dual Staircase'
              },  
              {
                name: 'optional-extraFeatures-Efficiency',
                inputType: InputTypes.Checkbox,
                label: 'Efficiency'
              },  
              {
                name: 'optional-extraFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },  
              {
                name: 'optional-extraFeatures-EntryLevelBedroom',
                inputType: InputTypes.Checkbox,
                label: 'Entry Level Bedroom'
              },  
              {
                name: 'optional-extraFeatures-ExposedBeams',
                inputType: InputTypes.Checkbox,
                label: 'Exposed Beams'
              },  
              {
                name: 'optional-extraFeatures-FamilyRoomOffKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Family Room Off Kitchen'
              },  
              {
                name: 'optional-extraFeatures-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },  
              {
                name: 'optional-extraFeatures-FloorPlanOpen',
                inputType: InputTypes.Checkbox,
                label: 'Floor Plan - Open'
              },  
              {
                name: 'optional-extraFeatures-FloorPlanTraditional',
                inputType: InputTypes.Checkbox,
                label: 'Floor Plan - Traditional'
              },  
              {
                name: 'optional-extraFeatures-FormalSeparateDiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Formal / Separate Dining Room'
              },  
              {
                name: 'optional-extraFeatures-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },  
              {
                name: 'optional-extraFeatures-KitchenCountry',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen - Country'
              },  
              {
                name: 'optional-extraFeatures-KitchenEatIn',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen - Eat-In'
              },  
              {
                name: 'optional-extraFeatures-KitchenEfficiency',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen - Efficiency'
              },  
              {
                name: 'optional-extraFeatures-KitchenGalley',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen - Galley'
              },  
              {
                name: 'optional-extraFeatures-KitchenGourmet',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen - Gourmet'
              },  
              {
                name: 'optional-extraFeatures-KitchenIsland',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen - Island'
              },  
              {
                name: 'optional-extraFeatures-KitchenTableSpace',
                inputType: InputTypes.Checkbox,
                label: 'Kitchen - Table Space'
              },  
              {
                name: 'optional-extraFeatures-Kitchenette',
                inputType: InputTypes.Checkbox,
                label: 'Kitchenette'
              },  
              {
                name: 'optional-extraFeatures-LaundryChute',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Chute'
              },  
              {
                name: 'optional-extraFeatures-PrimaryBath',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bath(s)'
              },  
              {
                name: 'optional-extraFeatures-PrimaryBedroomBayFront',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom - Bay Front'
              },  
              {
                name: 'optional-extraFeatures-PrimaryBedroomOceanFront',
                inputType: InputTypes.Checkbox,
                label: 'Primary Bedroom - Ocean Front'
              },  
              {
                name: 'optional-extraFeatures-Pantry',
                inputType: InputTypes.Checkbox,
                label: 'Pantry'
              },  
              {
                name: 'optional-extraFeatures-RecessedLighting',
                inputType: InputTypes.Checkbox,
                label: 'Recessed Lighting'
              },  
              {
                name: 'optional-extraFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },  
              {
                name: 'optional-extraFeatures-Skylight',
                inputType: InputTypes.Checkbox,
                label: 'Skylight(s)'
              },  
              {
                name: 'optional-extraFeatures-SolarTubes',
                inputType: InputTypes.Checkbox,
                label: 'Solar Tubes'
              },  
              {
                name: 'optional-extraFeatures-SprinklerSystem',
                inputType: InputTypes.Checkbox,
                label: 'Sprinkler System'
              },  
              {
                name: 'optional-extraFeatures-StainLeadGlass',
                inputType: InputTypes.Checkbox,
                label: 'Stain / Lead Glass'
              },  
              {
                name: 'optional-extraFeatures-StallShower',
                inputType: InputTypes.Checkbox,
                label: 'Stall Shower'
              },  
              {
                name: 'optional-extraFeatures-StoreOffice',
                inputType: InputTypes.Checkbox,
                label: 'Store / Office'
              },  
              {
                name: 'optional-extraFeatures-Studio',
                inputType: InputTypes.Checkbox,
                label: 'Studio'
              },  
              {
                name: 'optional-extraFeatures-UpgradedCountertops',
                inputType: InputTypes.Checkbox,
                label: 'Upgraded Countertops'
              },  
              {
                name: 'optional-extraFeatures-Wainscoting',
                inputType: InputTypes.Checkbox,
                label: 'Wainscoting'
              },  
              {
                name: 'optional-extraFeatures-WalkInCloset',
                inputType: InputTypes.Checkbox,
                label: 'Walk-in Closet(s)'
              },  
              {
                name: 'optional-extraFeatures-WaterTreatmentSystem',
                inputType: InputTypes.Checkbox,
                label: 'Water Treatment System'
              },  
              {
                name: 'optional-extraFeatures-WetDryBar',
                inputType: InputTypes.Checkbox,
                label: 'Wet / Dry Bar'
              },  
              {
                name: 'optional-extraFeatures-WhirlpoolHotTub',
                inputType: InputTypes.Checkbox,
                label: 'Whirlpool / Hot Tub'
              },  
              {
                name: 'optional-extraFeatures-WindowTreatments',
                inputType: InputTypes.Checkbox,
                label: 'Window Treatments'
              },  
              {
                name: 'optional-extraFeatures-WineStorage',
                inputType: InputTypes.Checkbox,
                label: 'Wine Storage'
              },  
              {
                name: 'optional-extraFeatures-WoodFloors',
                inputType: InputTypes.Checkbox,
                label: 'Wood Floors'
              },  
              {
                name: 'optional-extraFeatures-WoodStoveInsert',
                inputType: InputTypes.Checkbox,
                label: 'Wood Stove Insert'
              },  
              {
                name: 'optional-extraFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Basement',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-basement-Apartment',
                inputType: InputTypes.Checkbox,
                label: 'Apartment'
              },
              {
                name: 'optional-basement-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'optional-basement-Daylight',
                inputType: InputTypes.Checkbox,
                label: 'Daylight'
              },
              {
                name: 'optional-basement-ExteriorEntry',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Entry'
              },
              {
                name: 'optional-basement-Finished',
                inputType: InputTypes.Checkbox,
                label: 'Finished'
              },
              {
                name: 'optional-basement-Full',
                inputType: InputTypes.Checkbox,
                label: 'Full'
              },
              {
                name: 'optional-basement-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'optional-basement-InteriorEntry',
                inputType: InputTypes.Checkbox,
                label: 'Interior Entry'
              },
              {
                name: 'optional-basement-Partial',
                inputType: InputTypes.Checkbox,
                label: 'Partial'
              },
              {
                name: 'optional-basement-PartiallyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Partially Finished'
              },
              {
                name: 'optional-basement-StorageSpace',
                inputType: InputTypes.Checkbox,
                label: 'Storage Space'
              },
              {
                name: 'optional-basement-SumpPump',
                inputType: InputTypes.Checkbox,
                label: 'Sump Pump'
              },
              {
                name: 'optional-basement-Unfinished',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished'
              },
              {
                name: 'optional-basement-Unheated',
                inputType: InputTypes.Checkbox,
                label: 'Unheated'
              },
              {
                name: 'optional-basement-WalkInCrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Walk-In Crawl Space'
              },
              {
                name: 'optional-basement-WalkOutAccess',
                inputType: InputTypes.Checkbox,
                label: 'Walk Out Access'
              },
              {
                name: 'optional-basement-WalkUpAccess',
                inputType: InputTypes.Checkbox,
                label: 'Walk Up Access'
              },
              {
                name: 'optional-basement-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Security',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-security-24HourSecurity',
                inputType: InputTypes.Checkbox,
                label: '24 Hour Security'
              },
              {
                name: 'optional-security-CarbonMonoxideDetector',
                inputType: InputTypes.Checkbox,
                label: 'Carbon Monoxide Detector(s)'
              },
              {
                name: 'optional-security-DeskInLobby',
                inputType: InputTypes.Checkbox,
                label: 'Desk in Lobby'
              },
              {
                name: 'optional-security-Doorman',
                inputType: InputTypes.Checkbox,
                label: 'Doorman'
              },
              {
                name: 'optional-security-ElectricAlarm',
                inputType: InputTypes.Checkbox,
                label: 'Electric Alarm'
              },
              {
                name: 'optional-security-ExteriorCameras',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Cameras'
              },
              {
                name: 'optional-security-FireDetectionSystem',
                inputType: InputTypes.Checkbox,
                label: 'Fire Detection System'
              },
              {
                name: 'optional-security-Intercom',
                inputType: InputTypes.Checkbox,
                label: 'Intercom'
              },
              {
                name: 'optional-security-MainEntranceLock',
                inputType: InputTypes.Checkbox,
                label: 'Main Entrance Lock'
              },
              {
                name: 'optional-security-Monitored',
                inputType: InputTypes.Checkbox,
                label: 'Monitored'
              },
              {
                name: 'optional-security-MotionDetectors',
                inputType: InputTypes.Checkbox,
                label: 'Motion Detectors'
              },
              {
                name: 'optional-security-ResidentManager',
                inputType: InputTypes.Checkbox,
                label: 'Resident Manager'
              },
              {
                name: 'optional-security-SecurityGate',
                inputType: InputTypes.Checkbox,
                label: 'Security Gate'
              },
              {
                name: 'optional-security-SecuritySystem',
                inputType: InputTypes.Checkbox,
                label: 'Security System'
              },
              {
                name: 'optional-security-SmokeDetector',
                inputType: InputTypes.Checkbox,
                label: 'Smoke Detector'
              },
              {
                name: 'optional-security-SprinklerSystemIndoor',
                inputType: InputTypes.Checkbox,
                label: 'Sprinkler System - Indoor'
              },
              {
                name: 'optional-security-SurveillanceSystem',
                inputType: InputTypes.Checkbox,
                label: 'Surveillance System'
              },
              {
                name: 'optional-security-WindowGrills',
                inputType: InputTypes.Checkbox,
                label: 'Window Grills'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
              {
                name: 'optional-foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'optional-foundation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-foundation-RaisedBeach',
                inputType: InputTypes.Checkbox,
                label: 'Raised Beach'
              },
              {
                name: 'optional-foundation-BrickMasonry',
                inputType: InputTypes.Checkbox,
                label: 'Brick/Masonry'
              },
              {
                name: 'optional-foundation-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-foundation-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'optional-foundation-ElvConstruction',
                inputType: InputTypes.Checkbox,
                label: 'Elv. Construction'
              },
              {
                name: 'optional-foundation-Piling',
                inputType: InputTypes.Checkbox,
                label: 'Piling'
              },
              {
                name: 'optional-foundation-RaisedSlab',
                inputType: InputTypes.Checkbox,
                label: 'Raised Slab'
              },
              {
                name: 'optional-foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-foundation-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'optional-foundation-BrickMortar',
                inputType: InputTypes.Checkbox,
                label: 'Brick/Mortar'
              },
              {
                name: 'optional-foundation-Combination',
                inputType: InputTypes.Checkbox,
                label: 'Combination'
              },
              {
                name: 'optional-foundation-ConcretePerimeter',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Perimeter'
              },
              {
                name: 'optional-foundation-Permanent',
                inputType: InputTypes.Checkbox,
                label: 'Permanent'
              },
              {
                name: 'optional-foundation-PillarPostPier',
                inputType: InputTypes.Checkbox,
                label: 'Pillar/Post/Pier'
              },
              {
                name: 'optional-foundation-Raised',
                inputType: InputTypes.Checkbox,
                label: 'Raised'
              },
              {
                name: 'optional-foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
              {
                name: 'optional-foundation-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-foundation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation and Basement',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },
              {
                name: 'optional-foundation-Permanent',
                inputType: InputTypes.Checkbox,
                label: 'Permanent'
              },
              {
                name: 'optional-foundation-PillarPostPier',
                inputType: InputTypes.Checkbox,
                label: 'Pillar / Post / Pier'
              },
              {
                name: 'optional-foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },
              {
                name: 'optional-foundation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          },
              {
                groupTitle: 'Basement',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent],
                fields: [              
              {
                name: 'optional-foundation-basement-Basement',
                inputType: InputTypes.Checkbox,
                label: 'Basement'
              },
              {
                name: 'optional-foundation-basement-BasementApartment',
                inputType: InputTypes.Checkbox,
                label: 'Basement Apartment'
              },
              {
                name: 'optional-foundation-basement-BasementGarageDoor',
                inputType: InputTypes.Checkbox,
                label: 'Basement Garage Door'
              },            {
                name: 'optional-foundation-basement-BasementShop',
                inputType: InputTypes.Checkbox,
                label: 'Basement Shop'
              },
              {
                name: 'optional-foundation-basement-BathStub',
                inputType: InputTypes.Checkbox,
                label: 'Bath Stubbed'
              },
              {
                name: 'optional-foundation-basement-Daylight',
                inputType: InputTypes.Checkbox,
                label: 'Daylight'
              },
              {
                name: 'optional-foundation-basement-ExteriorEntry',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Entry'
              },
              {
                name: 'optional-foundation-basement-FullyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Fully Finished'
              },
              {
                name: 'optional-foundation-basement-FullBasement',
                inputType: InputTypes.Checkbox,
                label: 'Full Basement'
              },    
              {
                name: 'optional-foundation-basement-InteriorEnt',
                inputType: InputTypes.Checkbox,
                label: 'Interior Entrance'
              },
              {
                name: 'optional-foundation-basement-PartialBasement',
                inputType: InputTypes.Checkbox,
                label: 'Partial Basement'
              },
              {
                name: 'optional-foundation-basement-PartiallyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Partially Finished'
              },
              {
                name: 'optional-foundation-basement-StorageSpace',
                inputType: InputTypes.Checkbox,
                label: 'Storage Space'
              },
              {
                name: 'optional-foundation-basement-SumpPump',
                inputType: InputTypes.Checkbox,
                label: 'Sump Pump'
              },
              {
                name: 'optional-foundation-basement-UnfinisehdBasement',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished Basement '
              },
              {
                name: 'optional-foundation-basement-WalkOut',
                inputType: InputTypes.Checkbox,
                label: 'Walk Out Basement'
              },       
              {
                name: 'optional-foundation-basement-WalkUp',
                inputType: InputTypes.Checkbox,
                label: 'Walk Up Basement'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Foundation',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-foundation-ActiveRadonMitigation',
                inputType: InputTypes.Checkbox,
                label: 'Active Radon Mitigation'
              },  
              {
                name: 'optional-foundation-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },  
              {
                name: 'optional-foundation-BrickMortar',
                inputType: InputTypes.Checkbox,
                label: 'Brick / Mortar'
              },  
              {
                name: 'optional-foundation-ConcretePerimeter',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Perimeter'
              },  
              {
                name: 'optional-foundation-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },  
              {
                name: 'optional-foundation-PassiveRadonMitigation',
                inputType: InputTypes.Checkbox,
                label: 'Passive Radon Mitigation'
              },  
              {
                name: 'optional-foundation-Pilings',
                inputType: InputTypes.Checkbox,
                label: 'Pilings'
              },  
              {
                name: 'optional-foundation-PillarPostPier',
                inputType: InputTypes.Checkbox,
                label: 'Pillar / Post / Pier'
              },  
              {
                name: 'optional-foundation-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },  
              {
                name: 'optional-foundation-StoneFoundation',
                inputType: InputTypes.Checkbox,
                label: 'Stone Foundation'
              },  
              {
                name: 'optional-foundation-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },  
              {
                name: 'optional-foundation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Basement Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-basementType-Combination',
                inputType: InputTypes.Checkbox,
                label: 'Combination'
              },  
              {
                name: 'optional-basementType-ConnectingStairway',
                inputType: InputTypes.Checkbox,
                label: 'Connecting Stairway'
              },  
              {
                name: 'optional-basementType-CrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Crawl Space'
              },  
              {
                name: 'optional-basementType-DaylightFullBasement',
                inputType: InputTypes.Checkbox,
                label: 'Daylight - Full Basement'
              },  
              {
                name: 'optional-basementType-DaylightPartialBasement',
                inputType: InputTypes.Checkbox,
                label: 'Daylight - Partial Basement'
              },  
              {
                name: 'optional-basementType-DrainageSystem',
                inputType: InputTypes.Checkbox,
                label: 'Drainage System'
              },  
              {
                name: 'optional-basementType-English',
                inputType: InputTypes.Checkbox,
                label: 'English'
              },  
              {
                name: 'optional-basementType-FrontEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Front Entrance'
              },  
              {
                name: 'optional-basementType-Fully',
                inputType: InputTypes.Checkbox,
                label: 'Fully'
              },  
              {
                name: 'optional-basementType-FullyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Fully Finished'
              },  
              {
                name: 'optional-basementType-GarageAccess',
                inputType: InputTypes.Checkbox,
                label: 'Garage Access'
              },  
              {
                name: 'optional-basementType-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },  
              {
                name: 'optional-basementType-Improved',
                inputType: InputTypes.Checkbox,
                label: 'Improved'
              },  
              {
                name: 'optional-basementType-InteriorAccess',
                inputType: InputTypes.Checkbox,
                label: 'Interior Access'
              },  
              {
                name: 'optional-basementType-OutsideEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Outside Entrance'
              },  
              {
                name: 'optional-basementType-PartialBasement',
                inputType: InputTypes.Checkbox,
                label: 'Partial Basement'
              },  
              {
                name: 'optional-basementType-PartiallyFinished',
                inputType: InputTypes.Checkbox,
                label: 'Partially Finished'
              },  
              {
                name: 'optional-basementType-PouredConcrete',
                inputType: InputTypes.Checkbox,
                label: 'Poured Concrete'
              },  
              {
                name: 'optional-basementType-RearEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Rear Entrance'
              },  
              {
                name: 'optional-basementType-RoughInBathPlumbing',
                inputType: InputTypes.Checkbox,
                label: 'Rough In Bath Plumbing'
              },  
              {
                name: 'optional-basementType-Shelving',
                inputType: InputTypes.Checkbox,
                label: 'Shelving'
              },  
              {
                name: 'optional-basementType-SideEntrance',
                inputType: InputTypes.Checkbox,
                label: 'Side Entrance'
              },  
              {
                name: 'optional-basementType-Slab',
                inputType: InputTypes.Checkbox,
                label: 'Slab'
              },  
              {
                name: 'optional-basementType-SpaceForRooms',
                inputType: InputTypes.Checkbox,
                label: 'Space for Rooms'
              },  
              {
                name: 'optional-basementType-SumpPump',
                inputType: InputTypes.Checkbox,
                label: 'Sump Pump'
              },  
              {
                name: 'optional-basementType-Unfinished',
                inputType: InputTypes.Checkbox,
                label: 'Unfinished'
              },  
              {
                name: 'optional-basementType-WalkoutLevel',
                inputType: InputTypes.Checkbox,
                label: 'Walkout Level'
              },  
              {
                name: 'optional-basementType-WalkoutStairs',
                inputType: InputTypes.Checkbox,
                label: 'Walkout Stairs'
              },  
              {
                name: 'optional-basementType-Windows',
                inputType: InputTypes.Checkbox,
                label: 'Windows'
              },  
              {
                name: 'optional-basementType-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },  
              {
                name: 'optional-basementType-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Covering',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFinish-WoodFrame',
                inputType: InputTypes.Checkbox,
                label: 'Wood Frame'
              },
              {
                name: 'optional-exteriorFinish-BrickVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Brick Veneer'
              },
              {
                name: 'optional-exteriorFinish-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'optional-exteriorFinish-Siding',
                inputType: InputTypes.Checkbox,
                label: 'Siding'
              },
              {
                name: 'optional-exteriorFinish-MasonryAccent',
                inputType: InputTypes.Checkbox,
                label: 'Masonry Accent'
              },
              {
                name: 'optional-exteriorFinish-Stucco',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'optional-exteriorFinish-VinylSiding',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Siding'
              },
              {
                name: 'optional-exteriorFinish-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-exteriorFinish-GoodCents',
                inputType: InputTypes.Checkbox,
                label: 'Good Cents'
              },
              {
                name: 'optional-exteriorFinish-SingleWideMobileHome',
                inputType: InputTypes.Checkbox,
                label: 'Single Wide Mobile Home'
              },
              {
                name: 'optional-exteriorFinish-DoubleWideMobileHome',
                inputType: InputTypes.Checkbox,
                label: 'Double Wide Mobile Home'
              },
              {
                name: 'optional-exteriorFinish-AllBrickHome',
                inputType: InputTypes.Checkbox,
                label: 'All Brick Home'
              },
              {
                name: 'optional-exteriorFinish-ConcreteFiberSiding',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Fiber Siding'
              },
              {
                name: 'optional-exteriorFinish-Modular',
                inputType: InputTypes.Checkbox,
                label: 'Modular'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Covering',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFinish-AluminumSiding',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum Siding'
              },
              {
                name: 'optional-exteriorFinish-AsbestosShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos Shingle'
              },
              {
                name: 'optional-exteriorFinish-BlockMasonry',
                inputType: InputTypes.Checkbox,
                label: 'Block/Masonry'
              },
              {
                name: 'optional-exteriorFinish-BrickSolid',
                inputType: InputTypes.Checkbox,
                label: 'Brick - Solid'
              },
              {
                name: 'optional-exteriorFinish-BrickVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Brick - Veneer'
              },
              {
                name: 'optional-exteriorFinish-CementPlank',
                inputType: InputTypes.Checkbox,
                label: 'Cement Plank'
              },
              {
                name: 'optional-exteriorFinish-Masonite',
                inputType: InputTypes.Checkbox,
                label: 'Masonite'
              },
              {
                name: 'optional-exteriorFinish-StoneVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Stone Veneer'
              },
              {
                name: 'optional-exteriorFinish-Stucco',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'optional-exteriorFinish-VinylSiding',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl Siding'
              },
              {
                name: 'optional-exteriorFinish-WoodSiding',
                inputType: InputTypes.Checkbox,
                label: 'Wood Siding'
              },
              {
                name: 'optional-exteriorFinish-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },     
      {
        fieldsetTitle: 'Exterior Finish',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFinish-Aluminum',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum'
              },
              {
                name: 'optional-exteriorFinish-Asbestos',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos'
              },
              {
                name: 'optional-exteriorFinish-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'optional-exteriorFinish-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'optional-exteriorFinish-BrickVeneer',
                inputType: InputTypes.Checkbox,
                label: 'Brick Veneer'
              },
              {
                name: 'optional-exteriorFinish-Composition',
                inputType: InputTypes.Checkbox,
                label: 'Composition'
              },
              {
                name: 'optional-exteriorFinish-FiberCement',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Cement'
              },
              {
                name: 'optional-exteriorFinish-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'optional-exteriorFinish-SteelSiding',
                inputType: InputTypes.Checkbox,
                label: 'Steel Siding'
              },
              {
                name: 'optional-exteriorFinish-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-exteriorFinish-Stucco',
                inputType: InputTypes.Checkbox,
                label: 'Stucco'
              },
              {
                name: 'optional-exteriorFinish-StuccoRemoved',
                inputType: InputTypes.Checkbox,
                label: 'Stucco Removed'
              },
              {
                name: 'optional-exteriorFinish-StuccoSynthetic',
                inputType: InputTypes.Checkbox,
                label: 'Stucco Synthetic'
              },
              {
                name: 'optional-exteriorFinish-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-exteriorFinish-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'optional-exteriorFinish-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Construction',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-construction-Block',
                inputType: InputTypes.Checkbox,
                label: 'Block'
              },
              {
                name: 'optional-construction-BrickStone',
                inputType: InputTypes.Checkbox,
                label: 'Brick/Stone'
              },
              {
                name: 'optional-construction-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-construction-Log',
                inputType: InputTypes.Checkbox,
                label: 'Log'
              },
              {
                name: 'optional-construction-SteelFrame',
                inputType: InputTypes.Checkbox,
                label: 'Steel Frame'
              },
              {
                name: 'optional-construction-WoodFrame',
                inputType: InputTypes.Checkbox,
                label: 'Wood Frame'
              },
            ]
          }
        ]
      },

      // Exterior features
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-Dock',
                inputType: InputTypes.Checkbox,
                label: 'Dock'
              },
              {
                name: 'optional-exteriorFeatures-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-exteriorFeatures-GasGrill',
                inputType: InputTypes.Checkbox,
                label: 'Gas Grill'
              },
              {
                name: 'optional-exteriorFeatures-Garden',
                inputType: InputTypes.Checkbox,
                label: 'Garden'
              },
              {
                name: 'optional-exteriorFeatures-SprinklerSystem',
                inputType: InputTypes.Checkbox,
                label: 'Sprinkler System'
              },
              {
                name: 'optional-exteriorFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-exteriorFeatures-WaterFeature',
                inputType: InputTypes.Checkbox,
                label: 'Water Feature'
              },
              {
                name: 'optional-exteriorFeatures-Veranda',
                inputType: InputTypes.Checkbox,
                label: 'Veranda'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'optional-exteriorFeatures-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-exteriorFeatures-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-exteriorFeatures-FrontPorch',
                inputType: InputTypes.Checkbox,
                label: 'Front Porch'
              },
              {
                name: 'optional-exteriorFeatures-RearPorch',
                inputType: InputTypes.Checkbox,
                label: 'Rear Porch'
              },
              {
                name: 'optional-exteriorFeatures-Fencing',
                inputType: InputTypes.Checkbox,
                label: 'Fencing'
              },
              {
                name: 'optional-exteriorFeatures-LawnWell',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Well'
              },
              {
                name: 'optional-exteriorFeatures-LawnSprinkler',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Sprinkler'
              },
              {
                name: 'optional-exteriorFeatures-PoolOutdoorProperty',
                inputType: InputTypes.Checkbox,
                label: 'Pool-Outdoor on Property'
              },
              {
                name: 'optional-exteriorFeatures-PoolIndoorProperty',
                inputType: InputTypes.Checkbox,
                label: 'Pool-Indoor on Property'
              },
              {
                name: 'optional-exteriorFeatures-SpaHotTub',
                inputType: InputTypes.Checkbox,
                label: 'Spa/Hot Tub'
              },
              {
                name: 'optional-exteriorFeatures-BoatParking',
                inputType: InputTypes.Checkbox,
                label: 'Boat Parking'
              },
              {
                name: 'optional-exteriorFeatures-SatelliteDish',
                inputType: InputTypes.Checkbox,
                label: 'Satellite Dish'
              },
              {
                name: 'optional-exteriorFeatures-OutsideStorageAttached',
                inputType: InputTypes.Checkbox,
                label: 'Outside Storage Attached'
              },
              {
                name: 'optional-exteriorFeatures-OutsideStorageDetached',
                inputType: InputTypes.Checkbox,
                label: 'Outside Storage Detached'
              },
              {
                name: 'optional-exteriorFeatures-StormDoors',
                inputType: InputTypes.Checkbox,
                label: 'Storm Doors'
              },
              {
                name: 'optional-exteriorFeatures-StormWindows',
                inputType: InputTypes.Checkbox,
                label: 'Storm Windows'
              },
              {
                name: 'optional-exteriorFeatures-InsulatedDoorsWindows',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Doors/Windows'
              },
              {
                name: 'optional-exteriorFeatures-BuiltInBBQ',
                inputType: InputTypes.Checkbox,
                label: 'Built-in BBQ'
              },
              {
                name: 'optional-exteriorFeatures-BoatDock',
                inputType: InputTypes.Checkbox,
                label: 'Boat Dock'
              },
              {
                name: 'optional-exteriorFeatures-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-exteriorFeatures-HandicapAccess',
                inputType: InputTypes.Checkbox,
                label: 'Handicap Access'
              },
              {
                name: 'optional-exteriorFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-exteriorFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-exteriorFeatures-SideLoadGarage',
                inputType: InputTypes.Checkbox,
                label: 'Side Load Garage'
              },
              {
                name: 'optional-exteriorFeatures-BackLoadGarage',
                inputType: InputTypes.Checkbox,
                label: 'Back Load Garage'
              },
              {
                name: 'optional-exteriorFeatures-AdditionalGarage',
                inputType: InputTypes.Checkbox,
                label: 'Additional Garage'
              },
              {
                name: 'optional-exteriorFeatures-DetachedLivingQuarters',
                inputType: InputTypes.Checkbox,
                label: 'Detached Living Quarters'
              },
              {
                name: 'optional-exteriorFeatures-RVParking',
                inputType: InputTypes.Checkbox,
                label: 'RV Parking'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-exteriorFeatures-Boatlift',
                inputType: InputTypes.Checkbox,
                label: 'Boatlift'
              },
              {
                name: 'optional-exteriorFeatures-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-exteriorFeatures-DockExisting',
                inputType: InputTypes.Checkbox,
                label: 'Dock - Existing'
              },
              {
                name: 'optional-exteriorFeatures-DockFloating',
                inputType: InputTypes.Checkbox,
                label: 'Dock - Floating'
              },
              {
                name: 'optional-exteriorFeatures-DockPermit',
                inputType: InputTypes.Checkbox,
                label: 'Dock - Permit'
              },
              {
                name: 'optional-exteriorFeatures-DockShared',
                inputType: InputTypes.Checkbox,
                label: 'Dock - Shared'
              },
              {
                name: 'optional-exteriorFeatures-FenceBrick',
                inputType: InputTypes.Checkbox,
                label: 'Fence - Brick'
              },
              {
                name: 'optional-exteriorFeatures-FenceIron',
                inputType: InputTypes.Checkbox,
                label: 'Fence - Iron'
              },
              {
                name: 'optional-exteriorFeatures-FenceMetalEnclosed',
                inputType: InputTypes.Checkbox,
                label: 'Fence - Metal Enclosed'
              },
              {
                name: 'optional-exteriorFeatures-FencePartial',
                inputType: InputTypes.Checkbox,
                label: 'Fence - Partial'
              },
              {
                name: 'optional-exteriorFeatures-FencePrivate',
                inputType: InputTypes.Checkbox,
                label: 'Fence - Private'
              },
              {
                name: 'optional-exteriorFeatures-FenceVinyl',
                inputType: InputTypes.Checkbox,
                label: 'Fence - Vinyl'
              },
              {
                name: 'optional-exteriorFeatures-FenceWoodenEnclosed',
                inputType: InputTypes.Checkbox,
                label: 'Fence - Wooden Enclosed'
              },
              {
                name: 'optional-exteriorFeatures-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-exteriorFeatures-Gutters',
                inputType: InputTypes.Checkbox,
                label: 'Gutters'
              },
              {
                name: 'optional-exteriorFeatures-HandicappedEquipped',
                inputType: InputTypes.Checkbox,
                label: 'Handicapped Equipped'
              },
              {
                name: 'optional-exteriorFeatures-LawnIrrigation',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Irrigation'
              },
              {
                name: 'optional-exteriorFeatures-LawnWell',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Well'
              },
              {
                name: 'optional-exteriorFeatures-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'optional-exteriorFeatures-PatioCovered',
                inputType: InputTypes.Checkbox,
                label: 'Patio - Covered'
              },
              {
                name: 'optional-exteriorFeatures-PoolAboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Pool - Above Ground'
              },
              {
                name: 'optional-exteriorFeatures-PoolElevated',
                inputType: InputTypes.Checkbox,
                label: 'Pool - Elevated'
              },
              {
                name: 'optional-exteriorFeatures-PoolInGround',
                inputType: InputTypes.Checkbox,
                label: 'Pool - In Ground'
              },
              {
                name: 'optional-exteriorFeatures-PorchFrontPorch',
                inputType: InputTypes.Checkbox,
                label: 'Porch - Front Porch'
              },
              {
                name: 'optional-exteriorFeatures-PorchFullFront',
                inputType: InputTypes.Checkbox,
                label: 'Porch - Full Front'
              },
              {
                name: 'optional-exteriorFeatures-PorchScreened',
                inputType: InputTypes.Checkbox,
                label: 'Porch - Screened'
              },
              {
                name: 'optional-exteriorFeatures-PorchWrapAround',
                inputType: InputTypes.Checkbox,
                label: 'Porch - Wrap-Around'
              },
              {
                name: 'optional-exteriorFeatures-SeaWall',
                inputType: InputTypes.Checkbox,
                label: 'Sea Wall'
              },
              {
                name: 'optional-exteriorFeatures-SomeStormWndDoors',
                inputType: InputTypes.Checkbox,
                label: 'Some Storm Wnd/Doors'
              },
              {
                name: 'optional-exteriorFeatures-SomeThermalWndDoors',
                inputType: InputTypes.Checkbox,
                label: 'Some Thermal Wnd/Doors'
              },
              {
                name: 'optional-exteriorFeatures-SpecialYardLights',
                inputType: InputTypes.Checkbox,
                label: 'Special Yard Lights'
              },
              {
                name: 'optional-exteriorFeatures-Stoop',
                inputType: InputTypes.Checkbox,
                label: 'Stoop'
              },
              {
                name: 'optional-exteriorFeatures-StorageOutbuilding',
                inputType: InputTypes.Checkbox,
                label: 'Storage/Outbuilding'
              },
              {
                name: 'optional-exteriorFeatures-StormDoors',
                inputType: InputTypes.Checkbox,
                label: 'Storm Doors'
              },
              {
                name: 'optional-exteriorFeatures-StormWindows',
                inputType: InputTypes.Checkbox,
                label: 'Storm Windows'
              },
              {
                name: 'optional-exteriorFeatures-ThermalWindowsDoors',
                inputType: InputTypes.Checkbox,
                label: 'Thermal Windows/Doors'
              },
              {
                name: 'optional-exteriorFeatures-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-AccentLighting',
                inputType: InputTypes.Checkbox,
                label: 'Accent Lighting'
              },
              {
                name: 'optional-exteriorFeatures-BoatHouse',
                inputType: InputTypes.Checkbox,
                label: 'Boat House'
              },
              {
                name: 'optional-exteriorFeatures-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-exteriorFeatures-CircleDrive',
                inputType: InputTypes.Checkbox,
                label: 'Circle Drive'
              },
              {
                name: 'optional-exteriorFeatures-CoveredPorch',
                inputType: InputTypes.Checkbox,
                label: 'Covered Porch'
              },
              {
                name: 'optional-exteriorFeatures-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-exteriorFeatures-DogRun',
                inputType: InputTypes.Checkbox,
                label: 'Dog Run'
              },
              {
                name: 'optional-exteriorFeatures-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-exteriorFeatures-GardenSpace',
                inputType: InputTypes.Checkbox,
                label: 'Garden Space'
              },
              {
                name: 'optional-exteriorFeatures-GasGrill',
                inputType: InputTypes.Checkbox,
                label: 'Gas Grill'
              },
              {
                name: 'optional-exteriorFeatures-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-exteriorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-exteriorFeatures-InvisibleFencing',
                inputType: InputTypes.Checkbox,
                label: 'Invisible Fencing'
              },
              {
                name: 'optional-exteriorFeatures-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'optional-exteriorFeatures-PavedDrive',
                inputType: InputTypes.Checkbox,
                label: 'Paved Drive'
              },
              {
                name: 'optional-exteriorFeatures-Pier',
                inputType: InputTypes.Checkbox,
                label: 'Pier'
              },
              {
                name: 'optional-exteriorFeatures-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-exteriorFeatures-PrivacyFence',
                inputType: InputTypes.Checkbox,
                label: 'Privacy Fence'
              },
              {
                name: 'optional-exteriorFeatures-SatelliteDish',
                inputType: InputTypes.Checkbox,
                label: 'Satellite Dish'
              },
              {
                name: 'optional-exteriorFeatures-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },
              {
                name: 'optional-exteriorFeatures-SecurityLighting',
                inputType: InputTypes.Checkbox,
                label: 'Security Lighting'
              },
              {
                name: 'optional-exteriorFeatures-SprinklerSystem',
                inputType: InputTypes.Checkbox,
                label: 'Sprinkler System'
              },
              {
                name: 'optional-exteriorFeatures-StorageBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Storage Building(s)'
              },
              {
                name: 'optional-exteriorFeatures-TennisCourtPrivate',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court - Private'
              },
              {
                name: 'optional-exteriorFeatures-VerandaBreezeway',
                inputType: InputTypes.Checkbox,
                label: 'Veranda/Breezeway'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-Awning',
                inputType: InputTypes.Checkbox,
                label: 'Awning'
              },
              {
                name: 'optional-exteriorFeatures-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-exteriorFeatures-BoatSlip',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip'
              },
              {
                name: 'optional-exteriorFeatures-BuiltinBarbecue',
                inputType: InputTypes.Checkbox,
                label: 'Built-in Barbecue'
              },
              {
                name: 'optional-exteriorFeatures-Courtyard',
                inputType: InputTypes.Checkbox,
                label: 'Courtyard'
              },
              {
                name: 'optional-exteriorFeatures-CoveredCourtyard',
                inputType: InputTypes.Checkbox,
                label: 'Covered Courtyard'
              },
              {
                name: 'optional-exteriorFeatures-Dock',
                inputType: InputTypes.Checkbox,
                label: 'Dock'
              },
              {
                name: 'optional-exteriorFeatures-DogRun',
                inputType: InputTypes.Checkbox,
                label: 'Dog Run'
              },
              {
                name: 'optional-exteriorFeatures-FencedYard',
                inputType: InputTypes.Checkbox,
                label: 'Fenced Yard'
              },
              {
                name: 'optional-exteriorFeatures-FirePit',
                inputType: InputTypes.Checkbox,
                label: 'Fire Pit'
              },
              {
                name: 'optional-exteriorFeatures-Garden',
                inputType: InputTypes.Checkbox,
                label: 'Garden'
              },
              {
                name: 'optional-exteriorFeatures-Lighting',
                inputType: InputTypes.Checkbox,
                label: 'Lighting'
              },
              {
                name: 'optional-exteriorFeatures-OutdoorGrill',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Grill'
              },
              {
                name: 'optional-exteriorFeatures-OutdoorKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Kitchen'
              },
              {
                name: 'optional-exteriorFeatures-OutdoorShower',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Shower'
              },
              {
                name: 'optional-exteriorFeatures-Playground',
                inputType: InputTypes.Checkbox,
                label: 'Playground'
              },
              {
                name: 'optional-exteriorFeatures-PrivateYard',
                inputType: InputTypes.Checkbox,
                label: 'Private Yard'
              },
              {
                name: 'optional-exteriorFeatures-RainBarrel',
                inputType: InputTypes.Checkbox,
                label: 'Rain Barrel/Cistern(s)'
              },
              {
                name: 'optional-exteriorFeatures-RainGutters',
                inputType: InputTypes.Checkbox,
                label: 'Rain Gutters'
              },
              {
                name: 'optional-exteriorFeatures-RVHookup',
                inputType: InputTypes.Checkbox,
                label: 'RV Hookup'
              },
              {
                name: 'optional-exteriorFeatures-SmartCameras',
                inputType: InputTypes.Checkbox,
                label: 'Smart Camera(s)/Recording'
              },
              {
                name: 'optional-exteriorFeatures-SmartIrrigation',
                inputType: InputTypes.Checkbox,
                label: 'Smart Irrigation'
              },
              {
                name: 'optional-exteriorFeatures-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
              {
                name: 'optional-exteriorFeatures-UncoveredCourtyard',
                inputType: InputTypes.Checkbox,
                label: 'Uncovered Courtyard'
              },             
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-DP50Windows',
                inputType: InputTypes.Checkbox,
                label: 'DP50 Windows'
              },
              {
                name: 'optional-exteriorFeatures-ExteriorKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Kitchen'
              },
              {
                name: 'optional-exteriorFeatures-GasGrill',
                inputType: InputTypes.Checkbox,
                label: 'Gas Grill'
              },
              {
                name: 'optional-exteriorFeatures-GasLogs',
                inputType: InputTypes.Checkbox,
                label: 'Gas Logs'
              },
              {
                name: 'optional-exteriorFeatures-IrrigationSystem',
                inputType: InputTypes.Checkbox,
                label: 'Irrigation System'
              },
              {
                name: 'optional-exteriorFeatures-OutdoorShower',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Shower'
              },
              {
                name: 'optional-exteriorFeatures-SecurityLighting',
                inputType: InputTypes.Checkbox,
                label: 'Security Lighting'
              },
              {
                name: 'optional-exteriorFeatures-ShuttersBoardHurricane',
                inputType: InputTypes.Checkbox,
                label: 'Shutters - Board/Hurricane'
              },
              {
                name: 'optional-exteriorFeatures-ShuttersFunctional',
                inputType: InputTypes.Checkbox,
                label: 'Shutters - Functional'
              },
              {
                name: 'optional-exteriorFeatures-StormDoors',
                inputType: InputTypes.Checkbox,
                label: 'Storm Doors'
              },
              {
                name: 'optional-exteriorFeatures-StormWindows',
                inputType: InputTypes.Checkbox,
                label: 'Storm Windows'
              },
              {
                name: 'optional-exteriorFeatures-ThermalDoors',
                inputType: InputTypes.Checkbox,
                label: 'Thermal Doors'
              },
              {
                name: 'optional-exteriorFeatures-ThermalWindows',
                inputType: InputTypes.Checkbox,
                label: 'Thermal Windows'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [     
              {
                name: 'optional-exteriorFeatures-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-exteriorFeatures-FencedPasture',
                inputType: InputTypes.Checkbox,
                label: 'Fenced Pasture'
              },
              {
                name: 'optional-exteriorFeatures-FirePit',
                inputType: InputTypes.Checkbox,
                label: 'Fire pit'
              },
              {
                name: 'optional-exteriorFeatures-FloatingDock',
                inputType: InputTypes.Checkbox,
                label: 'Floating Dock'
              },              
              {
                name: 'optional-exteriorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-exteriorFeatures-InGroundGasGrill',
                inputType: InputTypes.Checkbox,
                label: 'In-Ground Gas Grill'
              },
              {
                name: 'optional-exteriorFeatures-InGroundIrrigation',
                inputType: InputTypes.Checkbox,
                label: 'In-Ground Irrigation'
              },
              {
                name: 'optional-exteriorFeatures-LawnMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Maintenance'
              },
              {
                name: 'optional-exteriorFeatures-OutdoorKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Kitchen'
              },
              {
                name: 'optional-exteriorFeatures-OutdoorShower',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Shower'
              }, 
              {
                name: 'optional-exteriorFeatures-PoolAboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Pool-Above Ground'
              },
              {
                name: 'optional-exteriorFeatures-PoolInGround',
                inputType: InputTypes.Checkbox,
                label: 'Pool-In-Ground'
              },
              {
                name: 'optional-exteriorFeatures-RainwaterCatchment',
                inputType: InputTypes.Checkbox,
                label: 'Rainwater Catchment'
              },
              {
                name: 'optional-exteriorFeatures-RooftopTerrace',
                inputType: InputTypes.Checkbox,
                label: 'Rooftop Terrace'
              },  
              {
                name: 'optional-exteriorFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-exteriorFeatures-StorageUnit',
                inputType: InputTypes.Checkbox,
                label: 'Storage Unit'
              },
              {
                name: 'optional-exteriorFeatures-TennisCourtPrivate',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court(s) - Private'
              },
               {
                name: 'optional-exteriorFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-Awnings',
                inputType: InputTypes.Checkbox,
                label: 'Awnings'
              },
              {
                name: 'optional-exteriorFeatures-BarnStable',
                inputType: InputTypes.Checkbox,
                label: 'Barn/Stable'
              },
              {
                name: 'optional-exteriorFeatures-BoatLift',
                inputType: InputTypes.Checkbox,
                label: 'Boat Lift'
              },
              {
                name: 'optional-exteriorFeatures-BulkheadRIP',
                inputType: InputTypes.Checkbox,
                label: 'Bulkhead/RIP'
              },
              {
                name: 'optional-exteriorFeatures-ControlledAccess',
                inputType: InputTypes.Checkbox,
                label: 'Controlled Access'
              },
              {
                name: 'optional-exteriorFeatures-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-exteriorFeatures-DockPier',
                inputType: InputTypes.Checkbox,
                label: 'Dock/Pier'
              },
              {
                name: 'optional-exteriorFeatures-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-exteriorFeatures-GreenHouse',
                inputType: InputTypes.Checkbox,
                label: 'Green House'
              },
              {
                name: 'optional-exteriorFeatures-GuestHouse',
                inputType: InputTypes.Checkbox,
                label: 'Guest House'
              },
              {
                name: 'optional-exteriorFeatures-HorsePermitted',
                inputType: InputTypes.Checkbox,
                label: 'Horse Permitted'
              },
              {
                name: 'optional-exteriorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-exteriorFeatures-InsulatedDoors',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Doors'
              },
              {
                name: 'optional-exteriorFeatures-IrrigationSystem',
                inputType: InputTypes.Checkbox,
                label: 'Irrigation System'
              },
              {
                name: 'optional-exteriorFeatures-LeadGlassWindows',
                inputType: InputTypes.Checkbox,
                label: 'Lead Glass Windows'
              },
              {
                name: 'optional-exteriorFeatures-OutBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Out Building'
              },
              {
                name: 'optional-exteriorFeatures-OutdoorLighting',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Lighting'
              },
              {
                name: 'optional-exteriorFeatures-PalladianWindows',
                inputType: InputTypes.Checkbox,
                label: 'Palladian Windows'
              },
              {
                name: 'optional-exteriorFeatures-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-exteriorFeatures-PrivateStorage',
                inputType: InputTypes.Checkbox,
                label: 'Private Storage'
              },
              {
                name: 'optional-exteriorFeatures-PublicPark',
                inputType: InputTypes.Checkbox,
                label: 'Public Park'
              },
              {
                name: 'optional-exteriorFeatures-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-exteriorFeatures-Screens',
                inputType: InputTypes.Checkbox,
                label: 'Screens'
              },
              {
                name: 'optional-exteriorFeatures-SlidingDoors',
                inputType: InputTypes.Checkbox,
                label: 'Sliding Doors'
              },
              {
                name: 'optional-exteriorFeatures-StainedGlass',
                inputType: InputTypes.Checkbox,
                label: 'Stained Glass'
              },
              {
                name: 'optional-exteriorFeatures-StorageShedAttached',
                inputType: InputTypes.Checkbox,
                label: 'Storage Shed Attached'
              },
              {
                name: 'optional-exteriorFeatures-StorageShedDetached',
                inputType: InputTypes.Checkbox,
                label: 'Storage Shed Detached'
              },
              {
                name: 'optional-exteriorFeatures-StormDoors',
                inputType: InputTypes.Checkbox,
                label: 'Storm Doors'
              },
              {
                name: 'optional-exteriorFeatures-StormWindows',
                inputType: InputTypes.Checkbox,
                label: 'Storm Windows'
              },
              {
                name: 'optional-exteriorFeatures-SwingSets',
                inputType: InputTypes.Checkbox,
                label: 'Swing Sets'
              },
              {
                name: 'optional-exteriorFeatures-TennisCourtOnProperty',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court - On Property'
              },
              {
                name: 'optional-exteriorFeatures-ThermalWindows',
                inputType: InputTypes.Checkbox,
                label: 'Thermal Windows'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorFeatures-BBQGrill',
                inputType: InputTypes.Checkbox,
                label: 'BBQ Grill'
              },
              {
                name: 'optional-exteriorFeatures-BoatStorage',
                inputType: InputTypes.Checkbox,
                label: 'Boat Storage'
              },
              {
                name: 'optional-exteriorFeatures-BumpOuts',
                inputType: InputTypes.Checkbox,
                label: 'Bump Outs'
              },
              {
                name: 'optional-exteriorFeatures-ExtensiveHardscape',
                inputType: InputTypes.Checkbox,
                label: 'Extensive Hardscape'
              },
              {
                name: 'optional-exteriorFeatures-ExteriorLighting',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Lighting'
              },
              {
                name: 'optional-exteriorFeatures-FireEscape',
                inputType: InputTypes.Checkbox,
                label: 'Fire Escape'
              },
              {
                name: 'optional-exteriorFeatures-FloodLights',
                inputType: InputTypes.Checkbox,
                label: 'Flood Lights'
              },
              {
                name: 'optional-exteriorFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-exteriorFeatures-Kennels',
                inputType: InputTypes.Checkbox,
                label: 'Kennels'
              },
              {
                name: 'optional-exteriorFeatures-LawnSprinkler',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Sprinkler'
              },
              {
                name: 'optional-exteriorFeatures-Outbuilding',
                inputType: InputTypes.Checkbox,
                label: 'Outbuilding(s)'
              },
              {
                name: 'optional-exteriorFeatures-OutsideShower',
                inputType: InputTypes.Checkbox,
                label: 'Outside Shower'
              },
              {
                name: 'optional-exteriorFeatures-PlayArea',
                inputType: InputTypes.Checkbox,
                label: 'Play Area'
              },
              {
                name: 'optional-exteriorFeatures-PlayEquipment',
                inputType: InputTypes.Checkbox,
                label: 'Play Equipment'
              },
              {
                name: 'optional-exteriorFeatures-PrivateBeach',
                inputType: InputTypes.Checkbox,
                label: 'Private Beach'
              },
              {
                name: 'optional-exteriorFeatures-SatelliteDish',
                inputType: InputTypes.Checkbox,
                label: 'Satellite Dish'
              },
              {
                name: 'optional-exteriorFeatures-SecureStorage',
                inputType: InputTypes.Checkbox,
                label: 'Secure Storage'
              },
              {
                name: 'optional-exteriorFeatures-Sidewalks',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalks'
              },
              {
                name: 'optional-exteriorFeatures-SportCourt',
                inputType: InputTypes.Checkbox,
                label: 'Sport Court'
              },
              {
                name: 'optional-exteriorFeatures-StoneRetainingWalls',
                inputType: InputTypes.Checkbox,
                label: 'Stone Retaining Walls'
              },
              {
                name: 'optional-exteriorFeatures-StreetLights',
                inputType: InputTypes.Checkbox,
                label: 'Street Lights'
              },
              {
                name: 'optional-exteriorFeatures-TennisCourtOnProperty',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court(s) - On Property'
              },
              {
                name: 'optional-exteriorFeatures-UndergroundLawnSprinkler',
                inputType: InputTypes.Checkbox,
                label: 'Underground Lawn Sprinkler'
              },
              {
                name: 'optional-exteriorFeatures-WaterFalls',
                inputType: InputTypes.Checkbox,
                label: 'Water Falls'
              },
              {
                name: 'optional-exteriorFeatures-WaterFountain',
                inputType: InputTypes.Checkbox,
                label: 'Water Fountain(s)'
              },
              {
                name: 'optional-exteriorFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Exterior Structure',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-exteriorStructure-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn(s)'
              },
              {
                name: 'optional-exteriorStructure-Bathhouse',
                inputType: InputTypes.Checkbox,
                label: 'Bathhouse'
              },
              {
                name: 'optional-exteriorStructure-Fountain',
                inputType: InputTypes.Checkbox,
                label: 'Fountain'
              },
              {
                name: 'optional-exteriorStructure-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-exteriorStructure-GreenHouse',
                inputType: InputTypes.Checkbox,
                label: 'Green House'
              },
              {
                name: 'optional-exteriorStructure-Shower',
                inputType: InputTypes.Checkbox,
                label: 'Shower'
              },
              {
                name: 'optional-exteriorStructure-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
              {
                name: 'optional-exteriorStructure-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },

      {
        fieldsetTitle: 'Patio and Porch',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-patioAndPorch-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-patioAndPorch-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'optional-patioAndPorch-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-patioAndPorch-Screened',
                inputType: InputTypes.Checkbox,
                label: 'Screened'
              },
            ]
          }
        ]
      },

      {
        fieldsetTitle: 'Miscellaneous',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-miscellaneous-Easements',
                inputType: InputTypes.Checkbox,
                label: 'Easements'
              },
              {
                name: 'optional-miscellaneous-LenderOwned',
                inputType: InputTypes.Checkbox,
                label: 'Lender Owned'
              },
              {
                name: 'optional-miscellaneous-PossibleShortSale',
                inputType: InputTypes.Checkbox,
                label: 'Possible Short Sale'
              },
              {
                name: 'optional-miscellaneous-PrivateDockAtLot',
                inputType: InputTypes.Checkbox,
                label: 'Private Dock At Lot'
              },
              {
                name: 'optional-miscellaneous-SharedDock',
                inputType: InputTypes.Checkbox,
                label: 'Shared Dock'
              },
              {
                name: 'optional-miscellaneous-SubjectToLease',
                inputType: InputTypes.Checkbox,
                label: 'Subject to Lease'
              },
              {
                name: 'optional-miscellaneous-SurveyRequired',
                inputType: InputTypes.Checkbox,
                label: 'Survey Required'
              },
              {
                name: 'optional-miscellaneous-WillBuild',
                inputType: InputTypes.Checkbox,
                label: 'Will Build'
              },
              {
                name: 'optional-miscellaneous-WillSubdivide',
                inputType: InputTypes.Checkbox,
                label: 'Will Subdivide'
              },
              {
                name: 'optional-miscellaneous-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Miscellaneous',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-miscellaneous-AddlRoadFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Addl Road Frontage'
              },
              {
                name: 'optional-miscellaneous-CropSupplement',
                inputType: InputTypes.Checkbox,
                label: 'Crop Supplement'
              },
              {
                name: 'optional-miscellaneous-Curbs',
                inputType: InputTypes.Checkbox,
                label: 'Curbs'
              },
              {
                name: 'optional-miscellaneous-Drainage',
                inputType: InputTypes.Checkbox,
                label: 'Drainage'
              },
              {
                name: 'optional-miscellaneous-Gutters',
                inputType: InputTypes.Checkbox,
                label: 'Gutters'
              },
              {
                name: 'optional-miscellaneous-NoAccessLandLocked',
                inputType: InputTypes.Checkbox,
                label: 'No Access - Land Locked'
              },
              {
                name: 'optional-miscellaneous-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-miscellaneous-Sidewalk',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalk'
              },
              {
                name: 'optional-miscellaneous-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'optional-miscellaneous-Tobacco',
                inputType: InputTypes.Checkbox,
                label: 'Tobacco'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Miscellaneous',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-miscellaneous-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-miscellaneous-Barns',
                inputType: InputTypes.Checkbox,
                label: 'Barns'
              },
              {
                name: 'optional-miscellaneous-Cable',
                inputType: InputTypes.Checkbox,
                label: 'Cable'
              },
              {
                name: 'optional-miscellaneous-GreatRoom',
                inputType: InputTypes.Checkbox,
                label: 'Great Room'
              },
              {
                name: 'optional-miscellaneous-GuestQuarters',
                inputType: InputTypes.Checkbox,
                label: 'Guest Quarters'
              },
              {
                name: 'optional-miscellaneous-HistoricDistrict',
                inputType: InputTypes.Checkbox,
                label: 'Historic District'
              },
              {
                name: 'optional-miscellaneous-HorsesAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Horses Allowed'
              },
              {
                name: 'optional-miscellaneous-PlantationShutters',
                inputType: InputTypes.Checkbox,
                label: 'Plantation Shutters'
              },
              {
                name: 'optional-miscellaneous-PublicTransportNearby',
                inputType: InputTypes.Checkbox,
                label: 'Public Transport Nearby'
              }
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-water-CommunitySubdiv',
                inputType: InputTypes.Checkbox,
                label: 'Community / Subdiv'
              },
              {
                name: 'optional-water-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-water-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-water-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'optional-water-PublicAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Public Available'
              },
              {
                name: 'optional-water-Spring',
                inputType: InputTypes.Checkbox,
                label: 'Spring'
              },
              {
                name: 'optional-water-Well',
                inputType: InputTypes.Checkbox,
                label: 'Well'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Sewer / Septic',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-sewerSeptic-Community',
                inputType: InputTypes.Checkbox,
                label: 'Community'
              },
              {
                name: 'optional-sewerSeptic-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-sewerSeptic-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'optional-sewerSeptic-PublicAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Public Available'
              },
              {
                name: 'optional-sewerSeptic-Septic',
                inputType: InputTypes.Checkbox,
                label: 'Septic'
              },
              {
                name: 'optional-sewerSeptic-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Gas',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-gas-NaturalGas',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas'
              },
              {
                name: 'optional-gas-NoneAvailable',
                inputType: InputTypes.Checkbox,
                label: 'None Available'
              },
              {
                name: 'optional-gas-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Pool Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-poolFeatures-AboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Above Ground'
              },
              {
                name: 'optional-poolFeatures-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'optional-poolFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-poolFeatures-InGround',
                inputType: InputTypes.Checkbox,
                label: 'In Ground'
              },
              {
                name: 'optional-poolFeatures-SaltWater',
                inputType: InputTypes.Checkbox,
                label: 'Salt Water'
              },
              {
                name: 'optional-poolFeatures-ScreenEnclosure',
                inputType: InputTypes.Checkbox,
                label: 'Screen Enclosure'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Pool Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-poolFeatures-AboveGroundPool',
                inputType: InputTypes.Checkbox,
                label: 'Above Ground Pool'
              },
              {
                name: 'optional-poolFeatures-IndoorPool',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Pool'
              },
              {
                name: 'optional-poolFeatures-IngroundPool',
                inputType: InputTypes.Checkbox,
                label: 'Inground Pool'
              },
              {
                name: 'optional-poolFeatures-NeighborhoodPool',
                inputType: InputTypes.Checkbox,
                label: 'Neighborhood Pool'
              },
              {
                name: 'optional-poolFeatures-PoolHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pool House'
              },
              {
                name: 'optional-poolFeatures-PoolOnProperty',
                inputType: InputTypes.Checkbox,
                label: 'Pool on Property'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Porch / Balcony / Deck / Pool',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-porchBalconyDeckPool-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-porchBalconyDeckPool-Covered',
                inputType: InputTypes.Checkbox,
                label: 'Covered'
              },
              {
                name: 'optional-porchBalconyDeckPool-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-porchBalconyDeckPool-Enclosed',
                inputType: InputTypes.Checkbox,
                label: 'Enclosed'
              },
              {
                name: 'optional-porchBalconyDeckPool-Open',
                inputType: InputTypes.Checkbox,
                label: 'Open'
              },
              {
                name: 'optional-porchBalconyDeckPool-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'optional-porchBalconyDeckPool-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-porchBalconyDeckPool-Screened',
                inputType: InputTypes.Checkbox,
                label: 'Screened'
              },
              {
                name: 'optional-porchBalconyDeckPool-AboveGroundPoolProperty',
                inputType: InputTypes.Checkbox,
                label: 'Above Ground Pool on Property'
              },
              {
                name: 'optional-porchBalconyDeckPool-HotTubProperty',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub on Property'
              },
              {
                name: 'optional-porchBalconyDeckPool-InGroundPoolProperty',
                inputType: InputTypes.Checkbox,
                label: 'In Ground Pool on Property'
              },              
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Porch And Patio',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-porchAndPatio-Awnings',
                inputType: InputTypes.Checkbox,
                label: 'Awnings'
              },
              {
                name: 'optional-porchAndPatio-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-porchAndPatio-CoveredPorch',
                inputType: InputTypes.Checkbox,
                label: 'Covered Porch'
              },
              {
                name: 'optional-porchAndPatio-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-porchAndPatio-Enclosed',
                inputType: InputTypes.Checkbox,
                label: 'Enclosed'
              },
              {
                name: 'optional-porchAndPatio-FrontPorch',
                inputType: InputTypes.Checkbox,
                label: 'Front Porch'
              },
              {
                name: 'optional-porchAndPatio-GlassEnclosed',
                inputType: InputTypes.Checkbox,
                label: 'Glass Enclosed'
              },
              {
                name: 'optional-porchAndPatio-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'optional-porchAndPatio-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch'
              },
              {
                name: 'optional-porchAndPatio-RearPorch',
                inputType: InputTypes.Checkbox,
                label: 'Rear Porch'
              },
              {
                name: 'optional-porchAndPatio-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },
              {
                name: 'optional-porchAndPatio-SidePorch',
                inputType: InputTypes.Checkbox,
                label: 'Side Porch'
              },
              {
                name: 'optional-porchAndPatio-Terrace',
                inputType: InputTypes.Checkbox,
                label: 'Terrace'
              },
              {
                name: 'optional-porchAndPatio-WrapAround',
                inputType: InputTypes.Checkbox,
                label: 'Wrap Around Porch'
              },
              {
                name: 'optional-porchAndPatio-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
          ]
        },
        {
          groupTitle: 'Security',
          scope: Scopes.ByPropertyType,
          types: [PropertyTypes.HomeSale,  PropertyTypes.HomeRent],
          fields: [    
            {
              name: 'optional-security-CarbonMonoxideDetector',
              inputType: InputTypes.Checkbox,
              label: 'Carbon Monoxide Detector'
            },
            {
              name: 'optional-security-IndoorFireSprinklers',
              inputType: InputTypes.Checkbox,
              label: 'Indoor Fire Sprinklers'
            },              {
              name: 'optional-security-Intercom',
              inputType: InputTypes.Checkbox,
              label: 'Intercom'
            },
            {
              name: 'optional-security-RadonMitigation',
              inputType: InputTypes.Checkbox,
              label: 'Radon Mitigation System'
            },
            {
              name: 'optional-security-SecuritySystem',
              inputType: InputTypes.Checkbox,
              label: 'Security System'
            },
            {
              name: 'optional--security-SmokeAlarm',
              inputType: InputTypes.Checkbox,
              label: 'Smoke Alarm'
            },  
            {
              name: 'optional--security-Generator',
              inputType: InputTypes.Checkbox,
              label: 'Generator'
            },                
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Porch And Deck',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-porchAndDeck-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },
              {
                name: 'optional-porchAndDeck-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck'
              },
              {
                name: 'optional-porchAndDeck-Front',
                inputType: InputTypes.Checkbox,
                label: 'Front'
              },
              {
                name: 'optional-porchAndDeck-FrontCountry',
                inputType: InputTypes.Checkbox,
                label: 'Front Country'
              },
              {
                name: 'optional-porchAndDeck-FrontFull',
                inputType: InputTypes.Checkbox,
                label: 'Front Full'
              },
              {
                name: 'optional-porchAndDeck-Glass',
                inputType: InputTypes.Checkbox,
                label: 'Glass'
              },
              {
                name: 'optional-porchAndDeck-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio'
              },
              {
                name: 'optional-porchAndDeck-Rear',
                inputType: InputTypes.Checkbox,
                label: 'Rear'
              },
              {
                name: 'optional-porchAndDeck-Screened',
                inputType: InputTypes.Checkbox,
                label: 'Screened'
              },
              {
                name: 'optional-porchAndDeck-Side',
                inputType: InputTypes.Checkbox,
                label: 'Side'
              },
              {
                name: 'optional-porchAndDeck-SleepingPorch',
                inputType: InputTypes.Checkbox,
                label: 'Sleeping Porch'
              },
              {
                name: 'optional-porchAndDeck-Stoop',
                inputType: InputTypes.Checkbox,
                label: 'Stoop'
              },
              {
                name: 'optional-porchAndDeck-WrapAround',
                inputType: InputTypes.Checkbox,
                label: 'Wrap Around'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Present Use',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-presentUse-Agricultural',
                inputType: InputTypes.Checkbox,
                label: 'Agricultural'
              },
              {
                name: 'optional-presentUse-Cattle',
                inputType: InputTypes.Checkbox,
                label: 'Cattle'
              },
              {
                name: 'optional-presentUse-Commercial',
                inputType: InputTypes.Checkbox,
                label: 'Commercial'
              },
              {
                name: 'optional-presentUse-Dairy',
                inputType: InputTypes.Checkbox,
                label: 'Dairy'
              },
              {
                name: 'optional-presentUse-Farm',
                inputType: InputTypes.Checkbox,
                label: 'Farm'
              },
              {
                name: 'optional-presentUse-Horses',
                inputType: InputTypes.Checkbox,
                label: 'Horses'
              },
              {
                name: 'optional-presentUse-Hunting',
                inputType: InputTypes.Checkbox,
                label: 'Hunting'
              },
              {
                name: 'optional-presentUse-Industrial',
                inputType: InputTypes.Checkbox,
                label: 'Industrial'
              },
              {
                name: 'optional-presentUse-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-presentUse-Poultry',
                inputType: InputTypes.Checkbox,
                label: 'Poultry'
              },
              {
                name: 'optional-presentUse-Recreational',
                inputType: InputTypes.Checkbox,
                label: 'Recreational'
              },
              {
                name: 'optional-presentUse-Subdivision',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision'
              },
              {
                name: 'optional-presentUse-Swine',
                inputType: InputTypes.Checkbox,
                label: 'Swine'
              },
              {
                name: 'optional-presentUse-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'optional-presentUse-TreeFarm',
                inputType: InputTypes.Checkbox,
                label: 'Tree Farm'
              },
              {
                name: 'optional-presentUse-Unimproved',
                inputType: InputTypes.Checkbox,
                label: 'Unimproved'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Potential Use',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-potentialUse-Residential',
                inputType: InputTypes.Checkbox,
                label: 'Residential'
              },
              {
                name: 'optional-potentialUse-Agricultural',
                inputType: InputTypes.Checkbox,
                label: 'Agricultural'
              },
              {
                name: 'optional-potentialUse-Commercial',
                inputType: InputTypes.Checkbox,
                label: 'Commercial'
              },
              {
                name: 'optional-potentialUse-Hunting',
                inputType: InputTypes.Checkbox,
                label: 'Hunting'
              },
              {
                name: 'optional-potentialUse-Industrial',
                inputType: InputTypes.Checkbox,
                label: 'Industrial'
              },
              {
                name: 'optional-potentialUse-OfficeInstitutional',
                inputType: InputTypes.Checkbox,
                label: 'Office/Institutional'
              },
              {
                name: 'optional-potentialUse-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-potentialUse-Recreational',
                inputType: InputTypes.Checkbox,
                label: 'Recreational'
              },
              {
                name: 'optional-potentialUse-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Structures on Property',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-structuresOnProperty-Barns',
                inputType: InputTypes.Checkbox,
                label: 'Barn(s)'
              },
              {
                name: 'optional-structuresOnProperty-BoatHouse',
                inputType: InputTypes.Checkbox,
                label: 'Boat House'
              },
              {
                name: 'optional-structuresOnProperty-CoveredDock',
                inputType: InputTypes.Checkbox,
                label: 'Covered Dock'
              },
              {
                name: 'optional-structuresOnProperty-Garages',
                inputType: InputTypes.Checkbox,
                label: 'Garage(s)'
              },
              {
                name: 'optional-structuresOnProperty-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-structuresOnProperty-Greenhouse',
                inputType: InputTypes.Checkbox,
                label: 'Greenhouse'
              },
              {
                name: 'optional-structuresOnProperty-GuestHouse',
                inputType: InputTypes.Checkbox,
                label: 'Guest House'
              },
              {
                name: 'optional-structuresOnProperty-KennelDogRun',
                inputType: InputTypes.Checkbox,
                label: 'Kennel/Dog Run'
              },
              {
                name: 'optional-structuresOnProperty-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-structuresOnProperty-Outbuilding',
                inputType: InputTypes.Checkbox,
                label: 'Outbuilding'
              },
              {
                name: 'optional-structuresOnProperty-OutdoorKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Kitchen'
              },
              {
                name: 'optional-structuresOnProperty-PoolHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pool House'
              },
              {
                name: 'optional-structuresOnProperty-SecondGarage',
                inputType: InputTypes.Checkbox,
                label: 'Second Garage'
              },
              {
                name: 'optional-structuresOnProperty-SecondResidence',
                inputType: InputTypes.Checkbox,
                label: 'Second Residence'
              },
              {
                name: 'optional-structuresOnProperty-Sheds',
                inputType: InputTypes.Checkbox,
                label: 'Shed(s)'
              },
              {
                name: 'optional-structuresOnProperty-Stables',
                inputType: InputTypes.Checkbox,
                label: 'Stable(s)'
              },
              {
                name: 'optional-structuresOnProperty-StationaryDock',
                inputType: InputTypes.Checkbox,
                label: 'Stationary Dock'
              },
              {
                name: 'optional-structuresOnProperty-TennisCourts',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court(s)'
              },
              {
                name: 'optional-structuresOnProperty-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Outdoor Living Structures',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-outdoorLivingStructures-Balcony',
                inputType: InputTypes.Checkbox,
                label: 'Balcony'
              },  
              {
                name: 'optional-outdoorLivingStructures-BalconiesMultiple',
                inputType: InputTypes.Checkbox,
                label: 'Balconies - Multiple'
              },  
              {
                name: 'optional-outdoorLivingStructures-Breezeway',
                inputType: InputTypes.Checkbox,
                label: 'Breezeway'
              },  
              {
                name: 'optional-outdoorLivingStructures-Deck',
                inputType: InputTypes.Checkbox,
                label: 'Deck(s)'
              },  
              {
                name: 'optional-outdoorLivingStructures-EnclosedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Enclosed Porch'
              },  
              {
                name: 'optional-outdoorLivingStructures-Patio',
                inputType: InputTypes.Checkbox,
                label: 'Patio(s)'
              },  
              {
                name: 'optional-outdoorLivingStructures-Porch',
                inputType: InputTypes.Checkbox,
                label: 'Porch(es)'
              },  
              {
                name: 'optional-outdoorLivingStructures-RoofDeck',
                inputType: InputTypes.Checkbox,
                label: 'Roof Deck'
              },  
              {
                name: 'optional-outdoorLivingStructures-ScreenedPorch',
                inputType: InputTypes.Checkbox,
                label: 'Screened Porch'
              },  
              {
                name: 'optional-outdoorLivingStructures-Terrace',
                inputType: InputTypes.Checkbox,
                label: 'Terrace'
              },  
              {
                name: 'optional-outdoorLivingStructures-WrapAroundPorch',
                inputType: InputTypes.Checkbox,
                label: 'Wrap Around Porch'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Energy Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-energyFeatures-LowFlowFixtures',
                inputType: InputTypes.Checkbox,
                label: 'Low-Flow Fixtures'
              },
              {
                name: 'optional-energyFeatures-WaterRecycling',
                inputType: InputTypes.Checkbox,
                label: 'Water Recycling'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientAppliances',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Appliances'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientDoors',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Doors'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientExposureShade',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Exposure/Shade'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientInsulation',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Insulation'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientRoof',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Roof'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientThermostat',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Thermostat'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Water Heater'
              },
              {
                name: 'optional-energyFeatures-EnergyEfficientWindows',
                inputType: InputTypes.Checkbox,
                label: 'Energy Efficient Windows'
              },
              {
                name: 'optional-energyFeatures-CertifiedEarthcraft',
                inputType: InputTypes.Checkbox,
                label: 'Certified Earthcraft'
              },
              {
                name: 'optional-energyFeatures-CertifiedGoodCents',
                inputType: InputTypes.Checkbox,
                label: 'Certified Good Cents'
              },
              {
                name: 'optional-energyFeatures-ENERGYSTARCertified',
                inputType: InputTypes.Checkbox,
                label: 'ENERGY STAR Certified'
              },
              {
                name: 'optional-energyFeatures-HERSIndexScore',
                inputType: InputTypes.Checkbox,
                label: 'HERS Index Score'
              },
              {
                name: 'optional-energyFeatures-HomeEnergyScore',
                inputType: InputTypes.Checkbox,
                label: 'Home Energy Score'
              },
              {
                name: 'optional-energyFeatures-LEEDForHomes',
                inputType: InputTypes.Checkbox,
                label: 'LEED For Homes'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Energy Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-energyFeatures-EnergyStarRated',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Rated'
              },
              {
                name: 'optional-energyFeatures-Generator',
                inputType: InputTypes.Checkbox,
                label: 'Generator'
              },
              {
                name: 'optional-energyFeatures-GeneratorAutoStart',
                inputType: InputTypes.Checkbox,
                label: 'Generator Auto Start'
              },
              {
                name: 'optional-energyFeatures-InsulatedDoors',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Doors'
              },
              {
                name: 'optional-energyFeatures-InsulatedWindows',
                inputType: InputTypes.Checkbox,
                label: 'Insulated Windows'
              },
              {
                name: 'optional-energyFeatures-LEEDCertified',
                inputType: InputTypes.Checkbox,
                label: 'LEED Certified'
              },
              {
                name: 'optional-energyFeatures-NAHBCertifiedGreen',
                inputType: InputTypes.Checkbox,
                label: 'NAHB Certified Green'
              },
              {
                name: 'optional-energyFeatures-StormDoors',
                inputType: InputTypes.Checkbox,
                label: 'Storm Doors'
              },
              {
                name: 'optional-energyFeatures-StormWindows',
                inputType: InputTypes.Checkbox,
                label: 'Storm Windows'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Pool Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-poolFeatures-AboveGroundPool',
                inputType: InputTypes.Checkbox,
                label: 'Above Ground Pool'
              },
              {
                name: 'optional-poolFeatures-Association',
                inputType: InputTypes.Checkbox,
                label: 'Association'
              },
              {
                name: 'optional-poolFeatures-Community',
                inputType: InputTypes.Checkbox,
                label: 'Community'
              },
              {
                name: 'optional-poolFeatures-DivingBoard',
                inputType: InputTypes.Checkbox,
                label: 'Diving Board'
              },
              {
                name: 'optional-poolFeatures-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-poolFeatures-Fiberglass',
                inputType: InputTypes.Checkbox,
                label: 'Fiberglass'
              },
              {
                name: 'optional-poolFeatures-Gunite',
                inputType: InputTypes.Checkbox,
                label: 'Gunite'
              },
              {
                name: 'optional-poolFeatures-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'optional-poolFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-poolFeatures-InGroundPool',
                inputType: InputTypes.Checkbox,
                label: 'In Ground Pool'
              },
              {
                name: 'optional-poolFeatures-Indoor',
                inputType: InputTypes.Checkbox,
                label: 'Indoor'
              },
              {
                name: 'optional-poolFeatures-Infinity',
                inputType: InputTypes.Checkbox,
                label: 'Infinity'
              },
              {
                name: 'optional-poolFeatures-Lap',
                inputType: InputTypes.Checkbox,
                label: 'Lap'
              },
              {
                name: 'optional-poolFeatures-OutdoorPool',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Pool'
              },
              {
                name: 'optional-poolFeatures-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-poolFeatures-SaltWaterPool',
                inputType: InputTypes.Checkbox,
                label: 'Salt Water Pool'
              },
              {
                name: 'optional-poolFeatures-SolarCover',
                inputType: InputTypes.Checkbox,
                label: 'Solar Cover'
              },
              {
                name: 'optional-poolFeatures-SolarHeat',
                inputType: InputTypes.Checkbox,
                label: 'Solar Heat'
              },
              {
                name: 'optional-poolFeatures-SwimmingPoolFee',
                inputType: InputTypes.Checkbox,
                label: 'Swimming Pool Com/Fee'
              },
              {
                name: 'optional-poolFeatures-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-poolFeatures-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-poolFeatures-Waterfall',
                inputType: InputTypes.Checkbox,
                label: 'Waterfall'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Pool Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-poolFeatures-AboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Above Ground'
              },
              {
                name: 'optional-poolFeatures-CommunityOffSite',
                inputType: InputTypes.Checkbox,
                label: 'Community / Off Site'
              },
              {
                name: 'optional-poolFeatures-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-poolFeatures-Covered',
                inputType: InputTypes.Checkbox,
                label: 'Covered'
              },
              {
                name: 'optional-poolFeatures-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-poolFeatures-Gunite',
                inputType: InputTypes.Checkbox,
                label: 'Gunite'
              },
              {
                name: 'optional-poolFeatures-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'optional-poolFeatures-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-poolFeatures-InGround',
                inputType: InputTypes.Checkbox,
                label: 'In Ground'
              },
              {
                name: 'optional-poolFeatures-Indoor',
                inputType: InputTypes.Checkbox,
                label: 'Indoor'
              },
              {
                name: 'optional-poolFeatures-LapPool',
                inputType: InputTypes.Checkbox,
                label: 'Lap Pool'
              },
              {
                name: 'optional-poolFeatures-MembershipRequired',
                inputType: InputTypes.Checkbox,
                label: 'Membership Required'
              },
              {
                name: 'optional-poolFeatures-Outdoor',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor'
              },
              {
                name: 'optional-poolFeatures-PoolEquipment',
                inputType: InputTypes.Checkbox,
                label: 'Pool Equipment'
              },
              {
                name: 'optional-poolFeatures-PoolHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pool House'
              },
              {
                name: 'optional-poolFeatures-SelfCleaner',
                inputType: InputTypes.Checkbox,
                label: 'Self-Cleaner'
              },
              {
                name: 'optional-poolFeatures-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-poolFeatures-WithSpa',
                inputType: InputTypes.Checkbox,
                label: 'With Spa'
              },
              {
                name: 'optional-poolFeatures-WithinYard',
                inputType: InputTypes.Checkbox,
                label: 'Within Yard'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Pool Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-poolFeatures-AboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Above Ground'
              },
              {
                name: 'optional-poolFeatures-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-poolFeatures-Heated',
                inputType: InputTypes.Checkbox,
                label: 'Heated'
              },
              {
                name: 'optional-poolFeatures-InGround',
                inputType: InputTypes.Checkbox,
                label: 'In-Ground'
              },
              {
                name: 'optional-poolFeatures-Indoor',
                inputType: InputTypes.Checkbox,
                label: 'Indoor'
              },
              {
                name: 'optional-poolFeatures-LapExercise',
                inputType: InputTypes.Checkbox,
                label: 'Lap / Exercise'
              },
              {
                name: 'optional-poolFeatures-NegativeEdgeInfinity',
                inputType: InputTypes.Checkbox,
                label: 'Negative Edge / Infinity'
              },
              {
                name: 'optional-poolFeatures-NoPermits',
                inputType: InputTypes.Checkbox,
                label: 'No Permits'
              },
              {
                name: 'optional-poolFeatures-Permits',
                inputType: InputTypes.Checkbox,
                label: 'Permits'
              },
              {
                name: 'optional-poolFeatures-Saltwater',
                inputType: InputTypes.Checkbox,
                label: 'Saltwater'
              },
              {
                name: 'optional-poolFeatures-SolarHeated',
                inputType: InputTypes.Checkbox,
                label: 'Solar Heated'
              },
              {
                name: 'optional-poolFeatures-SpaceHeatPassive',
                inputType: InputTypes.Checkbox,
                label: 'Space Heat Passive'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Buildings',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-buildings-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn(s)'
              },
              {
                name: 'optional-buildings-ChickenHouse',
                inputType: InputTypes.Checkbox,
                label: 'Chicken House'
              },
              {
                name: 'optional-buildings-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-buildings-GreenHouse',
                inputType: InputTypes.Checkbox,
                label: 'Green House'
              },
              {
                name: 'optional-buildings-HorseFacilities',
                inputType: InputTypes.Checkbox,
                label: 'Horse Facilities'
              },
              {
                name: 'optional-buildings-MainHouse',
                inputType: InputTypes.Checkbox,
                label: 'Main House'
              },
              {
                name: 'optional-buildings-ManufacturedHome',
                inputType: InputTypes.Checkbox,
                label: 'Manufactured Home'
              },
              {
                name: 'optional-buildings-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-buildings-Shed',
                inputType: InputTypes.Checkbox,
                label: 'Shed'
              },
              {
                name: 'optional-buildings-Silo',
                inputType: InputTypes.Checkbox,
                label: 'Silo'
              },
              {
                name: 'optional-buildings-Stable',
                inputType: InputTypes.Checkbox,
                label: 'Stable'
              },
              {
                name: 'optional-buildings-StorageBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Storage Building'
              },
              {
                name: 'optional-buildings-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fuel Tank',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fuelTank-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-fuelTank-OilAboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Oil Above Ground'
              },
              {
                name: 'optional-fuelTank-OilBuried',
                inputType: InputTypes.Checkbox,
                label: 'Oil Buried'
              },
              {
                name: 'optional-fuelTank-PropaneAboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Propane Above Ground'
              },
              {
                name: 'optional-fuelTank-PropaneBuried',
                inputType: InputTypes.Checkbox,
                label: 'Propane Buried'
              },
              {
                name: 'optional-fuelTank-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-fuelTank-FuelTankLeased',
                inputType: InputTypes.Checkbox,
                label: 'Fuel Tank Leased'
              },
              {
                name: 'optional-fuelTank-FuelTankOwned',
                inputType: InputTypes.Checkbox,
                label: 'Fuel Tank Owned'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Improvements',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-improvements-Residence',
                inputType: InputTypes.Checkbox,
                label: 'Residence'
              },
              {
                name: 'optional-improvements-MobileHome',
                inputType: InputTypes.Checkbox,
                label: 'Mobile Home'
              },
              {
                name: 'optional-improvements-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-improvements-UtilityBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Utility Building'
              },
              {
                name: 'optional-improvements-StorageBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Storage Building'
              },
              {
                name: 'optional-improvements-ConcreteSlab',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Slab'
              },
              {
                name: 'optional-improvements-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-improvements-CrossFenced',
                inputType: InputTypes.Checkbox,
                label: 'Cross Fenced'
              },
              {
                name: 'optional-improvements-Driveway',
                inputType: InputTypes.Checkbox,
                label: 'Driveway'
              },
              {
                name: 'optional-improvements-BoatDock',
                inputType: InputTypes.Checkbox,
                label: 'Boat Dock'
              },
              {
                name: 'optional-improvements-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-improvements-Stables',
                inputType: InputTypes.Checkbox,
                label: 'Stables'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Improvements',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-improvements-CurbGutter',
                inputType: InputTypes.Checkbox,
                label: 'Curb/Gutter'
              },
              {
                name: 'optional-improvements-Driveway',
                inputType: InputTypes.Checkbox,
                label: 'Driveway'
              },
              {
                name: 'optional-improvements-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-improvements-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-improvements-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-improvements-PartiallyFenced',
                inputType: InputTypes.Checkbox,
                label: 'Partially Fenced'
              },
              {
                name: 'optional-improvements-Sidewalks',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalks'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Improvements',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-improvements-AutoShop',
                inputType: InputTypes.Checkbox,
                label: 'Auto Shop'
              },
              {
                name: 'optional-improvements-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn(s)'
              },
              {
                name: 'optional-improvements-FeedBarn',
                inputType: InputTypes.Checkbox,
                label: 'Feed Barn'
              },
              {
                name: 'optional-improvements-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-improvements-Greenhouse',
                inputType: InputTypes.Checkbox,
                label: 'Greenhouse'
              },
              {
                name: 'optional-improvements-HayShed',
                inputType: InputTypes.Checkbox,
                label: 'Hay Shed'
              },
              {
                name: 'optional-improvements-MilkParlor',
                inputType: InputTypes.Checkbox,
                label: 'Milk Parlor'
              },
              {
                name: 'optional-improvements-Outbuilding',
                inputType: InputTypes.Checkbox,
                label: 'Outbuliding'
              },
              {
                name: 'optional-improvements-PackingShed',
                inputType: InputTypes.Checkbox,
                label: 'Packing Shed'
              },
              {
                name: 'optional-improvements-Shed',
                inputType: InputTypes.Checkbox,
                label: 'Shed(s)'
              },
              {
                name: 'optional-improvements-TractorShed',
                inputType: InputTypes.Checkbox,
                label: 'Tractor Shed'
              },
              {
                name: 'optional-improvements-Stable',
                inputType: InputTypes.Checkbox,
                label: 'Stable'
              },
              {
                name: 'optional-improvements-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
              {
                name: 'optional-improvements-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Best Use',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-bestUse-SingleResidence',
                inputType: InputTypes.Checkbox,
                label: 'Single Residence'
              },
              {
                name: 'optional-bestUse-Business',
                inputType: InputTypes.Checkbox,
                label: 'Business'
              },
              {
                name: 'optional-bestUse-Commercial',
                inputType: InputTypes.Checkbox,
                label: 'Commercial'
              },
              {
                name: 'optional-bestUse-Dairy',
                inputType: InputTypes.Checkbox,
                label: 'Dairy'
              },
              {
                name: 'optional-bestUse-Farming',
                inputType: InputTypes.Checkbox,
                label: 'Farming'
              },
              {
                name: 'optional-bestUse-Hunting',
                inputType: InputTypes.Checkbox,
                label: 'Hunting'
              },
              {
                name: 'optional-bestUse-Investment',
                inputType: InputTypes.Checkbox,
                label: 'Investment'
              },
              {
                name: 'optional-bestUse-Manufactured',
                inputType: InputTypes.Checkbox,
                label: 'Manufactured'
              },
              {
                name: 'optional-bestUse-Modular',
                inputType: InputTypes.Checkbox,
                label: 'Modular'
              },
              {
                name: 'optional-bestUse-MultiFamily',
                inputType: InputTypes.Checkbox,
                label: 'Multi-Family'
              },
              {
                name: 'optional-bestUse-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-bestUse-Recreational',
                inputType: InputTypes.Checkbox,
                label: 'Recreational'
              },
              {
                name: 'optional-bestUse-Resort',
                inputType: InputTypes.Checkbox,
                label: 'Resort'
              },
              {
                name: 'optional-bestUse-Service',
                inputType: InputTypes.Checkbox,
                label: 'Service'
              },
              {
                name: 'optional-bestUse-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'optional-bestUse-Warehouse',
                inputType: InputTypes.Checkbox,
                label: 'Warehouse'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Possible Use',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-possibleUse-SingleFamily',
                inputType: InputTypes.Checkbox,
                label: 'Single Family'
              },
              {
                name: 'optional-possibleUse-Business',
                inputType: InputTypes.Checkbox,
                label: 'Business'
              },
              {
                name: 'optional-possibleUse-Farm',
                inputType: InputTypes.Checkbox,
                label: 'Farm'
              },
              {
                name: 'optional-possibleUse-GenCommercial',
                inputType: InputTypes.Checkbox,
                label: 'Gen Commercial'
              },
              {
                name: 'optional-possibleUse-HorsesPermitted',
                inputType: InputTypes.Checkbox,
                label: 'Horses Permitted'
              },
              {
                name: 'optional-possibleUse-Hunting',
                inputType: InputTypes.Checkbox,
                label: 'Hunting'
              },
              {
                name: 'optional-possibleUse-Recreational',
                inputType: InputTypes.Checkbox,
                label: 'Recreational'
              },
              {
                name: 'optional-possibleUse-Subdivision',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision'
              },
              {
                name: 'optional-possibleUse-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Possible Use',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-possibleUse-Residential',
                inputType: InputTypes.Checkbox,
                label: 'Residential'
              },
              {
                name: 'optional-possibleUse-Agriculture',
                inputType: InputTypes.Checkbox,
                label: 'Agriculture'
              },
              {
                name: 'optional-possibleUse-Commercial',
                inputType: InputTypes.Checkbox,
                label: 'Commercial'
              },
              {
                name: 'optional-possibleUse-Hunting',
                inputType: InputTypes.Checkbox,
                label: 'Hunting'
              },
              {
                name: 'optional-possibleUse-Industrial',
                inputType: InputTypes.Checkbox,
                label: 'Industrial'
              },
              {
                name: 'optional-possibleUse-LandLotOnly',
                inputType: InputTypes.Checkbox,
                label: 'Land/Lot Only'
              },
              {
                name: 'optional-possibleUse-Livestock',
                inputType: InputTypes.Checkbox,
                label: 'Livestock'
              },
              {
                name: 'optional-possibleUse-Mixed',
                inputType: InputTypes.Checkbox,
                label: 'Mixed'
              },
              {
                name: 'optional-possibleUse-Office',
                inputType: InputTypes.Checkbox,
                label: 'Office'
              },
              {
                name: 'optional-possibleUse-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-possibleUse-Parking',
                inputType: InputTypes.Checkbox,
                label: 'Parking'
              },
              {
                name: 'optional-possibleUse-Recreation',
                inputType: InputTypes.Checkbox,
                label: 'Recreation'
              },
              {
                name: 'optional-possibleUse-Retail',
                inputType: InputTypes.Checkbox,
                label: 'Retail'
              },
              {
                name: 'optional-possibleUse-SandGravelDirt',
                inputType: InputTypes.Checkbox,
                label: 'Sand/Gravel/Dirt'
              },
              {
                name: 'optional-possibleUse-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'optional-possibleUse-Warehouse',
                inputType: InputTypes.Checkbox,
                label: 'Warehouse'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Main Road Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-mainRoadType-2Lane',
                inputType: InputTypes.Checkbox,
                label: '2-Lane'
              },
              {
                name: 'optional-mainRoadType-4Lane',
                inputType: InputTypes.Checkbox,
                label: '4-Lane'
              },
              {
                name: 'optional-mainRoadType-Dirt',
                inputType: InputTypes.Checkbox,
                label: 'Dirt'
              },
              {
                name: 'optional-mainRoadType-Gravel',
                inputType: InputTypes.Checkbox,
                label: 'Gravel'
              },
              {
                name: 'optional-mainRoadType-NotStateMaintained',
                inputType: InputTypes.Checkbox,
                label: 'Not State Maintained'
              },
              {
                name: 'optional-mainRoadType-Paved',
                inputType: InputTypes.Checkbox,
                label: 'Paved'
              },
              {
                name: 'optional-mainRoadType-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-mainRoadType-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'optional-mainRoadType-StateMaintained',
                inputType: InputTypes.Checkbox,
                label: 'State Maintained'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Restrictions',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-restrictions-ArchitecDes',
                inputType: InputTypes.Checkbox,
                label: 'Architec Des'
              },
              {
                name: 'optional-restrictions-AssocRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Assoc Restrictions'
              },
              {
                name: 'optional-restrictions-BldgMorator',
                inputType: InputTypes.Checkbox,
                label: 'Bldg Morator'
              },
              {
                name: 'optional-restrictions-BldgRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Bldg Restrictions'
              },
              {
                name: 'optional-restrictions-CropsToBeHarvested',
                inputType: InputTypes.Checkbox,
                label: 'Crops To Be Harvested'
              },
              {
                name: 'optional-restrictions-DeedRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Deed Restrictions'
              },
              {
                name: 'optional-restrictions-Easement',
                inputType: InputTypes.Checkbox,
                label: 'Easement'
              },
              {
                name: 'optional-restrictions-Historic',
                inputType: InputTypes.Checkbox,
                label: 'Historic'
              },
              {
                name: 'optional-restrictions-LandLocked',
                inputType: InputTypes.Checkbox,
                label: 'Land Locked'
              },
              {
                name: 'optional-restrictions-MineralRights',
                inputType: InputTypes.Checkbox,
                label: 'Mineral Rights'
              },
              {
                name: 'optional-restrictions-Municipality',
                inputType: InputTypes.Checkbox,
                label: 'Municipality'
              },
              {
                name: 'optional-restrictions-NoMobileHomes',
                inputType: InputTypes.Checkbox,
                label: 'No Mobile Homes'
              },
              {
                name: 'optional-restrictions-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-restrictions-RightOfWay',
                inputType: InputTypes.Checkbox,
                label: 'Right Of Way'
              },
              {
                name: 'optional-restrictions-SubdivRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Subdiv Restrictions'
              },
              {
                name: 'optional-restrictions-VarianceNeeded',
                inputType: InputTypes.Checkbox,
                label: 'Variance Needed'
              },
              {
                name: 'optional-restrictions-WaterfrontRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront Restrictions'
              },
              {
                name: 'optional-restrictions-Wetlands',
                inputType: InputTypes.Checkbox,
                label: 'Wetlands'
              },
              {
                name: 'optional-restrictions-ZoningRestrictions',
                inputType: InputTypes.Checkbox,
                label: 'Zoning Restrictions'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Sale Option',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-saleOption-EntireParcelOnly',
                inputType: InputTypes.Checkbox,
                label: 'Entire Parcel Only'
              },
              {
                name: 'optional-saleOption-WillSellSeparately',
                inputType: InputTypes.Checkbox,
                label: 'Will Sell Separately'
              },
              {
                name: 'optional-saleOption-WillBuild',
                inputType: InputTypes.Checkbox,
                label: 'Will Build'
              },
              {
                name: 'optional-saleOption-WillExchange',
                inputType: InputTypes.Checkbox,
                label: 'Will Exchange'
              },
              {
                name: 'optional-saleOption-WillSubdivide',
                inputType: InputTypes.Checkbox,
                label: 'Will Subdivide'
              },
              {
                name: 'optional-saleOption-WillSubordinate',
                inputType: InputTypes.Checkbox,
                label: 'Will Subordinate'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Available Information',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-availableInformation-AerialPhoto',
                inputType: InputTypes.Checkbox,
                label: 'Aerial Photo'
              },
              {
                name: 'optional-availableInformation-Appraisal',
                inputType: InputTypes.Checkbox,
                label: 'Appraisal'
              },
              {
                name: 'optional-availableInformation-AssociationByLaw',
                inputType: InputTypes.Checkbox,
                label: 'Association By-law'
              },
              {
                name: 'optional-availableInformation-BuildingPlans',
                inputType: InputTypes.Checkbox,
                label: 'Building Plans'
              },
              {
                name: 'optional-availableInformation-Easement',
                inputType: InputTypes.Checkbox,
                label: 'Easement'
              },
              {
                name: 'optional-availableInformation-EngineerPlans',
                inputType: InputTypes.Checkbox,
                label: 'Engineer Plans'
              },
              {
                name: 'optional-availableInformation-EnvironmentalStudy',
                inputType: InputTypes.Checkbox,
                label: 'Environmental Study'
              },
              {
                name: 'optional-availableInformation-FeasibilityStudy',
                inputType: InputTypes.Checkbox,
                label: 'Feasibility Study'
              },
              {
                name: 'optional-availableInformation-FieldMap',
                inputType: InputTypes.Checkbox,
                label: 'Field Map'
              },
              {
                name: 'optional-availableInformation-PhaseOne',
                inputType: InputTypes.Checkbox,
                label: 'Phase One'
              },
              {
                name: 'optional-availableInformation-PlanDevelopment',
                inputType: InputTypes.Checkbox,
                label: 'Plan Development'
              },
              {
                name: 'optional-availableInformation-Plat',
                inputType: InputTypes.Checkbox,
                label: 'Plat'
              },
              {
                name: 'optional-availableInformation-PotableWater',
                inputType: InputTypes.Checkbox,
                label: 'Potable Water'
              },
              {
                name: 'optional-availableInformation-Survey',
                inputType: InputTypes.Checkbox,
                label: 'Survey'
              },
              {
                name: 'optional-availableInformation-TopographyMap',
                inputType: InputTypes.Checkbox,
                label: 'Topography Map'
              },
              {
                name: 'optional-availableInformation-TrafficStudy',
                inputType: InputTypes.Checkbox,
                label: 'Traffic Study'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Documents Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-documentAvailable-PlatSurvey',
                inputType: InputTypes.Checkbox,
                label: 'Plat/Survey'
              },
              {
                name: 'optional-documentAvailable-LegalDescription',
                inputType: InputTypes.Checkbox,
                label: 'Legal Description'
              },
              {
                name: 'optional-documentAvailable-TopographyMap',
                inputType: InputTypes.Checkbox,
                label: 'Topography Map'
              },
              {
                name: 'optional-documentAvailable-AerialPhoto',
                inputType: InputTypes.Checkbox,
                label: 'Aerial Photo'
              },
              {
                name: 'optional-documentAvailable-SepticPermit',
                inputType: InputTypes.Checkbox,
                label: 'Septic Permit'
              },
              {
                name: 'optional-documentAvailable-SubdivisionPlan',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision Plan'
              },
              {
                name: 'optional-documentAvailable-Leases',
                inputType: InputTypes.Checkbox,
                label: 'Leases'
              },
              {
                name: 'optional-documentAvailable-Mortgages',
                inputType: InputTypes.Checkbox,
                label: 'Mortgages'
              },
              {
                name: 'optional-documentAvailable-FHAApproval',
                inputType: InputTypes.Checkbox,
                label: 'FHA Approval'
              },
              {
                name: 'optional-documentAvailable-FMHMApproval',
                inputType: InputTypes.Checkbox,
                label: 'FMHM Approval'
              },
              {
                name: 'optional-documentAvailable-VAApproval',
                inputType: InputTypes.Checkbox,
                label: 'VA Approval'
              },
              {
                name: 'optional-documentAvailable-Restrictions',
                inputType: InputTypes.Checkbox,
                label: 'Restrictions'
              },
              {
                name: 'optional-documentAvailable-TimberMineralReservtns',
                inputType: InputTypes.Checkbox,
                label: 'Timber/Mineral Reservtns'
              },
              {
                name: 'optional-documentAvailable-TimberCruise',
                inputType: InputTypes.Checkbox,
                label: 'Timber Cruise'
              },
              {
                name: 'optional-documentAvailable-DockPermit',
                inputType: InputTypes.Checkbox,
                label: 'Dock Permit'
              },
              {
                name: 'optional-documentAvailable-BulkHeadPermit',
                inputType: InputTypes.Checkbox,
                label: 'Bulk Head Permit'
              },
              {
                name: 'optional-documentAvailable-WetlandStudy',
                inputType: InputTypes.Checkbox,
                label: 'Wetland Study'
              },
              {
                name: 'optional-documentAvailable-EnvironmentalStudy',
                inputType: InputTypes.Checkbox,
                label: 'Environmental Study'
              },
              {
                name: 'optional-documentAvailable-DesignatedFloodZoneSrv',
                inputType: InputTypes.Checkbox,
                label: 'Designated Flood Zone Srv'
              },
              {
                name: 'optional-documentAvailable-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Additional Information',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-additionalInfo-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'optional-additionalInfo-Cleared',
                inputType: InputTypes.Checkbox,
                label: 'Cleared'
              },
              {
                name: 'optional-additionalInfo-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-additionalInfo-UnderCultivation',
                inputType: InputTypes.Checkbox,
                label: 'Under Cultivation'
              },
              {
                name: 'optional-additionalInfo-GardenPlot',
                inputType: InputTypes.Checkbox,
                label: 'Garden Plot'
              },
              {
                name: 'optional-additionalInfo-Spoilage',
                inputType: InputTypes.Checkbox,
                label: 'Spoilage'
              },
              {
                name: 'optional-additionalInfo-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond(s)'
              },
              {
                name: 'optional-additionalInfo-Irrigation',
                inputType: InputTypes.Checkbox,
                label: 'Irrigation'
              },
              {
                name: 'optional-additionalInfo-TobaccoAllotments',
                inputType: InputTypes.Checkbox,
                label: 'Tobacco Allotments'
              },
              {
                name: 'optional-additionalInfo-TobaccoBarn',
                inputType: InputTypes.Checkbox,
                label: 'Tobacco Barn(s)'
              },
              {
                name: 'optional-additionalInfo-TimberReservations',
                inputType: InputTypes.Checkbox,
                label: 'Timber Reservations'
              },
              {
                name: 'optional-additionalInfo-MineralReservations',
                inputType: InputTypes.Checkbox,
                label: 'Mineral Reservations'
              },
              {
                name: 'optional-additionalInfo-StormWaterRetention',
                inputType: InputTypes.Checkbox,
                label: 'Storm Water Retention'
              },
              {
                name: 'optional-additionalInfo-RailroadSiding',
                inputType: InputTypes.Checkbox,
                label: 'Railroad Siding'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Documents Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-documentAvailable-AerialPhoto',
                inputType: InputTypes.Checkbox,
                label: 'Aerial Photo'
              },
              {
                name: 'optional-documentAvailable-Appraisal',
                inputType: InputTypes.Checkbox,
                label: 'Appraisal'
              },
              {
                name: 'optional-documentAvailable-DockPermit',
                inputType: InputTypes.Checkbox,
                label: 'Dock Permit'
              },
              {
                name: 'optional-documentAvailable-DrivingDirections',
                inputType: InputTypes.Checkbox,
                label: 'Driving Directions'
              },
              {
                name: 'optional-documentAvailable-HousePlans',
                inputType: InputTypes.Checkbox,
                label: 'House Plans'
              },
              {
                name: 'optional-documentAvailable-Plat',
                inputType: InputTypes.Checkbox,
                label: 'Plat'
              },
              {
                name: 'optional-documentAvailable-SoilInfo',
                inputType: InputTypes.Checkbox,
                label: 'Soil Info'
              },
              {
                name: 'optional-documentAvailable-TimberCruise',
                inputType: InputTypes.Checkbox,
                label: 'Timber Cruise'
              },
              {
                name: 'optional-documentAvailable-TopoMap',
                inputType: InputTypes.Checkbox,
                label: 'Topo Map'
              },
              {
                name: 'optional-documentAvailable-TopoSurvey',
                inputType: InputTypes.Checkbox,
                label: 'Topo Survey'
              },
              {
                name: 'optional-documentAvailable-TreeSurvey',
                inputType: InputTypes.Checkbox,
                label: 'Tree Survey'
              },
              {
                name: 'optional-documentAvailable-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Documents Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-documentAvailable-Aerial',
                inputType: InputTypes.Checkbox,
                label: 'Aerial'
              },
              {
                name: 'optional-documentAvailable-Appraisal',
                inputType: InputTypes.Checkbox,
                label: 'Appraisal'
              },
              {
                name: 'optional-documentAvailable-Boundary',
                inputType: InputTypes.Checkbox,
                label: 'Boundary'
              },
              {
                name: 'optional-documentAvailable-DeedRestriction',
                inputType: InputTypes.Checkbox,
                label: 'Deed Restriction'
              },
              {
                name: 'optional-documentAvailable-Deeds',
                inputType: InputTypes.Checkbox,
                label: 'Deeds'
              },
              {
                name: 'optional-documentAvailable-Demographics',
                inputType: InputTypes.Checkbox,
                label: 'Demographics'
              },
              {
                name: 'optional-documentAvailable-FeasibilityStudy',
                inputType: InputTypes.Checkbox,
                label: 'Feasibility Study'
              },
              {
                name: 'optional-documentAvailable-FloodMap',
                inputType: InputTypes.Checkbox,
                label: 'Flood Map'
              },
              {
                name: 'optional-documentAvailable-Plan',
                inputType: InputTypes.Checkbox,
                label: 'Plan'
              },
              {
                name: 'optional-documentAvailable-Plat',
                inputType: InputTypes.Checkbox,
                label: 'Plat'
              },
              {
                name: 'optional-documentAvailable-RoadMaintAgree',
                inputType: InputTypes.Checkbox,
                label: 'Road Maint. Agree'
              },
              {
                name: 'optional-documentAvailable-SepticTankPermit',
                inputType: InputTypes.Checkbox,
                label: 'Septic Tank Permit'
              },
              {
                name: 'optional-documentAvailable-SepticSoilEval',
                inputType: InputTypes.Checkbox,
                label: 'Septic / Soil Eval'
              },
              {
                name: 'optional-documentAvailable-SoilSurvey',
                inputType: InputTypes.Checkbox,
                label: 'Soil Survey'
              },
              {
                name: 'optional-documentAvailable-Survey',
                inputType: InputTypes.Checkbox,
                label: 'Survey'
              },
              {
                name: 'optional-documentAvailable-TaxMap',
                inputType: InputTypes.Checkbox,
                label: 'Tax Map'
              },
              {
                name: 'optional-documentAvailable-TopographyMap',
                inputType: InputTypes.Checkbox,
                label: 'Topography Map'
              },
              {
                name: 'optional-documentAvailable-TrafficCount',
                inputType: InputTypes.Checkbox,
                label: 'Traffic Count'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Documents Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-documentAvailable-Aerial',
                inputType: InputTypes.Checkbox,
                label: 'Aerial'
              },
              {
                name: 'optional-documentAvailable-AircraftNoise',
                inputType: InputTypes.Checkbox,
                label: 'Aircraft Noise'
              },
              {
                name: 'optional-documentAvailable-Appraisal',
                inputType: InputTypes.Checkbox,
                label: 'Appraisal'
              },
              {
                name: 'optional-documentAvailable-AsIs',
                inputType: InputTypes.Checkbox,
                label: 'As Is'
              },
              {
                name: 'optional-documentAvailable-BoundLandAgreement',
                inputType: InputTypes.Checkbox,
                label: 'Bound Land Agreement'
              },
              {
                name: 'optional-documentAvailable-BoundStudy',
                inputType: InputTypes.Checkbox,
                label: 'Bound Study'
              },
              {
                name: 'optional-documentAvailable-CommunityInformation',
                inputType: InputTypes.Checkbox,
                label: 'Community Information'
              },
              {
                name: 'optional-documentAvailable-DeedRestriction',
                inputType: InputTypes.Checkbox,
                label: 'Deed Restriction'
              },
              {
                name: 'optional-documentAvailable-Easement',
                inputType: InputTypes.Checkbox,
                label: 'Easement'
              },
              {
                name: 'optional-documentAvailable-FloodPlains',
                inputType: InputTypes.Checkbox,
                label: 'Flood Plains'
              },
              {
                name: 'optional-documentAvailable-GreenCertification',
                inputType: InputTypes.Checkbox,
                label: 'Green Certification'
              },
              {
                name: 'optional-documentAvailable-HomeInspectionReport',
                inputType: InputTypes.Checkbox,
                label: 'Home Inspection Report'
              },
              {
                name: 'optional-documentAvailable-MoldReport',
                inputType: InputTypes.Checkbox,
                label: 'Mold Report'
              },             
              {
                name: 'optional-documentAvailable-PerkTest',
                inputType: InputTypes.Checkbox,
                label: 'Perk Test'
              },
              {
                name: 'optional-documentAvailable-RestrictiveCovenants',
                inputType: InputTypes.Checkbox,
                label: 'Restrictive Covenants'
              },
              {
                name: 'optional-documentAvailable-RoadMaintAgreement',
                inputType: InputTypes.Checkbox,
                label: 'Road Maint. Agreement'
              },
              {
                name: 'optional-documentAvailable-SitePlan',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan'
              },
              {
                name: 'optional-documentAvailable-SoilSurvey',
                inputType: InputTypes.Checkbox,
                label: 'Soil Survey'
              },
              {
                name: 'optional-documentAvailable-SubdivisionPlan',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision Plan'
              },
              {
                name: 'optional-documentAvailable-Survey',
                inputType: InputTypes.Checkbox,
                label: 'Survey'
              },
              {
                name: 'optional-documentAvailable-TopographyMap',
                inputType: InputTypes.Checkbox,
                label: 'Topography Map'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Documents Required',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-documentoptional-ArcheologicalStudy',
                inputType: InputTypes.Checkbox,
                label: 'Archeological Study'
              },
              {
                name: 'optional-documentoptional-Architectural',
                inputType: InputTypes.Checkbox,
                label: 'Architectural'
              },
              {
                name: 'optional-documentoptional-BldgPermit',
                inputType: InputTypes.Checkbox,
                label: 'Bldg Permit'
              },
              {
                name: 'optional-documentoptional-Historical',
                inputType: InputTypes.Checkbox,
                label: 'Historical'
              },
              {
                name: 'optional-documentoptional-PercTest',
                inputType: InputTypes.Checkbox,
                label: 'Perc Test'
              },
              {
                name: 'optional-documentoptional-PlanOfDevelopment',
                inputType: InputTypes.Checkbox,
                label: 'Plan Of Development'
              },
              {
                name: 'optional-documentoptional-RoadMaintAgreement',
                inputType: InputTypes.Checkbox,
                label: 'Road Maint Agreement'
              },
              {
                name: 'optional-documentoptional-SepticPermit',
                inputType: InputTypes.Checkbox,
                label: 'Septic Permit'
              },
              {
                name: 'optional-documentoptional-SitePlan',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan'
              },
              {
                name: 'optional-documentoptional-SoilStudy',
                inputType: InputTypes.Checkbox,
                label: 'Soil Study'
              },
              {
                name: 'optional-documentoptional-SubdivisionPlan',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision Plan'
              },
              {
                name: 'optional-documentoptional-Variance',
                inputType: InputTypes.Checkbox,
                label: 'Variance'
              },
              {
                name: 'optional-documentoptional-WetlandStudy',
                inputType: InputTypes.Checkbox,
                label: 'Wetland Study'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Documents Filled',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-documentFilled-ArcheologicalStudy',
                inputType: InputTypes.Checkbox,
                label: 'Archeological Study'
              },
              {
                name: 'optional-documentFilled-Architectural',
                inputType: InputTypes.Checkbox,
                label: 'Architectural'
              },
              {
                name: 'optional-documentFilled-BldgPermit',
                inputType: InputTypes.Checkbox,
                label: 'Bldg Permit'
              },
              {
                name: 'optional-documentFilled-Historical',
                inputType: InputTypes.Checkbox,
                label: 'Historical'
              },
              {
                name: 'optional-documentFilled-PercTest',
                inputType: InputTypes.Checkbox,
                label: 'Perc Test'
              },
              {
                name: 'optional-documentFilled-PlanOfDevelopment',
                inputType: InputTypes.Checkbox,
                label: 'Plan Of Development'
              },
              {
                name: 'optional-documentFilled-RoadMaintAgreement',
                inputType: InputTypes.Checkbox,
                label: 'Road Maint Agreement'
              },
              {
                name: 'optional-documentFilled-SepticPermit',
                inputType: InputTypes.Checkbox,
                label: 'Septic Permit'
              },
              {
                name: 'optional-documentFilled-SitePlan',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan'
              },
              {
                name: 'optional-documentFilled-SoilStudy',
                inputType: InputTypes.Checkbox,
                label: 'Soil Study'
              },
              {
                name: 'optional-documentFilled-SubdivisionPlan',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision Plan'
              },
              {
                name: 'optional-documentFilled-Variance',
                inputType: InputTypes.Checkbox,
                label: 'Variance'
              },
              {
                name: 'optional-documentFilled-WetlandStudy',
                inputType: InputTypes.Checkbox,
                label: 'Wetland Study'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Documents Approved',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-documentApproved-ArcheologicalStudy',
                inputType: InputTypes.Checkbox,
                label: 'Archeological Study'
              },
              {
                name: 'optional-documentApproved-Architectural',
                inputType: InputTypes.Checkbox,
                label: 'Architectural'
              },
              {
                name: 'optional-documentApproved-BldgPermit',
                inputType: InputTypes.Checkbox,
                label: 'Bldg Permit'
              },
              {
                name: 'optional-documentApproved-Historical',
                inputType: InputTypes.Checkbox,
                label: 'Historical'
              },
              {
                name: 'optional-documentApproved-PercTest',
                inputType: InputTypes.Checkbox,
                label: 'Perc Test'
              },
              {
                name: 'optional-documentApproved-PlanOfDevelopment',
                inputType: InputTypes.Checkbox,
                label: 'Plan Of Development'
              },
              {
                name: 'optional-documentApproved-RoadMaintAgreement',
                inputType: InputTypes.Checkbox,
                label: 'Road Maint Agreement'
              },
              {
                name: 'optional-documentApproved-SepticPermit',
                inputType: InputTypes.Checkbox,
                label: 'Septic Permit'
              },
              {
                name: 'optional-documentApproved-SitePlan',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan'
              },
              {
                name: 'optional-documentApproved-SoilStudy',
                inputType: InputTypes.Checkbox,
                label: 'Soil Study'
              },
              {
                name: 'optional-documentApproved-SubdivisionPlan',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision Plan'
              },
              {
                name: 'optional-documentApproved-Variance',
                inputType: InputTypes.Checkbox,
                label: 'Variance'
              },
              {
                name: 'optional-documentApproved-WetlandStudy',
                inputType: InputTypes.Checkbox,
                label: 'Wetland Study'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Development Status',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-developmentStatus-FinishedLots',
                inputType: InputTypes.Checkbox,
                label: 'Finished Lots'
              },
              {
                name: 'optional-developmentStatus-RawLand',
                inputType: InputTypes.Checkbox,
                label: 'Raw Land'
              },
              {
                name: 'optional-developmentStatus-RoughGrade',
                inputType: InputTypes.Checkbox,
                label: 'Rough Grade'
              },
              {
                name: 'optional-developmentStatus-SitePlanned',
                inputType: InputTypes.Checkbox,
                label: 'Site Planned'
              },
              {
                name: 'optional-developmentStatus-SitePlanRecorded',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan Recorded'
              },
              {
                name: 'optional-developmentStatus-Subdivision',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision'
              },
              {
                name: 'optional-developmentStatus-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Development Status',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-developmentStatus-Bonded',
                inputType: InputTypes.Checkbox,
                label: 'Bonded'
              },
              {
                name: 'optional-developmentStatus-BuildingPermit',
                inputType: InputTypes.Checkbox,
                label: 'Building Permit'
              },
              {
                name: 'optional-developmentStatus-CurbCuts',
                inputType: InputTypes.Checkbox,
                label: 'Curb Cuts'
              },
              {
                name: 'optional-developmentStatus-CurbGutter',
                inputType: InputTypes.Checkbox,
                label: 'Curb / Gutter'
              },
              {
                name: 'optional-developmentStatus-DrainfieldPermit',
                inputType: InputTypes.Checkbox,
                label: 'Drainfield Permit'
              },
              {
                name: 'optional-developmentStatus-EngineerDrawings',
                inputType: InputTypes.Checkbox,
                label: 'Engineer Drawings'
              },
              {
                name: 'optional-developmentStatus-FinishedLots',
                inputType: InputTypes.Checkbox,
                label: 'Finished Lots'
              },
              {
                name: 'optional-developmentStatus-MaserPlan',
                inputType: InputTypes.Checkbox,
                label: 'Maser Plan'
              },
              {
                name: 'optional-developmentStatus-PlatApproved',
                inputType: InputTypes.Checkbox,
                label: 'Plat Approved'
              },
              {
                name: 'optional-developmentStatus-PlatRecorded',
                inputType: InputTypes.Checkbox,
                label: 'Plat Recorded'
              },
              {
                name: 'optional-developmentStatus-PrelimPlanReview',
                inputType: InputTypes.Checkbox,
                label: 'Prelim Plan Review'
              },
              {
                name: 'optional-developmentStatus-RawLand',
                inputType: InputTypes.Checkbox,
                label: 'Raw Land'
              },
              {
                name: 'optional-developmentStatus-ReadyBond',
                inputType: InputTypes.Checkbox,
                label: 'Ready to Bond'
              },
              {
                name: 'optional-developmentStatus-SitePlan',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan'
              },
              {
                name: 'optional-developmentStatus-SitePlanApproved',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan Approved'
              },
              {
                name: 'optional-developmentStatus-SitePlanRecorded',
                inputType: InputTypes.Checkbox,
                label: 'Site Plan Recorded'
              },
              {
                name: 'optional-developmentStatus-UtilitiesSite',
                inputType: InputTypes.Checkbox,
                label: 'Utilities at Site'
              },
              {
                name: 'optional-developmentStatus-UtilitiesWithin50Ft',
                inputType: InputTypes.Checkbox,
                label: 'Utilities Within 50 Ft'
              },
              {
                name: 'optional-developmentStatus-Zoned',
                inputType: InputTypes.Checkbox,
                label: 'Zoned'
              },
              {
                name: 'optional-developmentStatus-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Uses',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-uses-Residential',
                inputType: InputTypes.Checkbox,
                label: 'Residential'
              },
              {
                name: 'optional-uses-Agricultural',
                inputType: InputTypes.Checkbox,
                label: 'Agricultural'
              },
              {
                name: 'optional-uses-Commercial',
                inputType: InputTypes.Checkbox,
                label: 'Commercial'
              },
              {
                name: 'optional-uses-Grazing',
                inputType: InputTypes.Checkbox,
                label: 'Grazing'
              },
              {
                name: 'optional-uses-HorseFarm',
                inputType: InputTypes.Checkbox,
                label: 'Horse Farm'
              },
              {
                name: 'optional-uses-Hunting',
                inputType: InputTypes.Checkbox,
                label: 'Hunting'
              },
              {
                name: 'optional-uses-MobileHome',
                inputType: InputTypes.Checkbox,
                label: 'Mobile Home'
              },
              {
                name: 'optional-uses-MultiFamily',
                inputType: InputTypes.Checkbox,
                label: 'Multi-Family'
              },
              {
                name: 'optional-uses-Timber',
                inputType: InputTypes.Checkbox,
                label: 'Timber'
              },
              {
                name: 'optional-uses-Zoning',
                inputType: InputTypes.Checkbox,
                label: 'Zoning'
              },
              {
                name: 'optional-uses-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-uses-PrivateEstate',
                inputType: InputTypes.Checkbox,
                label: 'Private Estate'
              },
              {
                name: 'optional-uses-Recreational2ndHouse',
                inputType: InputTypes.Checkbox,
                label: 'Recreational/2nd House'
              },
              {
                name: 'optional-uses-Subdevelopment',
                inputType: InputTypes.Checkbox,
                label: 'Subdevelopment'
              },
              {
                name: 'optional-uses-TreeFarmTimber',
                inputType: InputTypes.Checkbox,
                label: 'Tree Farm/Timber'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Buildings',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-building-Abandoned',
                inputType: InputTypes.Checkbox,
                label: 'Abandoned'
              },
              {
                name: 'optional-building-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn'
              },
              {
                name: 'optional-building-ChickenHouse',
                inputType: InputTypes.Checkbox,
                label: 'Chicken House'
              },
              {
                name: 'optional-building-FeedBarn',
                inputType: InputTypes.Checkbox,
                label: 'Feed Barn'
              },
              {
                name: 'optional-building-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-building-GreenHouse',
                inputType: InputTypes.Checkbox,
                label: 'Green House'
              },
              {
                name: 'optional-building-HayBarn',
                inputType: InputTypes.Checkbox,
                label: 'Hay Barn'
              },
              {
                name: 'optional-building-House',
                inputType: InputTypes.Checkbox,
                label: 'House'
              },
              {
                name: 'optional-building-ModularHome',
                inputType: InputTypes.Checkbox,
                label: 'Modular Home'
              },
              {
                name: 'optional-building-RidingArena',
                inputType: InputTypes.Checkbox,
                label: 'Riding Arena'
              },
              {
                name: 'optional-building-Silo',
                inputType: InputTypes.Checkbox,
                label: 'Silo(s)'
              },
              {
                name: 'optional-building-Stable',
                inputType: InputTypes.Checkbox,
                label: 'Stable'
              },
              {
                name: 'optional-building-StorageShed',
                inputType: InputTypes.Checkbox,
                label: 'Storage Shed'
              },
              {
                name: 'optional-building-TobaccoBarn',
                inputType: InputTypes.Checkbox,
                label: 'Tobacco Barn'
              },
              {
                name: 'optional-building-TractorShed',
                inputType: InputTypes.Checkbox,
                label: 'Tractor Shed'
              },
              {
                name: 'optional-building-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Other Structures',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.LandSale, PropertyTypes.HomeRent]  },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-building-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn'
              },
              {
                name: 'optional-building-BoatHouse',
                inputType: InputTypes.Checkbox,
                label: 'Boat House'
              },
              {
                name: 'optional-building-Corrals',
                inputType: InputTypes.Checkbox,
                label: 'Corral(s)'
              },
              {
                name: 'optional-building-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-building-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-building-GrainStorage',
                inputType: InputTypes.Checkbox,
                label: 'Grain Storage'
              },
              {
                name: 'optional-building-Greenhouse',
                inputType: InputTypes.Checkbox,
                label: 'Greenhouse'
              },
              {
                name: 'optional-building-GuestHouse',
                inputType: InputTypes.Checkbox,
                label: 'Guest House'
              },
              {
                name: 'optional-building-MobileHome',
                inputType: InputTypes.Checkbox,
                label: 'Mobile Home'
              },
              {
                name: 'optional-building-Outbuilding',
                inputType: InputTypes.Checkbox,
                label: 'Outbuilding'
              },              
              {
                name: 'optional-building-PackingShed',
                inputType: InputTypes.Checkbox,
                label: 'Packing Shed'
              },
              {
                name: 'optional-building-Pergola',
                inputType: InputTypes.Checkbox,
                label: 'Pergola'
              },
              {
                name: 'optional-building-PoolHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pool House'
              },
              {
                name: 'optional-building-RVBoatStorage',
                inputType: InputTypes.Checkbox,
                label: 'RV/Boat Storage'
              },
              {
                name: 'optional-building-SecondGarage',
                inputType: InputTypes.Checkbox,
                label: 'Second Garage'
              },
              {
                name: 'optional-building-SecondResidence',
                inputType: InputTypes.Checkbox,
                label: 'Second Residence'
              },
              {
                name: 'optional-building-Shed',
                inputType: InputTypes.Checkbox,
                label: 'Shed(s)'
              },
              {
                name: 'optional-building-Stable',
                inputType: InputTypes.Checkbox,
                label: 'Stable(s)'
              },
              {
                name: 'optional-building-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },           
              {
                name: 'optional-building-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Structures',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-structures-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn'
              },
              {
                name: 'optional-structures-ConcreteSlabPad',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Slab / Pad'
              },
              {
                name: 'optional-structures-MobileHome',
                inputType: InputTypes.Checkbox,
                label: 'Mobile Home'
              },
              {
                name: 'optional-structures-Residence',
                inputType: InputTypes.Checkbox,
                label: 'Residence'
              },
              {
                name: 'optional-structures-UtilityStorageBldg',
                inputType: InputTypes.Checkbox,
                label: 'Utility / Storage Bldg'
              },
              {
                name: 'optional-structures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Building / Structures',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-buildingStructures-Boathouse',
                inputType: InputTypes.Checkbox,
                label: 'Boathouse'
              },
              {
                name: 'optional-buildingStructures-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn'
              },
              {
                name: 'optional-buildingStructures-Cabin',
                inputType: InputTypes.Checkbox,
                label: 'Cabin'
              },
              {
                name: 'optional-buildingStructures-CornCrib',
                inputType: InputTypes.Checkbox,
                label: 'Corn Crib'
              },
              {
                name: 'optional-buildingStructures-Dairy',
                inputType: InputTypes.Checkbox,
                label: 'Dairy'
              },
              {
                name: 'optional-buildingStructures-FeedBarn',
                inputType: InputTypes.Checkbox,
                label: 'Feed Barn'
              },
              {
                name: 'optional-buildingStructures-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-buildingStructures-Greenhouse',
                inputType: InputTypes.Checkbox,
                label: 'Greenhouse'
              },
              {
                name: 'optional-buildingStructures-HayBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Hay Building'
              },
              {
                name: 'optional-buildingStructures-House',
                inputType: InputTypes.Checkbox,
                label: 'House'
              },
              {
                name: 'optional-buildingStructures-Livestock',
                inputType: InputTypes.Checkbox,
                label: 'Livestock'
              },
              {
                name: 'optional-buildingStructures-Manufactured',
                inputType: InputTypes.Checkbox,
                label: 'Manufactured'
              },
              {
                name: 'optional-buildingStructures-Modular',
                inputType: InputTypes.Checkbox,
                label: 'Modular'
              },
              {
                name: 'optional-buildingStructures-NoBuildings',
                inputType: InputTypes.Checkbox,
                label: 'No Buildings'
              },
              {
                name: 'optional-buildingStructures-PumpHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pump House'
              },
              {
                name: 'optional-buildingStructures-Shed',
                inputType: InputTypes.Checkbox,
                label: 'Shed'
              },
              {
                name: 'optional-buildingStructures-SmokeHouse',
                inputType: InputTypes.Checkbox,
                label: 'Smoke House'
              },
              {
                name: 'optional-buildingStructures-Stable',
                inputType: InputTypes.Checkbox,
                label: 'Stable'
              },
              {
                name: 'optional-buildingStructures-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
              {
                name: 'optional-buildingStructures-TackRoom',
                inputType: InputTypes.Checkbox,
                label: 'Tack Room'
              },
              {
                name: 'optional-buildingStructures-UtilityBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Utility Building'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Building Permit',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-buildingPermit-ApplicationPending',
                inputType: InputTypes.Checkbox,
                label: 'Application Pending'
              },
              {
                name: 'optional-buildingPermit-CostObtain',
                inputType: InputTypes.Checkbox,
                label: 'Cost to Obtain'
              },
              {
                name: 'optional-buildingPermit-ImpactFee',
                inputType: InputTypes.Checkbox,
                label: 'Impact Fee'
              },
              {
                name: 'optional-buildingPermit-NoBuildingPermits',
                inputType: InputTypes.Checkbox,
                label: 'No Building Permits'
              },
              {
                name: 'optional-buildingPermit-NoObtain',
                inputType: InputTypes.Checkbox,
                label: 'No to Obtain'
              },
              {
                name: 'optional-buildingPermit-NotObtainable',
                inputType: InputTypes.Checkbox,
                label: 'Not Obtainable'
              },
              {
                name: 'optional-buildingPermit-OnHand',
                inputType: InputTypes.Checkbox,
                label: 'On Hand'
              },
              {
                name: 'optional-buildingPermit-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              }
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Easement',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-easement-Access',
                inputType: InputTypes.Checkbox,
                label: 'Access'
              },
              {
                name: 'optional-easement-Buffer',
                inputType: InputTypes.Checkbox,
                label: 'Buffer'
              },
              {
                name: 'optional-easement-Drainage',
                inputType: InputTypes.Checkbox,
                label: 'Drainage'
              },
              {
                name: 'optional-easement-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-easement-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer'
              },
              {
                name: 'optional-easement-Utility',
                inputType: InputTypes.Checkbox,
                label: 'Utility'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Road Access',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-roadAccess-EasementOnly',
                inputType: InputTypes.Checkbox,
                label: 'Easement Only'
              },
              {
                name: 'optional-roadAccess-PrivateOnly',
                inputType: InputTypes.Checkbox,
                label: 'Private Only'
              },
              {
                name: 'optional-roadAccess-StateRoad',
                inputType: InputTypes.Checkbox,
                label: 'State Road'
              },
              {
                name: 'optional-roadAccess-Interstate',
                inputType: InputTypes.Checkbox,
                label: 'Interstate'
              },
              {
                name: 'optional-roadAccess-CountyRoad',
                inputType: InputTypes.Checkbox,
                label: 'County Road'
              },
              {
                name: 'optional-roadAccess-CityStreet',
                inputType: InputTypes.Checkbox,
                label: 'City Street'
              },
            ]
          },
          {
            groupTitle: 'Road Surface',
            fields: [
              {
                name: 'optional-roadSurface-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-roadSurface-Blacktop',
                inputType: InputTypes.Checkbox,
                label: 'Blacktop'
              },
              {
                name: 'optional-roadSurface-Gravel',
                inputType: InputTypes.Checkbox,
                label: 'Gravel'
              },
              {
                name: 'optional-roadSurface-Dirt',
                inputType: InputTypes.Checkbox,
                label: 'Dirt'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Road Frontage',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-roadFronatge-CityStreet',
                inputType: InputTypes.Checkbox,
                label: 'City Street'
              },
              {
                name: 'optional-roadFronatge-Dirt',
                inputType: InputTypes.Checkbox,
                label: 'Dirt'
              },
              {
                name: 'optional-roadFronatge-Gravel',
                inputType: InputTypes.Checkbox,
                label: 'Gravel'
              },
              {
                name: 'optional-roadFronatge-Interstate',
                inputType: InputTypes.Checkbox,
                label: 'Interstate'
              },
              {
                name: 'optional-roadFronatge-Paved',
                inputType: InputTypes.Checkbox,
                label: 'Paved'
              },
              {
                name: 'optional-roadFronatge-PrivateRoad',
                inputType: InputTypes.Checkbox,
                label: 'Private Road'
              },
              {
                name: 'optional-roadFronatge-ServiceRoad',
                inputType: InputTypes.Checkbox,
                label: 'Service Road'
              },
              {
                name: 'optional-roadFronatge-StateCountyRoad',
                inputType: InputTypes.Checkbox,
                label: 'State / County Road'
              },
              {
                name: 'optional-roadFronatge-USHighway',
                inputType: InputTypes.Checkbox,
                label: 'US Highway'
              },
              {
                name: 'optional-roadFronatge-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Utilities to Site',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-utilities-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity'
              },
              {
                name: 'optional-utilities-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-utilities-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water'
              },
              {
                name: 'optional-utilities-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer'
              },
              {
                name: 'optional-utilities-Phone',
                inputType: InputTypes.Checkbox,
                label: 'Phone'
              },
              {
                name: 'optional-utilities-TVCable',
                inputType: InputTypes.Checkbox,
                label: 'TV Cable'
              },
              {
                name: 'optional-utilities-Underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              },
              {
                name: 'optional-utilities-Utilities',
                inputType: InputTypes.Checkbox,
                label: 'Utilities'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterType-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-waterType-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'optional-waterType-Well',
                inputType: InputTypes.Checkbox,
                label: 'Well'
              },
              {
                name: 'optional-waterType-NoDevelopedWater',
                inputType: InputTypes.Checkbox,
                label: 'No Developed Water'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Sewer Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-sewerType-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'optional-sewerType-SepticInstalled',
                inputType: InputTypes.Checkbox,
                label: 'Septic Installed'
              },
              {
                name: 'optional-sewerType-SepticPermitRequired',
                inputType: InputTypes.Checkbox,
                label: 'Septic Permit Required'
              },
              {
                name: 'optional-sewerType-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-sewerType-SepticApproved',
                inputType: InputTypes.Checkbox,
                label: 'Septic Approved'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Utilities Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-utilities-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity'
              },
              {
                name: 'optional-utilities-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-utilities-NoUtilities',
                inputType: InputTypes.Checkbox,
                label: 'No Utilities'
              },
              {
                name: 'optional-utilities-SepticAtSite',
                inputType: InputTypes.Checkbox,
                label: 'Septic At Site'
              },
              {
                name: 'optional-utilities-SepticPermit',
                inputType: InputTypes.Checkbox,
                label: 'Septic Permit'
              },
              {
                name: 'optional-utilities-SewerAtSite',
                inputType: InputTypes.Checkbox,
                label: 'Sewer At Site'
              },
              {
                name: 'optional-utilities-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water'
              },
              {
                name: 'optional-utilities-VegetableTruckFarm',
                inputType: InputTypes.Checkbox,
                label: 'Vegetable/Truck Farm'
              },
              {
                name: 'optional-utilities-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Utilities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-utilities-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-utilities-AboveGround',
                inputType: InputTypes.Checkbox,
                label: 'Above Ground'
              },
              {
                name: 'optional-utilities-Butane',
                inputType: InputTypes.Checkbox,
                label: 'Butane'
              },
              {
                name: 'optional-utilities-CableTV',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV'
              },
              {
                name: 'optional-utilities-CableTVAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV Available'
              },
              {
                name: 'optional-utilities-DSLAvailable',
                inputType: InputTypes.Checkbox,
                label: 'DSL Available'
              },
              {
                name: 'optional-utilities-ElectricAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Electric Available'
              },
              {
                name: 'optional-utilities-FiberOpticsAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Fiber Optics Available'
              },
              {
                name: 'optional-utilities-MultiplePhoneLines',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Phone Lines'
              },
              {
                name: 'optional-utilities-NaturalGasAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas Available'
              },
              {
                name: 'optional-utilities-PhoneAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Phone Available'
              },
              {
                name: 'optional-utilities-PhoneNOTAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Phone NOT Available'
              },
              {
                name: 'optional-utilities-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'optional-utilities-SewerAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Sewer Available'
              },
              {
                name: 'optional-utilities-Underground',
                inputType: InputTypes.Checkbox,
                label: 'Underground'
              },
              {
                name: 'optional-utilities-WaterAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Water Available'
              }
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fencing',
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Triad]: [PropertyTypes.LandSale],
          [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fencing-BarbedWire',
                inputType: InputTypes.Checkbox,
                label: 'Barbed Wire'
              },
              {
                name: 'optional-fencing-ChainLink',
                inputType: InputTypes.Checkbox,
                label: 'Chain Link'
              },
              {
                name: 'optional-fencing-Concrete',
                inputType: InputTypes.Checkbox,
                label: 'Concrete'
              },
              {
                name: 'optional-fencing-CrossedFencing',
                inputType: InputTypes.Checkbox,
                label: 'Crossed Fencing'
              },
              {
                name: 'optional-fencing-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-fencing-Invisible',
                inputType: InputTypes.Checkbox,
                label: 'Invisible'
              },
              {
                name: 'optional-fencing-Masonry',
                inputType: InputTypes.Checkbox,
                label: 'Masonry'
              },
              {
                name: 'optional-fencing-NoFencing',
                inputType: InputTypes.Checkbox,
                label: 'No Fencing'
              },
              {
                name: 'optional-fencing-Partial',
                inputType: InputTypes.Checkbox,
                label: 'Partial'
              },
              {
                name: 'optional-fencing-Privacy',
                inputType: InputTypes.Checkbox,
                label: 'Privacy'
              },
              {
                name: 'optional-fencing-Rail',
                inputType: InputTypes.Checkbox,
                label: 'Rail'
              },
              {
                name: 'optional-fencing-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-fencing-TotallyFenced',
                inputType: InputTypes.Checkbox,
                label: 'Totally Fenced'
              },
              {
                name: 'optional-fencing-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-fencing-Wire',
                inputType: InputTypes.Checkbox,
                label: 'Wire'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fencing',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fencing-Brick',
                inputType: InputTypes.Checkbox,
                label: 'Brick'
              },
              {
                name: 'optional-fencing-ChainLink',
                inputType: InputTypes.Checkbox,
                label: 'Chain Link'
              },
              {
                name: 'optional-fencing-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-fencing-Full',
                inputType: InputTypes.Checkbox,
                label: 'Full'
              },
              {
                name: 'optional-fencing-Invisible',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                label: 'Invisible'
              },
              {
                name: 'optional-fencing-Wire',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Wire'
              },
              {
                name: 'optional-fencing-MetalOrnamental',
                inputType: InputTypes.Checkbox,
                label: 'Metal/Ornamental'
              },
              {
                name: 'optional-fencing-Partial',
                inputType: InputTypes.Checkbox,
                label: 'Partial'
              },
              {
                name: 'optional-fencing-RearOnly',
                inputType: InputTypes.Checkbox,
                label: 'Rear Only'
              },
              {
                name: 'optional-fencing-SplitRail',
                inputType: InputTypes.Checkbox,
                label: 'Split Rail'
              },
              {
                name: 'optional-fencing-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-fencing-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fencing',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fencing-AllFenced',
                inputType: InputTypes.Checkbox,
                label: 'All Fenced'
              },
              {
                name: 'optional-fencing-Barbed',
                inputType: InputTypes.Checkbox,
                label: 'Barbed'
              },
              {
                name: 'optional-fencing-Board',
                inputType: InputTypes.Checkbox,
                label: 'Board'
              },
              {
                name: 'optional-fencing-Cedar',
                inputType: InputTypes.Checkbox,
                label: 'Cedar'
              },
              {
                name: 'optional-fencing-ChainLink',
                inputType: InputTypes.Checkbox,
                label: 'Chain Link'
              },
              {
                name: 'optional-fencing-Decorative',
                inputType: InputTypes.Checkbox,
                label: 'Decorative'
              },
              {
                name: 'optional-fencing-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-fencing-FrontOnly',
                inputType: InputTypes.Checkbox,
                label: 'Front Only'
              },
              {
                name: 'optional-fencing-Invisible',
                inputType: InputTypes.Checkbox,
                label: 'Invisible'
              },
              {
                name: 'optional-fencing-PartFenced',
                inputType: InputTypes.Checkbox,
                label: 'Part Fenced'
              },
              {
                name: 'optional-fencing-Picket',
                inputType: InputTypes.Checkbox,
                label: 'Picket'
              },
              {
                name: 'optional-fencing-Privacy',
                inputType: InputTypes.Checkbox,
                label: 'Privacy'
              },
              {
                name: 'optional-fencing-RearOnly',
                inputType: InputTypes.Checkbox,
                label: 'Rear Only'
              },
              {
                name: 'optional-fencing-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-fencing-SplitRail',
                inputType: InputTypes.Checkbox,
                label: 'Split Rail'
              },
              {
                name: 'optional-fencing-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-fencing-Wall',
                inputType: InputTypes.Checkbox,
                label: 'Wall'
              },
              {
                name: 'optional-fencing-WroughtIron',
                inputType: InputTypes.Checkbox,
                label: 'Wrought Iron'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fencing',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fencing-Board',
                inputType: InputTypes.Checkbox,
                label: 'Board'
              },
              {
                name: 'optional-fencing-ChainLink',
                inputType: InputTypes.Checkbox,
                label: 'Chain Link'
              },
              {
                name: 'optional-fencing-Cyclone',
                inputType: InputTypes.Checkbox,
                label: 'Cyclone'
              },
              {
                name: 'optional-fencing-BarbedWire',
                inputType: InputTypes.Checkbox,
                label: 'Barbed Wire'
              },
              {
                name: 'optional-fencing-Decorative',
                inputType: InputTypes.Checkbox,
                label: 'Decorative'
              },
              {
                name: 'optional-fencing-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-fencing-Fully',
                inputType: InputTypes.Checkbox,
                label: 'Fully'
              },
              {
                name: 'optional-fencing-HighTensile',
                inputType: InputTypes.Checkbox,
                label: 'High Tensile'
              },
              {
                name: 'optional-fencing-Invisible',
                inputType: InputTypes.Checkbox,
                label: 'Invisible'
              },
              {
                name: 'optional-fencing-MasonryStone',
                inputType: InputTypes.Checkbox,
                label: 'Masonry/Stone'
              },
              {
                name: 'optional-fencing-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-fencing-Panel',
                inputType: InputTypes.Checkbox,
                label: 'Panel'
              },
              {
                name: 'optional-fencing-Partially',
                inputType: InputTypes.Checkbox,
                label: 'Partially'
              },
              {
                name: 'optional-fencing-Picket',
                inputType: InputTypes.Checkbox,
                label: 'Picket'
              },
              {
                name: 'optional-fencing-Privacy',
                inputType: InputTypes.Checkbox,
                label: 'Privacy'
              },
              {
                name: 'optional-fencing-Rear',
                inputType: InputTypes.Checkbox,
                label: 'Rear'
              },
              {
                name: 'optional-fencing-SplitRail',
                inputType: InputTypes.Checkbox,
                label: 'Split Rail'
              },
              {
                name: 'optional-fencing-Vinyl',
                inputType: InputTypes.Checkbox,
                label: 'Vinyl'
              },
              {
                name: 'optional-fencing-Wire',
                inputType: InputTypes.Checkbox,
                label: 'Wire'
              },
              {
                name: 'optional-fencing-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Other Sctructures',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-otherStructures-Boathouse',
                inputType: InputTypes.Checkbox,
                label: 'Boathouse'
              },
              {
                name: 'optional-otherStructures-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn'
              },
              {
                name: 'optional-otherStructures-Cabin',
                inputType: InputTypes.Checkbox,
                label: 'Cabin'
              },
              {
                name: 'optional-otherStructures-Cottage',
                inputType: InputTypes.Checkbox,
                label: 'Cottage'
              },
              {
                name: 'optional-otherStructures-FeedBarn',
                inputType: InputTypes.Checkbox,
                label: 'Feed Barn'
              },
              {
                name: 'optional-otherStructures-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage'
              },
              {
                name: 'optional-otherStructures-Greenhouse',
                inputType: InputTypes.Checkbox,
                label: 'Greenhouse'
              },
              {
                name: 'optional-otherStructures-HayBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Hay Building'
              },
              {
                name: 'optional-otherStructures-PumpHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pump House'
              },
              {
                name: 'optional-otherStructures-Shed',
                inputType: InputTypes.Checkbox,
                label: 'Shed'
              },
              {
                name: 'optional-otherStructures-Stable',
                inputType: InputTypes.Checkbox,
                label: 'Stable'
              },
              {
                name: 'optional-otherStructures-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
              {
                name: 'optional-otherStructures-TackRoom',
                inputType: InputTypes.Checkbox,
                label: 'Tack Room'
              },
              {
                name: 'optional-otherStructures-UtilityBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Utility Building'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Other Structures',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-otherStructures-2ndGarage',
                inputType: InputTypes.Checkbox,
                label: '2nd Garage'
              },
              {
                name: 'optional-otherStructures-2ndHouse',
                inputType: InputTypes.Checkbox,
                label: '2nd House'
              },
              {
                name: 'optional-otherStructures-BankBarn',
                inputType: InputTypes.Checkbox,
                label: 'Bank Barn'
              },
              {
                name: 'optional-otherStructures-Barn',
                inputType: InputTypes.Checkbox,
                label: 'Barn'
              },
              {
                name: 'optional-otherStructures-BarnFarmBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Barn / Farm Building'
              },
              {
                name: 'optional-otherStructures-BarnStable',
                inputType: InputTypes.Checkbox,
                label: 'Barn / Stable'
              },
              {
                name: 'optional-otherStructures-BoatHouse',
                inputType: InputTypes.Checkbox,
                label: 'Boat House'
              },
              {
                name: 'optional-otherStructures-CabanaPoolHouse',
                inputType: InputTypes.Checkbox,
                label: 'Cabana / Pool House'
              },
              {
                name: 'optional-otherStructures-CarriageHouse',
                inputType: InputTypes.Checkbox,
                label: 'Carriage House'
              },
              {
                name: 'optional-otherStructures-CattleDairyBarn',
                inputType: InputTypes.Checkbox,
                label: 'Cattle / Dairy Barn'
              },
              {
                name: 'optional-otherStructures-CornBarn',
                inputType: InputTypes.Checkbox,
                label: 'Corn Barn'
              },
              {
                name: 'optional-otherStructures-DairyBarn',
                inputType: InputTypes.Checkbox,
                label: 'Dairy Barn'
              },
              {
                name: 'optional-otherStructures-FeedBarn',
                inputType: InputTypes.Checkbox,
                label: 'Feed Barn'
              },
              {
                name: 'optional-otherStructures-Garage',
                inputType: InputTypes.Checkbox,
                label: 'Garage(s)'
              },
              {
                name: 'optional-otherStructures-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-otherStructures-Greenhouse',
                inputType: InputTypes.Checkbox,
                label: 'Greenhouse'
              },
              {
                name: 'optional-otherStructures-GuestHouse',
                inputType: InputTypes.Checkbox,
                label: 'Guest House'
              },
              {
                name: 'optional-otherStructures-HayBarn',
                inputType: InputTypes.Checkbox,
                label: 'Hay Barn'
              },
              {
                name: 'optional-otherStructures-HorsesAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Horses Allowed'
              },
              {
                name: 'optional-otherStructures-HorseStable',
                inputType: InputTypes.Checkbox,
                label: 'Horse Stable'
              },
              {
                name: 'optional-otherStructures-MachineShed',
                inputType: InputTypes.Checkbox,
                label: 'Machine Shed'
              },
              {
                name: 'optional-otherStructures-OfficeStudio',
                inputType: InputTypes.Checkbox,
                label: 'Office / Studio (Sep Structure)'
              },
              {
                name: 'optional-otherStructures-PackHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pack House'
              },
              {
                name: 'optional-otherStructures-PoultryHouse',
                inputType: InputTypes.Checkbox,
                label: 'Poultry House'
              },
              {
                name: 'optional-otherStructures-RidingRing',
                inputType: InputTypes.Checkbox,
                label: 'Riding Ring'
              },
              {
                name: 'optional-otherStructures-Shed',
                inputType: InputTypes.Checkbox,
                label: 'Shed'
              },
              {
                name: 'optional-otherStructures-ShedShop',
                inputType: InputTypes.Checkbox,
                label: 'Shed Shop'
              },
              {
                name: 'optional-otherStructures-Silo',
                inputType: InputTypes.Checkbox,
                label: 'Silo(s)'
              },
              {
                name: 'optional-otherStructures-StorageBarnShed',
                inputType: InputTypes.Checkbox,
                label: 'Storage Barn / Shed'
              },
              {
                name: 'optional-otherStructures-TenantHouse',
                inputType: InputTypes.Checkbox,
                label: 'Tenant House'
              },
              {
                name: 'optional-otherStructures-UtilityBuilding',
                inputType: InputTypes.Checkbox,
                label: 'Utility Building'
              },
              {
                name: 'optional-otherStructures-WoodMetalShed',
                inputType: InputTypes.Checkbox,
                label: 'Wood / Metal Shed'
              },
              {
                name: 'optional-otherStructures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Green Building Certificates',
        fieldsetDescription: 'MUST Be Able to Furnish Certificate to Buyer',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-greenBuildingCertificates-ENERGYSTARHome',
                inputType: InputTypes.Checkbox,
                label: 'ENERGY STAR Certified Home'
              },
              {
                name: 'optional-greenBuildingCertificates-HomeEnergyScore',
                inputType: InputTypes.Checkbox,
                label: 'Home Energy Score'
              },
              {
                name: 'optional-greenBuildingCertificates-IndoorAirPlus',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Air Plus'
              },
              {
                name: 'optional-greenBuildingCertificates-LEEDCertified',
                inputType: InputTypes.Checkbox,
                label: 'LEED For Homes Certified'
              },
              {
                name: 'optional-greenBuildingCertificates-NGBSCertification',
                inputType: InputTypes.Checkbox,
                label: 'NGBS Certification'
              },
              {
                name: 'optional-greenBuildingCertificates-NGBSSmallRemodel',
                inputType: InputTypes.Checkbox,
                label: 'NGBS Small Projects Remodel'
              },
              {
                name: 'optional-greenBuildingCertificates-NGBSWholeHomeRemodel',
                inputType: InputTypes.Checkbox,
                label: 'NGBS Whole-Home Remodel'
              },
              {
                name: 'optional-greenBuildingCertificates-PearlCertification',
                inputType: InputTypes.Checkbox,
                label: 'Pearl Certification'
              },
              {
                name: 'optional-greenBuildingCertificates-PHIUS',
                inputType: InputTypes.Checkbox,
                label: 'PHIUS+'
              },
              {
                name: 'optional-greenBuildingCertificates-WaterSense',
                inputType: InputTypes.Checkbox,
                label: 'WaterSense'
              },
              {
                name: 'optional-greenBuildingCertificates-ZeroEnergy',
                inputType: InputTypes.Checkbox,
                label: 'Zero Energy Ready Home'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Green Building Certificates',
        fieldsetDescription: 'MUST Be Able to Furnish Certificate to Buyer',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-greenBuildingCertificates-EarthcraftHouseConst',
                inputType: InputTypes.Checkbox,
                label: 'Earthcraft House Const.'
              },
              {
                name: 'optional-greenBuildingCertificates-ENERGYSTARHomes',
                inputType: InputTypes.Checkbox,
                label: 'ENERGY STAR Homes'
              },
              {
                name: 'optional-greenBuildingCertificates-EnvironmentsForLiving',
                inputType: InputTypes.Checkbox,
                label: 'Environments for Living'
              },
              {
                name: 'optional-greenBuildingCertificates-EPAIndoorAirPlus',
                inputType: InputTypes.Checkbox,
                label: 'EPA Indoor Air Plus'
              },
              {
                name: 'optional-greenBuildingCertificates-EPAWatersenseHomeConst',
                inputType: InputTypes.Checkbox,
                label: 'EPA Watersense Home Const.'
              },
              {
                name: 'optional-greenBuildingCertificates-GHBTGreenCertHomes',
                inputType: InputTypes.Checkbox,
                label: 'GHBT Green Cert Homes'
              },
              {
                name: 'optional-greenBuildingCertificates-GreenBuiltHomes',
                inputType: InputTypes.Checkbox,
                label: 'Green Built Homes'
              },
              {
                name: 'optional-greenBuildingCertificates-HERSRated',
                inputType: InputTypes.Checkbox,
                label: 'HERS Rated'
              },
              {
                name: 'optional-greenBuildingCertificates-LEEDHCertified',
                inputType: InputTypes.Checkbox,
                label: 'LEED-H Certified'
              },
              {
                name: 'optional-greenBuildingCertificates-NAHBCertifiedHome',
                inputType: InputTypes.Checkbox,
                label: 'NAHB Certified Home'
              },
              {
                name: 'optional-greenBuildingCertificates-NCHealthyBuiltHome',
                inputType: InputTypes.Checkbox,
                label: 'NC Healthy Built Home'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Garage / Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-garage-1CarGarage',
                inputType: InputTypes.Checkbox,
                label: '1 Car Garage'
              },
              {
                name: 'optional-garage-15CarGarage',
                inputType: InputTypes.Checkbox,
                label: '1.5 Car Garage'
              },
              {
                name: 'optional-garage-2CarGarage',
                inputType: InputTypes.Checkbox,
                label: '2 Car Garage'
              },
              {
                name: 'optional-garage-3CarGarage',
                inputType: InputTypes.Checkbox,
                label: '3 Car Garage'
              },
              {
                name: 'optional-garage-4CarGarage',
                inputType: InputTypes.Checkbox,
                label: '4 Car Garage'
              },
              {
                name: 'optional-garage-5CarGarage',
                inputType: InputTypes.Checkbox,
                label: '5 Car Garage'
              },
              {
                name: 'optional-garage-1CarCarport',
                inputType: InputTypes.Checkbox,
                label: '1 Car Carport'
              },
              {
                name: 'optional-garage-2CarCarport',
                inputType: InputTypes.Checkbox,
                label: '2 Car Carport'
              },
              {
                name: 'optional-garage-3CarCarport',
                inputType: InputTypes.Checkbox,
                label: '3 Car Carport'
              },
              {
                name: 'optional-garage-4CarCarport',
                inputType: InputTypes.Checkbox,
                label: '4 Car Carport'
              },
              {
                name: 'optional-garage-Attached',
                inputType: InputTypes.Checkbox,
                label: 'Attached'
              },
              {
                name: 'optional-garage-Detached',
                inputType: InputTypes.Checkbox,
                label: 'Detached'
              },
              {
                name: 'optional-garage-OffStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'Off-Street Parking'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Green Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-greenFeatures-BambooFloors',
                inputType: InputTypes.Checkbox,
                label: 'Bamboo Floors'
              },
              {
                name: 'optional-greenFeatures-EarthCraft',
                inputType: InputTypes.Checkbox,
                label: 'Earth Craft'
              },
              {
                name: 'optional-greenFeatures-EnergyStarAirFiltration',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Air Filtration'
              },
              {
                name: 'optional-greenFeatures-EnergyStarAppliance',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Appliance'
              },
              {
                name: 'optional-greenFeatures-EnergyStarCentralHeatAir',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Central Heat/Air'
              },
              {
                name: 'optional-greenFeatures-EnergyStarInsul',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Insul'
              },
              {
                name: 'optional-greenFeatures-EnergyStarRoofing',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Roofing'
              },
              {
                name: 'optional-greenFeatures-EnergyStarWindows',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Windows'
              },
              {
                name: 'optional-greenFeatures-HERSRated',
                inputType: InputTypes.Checkbox,
                label: 'HERS Rated'
              },
              {
                name: 'optional-greenFeatures-LEEDCertified',
                inputType: InputTypes.Checkbox,
                label: 'LEED Certified'
              },
              {
                name: 'optional-greenFeatures-RainwaterCollWaterMgt',
                inputType: InputTypes.Checkbox,
                label: 'Rainwater Coll/Water Mgt'
              },
              {
                name: 'optional-greenFeatures-TanklessWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Tankless Water Heater'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Green Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-greenFeatures-EnergyEfficientAppli',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Appliances'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientConstruction',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Construction'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientExposure',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Exposure/Shade'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientHVAC',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent HVAC'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientInsulation',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Insulation'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientLighting',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Lighting'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientRoof',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Roof'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientThermostat',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Thermostat'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Water Heater'
              },
              {
                name: 'optional-greenFeatures-EnergyEfficientWindows',
                inputType: InputTypes.Checkbox,
                label: 'Enery Efficent Windows'
              },
              {
                name: 'optional-greenFeatures-Solar',
                inputType: InputTypes.Checkbox,
                label: 'Solar Power'
              },
              {
                name: 'optional-greenFeatures-WindPower',
                inputType: InputTypes.Checkbox,
                label: 'Wind Power'
              },
              {
                name: 'optional-greenFeatures-IndoorMoistureControl',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Moisture Control'
              },
              {
                name: 'optional-greenFeatures-BuiltRecycledMaterials',
                inputType: InputTypes.Checkbox,
                label: 'Built With Recycled Materials'
              },
              {
                name: 'optional-greenFeatures-BuiltRenewableMaterials',
                inputType: InputTypes.Checkbox,
                label: 'Built With Renewable Materials'
              },
              {
                name: 'optional-greenFeatures-LowFlowFixtures',
                inputType: InputTypes.Checkbox,
                label: 'Low-Flow Fixtures'
              },
              {
                name: 'optional-greenFeatures-WaterSmartLandscaping',
                inputType: InputTypes.Checkbox,
                label: 'Water-Smart Landscaping'
              },             
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Green Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-greenFeatures-ActiveSolarDesignConst',
                inputType: InputTypes.Checkbox,
                label: 'Active Solar Design Const.'
              },
              {
                name: 'optional-greenFeatures-DroughtResLandscape',
                inputType: InputTypes.Checkbox,
                label: 'Drought Res Landscape'
              },
              {
                name: 'optional-greenFeatures-ElectricCarCharging',
                inputType: InputTypes.Checkbox,
                label: 'Electric Car Charging'
              },
              {
                name: 'optional-greenFeatures-ENERGYSTARAppliances',
                inputType: InputTypes.Checkbox,
                label: 'ENERGY STAR Appliances'
              },
              {
                name: 'optional-greenFeatures-EPAWaterSensePlmbFixt',
                inputType: InputTypes.Checkbox,
                label: 'EPA WaterSense Plmb Fixt'
              },
              {
                name: 'optional-greenFeatures-FreshAirVentilation',
                inputType: InputTypes.Checkbox,
                label: 'Fresh Air Ventilation'
              },
              {
                name: 'optional-greenFeatures-GeoThermalHeatSystem',
                inputType: InputTypes.Checkbox,
                label: 'Geo Thermal Heat System'
              },
              {
                name: 'optional-greenFeatures-HighEfficientToilets',
                inputType: InputTypes.Checkbox,
                label: 'High Efficient Toilets'
              },
              {
                name: 'optional-greenFeatures-LEDLighting',
                inputType: InputTypes.Checkbox,
                label: 'LED Lighting'
              },
              {
                name: 'optional-greenFeatures-LowVOCPaintsSealVar',
                inputType: InputTypes.Checkbox,
                label: 'Low VOC Paints/Seal/Var'
              },
              {
                name: 'optional-greenFeatures-PassiveSolarDesign',
                inputType: InputTypes.Checkbox,
                label: 'Passive Solar Design'
              },
              {
                name: 'optional-greenFeatures-ProgrammableThermostats',
                inputType: InputTypes.Checkbox,
                label: 'Programmable Thermostats'
              },
              {
                name: 'optional-greenFeatures-RadiantHeatFloors',
                inputType: InputTypes.Checkbox,
                label: 'Radiant Heat Floors'
              },
              {
                name: 'optional-greenFeatures-RainwaterCollection',
                inputType: InputTypes.Checkbox,
                label: 'Rainwater Collection'
              },
              {
                name: 'optional-greenFeatures-SealedAttic',
                inputType: InputTypes.Checkbox,
                label: 'Sealed Attic'
              },
              {
                name: 'optional-greenFeatures-SealedCombustionFP',
                inputType: InputTypes.Checkbox,
                label: 'Sealed Combustion FP'
              },
              {
                name: 'optional-greenFeatures-SealedCrawlSpace',
                inputType: InputTypes.Checkbox,
                label: 'Sealed Crawl Space'
              },
              {
                name: 'optional-greenFeatures-SolarActiveHeating',
                inputType: InputTypes.Checkbox,
                label: 'Solar Active Heating'
              },
              {
                name: 'optional-greenFeatures-SolarHotWater',
                inputType: InputTypes.Checkbox,
                label: 'Solar Hot Water'
              },
              {
                name: 'optional-greenFeatures-SolarPassHeat',
                inputType: InputTypes.Checkbox,
                label: 'Solar Pass Heat'
              },
              {
                name: 'optional-greenFeatures-SolarPhotovoltaic',
                inputType: InputTypes.Checkbox,
                label: 'Solar Photovoltaic'
              },
              {
                name: 'optional-greenFeatures-TanklessWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'Tankless Water Heater'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Green Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-greenFeatures-AdvFraming',
                inputType: InputTypes.Checkbox,
                label: 'Advanced Framing'
              },
              {
                name: 'optional-greenFeatures-ConcreteConstruction',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Construction'
              },
              {
                name: 'optional-greenFeatures-EngineeredWoodProducts',
                inputType: InputTypes.Checkbox,
                label: 'Engineered Wood Products'
              },
              {
                name: 'optional-greenFeatures-LowVOCCoating',
                inputType: InputTypes.Checkbox,
                label: 'Low VOC Coatings'
              },
              {
                name: 'optional-greenFeatures-NoVOCCoating',
                inputType: InputTypes.Checkbox,
                label: 'No VOC Coatings'
              },
              {
                name: 'optional-greenFeatures-PhotovoltaicSolarPower',
                inputType: InputTypes.Checkbox,
                label: 'Photovoltaic - Solar Power'
              },
              {
                name: 'optional-greenFeatures-RecycledMaterials',
                inputType: InputTypes.Checkbox,
                label: 'Recycled Materials'
              },
              {
                name: 'optional-greenFeatures-SalvagedMaterials',
                inputType: InputTypes.Checkbox,
                label: 'Salvaged Materials'
              },
              {
                name: 'optional-greenFeatures-SprayFoamInsulation',
                inputType: InputTypes.Checkbox,
                label: 'Spray Foam Insulation'
              },
              {
                name: 'optional-greenFeatures-XeriscapingDroughtResistPlnts',
                inputType: InputTypes.Checkbox,
                label: 'Xeriscaping-Drought Resist. Plants'
              },
              {
                name: 'optional-greenFeatures-EarthCraftHouse',
                inputType: InputTypes.Checkbox,
                label: 'EarthCraft House'
              },
              {
                name: 'optional-greenFeatures-EnergyStarCertified',
                inputType: InputTypes.Checkbox,
                label: 'Energy Star Certified'
              },
              {
                name: 'optional-greenFeatures-EnvironLiving',
                inputType: InputTypes.Checkbox,
                label: 'Environments for Living'
              },
              {
                name: 'optional-greenFeatures-GreenBuiltHomes',
                inputType: InputTypes.Checkbox,
                label: 'Green Built Homes'
              },
              {
                name: 'optional-greenFeatures-HERSIndex',
                inputType: InputTypes.Checkbox,
                label: 'HERS Index Score'
              },
              {
                name: 'optional-greenFeatures-LEEDHome',
                inputType: InputTypes.Checkbox,
                label: 'LEED for Homes'
              },
              {
                name: 'optional-greenFeatures-NGBSNationalGreenBuildingStandard',
                inputType: InputTypes.Checkbox,
                label: 'NGBS National Green Building Standard'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Roof',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-roof-Aluminum',
                inputType: InputTypes.Checkbox,
                label: 'Aluminum'
              },
              {
                name: 'optional-roof-ArchitecturalShingle',
                inputType: InputTypes.Checkbox,
                label: 'Architectural Shingle'
              },
              {
                name: 'optional-roof-AsbestosShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos Shingle'
              },
              {
                name: 'optional-roof-BuiltUp',
                inputType: InputTypes.Checkbox,
                label: 'Built Up'
              },
              {
                name: 'optional-roof-Composition',
                inputType: InputTypes.Checkbox,
                label: 'Composition'
              },
              {
                name: 'optional-roof-Fiberglass',
                inputType: InputTypes.Checkbox,
                label: 'Fiberglass Shingle'
              },
              {
                name: 'optional-roof-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },
              {
                name: 'optional-roof-Insulated',
                inputType: InputTypes.Checkbox,
                label: 'Insulated'
              },
              {
                name: 'optional-roof-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'optional-roof-Rubber',
                inputType: InputTypes.Checkbox,
                label: 'Rubber'
              },
              {
                name: 'optional-roof-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-roof-TarGravel',
                inputType: InputTypes.Checkbox,
                label: 'Tar and Gravel'
              },
              {
                name: 'optional-roof-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
               {
                name: 'optional-roof-WoodShingle',
                inputType: InputTypes.Checkbox,
                label: 'Wood Shingle'
              },
              {
                name: 'optional-roof-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Roof',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-roof-Architectural',
                inputType: InputTypes.Checkbox,
                label: 'Architectural'
              },
              {
                name: 'optional-roof-AsbestosShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos Shingle'
              },
              {
                name: 'optional-roof-AsphaltShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt Shingle'
              },
              {
                name: 'optional-roof-BuiltUp',
                inputType: InputTypes.Checkbox,
                label: 'Built Up'
              },
              {
                name: 'optional-roof-Copper',
                inputType: InputTypes.Checkbox,
                label: 'Copper'
              },
              {
                name: 'optional-roof-FiberglassShingle',
                inputType: InputTypes.Checkbox,
                label: 'Fiberglass Shingle'
              },
              {
                name: 'optional-roof-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'optional-roof-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-roof-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-roof-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'optional-roof-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Roof',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-roof-ArchitecturalShingle',
                inputType: InputTypes.Checkbox,
                label: 'Architectural Shingle'
              },
              {
                name: 'optional-roof-AsbestosShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos Shingle'
              },
              {
                name: 'optional-roof-BuiltUp',
                inputType: InputTypes.Checkbox,
                label: 'Built Up'
              },
              {
                name: 'optional-roof-CompositeShingle',
                inputType: InputTypes.Checkbox,
                label: 'Composite Shingle'
              },
              {
                name: 'optional-roof-FiberglassShingle',
                inputType: InputTypes.Checkbox,
                label: 'Fiberglass Shingle'
              },
              {
                name: 'optional-roof-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },
              {
                name: 'optional-roof-Insulated',
                inputType: InputTypes.Checkbox,
                label: 'Insulated'
              },
              {
                name: 'optional-roof-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'optional-roof-RolledRubber',
                inputType: InputTypes.Checkbox,
                label: 'Rolled Rubber'
              },
              {
                name: 'optional-roof-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-roof-TarGravel',
                inputType: InputTypes.Checkbox,
                label: 'Tar and Gravel'
              },
              {
                name: 'optional-roof-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-roof-WoodShake',
                inputType: InputTypes.Checkbox,
                label: 'Wood Shake'
              },
              {
                name: 'optional-roof-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Roof',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-roof-ArchitecturalShingle',
                inputType: InputTypes.Checkbox,
                label: 'Architectural Shingle'
              },
              {
                name: 'optional-roof-AsbestosShingle',
                inputType: InputTypes.Checkbox,
                label: 'Asbestos Shingle'
              },
              {
                name: 'optional-roof-Asphalt',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt'
              },
              {
                name: 'optional-roof-BuiltUp',
                inputType: InputTypes.Checkbox,
                label: 'Built Up'
              },
              {
                name: 'optional-roof-Composite',
                inputType: InputTypes.Checkbox,
                label: 'Composite'
              },
              {
                name: 'optional-roof-CoolWhite',
                inputType: InputTypes.Checkbox,
                label: 'Cool / White'
              },
              {
                name: 'optional-roof-Copper',
                inputType: InputTypes.Checkbox,
                label: 'Copper'
              },
              {
                name: 'optional-roof-Fiberglass',
                inputType: InputTypes.Checkbox,
                label: 'Fiberglass'
              },
              {
                name: 'optional-roof-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },
              {
                name: 'optional-roof-Foam',
                inputType: InputTypes.Checkbox,
                label: 'Foam'
              },
              {
                name: 'optional-roof-HipRoof',
                inputType: InputTypes.Checkbox,
                label: 'Hip Roof'
              },
              {
                name: 'optional-roof-Metal',
                inputType: InputTypes.Checkbox,
                label: 'Metal'
              },
              {
                name: 'optional-roof-Pitched',
                inputType: InputTypes.Checkbox,
                label: 'Pitched'
              },
              {
                name: 'optional-roof-Rubber',
                inputType: InputTypes.Checkbox,
                label: 'Rubber'
              },
              {
                name: 'optional-roof-Shake',
                inputType: InputTypes.Checkbox,
                label: 'Shake'
              },
              {
                name: 'optional-roof-Slate',
                inputType: InputTypes.Checkbox,
                label: 'Slate'
              },
              {
                name: 'optional-roof-TarAndGravel',
                inputType: InputTypes.Checkbox,
                label: 'Tar and Gravel'
              },
              {
                name: 'optional-roof-Tile',
                inputType: InputTypes.Checkbox,
                label: 'Tile'
              },
              {
                name: 'optional-roof-VegetatedRoof',
                inputType: InputTypes.Checkbox,
                label: 'Vegetated Roof'
              },
              {
                name: 'optional-roof-WoodShingle',
                inputType: InputTypes.Checkbox,
                label: 'Wood Shingle'
              },
              {
                name: 'optional-roof-Unknown',
                inputType: InputTypes.Checkbox,
                label: 'Unknown'
              },
            ]
          }
        ]
      },
    ]
  },
  {
    sectionTitle: 'Square Footage',
    scope: Scopes.IndividualMapping,
    mapping: { 
      [Areas.Charlotte]: [PropertyTypes.HomeRent],
      [Areas.Triangle]: [PropertyTypes.HomeRent],
      [Areas.Triad]: [PropertyTypes.HomeRent],
      [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
    },
    fieldsets: [
      {
        fieldsetTitle: '',
        fieldsetDescription: `
          <a href="/info/residential-square-footage" class="link" 
          target="_blank">Click Here</a> for Square Footage Guidelines
        `,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'optional-livingAreaAboveGround',
            inputType: InputTypes.Text,
            label: 'Living Area Above Ground'
          },
          {
            name: 'optional-livingAreaBelowGround',
            inputType: InputTypes.Text,
            label: 'Living Area Below Ground'
          },
          {
            name: 'optional-otherAreaAboveGround',
            inputType: InputTypes.Text,
            label: 'Other Area Above Ground'
          },
          {
            name: 'optional-otherAreaBelowGround',
            inputType: InputTypes.Text,
            label: 'Other Area Below Ground'
          },
        ]
      },
      {
        fieldsetTitle: '',
        fieldsetDescription: `
          <a href="/info/residential-square-footage" class="link" 
          target="_blank">Click Here</a> for Square Footage Guidelines
        `,
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        fields: [
          {
            name: 'optional-mainLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Main Level Heated Square Footage'
          },
          {
            name: 'optional-upperLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Upper Level Heated Square Footage'
          },
          {
            name: 'optional-thirdLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Third Level Heated Square Footage'
          },
          {
            name: 'optional-lowerLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Lower Level Heated Square Footage'
          },
          {
            name: 'optional-basementLevelHeatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Basement Level Heated Square Footage'
          },
          {
            name: 'optional-totalUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Total UNHeated Square Footage'
          },
          {
            name: 'optional-mainLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Main Level UNHeated Square Footage'
          },
          {
            name: 'optional-upperLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Upper Level UNHeated Square Footage'
          },
          {
            name: 'optional-thirdLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Third Level UNHeated Square Footage'
          },
          {
            name: 'optional-lowerLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Lower Level UNHeated Square Footage'
          },
          {
            name: 'optional-basementLevelUnheatedSquareFootage',
            inputType: InputTypes.Text,
            label: 'Basement Level UNHeated Square Footage'
          },
        ]
      },
      {
        fieldsetTitle: 'Style of Home',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-styleOfHome-1',
                inputType: InputTypes.Checkbox,
                label: 'A-Frame',
              },
              {
                name: 'optional-styleOfHome-2',
                inputType: InputTypes.Checkbox,
                label: 'Arts and Crafts',
              },
              {
                name: 'optional-styleOfHome-3',
                inputType: InputTypes.Checkbox,
                label: 'Cabin',
              },
              {
                name: 'optional-styleOfHome-4',
                inputType: InputTypes.Checkbox,
                label: 'Cape Cod',
              },
              {
                name: 'optional-styleOfHome-5',
                inputType: InputTypes.Checkbox,
                label: 'Charleston',
              },
              {
                name: 'optional-styleOfHome-6',
                inputType: InputTypes.Checkbox,
                label: 'Colonial',
              },
              {
                name: 'optional-styleOfHome-7',
                inputType: InputTypes.Checkbox,
                label: 'Cottage / Bungalow',
              },
              {
                name: 'optional-styleOfHome-8',
                inputType: InputTypes.Checkbox,
                label: 'Contemporary',
              },
              {
                name: 'optional-styleOfHome-9',
                inputType: InputTypes.Checkbox,
                label: 'French Provincial',
              },
              {
                name: 'optional-styleOfHome-10',
                inputType: InputTypes.Checkbox,
                label: 'Garden',
              },
              {
                name: 'optional-styleOfHome-11',
                inputType: InputTypes.Checkbox,
                label: 'Georgian',
              },
              {
                name: 'optional-styleOfHome-12',
                inputType: InputTypes.Checkbox,
                label: 'Post and Beam',
              },
              {
                name: 'optional-styleOfHome-13',
                inputType: InputTypes.Checkbox,
                label: 'Ranch',
              },
              {
                name: 'optional-styleOfHome-14',
                inputType: InputTypes.Checkbox,
                label: 'Rustic',
              },
              {
                name: 'optional-styleOfHome-15',
                inputType: InputTypes.Checkbox,
                label: 'Spanish',
              },
              {
                name: 'optional-styleOfHome-16',
                inputType: InputTypes.Checkbox,
                label: 'Traditional',
              },
              {
                name: 'optional-styleOfHome-17',
                inputType: InputTypes.Checkbox,
                label: 'Transitional',
              },
              {
                name: 'optional-styleOfHome-18',
                inputType: InputTypes.Checkbox,
                label: 'Tudor',
              },
              {
                name: 'optional-styleOfHome-19',
                inputType: InputTypes.Checkbox,
                label: 'Victorian',
              },
              {
                name: 'optional-styleOfHome-20',
                inputType: InputTypes.Checkbox,
                label: 'Williamsburg',
              },
              {
                name: 'optional-styleOfHome-21',
                inputType: InputTypes.Checkbox,
                label: 'Other',
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Parking',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-parkingFeatures-Assigned',
                inputType: InputTypes.Checkbox,
                label: 'Assigned'
              },   
              {
                name: 'optional-parkingFeatures-BasementGarage',
                inputType: InputTypes.Checkbox,
                label: 'Basement Garage'
              },  
              {
                name: 'optional-parkingFeatures-CarportAttached',
                inputType: InputTypes.Checkbox,
                label: 'Carport Attached'
              }, 
              {
                name: 'optional-parkingFeatures-CarportDetached',
                inputType: InputTypes.Checkbox,
                label: 'Carport Detached'
              },   
              {
                name: 'optional-parkingFeatures-1CarCarport',
                inputType: InputTypes.Checkbox,
                label: '1 Car Carport'
              },  
              {
                name: 'optional-parkingFeatures-2CarCarport',
                inputType: InputTypes.Checkbox,
                label: '2 Car Carport'
              },  
              {
                name: 'optional-parkingFeatures-3CarCarport',
                inputType: InputTypes.Checkbox,
                label: '3 Car Carport'
              },  
              {
                name: 'optional-parkingFeatures-4PlusCarCarport',
                inputType: InputTypes.Checkbox,
                label: '4+ Car Carport'
              },  
              {
                name: 'optional-parkingFeatures-Driveway',
                inputType: InputTypes.Checkbox,
                label: 'Driveway'
              },  
              {
                name: 'optional-parkingFeatures-ElectricCharge',
                inputType: InputTypes.Checkbox,
                label: 'Electric Veh. Charging Station'
              },  
              {
                name: 'optional-parkingFeatures-GarageAttached',
                inputType: InputTypes.Checkbox,
                label: 'Garage Attached'
              },  
              {
                name: 'optional-parkingFeatures-GarageDetached',
                inputType: InputTypes.Checkbox,
                label: 'Garage Detached'
              },  
              {
                name: 'optional-parkingFeatures-1CarGarage',
                inputType: InputTypes.Checkbox,
                label: '1 Car Garage'
              },  
              {
                name: 'optional-parkingFeatures-2CarGarage',
                inputType: InputTypes.Checkbox,
                label: '2 Car Garage'
              },  
              {
                name: 'optional-parkingFeatures-3CarGarage',
                inputType: InputTypes.Checkbox,
                label: '3 Car Garage'
              },  
              {
                name: 'optional-parkingFeatures-4PlusCarGarage',
                inputType: InputTypes.Checkbox,
                label: '4+ Car Garage'
              },  
              {
                name: 'optional-parkingFeatures-GarageDoorOpener',
                inputType: InputTypes.Checkbox,
                label: 'Garage Door Opener'
              },  
              {
                name: 'optional-parkingFeatures-GarageFacesFront',
                inputType: InputTypes.Checkbox,
                label: 'Garage Faces Front'
              },  
              {
                name: 'optional-parkingFeatures-GarageFacesRear',
                inputType: InputTypes.Checkbox,
                label: 'Garage Faces Rear'
              },  
              {
                name: 'optional-parkingFeatures-GarageFacesSide',
                inputType: InputTypes.Checkbox,
                label: 'Garage Faces Side'
              },  
              {
                name: 'optional-parkingFeatures-GarageShop',
                inputType: InputTypes.Checkbox,
                label: 'Garage Shop'
              },  
              {
                name: 'optional-parkingFeatures-GolfCartGarage',
                inputType: InputTypes.Checkbox,
                label: 'Golf Cart Garage'
              },  
              {
                name: 'optional-parkingFeatures-KeypadEntry',
                inputType: InputTypes.Checkbox,
                label: 'Keypad Entry'
              },  
              {
                name: 'optional-parkingFeatures-OnStreetParking',
                inputType: InputTypes.Checkbox,
                label: 'On Street Parking'
              },  
              {
                name: 'optional-parkingFeatures-ParkingDeck',
                inputType: InputTypes.Checkbox,
                label: 'Parking Deck'
              },  
              {
                name: 'optional-parkingFeatures-ParkingGarage',
                inputType: InputTypes.Checkbox,
                label: 'Parking Garage'
              },  
              {
                name: 'optional-parkingFeatures-ParkingLot',
                inputType: InputTypes.Checkbox,
                label: 'Parking Lot'
              },  
              {
                name: 'optional-parkingFeatures-ParkingSpaces',
                inputType: InputTypes.Checkbox,
                label: 'Parking Spaces'
              },  
              {
                name: 'optional-parkingFeatures-RVParking',
                inputType: InputTypes.Checkbox,
                label: 'RV Access / Parking'
              },  
              {
                name: 'optional-parkingFeatures-SharedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Shared Driveway'
              },  
              {
                name: 'optional-parkingFeatures-TandemGarage',
                inputType: InputTypes.Checkbox,
                label: 'Tandem Garage'
              },  
              {
                name: 'optional-parkingFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },  
              {
                name: 'optional-parkingFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              }, 
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Driveway Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-drivewayFeatures-AsphaltDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Asphalt Driveway'
              },
              {
                name: 'optional-drivewayFeatures-BrickDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Brick Driveway'
              },
              {
                name: 'optional-drivewayFeatures-CobblestoneDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Cobblestone Driveway'
              },
              {
                name: 'optional-drivewayFeatures-ConcreteDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Concrete Driveway'
              },
              {
                name: 'optional-drivewayFeatures-EarthDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Earth Driveway'
              },
              {
                name: 'optional-drivewayFeatures-GatedDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Gated Driveway'
              },
              {
                name: 'optional-drivewayFeatures-GravelDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Gravel Driveway'
              },
              {
                name: 'optional-drivewayFeatures-StoneDriveway',
                inputType: InputTypes.Checkbox,
                label: 'Stone Driveway'
              },
              {
                name: 'optional-drivewayFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-drivewayFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heating and Cooling',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-heatSource-CentralHeat',
                inputType: InputTypes.Checkbox,
                label: 'Central Heat'
              },
              {
                name: 'optional-heatSource-Baseboard',
                inputType: InputTypes.Checkbox,
                label: 'Baseboard'
              },
              {
                name: 'optional-heatSource-ForcedAir',
                inputType: InputTypes.Checkbox,
                label: 'Forced Air'
              },
              {
                name: 'optional-heatSource-SpaceHeater',
                inputType: InputTypes.Checkbox,
                label: 'Space Heater'
              },
              {
                name: 'optional-heatSource-CentralAir',
                inputType: InputTypes.Checkbox,
                label: 'Central Air'
              },
              {
                name: 'optional-heatSource-WindowWallUnit',
                inputType: InputTypes.Checkbox,
                label: 'Window/Wall Unit'
              },
              {
                name: 'optional-heatSource-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-heatSource-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-heatSource-Oil',
                inputType: InputTypes.Checkbox,
                label: 'Oil'
              },
              {
                name: 'optional-heatSource-Propane',
                inputType: InputTypes.Checkbox,
                label: 'Propane'
              },
              {
                name: 'optional-heatSource-WoodCoal',
                inputType: InputTypes.Checkbox,
                label: 'Wood/Coal'
              },
              {
                name: 'optional-heatSource-Solar',
                inputType: InputTypes.Checkbox,
                label: 'Solar'
              },
              {
                name: 'optional-heatSource-NoHeat',
                inputType: InputTypes.Checkbox,
                label: 'No Heat'
              },
              {
                name: 'optional-heatSource-NoCool',
                inputType: InputTypes.Checkbox,
                label: 'No Cool'
              },
              {
                name: 'optional-heatSource-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-heatSource-Geothermal',
                inputType: InputTypes.Checkbox,
                label: 'Geothermal'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heat Source',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-heatSource-ActiveSolar',
                inputType: InputTypes.Checkbox,
                label: 'Active Solar'
              },
              {
                name: 'optional-heatSource-Boiler',
                inputType: InputTypes.Checkbox,
                label: 'Boiler'
              },
              {
                name: 'optional-heatSource-Circulator',
                inputType: InputTypes.Checkbox,
                label: 'Circulator'
              },
              {
                name: 'optional-heatSource-CirculatorHotWater',
                inputType: InputTypes.Checkbox,
                label: 'Circulator Hot Water'
              },
              {
                name: 'optional-heatSource-DualFuelSystem',
                inputType: InputTypes.Checkbox,
                label: 'Dual Fuel System'
              },
              {
                name: 'optional-heatSource-ElectricBaseboard',
                inputType: InputTypes.Checkbox,
                label: 'Electric Baseboard'
              },
              {
                name: 'optional-heatSource-ElectricCeiling',
                inputType: InputTypes.Checkbox,
                label: 'Electric Ceiling'
              },
              {
                name: 'optional-heatSource-Fireplace',
                inputType: InputTypes.Checkbox,
                label: 'Fireplace'
              },
              {
                name: 'optional-heatSource-FloorFurnace',
                inputType: InputTypes.Checkbox,
                label: 'Floor Furnace'
              },
              {
                name: 'optional-heatSource-ForcedWarmAir',
                inputType: InputTypes.Checkbox,
                label: 'Forced Warm Air'
              },
              {
                name: 'optional-heatSource-GeoThermal',
                inputType: InputTypes.Checkbox,
                label: 'Geo-Thermal'
              },
              {
                name: 'optional-heatSource-GroundWaterHeatPump',
                inputType: InputTypes.Checkbox,
                label: 'Ground-Water Heat Pump'
              },
              {
                name: 'optional-heatSource-HeatPump',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump'
              },
              {
                name: 'optional-heatSource-MultiZone',
                inputType: InputTypes.Checkbox,
                label: 'Multi-Zone'
              },
              {
                name: 'optional-heatSource-MultipleSystems',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Systems'
              },
              {
                name: 'optional-heatSource-NoHeat',
                inputType: InputTypes.Checkbox,
                label: 'No Heat'
              },
              {
                name: 'optional-heatSource-PassiveSolar',
                inputType: InputTypes.Checkbox,
                label: 'Passive Solar'
              },
              {
                name: 'optional-heatSource-RadiantFloor',
                inputType: InputTypes.Checkbox,
                label: 'Radiant Floor'
              },
              {
                name: 'optional-heatSource-Radiator',
                inputType: InputTypes.Checkbox,
                label: 'Radiator'
              },
              {
                name: 'optional-heatSource-SuspendedCeilingHeat',
                inputType: InputTypes.Checkbox,
                label: 'Suspended Ceiling Heat'
              },
              {
                name: 'optional-heatSource-WallFurnace',
                inputType: InputTypes.Checkbox,
                label: 'Wall Furnace'
              },
              {
                name: 'optional-heatSource-WoodFurnace',
                inputType: InputTypes.Checkbox,
                label: 'Wood Furnace'
              },
              {
                name: 'optional-heatSource-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heat Source',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-heatSource-3Zone',
                inputType: InputTypes.Checkbox,
                label: '3 Zone'
              },
              {
                name: 'optional-heatSource-Baseboard',
                inputType: InputTypes.Checkbox,
                label: 'Baseboard'
              },
              {
                name: 'optional-heatSource-Circulator',
                inputType: InputTypes.Checkbox,
                label: 'Circulator'
              },
              {
                name: 'optional-heatSource-DualZoneHeat',
                inputType: InputTypes.Checkbox,
                label: 'Dual Zone Heat'
              },
              {
                name: 'optional-heatSource-FloorFurnace',
                inputType: InputTypes.Checkbox,
                label: 'Floor Furnace'
              },
              {
                name: 'optional-heatSource-ForcedAir',
                inputType: InputTypes.Checkbox,
                label: 'Forced Air'
              },
              {
                name: 'optional-heatSource-HotWater',
                inputType: InputTypes.Checkbox,
                label: 'Hot Water'
              },
              {
                name: 'optional-heatSource-Radiant',
                inputType: InputTypes.Checkbox,
                label: 'Radiant'
              },
              {
                name: 'optional-heatSource-Steam',
                inputType: InputTypes.Checkbox,
                label: 'Steam'
              },
              {
                name: 'optional-heatSource-WoodStove',
                inputType: InputTypes.Checkbox,
                label: 'Wood Stove'
              },
              {
                name: 'optional-heatSource-HeatPump',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump'
              },
              {
                name: 'optional-heatSource-HydroHeat',
                inputType: InputTypes.Checkbox,
                label: 'Hydro Heat'
              },
              {
                name: 'optional-heatSource-SpaceHeater',
                inputType: InputTypes.Checkbox,
                label: 'Space Heater'
              },
              {
                name: 'optional-heatSource-WallHeater',
                inputType: InputTypes.Checkbox,
                label: 'Wall Heater'
              },
              {
                name: 'optional-heatSource-NoHeat',
                inputType: InputTypes.Checkbox,
                label: 'No Heat'
              },
              {
                name: 'optional-heatSource-HeatAge03Yrs',
                inputType: InputTypes.Checkbox,
                label: 'Heat Age 0-3 Yrs'
              },
              {
                name: 'optional-heatSource-HeatAge36Yrs',
                inputType: InputTypes.Checkbox,
                label: 'Heat Age 3-6 Yrs'
              },
              {
                name: 'optional-heatSource-HeatAge6Yrs',
                inputType: InputTypes.Checkbox,
                label: 'Heat Age 6+ Yrs'
              },
              {
                name: 'optional-heatSource-GasPack',
                inputType: InputTypes.Checkbox,
                label: 'Gas Pack'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heat Fuel',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-heatingFuel-Coal',
                inputType: InputTypes.Checkbox,
                label: 'Coal'
              },
              {
                name: 'optional-heatingFuel-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-heatingFuel-NaturalGas',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas'
              },
              {
                name: 'optional-heatingFuel-NoFuel',
                inputType: InputTypes.Checkbox,
                label: 'No Fuel'
              },
              {
                name: 'optional-heatingFuel-Oil',
                inputType: InputTypes.Checkbox,
                label: 'Oil'
              },
              {
                name: 'optional-heatingFuel-PropaneGas',
                inputType: InputTypes.Checkbox,
                label: 'Propane Gas'
              },
              {
                name: 'optional-heatingFuel-Solar',
                inputType: InputTypes.Checkbox,
                label: 'Solar'
              },
              {
                name: 'optional-heatingFuel-WoodFuel',
                inputType: InputTypes.Checkbox,
                label: 'Wood Fuel'
              },
              {
                name: 'optional-heatingFuel-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heating Fuel',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-heatingFuel-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-heatingFuel-GasLP',
                inputType: InputTypes.Checkbox,
                label: 'Gas LP'
              },
              {
                name: 'optional-heatingFuel-NaturalGas',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas'
              },
              {
                name: 'optional-heatingFuel-Oil',
                inputType: InputTypes.Checkbox,
                label: 'Oil'
              },
              {
                name: 'optional-heatingFuel-QualifiesForUtilRate',
                inputType: InputTypes.Checkbox,
                label: 'Qualifies For Util. Rate'
              },
              {
                name: 'optional-heatingFuel-NoFuelHeat',
                inputType: InputTypes.Checkbox,
                label: 'No Fuel Heat'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Cooling Type',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-coolingType-AtticFan',
                inputType: InputTypes.Checkbox,
                label: 'Attic Fan'
              },
              {
                name: 'optional-coolingType-AtticVentilator',
                inputType: InputTypes.Checkbox,
                label: 'Attic Ventilator'
              },
              {
                name: 'optional-coolingType-Central',
                inputType: InputTypes.Checkbox,
                label: 'Central'
              },
              {
                name: 'optional-coolingType-Chiller',
                inputType: InputTypes.Checkbox,
                label: 'Chiller'
              },
              {
                name: 'optional-coolingType-GroundWater',
                inputType: InputTypes.Checkbox,
                label: 'Ground Water'
              },
              {
                name: 'optional-coolingType-HeatPump',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump'
              },
              {
                name: 'optional-coolingType-MultiZoned',
                inputType: InputTypes.Checkbox,
                label: 'Multi-Zoned'
              },
              {
                name: 'optional-coolingType-MultipleSystems',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Systems'
              },
              {
                name: 'optional-coolingType-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-coolingType-SingleLevelOnly',
                inputType: InputTypes.Checkbox,
                label: 'Single Level Only'
              },
              {
                name: 'optional-coolingType-WallUnits',
                inputType: InputTypes.Checkbox,
                label: 'Wall Units'
              },
              {
                name: 'optional-coolingType-WindowUnit',
                inputType: InputTypes.Checkbox,
                label: 'Window Unit(s)'
              },
              {
                name: 'optional-coolingType-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Cooling Type',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-coolingType-3Zone',
                inputType: InputTypes.Checkbox,
                label: '3 Zone'
              },
              {
                name: 'optional-coolingType-Central',
                inputType: InputTypes.Checkbox,
                label: 'Central'
              },
              {
                name: 'optional-coolingType-DualZoneAC',
                inputType: InputTypes.Checkbox,
                label: 'Dual Zone A/C'
              },
              {
                name: 'optional-coolingType-HeatPump',
                inputType: InputTypes.Checkbox,
                label: 'Heat Pump'
              },
              {
                name: 'optional-coolingType-WallUnit',
                inputType: InputTypes.Checkbox,
                label: 'Wall Unit'
              },
              {
                name: 'optional-coolingType-WholeHouseFan',
                inputType: InputTypes.Checkbox,
                label: 'Whole House Fan'
              },
              {
                name: 'optional-coolingType-WindowUnit',
                inputType: InputTypes.Checkbox,
                label: 'Window Unit(s)'
              },
              {
                name: 'optional-coolingType-ACAge03Years',
                inputType: InputTypes.Checkbox,
                label: 'A/C Age 0-3 Years'
              },
              {
                name: 'optional-coolingType-ACAge36Years',
                inputType: InputTypes.Checkbox,
                label: 'A/C Age 3-6 Years'
              },
              {
                name: 'optional-coolingType-ACAge6Years',
                inputType: InputTypes.Checkbox,
                label: 'A/C Age 6+ Years'
              },
              {
                name: 'optional-coolingType-GasPack',
                inputType: InputTypes.Checkbox,
                label: 'Gas Pack'
              },
              {
                name: 'optional-coolingType-NoAC',
                inputType: InputTypes.Checkbox,
                label: 'No A/C'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Heating and Cooling',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [  
            {            
            groupTitle: 'Heating',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeRent],
            fields: [
            {
              name: 'optional-HVAC-ActiveSolarHeat',
              inputType: InputTypes.Checkbox,
              label: 'Active Solar Heat'
            },
            {
              name: 'optional-HVAC-Baseboard',
              inputType: InputTypes.Checkbox,
              label: 'Baseboard'
            },
            {
              name: 'optional-HVAC-CentralAirHeat',
              inputType: InputTypes.Checkbox,
              label: 'Central Air Heat'
            },
            {
              name: 'optional-HVAC-DuctlessMiniSplitSystem',
              inputType: InputTypes.Checkbox,
              label: 'Ductless/Mini-Split System'
            },
            {
              name: 'optional-HVAC-ElectricHeat',
              inputType: InputTypes.Checkbox,
              label: 'Electric Heat'
            },
            {
              name: 'optional-HVAC-EnergyStarQualHeat',
              inputType: InputTypes.Checkbox,
              label: 'Energy Star Qual - Heat'
            },
             {
              name: 'optional-HVAC-FloorFurnace',
              inputType: InputTypes.Checkbox,
              label: 'Floor Furnace'
            },
            {
              name: 'optional-HVAC-ForcedAir',
              inputType: InputTypes.Checkbox,
              label: 'Forced Air'
            },
            {
              name: 'optional-HVAC-FreshAirVent',
              inputType: InputTypes.Checkbox,
              label: 'Fresh Air Ventilation'
            },
            {
              name: 'optional-HVAC-GeothermalHeat',
              inputType: InputTypes.Checkbox,
              label: 'Geothermal Heat'
            },
            {
              name: 'optional-HVAC-HeatPumpHeat',
              inputType: InputTypes.Checkbox,
              label: 'Heat Pump - Heat'
            },
            {
              name: 'optional-HVAC-HotWaterHeat',
              inputType: InputTypes.Checkbox,
              label: 'Hot Water - Heat'
            },
            {
              name: 'optional-HVAC-HumidityControlHeat',
              inputType: InputTypes.Checkbox,
              label: 'Humidity Control - Heat'
            },
            {
              name: 'optional-HVAC-Kerosene',
              inputType: InputTypes.Checkbox,
              label: 'Kerosene'
            },
            {
              name: 'optional-HVAC-NaturalGas',
              inputType: InputTypes.Checkbox,
              label: 'Natural Gas'
            },
            {
              name: 'optional-HVAC-NoHeat',
              inputType: InputTypes.Checkbox,
              label: 'No Heat'
            },
            {
              name: 'optional-HVAC-Oil',
              inputType: InputTypes.Checkbox,
              label: 'Oil'
            },       
            {
              name: 'optional-HVAC-PassiveSolar',
              inputType: InputTypes.Checkbox,
              label: 'Passive Solar'
            },
            {
              name: 'optional-HVAC-Propane',
              inputType: InputTypes.Checkbox,
              label: 'Propane'
            },
            {
              name: 'optional-HVAC-RadiantHeat',
              inputType: InputTypes.Checkbox,
              label: 'Radiant Heat'
            },
            {
              name: 'optional-HVAC-RadiantFloor',
              inputType: InputTypes.Checkbox,
              label: 'Radiant Floor'
            },
            {
              name: 'optional-HVAC-SealedCombustionFireplace',
              inputType: InputTypes.Checkbox,
              label: 'Sealed Combustion Fireplce'
            },
            {
              name: 'optional-HVAC-SealedCombustionWoodstove',
              inputType: InputTypes.Checkbox,
              label: 'Sealed Combustion Woodstv'
            },
            {
              name: 'optional-HVAC-SteamHeat',
              inputType: InputTypes.Checkbox,
              label: 'Steam Heat'
            },
            {
              name: 'optional-HVAC-WallFurnace',
              inputType: InputTypes.Checkbox,
              label: 'Wall Furnace'
            },
            {
              name: 'optional-HVAC-Woodstove',
              inputType: InputTypes.Checkbox,
              label: 'Woodstove'
            },
            {
              name: 'optional-HVAC-ZonedHeat',
              inputType: InputTypes.Checkbox,
              label: 'Zoned Heat'
            },
            {
              name: 'optional-HVAC-OtherHeat',
              inputType: InputTypes.Checkbox,
              label: 'Other - Heat'
            },  
        ]
      },
      {
        groupTitle: 'Cooling',
        scope: Scopes.ByPropertyType,
        types: [PropertyTypes.HomeRent],
        fields: [
            {
              name: 'optional-cooling-AtticFan',
              inputType: InputTypes.Checkbox,
              label: 'Attic Fan'
            },
            {
              name: 'optional-cooling-CeilingFan',
              inputType: InputTypes.Checkbox,
              label: 'Ceiling Fan'
            },
            {
              name: 'optional-cooling-CentralAirCool',
              inputType: InputTypes.Checkbox,
              label: 'Central Air'
            },
            {
              name: 'optional-cooling-Dual',
              inputType: InputTypes.Checkbox,
              label: 'Dual'
            },
            {
              name: 'optional-cooling-Ductless',
              inputType: InputTypes.Checkbox,
              label: 'Ductless / Mini Split'
            },
            {
              name: 'optional-cooling-ElectricCool',
              inputType: InputTypes.Checkbox,
              label: 'Electric - Cool'
            },
            {
              name: 'optional-cooling-EnergyStarQualCool',
              inputType: InputTypes.Checkbox,
              label: 'Energy Star Qual Syst'
            },
            {
              name: 'optional-cooling-Gas',
              inputType: InputTypes.Checkbox,
              label: 'Gas'
            },
            {
              name: 'optional-cooling-GeothermalCool',
              inputType: InputTypes.Checkbox,
              label: 'Geothermal - Cool'
            },
            {
              name: 'optional-cooling-HeatPumpCool',
              inputType: InputTypes.Checkbox,
              label: 'Heat Pump - Cool'
            },
            {
              name: 'optional-cooling-HumidityControlCool',
              inputType: InputTypes.Checkbox,
              label: 'Humidity Control'
            },
            {
              name: 'optional-cooling-MultiUnit',
              inputType: InputTypes.Checkbox,
              label: 'Multiple Units'
            },
            {
              name: 'optional-cooling-WallUnit',
              inputType: InputTypes.Checkbox,
              label: 'Wall Unit(s)'
            },
            {
              name: 'optional-cooling-WindowUnit',
              inputType: InputTypes.Checkbox,
              label: 'Window Unit(s)'
            },
            {
              name: 'optional-cooling-ZonedCool',
              inputType: InputTypes.Checkbox,
              label: 'Zoned'
            },
            {
              name: 'optional-cooling-OtherCool',
              inputType: InputTypes.Checkbox,
              label: 'Other'
            },
            {
              name: 'optional-cooling-NoAC',
              inputType: InputTypes.Checkbox,
              label: 'No AC'
            },
            ]
          }
        ]
      },    
      {
        fieldsetTitle: 'Water Heater',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterHeater-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-waterHeater-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-waterHeater-Solar',
                inputType: InputTypes.Checkbox,
                label: 'Solar'
              },
              {
                name: 'optional-waterHeater-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-waterHeater-Tankless',
                inputType: InputTypes.Checkbox,
                label: 'Tankless'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Heater',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterHeater-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-waterHeater-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-waterHeater-Insulated',
                inputType: InputTypes.Checkbox,
                label: 'Insulated'
              },
              {
                name: 'optional-waterHeater-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-waterHeater-Solar',
                inputType: InputTypes.Checkbox,
                label: 'Solar'
              },
              {
                name: 'optional-waterHeater-Tankless',
                inputType: InputTypes.Checkbox,
                label: 'Tankless'
              },
              {
                name: 'optional-waterHeater-Unknown',
                inputType: InputTypes.Checkbox,
                label: 'Unknown'
              },
              {
                name: 'optional-waterHeater-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Heater',
        fieldsetDescription: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterHeater-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-waterHeater-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-waterHeater-NoWaterHeater',
                inputType: InputTypes.Checkbox,
                label: 'No Water Heater'
              },
              {
                name: 'optional-waterHeater-Tankless',
                inputType: InputTypes.Checkbox,
                label: 'Tankless'
              },
              {
                name: 'optional-waterHeater-Age03Years',
                inputType: InputTypes.Checkbox,
                label: 'Age 0-3 Years'
              },
              {
                name: 'optional-waterHeater-Age36Years',
                inputType: InputTypes.Checkbox,
                label: 'Age 3-6 Years'
              },
              {
                name: 'optional-waterHeater-Age6Years',
                inputType: InputTypes.Checkbox,
                label: 'Age 6+ Years'
              },
            ]
          }
        ]
      }, 
      {
        fieldsetTitle: 'Water Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterType-CommunitySubdivision',
                inputType: InputTypes.Checkbox,
                label: 'Community / Subdivision'
              },
              {
                name: 'optional-waterType-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-waterType-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'optional-waterType-PublicAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Public Available'
              },
              {
                name: 'optional-waterType-Spring',
                inputType: InputTypes.Checkbox,
                label: 'Spring'
              },
              {
                name: 'optional-waterType-Well',
                inputType: InputTypes.Checkbox,
                label: 'Well'
              },
              {
                name: 'optional-waterType-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterType-CityWater',
                inputType: InputTypes.Checkbox,
                label: 'City Water'
              },
              {
                name: 'optional-waterType-CommunityWell',
                inputType: InputTypes.Checkbox,
                label: 'Community Well'
              },
              {
                name: 'optional-waterType-CountyWater',
                inputType: InputTypes.Checkbox,
                label: 'County Water'
              },
              {
                name: 'optional-waterType-PublicWater',
                inputType: InputTypes.Checkbox,
                label: 'Public Water'
              },
              {
                name: 'optional-waterType-SharedWell',
                inputType: InputTypes.Checkbox,
                label: 'Shared Well'
              },
              {
                name: 'optional-waterType-Spring',
                inputType: InputTypes.Checkbox,
                label: 'Spring'
              },
              {
                name: 'optional-waterType-TapFeeRequired',
                inputType: InputTypes.Checkbox,
                label: 'Tap Fee Required'
              },
              {
                name: 'optional-waterType-WellInstalled',
                inputType: InputTypes.Checkbox,
                label: 'Well Installed'
              },
              {
                name: 'optional-waterType-WellNeeded',
                inputType: InputTypes.Checkbox,
                label: 'Well Needed'
              },
              {
                name: 'optional-waterType-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-waterType-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Sewer Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-sewerType-Community',
                inputType: InputTypes.Checkbox,
                label: 'Community'
              },
              {
                name: 'optional-sewerType-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-sewerType-Public',
                inputType: InputTypes.Checkbox,
                label: 'Public'
              },
              {
                name: 'optional-sewerType-PublicAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Public Available'
              },
              {
                name: 'optional-sewerType-Septic',
                inputType: InputTypes.Checkbox,
                label: 'Septic'
              },
              {
                name: 'optional-sewerType-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Sewer Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-sewerType-CitySewer',
                inputType: InputTypes.Checkbox,
                label: 'City Sewer'
              },
              {
                name: 'optional-sewerType-CountySewer',
                inputType: InputTypes.Checkbox,
                label: 'County Sewer'
              },
              {
                name: 'optional-sewerType-PrivateSewer',
                inputType: InputTypes.Checkbox,
                label: 'Private Sewer'
              },
              {
                name: 'optional-sewerType-PublicSewer',
                inputType: InputTypes.Checkbox,
                label: 'Public Sewer'
              },
              {
                name: 'optional-sewerType-SepticInstalled',
                inputType: InputTypes.Checkbox,
                label: 'Septic Installed'
              },
              {
                name: 'optional-sewerType-SepticNeeded',
                inputType: InputTypes.Checkbox,
                label: 'Septic Needed'
              },
              {
                name: 'optional-sewerType-SharedSeptic',
                inputType: InputTypes.Checkbox,
                label: 'Shared Septic'
              },
              {
                name: 'optional-sewerType-TapFeeRequired',
                inputType: InputTypes.Checkbox,
                label: 'Tap Fee Required'
              },
              {
                name: 'optional-sewerType-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-sewerType-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water and Sewer',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterSewer-CitySewer',
                inputType: InputTypes.Checkbox,
                label: 'City Sewer'
              },
              {
                name: 'optional-waterSewer-CityWater',
                inputType: InputTypes.Checkbox,
                label: 'City Water'
              },
              {
                name: 'optional-waterSewer-CommunitySewer',
                inputType: InputTypes.Checkbox,
                label: 'Community Sewer'
              },
              {
                name: 'optional-waterSewer-CommunityWater',
                inputType: InputTypes.Checkbox,
                label: 'Community Water'
              },
              {
                name: 'optional-waterSewer-CountySewer',
                inputType: InputTypes.Checkbox,
                label: 'County Sewer'
              },
              {
                name: 'optional-waterSewer-CountyWater',
                inputType: InputTypes.Checkbox,
                label: 'County Water'
              },
              {
                name: 'optional-waterSewer-SandFilter',
                inputType: InputTypes.Checkbox,
                label: 'Sand Filter'
              },
              {
                name: 'optional-waterSewer-SepticTank',
                inputType: InputTypes.Checkbox,
                label: 'Septic Tank'
              },
              {
                name: 'optional-waterSewer-Well',
                inputType: InputTypes.Checkbox,
                label: 'Well'
              },
              {
                name: 'optional-waterSewer-NoWaterSewer',
                inputType: InputTypes.Checkbox,
                label: 'No Water / Sewer'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Utilities Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterSewer-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity'
              },
              {
                name: 'optional-waterSewer-NaturalGas',
                inputType: InputTypes.Checkbox,
                label: 'Natural Gas'
              },
              {
                name: 'optional-waterSewer-WaterPrivate',
                inputType: InputTypes.Checkbox,
                label: 'Water Private'
              },
              {
                name: 'optional-waterSewer-WaterPublic',
                inputType: InputTypes.Checkbox,
                label: 'Water Public'
              },
              {
                name: 'optional-waterSewer-WellPrivate',
                inputType: InputTypes.Checkbox,
                label: 'Well Private'
              },
              {
                name: 'optional-waterSewer-WellShared',
                inputType: InputTypes.Checkbox,
                label: 'Well Shared'
              },
              {
                name: 'optional-waterSewer-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer'
              },
              {
                name: 'optional-waterSewer-Septic',
                inputType: InputTypes.Checkbox,
                label: 'Septic'
              },
              {
                name: 'optional-waterSewer-CableTV',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV'
              },
              {
                name: 'optional-waterSewer-UndergroundUtilities',
                inputType: InputTypes.Checkbox,
                label: 'Underground Utilities'
              },
              {
                name: 'optional-waterSewer-Telephone',
                inputType: InputTypes.Checkbox,
                label: 'Telephone'
              },
              {
                name: 'optional-waterSewer-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
    ]
  },
  {
    sectionTitle: 'Location / Community Information',
    fieldsets: [
      {
        fieldsetTitle: 'Lot Location',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-location-InsideCityLimits',
                inputType: InputTypes.Checkbox,
                label: 'Inside City Limits'
              },
              {
                name: 'optional-location-OutsideCityLimits',
                inputType: InputTypes.Checkbox,
                label: 'Outside City Limits'
              },
              {
                name: 'optional-location-OceanFront',
                inputType: InputTypes.Checkbox,
                label: 'Ocean Front'
              },
              {
                name: 'optional-location-ChannelFront',
                inputType: InputTypes.Checkbox,
                label: 'Channel Front'
              },
              {
                name: 'optional-location-LakePond',
                inputType: InputTypes.Checkbox,
                label: 'Lake/Pond'
              },
              {
                name: 'optional-location-OnICW',
                inputType: InputTypes.Checkbox,
                label: 'On ICW'
              },
              {
                name: 'optional-location-OnGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'On Golf Course'
              },
              {
                name: 'optional-location-EastBusiness17',
                inputType: InputTypes.Checkbox,
                label: 'East of Business 17'
              },
              {
                name: 'optional-location-DesignatedFloodZone',
                inputType: InputTypes.Checkbox,
                label: 'Designated Flood Zone'
              },
              {
                name: 'optional-location-InletCreek',
                inputType: InputTypes.Checkbox,
                label: 'Inlet/Creek'
              },
              {
                name: 'optional-location-OceanView',
                inputType: InputTypes.Checkbox,
                label: 'Ocean View'
              },
              {
                name: 'optional-location-Island',
                inputType: InputTypes.Checkbox,
                label: 'Island'
              },
              {
                name: 'optional-location-SecondRowBeach',
                inputType: InputTypes.Checkbox,
                label: 'Second Row Beach'
              },
              {
                name: 'optional-location-SecondRowOther',
                inputType: InputTypes.Checkbox,
                label: 'Second Row Other'
              },
              {
                name: 'optional-location-AdultCommunity55',
                inputType: InputTypes.Checkbox,
                label: 'Adult Community (55+)'
              },
              {
                name: 'optional-location-InICWCommunity',
                inputType: InputTypes.Checkbox,
                label: 'In ICW Community'
              },
              {
                name: 'optional-location-Wetlands',
                inputType: InputTypes.Checkbox,
                label: 'Wetlands'
              },
              {
                name: 'optional-location-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },
              {
                name: 'optional-location-InGolfCourseCommunity',
                inputType: InputTypes.Checkbox,
                label: 'In Golf Course Community'
              },
              {
                name: 'optional-location-MarshView',
                inputType: InputTypes.Checkbox,
                label: 'Marsh View'
              },
              {
                name: 'optional-location-EastHighway17Bypass',
                inputType: InputTypes.Checkbox,
                label: 'East of Highway 17 Bypass'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Location',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            groupTitle: 'Single Family Homes',
            fields: [
              {
                name: 'optional-locationSingleFamily-Oceanfront',
                inputType: InputTypes.Checkbox,
                label: 'Oceanfront'
              },
              {
                name: 'optional-locationSingleFamily-OnICW',
                inputType: InputTypes.Checkbox,
                label: 'On ICW'
              },
              {
                name: 'optional-locationSingleFamily-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },
              {
                name: 'optional-locationSingleFamily-LakePond',
                inputType: InputTypes.Checkbox,
                label: 'Lake/Pond'
              },
              {
                name: 'optional-locationSingleFamily-Channel',
                inputType: InputTypes.Checkbox,
                label: 'Channel'
              },
              {
                name: 'optional-locationSingleFamily-InletCreek',
                inputType: InputTypes.Checkbox,
                label: 'Inlet/Creek'
              },
              {
                name: 'optional-locationSingleFamily-InsideCityLimits',
                inputType: InputTypes.Checkbox,
                label: 'Inside City Limits'
              },
              {
                name: 'optional-locationSingleFamily-OutsideCityLimits',
                inputType: InputTypes.Checkbox,
                label: 'Outside City Limits'
              },
              {
                name: 'optional-locationSingleFamily-EastBus17',
                inputType: InputTypes.Checkbox,
                label: 'East of Bus 17'
              },
              {
                name: 'optional-locationSingleFamily-OnGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'On Golf Course'
              },
              {
                name: 'optional-locationSingleFamily-Island',
                inputType: InputTypes.Checkbox,
                label: 'Island'
              },
              {
                name: 'optional-locationSingleFamily-OceanView',
                inputType: InputTypes.Checkbox,
                label: 'Ocean View'
              },
              {
                name: 'optional-locationSingleFamily-SecondRowBeach',
                inputType: InputTypes.Checkbox,
                label: 'Second Row Beach'
              },
              {
                name: 'optional-locationSingleFamily-SecondRowOther',
                inputType: InputTypes.Checkbox,
                label: 'Second Row Other'
              },
              {
                name: 'optional-locationSingleFamily-FloatingDock',
                inputType: InputTypes.Checkbox,
                label: 'Floating Dock'
              },
              {
                name: 'optional-locationSingleFamily-AdultCommunity55Plus',
                inputType: InputTypes.Checkbox,
                label: 'Adult Community (55+)'
              },
              {
                name: 'optional-locationSingleFamily-InICWCommunity',
                inputType: InputTypes.Checkbox,
                label: 'In ICW community'
              },
              {
                name: 'optional-locationSingleFamily-Wetlands',
                inputType: InputTypes.Checkbox,
                label: 'Wetlands'
              },
              {
                name: 'optional-locationSingleFamily-InGolfCourseCommunity',
                inputType: InputTypes.Checkbox,
                label: 'In Golf Course Community'
              },
              {
                name: 'optional-locationSingleFamily-MarshView',
                inputType: InputTypes.Checkbox,
                label: 'Marsh View'
              },
              {
                name: 'optional-locationSingleFamily-EastHighway17Bypass',
                inputType: InputTypes.Checkbox,
                label: 'East of Highway 17 Bypass'
              },
            ]
          },
          {
            groupTitle: 'Condos and Town homes',
            fields: [
              {
                name: 'optional-locationCondoTownHouse-GolfCourseView',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course View'
              },
              {
                name: 'optional-locationCondoTownHouse-OceanFront',
                inputType: InputTypes.Checkbox,
                label: 'Ocean Front'
              },
              {
                name: 'optional-locationCondoTownHouse-OceanView',
                inputType: InputTypes.Checkbox,
                label: 'Ocean View'
              },
              {
                name: 'optional-locationCondoTownHouse-WaterwayView',
                inputType: InputTypes.Checkbox,
                label: 'Waterway View'
              },
              {
                name: 'optional-locationCondoTownHouse-LakePondView',
                inputType: InputTypes.Checkbox,
                label: 'Lake/Pond View'
              },
              {
                name: 'optional-locationCondoTownHouse-ChannelView',
                inputType: InputTypes.Checkbox,
                label: 'Channel View'
              },
              {
                name: 'optional-locationCondoTownHouse-InletCreekView',
                inputType: InputTypes.Checkbox,
                label: 'Inlet/Creek View'
              },
              {
                name: 'optional-locationCondoTownHouse-IslandView',
                inputType: InputTypes.Checkbox,
                label: 'Island View'
              },
              {
                name: 'optional-locationCondoTownHouse-EndUnit',
                inputType: InputTypes.Checkbox,
                label: 'End Unit'
              },
              {
                name: 'optional-locationCondoTownHouse-ViewMarsh',
                inputType: InputTypes.Checkbox,
                label: 'View of Marsh'
              },
              {
                name: 'optional-locationCondoTownHouse-Penthouse',
                inputType: InputTypes.Checkbox,
                label: 'Penthouse'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Vegetation',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-vegetation-Cleared',
                inputType: InputTypes.Checkbox,
                label: 'Cleared'
              },
              {
                name: 'optional-vegetation-Grassed',
                inputType: InputTypes.Checkbox,
                label: 'Grassed'
              },
              {
                name: 'optional-vegetation-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-vegetation-PartiallyWooded',
                inputType: InputTypes.Checkbox,
                label: 'Partially Wooded'
              },
              {
                name: 'optional-vegetation-Wooded',
                inputType: InputTypes.Checkbox,
                label: 'Wooded'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-CityLot',
                inputType: InputTypes.Checkbox,
                label: 'City Lot'
              },
              {
                name: 'optional-typeDescription-CornerLot',
                inputType: InputTypes.Checkbox,
                label: 'Corner Lot'
              },
              {
                name: 'optional-typeDescription-CulDeSac',
                inputType: InputTypes.Checkbox,
                label: 'Cul-De-Sac'
              },
              {
                name: 'optional-typeDescription-Greenbelt',
                inputType: InputTypes.Checkbox,
                label: 'Greenbelt'
              },
              {
                name: 'optional-typeDescription-Level',
                inputType: InputTypes.Checkbox,
                label: 'Level'
              },
              {
                name: 'optional-typeDescription-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-typeDescription-OpenLot',
                inputType: InputTypes.Checkbox,
                label: 'Open Lot'
              },
              {
                name: 'optional-typeDescription-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-typeDescription-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-typeDescription-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-typeDescription-Sloped',
                inputType: InputTypes.Checkbox,
                label: 'Sloped'
              },
              {
                name: 'optional-typeDescription-SteepSlope',
                inputType: InputTypes.Checkbox,
                label: 'Steep Slope'
              },
              {
                name: 'optional-typeDescription-Waterfall',
                inputType: InputTypes.Checkbox,
                label: 'Waterfall'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Type / Description',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-Rectangular',
                inputType: InputTypes.Checkbox,
                label: 'Rectangular'
              },
              {
                name: 'optional-typeDescription-Irregular',
                inputType: InputTypes.Checkbox,
                label: 'Irregular'
              },
              {
                name: 'optional-typeDescription-CulDeSac',
                inputType: InputTypes.Checkbox,
                label: 'Cul-De-Sac'
              },
              {
                name: 'optional-typeDescription-Corner',
                inputType: InputTypes.Checkbox,
                label: 'Corner'
              },
              {
                name: 'optional-typeDescription-DesignatedFloodZone',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent, PropertyTypes.HomeSale],
                label: 'Designated Flood Zone'
              },
              {
                name: 'optional-typeDescription-1MoreAcres',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent, PropertyTypes.HomeSale],
                label: '1 or More Acres'
              },
              {
                name: 'optional-typeDescription-5MoreAcres',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent, PropertyTypes.HomeSale],
                label: '5 or More Acres'
              },
              {
                name: 'optional-typeDescription-Leased',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeRent, PropertyTypes.HomeSale],
                label: 'Leased'
              },
              {
                name: 'optional-typeDescription-Wooded',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Wooded'
              },
              {
                name: 'optional-typeDescription-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Type / Description',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-051Acre',
                inputType: InputTypes.Checkbox,
                label: '0.5 - 1 Acre'
              },
              {
                name: 'optional-typeDescription-12Acres',
                inputType: InputTypes.Checkbox,
                label: '1 - 2 Acres'
              },
              {
                name: 'optional-typeDescription-25Acres',
                inputType: InputTypes.Checkbox,
                label: '2 - 5 Acres'
              },
              {
                name: 'optional-typeDescription-510Acres',
                inputType: InputTypes.Checkbox,
                label: '5 - 10 Acres'
              },
              {
                name: 'optional-typeDescription-10PlusAcres',
                inputType: InputTypes.Checkbox,
                label: '10+ Acres'
              },
              {
                name: 'optional-typeDescription-BeachAccess',
                inputType: InputTypes.Checkbox,
                label: 'Beach Access'
              },
              {
                name: 'optional-typeDescription-Beachfront',
                inputType: InputTypes.Checkbox,
                label: 'Beachfront'
              },
              {
                name: 'optional-typeDescription-Canal',
                inputType: InputTypes.Checkbox,
                label: 'Canal'
              },
              {
                name: 'optional-typeDescription-Corner',
                inputType: InputTypes.Checkbox,
                label: 'Corner'
              },
              {
                name: 'optional-typeDescription-CulDeSacLot',
                inputType: InputTypes.Checkbox,
                label: 'Cul-de-sac Lot'
              },
              {
                name: 'optional-typeDescription-High',
                inputType: InputTypes.Checkbox,
                label: 'High'
              },
              {
                name: 'optional-typeDescription-Inside',
                inputType: InputTypes.Checkbox,
                label: 'Inside'
              },
              {
                name: 'optional-typeDescription-Lagoon',
                inputType: InputTypes.Checkbox,
                label: 'Lagoon'
              },
              {
                name: 'optional-typeDescription-Lakefront',
                inputType: InputTypes.Checkbox,
                label: 'Lakefront'
              },
              {
                name: 'optional-typeDescription-LakefrontMarion',
                inputType: InputTypes.Checkbox,
                label: 'Lakefront - Marion'
              },
              {
                name: 'optional-typeDescription-LakefrontMoultrie',
                inputType: InputTypes.Checkbox,
                label: 'Lakefront - Moultrie'
              },
              {
                name: 'optional-typeDescription-Lakeview',
                inputType: InputTypes.Checkbox,
                label: 'Lakeview'
              },
              {
                name: 'optional-typeDescription-Level',
                inputType: InputTypes.Checkbox,
                label: 'Level'
              },
              {
                name: 'optional-typeDescription-Low',
                inputType: InputTypes.Checkbox,
                label: 'Low'
              },
              {
                name: 'optional-typeDescription-Marshfront',
                inputType: InputTypes.Checkbox,
                label: 'Marshfront'
              },
              {
                name: 'optional-typeDescription-Marshview',
                inputType: InputTypes.Checkbox,
                label: 'Marshview'
              },
              {
                name: 'optional-typeDescription-OceanView',
                inputType: InputTypes.Checkbox,
                label: 'Ocean View'
              },
              {
                name: 'optional-typeDescription-OnGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'On Golf Course'
              },
              {
                name: 'optional-typeDescription-OnTennisCourt',
                inputType: InputTypes.Checkbox,
                label: 'On Tennis Court'
              },
              {
                name: 'optional-typeDescription-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },
              {
                name: 'optional-typeDescription-PondSite',
                inputType: InputTypes.Checkbox,
                label: 'Pond Site'
              },
              {
                name: 'optional-typeDescription-RiverAccess',
                inputType: InputTypes.Checkbox,
                label: 'River Access'
              },
              {
                name: 'optional-typeDescription-Riverfront',
                inputType: InputTypes.Checkbox,
                label: 'Riverfront'
              },
              {
                name: 'optional-typeDescription-Riverview',
                inputType: InputTypes.Checkbox,
                label: 'Riverview'
              },
              {
                name: 'optional-typeDescription-Rolling',
                inputType: InputTypes.Checkbox,
                label: 'Rolling'
              },
              {
                name: 'optional-typeDescription-Stream',
                inputType: InputTypes.Checkbox,
                label: 'Stream'
              },
              {
                name: 'optional-typeDescription-TidalCreek',
                inputType: InputTypes.Checkbox,
                label: 'Tidal Creek'
              },
              {
                name: 'optional-typeDescription-WaterfrontDeep',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront - Deep'
              },
              {
                name: 'optional-typeDescription-WaterfrontShallow',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront - Shallow'
              },
              {
                name: 'optional-typeDescription-Wetlands',
                inputType: InputTypes.Checkbox,
                label: 'Wetlands'
              },
              {
                name: 'optional-typeDescription-Wooded',
                inputType: InputTypes.Checkbox,
                label: 'Wooded'
              },
              {
                name: 'optional-typeDescription-LakeAccess',
                inputType: InputTypes.Checkbox,
                label: 'Lake Access'
              },
              {
                name: 'optional-typeDescription-USForestryServiceAdjacent',
                inputType: InputTypes.Checkbox,
                label: 'US Forestry Service Adjacent'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: {
          default: 'Lot Type / Description',
          scope: Scopes.ByPropertyType,
          types: { [PropertyTypes.LandSale]: 'Land Description and Topology' },
        },
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triad]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-BeachFront',
                inputType: InputTypes.Checkbox,
                label: 'Beach Front'
              },
              {
                name: 'optional-typeDescription-Clear',
                inputType: InputTypes.Checkbox,
                label: 'Clear'
              },
              {
                name: 'optional-typeDescription-ClearedLand',
                inputType: InputTypes.Checkbox,
                label: 'Cleared Land'
              },
              {
                name: 'optional-typeDescription-Corner',
                inputType: InputTypes.Checkbox,
                label: 'Corner'
              },
              {
                name: 'optional-typeDescription-Creek',
                inputType: InputTypes.Checkbox,
                label: 'Creek'
              },
              {
                name: 'optional-typeDescription-CulDeSac',
                inputType: InputTypes.Checkbox,
                label: 'Cul-De-Sac'
              },
              {
                name: 'optional-typeDescription-DeadEnd',
                inputType: InputTypes.Checkbox,
                label: 'Dead End'
              },
              {
                name: 'optional-typeDescription-Fence',
                inputType: InputTypes.Checkbox,
                label: 'Fence(s)'
              },
              {
                name: 'optional-typeDescription-Flat',
                inputType: InputTypes.Checkbox,
                label: 'Flat'
              },
              {
                name: 'optional-typeDescription-GolfCourseLot',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course Lot'
              },
              {
                name: 'optional-typeDescription-InlandWaterway',
                inputType: InputTypes.Checkbox,
                label: 'Inland Waterway'
              },
              {
                name: 'optional-typeDescription-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-typeDescription-LakeFront',
                inputType: InputTypes.Checkbox,
                label: 'Lake Front'
              },
              {
                name: 'optional-typeDescription-LakeView',
                inputType: InputTypes.Checkbox,
                label: 'Lake View'
              },
              {
                name: 'optional-typeDescription-LandLockedEasement',
                inputType: InputTypes.Checkbox,
                label: 'Land Locked - Easement'
              },
              {
                name: 'optional-typeDescription-LandLockedNOEasement',
                inputType: InputTypes.Checkbox,
                label: 'Land Locked - NO Easement'
              },
              {
                name: 'optional-typeDescription-Level',
                inputType: InputTypes.Checkbox,
                label: 'Level'
              },
              {
                name: 'optional-typeDescription-Low',
                inputType: InputTypes.Checkbox,
                label: 'Low'
              },
              {
                name: 'optional-typeDescription-Mountain',
                inputType: InputTypes.Checkbox,
                label: 'Mountain'
              },
              {
                name: 'optional-typeDescription-MountainView',
                inputType: InputTypes.Checkbox,
                label: 'Mountain View'
              },
              {
                name: 'optional-typeDescription-MultiZones',
                inputType: InputTypes.Checkbox,
                label: 'Multi Zones'
              },
              {
                name: 'optional-typeDescription-NaturalLand',
                inputType: InputTypes.Checkbox,
                label: 'Natural Land'
              },
              {
                name: 'optional-typeDescription-PartiallyCleared',
                inputType: InputTypes.Checkbox,
                label: 'Partially Cleared'
              },
              {
                name: 'optional-typeDescription-PartiallyFenced',
                inputType: InputTypes.Checkbox,
                label: 'Partially Fenced'
              },
              {
                name: 'optional-typeDescription-PartiallyWooded',
                inputType: InputTypes.Checkbox,
                label: 'Partially Wooded'
              },
              {
                name: 'optional-typeDescription-PastureLand',
                inputType: InputTypes.Checkbox,
                label: 'Pasture Land'
              },
              {
                name: 'optional-typeDescription-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },
              {
                name: 'optional-typeDescription-PUD',
                inputType: InputTypes.Checkbox,
                label: 'PUD'
              },
              {
                name: 'optional-typeDescription-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },
              {
                name: 'optional-typeDescription-Riverfront',
                inputType: InputTypes.Checkbox,
                label: 'Riverfront'
              },
              {
                name: 'optional-typeDescription-Rolling',
                inputType: InputTypes.Checkbox,
                label: 'Rolling'
              },
              {
                name: 'optional-typeDescription-Rough',
                inputType: InputTypes.Checkbox,
                label: 'Rough'
              },
              {
                name: 'optional-typeDescription-Rural',
                inputType: InputTypes.Checkbox,
                label: 'Rural'
              },
              {
                name: 'optional-typeDescription-SecludedLot',
                inputType: InputTypes.Checkbox,
                label: 'Secluded Lot'
              },
              {
                name: 'optional-typeDescription-Severe',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Severe'
              },
              {
                name: 'optional-typeDescription-Subdivision',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision'
              },
              {
                name: 'optional-typeDescription-Sloping',
                inputType: InputTypes.Checkbox,
                label: 'Sloping'
              },
              {
                name: 'optional-typeDescription-Steep',
                inputType: InputTypes.Checkbox,
                label: 'Steep'
              },
              {
                name: 'optional-typeDescription-Stream',
                inputType: InputTypes.Checkbox,
                label: 'Stream'
              },
              {
                name: 'optional-typeDescription-TierWatershed',
                inputType: InputTypes.Checkbox,
                label: 'Tier(ed) Watershed'
              },
              {
                name: 'optional-typeDescription-View',
                inputType: InputTypes.Checkbox,
                label: 'View'
              },
              {
                name: 'optional-typeDescription-Waterview',
                inputType: InputTypes.Checkbox,
                label: 'Waterview'
              },
              {
                name: 'optional-typeDescription-Wooded',
                inputType: InputTypes.Checkbox,
                label: 'Wooded'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Type / Description',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.LandSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-BackYard',
                inputType: InputTypes.Checkbox,
                label: 'Back Yard'
              },
              {
                name: 'optional-typeDescription-CityLot',
                inputType: InputTypes.Checkbox,
                label: 'City Lot'
              },
              {
                name: 'optional-typeDescription-Cleared',
                inputType: InputTypes.Checkbox,
                label: 'Cleared'
              },
              {
                name: 'optional-typeDescription-CornerLot',
                inputType: InputTypes.Checkbox,
                label: 'Corner Lot'
              },
              {
                name: 'optional-typeDescription-CulDeSacLot',
                inputType: InputTypes.Checkbox,
                label: 'Cul-de-sac Lot'
              },
              {
                name: 'optional-typeDescription-Farm',
                inputType: InputTypes.Checkbox,
                label: 'Farm'
              },
              {
                name: 'optional-typeDescription-FewTrees',
                inputType: InputTypes.Checkbox,
                label: 'Few Trees'
              },
              {
                name: 'optional-typeDescription-FrontYard',
                inputType: InputTypes.Checkbox,
                label: 'Front Yard'
              },
              {
                name: 'optional-typeDescription-GardenArea',
                inputType: InputTypes.Checkbox,
                label: 'Garden Area'
              },
              {
                name: 'optional-typeDescription-GentleSloping',
                inputType: InputTypes.Checkbox,
                label: 'Gentle Sloping'
              },
              {
                name: 'optional-typeDescription-Greenway',
                inputType: InputTypes.Checkbox,
                label: 'Greenway'
              },
              {
                name: 'optional-typeDescription-HardwoodTrees',
                inputType: InputTypes.Checkbox,
                label: 'Hardwood Trees'
              },
              {
                name: 'optional-typeDescription-IrregularLot',
                inputType: InputTypes.Checkbox,
                label: 'Irregular Lot'
              },
              {
                name: 'optional-typeDescription-LakeOnLot',
                inputType: InputTypes.Checkbox,
                label: 'Lake On Lot'
              },
              {
                name: 'optional-typeDescription-Landscaped',
                inputType: InputTypes.Checkbox,
                label: 'Landscaped'
              },
              {
                name: 'optional-typeDescription-Level',
                inputType: InputTypes.Checkbox,
                label: 'Level'
              },
              {
                name: 'optional-typeDescription-ManyTrees',
                inputType: InputTypes.Checkbox,
                label: 'Many Trees'
              },
              {
                name: 'optional-typeDescription-Meadow',
                inputType: InputTypes.Checkbox,
                label: 'Meadow'
              },
              {
                name: 'optional-typeDescription-NativePlants',
                inputType: InputTypes.Checkbox,
                label: 'Native Plants'
              },
              {
                name: 'optional-typeDescription-NearGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Near Golf Course'
              },
              {
                name: 'optional-typeDescription-NearPublicTransit',
                inputType: InputTypes.Checkbox,
                label: 'Near Public Transit'
              },
              {
                name: 'optional-typeDescription-NearGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Near Golf Course'
              },
              {
                name: 'optional-typeDescription-OnGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'On Golf Course'
              },
              {
                name: 'optional-typeDescription-Open',
                inputType: InputTypes.Checkbox,
                label: 'Open Lot'
              },
              {
                name: 'optional-typeDescription-PartiallyCleared',
                inputType: InputTypes.Checkbox,
                label: 'Partially Cleared'
              },
              {
                name: 'optional-typeDescription-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-typeDescription-PondOnLot',
                inputType: InputTypes.Checkbox,
                label: 'Pond On Lot'
              },
              {
                name: 'optional-typeDescription-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-typeDescription-RectangularLot',
                inputType: InputTypes.Checkbox,
                label: 'Rectangular Lot'
              },
              {
                name: 'optional-typeDescription-RollingSlope',
                inputType: InputTypes.Checkbox,
                label: 'Rolling Slope'
              },
              {
                name: 'optional-typeDescription-Secluded',
                inputType: InputTypes.Checkbox,
                label: 'Secluded'
              },
              {
                name: 'optional-typeDescription-Sloped',
                inputType: InputTypes.Checkbox,
                label: 'Sloped'
              },
              {
                name: 'optional-typeDescription-SplitPossible',
                inputType: InputTypes.Checkbox,
                label: 'Split Possible'
              },
              {
                name: 'optional-typeDescription-SprinklersFront',
                inputType: InputTypes.Checkbox,
                label: 'Sprinklers In Front'
              },
              {
                name: 'optional-typeDescription-SprinklersRear',
                inputType: InputTypes.Checkbox,
                label: 'Sprinklers In Rear'
              },
              {
                name: 'optional-typeDescription-Subdivided',
                inputType: InputTypes.Checkbox,
                label: 'Subdivided'
              },
              {
                name: 'optional-typeDescription-Views',
                inputType: InputTypes.Checkbox,
                label: 'Views'
              },
              {
                name: 'optional-typeDescription-Waterfall',
                inputType: InputTypes.Checkbox,
                label: 'Waterfall'
              },
              {
                name: 'optional-typeDescription-Waterfront',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront'
              },
              {
                name: 'optional-typeDescription-Wetlands',
                inputType: InputTypes.Checkbox,
                label: 'Wetlands'
              },        
              {
                name: 'optional-typeDescription-WoodedLot',
                inputType: InputTypes.Checkbox,
                label: 'Wooded Lot'
              },
            ]
          },
              {
                groupTitle: 'Views',
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.LandSale, PropertyTypes.HomeRent],
                 fields: [      
              {
                name: 'optional-typeDescription-BayView',
                inputType: InputTypes.Checkbox,
                label: 'Bay View'
              },
              {
                name: 'optional-typeDescription-CanalView',
                inputType: InputTypes.Checkbox,
                label: 'Canal View'
              },
              {
                name: 'optional-typeDescription-CityView',
                inputType: InputTypes.Checkbox,
                label: 'City View'
              },
              {
                name: 'optional-typeDescription-CreamStreamView',
                inputType: InputTypes.Checkbox,
                label: 'Creek/Stream View'
              },
              {
                name: 'optional-typeDescription-DowntownView',
                inputType: InputTypes.Checkbox,
                label: 'Downtown View'
              },
              {
                name: 'optional-typeDescription-GardenView',
                inputType: InputTypes.Checkbox,
                label: 'Garden View'
              },
              {
                name: 'optional-typeDescription-GolfCourseView',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course View'
              },
              {
                name: 'optional-typeDescription-LakeView',
                inputType: InputTypes.Checkbox,
                label: 'Lake View'
              },
              {
                name: 'optional-typeDescription-MarinaView',
                inputType: InputTypes.Checkbox,
                label: 'Marina View'
              },
              {
                name: 'optional-typeDescription-MountainView',
                inputType: InputTypes.Checkbox,
                label: 'Mountain(s) View'
              },
              {
                name: 'optional-typeDescription-ParkGreenbeltView',
                inputType: InputTypes.Checkbox,
                label: 'Park/Greenbelt View'
              },
              {
                name: 'optional-typeDescription-PondView',
                inputType: InputTypes.Checkbox,
                label: 'Pond View'
              },
              {
                name: 'optional-typeDescription-RiverView',
                inputType: InputTypes.Checkbox,
                label: 'River View'
              },
              {
                name: 'optional-typeDescription-TreesWoodsView',
                inputType: InputTypes.Checkbox,
                label: 'Trees/Woods View'
              },
              {
                name: 'optional-typeDescription-WaterView',
                inputType: InputTypes.Checkbox,
                label: 'Water View'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Type / Description',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-CornerLot',
                inputType: InputTypes.Checkbox,
                label: 'Corner Lot'
              },
              {
                name: 'optional-typeDescription-CulDeSacLot',
                inputType: InputTypes.Checkbox,
                label: 'Cul-de-sac Lot'
              },
              {
                name: 'optional-typeDescription-DeadEnd',
                inputType: InputTypes.Checkbox,
                label: 'Dead End'
              },
              {
                name: 'optional-typeDescription-DeededWaterRights',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Deeded Water Rights'
              },
              {
                name: 'optional-typeDescription-Development',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Development'
              },
              {
                name: 'optional-typeDescription-Dunes',
                inputType: InputTypes.Checkbox,
                label: 'Dunes'
              },
              {
                name: 'optional-typeDescription-Farm',
                inputType: InputTypes.Checkbox,
                label: 'Farm'
              },
              {
                name: 'optional-typeDescription-GolfCourseLot',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course Lot'
              },
              {
                name: 'optional-typeDescription-LandLocked',
                inputType: InputTypes.Checkbox,
                label: 'Land Locked'
              },
              {
                name: 'optional-typeDescription-Open',
                inputType: InputTypes.Checkbox,
                label: 'Open'
              },
              {
                name: 'optional-typeDescription-PartiallyCleared',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Partially Cleared'
              },
              {
                name: 'optional-typeDescription-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-typeDescription-PUD',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'PUD'
              },
              {
                name: 'optional-typeDescription-Rural',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Rural'
              },
              {
                name: 'optional-typeDescription-SailboatAccessible',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Sailboat Accessible'
              },
              {
                name: 'optional-typeDescription-Subdivision',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Subdivision'
              },
              {
                name: 'optional-typeDescription-Suburban',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Suburban'
              },
              {
                name: 'optional-typeDescription-Urban',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Urban'
              },
              {
                name: 'optional-typeDescription-WaterDepth4',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Water Depth 4+'
              },
              {
                name: 'optional-typeDescription-Wetlands',
                inputType: InputTypes.Checkbox,
                label: 'Wetlands'
              },
              {
                name: 'optional-typeDescription-WoodedLot',
                inputType: InputTypes.Checkbox,
                label: 'Wooded Lot'
              },
              {
                name: 'optional-typeDescription-Other',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Other'
              },
            ]
          },
          {
            fields: [
              {
                name: 'optional-waterOptions-WaterFront',
                inputType: InputTypes.YesNo,
                label: 'Water Front'
              },
              {
                name: 'optional-waterOptions-WaterView',
                inputType: InputTypes.YesNo,
                label: 'Water View'
              },
              {
                name: 'optional-waterOptions-WaterFrontage',
                inputType: InputTypes.Text,
                scope: Scopes.ByPropertyType,
                types: [ PropertyTypes.LandSale ],
                label: 'Water Frontage (in ft)'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Type / Description',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-AdjoinsNationalStateForest',
                inputType: InputTypes.Checkbox,
                label: 'Adjoins National/State Forest'
              },
              {
                name: 'optional-typeDescription-Beachfront',
                inputType: InputTypes.Checkbox,
                label: 'Beachfront'
              },
              {
                name: 'optional-typeDescription-Cleared',
                inputType: InputTypes.Checkbox,
                 label: 'Cleared'
              },
              {
                name: 'optional-typeDescription-CornerLot',
                inputType: InputTypes.Checkbox,
                label: 'Corner Lot'
              },
              {
                name: 'optional-typeDescription-Creekfront',
                inputType: InputTypes.Checkbox,
                label: 'Creekfront'
              },
              {
                name: 'optional-typeDescription-Crops',
                inputType: InputTypes.Checkbox,
                label: 'Crops'
              },
              {
                name: 'optional-typeDescription-CulDeSacLot',
                inputType: InputTypes.Checkbox,
                label: 'Cul-de-sac Lot'
              },
              {
                name: 'optional-typeDescription-EndUnit',
                inputType: InputTypes.Checkbox,
                label: 'End Unit'
              },
              {
                name: 'optional-typeDescription-FloodFringeArea',
                inputType: InputTypes.Checkbox,
                label: 'Flood Fringe Area'
              },
              {
                name: 'optional-typeDescription-FloodPlain',
                inputType: InputTypes.Checkbox,
                label: {
                  default: 'Flood Plain',
                  scope: Scopes.ByPropertyType,
                  types: {
                    [PropertyTypes.LandSale]: 'Flood Plain'
                  }
                }
              },
              {
                name: 'optional-typeDescription-FruitTrees',
                inputType: InputTypes.Checkbox,
                label: 'Fruit Trees'
              },
              {
                name: 'optional-typeDescription-GreenArea',
                inputType: InputTypes.Checkbox,
                label: 'Green Area'
              },
              {
                name: 'optional-typeDescription-Hilly',
                inputType: InputTypes.Checkbox,
                label: 'Hilly'
              },
              {
                name: 'optional-typeDescription-InfillLot',
                inputType: InputTypes.Checkbox,
                label: 'Infill Lot'
              },
              {
                name: 'optional-typeDescription-Level',
                inputType: InputTypes.Checkbox,
                label: 'Level'
              },
              {
                name: 'optional-typeDescription-OnGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'On Golf Course'
              },
              {
                name: 'optional-typeDescription-OpenLot',
                inputType: InputTypes.Checkbox,
                label: 'Open Lot'
              },
              {
                name: 'optional-typeDescription-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-typeDescription-PavedFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Paved Frontage'
              },
              {
                name: 'optional-typeDescription-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },
              {
                name: 'optional-typeDescription-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-typeDescription-Riverfront',
                inputType: InputTypes.Checkbox,
                label: 'Riverfront'
              },
              {
                name: 'optional-typeDescription-RollingSlope',
                inputType: InputTypes.Checkbox,
                label: 'Rolling Slope'
              },
              {
                name: 'optional-typeDescription-RunwayLot',
                inputType: InputTypes.Checkbox,
                label: 'Runway Lot'
              },
              {
                name: 'optional-typeDescription-Sloped',
                inputType: InputTypes.Checkbox,
                label: 'Sloped'
              },
              {
                name: 'optional-typeDescription-SteepSlope',
                inputType: InputTypes.Checkbox,
                label: 'Steep Slope'
              },
              {
                name: 'optional-typeDescription-StreamCreek',
                inputType: InputTypes.Checkbox,
                label: 'Stream / Creek'
              },
              {
                name: 'optional-typeDescription-Trees',
                inputType: InputTypes.Checkbox,
                label: 'Trees'
              },
              {
                name: 'optional-typeDescription-Views',
                inputType: InputTypes.Checkbox,
                label: 'Views'
              },
              {
                name: 'optional-typeDescription-Waterfall',
                inputType: InputTypes.Checkbox,
                label: 'Waterfall'
              },
              {
                name: 'optional-typeDescription-WaterfallArtificial',
                inputType: InputTypes.Checkbox,
                label: 'Waterfall - Artificial'
              },
              {
                name: 'optional-typeDescription-WaterFront',
                inputType: InputTypes.Checkbox,
                label: 'Water Front'
              },
              {
                name: 'optional-typeDescription-Wetlands',
                inputType: InputTypes.Checkbox,
                label: 'Wetlands'
              },
              {
                name: 'optional-typeDescription-Wooded',
                inputType: InputTypes.Checkbox,
                label: 'Wooded'
              },
              {
                name: 'HVAC-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
          ]
        },
        {
          groupTitle: 'Views',
          scope: Scopes.ByPropertyType,
          types: [PropertyTypes.HomeSale,  PropertyTypes.HomeRent,  PropertyTypes.LandSale],
          fields: [    
              {
                name: 'optional-views-CityView',
                inputType: InputTypes.Checkbox,
                label: 'City View'
              },
              {
                name: 'optional-views-GolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course View'
              },
              {
                name: 'optional-views-LongRangeView',
                inputType: InputTypes.Checkbox,
                label: 'Long Range View'
              },
              {
                name: 'optional-views-MountainView',
                inputType: InputTypes.Checkbox,
                label: 'Mountain View'
              },
              {
                name: 'optional-views-WaterView',
                inputType: InputTypes.Checkbox,
                label: 'Water View'
              },
              {
                name: 'optional-views-WinterView',
                inputType: InputTypes.Checkbox,
                label: 'Winter View'
              },
              {
                name: 'optional--views-YearRoundView',
                inputType: InputTypes.Checkbox,
                label: 'Year Round View'
              },
            ]
          },
          {
            groupTitle: 'Fencing',
            scope: Scopes.ByPropertyType,
            types: [PropertyTypes.HomeSale, ,  PropertyTypes.HomeRent, ,  PropertyTypes.LandSale],
            fields: [ 
              {
                name: 'optional-fencing-BackYard',
                inputType: InputTypes.Checkbox,
                label: 'Back Yard'
              },
              {
                name: 'optional-fencing-BarbedWire',
                inputType: InputTypes.Checkbox,
                label: 'Barbed Wire'
              },
              {
                name: 'optional-fencing-YearChainLink',
                inputType: InputTypes.Checkbox,
                label: 'Chain Link'
              },
              {
                name: 'optional-fencing-Electric',
                inputType: InputTypes.Checkbox,
                label: 'Electric'
              },
              {
                name: 'optional-fencing-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-fencing-FrontYard',
                inputType: InputTypes.Checkbox,
                label: 'Front Yard'
              },
              {
                name: 'optional-fencing-FullyFenced',
                inputType: InputTypes.Checkbox,
                label: 'Fully Fenced'
              },
              {
                name: 'optional-fencing-Privacy',
                inputType: InputTypes.Checkbox,
                label: 'Privacy'
              },
              {
                name: 'optional-fencing-Stone',
                inputType: InputTypes.Checkbox,
                label: 'Stone'
              },
              {
                name: 'optional-fencing-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Type / Description',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-AdditionalParcelAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Additional Parcel Available'
              },
              {
                name: 'optional-typeDescription-Beach',
                inputType: InputTypes.Checkbox,
                label: 'Beach'
              },
              {
                name: 'optional-typeDescription-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-typeDescription-Buildable',
                inputType: InputTypes.Checkbox,
                label: 'Buildable'
              },
              {
                name: 'optional-typeDescription-BulkheadRIP',
                inputType: InputTypes.Checkbox,
                label: 'Bulkhead / RIP'
              },
              {
                name: 'optional-typeDescription-CityView',
                inputType: InputTypes.Checkbox,
                label: 'City View'
              },
              {
                name: 'optional-typeDescription-Cleared',
                inputType: InputTypes.Checkbox,
                label: 'Cleared'
              },
              {
                name: 'optional-typeDescription-CornerLot',
                inputType: InputTypes.Checkbox,
                label: 'Corner Lot'
              },
              {
                name: 'optional-typeDescription-Crops',
                inputType: InputTypes.Checkbox,
                label: 'Crops'
              },
              {
                name: 'optional-typeDescription-CulDeSac',
                inputType: InputTypes.Checkbox,
                label: 'Cul-De-Sac'
              },
              {
                name: 'optional-typeDescription-CurbGutters',
                inputType: InputTypes.Checkbox,
                label: 'Curb/Gutters'
              },
              {
                name: 'optional-typeDescription-DeadEndStreet',
                inputType: InputTypes.Checkbox,
                label: 'Dead End Street'
              },
              {
                name: 'optional-typeDescription-FencedEnclosed',
                inputType: InputTypes.Checkbox,
                label: 'Fenced/Enclosed'
              },
              {
                name: 'optional-typeDescription-FlagLot',
                inputType: InputTypes.Checkbox,
                label: 'Flag Lot'
              },
              {
                name: 'optional-typeDescription-FruitTrees',
                inputType: InputTypes.Checkbox,
                label: 'Fruit Trees'
              },
              {
                name: 'optional-typeDescription-Graded',
                inputType: InputTypes.Checkbox,
                label: 'Graded'
              },
              {
                name: 'optional-typeDescription-Irregular',
                inputType: InputTypes.Checkbox,
                label: 'Irregular'
              },
              {
                name: 'optional-typeDescription-Landlocked',
                inputType: InputTypes.Checkbox,
                label: 'Landlocked'
              },
              {
                name: 'optional-typeDescription-Landscaped',
                inputType: InputTypes.Checkbox,
                label: 'Landscaped'
              },
              {
                name: 'optional-typeDescription-Level',
                inputType: InputTypes.Checkbox,
                label: 'Level'
              },
              {
                name: 'optional-typeDescription-OnGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'On Golf Course'
              },
              {
                name: 'optional-typeDescription-ParkLike',
                inputType: InputTypes.Checkbox,
                label: 'Park Like'
              },
              {
                name: 'optional-typeDescription-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-typeDescription-PossibleSubdivision',
                inputType: InputTypes.Checkbox,
                label: 'Possible Subdivision'
              },
              {
                name: 'optional-typeDescription-PossibleWetland',
                inputType: InputTypes.Checkbox,
                label: 'Possible Wetland'
              },
              {
                name: 'optional-typeDescription-RailroadAccess',
                inputType: InputTypes.Checkbox,
                label: 'Railroad Access'
              },
              {
                name: 'optional-typeDescription-RailroadSiding',
                inputType: InputTypes.Checkbox,
                label: 'Railroad Siding'
              },
              {
                name: 'optional-typeDescription-Rolling',
                inputType: InputTypes.Checkbox,
                label: 'Rolling'
              },
              {
                name: 'optional-typeDescription-Sidewalks',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalks'
              },
              {
                name: 'optional-typeDescription-Sloping',
                inputType: InputTypes.Checkbox,
                label: 'Sloping'
              },
              {
                name: 'optional-typeDescription-StreetLights',
                inputType: InputTypes.Checkbox,
                label: 'Street Lights'
              },
              {
                name: 'optional-typeDescription-Subdividable',
                inputType: InputTypes.Checkbox,
                label: 'Subdividable'
              },
              {
                name: 'optional-typeDescription-TimberHardwood',
                inputType: InputTypes.Checkbox,
                label: 'Timber - Hardwood'
              },
              {
                name: 'optional-typeDescription-TimberMixed',
                inputType: InputTypes.Checkbox,
                label: 'Timber - Mixed'
              },
              {
                name: 'optional-typeDescription-TimberPine',
                inputType: InputTypes.Checkbox,
                label: 'Timber - Pine'
              },
              {
                name: 'optional-typeDescription-TimberedClearCut',
                inputType: InputTypes.Checkbox,
                label: 'Timbered - Clear Cut'
              },
              {
                name: 'optional-typeDescription-Unimproved',
                inputType: InputTypes.Checkbox,
                label: 'Unimproved'
              },
              {
                name: 'optional-typeDescription-Waterfront',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront'
              },
              {
                name: 'optional-typeDescription-Waterview',
                inputType: InputTypes.Checkbox,
                label: 'Waterview'
              },
              {
                name: 'optional-typeDescription-Wooded',
                inputType: InputTypes.Checkbox,
                label: 'Wooded'
              },
              {
                name: 'optional-typeDescription-ZeroLotLine',
                inputType: InputTypes.Checkbox,
                label: 'Zero Lot Line'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lot Type / Description',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeDescription-AdditionalLots',
                inputType: InputTypes.Checkbox,
                label: 'Additional Lots'
              },
              {
                name: 'optional-typeDescription-BacksOpenCommonArea',
                inputType: InputTypes.Checkbox,
                label: 'Backs to Open Common Area'
              },
              {
                name: 'optional-typeDescription-BacksParkLand',
                inputType: InputTypes.Checkbox,
                label: 'Backs to Park Land'
              },
              {
                name: 'optional-typeDescription-BacksTrees',
                inputType: InputTypes.Checkbox,
                label: 'Backs to Trees'
              },
              {
                name: 'optional-typeDescription-Bulkheaded',
                inputType: InputTypes.Checkbox,
                label: 'Bulkheaded'
              },
              {
                name: 'optional-typeDescription-Cleared',
                inputType: InputTypes.Checkbox,
                label: 'Cleared'
              },
              {
                name: 'optional-typeDescription-CornerLot',
                inputType: InputTypes.Checkbox,
                label: 'Corner Lot'
              },
              {
                name: 'optional-typeDescription-CropsReserved',
                inputType: InputTypes.Checkbox,
                label: 'Crops Reserved'
              },
              {
                name: 'optional-typeDescription-Cul',
                inputType: InputTypes.Checkbox,
                label: 'Cul-de-sac'
              },
              {
                name: 'optional-typeDescription-FloodPlain',
                inputType: InputTypes.Checkbox,
                label: 'Flood Plain'
              },
              {
                name: 'optional-typeDescription-FrontYard',
                inputType: InputTypes.Checkbox,
                label: 'Front Yard'
              },
              {
                name: 'optional-typeDescription-InteriorLot',
                inputType: InputTypes.Checkbox,
                label: 'Interior Lot'
              },
              {
                name: 'optional-typeDescription-IrregularLot',
                inputType: InputTypes.Checkbox,
                label: 'Irregular Lot'
              },
              {
                name: 'optional-typeDescription-Landlocked',
                inputType: InputTypes.Checkbox,
                label: 'Landlocked'
              },
              {
                name: 'optional-typeDescription-Landscaping',
                inputType: InputTypes.Checkbox,
                label: 'Landscaping'
              },
              {
                name: 'optional-typeDescription-Level',
                inputType: InputTypes.Checkbox,
                label: 'Level'
              },
              {
                name: 'optional-typeDescription-Marshy',
                inputType: InputTypes.Checkbox,
                label: 'Marshy'
              },
              {
                name: 'optional-typeDescription-Mountainous',
                inputType: InputTypes.Checkbox,
                label: 'Mountainous'
              },
              {
                name: 'optional-typeDescription-NoThruStreet',
                inputType: InputTypes.Checkbox,
                label: 'No Thru Street'
              },
              {
                name: 'optional-typeDescription-NonTidalWetland',
                inputType: InputTypes.Checkbox,
                label: 'Non-Tidal Wetland'
              },
              {
                name: 'optional-typeDescription-NotDevelopment',
                inputType: InputTypes.Checkbox,
                label: 'Not in a Development'
              },
              {
                name: 'optional-typeDescription-Open',
                inputType: InputTypes.Checkbox,
                label: 'Open'
              },
              {
                name: 'optional-typeDescription-PartlyWooded',
                inputType: InputTypes.Checkbox,
                label: 'Partly Wooded'
              },
              {
                name: 'optional-typeDescription-Pipestem',
                inputType: InputTypes.Checkbox,
                label: 'Pipestem'
              },
              {
                name: 'optional-typeDescription-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-typeDescription-PUD',
                inputType: InputTypes.Checkbox,
                label: 'PUD'
              },
              {
                name: 'optional-typeDescription-RearYard',
                inputType: InputTypes.Checkbox,
                label: 'Rear Yard'
              },
              {
                name: 'optional-typeDescription-RentedLot',
                inputType: InputTypes.Checkbox,
                label: 'Rented Lot'
              },
              {
                name: 'optional-typeDescription-RipRapped',
                inputType: InputTypes.Checkbox,
                label: 'Rip-Rapped'
              },
              {
                name: 'optional-typeDescription-RoadFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Road Frontage'
              },
              {
                name: 'optional-typeDescription-Rural',
                inputType: InputTypes.Checkbox,
                label: 'Rural'
              },
              {
                name: 'optional-typeDescription-Secluded',
                inputType: InputTypes.Checkbox,
                label: 'Secluded'
              },
              {
                name: 'optional-typeDescription-SideYard',
                inputType: InputTypes.Checkbox,
                label: 'Side Yard(s)'
              },
              {
                name: 'optional-typeDescription-SkiInSkiOut',
                inputType: InputTypes.Checkbox,
                label: 'Ski In / Ski Out'
              },
              {
                name: 'optional-typeDescription-Sloping',
                inputType: InputTypes.Checkbox,
                label: 'Sloping'
              },
              {
                name: 'optional-typeDescription-StreamCreek',
                inputType: InputTypes.Checkbox,
                label: 'Stream/Creek'
              },
              {
                name: 'optional-typeDescription-SubdivisionPossible',
                inputType: InputTypes.Checkbox,
                label: 'Subdivision Possible'
              },
              {
                name: 'optional-typeDescription-TidalWetland',
                inputType: InputTypes.Checkbox,
                label: 'Tidal Wetland'
              },
              {
                name: 'optional-typeDescription-TreesWooded',
                inputType: InputTypes.Checkbox,
                label: 'Trees/Wooded'
              },
              {
                name: 'optional-typeDescription-Unrestricted',
                inputType: InputTypes.Checkbox,
                label: 'Unrestricted'
              },
              {
                name: 'optional-typeDescription-VegetativePlanting',
                inputType: InputTypes.Checkbox,
                label: 'Vegetative Planting'
              },
              {
                name: 'optional-typeDescription-ZeroLotLine',
                inputType: InputTypes.Checkbox,
                label: 'Zero Lot Line'
              },
            ]
          },
        ]
      },

      // View
      {
        fieldsetTitle: 'View',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-views-City',
                inputType: InputTypes.Checkbox,
                label: 'City'
              },
              {
                name: 'optional-views-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-views-Mountains',
                inputType: InputTypes.Checkbox,
                label: 'Mountain(s)'
              },
              {
                name: 'optional-views-Ocean',
                inputType: InputTypes.Checkbox,
                label: 'Ocean'
              },
              {
                name: 'optional-views-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },
              {
                name: 'optional-views-Valley',
                inputType: InputTypes.Checkbox,
                label: 'Valley'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Views',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-views-BayView',
                inputType: InputTypes.Checkbox,
                label: 'Bay View'
              },
              {
                name: 'optional-views-Canal',
                inputType: InputTypes.Checkbox,
                label: 'Canal'
              },
              {
                name: 'optional-views-City',
                inputType: InputTypes.Checkbox,
                label: 'City'
              },
              {
                name: 'optional-views-Courtyard',
                inputType: InputTypes.Checkbox,
                label: 'Courtyard'
              },
              {
                name: 'optional-views-CreekStream',
                inputType: InputTypes.Checkbox,
                label: 'Creek / Stream'
              },
              {
                name: 'optional-views-GardenLawn',
                inputType: InputTypes.Checkbox,
                label: 'Garden / Lawn'
              },
              {
                name: 'optional-views-GolfCourseView',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course View'
              },
              {
                name: 'optional-views-HarborView',
                inputType: InputTypes.Checkbox,
                label: 'Harbor View'
              },
              {
                name: 'optional-views-LakeView',
                inputType: InputTypes.Checkbox,
                label: 'Lake View'
              },
              {
                name: 'optional-views-LimitedView',
                inputType: InputTypes.Checkbox,
                label: 'Limited View'
              },
              {
                name: 'optional-views-MarinaView',
                inputType: InputTypes.Checkbox,
                label: 'Marina View'
              },
              {
                name: 'optional-views-MountainView',
                inputType: InputTypes.Checkbox,
                label: 'Mountain View'
              },
              {
                name: 'optional-views-OceanView',
                inputType: InputTypes.Checkbox,
                label: 'Ocean View'
              },
              {
                name: 'optional-views-PanoramicView',
                inputType: InputTypes.Checkbox,
                label: 'Panoramic View'
              },
              {
                name: 'optional-views-Pasture',
                inputType: InputTypes.Checkbox,
                label: 'Pasture'
              },
              {
                name: 'optional-views-PondView',
                inputType: InputTypes.Checkbox,
                label: 'Pond View'
              },
              {
                name: 'optional-views-RiverView',
                inputType: InputTypes.Checkbox,
                label: 'River View'
              },
              {
                name: 'optional-views-ScenicVistaView',
                inputType: InputTypes.Checkbox,
                label: 'Scenic Vista View'
              },
              {
                name: 'optional-views-TreesWoodsView',
                inputType: InputTypes.Checkbox,
                label: 'Trees / Woods View'
              },
              {
                name: 'optional-views-ValleyView',
                inputType: InputTypes.Checkbox,
                label: 'Valley View'
              },
              {
                name: 'optional-views-WaterView',
                inputType: InputTypes.Checkbox,
                label: 'Water View'
              },
            ]
          },
          {
            groupTitle: 'Location Type',
            fields: [
              {
                name: 'optional-locationType-AdjoinsGolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Adjoins Golf Course'
              },  
              {
                name: 'optional-locationType-AdjoinsNationalPark',
                inputType: InputTypes.Checkbox,
                label: 'Adjoins National Park'
              },  
              {
                name: 'optional-locationType-Bay',
                inputType: InputTypes.Checkbox,
                label: 'Bay'
              },  
              {
                name: 'optional-locationType-CornerLotUnit',
                inputType: InputTypes.Checkbox,
                label: 'Corner Lot / Unit'
              },  
              {
                name: 'optional-locationType-Cove',
                inputType: InputTypes.Checkbox,
                label: 'Cove'
              },  
              {
                name: 'optional-locationType-Creek',
                inputType: InputTypes.Checkbox,
                label: 'Creek'
              },  
              {
                name: 'optional-locationType-Downtown',
                inputType: InputTypes.Checkbox,
                label: 'Downtown'
              },  
              {
                name: 'optional-locationType-GroundLevel',
                inputType: InputTypes.Checkbox,
                label: 'Ground Level'
              },  
              {
                name: 'optional-locationType-Historic',
                inputType: InputTypes.Checkbox,
                label: 'Historic'
              },  
              {
                name: 'optional-locationType-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },  
              {
                name: 'optional-locationType-LowerLevel',
                inputType: InputTypes.Checkbox,
                label: 'Lower Level'
              },  
              {
                name: 'optional-locationType-Mountain',
                inputType: InputTypes.Checkbox,
                label: 'Mountain'
              },  
              {
                name: 'optional-locationType-Ocean',
                inputType: InputTypes.Checkbox,
                label: 'Ocean'
              },  
              {
                name: 'optional-locationType-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },  
              {
                name: 'optional-locationType-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },  
              {
                name: 'optional-locationType-Suburban',
                inputType: InputTypes.Checkbox,
                label: 'Suburban'
              },  
              {
                name: 'optional-locationType-UpperLevel',
                inputType: InputTypes.Checkbox,
                label: 'Upper Level'
              },  
              {
                name: 'optional-locationType-Urban',
                inputType: InputTypes.Checkbox,
                label: 'Urban'
              }, 
            ]
          }
        ]
      },

      {
        fieldsetTitle: 'Frontage',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-propertySettings-BordersUSStatePark',
                inputType: InputTypes.Checkbox,
                label: 'Borders US/State Park'
              },
              {
                name: 'optional-propertySettings-GolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course'
              },
              {
                name: 'optional-propertySettings-LagoonEstuary',
                inputType: InputTypes.Checkbox,
                label: 'Lagoon/Estuary'
              },
              {
                name: 'optional-propertySettings-Lakefront',
                inputType: InputTypes.Checkbox,
                label: 'Lakefront'
              },
              {
                name: 'optional-propertySettings-Marsh',
                inputType: InputTypes.Checkbox,
                label: 'Marsh'
              },
              {
                name: 'optional-propertySettings-Oceanfront',
                inputType: InputTypes.Checkbox,
                label: 'Oceanfront'
              },
              {
                name: 'optional-propertySettings-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },
              {
                name: 'optional-propertySettings-Stream',
                inputType: InputTypes.Checkbox,
                label: 'Stream'
              },
              {
                name: 'optional-propertySettings-Waterfront',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Fencing',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-fencing-BackYard',
                inputType: InputTypes.Checkbox,
                label: 'Back Yard'
              },
              {
                name: 'optional-fencing-Fenced',
                inputType: InputTypes.Checkbox,
                label: 'Fenced'
              },
              {
                name: 'optional-fencing-ChainLink',
                inputType: InputTypes.Checkbox,
                label: 'Chain Link'
              },
              {
                name: 'optional-fencing-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-fencing-FrontYard',
                inputType: InputTypes.Checkbox,
                label: 'Front Yard'
              },
              {
                name: 'optional-fencing-Wood',
                inputType: InputTypes.Checkbox,
                label: 'Wood'
              },
              {
                name: 'optional-fencing-Privacy',
                inputType: InputTypes.Checkbox,
                label: 'Privacy'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Other Structures',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-otherStructures-Barns',
                inputType: InputTypes.Checkbox,
                label: 'Barn(s)'
              },
              {
                name: 'optional-otherStructures-BoatHouse',
                inputType: InputTypes.Checkbox,
                label: 'Boat House'
              },
              {
                name: 'optional-otherStructures-CoveredDock',
                inputType: InputTypes.Checkbox,
                label: 'Covered Dock'
              },
              {
                name: 'optional-otherStructures-Garages',
                inputType: InputTypes.Checkbox,
                label: 'Garage(s)'
              },
              {
                name: 'optional-otherStructures-Gazebo',
                inputType: InputTypes.Checkbox,
                label: 'Gazebo'
              },
              {
                name: 'optional-otherStructures-Greenhouse',
                inputType: InputTypes.Checkbox,
                label: 'Greenhouse'
              },
              {
                name: 'optional-otherStructures-GuestHouse',
                inputType: InputTypes.Checkbox,
                label: 'Guest House'
              },
              {
                name: 'optional-otherStructures-KennelDogRun',
                inputType: InputTypes.Checkbox,
                label: 'Kennel/Dog Run'
              },
              {
                name: 'optional-otherStructures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-otherStructures-Outbuilding',
                inputType: InputTypes.Checkbox,
                label: 'Outbuilding'
              },
              {
                name: 'optional-otherStructures-OutdoorKitchen',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Kitchen'
              },
              {
                name: 'optional-otherStructures-PoolHouse',
                inputType: InputTypes.Checkbox,
                label: 'Pool House'
              },
              {
                name: 'optional-otherStructures-SecondGarage',
                inputType: InputTypes.Checkbox,
                label: 'Second Garage'
              },
              {
                name: 'optional-otherStructures-SecondResidence',
                inputType: InputTypes.Checkbox,
                label: 'Second Residence'
              },
              {
                name: 'optional-otherStructures-Sheds',
                inputType: InputTypes.Checkbox,
                label: 'Shed(s)'
              },
              {
                name: 'optional-otherStructures-Stables',
                inputType: InputTypes.Checkbox,
                label: 'Stable(s)'
              },
              {
                name: 'optional-otherStructures-StationaryDock',
                inputType: InputTypes.Checkbox,
                label: 'Stationary Dock'
              },
              {
                name: 'optional-otherStructures-TennisCourts',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court(s)'
              },
              {
                name: 'optional-otherStructures-Workshop',
                inputType: InputTypes.Checkbox,
                label: 'Workshop'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Waterfront Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterfrontFeatures-CorpsofEnginersControl',
                inputType: InputTypes.Checkbox,
                label: 'Corps of Enginers Control'
              },
              {
                name: 'optional-waterfrontFeatures-Creek',
                inputType: InputTypes.Checkbox,
                label: 'Creek'
              },
              {
                name: 'optional-waterfrontFeatures-DeepWaterAccess',
                inputType: InputTypes.Checkbox,
                label: 'Deep Water Access'
              },
              {
                name: 'optional-waterfrontFeatures-DockRights',
                inputType: InputTypes.Checkbox,
                label: 'Dock Rights'
              },
              {
                name: 'optional-waterfrontFeatures-FloatingDock',
                inputType: InputTypes.Checkbox,
                label: 'Floating Dock'
              },
              {
                name: 'optional-waterfrontFeatures-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-waterfrontFeatures-LakeAccess',
                inputType: InputTypes.Checkbox,
                label: 'Lake Access'
              },
              {
                name: 'optional-waterfrontFeatures-NoDockOrBoathouse',
                inputType: InputTypes.Checkbox,
                label: 'No Dock Or Boathouse'
              },
              {
                name: 'optional-waterfrontFeatures-NoDockRights',
                inputType: InputTypes.Checkbox,
                label: 'No Dock Rights'
              },
              {
                name: 'optional-waterfrontFeatures-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },
              {
                name: 'optional-waterfrontFeatures-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-waterfrontFeatures-Seawall',
                inputType: InputTypes.Checkbox,
                label: 'Seawall'
              },
              {
                name: 'optional-waterfrontFeatures-SwimDock',
                inputType: InputTypes.Checkbox,
                label: 'Swim Dock'
              },
              {
                name: 'optional-waterfrontFeatures-Tidal',
                inputType: InputTypes.Checkbox,
                label: 'Tidal'
              },
              {
                name: 'optional-waterfrontFeatures-UtilityCompanyControl',
                inputType: InputTypes.Checkbox,
                label: 'Utility Company Control'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Boathouse / Dock Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'totalDockSlips',
                inputType: InputTypes.Number,
                label: 'Total Dock Slips'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'HOA Fee Includes',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-HOAFeeIncludes-FacilitiesFee',
                inputType: InputTypes.Checkbox,
                label: 'Facilities Fee'
              },
              {
                name: 'optional-HOAFeeIncludes-HeatingCooling',
                inputType: InputTypes.Checkbox,
                label: 'Heating/Cooling'
              },
              {
                name: 'optional-HOAFeeIncludes-Insurance',
                inputType: InputTypes.Checkbox,
                label: 'Insurance'
              },
              {
                name: 'optional-HOAFeeIncludes-MaintenanceExterior',
                inputType: InputTypes.Checkbox,
                label: 'Maintenance Exterior'
              },
              {
                name: 'optional-HOAFeeIncludes-MaintenanceGrounds',
                inputType: InputTypes.Checkbox,
                label: 'Maintenance Grounds'
              },
              {
                name: 'optional-HOAFeeIncludes-ManagementFee',
                inputType: InputTypes.Checkbox,
                label: 'Management Fee'
              },
              {
                name: 'optional-HOAFeeIncludes-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-HOAFeeIncludes-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
              {
                name: 'optional-HOAFeeIncludes-PestControl',
                inputType: InputTypes.Checkbox,
                label: 'Pest Control'
              },
              {
                name: 'optional-HOAFeeIncludes-PrivateRoads',
                inputType: InputTypes.Checkbox,
                label: 'Private Roads'
              },
              {
                name: 'optional-HOAFeeIncludes-ReserveFund',
                inputType: InputTypes.Checkbox,
                label: 'Reserve Fund'
              },
              {
                name: 'optional-HOAFeeIncludes-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-HOAFeeIncludes-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer'
              },
              {
                name: 'optional-HOAFeeIncludes-Swimming',
                inputType: InputTypes.Checkbox,
                label: 'Swimming'
              },
              {
                name: 'optional-HOAFeeIncludes-Tennis',
                inputType: InputTypes.Checkbox,
                label: 'Tennis'
              },
              {
                name: 'optional-HOAFeeIncludes-Trash',
                inputType: InputTypes.Checkbox,
                label: 'Trash'
              },
              {
                name: 'optional-HOAFeeIncludes-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'HOA Fee Includes',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-HOAFeeIncludes-ElectricCommon',
                inputType: InputTypes.Checkbox,
                label: 'Electric Common'
              },
              {
                name: 'optional-HOAFeeIncludes-WaterSewer',
                inputType: InputTypes.Checkbox,
                label: 'Water and Sewer'
              },
              {
                name: 'optional-HOAFeeIncludes-TrashPickup',
                inputType: InputTypes.Checkbox,
                label: 'Trash Pickup'
              },
              {
                name: 'optional-HOAFeeIncludes-PoolService',
                inputType: InputTypes.Checkbox,
                label: 'Pool Service'
              },
              {
                name: 'optional-HOAFeeIncludes-LandscapeLawn',
                inputType: InputTypes.Checkbox,
                label: 'Landscape/Lawn'
              },
              {
                name: 'optional-HOAFeeIncludes-Insurance',
                inputType: InputTypes.Checkbox,
                label: 'Insurance'
              },
              {
                name: 'optional-HOAFeeIncludes-Manager',
                inputType: InputTypes.Checkbox,
                label: 'Manager'
              },
              {
                name: 'optional-HOAFeeIncludes-CommonMaintRepair',
                inputType: InputTypes.Checkbox,
                label: 'Common Maint/Repair'
              },
              {
                name: 'optional-HOAFeeIncludes-Golf',
                inputType: InputTypes.Checkbox,
                label: 'Golf'
              },
              {
                name: 'optional-HOAFeeIncludes-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-HOAFeeIncludes-RecreationFacilities',
                inputType: InputTypes.Checkbox,
                label: 'Recreation Facilities'
              },
              {
                name: 'optional-HOAFeeIncludes-LegalAccounting',
                inputType: InputTypes.Checkbox,
                label: 'Legal and Accounting'
              },
              {
                name: 'optional-HOAFeeIncludes-PrimaryAntennaCableTV',
                inputType: InputTypes.Checkbox,
                label: 'Primary Antenna/Cable TV'
              },
              {
                name: 'optional-HOAFeeIncludes-Recycling',
                inputType: InputTypes.Checkbox,
                label: 'Recycling'
              },
              {
                name: 'optional-HOAFeeIncludes-InternetAccess',
                inputType: InputTypes.Checkbox,
                label: 'Internet Access'
              },
              {
                name: 'optional-HOAFeeIncludes-PestControl',
                inputType: InputTypes.Checkbox,
                label: 'Pest Control'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'HOA and Neighborhood Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-HOANeighbordhoodAmenites-Basketball',
                inputType: InputTypes.Checkbox,
                label: 'Basketball'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-BoatSlipAssign',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip - Assign'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-BoatSlipNotAssigned',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip - Not Assigned'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Cable',
                inputType: InputTypes.Checkbox,
                label: 'Cable'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Clubhouse',
                inputType: InputTypes.Checkbox,
                label: 'Clubhouse'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-CommunityGarden',
                inputType: InputTypes.Checkbox,
                label: 'Community Garden'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Dock',
                inputType: InputTypes.Checkbox,
                label: 'Dock'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-FitnessCenter',
                inputType: InputTypes.Checkbox,
                label: 'Fitness Center'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-FloodInsurance',
                inputType: InputTypes.Checkbox,
                label: 'Flood Insurance'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-GatedCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Gated Community'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-GolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-HeatingCooling',
                inputType: InputTypes.Checkbox,
                label: 'Heating/Cooling'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-HorseFacility',
                inputType: InputTypes.Checkbox,
                label: 'Horse Facility'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-HorsesAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Horses Allowed'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-LaundryFacility',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Facility'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-MaintCommAreas',
                inputType: InputTypes.Checkbox,
                label: 'Maint - Comm Areas'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-MaintExtBldg',
                inputType: InputTypes.Checkbox,
                label: 'Maint - Ext Bldg'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-MaintGrounds',
                inputType: InputTypes.Checkbox,
                label: 'Maint - Grounds'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-MaintRoads',
                inputType: InputTypes.Checkbox,
                label: 'Maint - Roads'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Marina',
                inputType: InputTypes.Checkbox,
                label: 'Marina'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-PrimaryInsurance',
                inputType: InputTypes.Checkbox,
                label: 'Primary Insurance'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-ManagementFee',
                inputType: InputTypes.Checkbox,
                label: 'Management Fee'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-PestControl',
                inputType: InputTypes.Checkbox,
                label: 'Pest Control'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-PicnicGrounds',
                inputType: InputTypes.Checkbox,
                label: 'Picnic Grounds'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Playground',
                inputType: InputTypes.Checkbox,
                label: 'Playground'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-PoolIndoor',
                inputType: InputTypes.Checkbox,
                label: 'Pool - Indoor'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-PoolCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Pool - Community'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Ramp',
                inputType: InputTypes.Checkbox,
                label: 'Ramp'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Restaurant',
                inputType: InputTypes.Checkbox,
                label: 'Restaurant'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Roof',
                inputType: InputTypes.Checkbox,
                label: 'Roof'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Sewer',
                inputType: InputTypes.Checkbox,
                label: 'Sewer'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Sidewalk',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalk'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Storage',
                inputType: InputTypes.Checkbox,
                label: 'Storage'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-StorageLot',
                inputType: InputTypes.Checkbox,
                label: 'Storage Lot'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-StreetLights',
                inputType: InputTypes.Checkbox,
                label: 'Street Lights'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-TaxCommAreas',
                inputType: InputTypes.Checkbox,
                label: 'Tax Comm Areas'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Tennis',
                inputType: InputTypes.Checkbox,
                label: 'Tennis'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-TermiteBond',
                inputType: InputTypes.Checkbox,
                label: 'Termite Bond'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Trails',
                inputType: InputTypes.Checkbox,
                label: 'Trails'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-TrashRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Trash Removal'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-NoPets',
                inputType: InputTypes.Checkbox,
                label: 'No Pets'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-NoTenantPets',
                inputType: InputTypes.Checkbox,
                label: 'No Tenant Pets'
              },
              {
                name: 'optional-HOANeighbordhoodAmenites-OwnerPetsOnly',
                inputType: InputTypes.Checkbox,
                label: 'Owner Pets Only'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Lake / Water Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-lakeAmenities-Beach',
                inputType: InputTypes.Checkbox,
                label: 'Beach'
              },
              {
                name: 'optional-lakeAmenities-BeachCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Beach Community'
              },
              {
                name: 'optional-lakeAmenities-BoatHouse',
                inputType: InputTypes.Checkbox,
                label: 'Boat House'
              },
              {
                name: 'optional-lakeAmenities-BoatLift',
                inputType: InputTypes.Checkbox,
                label: 'Boat Lift'
              },
              {
                name: 'optional-lakeAmenities-BoatLiftCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Boat Lift Community'
              },
              {
                name: 'optional-lakeAmenities-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-lakeAmenities-BoatRampCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp Community'
              },
              {
                name: 'optional-lakeAmenities-BoatSlipCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip Community'
              },
              {
                name: 'optional-lakeAmenities-BoatSlipDeed',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip (Deed)'
              },
              {
                name: 'optional-lakeAmenities-BoatSlipLeaseLicense',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip (Lease/License)'
              },
              {
                name: 'optional-lakeAmenities-CoveredStructure',
                inputType: InputTypes.Checkbox,
                label: 'Covered structure'
              },
              {
                name: 'optional-lakeAmenities-DockCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Dock - Community'
              },
              {
                name: 'optional-lakeAmenities-FloatingDock',
                inputType: InputTypes.Checkbox,
                label: 'Floating Dock'
              },
              {
                name: 'optional-lakeAmenities-PaddlesportLaunchSite',
                inputType: InputTypes.Checkbox,
                label: 'Paddlesport Launch Site'
              },
              {
                name: 'optional-lakeAmenities-PaddlesportLaunchSiteCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Paddlesport Launch Site - Community'
              },
              {
                name: 'optional-lakeAmenities-PersonalWatercraftLift',
                inputType: InputTypes.Checkbox,
                label: 'Personal Watercraft Lift'
              },
              {
                name: 'optional-lakeAmenities-Pier',
                inputType: InputTypes.Checkbox,
                label: 'Pier'
              },
              {
                name: 'optional-lakeAmenities-PierCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Pier - Community'
              },
              {
                name: 'optional-lakeAmenities-RetainingWall',
                inputType: InputTypes.Checkbox,
                label: 'Retaining Wall'
              },
              {
                name: 'optional-lakeAmenities-StationaryDock',
                inputType: InputTypes.Checkbox,
                label: 'Stationary Dock'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Amenities Available',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-amenitiesAvailable-HomeOwnersAssocFees',
                inputType: InputTypes.Checkbox,
                label: 'Home Owners Assoc. Fees'
              },
              {
                name: 'optional-amenitiesAvailable-Restrictions',
                inputType: InputTypes.Checkbox,
                label: 'Restrictions'
              },
              {
                name: 'optional-amenitiesAvailable-ClubhouseRecFacilities',
                inputType: InputTypes.Checkbox,
                label: 'Clubhouse/Rec/Facilities'
              },
              {
                name: 'optional-amenitiesAvailable-Pool',
                inputType: InputTypes.Checkbox,
                label: 'Pool'
              },
              {
                name: 'optional-amenitiesAvailable-TennisCourt',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court'
              },
              {
                name: 'optional-amenitiesAvailable-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-amenitiesAvailable-GatedCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Gated Community'
              },
              {
                name: 'optional-amenitiesAvailable-ShortTermRentalAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Short Term Rental Allowed'
              },
              {
                name: 'optional-amenitiesAvailable-LongTermRentalAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Long Term Rental Allowed'
              },
              {
                name: 'optional-amenitiesAvailable-OwnerAllowedMotorcycle',
                inputType: InputTypes.Checkbox,
                label: 'Owner Allowed Motorcycle'
              },
              {
                name: 'optional-amenitiesAvailable-TenantAllowedMotorcycle',
                inputType: InputTypes.Checkbox,
                label: 'Tenant Allowed Motorcycle'
              },
              {
                name: 'optional-amenitiesAvailable-IndoorPool',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Pool'
              },
              {
                name: 'optional-amenitiesAvailable-OwnerAllowedGolfCart',
                inputType: InputTypes.Checkbox,
                label: 'Owner Allowed Golf Cart'
              },
              {
                name: 'optional-amenitiesAvailable-TenantAllowedGolfCart',
                inputType: InputTypes.Checkbox,
                label: 'Tenant Allowed Golf Cart'
              },
              {
                name: 'optional-amenitiesAvailable-OwnerAllowedPet',
                inputType: InputTypes.Checkbox,
                label: 'Owner Allowed Pet'
              },
              {
                name: 'optional-amenitiesAvailable-PrivateBeachCommAccess',
                inputType: InputTypes.Checkbox,
                label: 'Private Beach Comm Access'
              },
              {
                name: 'optional-amenitiesAvailable-PrivateBoatSlip',
                inputType: InputTypes.Checkbox,
                label: 'Private Boat Slip'
              },
              {
                name: 'optional-amenitiesAvailable-BoatDock',
                inputType: InputTypes.Checkbox,
                label: 'Boat Dock'
              },
              {
                name: 'optional-amenitiesAvailable-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
            ]
          }
        ]
      },

      // Community amenities
      {
        fieldsetTitle: 'Community Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [ 
              {
                name: 'optional-communityAmenities-AirportRunway',
                inputType: InputTypes.Checkbox,
                label: 'Airport/Runway'
              },
              {
                name: 'optional-communityAmenities-BoatCamperVanPrkg',
                inputType: InputTypes.Checkbox,
                label: 'Boat/Camper/Van Prkg'
              },
              {
                name: 'optional-communityAmenities-Clubhouse',
                inputType: InputTypes.Checkbox,
                label: 'Clubhouse'
              },
              {
                name: 'optional-communityAmenities-FitnessCenter',
                inputType: InputTypes.Checkbox,
                label: 'Fitness Center'
              },
              {
                name: 'optional-communityAmenities-Gated',
                inputType: InputTypes.Checkbox,
                label: 'Gated'
              },
              {
                name: 'optional-communityAmenities-Golf',
                inputType: InputTypes.Checkbox,
                label: 'Golf'
              },
              {
                name: 'optional-communityAmenities-GuestLodging',
                inputType: InputTypes.Checkbox,
                label: 'Guest Lodging'
              },
              {
                name: 'optional-communityAmenities-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-communityAmenities-Marina',
                inputType: InputTypes.Checkbox,
                label: 'Marina'
              },
              {
                name: 'optional-communityAmenities-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-communityAmenities-Park',
                inputType: InputTypes.Checkbox,
                label: 'Park'
              },
              {
                name: 'optional-communityAmenities-Playground',
                inputType: InputTypes.Checkbox,
                label: 'Playground'
              },
              {
                name: 'optional-communityAmenities-Pool',
                inputType: InputTypes.Checkbox,
                label: 'Pool'
              },
              {
                name: 'optional-communityAmenities-Racquetball',
                inputType: InputTypes.Checkbox,
                label: 'Racquetball'
              },
              {
                name: 'optional-communityAmenities-RetirementCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Retirement Community'
              },
              {
                name: 'optional-communityAmenities-SharedDock',
                inputType: InputTypes.Checkbox,
                label: 'Shared Dock'
              },
              {
                name: 'optional-communityAmenities-Sidewalks',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalks'
              },
              {
                name: 'optional-communityAmenities-Stables',
                inputType: InputTypes.Checkbox,
                label: 'Stable(s)'
              },
              {
                name: 'optional-communityAmenities-StreetLights',
                inputType: InputTypes.Checkbox,
                label: 'Street Lights'
              },
              {
                name: 'optional-communityAmenities-SwimTeam',
                inputType: InputTypes.Checkbox,
                label: 'Swim Team'
              },
              {
                name: 'optional-communityAmenities-TennisCourts',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court(s)'
              },
              {
                name: 'optional-communityAmenities-TennisTeam',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Team'
              },
              {
                name: 'optional-communityAmenities-WalktoPublicTransit',
                inputType: InputTypes.Checkbox,
                label: 'Walk to Public Transit'
              },
              {
                name: 'optional-communityAmenities-WalkToSchools',
                inputType: InputTypes.Checkbox,
                label: 'Walk To Schools'
              },
              {
                name: 'optional-communityAmenities-WalkToShopping',
                inputType: InputTypes.Checkbox,
                label: 'Walk To Shopping'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Community Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Coastal]: [PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-communityAmenities-HomeOwnersAssocFees',
                inputType: InputTypes.Checkbox,
                label: 'Home Owners Assoc. Fees'
              },
              {
                name: 'optional-communityAmenities-Restrictions',
                inputType: InputTypes.Checkbox,
                label: 'Restrictions'
              },
              {
                name: 'optional-communityAmenities-ClubhouseRecFacilities',
                inputType: InputTypes.Checkbox,
                label: 'Clubhouse/Rec/Facilities'
              },
              {
                name: 'optional-communityAmenities-Pool',
                inputType: InputTypes.Checkbox,
                label: 'Pool'
              },
              {
                name: 'optional-communityAmenities-TennisCourt',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court'
              },
              {
                name: 'optional-communityAmenities-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-communityAmenities-MobileHomePermitted',
                inputType: InputTypes.Checkbox,
                label: 'Mobile Home Permitted'
              },
              {
                name: 'optional-communityAmenities-GatedCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Gated Community'
              },
              {
                name: 'optional-communityAmenities-ShortTermRentalAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Short Term Rental Allowed'
              },
              {
                name: 'optional-communityAmenities-LongTermRentalAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Long Term Rental Allowed'
              },
              {
                name: 'optional-communityAmenities-ModularHomePermitted',
                inputType: InputTypes.Checkbox,
                label: 'Modular Home Permitted'
              },
              {
                name: 'optional-communityAmenities-OwnerAllowedMotorcycle',
                inputType: InputTypes.Checkbox,
                label: 'Owner Allowed Motorcycle'
              },
              {
                name: 'optional-communityAmenities-TenantAllowedMotorcycle',
                inputType: InputTypes.Checkbox,
                label: 'Tenant Allowed Motorcycle'
              },
              {
                name: 'optional-communityAmenities-OwnerAllowedGolfCart',
                inputType: InputTypes.Checkbox,
                label: 'Owner Allowed Golf Cart'
              },
              {
                name: 'optional-communityAmenities-TenantAllowedGolfCart',
                inputType: InputTypes.Checkbox,
                label: 'Tenant Allowed Golf Cart'
              },
              {
                name: 'optional-communityAmenities-OwnerAllowedPet',
                inputType: InputTypes.Checkbox,
                label: 'Owner Allowed Pet'
              },
              {
                name: 'optional-communityAmenities-PrivateBeachCommAccess',
                inputType: InputTypes.Checkbox,
                label: 'Private Beach Comm Access'
              },
              {
                name: 'optional-communityAmenities-PrivateBoatSlip',
                inputType: InputTypes.Checkbox,
                label: 'Private Boat Slip'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Community Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-communityAmenities-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-communityAmenities-BoatStorage',
                inputType: InputTypes.Checkbox,
                label: 'Boat Storage'
              },
              {
                name: 'optional-communityAmenities-BusLine',
                inputType: InputTypes.Checkbox,
                label: 'Bus Line'
              },
              {
                name: 'optional-communityAmenities-CableTVAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV Available'
              },
              {
                name: 'optional-communityAmenities-CentralTVAntenna',
                inputType: InputTypes.Checkbox,
                label: 'Central TV Antenna'
              },
              {
                name: 'optional-communityAmenities-ClubHouse',
                inputType: InputTypes.Checkbox,
                label: 'Club House'
              },
              {
                name: 'optional-communityAmenities-ClubMembershipAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Club Membership Available'
              },
              {
                name: 'optional-communityAmenities-DockFacilities',
                inputType: InputTypes.Checkbox,
                label: 'Dock Facilities'
              },
              {
                name: 'optional-communityAmenities-Elevators',
                inputType: InputTypes.Checkbox,
                label: 'Elevators'
              },
              {
                name: 'optional-communityAmenities-EquestrianCenter',
                inputType: InputTypes.Checkbox,
                label: 'Equestrian Center'
              },
              {
                name: 'optional-communityAmenities-ExerciseArea',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Area'
              },
              {
                name: 'optional-communityAmenities-ExtraStorage',
                inputType: InputTypes.Checkbox,
                label: 'Extra Storage'
              },
              {
                name: 'optional-communityAmenities-GatedCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Gated Community'
              },
              {
                name: 'optional-communityAmenities-Golf',
                inputType: InputTypes.Checkbox,
                label: 'Golf'
              },
              {
                name: 'optional-communityAmenities-GolfMembershipAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Golf Membership Available'
              },
              {
                name: 'optional-communityAmenities-HandicappedEquipped',
                inputType: InputTypes.Checkbox,
                label: 'Handicapped Equipped'
              },
              {
                name: 'optional-communityAmenities-HorsesOK',
                inputType: InputTypes.Checkbox,
                label: 'Horses OK'
              },
              {
                name: 'optional-communityAmenities-HotTubs',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tubs'
              },
              {
                name: 'optional-communityAmenities-LaundryFacility',
                inputType: InputTypes.Checkbox,
                label: 'Laundry Facility'
              },
              {
                name: 'optional-communityAmenities-LawnMaintIncluded',
                inputType: InputTypes.Checkbox,
                label: 'Lawn Maint Included'
              },
              {
                name: 'optional-communityAmenities-Marina',
                inputType: InputTypes.Checkbox,
                label: 'Marina'
              },
              {
                name: 'optional-communityAmenities-NeighborhoodPool',
                inputType: InputTypes.Checkbox,
                label: 'Neighborhood Pool'
              },
              {
                name: 'optional-communityAmenities-PlayPark',
                inputType: InputTypes.Checkbox,
                label: 'Play Park'
              },
              {
                name: 'optional-communityAmenities-RV',
                inputType: InputTypes.Checkbox,
                label: 'RV'
              },
              {
                name: 'optional-communityAmenities-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-communityAmenities-SecurityGuardSys',
                inputType: InputTypes.Checkbox,
                label: 'Security Guard/Sys'
              },
              {
                name: 'optional-communityAmenities-TennisCourt',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court'
              },
              {
                name: 'optional-communityAmenities-TrashPickup',
                inputType: InputTypes.Checkbox,
                label: 'Trash Pickup'
              },
              {
                name: 'optional-communityAmenities-WalkJogTrails',
                inputType: InputTypes.Checkbox,
                label: 'Walk/Jog Trails'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Community Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.LandSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-communityAmenities-ClubHouse',
                inputType: InputTypes.Checkbox,
                label: 'Club House'
              },
              {
                name: 'optional-communityAmenities-Curbs',
                inputType: InputTypes.Checkbox,
                label: 'Curbs'
              },
              {
                name: 'optional-communityAmenities-Fishing',
                inputType: InputTypes.Checkbox,
                label: 'Fishing'
              },
              {
                name: 'optional-communityAmenities-FitnessCenter',
                inputType: InputTypes.Checkbox,
                label: 'Fitness Center'
              },
              {
                name: 'optional-communityAmenities-Gated',
                inputType: InputTypes.Checkbox,
                label: 'Gated'
              },
              {
                name: 'optional-communityAmenities-Golf',
                inputType: InputTypes.Checkbox,
                label: 'Golf'
              },
              {
                name: 'optional-communityAmenities-HistoricalArea',
                inputType: InputTypes.Checkbox,
                label: 'Historical Area'
              },
              {
                name: 'optional-communityAmenities-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-communityAmenities-Park',
                inputType: InputTypes.Checkbox,
                label: 'Park'
              },
              {
                name: 'optional-communityAmenities-Playground',
                inputType: InputTypes.Checkbox,
                label: 'Playground'
              },
              {
                name: 'optional-communityAmenities-Pool',
                inputType: InputTypes.Checkbox,
                label: 'Pool'
              },
              {
                name: 'optional-communityAmenities-Racquetball',
                inputType: InputTypes.Checkbox,
                label: 'Racquetball'
              },
              {
                name: 'optional-communityAmenities-Sidewalks',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalks'
              },
              {
                name: 'optional-communityAmenities-Stables',
                inputType: InputTypes.Checkbox,
                label: 'Stable(s)'
              },
              {
                name: 'optional-communityAmenities-StreetLights',
                inputType: InputTypes.Checkbox,
                label: 'Street Lights'
              },
              {
                name: 'optional-communityAmenities-TennisCourts',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court(s)'
              },           
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Community Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-communityAmenities-55Older',
                inputType: InputTypes.Checkbox,
                label: '55 and Older'
              },
              {
                name: 'optional-communityAmenities-AirportRunway',
                inputType: InputTypes.Checkbox,
                label: 'Airport / Runway'
              },
              {
                name: 'optional-communityAmenities-BusinessCenter',
                inputType: InputTypes.Checkbox,
                label: 'Business Center'
              },
              {
                name: 'optional-communityAmenities-Cabana',
                inputType: InputTypes.Checkbox,
                label: 'Cabana'
              },
              {
                name: 'optional-communityAmenities-ClubHouse',
                inputType: InputTypes.Checkbox,
                label: 'Club House'
              },
              {
                name: 'optional-communityAmenities-Concierge',
                inputType: InputTypes.Checkbox,
                label: 'Concierge'
              },
              {
                name: 'optional-communityAmenities-DogPark',
                inputType: InputTypes.Checkbox,
                label: 'Dog Park'
              },
              {
                name: 'optional-communityAmenities-EquestrianFacilities',
                inputType: InputTypes.Checkbox,
                label: 'Equestrian Facilities'
              },
              {
                name: 'optional-communityAmenities-EquestrianTrails',
                inputType: InputTypes.Checkbox,
                label: 'Equestrian Trails'
              },
              {
                name: 'optional-communityAmenities-FitnessCenter',
                inputType: InputTypes.Checkbox,
                label: 'Fitness Center'
              },
              {
                name: 'optional-communityAmenities-GameCourt',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                label: 'Game Court'
              },
              {
                name: 'optional-communityAmenities-GatedCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Gated Community'
              },
              {
                name: 'optional-communityAmenities-GolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course'
              },
              {
                name: 'optional-communityAmenities-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-communityAmenities-IndoorPool',
                inputType: InputTypes.Checkbox,
                label: 'Indoor Pool'
              },
              {
                name: 'optional-communityAmenities-LakeAccess',
                inputType: InputTypes.Checkbox,
                label: 'Lake Access'
              },
              {
                name: 'optional-communityAmenities-OutdoorPool',
                inputType: InputTypes.Checkbox,
                label: 'Outdoor Pool'
              },
              {
                name: 'optional-communityAmenities-PicnicArea',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                label: 'Picnic Area'
              },
              {
                name: 'optional-communityAmenities-Playground',
                inputType: InputTypes.Checkbox,
                label: 'Playground'
              },
              {
                name: 'optional-communityAmenities-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },
              {
                name: 'optional-communityAmenities-PuttingGreen',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Putting Green'
              },
              {
                name: 'optional-communityAmenities-RecreationArea',
                inputType: InputTypes.Checkbox,
                label: 'Recreation Area'
              },
              {
                name: 'optional-communityAmenities-RooftopTerrace',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                label: 'Rooftop Terrace'
              },
              {
                name: 'optional-communityAmenities-RVBoat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'RV Boat Storage'
              },
              {
                name: 'optional-communityAmenities-Sauna',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Sauna'
              },
              {
                name: 'optional-communityAmenities-Sidewalks',
                inputType: InputTypes.Checkbox,
                label: 'Sidewalks'
              },
              {
                name: 'optional-communityAmenities-SkiSlopes',
                inputType: InputTypes.Checkbox,
                label: 'Ski Slopes'
              },
              {
                name: 'optional-communityAmenities-SportCourt',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                label: 'Sport Court'
              },
              {
                name: 'optional-communityAmenities-Stables',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
                label: 'Stables'
              },
              {
                name: 'optional-communityAmenities-StreetLights',
                inputType: InputTypes.Checkbox,
                label: 'Street Lights'
              },
              {
                name: 'optional-communityAmenities-TennisCourts',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Courts'
              },
              {
                name: 'optional-communityAmenities-WalkingTrails',
                inputType: InputTypes.Checkbox,
                label: 'Walking Trails'
              },
              {
                name: 'optional-communityAmenities-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Community Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-communityAmenities-Association',
                inputType: InputTypes.Checkbox,
                label: 'Association'
              },
              {
                name: 'optional-communityAmenities-BasketballCourts',
                inputType: InputTypes.Checkbox,
                label: 'Basketball Courts'
              },
              {
                name: 'optional-communityAmenities-Beach',
                inputType: InputTypes.Checkbox,
                label: 'Beach'
              },
              {
                name: 'optional-communityAmenities-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-communityAmenities-ClubHouse',
                inputType: InputTypes.Checkbox,
                label: 'Club House'
              },
              {
                name: 'optional-communityAmenities-CommonArea',
                inputType: InputTypes.Checkbox,
                label: 'Common Area'
              },
              {
                name: 'optional-communityAmenities-CommonLaundry',
                inputType: InputTypes.Checkbox,
                label: 'Common Laundry'
              },
              {
                name: 'optional-communityAmenities-CommunityRoom',
                inputType: InputTypes.Checkbox,
                label: 'Community Room'
              },
              {
                name: 'optional-communityAmenities-ControlledAccess',
                inputType: InputTypes.Checkbox,
                label: 'Controlled Access'
              },
              {
                name: 'optional-communityAmenities-Dock',
                inputType: InputTypes.Checkbox,
                label: 'Dock'
              },
              {
                name: 'optional-communityAmenities-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-communityAmenities-ExerciseRoom',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Room'
              },
              {
                name: 'optional-communityAmenities-ExtraStorage',
                inputType: InputTypes.Checkbox,
                label: 'Extra Storage'
              },
              {
                name: 'optional-communityAmenities-GatedCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Gated Community'
              },
              {
                name: 'optional-communityAmenities-GolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course'
              },
              {
                name: 'optional-communityAmenities-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-communityAmenities-JogWalkPath',
                inputType: InputTypes.Checkbox,
                label: 'Jog / Walk Path'
              },
              {
                name: 'optional-communityAmenities-KiddiePool',
                inputType: InputTypes.Checkbox,
                label: 'Kiddie Pool'
              },
              {
                name: 'optional-communityAmenities-LakePond',
                inputType: InputTypes.Checkbox,
                label: 'Lake/Pond'
              },
              {
                name: 'optional-communityAmenities-Landscaping',
                inputType: InputTypes.Checkbox,
                label: 'Landscaping'
              },
              {
                name: 'optional-communityAmenities-Lifeguard',
                inputType: InputTypes.Checkbox,
                label: 'Lifeguard'
              },
              {
                name: 'optional-communityAmenities-Marina',
                inputType: InputTypes.Checkbox,
                label: 'Marina'
              },
              {
                name: 'optional-communityAmenities-OnBusLine',
                inputType: InputTypes.Checkbox,
                label: 'On Bus Line'
              },
              {
                name: 'optional-communityAmenities-PicnicArea',
                inputType: InputTypes.Checkbox,
                label: 'Picnic Area'
              },
              {
                name: 'optional-communityAmenities-Playground',
                inputType: InputTypes.Checkbox,
                label: 'Playground'
              },
              {
                name: 'optional-communityAmenities-Pool',
                inputType: InputTypes.Checkbox,
                label: 'Pool'
              },
              {
                name: 'optional-communityAmenities-ProfessionalManagement',
                inputType: InputTypes.Checkbox,
                label: 'Professional Management'
              },
              {
                name: 'optional-communityAmenities-PuttingGreen',
                inputType: InputTypes.Checkbox,
                label: 'Putting Green'
              },
              {
                name: 'optional-communityAmenities-ResidentManager',
                inputType: InputTypes.Checkbox,
                label: 'Resident Manager'
              },
              {
                name: 'optional-communityAmenities-RoadMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Road Maintenance'
              },
              {
                name: 'optional-communityAmenities-RVBoatStorage',
                inputType: InputTypes.Checkbox,
                label: 'RV/Boat Storage'
              },
              {
                name: 'optional-communityAmenities-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-communityAmenities-SecurityGuard',
                inputType: InputTypes.Checkbox,
                label: 'Security Guard'
              },
              {
                name: 'optional-communityAmenities-SportsField',
                inputType: InputTypes.Checkbox,
                label: 'Sports Field'
              },
              {
                name: 'optional-communityAmenities-TennisCourt',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Court'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Community Amenities',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-communityAmenities-AnsweringService',
                inputType: InputTypes.Checkbox,
                label: 'Answering Service'
              },
              {
                name: 'optional-communityAmenities-ArtStudio',
                inputType: InputTypes.Checkbox,
                label: 'Art Studio'
              },
              {
                name: 'optional-communityAmenities-BaseballField',
                inputType: InputTypes.Checkbox,
                label: 'Baseball Field'
              },
              {
                name: 'optional-communityAmenities-BasketballCourts',
                inputType: InputTypes.Checkbox,
                label: 'Basketball Courts'
              },
              {
                name: 'optional-communityAmenities-Beach',
                inputType: InputTypes.Checkbox,
                label: 'Beach'
              },
              {
                name: 'optional-communityAmenities-BeachClub',
                inputType: InputTypes.Checkbox,
                label: 'Beach Club'
              },
              {
                name: 'optional-communityAmenities-BeautySalon',
                inputType: InputTypes.Checkbox,
                label: 'Beauty Salon'
              },
              {
                name: 'optional-communityAmenities-BikeTrail',
                inputType: InputTypes.Checkbox,
                label: 'Bike Trail'
              },
              {
                name: 'optional-communityAmenities-BilliardRoom',
                inputType: InputTypes.Checkbox,
                label: 'Billiard Room'
              },
              {
                name: 'optional-communityAmenities-BoatDockSlip',
                inputType: InputTypes.Checkbox,
                label: 'Boat Dock / Slip'
              },
              {
                name: 'optional-communityAmenities-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-communityAmenities-BowlingAlley',
                inputType: InputTypes.Checkbox,
                label: 'Bowling Alley'
              },
              {
                name: 'optional-communityAmenities-ClubHouse',
                inputType: InputTypes.Checkbox,
                label: 'Club House'
              },
              {
                name: 'optional-communityAmenities-CommonGrounds',
                inputType: InputTypes.Checkbox,
                label: 'Common Grounds'
              },
              {
                name: 'optional-communityAmenities-CommunityCenter',
                inputType: InputTypes.Checkbox,
                label: 'Community Center'
              },
              {
                name: 'optional-communityAmenities-Concierge',
                inputType: InputTypes.Checkbox,
                label: 'Concierge'
              },
              {
                name: 'optional-communityAmenities-ConvenienceStore',
                inputType: InputTypes.Checkbox,
                label: 'Convenience Store'
              },
              {
                name: 'optional-communityAmenities-DayCare',
                inputType: InputTypes.Checkbox,
                label: 'Day Care'
              },
              {
                name: 'optional-communityAmenities-DiningRoom',
                inputType: InputTypes.Checkbox,
                label: 'Dining Room(s)'
              },
              {
                name: 'optional-communityAmenities-Elevator',
                inputType: InputTypes.Checkbox,
                label: 'Elevator'
              },
              {
                name: 'optional-communityAmenities-ExerciseRoom',
                inputType: InputTypes.Checkbox,
                label: 'Exercise Room'
              },
              {
                name: 'optional-communityAmenities-ExtraStorage',
                inputType: InputTypes.Checkbox,
                label: 'Extra Storage'
              },
              {
                name: 'optional-communityAmenities-Fencing',
                inputType: InputTypes.Checkbox,
                label: 'Fencing'
              },
              {
                name: 'optional-communityAmenities-FitnessCenter',
                inputType: InputTypes.Checkbox,
                label: 'Fitness Center'
              },
              {
                name: 'optional-communityAmenities-GameRoom',
                inputType: InputTypes.Checkbox,
                label: 'Game Room'
              },
              {
                name: 'optional-communityAmenities-GatedCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Gated Community'
              },
              {
                name: 'optional-communityAmenities-GolfClub',
                inputType: InputTypes.Checkbox,
                label: 'Golf Club'
              },
              {
                name: 'optional-communityAmenities-GolfCourse',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course'
              },
              {
                name: 'optional-communityAmenities-GolfCourseMembershipAvail',
                inputType: InputTypes.Checkbox,
                label: 'Golf Course Membership Avail'
              },
              {
                name: 'optional-communityAmenities-GuestSuites',
                inputType: InputTypes.Checkbox,
                label: 'Guest Suites'
              },
              {
                name: 'optional-communityAmenities-HorseTrails',
                inputType: InputTypes.Checkbox,
                label: 'Horse Trails'
              },
              {
                name: 'optional-communityAmenities-HotTub',
                inputType: InputTypes.Checkbox,
                label: 'Hot Tub'
              },
              {
                name: 'optional-communityAmenities-JogWalkPath',
                inputType: InputTypes.Checkbox,
                label: 'Jog / Walk Path'
              },
              {
                name: 'optional-communityAmenities-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-communityAmenities-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-communityAmenities-Library',
                inputType: InputTypes.Checkbox,
                label: 'Library'
              },
              {
                name: 'optional-communityAmenities-MarinaMarinaClub',
                inputType: InputTypes.Checkbox,
                label: 'Marina / Marina Club'
              },
              {
                name: 'optional-communityAmenities-MeetingRoom',
                inputType: InputTypes.Checkbox,
                label: 'Meeting Room'
              },
              {
                name: 'optional-communityAmenities-MooringArea',
                inputType: InputTypes.Checkbox,
                label: 'Mooring Area'
              },
              {
                name: 'optional-communityAmenities-PartyRoom',
                inputType: InputTypes.Checkbox,
                label: 'Party Room'
              },
              {
                name: 'optional-communityAmenities-PicnicArea',
                inputType: InputTypes.Checkbox,
                label: 'Picnic Area'
              },
              {
                name: 'optional-communityAmenities-PierDock',
                inputType: InputTypes.Checkbox,
                label: 'Pier / Dock'
              },
              {
                name: 'optional-communityAmenities-PoolIndoor',
                inputType: InputTypes.Checkbox,
                label: 'Pool - Indoor'
              },
              {
                name: 'optional-communityAmenities-PoolOutdoor',
                inputType: InputTypes.Checkbox,
                label: 'Pool - Outdoor'
              },
              {
                name: 'optional-communityAmenities-PoolMembershipAvail',
                inputType: InputTypes.Checkbox,
                label: 'Pool Membership Avail'
              },
              {
                name: 'optional-communityAmenities-PuttingGreen',
                inputType: InputTypes.Checkbox,
                label: 'Putting Green'
              },
              {
                name: 'optional-communityAmenities-RacquetBall',
                inputType: InputTypes.Checkbox,
                label: 'Racquet Ball'
              },
              {
                name: 'optional-communityAmenities-RecCenter',
                inputType: InputTypes.Checkbox,
                label: 'Rec Center'
              },
              {
                name: 'optional-communityAmenities-RetirementCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Retirement Community'
              },
              {
                name: 'optional-communityAmenities-RidingStables',
                inputType: InputTypes.Checkbox,
                label: 'Riding / Stables'
              },
              {
                name: 'optional-communityAmenities-Satellite',
                inputType: InputTypes.Checkbox,
                label: 'Satellite'
              },
              {
                name: 'optional-communityAmenities-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-communityAmenities-Security',
                inputType: InputTypes.Checkbox,
                label: 'Security'
              },
              {
                name: 'optional-communityAmenities-SharedSlip',
                inputType: InputTypes.Checkbox,
                label: 'Shared Slip'
              },
              {
                name: 'optional-communityAmenities-Shuffleboard',
                inputType: InputTypes.Checkbox,
                label: 'Shuffleboard'
              },
              {
                name: 'optional-communityAmenities-SoccerField',
                inputType: InputTypes.Checkbox,
                label: 'Soccer Field'
              },
              {
                name: 'optional-communityAmenities-Spa',
                inputType: InputTypes.Checkbox,
                label: 'Spa'
              },
              {
                name: 'optional-communityAmenities-StorageBin',
                inputType: InputTypes.Checkbox,
                label: 'Storage Bin'
              },
              {
                name: 'optional-communityAmenities-TennisIndoor',
                inputType: InputTypes.Checkbox,
                label: 'Tennis - Indoor'
              },
              {
                name: 'optional-communityAmenities-TennisCourts',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Courts'
              },
              {
                name: 'optional-communityAmenities-TotLotPlayground',
                inputType: InputTypes.Checkbox,
                label: 'Tot Lot / Playground'
              },
              {
                name: 'optional-communityAmenities-TransportationServices',
                inputType: InputTypes.Checkbox,
                label: 'Transportation Services'
              },
              {
                name: 'optional-communityAmenities-VolleyballCourts',
                inputType: InputTypes.Checkbox,
                label: 'Volleyball Courts'
              },
              {
                name: 'optional-communityAmenities-WaterLakePrivilege',
                inputType: InputTypes.Checkbox,
                label: 'Water / Lake Privilege'
              },
              {
                name: 'optional-communityAmenities-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },

      {
        fieldsetTitle: 'Utilities Suppliers',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Charleston]: [PropertyTypes.HomeSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-utilitiesSuppliers-BCWSA',
                inputType: InputTypes.Checkbox,
                label: 'BCW & SA'
              },
              {
                name: 'optional-utilitiesSuppliers-BerkeleyElectCoOp',
                inputType: InputTypes.Checkbox,
                label: 'Berkeley Elect Co-Op'
              },
              {
                name: 'optional-utilitiesSuppliers-CarolinaWaterService',
                inputType: InputTypes.Checkbox,
                label: 'Carolina Water Service'
              },
              {
                name: 'optional-utilitiesSuppliers-CharlestonWaterService',
                inputType: InputTypes.Checkbox,
                label: 'Charleston Water Service'
              },
              {
                name: 'optional-utilitiesSuppliers-CityGooseCreek',
                inputType: InputTypes.Checkbox,
                label: 'City of Goose Creek'
              },
              {
                name: 'optional-utilitiesSuppliers-CoastalElecCoop',
                inputType: InputTypes.Checkbox,
                label: 'Coastal Elec Coop'
              },
              {
                name: 'optional-utilitiesSuppliers-DeptPublicUtil',
                inputType: InputTypes.Checkbox,
                label: 'Dept of Public Util'
              },
              {
                name: 'optional-utilitiesSuppliers-DominionEnergy',
                inputType: InputTypes.Checkbox,
                label: 'Dominion Energy'
              },
              {
                name: 'optional-utilitiesSuppliers-DorchesterCntyWaterSewer',
                inputType: InputTypes.Checkbox,
                label: 'Dorchester Cnty Water/Sewer'
              },
              {
                name: 'optional-utilitiesSuppliers-DorchesterCntyWaterAuth',
                inputType: InputTypes.Checkbox,
                label: 'Dorchester Cnty Water Auth'
              },
              {
                name: 'optional-utilitiesSuppliers-EdistoElectric',
                inputType: InputTypes.Checkbox,
                label: 'Edisto Electric'
              },
              {
                name: 'optional-utilitiesSuppliers-FrontierTelephone',
                inputType: InputTypes.Checkbox,
                label: 'Frontier Telephone'
              },
              {
                name: 'optional-utilitiesSuppliers-HighwayDept',
                inputType: InputTypes.Checkbox,
                label: 'Highway Dept'
              },
              {
                name: 'optional-utilitiesSuppliers-IOPWSComm',
                inputType: InputTypes.Checkbox,
                label: 'IOP W/S Comm'
              },
              {
                name: 'optional-utilitiesSuppliers-JamesISPSD',
                inputType: InputTypes.Checkbox,
                label: 'James IS PSD'
              },
              {
                name: 'optional-utilitiesSuppliers-JohnISWaterCo',
                inputType: InputTypes.Checkbox,
                label: 'John IS Water Co'
              },
              {
                name: 'optional-utilitiesSuppliers-MoncksCorner',
                inputType: InputTypes.Checkbox,
                label: 'Moncks Corner'
              },
              {
                name: 'optional-utilitiesSuppliers-MtPWSComm',
                inputType: InputTypes.Checkbox,
                label: 'Mt. P. W/S Comm'
              },
              {
                name: 'optional-utilitiesSuppliers-NChasSewerDistrict',
                inputType: InputTypes.Checkbox,
                label: 'N Chas Sewer District'
              },
              {
                name: 'optional-utilitiesSuppliers-SanteeCooper',
                inputType: InputTypes.Checkbox,
                label: 'Santee Cooper'
              },
              {
                name: 'optional-utilitiesSuppliers-SIWSComm',
                inputType: InputTypes.Checkbox,
                label: 'SI W/S Comm'
              },
              {
                name: 'optional-utilitiesSuppliers-SummervilleCPW',
                inputType: InputTypes.Checkbox,
                label: 'Summerville CPW'
              },
              {
                name: 'optional-utilitiesSuppliers-TriCountyElectric',
                inputType: InputTypes.Checkbox,
                label: 'Tri-County Electric'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Association Fee Type and Inclusions',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-associationFeeType-CommunityAssociation',
                inputType: InputTypes.Checkbox,
                label: 'Community Association'
              },
              {
                name: 'optional-associationFeeType-CondoAssociation',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Condo Association'
              },
              {
                name: 'optional-associationFeeType-Cooperative',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Cooperative'
              },
              {
                name: 'optional-associationFeeType-OwnersAssociation',
                inputType: InputTypes.Checkbox,
                label: 'Owners Association'
              },
              {
                name: 'optional-associationFeeType-RoadMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Road Maintenance'
              },
            ]
          },
          {
            groupTitle: 'Fee Includes',
            fields: [
              {
                name: 'optional-inclusions-Clubhouse',
                inputType: InputTypes.Checkbox,
                label: 'Clubhouse'
              },
              {
                name: 'optional-inclusions-CommonAreaMaint',
                inputType: InputTypes.Checkbox,
                label: 'Common Area Maint'
              },
              {
                name: 'optional-inclusions-CommonArea',
                inputType: InputTypes.Checkbox,
                label: 'Common Area'
              },
              {
                name: 'optional-inclusions-CommunityUtilities',
                inputType: InputTypes.Checkbox,
                label: 'Community Utilities'
              },
              {
                name: 'optional-inclusions-ExteriorBuildingMaint',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Building Maint'
              },
              {
                name: 'optional-inclusions-CustodialServiceMaint',
                inputType: InputTypes.Checkbox,
                label: 'Custodial Service Maint'
              },
              {
                name: 'optional-inclusions-Electricity',
                inputType: InputTypes.Checkbox,
                label: 'Electricity'
              },
              {
                name: 'optional-inclusions-ExteriorBuildingMaintenance',
                inputType: InputTypes.Checkbox,
                label: 'Exterior Building Maintenance'
              },
              {
                name: 'optional-inclusions-Insurance',
                inputType: InputTypes.Checkbox,
                label: 'Insurance'
              },
              {
                name: 'optional-inclusions-LimitedExteriorMaint',
                inputType: InputTypes.Checkbox,
                label: 'Limited Exterior Maint'
              },
              {
                name: 'optional-inclusions-LimitedYardMaint',
                inputType: InputTypes.Checkbox,
                label: 'Limited Yard Maint'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Association Fee Includes',
        scope: Scopes.IndividualMapping,
        mapping: {
          [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
          [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale],
        },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-associationFeeIncludes-AirConditioning',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Air Conditioning'
              },
              {
                name: 'optional-associationFeeIncludes-AlarmSystem',
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                inputType: InputTypes.Checkbox,
                label: 'Alarm System'
              },
              {
                name: 'optional-associationFeeIncludes-AllGroundFee',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },                
                label: 'All Ground Fee'
              },
              {
                name: 'optional-associationFeeIncludes-ApplianceMaintenance',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Appliance Maintenance'
              },
              {
                name: 'optional-associationFeeIncludes-Basketball',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Basketball Court'
              },
              {
                name: 'optional-associationFeeIncludes-Billard',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Billard Room'
              },
              {
                name: 'optional-associationFeeIncludes-BoatDock',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Boat Dock'
              },
              {
                name: 'optional-associationFeeIncludes-BoatSlip',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Boat Slip'
              },
              {
                name: 'optional-associationFeeIncludes-Boating',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Boating'
              },
              {
                name: 'optional-associationFeeIncludes-Broadband',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Broadband'
              },
              {
                name: 'optional-associationFeeIncludes-BusService',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Bus Service'
              },
              {
                name: 'optional-associationFeeIncludes-CableTV',
                inputType: InputTypes.Checkbox,
                label: 'Cable TV'
              },
              {
                name: 'optional-associationFeeIncludes-Clubhouse',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Clubhouse'
              },
              {
                name: 'optional-associationFeeIncludes-CoinLaundry',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Coin Laundry'
              },
              {
                name: 'optional-associationFeeIncludes-CommonAreaMaintenance',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Common Area Maintenance'
              },
              {
                name: 'optional-associationFeeIncludes-CookFee',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Cook Fee'
              },
              {
                name: 'optional-associationFeeIncludes-CustodialServiceMaint',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Custodial Service Maint'
              },
              {
                name: 'optional-associationFeeIncludes-DogPark',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Dog Park'
              },
              {
                name: 'optional-associationFeeIncludes-Electricity',
                inputType: InputTypes.Checkbox,                
                label: 'Electricity'
              },
              {
                name: 'optional-associationFeeIncludes-Elevator',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Elevator(s)'
              },
              {
                name: 'optional-associationFeeIncludes-FitnessCenter',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Fitness Center'
              },
              {
                name: 'optional-associationFeeIncludes-ExteriorBuildingMaintenance',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Exterior Building Maintenance'
              },
              {
                name: 'optional-associationFeeIncludes-FiberOpticsDwelling',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Fiber Optics at Dwelling'
              },
              {
                name: 'optional-associationFeeIncludes-Gated',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Gated'
              },
              {
                name: 'optional-associationFeeIncludes-Gas',
                inputType: InputTypes.Checkbox,
                label: 'Gas'
              },
              {
                name: 'optional-associationFeeIncludes-GolfCourse',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Golf Course'
              },
              {
                name: 'optional-associationFeeIncludes-HealthClub',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Health Club'
              },
              {
                name: 'optional-associationFeeIncludes-Heat',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Heat'
              },
              {
                name: 'optional-associationFeeIncludes-HighSpeedInternet',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'High Speed Internet'
              },
              {
                name: 'optional-associationFeeIncludes-IndoorPool',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Indoor Pool'
              },
              {
                name: 'optional-associationFeeIncludes-Insurance',
                inputType: InputTypes.Checkbox,
                label: 'Insurance'
              },
              {
                name: 'optional-associationFeeIncludes-Laundry',
                inputType: InputTypes.Checkbox,
                label: 'Laundry'
              },
              {
                name: 'optional-associationFeeIncludes-LawnCareFront',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Lawn Care - Front'
              },
              {
                name: 'optional-associationFeeIncludes-LawnCareRear',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Lawn Care - Rear'
              },
              {
                name: 'optional-associationFeeIncludes-LawnCareMaintenance',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Lawn Care Maintenance'
              },
              {
                name: 'optional-associationFeeIncludes-Maintenance',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Maintenance'
              },
              {
                name: 'optional-associationFeeIncludes-MaintenanceGrounds',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Maintenance Grounds'
              },
              {
                name: 'optional-associationFeeIncludes-Management',
                inputType: InputTypes.Checkbox,
                label: 'Management'
              },
              {
                name: 'optional-associationFeeIncludes-Park',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Park'
              },
              {
                name: 'optional-associationFeeIncludes-Parking',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Parking'
              },
              {
                name: 'optional-associationFeeIncludes-ParkingFee',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Parking Fee'
              },
              {
                name: 'optional-associationFeeIncludes-PartyRoom',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Party Room'
              },
              {
                name: 'optional-associationFeeIncludes-Playground',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Playground'
              },
              {
                name: 'optional-associationFeeIncludes-PondSeasonal',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Pond Seasonal'
              },
              {
                name: 'optional-associationFeeIncludes-PondYearRound',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Pond Year Round'
              },
              {
                name: 'optional-associationFeeIncludes-PierDockMaintenance',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Pier / Dock Maintenance'
              },
              {
                name: 'optional-associationFeeIncludes-Pool',
                inputType: InputTypes.Checkbox,
                label: 'Pool(s)'
              },
              {
                name: 'optional-associationFeeIncludes-RecFacility',
                inputType: InputTypes.Checkbox,
                label: 'Rec Facility'
              },
              {
                name: 'optional-associationFeeIncludes-ReserveFund',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Reserve Fund'
              },
              {
                name: 'optional-associationFeeIncludes-RoadMaintenance',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Road Maintenance'
              },
              {
                name: 'optional-associationFeeIncludes-Sauna',
                inputType: InputTypes.Checkbox,
                label: 'Sauna'
              },
              {
                name: 'optional-associationFeeIncludes-Security',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Security'
              },
              {
                name: 'optional-associationFeeIncludes-SecurityGate',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Security Gate'
              },
              {
                name: 'optional-associationFeeIncludes-Sewer',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Sewer'
              },
              {
                name: 'optional-associationFeeIncludes-SnowRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Snow Removal'
              },
              {
                name: 'optional-associationFeeIncludes-Stables',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Stables'
              },
              {
                name: 'optional-associationFeeIncludes-StandardPhoneService',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Standard Phone Service'
              },
              {
                name: 'optional-associationFeeIncludes-Taxes',
                inputType: InputTypes.Checkbox,
                label: 'Taxes'
              },
              {
                name: 'optional-associationFeeIncludes-TennisCourts',
                inputType: InputTypes.Checkbox,
                label: 'Tennis Courts'
              },
              {
                name: 'optional-associationFeeIncludes-TrashRemoval',
                inputType: InputTypes.Checkbox,
                label: 'Trash Removal'
              },
              {
                name: 'optional-associationFeeIncludes-UnknownFee',
                inputType: InputTypes.Checkbox,
                scope: Scopes.IndividualMapping,
                mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
                label: 'Unknown Fee'
              },
              {
                name: 'optional-associationFeeIncludes-Water',
                inputType: InputTypes.Checkbox,
                label: 'Water'
              },
              {
                name: 'optional-associationFeeIncludes-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Property Type',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.CentralVA]: [PropertyTypes.HomeSale, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterPropertyType-Access',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Access'
              },
              {
                name: 'optional-waterPropertyType-BayFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Bay Frontage'
              },
              {
                name: 'optional-waterPropertyType-Beach',
                inputType: InputTypes.Checkbox,
                label: 'Beach'
              },
              {
                name: 'optional-waterPropertyType-CreekFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Creek Frontage'
              },
              {
                name: 'optional-waterPropertyType-DockMooring',
                inputType: InputTypes.Checkbox,
                label: 'Dock/Mooring'
              },
              {
                name: 'optional-waterPropertyType-HarborFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Harbor Frontage'
              },
              {
                name: 'optional-waterPropertyType-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-waterPropertyType-LakeFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Lake Frontage'
              },
              {
                name: 'optional-waterPropertyType-Marsh',
                inputType: InputTypes.Checkbox,
                label: 'Marsh'
              },
              {
                name: 'optional-waterPropertyType-MLW02Ft',
                inputType: InputTypes.Checkbox,
                label: 'MLW 0-2 Ft'
              },
              {
                name: 'optional-waterPropertyType-MLW46Ft',
                inputType: InputTypes.Checkbox,
                label: 'MLW 4-6 Ft'
              },
              {
                name: 'optional-waterPropertyType-MLW6Ft',
                inputType: InputTypes.Checkbox,
                label: 'MLW 6+ Ft'
              },
              {
                name: 'optional-waterPropertyType-Navigable',
                inputType: InputTypes.Checkbox,
                label: 'Navigable'
              },
              {
                name: 'optional-waterPropertyType-OceanBayFrontage',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Ocean / Bay Frontage'
              },
              {
                name: 'optional-waterPropertyType-Ocean',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.LandSale],
                label: 'Ocean'
              },
              {
                name: 'optional-waterPropertyType-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },
              {
                name: 'optional-waterPropertyType-RiparianRights',
                inputType: InputTypes.Checkbox,
                label: 'Riparian Rights'
              },
              {
                name: 'optional-waterPropertyType-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },
              {
                name: 'optional-waterPropertyType-RiverFrontage',
                inputType: InputTypes.Checkbox,
                label: 'River Frontage'
              },
              {
                name: 'optional-waterPropertyType-SoundFrontage',
                inputType: InputTypes.Checkbox,
                label: 'Sound Frontage'
              },
              {
                name: 'optional-waterPropertyType-Stream',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Stream'
              },
              {
                name: 'optional-waterPropertyType-WalkToWater',
                inputType: InputTypes.Checkbox,
                label: 'Walk To Water'
              },
              {
                name: 'optional-waterPropertyType-WaterAccess',
                inputType: InputTypes.Checkbox,
                label: 'Water Access'
              },
              {
                name: 'optional-waterPropertyType-Whitewater',
                inputType: InputTypes.Checkbox,
                scope: Scopes.ByPropertyType,
                types: [PropertyTypes.HomeSale],
                label: 'Whitewater'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Oriented Properties Only - Waterfront Characteristics',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterOriented-BeachGrassy',
                inputType: InputTypes.Checkbox,
                label: 'Beach Grassy'
              },
              {
                name: 'optional-waterOriented-BeachRocky',
                inputType: InputTypes.Checkbox,
                label: 'Beach Rocky'
              },
              {
                name: 'optional-waterOriented-BeachSandy',
                inputType: InputTypes.Checkbox,
                label: 'Beach Sandy'
              },
              {
                name: 'optional-waterOriented-BoatLift',
                inputType: InputTypes.Checkbox,
                label: 'Boat Lift'
              },
              {
                name: 'optional-waterOriented-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-waterOriented-BoatSlip',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip'
              },
              {
                name: 'optional-waterOriented-Bulkhead',
                inputType: InputTypes.Checkbox,
                label: 'Bulkhead'
              },
              {
                name: 'optional-waterOriented-BuoyInstalled',
                inputType: InputTypes.Checkbox,
                label: 'Buoy Installed'
              },
              {
                name: 'optional-waterOriented-BuoyPermitAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Buoy Permit Available'
              },
              {
                name: 'optional-waterOriented-BuoyPermitObtained',
                inputType: InputTypes.Checkbox,
                label: 'Buoy Permit Obtained'
              },
              {
                name: 'optional-waterOriented-DeededWaterAccess',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Water Access'
              },
              {
                name: 'optional-waterOriented-DeededWaterRights',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Water Rights'
              },
              {
                name: 'optional-waterOriented-DeededWaterfront',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Waterfront'
              },
              {
                name: 'optional-waterOriented-DockCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Dock Community'
              },
              {
                name: 'optional-waterOriented-DockFloating',
                inputType: InputTypes.Checkbox,
                label: 'Dock Floating'
              },
              {
                name: 'optional-waterOriented-DockMultiSlip',
                inputType: InputTypes.Checkbox,
                label: 'Dock Multi Slip'
              },
              {
                name: 'optional-waterOriented-DockPermitAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Dock Permit Available'
              },
              {
                name: 'optional-waterOriented-DockPrivateInstalled',
                inputType: InputTypes.Checkbox,
                label: 'Dock Private Installed'
              },
              {
                name: 'optional-waterOriented-DockPermitObtained',
                inputType: InputTypes.Checkbox,
                label: 'Dock Permit Obtained'
              },
              {
                name: 'optional-waterOriented-DockShared',
                inputType: InputTypes.Checkbox,
                label: 'Dock Shared'
              },
              {
                name: 'optional-waterOriented-DockSingleSlip',
                inputType: InputTypes.Checkbox,
                label: 'Dock Single Slip'
              },
              {
                name: 'optional-waterOriented-Harbor',
                inputType: InputTypes.Checkbox,
                label: 'Harbor'
              },
              {
                name: 'optional-waterOriented-NoMotorWatercraft',
                inputType: InputTypes.Checkbox,
                label: 'No Motor Watercraft'
              },
              {
                name: 'optional-waterOriented-OnCove',
                inputType: InputTypes.Checkbox,
                label: 'On Cove'
              },
              {
                name: 'optional-waterOriented-Pier',
                inputType: InputTypes.Checkbox,
                label: 'Pier'
              },
              {
                name: 'optional-waterOriented-PondonLot',
                inputType: InputTypes.Checkbox,
                label: 'Pond on Lot'
              },
              {
                name: 'optional-waterOriented-PublicBoatRampLess',
                inputType: InputTypes.Checkbox,
                label: 'Public Boat Ramp Less Than 1 mile'
              },
              {
                name: 'optional-waterOriented-PublBoatRamp23Miles',
                inputType: InputTypes.Checkbox,
                label: 'Public Boat Ramp 2-3 miles'
              },
              {
                name: 'optional-waterOriented-SailboatAccessible',
                inputType: InputTypes.Checkbox,
                label: 'Sailboat Accessible'
              },
              {
                name: 'optional-waterOriented-SaltMarsh',
                inputType: InputTypes.Checkbox,
                label: 'Salt Marsh'
              },
              {
                name: 'optional-waterOriented-SoundSide',
                inputType: InputTypes.Checkbox,
                label: 'Sound Side'
              },
              {
                name: 'optional-waterOriented-SwimmingNotPermitted',
                inputType: InputTypes.Checkbox,
                label: 'Swimming Not Permitted'
              },
              {
                name: 'optional-waterOriented-WaterAccessCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Water Access Community'
              },
              {
                name: 'optional-waterOriented-WaterDepth4',
                inputType: InputTypes.Checkbox,
                label: 'Water Depth 4+'
              },
              {
                name: 'optional-waterOriented-WaterView',
                inputType: InputTypes.Checkbox,
                label: 'Water View'
              },
              {
                name: 'optional-waterOriented-WaterFront',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront'
              },
              {
                name: 'optional-waterOriented-WaterfrontCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront Community'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Oriented Properties Only - Waterfront Characteristics',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.NCRegion]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterOriented-BoatDock',
                inputType: InputTypes.Checkbox,
                label: 'Boat Dock'
              },
              {
                name: 'optional-waterOriented-BoatLift',
                inputType: InputTypes.Checkbox,
                label: 'Boat Lift'
              },
              {
                name: 'optional-waterOriented-BoatRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Ramp'
              },
              {
                name: 'optional-waterOriented-BoatSlip',
                inputType: InputTypes.Checkbox,
                label: 'Boat Slip'
              },
              {
                name: 'optional-waterOriented-Bulkhead',
                inputType: InputTypes.Checkbox,
                label: 'Bulkhead'
              },
              {
                name: 'optional-waterOriented-CanalFront',
                inputType: InputTypes.Checkbox,
                label: 'Canal Front'
              },
              {
                name: 'optional-waterOriented-CanalView',
                inputType: InputTypes.Checkbox,
                label: 'Canal View'
              },
              {
                name: 'optional-waterOriented-Cove',
                inputType: InputTypes.Checkbox,
                label: 'Cove'
              },
              {
                name: 'optional-waterOriented-CreekFront',
                inputType: InputTypes.Checkbox,
                label: 'Creek Front'
              },
              {
                name: 'optional-waterOriented-CreekView',
                inputType: InputTypes.Checkbox,
                label: 'Creek View'
              },
              {
                name: 'optional-waterOriented-DeededBeachAccess',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Beach Access'
              },
              {
                name: 'optional-waterOriented-DeededWaterAccess',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Water Access'
              },
              {
                name: 'optional-waterOriented-DeededWaterRights',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Water Rights'
              },
              {
                name: 'optional-waterOriented-DeededWaterfront',
                inputType: InputTypes.Checkbox,
                label: 'Deeded Waterfront'
              },
              {
                name: 'optional-waterOriented-Harbor',
                inputType: InputTypes.Checkbox,
                label: 'Harbor'
              },
              {
                name: 'optional-waterOriented-ICWFront',
                inputType: InputTypes.Checkbox,
                label: 'ICW Front'
              },
              {
                name: 'optional-waterOriented-ICWView',
                inputType: InputTypes.Checkbox,
                label: 'ICW View'
              },
              {
                name: 'optional-waterOriented-MarinaFront',
                inputType: InputTypes.Checkbox,
                label: 'Marina Front'
              },
              {
                name: 'optional-waterOriented-MarinaView',
                inputType: InputTypes.Checkbox,
                label: 'Marina View'
              },
              {
                name: 'optional-waterOriented-MarshFront',
                inputType: InputTypes.Checkbox,
                label: 'Marsh Front'
              },
              {
                name: 'optional-waterOriented-MarshView',
                inputType: InputTypes.Checkbox,
                label: 'Marsh View'
              },
              {
                name: 'optional-waterOriented-OceanFront',
                inputType: InputTypes.Checkbox,
                label: 'Ocean Front'
              },
              {
                name: 'optional-waterOriented-OceanSide',
                inputType: InputTypes.Checkbox,
                label: 'Ocean Side'
              },
              {
                name: 'optional-waterOriented-OceanView',
                inputType: InputTypes.Checkbox,
                label: 'Ocean View'
              },
              {
                name: 'optional-waterOriented-PondFront',
                inputType: InputTypes.Checkbox,
                label: 'Pond Front'
              },
              {
                name: 'optional-waterOriented-PondView',
                inputType: InputTypes.Checkbox,
                label: 'Pond View'
              },
              {
                name: 'optional-waterOriented-RiverFront',
                inputType: InputTypes.Checkbox,
                label: 'River Front'
              },
              {
                name: 'optional-waterOriented-RiverView',
                inputType: InputTypes.Checkbox,
                label: 'River View'
              },
              {
                name: 'optional-waterOriented-SailboatAccessible',
                inputType: InputTypes.Checkbox,
                label: 'Sailboat Accessible'
              },
              {
                name: 'optional-waterOriented-SaltMarsh',
                inputType: InputTypes.Checkbox,
                label: 'Salt Marsh'
              },
              {
                name: 'optional-waterOriented-SecondRow',
                inputType: InputTypes.Checkbox,
                label: 'Second Row'
              },
              {
                name: 'optional-waterOriented-SoundFront',
                inputType: InputTypes.Checkbox,
                label: 'Sound Front'
              },
              {
                name: 'optional-waterOriented-SoundSide',
                inputType: InputTypes.Checkbox,
                label: 'Sound Side'
              },
              {
                name: 'optional-waterOriented-SoundView',
                inputType: InputTypes.Checkbox,
                label: 'Sound View'
              },
              {
                name: 'optional-waterOriented-ThirdRow',
                inputType: InputTypes.Checkbox,
                label: 'Third Row'
              },
              {
                name: 'optional-waterOriented-WaterAccessComm',
                inputType: InputTypes.Checkbox,
                label: 'Water Access Comm'
              },
              {
                name: 'optional-waterOriented-WaterDepth4',
                inputType: InputTypes.Checkbox,
                label: 'Water Depth 4+'
              },
              {
                name: 'optional-waterOriented-WaterView',
                inputType: InputTypes.Checkbox,
                label: 'Water View'
              },
              {
                name: 'optional-waterOriented-WaterfrontCommunity',
                inputType: InputTypes.Checkbox,
                label: 'Waterfront Community'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Oriented',
        fieldsetDescription: 'Please check all that apply',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterOriented-WaterAccess',
                inputType: InputTypes.Checkbox,
                label: 'Water Access'
              },
              {
                name: 'optional-waterOriented-WaterFront',
                inputType: InputTypes.Checkbox,
                label: 'Water Front'
              },
              {
                name: 'optional-waterOriented-WaterView',
                inputType: InputTypes.Checkbox,
                label: 'Water View'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Type of Water',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-typeOfWater-Bay',
                inputType: InputTypes.Checkbox,
                label: 'Bay'
              },
              {
                name: 'optional-typeOfWater-Canal',
                inputType: InputTypes.Checkbox,
                label: 'Canal'
              },
              {
                name: 'optional-typeOfWater-Cove',
                inputType: InputTypes.Checkbox,
                label: 'Cove'
              },
              {
                name: 'optional-typeOfWater-Creek',
                inputType: InputTypes.Checkbox,
                label: 'Creek'
              },
              {
                name: 'optional-typeOfWater-Lagoon',
                inputType: InputTypes.Checkbox,
                label: 'Lagoon'
              },
              {
                name: 'optional-typeOfWater-Lake',
                inputType: InputTypes.Checkbox,
                label: 'Lake'
              },
              {
                name: 'optional-typeOfWater-Ocean',
                inputType: InputTypes.Checkbox,
                label: 'Ocean'
              },
              {
                name: 'optional-typeOfWater-Pond',
                inputType: InputTypes.Checkbox,
                label: 'Pond'
              },
              {
                name: 'optional-typeOfWater-River',
                inputType: InputTypes.Checkbox,
                label: 'River'
              },
              {
                name: 'optional-typeOfWater-Spring',
                inputType: InputTypes.Checkbox,
                label: 'Spring'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Water Access Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterAccessFeatures-BoatElectricMotorOnly',
                inputType: InputTypes.Checkbox,
                label: 'Boat - Electric Motor Only'
              },
              {
                name: 'optional-waterAccessFeatures-BoatLengthLimit',
                inputType: InputTypes.Checkbox,
                label: 'Boat - Length Limit'
              },
              {
                name: 'optional-waterAccessFeatures-BoatNonPoweredOnly',
                inputType: InputTypes.Checkbox,
                label: 'Boat - Non-Powered Only'
              },
              {
                name: 'optional-waterAccessFeatures-BoatPowered',
                inputType: InputTypes.Checkbox,
                label: 'Boat - Powered'
              },
              {
                name: 'optional-waterAccessFeatures-CanoeKayak',
                inputType: InputTypes.Checkbox,
                label: 'Canoe / Kayak'
              },
              {
                name: 'optional-waterAccessFeatures-FishingAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Fishing Allowed'
              },
              {
                name: 'optional-waterAccessFeatures-LimitedHoursWatercraft',
                inputType: InputTypes.Checkbox,
                label: 'Limited Hours Watercraft'
              },
              {
                name: 'optional-waterAccessFeatures-NoPersonalWatercraft',
                inputType: InputTypes.Checkbox,
                label: 'No Personal Watercraft'
              },
              {
                name: 'optional-waterAccessFeatures-PersonalWatercraft',
                inputType: InputTypes.Checkbox,
                label: 'Personal Watercraft'
              },
              {
                name: 'optional-waterAccessFeatures-PrivateAccess',
                inputType: InputTypes.Checkbox,
                label: 'Private Access'
              },
              {
                name: 'optional-waterAccessFeatures-PublicAccess',
                inputType: InputTypes.Checkbox,
                label: 'Public Access'
              },
              {
                name: 'optional-waterAccessFeatures-PublicBeach',
                inputType: InputTypes.Checkbox,
                label: 'Public Beach'
              },
              {
                name: 'optional-waterAccessFeatures-Sail',
                inputType: InputTypes.Checkbox,
                label: 'Sail'
              },
              {
                name: 'optional-waterAccessFeatures-SeaplanePermitted',
                inputType: InputTypes.Checkbox,
                label: 'Seaplane Permitted'
              },
              {
                name: 'optional-waterAccessFeatures-SwimmingAllowed',
                inputType: InputTypes.Checkbox,
                label: 'Swimming Allowed'
              },
              {
                name: 'optional-waterAccessFeatures-Under40mphMotorOnly',
                inputType: InputTypes.Checkbox,
                label: 'Under 40mph Motor Only'
              },
              {
                name: 'optional-waterAccessFeatures-WaterskiWakeboard',
                inputType: InputTypes.Checkbox,
                label: 'Waterski / Wakeboard'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Waterfront Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-waterfrontFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-waterfrontFeatures-BoatLaunchRamp',
                inputType: InputTypes.Checkbox,
                label: 'Boat Launch / Ramp'
              },
              {
                name: 'optional-waterfrontFeatures-ExclusiveEasement',
                inputType: InputTypes.Checkbox,
                label: 'Exclusive Easement'
              },
              {
                name: 'optional-waterfrontFeatures-OutParcel',
                inputType: InputTypes.Checkbox,
                label: 'Out-Parcel'
              },
              {
                name: 'optional-waterfrontFeatures-Park',
                inputType: InputTypes.Checkbox,
                label: 'Park'
              },
              {
                name: 'optional-waterfrontFeatures-Pipestem',
                inputType: InputTypes.Checkbox,
                label: 'Pipestem'
              },
              {
                name: 'optional-waterfrontFeatures-PrivateDockSite',
                inputType: InputTypes.Checkbox,
                label: 'Private Dock Site'
              },
              {
                name: 'optional-waterfrontFeatures-RipRap',
                inputType: InputTypes.Checkbox,
                label: 'Rip-Rap'
              },
              {
                name: 'optional-waterfrontFeatures-SandyBeach',
                inputType: InputTypes.Checkbox,
                label: 'Sandy Beach'
              },
              {
                name: 'optional-waterfrontFeatures-Shared',
                inputType: InputTypes.Checkbox,
                label: 'Shared'
              },
              {
                name: 'optional-waterfrontFeatures-SplitLakefront',
                inputType: InputTypes.Checkbox,
                label: 'Split Lakefront'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: 'Dock Type and Features',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        inputGroups: [
          {
            fields: [
              {
                name: 'optional-dockTypeFeatures-None',
                inputType: InputTypes.Checkbox,
                label: 'None'
              },
              {
                name: 'optional-dockTypeFeatures-AgainstBulkheadOnly',
                inputType: InputTypes.Checkbox,
                label: 'Against Bulkhead Only'
              },
              {
                name: 'optional-dockTypeFeatures-FloatingDock',
                inputType: InputTypes.Checkbox,
                label: 'Floating Dock'
              },
              {
                name: 'optional-dockTypeFeatures-FuelAvailable',
                inputType: InputTypes.Checkbox,
                label: 'Fuel Available'
              },
              {
                name: 'optional-dockTypeFeatures-HoistLiftElectric',
                inputType: InputTypes.Checkbox,
                label: 'Hoist/Lift Electric'
              },
              {
                name: 'optional-dockTypeFeatures-HoistLiftManual',
                inputType: InputTypes.Checkbox,
                label: 'Hoist/Lift Manual'
              },
              {
                name: 'optional-dockTypeFeatures-MaterialComposite',
                inputType: InputTypes.Checkbox,
                label: 'Material - Composite'
              },
              {
                name: 'optional-dockTypeFeatures-MaterialTreatedLumber',
                inputType: InputTypes.Checkbox,
                label: 'Material - Treated Lumber'
              },
              {
                name: 'optional-dockTypeFeatures-MaterialVinylClad',
                inputType: InputTypes.Checkbox,
                label: 'Material - Vinyl Clad'
              },
              {
                name: 'optional-dockTypeFeatures-Mooring',
                inputType: InputTypes.Checkbox,
                label: 'Mooring'
              },
              {
                name: 'optional-dockTypeFeatures-MultipleSlip',
                inputType: InputTypes.Checkbox,
                label: 'Multiple Slip'
              },
              {
                name: 'optional-dockTypeFeatures-Pier',
                inputType: InputTypes.Checkbox,
                label: 'Pier (Perpendicular)'
              },
              {
                name: 'optional-dockTypeFeatures-Private',
                inputType: InputTypes.Checkbox,
                label: 'Private'
              },
              {
                name: 'optional-dockTypeFeatures-Ramp',
                inputType: InputTypes.Checkbox,
                label: 'Ramp'
              },
              {
                name: 'optional-dockTypeFeatures-SharedSlip',
                inputType: InputTypes.Checkbox,
                label: 'Shared Slip'
              },
              {
                name: 'optional-dockTypeFeatures-StorageArea',
                inputType: InputTypes.Checkbox,
                label: 'Storage Area'
              },
              {
                name: 'optional-dockTypeFeatures-SunDeck',
                inputType: InputTypes.Checkbox,
                label: 'Sun Deck'
              },
              {
                name: 'optional-dockTypeFeatures-SwimOnly',
                inputType: InputTypes.Checkbox,
                label: 'Swim Only'
              },
              {
                name: 'optional-dockTypeFeatures-TypeA',
                inputType: InputTypes.Checkbox,
                label: 'Type A'
              },
              {
                name: 'optional-dockTypeFeatures-Other',
                inputType: InputTypes.Checkbox,
                label: 'Other'
              },
            ]
          }
        ]
      },
      {
        fieldsetTitle: '',
        scope: Scopes.IndividualMapping,
        mapping: { [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent, PropertyTypes.LandSale] },
        fields: [
          {
            name: 'optional-numberCommunityDocks',
            inputType: InputTypes.Number,
            label: 'Number of Community Docks'
          },
          {
            name: 'optional-numberPrivateDocks',
            inputType: InputTypes.Number,
            label: 'Number of Private Docks'
          },
          {
            name: 'optional-numberSlips',
            inputType: InputTypes.Number,
            label: 'Number of Slips'
          },
          {
            name: 'optional-distanceToBodyOfWater',
            inputType: InputTypes.Text,
            label: 'Distance to Body of Water',
            placeholder: 'State in Blocks, Feet Or Miles'
          },
          {
            name: 'optional-meanLowWater',
            inputType: InputTypes.Text,
            label: 'Mean Low Water',
          },
          {
            name: 'optional-nameBodyWater',
            inputType: InputTypes.Text,
            label: 'Name of Body of Water',
          },
          {
            name: 'optional-waterFrontage',
            inputType: InputTypes.Text,
            label: 'Water Frontage (in ft)',
          },
          {
            name: 'optional-navigableWater',
            inputType: InputTypes.YesNo,
            label: 'Navigable Water',
          },
          {
            name: 'optional-riparianRightsConvey',
            inputType: InputTypes.YesNo,
            label: 'Riparian Rights Convey?',
          },
        ]
      },
    ]
  },
]


export {
  form,
}
