const services = {
  oneYearListing: 'oneYearListing',
  mlsListing: 'mlsListing',
  extendListing: 'extendListing',
  withdrawListing: 'withdrawListing',
  updatePropertyInfo: 'updatePropertyInfo',
  updatePropertyInfo: 'updatePropertyInfo',
}

const prices = {
  [services.oneYearListing]: 55, // can't be dynamic
  [services.mlsListing]: 195,
  [services.extendListing]: 95,
  [services.withdrawListing]: 45,
  [services.updatePropertyInfo]: 20,
  [services.updatePropertyInfo]: 20,
}

export {
  services,
  prices,
}