import { PropertyTypes } from './property-types'
import { Areas } from './states-areas'
import { Scopes } from './forms/types'


const options = [
  {
    name: 'additionalPhotos',
    title: 'Additional Photos',
    description: {
      default: `
        Prospective buyers want to see as many photos as possible. Now you can highlight all of the important features 
        of your home with vivid color photos of everything imaginable. Give buyers a complete photo tour! With this 
        option, you can display up to <b>50 photos</b> in the MLS and associated websites.
      `,
      scope: Scopes.IndividualMapping,
      mapping: {
        [Areas.Bright]: {
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important features 
            of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! With this 
            option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.CentralVA]: {
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important features 
            of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! With this 
            option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.Charlotte]: {
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important features 
            of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! With this 
            option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.NCRegion]: {
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important features 
            of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! With this 
            option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.Triad]: {
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important features 
            of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! With this 
            option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.Charleston]: {
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important features 
            of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! With this 
            option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.Triangle]: {
          [PropertyTypes.HomeSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your home with vivid color photos of everything imaginable. Give buyers a complete photo tour! 
            With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important 
            features of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! 
            With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>50 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.Coastal]: {
          [PropertyTypes.HomeSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your home with vivid color photos of everything imaginable. Give buyers a complete photo tour! 
            With this option, you can display up to <b>40 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important 
            features of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! 
            With this option, you can display up to <b>40 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>40 photos</b> in the MLS and associated websites.
          `
        },
        [Areas.Georgia]: {
          [PropertyTypes.HomeSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your home with vivid color photos of everything imaginable. Give buyers a complete photo tour! 
            With this option, you can display up to <b>80 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.HomeRent]: `
            Prospective renters want to see as many photos as possible. Now you can highlight all of the important 
            features of your home with vivid color photos of everything imaginable. Give renters a complete photo tour! 
            With this option, you can display up to <b>80 photos</b> in the MLS and associated websites.
          `,
          [PropertyTypes.LandSale]: `
            Prospective buyers want to see as many photos as possible. Now you can highlight all of the important 
            features of your lot and land with vivid color photos of everything imaginable. Give buyers a complete photo 
            tour! With this option, you can display up to <b>80 photos</b> in the MLS and associated websites.
          `
        },
      },
    },
    help: `
      Additional Photos may be sent to us at any time during the listing period. You may also change or replace photos 
      at no additional charge. Standard listing comes with 6 photos without unlimited changes.
    `,
    img: {
      default: 'additional-photos-home.jpg',
      scope: Scopes.ByPropertyType,
      types: {
        [PropertyTypes.LandSale]: 'additional-photos-land.jpg',
      },
    },
    label: 'Check This Box to Order the Additional Photos',
    price: {
      default: 45,
      scope: Scopes.ByMLSArea,
      areas: {
        [Areas.Triangle]: 45,
        [Areas.Coastal]: 45,
        [Areas.Georgia]: 45,
      },
    },
  },
  {
    name: 'openHouseAnnouncement',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
    title: 'Open House Announcement',
    description: {
      default: `
        Promote your next Open House with an announcement in the MLS, on Realtor.com and other MLS associated websites. 
        Let agents and buyers know when you are holding an open house. Save time and get more showings!
      `,
      scope: Scopes.ByPropertyType,
      types: {
        [PropertyTypes.HomeRent]: `
          Promote your next Open House with an announcement in the MLS, on Realtor.com and other MLS associated websites. 
          Let agents and renters know when you are holding an open house. Save time and get more showings!
        `,
      }
    },
    img: 'open-house-announcement.svg',
    label: 'Check This Box To Order the Open House Announcement',
    price: 20,
  },
  {
    name: 'lockbox',
    scope: Scopes.IndividualMapping,
    mapping: {
      [Areas.Charlotte]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],      
      [Areas.Triangle]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Coastal]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Georgia]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
      [Areas.Charleston]: [PropertyTypes.HomeSale, PropertyTypes.HomeRent],
    },
    title: 'MLS Lockbox',
    description: {
      default: `
        A lockbox is a universal metal container for your house key that is hung on the front door and can only be 
        opened by a special key carried by licensed real estate agents. It provides access when you are unable to show 
        the home, thus assuring full exposure to prospective buyers. Real Estate Agents are on a fast track and depend 
        heavily on lockboxes for quick access to homes. They are completely removable and controllable by you and fit 
        conveniently over the door frame, not the handle, so you may simply slip it off the frame and bring it inside 
        whenever wish.
      `,
      scope: Scopes.ByPropertyType,
      types: {
        [PropertyTypes.HomeRent]: `
          A lockbox is a universal metal container for your house key that is hung on the front door and can only be 
          opened by a special key carried by licensed real estate agents. It provides access when you are unable to show 
          the home, thus assuring full exposure to prospective renters. Real Estate Agents are on a fast track and depend 
          heavily on lockboxes for quick access to homes. They are completely removable and controllable by you and fit 
          conveniently over the door frame, not the handle, so you may simply slip it off the frame and bring it inside 
          whenever wish.
        `
      },
    },
    help: `
      Lockbox is leased and remains the property of Select Premium Properties. Lockbox is delivered by FedEx or USPS and 
      usually arrives within 1-2 business days. Small shipping cost applies.
    `,
    img: {
      default: 'lockbox.jpg',
      scope: Scopes.ByMLSArea,
      areas: {
        [Areas.Charlotte]: 'lockbox-supra.jpg',      
        [Areas.Coastal]: 'lockbox-supra.jpg',
        [Areas.Triangle]: 'lockbox-white.jpg',
        [Areas.Charleston]: 'lockbox-white.jpg',
        [Areas.Georgia]: 'lockbox-supra-ibox.jpg',
      },
    },
    label: 'Check This Box To Order an MLS Lockbox',
    price: 75,
    delivery: 14.85,
  },
  {
    name: 'woodenSignPost',
    scope: Scopes.IndividualMapping,
    mapping: {
      [Areas.Bright]: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
    },
    title: 'Wooden Sign Post',
    description: `
      Nothing says "Buy Me" more than a professional wooden sign post. Installed with a wooden post, metal panel, and 
      brochure box - this prestigious wooden sign post offers maximum high visibility and a quality presentation. Price 
      includes professional installation and removal after the listing is over. Sign posts are usually installed within 
      1-3 business days.
    `,
    img: 'wooden-sign-post.jpg',
    label: 'Check This Box To Order a Wooden Sign Post',
    price: 99,
  },
  {
    name: 'fullColorForSaleSign',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
    title: 'Full Color For Sale Sign',
    description: `
      This Professional Looking, 18 x 24, Full Color, For Sale sign is Double Sided with a Blank Call Space for Your 
      Phone Number (so you will get all of the calls). Included is an H Stand for easy installation. No hardware is 
      required. This sign is yours to keep and does not need to be returned to us.
    `,
    help: `Delivered by FedEx or USPS within 1-2 business days. Small shipping cost applies.`,
    img: 'full-color-for-sale.jpg',
    label: 'Check This Box To Order a Full Color For Sale Sign',
    price: 30,
    delivery: 13.85,
  },
  {
    name: 'blueAndWhiteForSaleSign',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
    title: 'Blue and White For Sale Sign',
    description: `
      This Beautiful Blue and White Professional Looking, 18 x 24, For Sale sign is Double Sided with a Blank Call Space 
      for Your Phone Number (so you will get all of the calls). An H Stand is included for easy installation. No 
      hardware is required. This sign is yours to keep and does not need to be returned to us.
    `,
    help: `Delivered by FedEx or USPS within 1-2 business days. Small shipping cost applies.`,
    img: 'blue-and-white-for-sale.jpg',
    label: 'Check This Box To Order a Blue and White For Sale Sign',
    price: 20,
    delivery: 13.85,
  },
  {
    name: 'fullColorSignAndRider',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
    title: 'Full Color Sign and Rider - Without Frame',
    description: `
      This Double Sided, Full Color, 18 x 24 Sign comes with a Blank 6 x 24 Call Sign Rider for your phone number. The 
      Sign is designed to be installed in your own sign frame or hanging sign post. This sign does not come with a frame 
      or stand. This sign is yours to keep and does not need to be returned to us.
    `,
    help: `Delivered by FedEx or USPS within 1-2 business days. Small shipping cost applies.`,
    img: 'full-color-with-rider.jpg',
    label: 'Check This Box To Order a Full Color Sign and Rider',
    price: 45,
    delivery: 13.85,
  },
  {
    name: 'homeWarrantyCoverage',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeSale],
    title: 'Home Warranty Coverage',
    description: {
      default: `
        <p>Covers you during the listing period then transfers to the buyer at closing for <b>one full year!</b> <a href="https://www.expressmlslistings.com/HomeWarrantyInfo.html" class="link" target="_blank">Click Here<a> for more coverage information.</p>
        <p class="mt-1">$444 - Order Now for Coverage. No Payment Due Until Closing.</p>
      `,
      scope: Scopes.ByMLSArea,
      areas: {
        [Areas.Bright]: `
          <p>Covers you during the listing period then transfers to the buyer at closing for <b>one full year!</b> <a href="https://www.expressmlslistings.com/HomeWarrantyInfo.html" class="link" target="_blank">Click Here</a> for more coverage information.</p>
          <p class="mt-1">$514 - Order Now for Coverage. No Payment Due Until Closing.</p>
        `,
        [Areas.Georgia]: `
          <p>Covers you during the listing period then transfers to the buyer at closing for <b>one full year!</b> <a href="https://www.expressmlslistings.com/HomeWarrantyInfo.html" class="link" target="_blank">Click Here</a> for more coverage information.</p>
          <p class="mt-1">$444 - Order Now for Coverage. No Payment Due Until Closing.</p>
        `,
      }
    },
    help: `Sales tax may apply depending on your local jurisdiction.`,
    img: 'one-year-warranty.svg',
    label: 'Check This Box To Order the 1 Year Home Warranty',
    price: null,
    replacePriceOnCheckoutWith: 'Paid at closing',
  },
  {
    name: 'contractReview',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeSale, PropertyTypes.LandSale],
    title: 'Contract Review with Broker',
    description: `
      The broker will review your purchase offer with you to help you understand the finer points of the offer, explain 
      what terms mean, explain the process of the contract, answers any non-legal questions and offer negotiation tips. 
      However, you will still be the one communicating with the buyer’s agent and handling the negotiations. Price 
      includes up to 2 purchase contract reviews.
    `,
    img: 'contract-review.svg',
    label: 'Check This Box To Order the Contract Review with Broker',
    price: 299,
  },
  {
    name: 'forRentSign',
    scope: Scopes.ByPropertyType,
    types: [PropertyTypes.HomeRent],
    title: 'For Rent Sign',
    description: `
      This Professional Looking, 18 x 24, For Rent sign is Double Sided with a Blank Call Space for Your Phone Number 
      (so you will get all of the calls). An H Stand is included for easy installation. No hardware is required. This 
      sign is yours to keep and does not need to be returned to us.
    `,
    help: `Delivered by FedEx or USPS within 1-2 business days. Small shipping cost applies.`,
    img: 'for-rent-sign.jpg',
    label: 'Check This Box to Order a Blue and White For Rent Sign',
    price: 20,
    delivery: 13.85,
  },
]


export {
  options,
}