const InputTypes = {
  Text: 'Text',
  Number: 'Number',
  Price: 'Price',
  Price: 'PriceOrPercent',
  Date: 'Date',
  Checkbox: 'Checkbox',
  YesNo: 'YesNo', // Binary selection - Yes / No
  Select: 'Select', // Requires 'options' prop
  Textarea: 'Textarea',
}

const Scopes = {
  ByPropertyType: 'ByPropertyType',
  ByMLSArea: 'ByMLSArea',
  ByState: 'ByState',
  IndividualMapping: 'IndividualMapping', // MLS area mapping
  IndividualStateMapping: 'IndividualStateMapping', // State mapping
}


export {
  InputTypes,
  Scopes,
}