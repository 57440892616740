<template>
  <header>
    <img
      :src="require('@/assets/img/landing-header.png')"
      class="w-full"
      alt="Get The Exposure You Need To Sell Your Home Fast!">
  </header>
  <footer>
    <div class="container cs14">
      <div class="row">
        <!-- Column 1 Start -->
        <div class="col-md-4 col-sm-6 col-12">
          <h3>License Info</h3>
          <div class="mt-25">
            <p class="mt-25">Select Premium Properties is Licensed in North Carolina, South Carolina, Virginia, Maryland,
              Pennsylvania, and Georgia. Stephanie Smith is the Principal Broker and Owner.</p>
            <br>
            <p>NC Principal Broker - Lic # 233506 <br>
              SC Principal Broker - Lic # 55136<br>
              GA Principal Broker - Lic # 366731<br>
              VA Principal Broker - Lic # 0225063073<br>
              MD Principal Broker - Lic # 641388<br>
              PA Principal Broker - Lic # RM423650<br>
            </p>
          </div>
        </div>
        <!-- Column 1 End -->

        <!-- Column 3 Start -->
        <div class="col-md-4 col-sm-6 col-12">
          <h3>Contact Us</h3>
          <div class="mt-25">
            <!-- Post Start -->
            <div class="footer-recent-post clearfix">
              <div class="footer-recent-post-content">
                <span><i class="fa fa-home"></i> 1213 Culbreth Dr. Wilmington, NC 28405</span>
              </div>
            </div>
            <!-- Post End -->
            <!-- Post Start -->
            <div class="footer-recent-post clearfix">
              <div class="footer-recent-post-content">
                <span><i class="fa fa-phone"></i>910-518-9280 / 703-831-6806</span>
              </div>
            </div>
            <!-- Post End -->
            <!-- Post Start -->
            <div class="footer-recent-post clearfix">
              <div class="footer-recent-post-content">
                <span><i class="fa fa-envelope"></i> service@selectpremiumprop.com</span>
              </div>
            </div>

            <div class="footer-recent-post clearfix">
              <div class="footer-recent-post-content">
                <span><i class="fa fa-clock-o"></i> Open Everyday, 365 Days A Year</span>
              </div>
            </div>
            <!-- Post End -->
          </div>
        </div>
        <!-- Column 3 End -->

        <!-- Column 4 Start -->
        <div class="col-md-4 col-sm-6 col-12">
          <h3>About Us</h3>
          <div class="footer-tags mt-25">
            <p> Our Purpose is to save property owners their hard-earned money by providing an alternative way to sell or
              rent your home quickly and in a professional manner.</p><br>
            <p> Our Mission is to provide a flat rate MLS listing with excellent customer service. Our turn around time
              for MLS listing, Listing Changes and Answering Emails are usually within a few hours of receiving them.</p>
            <br>
            <p>Our Belief is that there is a better way to sell or rent your home instead of a commission based on your
              selling price. Flat rate MLS listing is the future and For Sale By Owners can save thousands by going Flat
              Rate!</p>
            <br>
          </div>
        </div>
        <!-- Column 4 End -->
      </div>
      <!-- Footer Bar Start -->
      <div class="footer-bar">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-ms-12 col-xs-12 cs5">
            <span class="primary-color">©Select Premium Properties, Inc. No one may reproduce material herein.</span>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-ms-12 col-xs-12 cs4">
            <ul class="cs4-1" style="">
              <li><a href="/login">Log In</a></li>
              <li><a href="/signup">Sign Up</a></li>
              <!-- <li><a href="contactus.html">Contact Us</a> </li>
              <li><a href="fairhousing.html">Fair Housing</a></li>
              <li><a href="terms.html">Terms</a></li>
              <li><a href="privacy.html">Privacy </a></li>
              <li><a href="links.html">Links</a></li>
              <li><a href="sitemap.html">Site Map</a> </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- Footer Bar End -->
    </div>
  </footer>
</template>

<script>
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { auth } from '@/firebase'

export default {
  name: 'Landing',

  components: {
    
  },

  setup () {
    // General

    const router = useRouter()
    const route = useRoute()


    // Check if not autorized
    
    if (auth.currentUser) {
      router.push('/home')
    }


    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/bootstrap.scss";


/*-----------------------------------------
17. Footer
-------------------------------------------*/
footer {
    background: #162a4e;
    padding: 0px 0px 10px 0px;
}

footer .cs14 {
	padding: 60px 0px 20px 0px;
}

footer p {
    font-size: 15px;
    color: #fff;
    line-height: 1.7;
}

footer h3 {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    line-height: 1;
}

footer h3:after {
    content: '';
    display: block;
    width: 70px;
    height: 2px;
    background: #00A7E6;
    margin-top: 12px;
}

@media (max-width: 991px) {
    footer h3 {
        margin-top: 30px;
    }
}


.footer-social-icons ul li {
    display: inline-block;
    background: #333;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 5px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.footer-social-icons ul li a {
    display: block;
    font-size: 15px;
    color: #fff;
    width: 35px;
    height: 35px;
    padding: 11px;
    padding-top: 5px;
}

.footer-social-icons ul li:hover {
    background: #00A7E6;
}


.footer-list {
    margin-top: 20px;
}

.footer-list li {
    list-style-type: none;
    color: #aaa;
    padding: 6px 0px;
}

.footer-list li a:before {
    content: '\f105';
    font-family: 'FontAwesome';
    color: #aaa;
    padding-right: 8px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.footer-list li a {
    font-size: 15px;
    font-weight: 400;
    color: #aaa;
}

.footer-list li a:hover {
    color: #fff;
}

.footer-list li a:hover:before {
    color: #fff;
}


.footer-recent-post {
    margin: 15px 0px;
}

.footer-recent-post-thumb {
    float: left;
    width: 25%;
}

.footer-recent-post-content {
    float: left;
    width: 75%;
    padding-left: 10px;
}

.footer-recent-post-content a {
    display: block;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
}

.footer-recent-post-content span {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}


.footer-tags a {
    display: inline-block;
    font-size: 11px;
    padding: 10px 15px;
    color: #fff;
    font-weight: 400;
    background: #222;
    margin-top: 4px;
    border-radius: 5px;
}

.footer-tags a:hover {
    background: #00A7E6;
}


.footer-bar {
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding-top: 20px;
/*     margin-top: 30px; */
    /* padding-bottom: 3%; */
    padding-bottom: 20px;
}


.footer-bar span {
    color: #fff !important;
    font-size: 15px;
    font-weight: 400;
}

.footer-bar p {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

.main-logo {
	height: 60px;
}

.top-bar-welcome a {
	color: white;
}

.cs1 {
	background: #F23031;
	color: #fff;
	padding: 10px 15px;
	border-radius: 5px;
}

.cs1:hover {
	background: rgba(243, 49, 49, 0.8);
}

.nav-menu>li:first-child:hover>a .submenu-indicator-chevron {
	border-color: transparent #F23031 #F23031 transparent;
}

.nav-menu>li:first-child .nav-dropdown>li:hover>a,
.nav-menu>li:first-child .nav-dropdown>li.focus>a {
	color: #F23031;
}

.cs2 {
	background-repeat: no-repeat;
	width: 100%;
	background-position: 50% 50%;
	background-size: cover;
	min-height: 350px;
}

.slider-content {
	padding: 20px;
}

.slider-content span {
	color: #ED787E;
}

#top-bar a {
	color: #fff;
}

#top-bar a:hover {
	color: #00A7E6;
}

.margin {
	margin: 10px;
}

.padding-top {
	padding-top: 10%;
}

.sold {
	margin-top: 10px;
}

#top {
	background: #33486f;
	color: white;
	padding: 5px 15px;
	text-align: center;
}

#top a {
	color: #fff;
}

.cs-text {
	padding: 15px 15px 15px 20px;
}

.cs3 {
	color: #000;
}

.cs4 {
	text-align: right;
}

.cs4-1 {
	display: inline-block;
}

@media (max-width: 992px) {
	.cs4 {
		text-align: center;
	}

	.cs5 {
		text-align: center;
		margin-bottom: 10px;
	}
}

.cs4 ul li {
	float: left;
	padding-left: 5px;
	list-style: none;
}

.cs4 ul li a {
	color: #fff;
}

.cs5 {
	float: left !important;
}

.cs4-1 li:not(:last-of-type):after {
	padding-left: 5px;
	content: "|";
	color: #fff;
}

.cs4 ul li a:hover {
	color: #00A7E6;
}

blockquote p {
	color: #000 !important;
}

.cs6 {
	padding: 60px;
}

.outer {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	width: 100%;
	height: 400px;
}

.cs7 li {
	list-style: square;
}

.cs7 .cs8 h3 {
	color: #000 !important;
}

.primary-button-red {
	background: #F23031;
}

.primary-button-red:hover {
	background: rgba(243, 49, 49, 0.8);
}

.mls .team-member {
	min-height: 500px;
}

.overlay {
	background-color: rgba(0, 0, 0, 0.7);
	bottom: 0;
	cursor: default;
	left: 0;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	visibility: hidden;
	z-index: 10000;
	-webkit-transition: opacity .5s;
	-moz-transition: opacity .5s;
	-ms-transition: opacity .5s;
	-o-transition: opacity .5s;
	transition: opacity .5s;
}

.overlay:target {
	visibility: visible;
	opacity: 1;
	z-index: 99000;
}

.popup {
	overflow: auto;

	/* one finger scroll for iPad */
	-webkit-overflow-scrolling: touch;
	background: #FFF;
	margin: 0 auto 30px;
	border-radius: 8px;
	display: inline-block;
	left: 50%;
	opacity: 0;
	padding: 15px;
	position: fixed;
	text-align: justify;
	font-size: 16px;
	font-weight: 300;
	top: 40%;
	visibility: hidden;
	z-index: 100000;
	min-height: 200px;

	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);

	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	border-radius: 10px;



	-webkit-transition: opacity .5s, top .5s;
	-moz-transition: opacity .5s, top .5s;
	-ms-transition: opacity .5s, top .5s;
	-o-transition: opacity .5s, top .5s;
	transition: opacity .5s, top .5s;


}

.overlay:target+.popup {
	top: 50%;
	opacity: 1;
	visibility: visible;
}

.close {
	cursor: pointer;
	color: #494949;
	font-size: 16px;
	padding: 8px;
	z-index: 1;
	position: absolute;
	top: 15px;
	right: 15px;
	opacity: .9;
	background: #fff;
}

.close:before {
	color: #494949;
	content: "X";

}

.mf-text {
	padding: 10px 0px;
	color: #777;
}

.mf-req {
	color: red;
	font-size: 20px;
	padding-left: 5px;
}

input[type="text"] {
	padding: 7px 10px;
	border: 2px solid #DDD;
	height: 40px;
	border-radius: 6px;
	-webkit-transition-property: border-color;
	transition-property: border-color;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
	-webkit-transition-timing-function: cubic-bezier(.23, 1, .32, 1);
	transition-timing-function: cubic-bezier(.23, 1, .32, 1);
	font-size: 16px;
	width: 100% !important;

}

textarea {
	padding: 7px 10px;
	border: 2px solid #DDD;

	border-radius: 6px;
	-webkit-transition-property: border-color;
	transition-property: border-color;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
	-webkit-transition-timing-function: cubic-bezier(.23, 1, .32, 1);
	transition-timing-function: cubic-bezier(.23, 1, .32, 1);
	font-size: 16px;
	width: 100% !important;
}

blockquote {
	margin: 25px 0px;
	border-left: 3px solid #eee;
	padding: 10px 20px;
}

.cs9 li {
	list-style: square;
}

.cs10 li {
	margin: 10px 0px;
}

.wt {
	color: #fff;
}

.cs13 {
	text-align: center;
	padding-top: 10px;
}


/* Make text bigger */

.progress-text {
	font-size: 14px;
}

.testmonial-box span {
	font-size: 15px;
}

body .container p,
.testmonial-single h6,
.primary-list li,
.default-tabs .tabs .tab-menu li a,
.page-title-section ul li a,
.primary-button  {
	font-size: 16px;
}

.testmonial-single p,
.accordion-title a {
	font-size: 18px;
}


.testmonial-single h4 {
	font-size: 25px;
}


/* Darken 'Recently Sold' section background */

.section-clients-grey {
	background: #ccc;
}


/* Hover color for blocks from MLS marketing options */

.shop-grid:hover .shop-grid-info h5 {
	background-color: #333;
	color: #fff;
}


/* MLS pricing page - make width of 80% on big screens */

.pricing-table {
	width: 80%;
}

@media (max-width: 480px) {
	.pricing-table {
		width: 100%;
	}
}
.cont {
	min-height: 600px;
}
</style>