import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/app-check'
import { adminEmails, localhostSendEmails, localhostDevIP } from '@/config/main'
import { buildMessage, buildAdminMessage } from '@/utils/main'

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyAvGu_0F2UNTiXtMx6xLOAxqG7856UwW7Q",
  authDomain: "select-premium-properties.firebaseapp.com",
  projectId: "select-premium-properties",
  storageBucket: "select-premium-properties.appspot.com",
  messagingSenderId: "336804150470",
  appId: "1:336804150470:web:3e46230833886c4aaaabc7"
};

firebase.initializeApp(firebaseConfig)

// Firebase AppCheck
// https://firebase.google.com/docs/app-check/web/recaptcha-provider?authuser=0#web-version-8
// const appCheck = firebase.appCheck()
// appCheck.activate('6Ld9q2MeAAAAAOo7EyFk7e9QzbHSdYaQU5GoHpNe', true)

// Utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
storage.setMaxUploadRetryTime(60*60*1000) // 1 hour (default - 10 mins)
const Timestamp = firebase.firestore.Timestamp

const userId = 'uid'

// Firestore
const userCollection = db.collection('users')
const userRef = (uid) => uid ? userCollection.doc(uid) : userCollection.doc(auth.currentUser[userId])
const listingCollection = db.collection('listings')
const listingRef = (listingId) => listingCollection.doc(listingId)
const formCollection = (listingId) => listingRef(listingId).collection('forms')
const formRef = (listingId, formId) => formCollection(listingId).doc(formId)
const orderCollection = (listingId) => listingRef(listingId).collection('orders')
const orderRef = (listingId, orderId) => orderCollection(listingId).doc(orderId)
const emailCollection = () => db.collection('emails')

// Storage
const getStorageRef = (listingId, dirName, uid) => {
  const UID = uid ? uid : auth.currentUser[userId]
  return storage.ref(`users/${UID}/${listingId}/${dirName}`)
}
const propertyPhotosStorage = (listingId, uid) => getStorageRef(listingId, 'propertyPhotos', uid)
const formSignaturesStorage = (listingId, formId, uid) => getStorageRef(listingId, 'formSignatures', uid).child(formId)
const uploadedDocumentsStorage = (listingId, uid) => getStorageRef(listingId, 'documents', uid)

// Functions
const cloudFunctionUrl = (func) => process.env.VUE_APP_CLOUD_FUNCTIONS_URL + func


// Utils

const notifyAdmin = (id, data) => {
  if ((location.hostname === 'localhost' || location.hostname === localhostDevIP) && !localhostSendEmails) {
    console.log('Localhost emails are disabled')
    console.log(buildAdminMessage(id, data))
    return
  }

  emailCollection().add({
    to: adminEmails,
    message: buildAdminMessage(id, data),
  }).then(() => console.log('Queued email for delivery!'))
}

const notifyUser = (id, data, userEmail) => {
  if ((location.hostname === 'localhost' || location.hostname === localhostDevIP) && !localhostSendEmails) {
    console.log('Localhost emails are disabled')
    console.log(buildMessage(id, data))
    return
  }

  let emailObj = {
    to: [userEmail ? userEmail : auth.currentUser.email],
    message: buildMessage(id, data),
  }

  if (id === 'registration') {
    emailObj['cc'] = adminEmails
  } else if (id === 'activeListing') {
    emailObj['bcc'] = adminEmails
  }
  
  emailCollection().add(emailObj).then(() => console.log('Queued email for delivery!'))
}


export {
  db,
  auth,
  storage,
  Timestamp,

  userCollection,
  userRef,
  listingCollection,
  listingRef,
  formCollection,
  formRef,
  orderCollection,
  orderRef,

  propertyPhotosStorage,
  formSignaturesStorage,
  uploadedDocumentsStorage,

  cloudFunctionUrl,

  notifyAdmin,
  notifyUser,
}