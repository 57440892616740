const Areas = {
  Georgia: 'Georgia',
  Bright: 'Bright',
  Charlotte: 'Charlotte',
  NCRegion: 'NCRegion',
  Triangle: 'Triangle',
  Triad: 'Triad',
  Charleston: 'Charleston',
}

const States = {
  Maryland: 'Maryland',
  Pennsylvania: 'Pennsylvania',
  Virginia: 'Virginia',
  NorthCarolina: 'NorthCarolina',
  SouthCarolina: 'SouthCarolina',
  Georgia: 'Georgia',
}

const StateNames = {
  [States.Maryland]: 'Maryland',
  [States.Pennsylvania]: 'Pennsylvania',
  [States.Virginia]: 'Virginia',
  [States.NorthCarolina]: 'North Carolina',
  [States.SouthCarolina]: 'South Carolina',
  [States.Georgia]: 'Georgia',
}

const ShortStates = {
  [States.Maryland]: 'MD',
  [States.Pennsylvania]: 'PA',
  [States.Virginia]: 'VA',
  [States.NorthCarolina]: 'NC',
  [States.SouthCarolina]: 'SC',
  [States.Georgia]: 'GA',
}

let sourcesStatesAreas = {
  lexp: {
    [States.Maryland]: {
      title: StateNames[States.Maryland],
      areas: [Areas.Bright],
    },
    [States.Pennsylvania]: {
      title: StateNames[States.Pennsylvania],
      areas: [Areas.Bright],
    },
    [States.Virginia]: {
      title: StateNames[States.Virginia],
      areas: [Areas.Bright],
    },
  },
  clxp: {
    [States.NorthCarolina]: {
      title: StateNames[States.NorthCarolina],
      areas: [Areas.Charlotte, Areas.NCRegion, Areas.Triangle, Areas.Triad],
    },
    [States.SouthCarolina]: {
      title: StateNames[States.SouthCarolina],
      areas: [Areas.Charleston, Areas.Charlotte],
    },
  },
  glxp: {
    [States.Georgia]: {
      title: StateNames[States.Georgia],
      areas: [Areas.Georgia],
    },
  },
}

sourcesStatesAreas['emls'] = {
  ...sourcesStatesAreas.lexp,
  ...sourcesStatesAreas.clxp,
  ...sourcesStatesAreas.glxp,
}

const areas = {
  // lexp
  [Areas.Bright]: {
    title: 'Bright MLS',
    img: 'Bright.png',
  },
  // clxp
  [Areas.Charlotte]: {
    title: 'Charlotte / NC Mountains MLS',
    img: 'Charlotte.png',
  },
  [Areas.NCRegion]: {
    title: 'NC Regional MLS',
    img: 'NCRegion.png',
  },
  [Areas.Triangle]: {
    title: 'Triangle MLS',
    img: 'Triangle.png',
  },
  [Areas.Triad]: {
    title: 'Triad MLS',
    img: 'Triad.png',
  },
  [Areas.Charleston]: {
    title: 'Charleston SC MLS',
    img: 'Charleston.png',
  },
  // glxp
  [Areas.Georgia]: {
    title: 'Georgia MLS',
    img: 'Georgia.jpg',
  },
}

let areasCoverage = {
  lexp: {
    [States.Maryland]: {
      [Areas.Bright]: {
        subtitle: 'Entire State of Maryland',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: "Maryland - All Areas Served",
          },
        ],
      },
    },
    [States.Pennsylvania]: {
      [Areas.Bright]: {
        subtitle: 'South Eastern Pennsylvania',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: "Adams County PA, Berks County PA, Bucks County PA, Chester County PA, Cumberland County PA, Dauphin County PA, Delaware County PA, Franklin County PA, Fulton County PA, Juniata County PA, Lancaster County PA, Lebanon County PA, Montgomery County PA, Perry County PA, Philadelphia County PA, Schuylkill County PA, York County PA",
          },
        ],
      },
    },    
    [States.Virginia]: {
      [Areas.Bright]: {
        subtitle: 'Northern Virginia Area <br> and Charlottesville Area',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
            <p class="mb-2">Alexandria City VA, Arlington VA, Caroline County VA, Clarke County, VA, Culpeper County VA, Fairfax City VA,  Fairfax County VA, Falls Church City VA, Fauquier County VA, Frederick County VA, Fredericksburg City VA, King George County VA, Loudoun County VA, Madison County VA, Manassas City VA, Manassas Park City VA, Orange County VA, Page County VA, Prince William County VA,  Rappahannock County VA, Shendandoah County VA, Spotsylvania County VA, Stafford County VA, Warren County VA, Winchester City VA</p>
            <p><b>Charlottesville Area</b><br>The Bright MLS has a data sharing agreement with the Charlottesville Area. The following Counties and Cities may list in the Bright MLS and agents who belong to the Charlottesville MLS will be able to view the listing.<br><br> Albemarle VA, Charlottesville VA, City of Charlottesville, Fluvanna VA, Greene VA, Louisa VA, Nelson VA</p>
            `,
          },
        ],
      },     
    },
  },
  clxp: {
    [States.NorthCarolina]: {
      [Areas.Triangle]: {
        subtitle: 'Raleigh / Durham / Cary',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">Alamance County NC, Caswell County NC, Chatham County NC, Cumberland County NC, Durham County NC, Franklin County NC, Granville County NC, Halifax County NC, Harnett County NC, Hoke County NC, Johnston County NC (Also Covered By NC Regional MLS), Lee County NC, Nash County NC (Also Covered By NC Regional MLS), Orange County NC, Person County NC, Sampson County NC (Also Covered By NC Regional MLS), Robeson County (Also Covered By NC Regional MLS) NC, Vance County NC, Wake County NC, Warren County NC</p>
              <p><b>Other Surrounding Areas</b><br>These areas may have another MLS system associated with them, however you may still list your home in the larger Triangle Area MLS for greater exposure: Halifax County NC, Northampton County NC</p>
            `,
          },
        ],
      },
      [Areas.Triad]: {
        subtitle: 'Winston-Salem / High Point / Greensboro',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">Davidson County NC, Davie County NC, Forsyth County NC, Guilford County NC, Randolph County NC, Rockingham County NC, Stokes County NC, Surry County NC, Wilkes County NC, Yadkin County NC</p>
              <p><b>Other Surrounding Areas</b><br>These areas may have another MLS system associated with them, however you may still list your home in the larger Triad Area MLS for greater exposure: Alamance County NC, Caswell County NC</p>
            `,
          },
        ],
      },
      [Areas.Charlotte]: {
        subtitle: 'Charlotte Area / NC Mountains',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">Alexander County NC, Anson County NC, Asheville, Buncombe County NC, Burke County NC, Brevard, Cabarrus County NC, Caldwell County NC, Catawba County NC, City of Kannapolis, Chester County SC, Cleveland County NC, Gaston County NC, Haywood County NC, Henderson County NC, Hendersonville, Iredell County NC, Lancaster County SC, Lincoln County NC, Madison County NC, McDowell County NC, Mecklenburg County NC, Montgomery County NC, Polk County NC, Rowan County NC, Rutherford County NC, Stanly County NC, Transylvania County NC, Union County NC, Uptown Charlotte, York County SC</p>
              `,
          },
        ],
      },
      [Areas.NCRegion]: {
        subtitle: 'North Carolina Regional MLS',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
            Beaufort County NC, Bertie County NC, Bladen County NC, Brunswick County NC, Camden County NC, Carteret County NC, Chowan County NC, Columbus County NC, Craven County NC, Currituck County NC, Duplin County NC, Edgecomb County NC, Gates County NC, Greene County NC, Hertford County NC, Hyde County NC, Jacksonville NC, Johnston County (Also Covered by Triangle MLS), Jones County NC, Lenoir County NC, Martin County NC, Moore County NC, Nash County NC (Also Covered by Triangle MLS), New Hanover County NC, Onslow County NC, Pamlico County NC, Pasquotank County NC, Pender County NC, Perquimans County NC, Pitt County NC, Richmond County NC, Robeson County NC (Also Covered By Triangle MLS), Sampson County NC (Also Covered By Triangle MLS), Scotland County NC, Tyrrell County NC, Washington County NC, Wayne County NC, Wilson County NC
            `,
          },
        ],
      },
    },
    [States.SouthCarolina]: {
      [Areas.Charleston]: {
        subtitle: 'Charleston Area MLS',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">Allendale County SC, Berkeley County SC, Charleston County SC, Colleton County SC, Dorchester County SC, Hampton County SC, North Charleston SC </p>
              <p><b>Other Surrounding Areas</b><br>These areas may have another MLS system associated with them, however you may still list your home in the larger Charleston Area MLS for greater exposure: Bamberg County SC, Orangeberg County SC</p>
            `,
          },
        ],
      },
      [Areas.Charlotte]: {
        subtitle: 'Charlotte Area / NC Mountains',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
            Alexander County NC, Anson County NC, Asheville, Buncombe County NC, Burke County NC, Brevard, Cabarrus County NC, Caldwell County NC, Catawba County NC, City of Kannapolis, Chester County SC, Cleveland County NC, Gaston County NC, Haywood County NC, Henderson County NC, Hendersonville, Iredell County NC, Lancaster County SC, Lincoln County NC, Madison County NC, McDowell County NC, Mecklenburg County NC, Montgomery County NC, Polk County NC, Rowan County NC, Rutheford County NC, Stanly County NC, Transylvania County NC, Union County NC, Uptown Charlotte, York County SC
                `,
          },
        ],
      },
    },
  },
  glxp: {
    [States.Georgia]: {
      [Areas.Georgia]: {
        subtitle: 'The Georgia MLS Covers Much of Georgia',
        info: [
          {
            title: 'The GAMLS (Georgia MLS) is the primary MLS system for the following counties',
            text: `
              <p class="mb-2">Banks GA, Barrow GA, Bartow GA, Butts GA, Carroll GA, Cherokee GA, Clarke GA, Clayton GA, Cobb GA, Coweta GA, Dawson GA, Dekalb GA, Douglas GA, Fayette GA, Floyd GA, Forsyth GA, Franklin GA, Fulton GA, Gordon GA, Green GA, Gwinnett GA, Habersham GA, Hall GA, Hart GA, Haralson GA, Heard GA, Henry GA, Jackson GA, Jasper GA, Jones GA, Lamar GA, Lumpkin GA, Meriwether GA, Monroe GA, Morgan GA, Newton GA, Oconee GA, Oglethorpe GA, Paulding GA, Pickens GA, Pike GA, Polk GA, Putnam GA, Rabun GA, Rockdale GA, Spalding GA, Stephens GA, Troup GA, Upson GA, Walton GA, White GA</p>
              <p><b>The GAMLS (Georgia MLS) also covers the counties below. However, there may be another MLS that covers these counties as well. You may list in the GAMLS for greater exposure of your property.</b><br>Appling GA, Atkinson GA, Bacon GA, Baker GA, Baldwin GA, Ben Hill GA, Berrien GA, Bibb GA, Bleckley GA, Brantley GA, Brooks GA, Bryan GA, Bulloch GA, Burke GA, Calhoun GA, Camden GA, Candler GA, Catoosa GA, Charlton GA, Chatham GA, Chattahoochee GA, Chattooga GA, Clay GA, Coffee GA, Columbia GA, Cook GA, Crawford GA, Crisp GA, Dade GA, Decatur GA, Dodge GA, Dooly GA, Dougherty GA, Douglas GA, Effingham GA, Elbert GA, Emanuel GA, Evans GA, Fannin GA, Gilmer GA, Glascock GA, Glynn GA, Hancock GA, Harris GA, Houston GA, Jeff Davis GA, Jefferson GA, Jenkins GA, Johnson GA, Laurens GA, Lee GA GA, Liberty GA, Lincoln GA, Long GA, Lowndes GA, Macon GA, Madison, A, Marion GA, Mcduffie GA, Mcintosh GA, Mitchell GA, Monroe GA, Montgomery GA, Morgan GA, Murray GA, Muscogee GA, Peach GA, Pierce GA, Pulaski GA, Quitman GA, Randolph GA, Richmond GA, Schley GA, Screven GA, Seminole GA, Stewart GA, Sumter GA, Talbot GA, Taliaferro GA, Tattnall GA, Taylor GA, Telfair GA, Terrell GA, Thomas GA, Tift GA, Toombs GA, Towns GA, Treutlen GA, Turner GA, Twiggs GA, Union GA, Walker GA, Ware GA, Warren GA, Washington GA, Wayne GA, Wheeler GA, Whitfield GA, Wilcox GA, Wilkes GA, Wilkinson GA, Worth GA</p>
              `,
          },
        ],
      },
    },
  },
}

areasCoverage['emls'] = {
  ...areasCoverage.lexp,
  ...areasCoverage.clxp,
  ...areasCoverage.glxp,
}


export {
  Areas,
  States,
  StateNames,
  ShortStates,
  sourcesStatesAreas,
  areas,
  areasCoverage,
}