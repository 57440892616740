import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import { auth } from './firebase'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";


let app

auth.onAuthStateChanged(user => {
  if (user) {
    store.dispatch('user/fetchProfile')
  }

  if (!app) {
    app = createApp(App)

    // Initialize Sentry only in production environment
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        app,
        dsn: "https://16bb964a999445c293f3ffedc8f964c2@o1143533.ingest.sentry.io/6204238",
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "my-site-url.com", /^\//],
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }

    app.use(store).use(router).mount('#app')
  }
})
