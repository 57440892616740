import { createStore } from 'vuex'

import user from './modules/user'
import listing from './modules/listing'

export default createStore({
  modules: {
    user,
    listing,
  }
})