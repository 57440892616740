import { Scopes } from "@/config/forms/types"
import { mapValues } from 'lodash'


export class Localizer {
  constructor(propertyType, mlsArea, state) {
    this.propertyType = propertyType
    this.mlsArea = mlsArea
    this.state = state
  }

  isRightScope(obj) {
    return !('scope' in obj) ||
      (obj.scope === Scopes.ByPropertyType && obj.types.includes(this.propertyType)) ||
      (obj.scope === Scopes.ByMLSArea && obj.areas.includes(this.mlsArea)) ||
      (obj.scope === Scopes.ByState && obj.states.includes(this.state)) ||
      (obj.scope === Scopes.IndividualMapping &&
        this.mlsArea in obj.mapping &&
        obj.mapping[this.mlsArea].includes(this.propertyType)
      ) ||
      (obj.scope === Scopes.IndividualStateMapping &&
        this.state in obj.mapping &&
        obj.mapping[this.state].includes(this.propertyType)
      )
  }

  getLocalValue = (val) => {
    if (val === undefined || typeof val !== 'object' || Array.isArray(val) || val === null) {
      return val
    }
  
    if ('scope' in val) {
      if ((val.scope === Scopes.ByPropertyType && this.propertyType in val.types)) {
        return val.types[this.propertyType]
      } else if (val.scope === Scopes.ByMLSArea && this.mlsArea in val.areas) {
        return val.areas[this.mlsArea]
      } else if (val.scope === Scopes.ByState && this.state in val.states) {
        return val.states[this.state]
      } else if (val.scope === Scopes.IndividualMapping && 
        this.mlsArea in val.mapping && 
        this.propertyType in val.mapping[this.mlsArea]
      ) {
        return val.mapping[this.mlsArea][this.propertyType]
      } else if (val.scope === Scopes.IndividualStateMapping && 
        this.state in val.mapping && 
        this.propertyType in val.mapping[this.state]
      ) {
        return val.mapping[this.state][this.propertyType]
      } else {
        return val.default
      }
    }
  
    return val
  }
  
  localizeArray(arr, deep = true) {
    if (!Array.isArray(arr)) {
      return arr
    }
  
    const arrCopy = arr.map(obj => { return {...obj} })
    const localizedArr = arrCopy.filter(obj => this.isRightScope(obj))

    if (!deep) {
      return localizedArr
    }

    return localizedArr.map(obj => mapValues(obj, prop => this.getLocalValue(prop)))
  }
}