import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase'
import Landing from '../views/Landing.vue'


const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      title: "Home",
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup-info" */ '../views/SignUp.vue'),
    meta: {
      title: "Sign Up",
    }
  },
  {
    path: '/signup-info',
    name: 'signupInfo',
    component: () => import(/* webpackChunkName: "signup-info" */ '../views/SignUpInfo.vue'),
    meta: {
      title: "Sign Up",
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: "Login",
    }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
    meta: {
      title: "Reset Password",
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: "Home",
      requiresAuth: true,
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      title: "Settings",
      requiresAuth: true,
    }
  },
  {
    path: '/listing/:listingId',
    name: 'listing',
    component: () => import(/* webpackChunkName: "listing" */ '../views/Listing.vue'),
    meta: {
      title: "Listing",
      requiresAuth: true,
    }
  },
  {
    path: '/photos/:listingId',
    name: 'photos',
    component: () => import(/* webpackChunkName: "photos" */ '../views/Photos.vue'),
    meta: {
      title: "Photos",
      requiresAuth: true,
    }
  },
  {
    path: '/listing/:listingId/form/:formName/:formId',
    name: 'SignForm',
    component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue'),
    meta: {
      title: "Form",
      requiresAuth: true,
    }
  },

  // Informational pages
  {
    path: '/info/residential-square-footage',
    name: 'ResidentialSquareFootage',
    component: () => import(/* webpackChunkName: "info-residential-square-footage" */ '../info-pages/ResidentialSquareFootage.vue'),
    meta: {
      title: "Residential Square Footage Guidelines",
      requiresAuth: true,
    }
  },

  // Admin
  {
    path: '/admin/listings',
    name: 'listings',
    component: () => import(/* webpackChunkName: "form" */ '../views/admin/Listings.vue'),
    meta: {
      title: "Listings",
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/admin/user-listings/:uid',
    name: 'userListings',
    component: () => import(/* webpackChunkName: "form" */ '../views/admin/UserListings.vue'),
    meta: {
      title: "User Listings",
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/admin/listings/:id',
    name: 'adminListing',
    component: () => import(/* webpackChunkName: "form" */ '../views/admin/Listing.vue'),
    meta: {
      title: "Listing",
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/admin/photos/:id',
    name: 'adminPhotos',
    component: () => import(/* webpackChunkName: "form" */ '../views/admin/AdminPhotos.vue'),
    meta: {
      title: "Photos",
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/admin/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "form" */ '../views/admin/Users.vue'),
    meta: {
      title: "Users",
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/admin/users/:id',
    name: 'adminUser',
    component: () => import(/* webpackChunkName: "form" */ '../views/admin/UserProfile.vue'),
    meta: {
      title: "User Profile",
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { left: 0, top: 0 }
  }
})


const DefaultTitle = 'Select Premium Properties'

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/signup')
  } else {
    document.title = to.meta.title ? to.meta.title + ' – ' + DefaultTitle : DefaultTitle
    next()
  }
})


export default router
