import router from '@/router'
import { merge } from 'lodash'
import { auth, notifyAdmin, listingCollection, listingRef, Timestamp } from '@/firebase'
import { listingSteps, domainAddress } from '@/config/main'
import { formatAddress, generateString } from '@/utils/main'
// import { getSortedFields } from '@/config/fields-sorting-for-admin'


const generateId = (source) => {
  const now = new Date()
  const year = now.getFullYear().toString().slice(2)
  let month = (now.getMonth() + 1).toString()
  month = month.length === 1 ? '0' + month : month
  let day = now.getDate().toString()
  day = day.length === 1 ? '0' + day : day
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const seconds = now.getSeconds()
  return `${year}-${month}-${day}-${hours}${minutes}${seconds}${generateString(3)}.${source}`
}

export default {
  namespaced: true,

  state: {
    id: null,
    data: {},
  },

  getters: {
    initialized: (state) => {
      return Object.keys(state.data).length !== 0
    },

    initialTemplate: (state, getters, rootState) => {
      return {
        created: new Date(),
        uid: auth.currentUser.uid,
        step: 'property-info',
        formStep: 'type-and-mls',
        owner: {
          email: auth.currentUser.email,
          firstName: rootState.user.profile.firstName,
          lastName: rootState.user.profile.lastName,
          phone: rootState.user.profile.phone,
        },
      }
    },

    stepObj: (state, getters) => {
      if (getters.initialized) {
        return listingSteps.filter((step) => step.id === state.data.step)[0]
      }

      return null
    },

    formStepObj: (state, getters) => {
      if (getters.initialized && getters.stepObj && 'formSteps' in getters.stepObj) {
        return getters.stepObj.formSteps.filter((step) => step.id === state.data.formStep)[0]
      }

      return null
    },

    fullAddress: (state, getters) => {
      return formatAddress(state.data)
    },
  },
  
  actions: {
    async create(ctx) {
      const initialTemplate = ctx.getters.initialTemplate
      let generatedId = generateId(ctx.rootState.user.profile.source)
      const listing = await listingCollection.doc(generatedId).set(initialTemplate)
      ctx.commit('setId', generatedId)
      ctx.commit('setData', initialTemplate)
      router.replace('/listing/'+ generatedId)
    },

    async fetch(ctx, id) {
      const listing = await listingRef(id).get()

      if (listing.exists) {
        ctx.commit('setId', id)
        ctx.commit('setData', listing.data())

        if (!ctx.rootState.user.profile.isAdmin) {
          listingRef(id).update({lastAccess: Timestamp.fromDate(new Date())})
        }

        // getSortedFields(listing.data())
      } else {
        ctx.commit('setId', null)
        throw new Error('Listing doesn\'t exist')
      }
    },

    async goToStep(ctx, newStep) {
      if (newStep === 'waiting-for-listing') {
        notifyAdmin('newListing', {
          userFullName: `${ctx.state.data.owner.firstName} ${ctx.state.data.owner.lastName} (${ctx.state.data.owner.email})`,
          listingURL: `${domainAddress}/admin/listings/${ctx.state.id}`,
          listingData: ctx.state.data,
          listingId: ctx.state.id,
        })
      }

      ctx.commit('setStep', newStep)
      listingRef(ctx.state.id).update({step: newStep})
    },

    async goToFormStep(ctx, newStep) {
      ctx.commit('setFormStep', newStep)
      listingRef(ctx.state.id).update({formStep: newStep})
    },

    async nextFormStep(ctx) {
      const nextStep = ctx.getters.stepObj.formSteps.filter(step => step.number === ctx.getters.formStepObj.number + 1)[0].id
      ctx.commit('setFormStep', nextStep)
      listingRef(ctx.state.id).update({formStep: nextStep})
    },

    async setFormStep(ctx, stepId) {
      ctx.commit('setFormStep', stepId)
      listingRef(ctx.state.id).update({formStep: stepId})
    },

    clear(ctx) {
      ctx.commit('setId', null)
      ctx.commit('setData', {})
    },

    async save(ctx) {
      listingRef(ctx.state.id).update(ctx.state.data)
    },
  },

  mutations: {
    setId(state, id) {
      state.id = id
    },
    setData(state, listing) {
      state.data = listing
    },
    updateData(state, newData) {
      merge(state.data, newData)
    },
    setStep(state, step) {
      state.data.step = step
    },
    setFormStep(state, formStep) {
      state.data.formStep = formStep
    },
  },
}